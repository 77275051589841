import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  TextInput,
  Platform,
  Dimensions,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';



import MavSelectDate from './mavSelectDate';

import firebase from './firebase';
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

import info from '../info';
import MavInfoBubble from './mavInfoBubble';
import MavAlert from './mavAlert';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MapView from 'react-native-maps';
import MavSelector from './mavSelector';
import TextPresentation from './textPresentation';
import moneyList from '../moneyList';
import MavNavigationMinimize from './mavNavigationMinimize';

const timer = require('react-native-timer');
export default class account extends Component {
  constructor(props) {
    super(props);


    var infoMoney=this.findMoney();


    this.state = {
      codeName:"",
      money:infoMoney,
      amount:false,
      codeReduc:"",
      codeFrom:"", 
      codeID:"",
      useList:[],
      viewList:false,
      addUsersMax:false,
      onlyProvision:false,
      validMoreUser:false,
      usersMax:0, 
      dateStart:"",
      dateStartTxt:"",
      dateEndTxt:"",
      dateEnd:"",
      addToPOI:false,
      takeCost:false,
      listPOI:[],
      poiSelected:{},
      extraEUMode:false,
    };
  }

  findMoney(value){
    if(value==undefined) value="eu";
    var final="";
    var i; 
    for(i=0;i<moneyList.length;i++){
      if(final==""){
        if(moneyList[i].id==value) final=moneyList[i];
      }
    }
    return final
  }


  changeAllPrice(value){

    var codeReduc=this.state.codeReduc;
    codeReduc=codeReduc.replace(this.state.money.symbole,'')
    if(codeReduc!="") codeReduc=((value.position=="left")?(value.symbole+" "):"")+codeReduc+((value.position=="right")?(value.symbole):"")
    
    this.setState({money:value,codeReduc:codeReduc})

  }


  changeType(){
    var codeReduc=this.state.codeReduc;
    if(this.state.amount==true){
      
      console.log("je remplace "+this.state.money.symbole)
      console.log(codeReduc)
      codeReduc=codeReduc.replace(this.state.money.symbole,'')
      console.log(codeReduc)
      if(codeReduc!="") codeReduc+="%"
    }
    else {
      codeReduc=codeReduc.replace("%",'')
      console.log("je remplace %")
      if(codeReduc!="") codeReduc=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+codeReduc+((this.state.money.position=="right")?(this.state.money.symbole):"")
    }

    this.setState({amount:!this.state.amount, codeReduc:codeReduc})

  }

  viewPoi(){

      var selected=-1;
      var i;
      var list=this.state.listPOI;
      for(i=0;i<list.length;i++){
          if(this.state.poiSelected.id==list[i].id) selected=i;
      }
      this.alert.onAlert({title:strings.addToPOI, items:list,itemSelected:selected, buttons:[{text: strings.validate,onPress: (id,value) => {this.setState({poiSelected:this.state.listPOI[id], addToPOI:true}) }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })  
  


  }

  changeUsersMax(){

    if(this.state.addUsersMax==true) this.setState({addUsersMax:false})
    else {

              this.alert.onAlert({title:"Combien d'utilisateurs maximum ?", textInput:{placeholder:"50",text:"",sizeMultiplicator:2,keyboardType:"numeric"},buttons:[
                  {text: strings.validate,onPress: (value) => {
                   console.log(value)
                   value=parseInt(value);
                   if(value.toString()!="NaN" && value>0) this.setState({addUsersMax:true, usersMax:value   })

                  }},
                  ],})


    }



   
  }





  componentDidMount() {

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
    if(params.extraEUMode==true) this.setState({extraEUMode:true})

    if(params.data!=undefined){
      this.setState({codeName:params.data.codeName,codeID:params.data.id,users:params.data.users})
      
      if(params.data.dateStart!=undefined && params.data.dateStart!=""){
        var date=new Date(params.data.dateStart)
        date=((date.getDate()<10)?"0":"")+date.getDate()+"/"+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+"/"+date.getFullYear()+" - "+((date.getHours()<10)?"0":"")+date.getHours()+":"+((date.getMinutes()<10)?"0":"")+date.getMinutes()
        this.setState({dateStart:params.data.dateStart,dateStartTxt:date})
      } 
      if(params.data.dateEnd!=undefined && params.data.dateEnd!=""){
        var date=new Date(params.data.dateEnd)
        date=((date.getDate()<10)?"0":"")+date.getDate()+"/"+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+"/"+date.getFullYear()+" - "+((date.getHours()<10)?"0":"")+date.getHours()+":"+((date.getMinutes()<10)?"0":"")+date.getMinutes()
        this.setState({dateEnd:params.data.dateEnd,dateEndTxt:date})
      }
      if(params.data.poiSelected!=undefined && params.data.poiSelected!=""){
        this.setState({addToPOI:true,poiSelected:params.data.poiSelected});
      }

      if(params.data.usersMax!=undefined && params.data.usersMax!=""){
        this.setState({addUsersMax:true,usersMax:params.data.usersMax});
      }
      if(params.data.takeCost==true){ this.setState({takeCost:true}); }


      
      if(params.data.validMoreUser!=undefined && params.data.validMoreUser!="") this.setState({validMoreUser:params.data.validMoreUser})

      if(params.data.onlyProvision!=undefined && params.data.onlyProvision!="") this.setState({onlyProvision:params.data.onlyProvision})

      if(params.data.amount==true){
        this.setState({amount:true,codeFrom: ((params.data.money.position=="left")?(params.data.money.symbole+" "):"")+params.data.codeFrom+((params.data.money.position=="right")?(params.data.money.symbole):""), money:params.data.money,codeReduc: ((params.data.money.position=="left")?(params.data.money.symbole+" "):"")+params.data.codeReduc+((params.data.money.position=="right")?(params.data.money.symbole):"")     })
      }
      else this.setState({codeReduc:params.data.codeReduc+"%"})




      
      



    }
    get(child(ref(getDatabase(firebase)), '/favorite')).then(value2 => {
                var val2 = value2.val();
                var listPOI=[];
                if(val2!=null){
                  var lil=Object.keys(val2);
                  for (var i=0; i<lil.length;i++){
                      listPOI.push({id:lil[i], text:val2[lil[i]].userDescription})
                  }
                  this.setState({listPOI:listPOI})
                }
                


      })

  }



render() {
    return (
      <StyleContext.Consumer>{style => 
      <ScrollView style={{ marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor }}>

        {(Platform.OS=="web" && this.props.params!=undefined && this.props.params.goBack!=undefined) && <MavNavigationMinimize  clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={ strings.promoCode } />}



        <MavInfoBubble type={"menu"} icon={(style.mode=="dark")?require('../assets/promo_white.png'):require('../assets/promo_black.png')} title={strings.majoPromoCodeWork} subTitle={strings.promoExplication} style={{marginTop:10,}} />


        {true && <MavSelector style={{marginBottom:10}} selected={(this.state.amount==false)?0:1 } textLeft={strings.percent} textRight={strings.montant} onChange={(value)=>{  this.changeType() }}/>}

        {(this.state.amount==true && this.state.extraEUMode===true)  && <TextPresentation editable={false} disabledText={false} text={this.state.money[strings.idLangue]} chooseMoney={true} money={this.state.money} flagMoney={this.state.money.flag} textExplication={strings.moneySelected} onChangeMoney ={(info)=>{ this.changeAllPrice(info); }}  />}


        <TextPresentation style={{marginTop:0}} text={this.state.codeName} textExplication={strings.promoCodeName} placeholder={"HAPPY"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({codeName:text})}}  />

        <TextPresentation style={{marginTop:0}} keyboardType={"numeric"} text={this.state.codeReduc} textExplication={strings.promoCodePercent} placeholder={ (this.state.amount==false)?"10%" : ( ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"") )     } onChangeText={(text)=>{var find=(this.state.amount==false)?"%":this.state.money.symbole;

          timer.setTimeout(generateUUID(), () => {
              var test=this.state.codeReduc.indexOf(find);
              if(test==-1 && this.state.codeReduc!="") {
                var car=this.state.codeReduc;
                if(this.state.amount==false) car+="%"; 
                else car = ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car+((this.state.money.position=="right")?(this.state.money.symbole):"")
                this.setState({codeReduc:car}); 
              }
          }, 1000);
          text=text.toString().replace(find,'')
          text=text.toString().replace(",",'.')
          this.setState({ codeReduc:text });
        }}  />

         {this.state.amount==true && <TextPresentation style={{marginTop:0}} keyboardType={"numeric"} text={this.state.codeFrom} textExplication={strings.validFrom} placeholder={  ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"50"+((this.state.money.position=="right")?(this.state.money.symbole):"" )       } onChangeText={(text)=>{

          var find=this.state.money.symbole;
          timer.setTimeout(generateUUID(), () => {
              var test=this.state.codeFrom.indexOf(find);
              if(test==-1 && this.state.codeFrom!="") {
                var car=this.state.codeFrom;
                car = ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car+((this.state.money.position=="right")?(this.state.money.symbole):"")
                this.setState({codeFrom:car}); 
              }
          }, 1000);
          text=text.toString().replace(find,'')
          text=text.toString().replace(",",'.')
          this.setState({ codeFrom:text });
        }}  />}





        <TextPresentation reduceTextSizeOnOff={true} disableAnimation={true} editable={false} onoff={(this.state.dateStart!="")?true:false} text={(this.state.dateStart!="")?(strings.startDate):strings.defineStartDate} textMini={(this.state.dateStart!="")?(this.state.dateStartTxt):undefined} onOnOff={()=>{ if(this.state.dateStart!="") this.setState({dateStart:""}); else {this.setState({dateSelected:"start"}); this.selectDate.onPicker() } }}/>
        <TextPresentation reduceTextSizeOnOff={true} disableAnimation={true} editable={false} onoff={(this.state.dateEnd!="")?true:false} text={(this.state.dateEnd!="")?(strings.endDate):strings.defineEndDate} textMini={(this.state.dateEnd!="")?(this.state.dateEndTxt):undefined} onOnOff={()=>{ if(this.state.dateEnd!="") this.setState({dateEnd:""}); else {this.setState({dateSelected:"end"}); this.selectDate.onPicker() } }}/>
        <TextPresentation reduceTextSizeOnOff={true} disableAnimation={true} editable={false} onoff={(this.state.addUsersMax==true)?true:false} text={strings.defineMaxUsePromoCode} onOffText={(this.state.addUsersMax==true)?this.state.usersMax:undefined} onOnOff={()=>{ this.changeUsersMax() }}/>

        <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.validMoreUser==true)?true:false} text={strings.promoValidMoreUser} onOnOff={()=>{ if(this.state.validMoreUser==true) this.setState({validMoreUser:false});else this.setState({validMoreUser:true}) }}/>
        
        <TextPresentation reduceTextSizeOnOff={true} textMini={strings.takeCostMoreInfo} editable={false} onoff={(this.state.takeCost==true)?true:false} text={strings.takeCost} onOnOff={()=>{ if(this.state.takeCost==true) this.setState({takeCost:false});else this.setState({takeCost:true}) }}/>
        
        {this.state.onlyProvision==false && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.addToPOI==true)?true:false} text={strings.addToPOI} textMini={(this.state.addToPOI===true)?this.state.poiSelected.text:undefined} onOnOff={()=>{ if(this.state.addToPOI==true) this.setState({addToPOI:false}); else this.viewPoi() }}/>}
        
        {this.state.addToPOI==false && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.onlyProvision==true)?true:false} text={strings.onlyProvision} onOnOff={()=>{ if(this.state.onlyProvision==true) this.setState({onlyProvision:false}); else this.setState({onlyProvision:true}) }}/>}

        

        {this.state.codeID!="" && <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{this.state.users.length+" "+strings.codeUsedTimes}</Text>}
       
        {(this.state.codeID!="" && this.state.users.length>0) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.setState({viewList:!this.state.viewList})}}>
          <Text style={{marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*1,textAlign: "center",}}>{(this.state.viewList==true)?strings.promoCodeHideCustomers:strings.promoCodeViewCustomers}</Text>
        </TouchableHighlight>}
        
          {this.state.viewList==true && this.state.users.map((rowData) => (
              <Text style={{marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{rowData.clientName+" "+rowData.clientLastName}</Text>
          ))}


        <View style={{alignItems:"center",justifyContent:"center", marginTop:20,marginBottom:(this.state.codeID!="")?10:30}}>
          <MavButton text={strings.confirm} onlyButton={true} onPress={()=>{
            
            var find=(this.state.amount==false)?"%":this.state.money.symbole;
            var cr=this.state.codeReduc.toString().replace("find",'')
            cr=parseFloat(cr);
            if(this.state.codeName!="" && cr!="" && isNaN(cr)==false){

              if(cr>90 && this.state.amount==false) this.alert.onAlert({title:strings.promoCodeInvalid+" ( max : 90%)"}); 
              else {
                var id=(this.state.codeID!="")?this.state.codeID:generateUUID()
                const fireInfo={} 
                fireInfo['/promoCode/'+id+'/codeName']=this.state.codeName
                fireInfo['/promoCode/'+id+'/codeReduc']=cr
                fireInfo['/promoCode/'+id+'/dateStart']=this.state.dateStart
                fireInfo['/promoCode/'+id+'/dateEnd']=this.state.dateEnd
                fireInfo['/promoCode/'+id+'/amount']=(this.state.amount==true)?true:null
                
                if(this.state.amount==true){
                    var cf=this.state.codeFrom.toString().replace("find",''); 
                    if(cf!="") cf=parseFloat(cf);
                    fireInfo['/promoCode/'+id+'/codeFrom']=(cf!="" && isNaN(cf)==false)?cf:null;

                    if(this.state.extraEUMode==true && this.state.money.id!="eu") fireInfo['/promoCode/'+id+'/moneyID']=this.state.money.id; 
                }

                

                if(this.state.addUsersMax==true) fireInfo['/promoCode/'+id+'/usersMax']=this.state.usersMax;
                else fireInfo['/promoCode/'+id+'/usersMax']=null

                if(this.state.takeCost==true) fireInfo['/promoCode/'+id+'/takeCost']=true; else fireInfo['/promoCode/'+id+'/takeCost']=null; 

                if(this.state.addToPOI==true) fireInfo['/promoCode/'+id+'/poiSelected']=this.state.poiSelected;
                else fireInfo['/promoCode/'+id+'/poiSelected']=null

                fireInfo['/promoCode/'+id+'/validMoreUser']=(this.state.validMoreUser==true)?this.state.validMoreUser:null;
                fireInfo['/promoCode/'+id+'/onlyProvision']=(this.state.onlyProvision==true)?this.state.onlyProvision:null;

                
                
                update(ref(getDatabase(firebase)), fireInfo)


                var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
                if(params.updateCode!=null) params.updateCode();
                
                if(this.props.navigation!=undefined) this.props.navigation.goBack(); 
                else params.goBack();

             }
            }
            else{
              this.alert.onAlert({title:strings.promoCodeInvalid});
            }

            

         }}/>
         </View>



         {this.state.codeID!="" && <View style={{alignItems:"center",justifyContent:"center", marginBottom:30}}>
         
         <MavButton text={strings.remove} strokeMode={true} color={style.color1} onlyButton={true}  onPress={()=>{  
            const fireInfo={} 
            fireInfo['/promoCode/' + this.state.codeID]=null
            update(ref(getDatabase(firebase)), fireInfo)

            var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
            if(params.updateCode!=null) params.updateCode();
            
            if(this.props.navigation!=undefined) this.props.navigation.goBack(); 
              else params.goBack();
              
          }}/></View>}


          



          <MavSelectDate 
              minDate={(this.state.roundTrip==true && this.state.date!="")?this.state.date:this.state.dateMin}
              maxDate={(this.state.roundTrip==true && this.state.date2!="")?this.state.date2:"2100-01-01"} 

              onDateChange={dateTxt => { 

                var date=dateTxt;
                date=new Date(date[6]+date[7]+date[8]+date[9]+'-'+date[3]+date[4]+'-'+date[0]+date[1]+'T'+date[13]+date[14]+':'+date[16]+date[17]+':00')
                date=Date.parse(date)+date.getTimezoneOffset()*60000;
                if(this.state.dateSelected=="start") this.setState({dateStart:date,dateStartTxt:dateTxt})
                else this.setState({dateEnd:date,dateEndTxt:dateTxt})


              }}

            ref={select => {this.selectDate = select;}}/>




          







        <MavAlert ref={alert => {this.alert = alert;}}/>
      </ScrollView>
      }</StyleContext.Consumer>
    );
  }
}



export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
