import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  Keyboard,
  TouchableHighlight,
  Image,
  Picker,
  Platform,
  ScrollView,

} from 'react-native';

import {StyleContext} from '../styleC';


export default class MavNavigationMinimize extends Component {
constructor(props) { 
    super(props);

      this.state = {
        modalVisible: false,

        
      };

  }

  render() {
    return (
    <StyleContext.Consumer>{style => 

    <View> 

            <View style={{height:50*( (this.props.maxiMode==true)?2:1), backgroundColor:style.headerBackgroundColor, flexDirection: 'row', justifyContent:"space-between",alignItems:"center"}}>

              {this.props.headerLeft!=undefined && <View style={{flex:1}}>{this.props.headerLeft}</View>}
              {this.props.left!=undefined && <TouchableHighlight style={{flex:1,}} underlayColor={"#FFFFFF00"} onPress={() => {if(this.props.clickLeft!=undefined) this.props.clickLeft()  }}>
                  <Text style={{fontSize: style.textInputFontSize*0.9*( (this.props.maxiMode==true)?( (Dimensions.get('window').width>1000)?2:1.8):1),color:style.headerColor, textAlign:"center",}}>{(this.props.left!=undefined)?this.props.left:""}</Text>
              </TouchableHighlight>}

              {(this.props.headerLeft==undefined && this.props.left==undefined) && <View style={{flex:1}}/>}

              
              <Text style={{flex:2.5, fontSize: style.textInputFontSize*1.2*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),color:style.headerColor,fontWeight: 'bold', textAlign:"center"}} >{(this.props.title!=undefined)?this.props.title:""}</Text>
              
              {this.props.headerRight!=undefined && <View style={{flex:1,backgroundColor:"#FF0000"}}>{this.props.headerRight}</View>}
              {this.props.right!=undefined && <TouchableHighlight style={{flex:1,}} underlayColor={"#FFFFFF00"} onPress={() => {if(this.props.clickRight!=undefined) this.props.clickRight()  }}>
                  <Text style={{fontSize: style.textInputFontSize*0.9*( (this.props.maxiMode==true)?2:1),color:style.headerColor, textAlign:"center",}}>{(this.props.right!=undefined)?this.props.right:""}</Text>
              </TouchableHighlight>}

              {(this.props.headerRight==undefined && this.props.right==undefined) && <View style={{flex:1}}/>}


            </View>
            
            <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,}}/>

            
          
          </View>

          }</StyleContext.Consumer>

    )}

}

    

