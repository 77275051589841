import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  ActivityIndicator,
  Platform,
  ScrollView,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';


export default class MavSearch extends Component {
constructor(props) { 
    super(props);


    console.log(props)

    
    this.state = {
      
      test:false,
    
    }
  }
  render() {

    

    return (
      <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>


<View style={{borderRadius: 20,borderWidth: 1, backgroundColor:(this.props.background!=undefined)?this.props.background:undefined, borderColor:(this.props.highContrast==true)?style.textInputColor:style.backgroundColorContrast, marginLeft:(this.props.noMargin==true)?undefined:"10%", marginRight:(this.props.noMargin==true)?undefined:"10%", height:40, flexDirection:"row",alignItems:"center"}}>
                <Image source={(this.props.highContrast==true)?( (style.mode=="dark")?require('../assets/search_light.png'):require('../assets/search_dark.png') ):((style.mode=="dark")?require('../assets/search_dark.png'):require('../assets/search_light.png'))} style={{marginLeft:10,height:25,width:25,resizeMode:"contain"}}/>
                <TextInput
                placeholder={(this.props.searchExplication!=undefined)?this.props.searchExplication:(strings.search+" ...")}
                placeholderTextColor={style.placeholderTextInputColor}
                autoCorrect={false}
                multiline={false}
                numberOfLines={1}
                underlineColorAndroid="rgba(0,0,0,0)"
                style={{marginLeft:10,color:style.textInputColor,fontSize: style.textInputFontSize,textAlign:"left",height:style.textInputHeight*0.8, flex:1  }}
                onChangeText={text => { if(this.props.onSearch!=undefined) this.props.onSearch(text)  }}
                value={this.props.search}
                />

                {this.props.loading==true && <View style={{backgroundColor:style.backgroundColor, height:style.textInputHeight*0.8, width:10, right:10}} />}
                {this.props.loading==true && <ActivityIndicator style={{right:15}} size="small" color={style.color1} />}

                {this.props.filter==true && <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { if(this.props.onFilter!=undefined) this.props.onFilter() }} style={{backgroundColor:style.color1, alignItems:"center", justifyContent:"center", height:style.textInputHeight, width:style.textInputHeight, borderWidth:1, borderColor:style.backgroundColorContrast, borderRadius:style.textInputHeight*0.5}} >
                    <Image source={(style.mode=="dark" && true)?require('../assets/filter_black.png'):require('../assets/filter_white.png')} style={{height:style.textInputHeight*0.6,width:style.textInputHeight*0.6,resizeMode:"contain"}}/>
                </TouchableHighlight>}


              </View>
            
        </View>

         }</StyleContext.Consumer>


    	)}

    }

