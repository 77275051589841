import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  Keyboard,
  TouchableHighlight,
  Clipboard,
  Linking,
  Image,
  ActivityIndicator,
  Picker,
  Platform,
  ScrollView,
} from 'react-native';
import {StyleContext,styleInfo} from '../styleC'; 
import info from '../info';
import strings from '../strings';
const timer = require('react-native-timer');
import MavAlert from './mavAlert';
import Modal from './modal';
import MavButton from './mavButton';
import AddCreditCard from './addCreditCard';
import TextPresentation from './textPresentation';

import firebase from './firebase';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword,sendPasswordResetEmail, signInWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential, updatePassword, deleteUser} from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child} from "firebase/database";
import { getStorage, ref as refStorage, uploadBytes, getDownloadURL } from "firebase/storage";






export default class MavSelectDate extends Component {


  state = {
    alreadyStart:false,
    modalVisible: false,
    waitingUserCard:true,noCardDetected:false,
    mode:"customer",
    remainingTime:0,
    paymentValidate:false,
    paymentError:false,
    paymentWait:false, 
    amount:"",
    money:{},
    
  };


  onInfo(infoL){
    console.log("on info lancé et "+this.state.modalVisible)
    if(this.state.modalVisible==false){
        var st={modalVisible:true,waitingUserCard:true,paymentError:false, waitingUserCard:false,paymentValidate:false, amount:infoL.amount,raceID:infoL.raceID, money:infoL.currency,noCardDetected:false,mode:(infoL.driver==true)?"driver":"customer"  }
        if(infoL.driver==true) st.clientID=infoL.clientID;
        else st.driverID=infoL.driverID;
        this.setState(st,() =>{ if(infoL.driver==true) this.restart();else this.getUserCards() })
  }


    

  }

  close(){
    console.log("je lance close ")
    this.setState({modalVisible:false})
  }

  restart(){
      var st={}
      
      
      var maxDate=new Date();
      maxDate.setMinutes(maxDate.getMinutes()+10);
      st.remainingTime=599; 
      timer.setInterval("remainingTime", () => { var remainingTime=this.state.remainingTime; remainingTime-=1; this.setState({remainingTime:remainingTime}); if(remainingTime==0 || this.state.paymentValidate==true) timer.clearInterval("remainingTime"); }, 1000)
      this.setState(st)

      var data={date:Date.parse(maxDate),amount:this.state.amount, raceID:this.state.raceID,customer:true}
      if(this.state.money.id!="eu") data.currency=this.state.money.id;

      onValue(ref(getDatabase(firebase), '/race/'+this.state.raceID+"/chargeAfterRace"), (value) => {
          var val=value.val();
          if(val!=null){
            this.setState({paymentValidate:true})
          }


      })

      const fireInfo={} 
      fireInfo["users/"+this.state.clientID+"/waitNowPayment/"+getAuth(firebase).currentUser.uid]=data
      update(ref(getDatabase(firebase)), fireInfo)

  }

  cancel(){
    const fireInfo={} 
    fireInfo["users/"+this.state.clientID+"/waitNowPayment/"+getAuth(firebase).currentUser.uid+"/removed"]=true
    update(ref(getDatabase(firebase)), fireInfo)

    this.setState({modalVisible:false})
  }



  getUserCards(){
    this.setState({waitingUserCard:true})

    getAuth(firebase).currentUser.getIdToken().then(token => {
              var body={
                  userID:getAuth(firebase).currentUser.uid,
                  bundleIdentifer:info.bundleIdentifer,
                  token:token,
                  bundleAdress:info.firebaseProjectID,
                  version:info.appliVersion,
              }

          get(child(ref(getDatabase(firebase)), '/users/'+getAuth(firebase).currentUser.uid+"/email")).then(snapshot => { 
              var email=snapshot.val();
              body.email=email;
                console.log(" fetch userCard")
                console.log(JSON.stringify(body))
                fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getUserCards", {
                  body: JSON.stringify(body),method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {
                        var res=responseJson;
                        var i=0;
                        this.setState({waitingUserCard:false, noCardDetected:(responseJson==null || !(res.length>0) )?true:false    })
                        
                        for(i=0;i<res.length;i++){
                          res[i].text="*"+res[i].last4;
                          res[i].icon=(res[i].brand.toLowerCase()==="visa")?require("../assets/visa.png"):((res[i].brand.toLowerCase()==="mastercard")?require("../assets/mastercard.png"):((res[i].brand.toLowerCase()==="amex")?require("../assets/amex.png"):require("../assets/paymentCard.png")))
                          res[i].image=(res[i].brand.toLowerCase()==="visa")?require("../assets/visa.png"):((res[i].brand.toLowerCase()==="mastercard")?require("../assets/mastercard.png"):((res[i].brand.toLowerCase()==="amex")?require("../assets/amex.png"):require("../assets/paymentCard.png")))
                        }
                        

                      
                      console.log(res)

                      this.setState({cardList:res, cardSelected:res[0], cardSelectedKey:0});

                    }).catch((err) => { console.log("error getUserCards"); console.log(err); timer.setTimeout("getUserCards", () => { this.getUserCards();  }, 2000);   })

            });
        })
}



validatePayment(){


  this.setState({paymentWait:true})


  getAuth(firebase).currentUser.getIdToken().then(token => {
              var body={
                  currency:this.state.money.stripeCode, 
                  moneyID:this.state.money.id,
                  userID:getAuth(firebase).currentUser.uid,
                  driverID:this.state.driverID,
                  bundleIdentifer:info.bundleIdentifer,
                  price:this.state.amount,
                  source:this.state.cardSelected.id,
                  raceID:this.state.raceID,
                  token:token,
                  bundleAdress:info.firebaseProjectID,
                  version:info.appliVersion,
                  afterRace:true, 
                  withURL:true,
              }

              fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getProvision", {
                body: JSON.stringify(body),
                method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {
                  
                  var rep=responseJson;
                  console.log("on a recu la réponse")
                  console.log(rep)
                  this.setState({paymentWait:false})
                  
                  if(rep.payment==="ok"){
                    /*je close */
                    this.setState({paymentValidate:true})
                    timer.setTimeout("valid", () => { this.close() }, 3000)
                  }
                  else if(rep.payment==="requires_action"){
                      console.log("je suis dans requires_action")
                      this.close()
                  }
                  else{
                    this.setState({paymentError:true})
                     timer.setTimeout("paymentError", () => { this.setState({paymentError:false}) }, 3000)

                    

                  }

          }).catch((err) => { console.log("error validatePayment"); console.log(err); timer.setTimeout("validatePayment", () => { this.validatePayment();  }, 2000);   })


    })

}

 

  componentDidMount () {

    console.log("after RacePay mount")
        
  }





  render() {
    


    return (
    <StyleContext.Consumer>{style => 
    <View style={{flex:1}}>



     <Modal
          animationType="none"
          transparent={true}
          visible={this.state.modalVisible}>
           <View style={{flex:1,backgroundColor:style.backgroundColor,}} >

          
          
          {this.state.mode=="customer" && <View style={{backgroundColor:style.backgroundColor, height:Dimensions.get('window').height*0.5,width:Dimensions.get('window').width,position:"absolute",alignItems:'center'}}>
              
              

              <View style={{position:"absolute",width:Dimensions.get('window').width,alignItems:"center"}}>

              <Image source={require("../assets/login_logo.png")} style={{marginTop:50,height:Dimensions.get('window').height*0.1,resizeMode:"contain"}}/>
            
              <Text style={{marginTop:20, marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', textAlign:"center", color:style.textInputColor,fontSize: style.textInputFontSize*1.2}}>{strings.thanksFinishPayRace}</Text>
              <Text style={{marginTop:20, marginLeft:"5%", marginRight:"5%",textAlign:"center",color:style.textInputColor,fontSize: style.textInputFontSize*1.5}}>{strings.amountPaid}</Text>
              <Text style={{marginLeft:"5%", marginRight:"5%",textAlign:"center",fontWeight: 'bold',color:style.textInputColor,fontSize: style.textInputFontSize*3}}>{((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.amount+((this.state.money.position=="right")?(this.state.money.symbole):"")}</Text>

              {this.state.waitingUserCard==true && <ActivityIndicator style={{marginTop:50}} size="large" color={style.color1} />} 
              {this.state.waitingUserCard==true && <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginTop:5,color:style.textInputColor}}>{strings.thankWaiting}</Text>}
              


              {(this.state.waitingUserCard==false && this.state.cardSelected!=undefined && this.state.cardList.length>1) && <View style={{width:Dimensions.get('window').width }}>
                


                
                <TextPresentation style={{marginTop:10,width:Dimensions.get('window').width}} textExplication={strings.paymentCb} editable={false} text={this.state.cardSelected.text} icon={this.state.cardSelected.icon} >

                      
                      <View style={{height:10}}/>
                      {this.state.cardList.map((rowData,key) => (
                          <TouchableHighlight underlayColor={"rgba(0,0,0,0.0)"} style={{marginBottom:10,width:"100%", alignItems:"center"}} onPress={() => {if(this.state.paymentWait!=true) this.setState({cardSelectedKey:key, cardSelected:rowData}) }}>
                            <View style={{width:Dimensions.get('window').width*0.68, alignItems:"center", flexDirection: 'row', height: style.buttonHeight,borderRadius: style.buttonRadius,borderColor:(this.state.cardSelectedKey==key)?(style.color1):(style.textInputLineColor)   ,borderWidth:1,}}>
                              <Image source={rowData.icon} style={{ height:20,width:20,resizeMode:"contain",marginLeft:20,  }}/>
                               <View style={{justifyContent:"center", marginLeft:20, width:Dimensions.get('window').width*0.5, }}>
                                  <Text allowFontScaling={false} style={{color: style.textInputColor,fontSize: style.buttonFontSize,textAlign: 'left',}}>{rowData.text} </Text>
                               </View>
                            </View>
                          </TouchableHighlight>

                          
                      ))}
                  </TextPresentation>
                

              </View>}


              {(this.state.waitingUserCard==false && this.state.cardSelected!=undefined && this.state.cardList.length==1) && <View style={{width:Dimensions.get('window').width}}>
                <TextPresentation style={{marginTop:10,width:Dimensions.get('window').width}} textExplication={strings.paymentCb} editable={false} text={this.state.cardSelected.text} enableValidate={true} icon={this.state.cardSelected.icon} />
              </View>}


              {(this.state.waitingUserCard==false) && <View style={{width:Dimensions.get('window').width}}>
                    <AddCreditCard style={{width:Dimensions.get('window').width}} disable={(this.state.paymentWait==true)?true:false} newCardAdded={() => { this.getUserCards() }} />
              </View>}

              {(this.state.waitingUserCard==false && this.state.paymentValidate!=true &&  this.state.cardSelected!=undefined && this.state.cardList.length>0) && <View style={{alignItems:"center",justifyContent:"center", marginTop:50,marginBottom:50}}>
                  {this.state.paymentWait!=true && <MavButton text={strings.paid} onlyButton={true} onPress={()=>{ this.validatePayment()}}/>}
                  {this.state.paymentWait==true && <ActivityIndicator style={{marginTop:0}} size="large" color={style.color1} />}
              </View>}

              {this.state.paymentValidate==true && <Image source={require('../assets/check_mark.png')} style={{ width: 100, height: 100,marginTop:40}}/>}
              {this.state.paymentValidate==true && <Text style={{marginLeft:"5%", marginRight:"5%",textAlign:"center",color:style.colorValidate,fontSize: style.textInputFontSize*1.5}}>{strings.paymentValidate}</Text>}

              {this.state.paymentError==true && <Image source={require('../assets/error_mark.png')} style={{ width: 100, height: 100,marginTop:40}}/>}
              {this.state.paymentError==true && <Text style={{marginLeft:"5%", marginRight:"5%",textAlign:"center",color:style.colorNotification,fontSize: style.textInputFontSize*1.5}}>{strings.errorCard}</Text>}



              </View>


             


          </View>}


          {this.state.mode=="driver" && <View style={{backgroundColor:style.backgroundColor, height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",alignItems:'center'}}>
              <Image source={require("../assets/login_logo.png")} style={{marginTop:50,height:Dimensions.get('window').height*0.1,resizeMode:"contain"}}/>
             
              <Text style={{marginTop:20, marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', textAlign:"center", color:style.textInputColor,fontSize: style.textInputFontSize*1.2}}>{strings.paidPhoneWait}</Text>
              <Text style={{marginTop:20, marginLeft:"5%", marginRight:"5%",textAlign:"center",color:style.textInputColor,fontSize: style.textInputFontSize*1.5}}>{strings.amountPaid}</Text>
              <Text style={{marginLeft:"5%", marginRight:"5%",textAlign:"center",fontWeight: 'bold',color:style.textInputColor,fontSize: style.textInputFontSize*3}}>{((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.amount+((this.state.money.position=="right")?(this.state.money.symbole):"")}</Text>

              {this.state.paymentValidate==false && <Text style={{marginTop:20, marginLeft:"5%", marginRight:"5%",textAlign:"center",color:style.textInputColor,fontSize: style.textInputFontSize*1.5}}>{strings.remainingTime}</Text>}
              {this.state.paymentValidate==false && <Text style={{marginLeft:"5%", marginRight:"5%",textAlign:"center",color:style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{strings.remainingTimeMoreInfo}</Text>}
              {this.state.paymentValidate==false && <Text style={{marginLeft:"5%", marginRight:"5%",textAlign:"center",fontWeight: 'bold',color:(this.state.remainingTime<10)?style.colorNotification:style.textInputColor,fontSize: style.textInputFontSize*3}}>{"0"+(Math.trunc(this.state.remainingTime/60))+":"+ (((this.state.remainingTime%60)<10)?"0":"")+   (this.state.remainingTime%60)  }</Text>}


              {(this.state.remainingTime==0 && this.state.paymentValidate==false) && <View style={{alignItems:"center",justifyContent:"center", marginTop:50,flexDirection:"row"}}>
                  <MavButton text={strings.retryNowQuestion} onlyButton={true} onPress={()=>{ this.restart() }}/>
              </View>}

              {this.state.paymentValidate==false && <View style={{alignItems:"center",justifyContent:"center", marginTop:(this.state.remainingTime==0)?20:50,flexDirection:"row"}}>
                  <MavButton text={strings.cancel} strokeMode={true} onlyButton={true} onPress={()=>{ this.cancel() }}/>
                </View>}



              {this.state.paymentValidate==true && <Image source={require('../assets/check_mark.png')} style={{ width: 100, height: 100,marginTop:40}}/>}
              {this.state.paymentValidate==true && <Text style={{marginLeft:"5%", marginRight:"5%",textAlign:"center",color:style.colorValidate,fontSize: style.textInputFontSize*1.5}}>{strings.paymentValidate}</Text>}

              {(this.state.paymentValidate==true) && <View style={{alignItems:"center",justifyContent:"center", marginTop:50,flexDirection:"row"}}>
                  <MavButton text={strings.generateBill} onlyButton={true} onPress={()=>{ this.close(); this.props.params.sendBill(); }}/>
              </View>}


          </View>}

          </View>


        </Modal>



        </View>

        }</StyleContext.Consumer>
      
      )}

    }


    



















