export default {
  "a75196f4-dc4d-48b0-82f4-ab29b837c75a":{name:"CTRIPS", type:"public"}, 
  "a086f7c1-e971-488d-869a-b88bd396b963":{name:"GETT", type:"public"}, 
  "1a7d2c31-a719-4246-b831-aa83a14d99e0":{name:"Allocab", type:"public"}, 
  "76908701-53bc-4368-9082-872e3d593dbf":{name:"MonTransport.com", type:"public"}, 
  "db18de45-f882-4515-8aaf-5d08a20268c0":{name:"POCO", type:"public"}, 
  "7313c397-6516-4129-888d-aa70d34949bd":{name:"CMAC", type:"public"}, 
  "a15f9caf-f3b3-4122-9d3f-2bead7f4adb8":{name:"Fidelia", type:"assistance"}, 
  "3c1c911e-6b2c-42de-b81f-18fe47867d44":{name:"Fidelia", type:"assistance"}, 
  "f9e134dc-3aa9-4ada-b572-cd65e463a862":{name:"IMA", type:"assistance"}, 
  "6fa00464-624e-462c-8024-242ef6cdd231":{name:"Mon Chauffeur SNCF", type:"public"}, 
  "d9189571-614c-4707-aea3-5c6ee5665c04":{name:"Booking", type:"public",  sla:{fr:"15 minutes d'attente en gare et 45 minutes à l'aeroport"}, }, 
  "8d076127-1fc7-4856-bba2-fe3bbdf0c64b":{name:"Accor", type:"public"}, 
  "5820b15a-4462-440a-8d6a-c7c8acbb1145":{name:"Amadeus", type:"public"}, 
  "680c9a6f-565a-4ed4-be30-09d08a044549":{name:"Appart City", type:"public"}, 
  "e0d9fb48-7eef-4914-9814-fd45ffb65953":{name:"Costa", type:"public"}, 
  "96952eae-20cd-4e29-9d96-09992c48e587":{name:"Farechoice", type:"public"}, 
  "22145dbb-43ed-4249-a539-1a3c60df7c3f":{name:"Fareportal", type:"public"}, 
  "3ffb5874-db9a-4864-af58-71e1bd693f5c":{name:"Fatec", type:"public"}, 
  "a0cc354c-9697-45d2-9673-aca6548907c2":{name:"Hoppa", type:"public"}, 
  "d3455146-2b89-429e-9092-864e861a2c0c":{name:"Instant system", type:"public"}, 
  "6a7a57ec-821e-4663-9f89-3243049ee37b":{name:"Logis Hotel", type:"public"}, 
  "27c4db5a-bae8-4be9-b56e-b0dea823fb05":{name:"Lyko", type:"public"}, 
  "94aff6a0-ad0f-4dbe-a8de-df8b256f8415":{name:"Mobee travel", type:"public"}, 
  "92a364b0-76e3-4f30-9b28-f1ad6f9a2cd3":{name:"Mutuaide", type:"assistance"}, 
  "396e0898-5ae7-496f-b87d-61c13adf8d59":{name:"MyDriver Thalys", type:"public"}, 
  "26296065-0854-496f-b155-47e48543158b":{name:"SNCF MaaS", type:"public"}, 
  "26ecc30e-f841-449e-8747-3bfc05ed4ac9":{name:"Stranded Flight Solution", type:"public"}, 
  "3912c387-39f1-45f8-9009-51c77d1574e1":{name:"Taksee", type:"public"}, 
  "239d2c8e-2712-493a-926c-0a273ffa3968":{name:"Taxi Service", type:"public"}, 
  "206334ac-9a66-4852-8aab-47d62b8e7f46":{name:"Trainline", type:"public"}, 
  "a592dfb5-d896-43fc-949b-b6abca47d5bb":{name:"WebBeds", type:"public"}, 
  "23661866-6554-46bf-977e-21430a3e1f22":{name:"Test karhoo", type:"public"}, 
  "2489faf6-aa19-45c5-aa2b-6b9d9a061e76":{name:"Test karhoo", type:"public"}, 

}
 


  