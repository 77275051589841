import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  Dimensions,
  Image,
  Alert,
  Switch,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MavInfoBubble from './mavInfoBubble';

import MavAlert from './mavAlert';
const timer = require('react-native-timer');


export default class help extends Component {
  constructor(props) {
    super(props);

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;


    this.state = {
      mounted:true,
      hideHoursMajoration:false,
      noVAT:(params.noVAT!=undefined)?params.noVAT:false,
      adminMode:(params.adminMode!=undefined)?params.adminMode:false,
      viewCalcPrice:(params.viewCalcPrice!=undefined)?params.viewCalcPrice:true, 
      carList:params.carList,
      precision:(params.precision!=undefined)?params.precision:1,
      money:(params.money!=undefined)?params.money:{symbole:"€", position:"right"}
      

    };
  }

  componentDidMount() {
      console.log("Calcul detail mount")
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;

  }

  
  
  

  


  


  render() {
    return (
      <StyleContext.Consumer>{style => 
      <View style={{ backgroundColor: style.backgroundColor, flex: 1}}>

        <ScrollView style={{ flex: 1 }}>
              
           
            {this.state.carList.map(car => (
            <View>
                <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{car.name}</Text>
                {car.passagers!=undefined && <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{car.passagers+" "+((car.passagers>1)?strings.morePassager.toLowerCase():strings.onePassager.toLowerCase()) +((car.luggageMax!=undefined)?(" - "+car.luggageMax+" "+((car.luggageMax>1)?strings.baggageMax.toLowerCase():strings.oneBaggageMax.toLowerCase())    ) :"")        }</Text>}


                {(car.explicationPricing!=undefined && car.explicationPricing.tarifName!=undefined && this.state.adminMode==true) && <Text style={{marginBottom:(car.explicationPricing.taxiMode==true)?0:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{((car.explicationPricing.type=="classic")?( (car.explicationPricing.taxiMode!=true)?strings.zone:strings.zoneTaxi ):((car.explicationPricing.taxiMode!=true)?strings.forfait:strings.forfaitTaxi )   )+" : "+car.explicationPricing.tarifName }</Text>}
                {(car.explicationPricing!=undefined && car.explicationPricing.taxiMode==true) && <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",marginBottom:10,}}>{strings.duration+" : "+((Math.round(car.explicationPricing.duration*this.state.precision)/this.state.precision) )+" min"        }</Text>}

                {(car.explicationPricing!=undefined && car.explicationPricing.type=="provision" && this.state.adminMode==true) && <Text style={{marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.provision}</Text>}
                
                {(car.explicationPricing!=undefined && car.explicationPricing.type=="provision")   &&  Object.keys(car.explicationPricing).map(elem => (
                    <View>
                    {(elem!="type" && car.explicationPricing[elem]>0) && <MavTextInput style={{marginTop:0}}  textExplication={(elem=="other")?strings.partialHour:(strings.hour+" n°"+elem)} disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.explicationPricing[elem]+((this.state.money.position=="right")?(this.state.money.symbole):"")}  />}
                    </View>

                    ))}

                {(this.state.adminMode===true && this.state.viewCalcPrice==false) && <MavInfoBubble type={"warning"}  title={strings.viewCalcPrice} subTitle={strings.onlyAdminViewCalcPrice} style={{marginBottom:10,}} />}


                {(car.base!=undefined && car.base>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.priceBase} disabledText={true} textRight={car.base+"€" }  />}
                {(car.minute!=undefined && car.minute>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.priceMinute} disabledText={true} textRight={car.minute+"€"}  />}
                {(car.km!=undefined && car.km>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.priceKm} disabledText={true} textRight={car.km+"€"}  />}
                {(car.minPrice!=undefined && car.minPrice>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.priceMin} disabledText={true} textRight={car.minPrice+"€"}  />}





                {car.explicationPricing!=undefined && <View>

                  {( car.explicationPricing.base!=undefined && car.explicationPricing.base>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.priceBase} disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.explicationPricing.base+((this.state.money.position=="right")?(this.state.money.symbole):"")  }  />}
                  
                  {(car.explicationPricing.taxiMode!=true && car.explicationPricing.distance!=undefined && car.explicationPricing.distance>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.priceKm} textExplicationMini={(Math.round(car.explicationPricing.distance*this.state.precision)/this.state.precision) +"km x "+car.explicationPricing.pricingDistance+this.state.money.symbole+"/km"} disabledText={true} textRight={"+ "+    ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round((car.explicationPricing.distance*car.explicationPricing.pricingDistance)*this.state.precision)/this.state.precision)+((this.state.money.position=="right")?(this.state.money.symbole):"")   }  />}
                  {(car.explicationPricing.taxiMode!=true && car.explicationPricing.duration!=undefined && car.explicationPricing.duration>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.priceMinute} textExplicationMini={(Math.round(car.explicationPricing.duration*this.state.precision)/this.state.precision) +"min x "+car.explicationPricing.pricingDuration+ this.state.money.symbole+"/min"} disabledText={true} textRight={"+ "+ ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round((car.explicationPricing.duration*car.explicationPricing.pricingDuration)*this.state.precision)/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"")    }  />}
                  
                  {(car.explicationPricing.kilometerMajoration!=undefined && car.explicationPricing.kilometerMajoration.price>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.majoKilometer} disabledText={true} textRight={   ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+((car.explicationPricing.kilometerMajoration.price>0)?"+":"")+ (Math.round(car.explicationPricing.kilometerMajoration.price*this.state.precision)/this.state.precision)+((this.state.money.position=="right")?(this.state.money.symbole):"")  +((this.state.noVAT==true)?"HT":"")      }  />}
                  
                       {(car.explicationPricing.taxiMode==true && car.explicationPricing.type=="classic") && <View>

                       {car.tarifBD!=true && <MavTextInput style={{marginTop:0}}  textExplication={strings.priceStationToClient} disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round( ( car.priceC*((this.state.noVAT==true)?(1+(info.tvaPercentClassic/100)):1) - (car.explicationPricing.tarifC*car.explicationPricing.distance) - car.explicationPricing.base   )  *this.state.precision )/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"")    }  />}

                       
                       {car.tarifBD!=true && <MavTextInput style={{marginTop:0}}  textExplication={strings.distance+" "+strings.tarif.toLowerCase()+" A"} textExplicationMini={  car.explicationPricing.distance+"km x "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+ car.explicationPricing.tarifA +((this.state.money.position=="right")?(this.state.money.symbole):"") } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round((car.explicationPricing.tarifA*car.explicationPricing.distance)*this.state.precision )/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"")     }  />}
                       {car.tarifBD==true && <MavTextInput style={{marginTop:0}}  textExplication={strings.distance+" "+strings.tarif.toLowerCase()+" B"} textExplicationMini={  car.explicationPricing.distance+"km x "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+ car.explicationPricing.tarifB +((this.state.money.position=="right")?(this.state.money.symbole):"") } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round((car.explicationPricing.tarifB*car.explicationPricing.distance)*this.state.precision )/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"")   }  />}
                       {car.tarifBD!=true && <MavTextInput style={{marginTop:0}}  textExplication={strings.distance+" "+strings.tarif.toLowerCase()+" C"} textExplicationMini={  car.explicationPricing.distance+"km x "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+ car.explicationPricing.tarifC +((this.state.money.position=="right")?(this.state.money.symbole):"") } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round((car.explicationPricing.tarifC*car.explicationPricing.distance)*this.state.precision )/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"") }  />}
                       {car.tarifBD==true && <MavTextInput style={{marginTop:0}}  textExplication={strings.distance+" "+strings.tarif.toLowerCase()+" D"} textExplicationMini={  car.explicationPricing.distance+"km x "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+ car.explicationPricing.tarifD +((this.state.money.position=="right")?(this.state.money.symbole):"") } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round((car.explicationPricing.tarifD*car.explicationPricing.distance)*this.state.precision )/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"")  }  />}



                       {car.tarifBD!=true && <MavTextInput style={{marginTop:0}}  textExplicationBold={true} textExplication={strings.total+" "+strings.tarif.toLowerCase()+" A"} textExplicationMini={strings.base+" + "+strings.priceStationToClient+" + "+strings.distance+"*2" } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round(car.priceA*this.state.precision)/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"")   }  />}
                       {car.tarifBD==true && <MavTextInput style={{marginTop:0}}  textExplicationBold={true} textExplication={strings.total+" "+strings.tarif.toLowerCase()+" B"} textExplicationMini={strings.base+" + "+strings.priceStationToClient+" + "+strings.distance+"*2" } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round(car.priceB*this.state.precision)/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"")   }  />}
                       {car.tarifBD!=true && <MavTextInput style={{marginTop:0}}  textExplicationBold={true} textExplication={strings.total+" "+strings.tarif.toLowerCase()+" C"} textExplicationMini={strings.base+" + "+strings.priceStationToClient+" + "+strings.distance} disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round(car.priceC*this.state.precision)/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"")   }  />}
                       {car.tarifBD==true && <MavTextInput style={{marginTop:0}}  textExplicationBold={true} textExplication={strings.total+" "+strings.tarif.toLowerCase()+" D"} textExplicationMini={strings.base+" + "+strings.priceStationToClient+" + "+strings.distance} disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round(car.priceD*this.state.precision)/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"")   }  />}


                       {(car.explicationPricing.immediate==true && car.explicationPricing.majoTaxiImmediate>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.majoTaxiImmediate} disabledText={true} textRight={"+"+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round(car.explicationPricing.majoTaxiImmediate*this.state.precision)/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"")   }  />}
                       {(car.explicationPricing.immediate!=true && car.explicationPricing.majoTaxiAvance>0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.majoTaxiAvance} disabledText={true} textRight={"+"+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round(car.explicationPricing.majoTaxiAvance*this.state.precision)/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"")  }  />}

                        <MavTextInput style={{marginTop:0}}  textExplication={strings.total} textExplicationBold={true} textExplicationMini={strings.priceMin+" : "+ ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.explicationPricing.minPrice+((this.state.money.position=="right")?(this.state.money.symbole):"")+((car.explicationPricing.coeffEnable==true )?(" | x"+(1-(car.explicationPricing.coeffPercent/100))+" - x"+(1+(car.explicationPricing.coeffPercent/100)) ):"") } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.realPrice.priceTotal+((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"")  }  />
                        <MavTextInput style={{marginTop:0}}  textExplication={strings.total+" "+strings.roundTrip.toLowerCase()} textExplicationBold={true} textExplicationMini={strings.priceMin+" : "+ ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.explicationPricing.minPrice+((this.state.money.position=="right")?(this.state.money.symbole):"")+((car.explicationPricing.coeffEnable==true )?(" | x"+(1-(car.explicationPricing.coeffPercent/100))+" - x"+(1+(car.explicationPricing.coeffPercent/100)) ):"") } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.realPriceIfReturn.priceTotal+((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"")   }  />


                  </View>}




                  {( car.explicationPricing.taxiMode!=true && (car.explicationPricing.minPrice!=undefined || (info.priceCoeffMin!=1 || info.priceCoeffMax!=1) ) ) && <MavTextInput style={{marginTop:0}}  textExplicationBold={true} textExplication={strings.subTotal} textExplicationMini={((car.explicationPricing.minPrice!=undefined || (info.priceCoeffMin!=1 || info.priceCoeffMax!=1) )? "(":"")  +   ((car.explicationPricing.minPrice!=undefined)?(  strings.priceMin+" : "+        ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.explicationPricing.minPrice+((this.state.money.position=="right")?(this.state.money.symbole):"")     ):"")    + ((info.priceCoeffMin!=1 || info.priceCoeffMax!=1)?( ( (car.explicationPricing.minPrice!=undefined)?" | ":"") + "x"+info.priceCoeffMin+" - x"+info.priceCoeffMax):"")    + ((car.explicationPricing.minPrice!=undefined || (info.priceCoeffMin!=1 || info.priceCoeffMax!=1) )? ")":"")    } disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.realPrice.priceBasic+((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"") }  />}

                  {(car.explicationPricing.taxiMode!=true && !(car.explicationPricing.minPrice!=undefined || (info.priceCoeffMin!=1 || info.priceCoeffMax!=1) ) ) && <MavTextInput style={{marginTop:0}}  textExplicationBold={true} textExplication={strings.subTotal}  disabledText={true} textRight={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.realPrice.priceBasic+((this.state.money.position=="right")?(this.state.money.symbole):"")}  />}


                  {(car.realPrice.majorationRealTime!=undefined && car.realPrice.majorationRealTime!=0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.majoTakingTraffic} disabledText={true} textRight={"+ "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.realPrice.majorationRealTime+((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"") }  />}
                  
                  {(car.realPrice.majorationDate!=undefined && car.realPrice.majorationDate.amount!=0) && <MavTextInput style={{marginTop:0}}  textExplication={((car.realPrice.majorationDate.name!="saturday")? ((car.realPrice.majorationDate.name!="sunday")? (  (car.realPrice.majorationDate.name!="night")? (  (car.realPrice.majorationDate.name!="ferie")? ( (car.realPrice.majorationDate.name!="morning")? ( (car.realPrice.majorationDate.name!="evening")? ( (car.realPrice.majorationDate.name!="dynamic")? ((car.realPrice.majorationDate.name!="completeNight")? ( (car.realPrice.majorationDate.name!="afternoon")?  ( (car.realPrice.majorationDate.name!="afternoon")? ((car.realPrice.majorationDate.name=="majoOther")?strings.majoHourFrequency :strings.majoHighAffluence) :  strings.majoAllPassagers) :  ((this.state.hideHoursMajoration==true)?strings.majoHourFrequency:  strings.majoAfternoon )       )  : ((this.state.hideHoursMajoration==true)?strings.majoHourFrequency:strings.majoNight )  )  : strings.majoDynamic   )  : ((this.state.hideHoursMajoration==true)?strings.majoHourFrequency:strings.majoEvening )  )  : ((this.state.hideHoursMajoration==true)?strings.majoHourFrequency:strings.majoMorning) ) : strings.majoFerie     ) : ((this.state.hideHoursMajoration==true)?strings.majoHourFrequency:strings.majoNight )  ) : strings.majoSunday ):strings.majoSaturday  )} disabledText={true} textRight={"+ "+ ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.realPrice.majorationDate.amount+((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"") }  />}
                  
                  {(car.provCom!=undefined && car.provCom!=0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.majoration+" "+strings.providerAffair.toLowerCase()} disabledText={true} textRight={"+ "+ ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.provCom+((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"") }  />}
                  

                  

                  {(car.realPrice.lastMinute!=undefined && car.realPrice.lastMinute!=0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.majoLastMinutes} disabledText={true} textRight={"+"+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round(car.realPrice.lastMinute*this.state.precision)/this.state.precision) +((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"") }  />}

                  {(car.realPrice.avanceOrder!=undefined && car.realPrice.avanceOrder!=0) && <MavTextInput style={{marginTop:0}}  textExplication={strings.majoAvancePercent} disabledText={true} textRight={"+"+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(Math.round(car.realPrice.avanceOrder*this.state.precision)/this.state.precision)  +((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"") }  />}

                   {(car.explicationPricing.taxiMode!=true ) && <MavTextInput style={{marginTop:0}} textExplicationBold={true} textExplication={strings.total} disabledText={true} textRight={ ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car.realPrice.priceTotal+((this.state.money.position=="right")?(this.state.money.symbole):"") +((this.state.noVAT==true)?"HT":"") }  />}


                </View>}

            </View>

            ))}
            

            

            
           



        </ScrollView>








        

          <MavAlert ref={alert => {this.alert = alert;}}/>

        
      </View>
      }</StyleContext.Consumer>
    );
  }
}


