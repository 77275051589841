import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  ScrollView,
  ActivityIndicator,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import countryList from '../countryList';
import moneyList from '../moneyList';

import Modal from './modal';


import MavButton from './mavButton';

export default class MavTextInput extends Component {
constructor(props) { 
    super(props);




    
    this.state = {
      countryVisible:false,
      moneyVisible:false,
      viewPassword:false,
      

    }
  }
  render() {

    let countryListTotal=countryList
    let moneyListTotal=moneyList

    return (
      <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>

    {((this.props.choosePhone==true || this.props.chooseMoney==true) && (this.state.countryVisible==true || this.state.moneyVisible==true) ) && <Modal
        animationType="slide"
        transparent={false}
        visible={(this.state.countryVisible==true || this.state.moneyVisible==true)}>
          <View style={{flex: 1,backgroundColor: style.backgroundColor,}}>
          <ScrollView style={{marginTop:30}}>

            

            {(this.props.choosePhone==true) && Object.keys(countryList).map((rowData, key)=>(

              <View>
                   <Text style={{fontSize: style.textInputFontSize*1.2, fontWeight: 'bold' ,color:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor), textAlign:"center",marginBottom:10, marginTop:10}}>{countryList[rowData][strings.idLangue]}</Text>

                    {countryListTotal[rowData].list.map((countryListregion, key)=>( 

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { console.log(countryListregion); if(this.props.onChangeCountry!=undefined)this.props.onChangeCountry(countryListregion); this.setState({countryVisible:false, })  }}>
                            <View style={{flexDirection:"row", alignItems:"center", marginTop:5,marginLeft:Dimensions.get('window').width*0.1, marginRight:Dimensions.get('window').width*0.1}}>
                              <Image source={countryListregion.flag} style={{ height: 30,width:40, marginRight:10, }}/>
                              <Text style={{fontSize: style.textInputFontSize,color:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor), width:(Dimensions.get('window').width*0.8)-140}}>{countryListregion[strings.idLangue]}</Text>
                              <Text style={{fontSize: style.textInputFontSize,color:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor), width:90, textAlign:"right"}}>{countryListregion.idd}</Text>
                            

                            </View>
                        </TouchableHighlight>

                        ))}

              </View>

              ))}

              {(this.props.chooseMoney==true) && moneyListTotal.map((countryListregion, key)=>( 

                <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { if(this.props.onChangeMoney!=undefined)this.props.onChangeMoney(countryListregion); this.setState({moneyVisible:false, })  }}>
                            <View style={{flexDirection:"row", alignItems:"center", marginTop:5,marginLeft:Dimensions.get('window').width*0.1, marginRight:Dimensions.get('window').width*0.1}}>
                              <Image source={countryListregion.flag} style={{ height: 30,width:40, marginRight:10, }}/>
                              <Text style={{fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),color:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor), width:(Dimensions.get('window').width*0.8)-140}}>{countryListregion[strings.idLangue]}</Text>
                              <Text style={{fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),color:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor), width:90, textAlign:"right"}}>{countryListregion.symbole}</Text>
                            

                            </View>
                        </TouchableHighlight>

              ))}




            
          </ScrollView>
          <View style={{height:80,position:"absolute",bottom:0,backgroundColor:style.backgroundColor, width:Dimensions.get('window').width}}>
              <MavButton strokeMode={true} text={strings.cancel} style={{top:10}} onPress={()=>{ this.setState({countryVisible:false,moneyVisible:false}) }}/>
          </View>

          </View>
    </Modal>}




        <View style={{shadowOpacity: (this.props.shadow==true)?0.3:0,shadowRadius: (this.props.shadow==true)?2:0,shadowOffset: {height: 2,width: 0},elevation: (this.props.shadow==true)?1:0,  backgroundColor:(this.props.backgroundColor!=undefined)?this.props.backgroundColor:style.backgroundColor,flexDirection:(this.props.multiline==true || (this.props.arrow==true && this.props.textRight==undefined && this.props.enableInfo!=true) )?"column":"row",marginLeft:(this.props.marginSize!=undefined)?this.props.marginSize:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.1),marginRight:(this.props.marginSize!=undefined)?this.props.marginSize:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.1),height:style.textInputHeight*((this.props.multiline==true)?3:1)*( (this.props.maxiMode==true || this.props.maxiMode=="partial")?((Dimensions.get('window').width>1000)?3:2.3):1)}}>
          




    









          <View style={{flex:1, flexDirection:"row",alignItems:"center",marginLeft:0, justifyContent:(this.props.enableRemove==true && this.props.multiline==true)?"space-between":undefined,}}> 

            {this.props.iconWait==true && <View style={{width: (this.props.iconClassicSmall==true)?30:40, height: (this.props.iconClassicSmall==true)?30:40, borderRadius: 20,marginRight:5,backgroundColor: (this.props.notAvailable==true)?style.textInputLineColor:style.color1,justifyContent: 'center',alignItems:"center"}}>   
                <ActivityIndicator size="small" color={(style.mode=="dark")?"#000000":"#FFFFFF"} />
            </View>}

            {(this.props.icon!=undefined && this.props.iconWait!=true) && <View style={{width: (this.props.iconClassicSmall==true)?30:40, height: (this.props.iconClassicSmall==true)?30:40, borderRadius: 20,marginRight:5,backgroundColor:(this.props.iconBackgroundColor!=undefined)?this.props.iconBackgroundColor: ((this.props.notAvailable==true)?style.textInputLineColor:style.color1),justifyContent: 'center',alignItems:"center"}}>   
    
                <Image source={this.props.icon} style={{height:(this.props.iconClassicSmall==true)?15:20,width:(this.props.iconClassicSmall==true)?15:20,resizeMode:"contain"}}/> 
            </View>}

            {(this.props.statusMode!=undefined || this.props.statusModeMini!=undefined) && <View style={{width: (this.props.statusModeMini!=undefined)?20:40, height: (this.props.statusModeMini!=undefined)?20:40, marginRight:(this.props.statusModeMini!=undefined)?7:5,justifyContent: 'center',alignItems:"center"}}>   
    
                <View style={{width: 20, height: 20, borderRadius: 10,backgroundColor:(this.props.statusModeMini!=undefined)?this.props.statusModeMini:this.props.statusMode}} /> 
            </View>}



            {this.props.iconClassic!=undefined && <View style={{width: ((this.props.iconClassicSmall==true)?((this.props.shadow)?30:20):40)*( (this.props.maxiMode==true)?3:1), height: ((this.props.iconClassicSmall==true)?((this.props.shadow)?30:20):40)*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1), borderRadius: ((this.props.iconClassicSmall==true)?10:20)*( (this.props.maxiMode==true)?3:1),marginRight:5,justifyContent: 'center',alignItems:"center"}}>   
    
                <Image source={this.props.iconClassic} style={{height:((this.props.iconClassicSmall==true)?20:30)*( (this.props.maxiMode==true)?3:1),width:((this.props.iconClassicSmall==true)?20:30)*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),resizeMode:"contain"}}/> 
            </View>}


             {(this.props.arrow==true && this.props.arrowCircle!=true) && <TouchableHighlight accessibilityLabel={(this.props.arrowEnabled)?strings.coche:strings.notCoche} underlayColor={'rgba(255,255,255,0)'} onPress={() => {if(this.props.onChange!=null && this.props.editable!=false) this.props.onChange(); }} >
                <View style={{justifyContent:"center",alignItems:'center',borderWidth:1,borderColor: style.textInputLineColor,height:20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),width:20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),marginRight:5,backgroundColor:(this.props.arrowEnabled)?style.color1:((this.props.backgroundColor!=undefined)?this.props.backgroundColor:style.backgroundColor) }}>
                  <Text allowFontScaling={false} style={{fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),color:((this.props.backgroundColor!=undefined)?this.props.backgroundColor:style.backgroundColor),fontWeight: 'bold',}}>&#10003;</Text>
                </View>
            </TouchableHighlight>}

            {(this.props.arrow==true && this.props.arrowCircle==true) && 

              <TouchableHighlight accessibilityLabel={(this.props.arrowEnabled)?strings.selected:strings.notSelected} underlayColor={'rgba(255,255,255,0)'} onPress={() => {if(this.props.onChange!=null && this.props.editable!=false) this.props.onChange(); }} >
                <View style={{marginTop:0, marginRight:5,height:20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),width:20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),borderRadius:10*((this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),backgroundColor:(this.props.backgroundColor!=undefined)?this.props.backgroundColor:style.backgroundColor,borderWidth: 1,borderColor:style.color1}}>
                              <View style={{height:15*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),width:15*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),borderRadius:7.5*((this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),backgroundColor:(this.props.arrowEnabled)?style.color1:style.backgroundColor, marginTop:1.5*((this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?4:3.3):1),marginLeft:1.5*((this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?4:3.3):1)}}/>
                          </View>
            </TouchableHighlight>}


            {this.props.textExplicationOnPress!=undefined && <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.props.textExplicationOnPress(); }} >
                  <View style={{flexDirection:"column",}}>
                    <Text allowFontScaling={false} style={{textDecorationLine: (this.props.strike==true)?'line-through':undefined,fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),fontWeight: (this.props.textExplicationBold==true)?'bold':"normal",color:(this.props.notAvailable==true)?style.textInputLineColor:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor)}}>{this.props.textExplication}</Text>
                    {this.props.textExplicationMini!=undefined && <Text accessibilityLabel={this.props.accessibilityLabelMini} numberOfLines={1} allowFontScaling={false} style={{textDecorationLine: (this.props.strike==true)?'line-through':undefined,fontSize: style.textInputFontSize*0.7,color:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor)}}>{this.props.textExplicationMini}</Text>}
                  </View>
            </TouchableHighlight>}

 
            {this.props.textExplicationOnPress==undefined && <View style={{flexDirection:"column", }}>
              <Text allowFontScaling={false} numberOfLines={(this.props.textExplicationMini!=undefined)?1:((this.props.enableNext==true || this.props.enableNextBottom ==true || this.props.enableNextTop==true)?2:undefined)} style={{marginRight:(this.props.enableInfo!=undefined)?20:( (this.props.enableNext==true || this.props.enableNextBottom ==true || this.props.enableNextTop==true)?35:undefined), textDecorationLine: (this.props.strike==true)?'line-through':undefined,fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):( (this.props.maxiMode=="partial")?1.5:1)),fontWeight: (this.props.textExplicationBold==true)?'bold':"normal",color:(this.props.notAvailable==true)?style.textInputLineColor:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor),maxHeight:40*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1)}}>{this.props.textExplication}</Text>
              {this.props.textExplicationMini!=undefined && <Text accessibilityLabel={this.props.accessibilityLabelMini} numberOfLines={1} allowFontScaling={false} style={{textDecorationLine: (this.props.strike==true)?'line-through':undefined,marginRight:(this.props.enableInfo!=undefined)?20:(  (this.props.enableNext==true || this.props.enableNextBottom ==true || this.props.enableNextTop==true)?35:undefined),fontSize: style.textInputFontSize*0.7*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?2:1.8):1),color:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor)}}>{this.props.textExplicationMini}</Text>}
            </View>}

            {(this.props.enableRemove==true && this.props.multiline==true) && <TouchableHighlight style={{height:40, width:20, alignItems:"center"}} underlayColor={'rgba(255,255,255,0)'} onPress={() => {if(this.props.onRemove!=null) this.props.onRemove(); }} >
               <Image source={require('../assets/favorite_cancel.png')} style={{ width: 20, height: 20,marginTop:9, marginRight:(this.props.disableLine==true)?5:undefined }}/>
          </TouchableHighlight>}


          </View>
          
          <View style={{flex:(this.props.disabledText==true)?undefined:((this.props.textInputFlex!=undefined)?this.props.textInputFlex:((this.props.multiline==true)?2.5:1)),flexDirection:"row"}}>

          


          {this.props.enableButtonsLH==true && <View style={{ flex:1, justifyContent:"flex-start",flexDirection:"row",alignItems:"center"}}> 
 
                <TouchableHighlight accessibilityLabel={strings.removeElement} underlayColor={style.color1}
                        onPress={() => {
                          if(this.props.onChange!=null) this.props.onChange(0);
                        }}
                        style={{marginLeft:-5, borderRadius:style.textInputHeight*0.4*( (this.props.maxiMode==true)?3:1), height: style.textInputHeight*0.8*( (this.props.maxiMode==true)?3:1),width:style.textInputHeight*0.8*( (this.props.maxiMode==true)?3:1),backgroundColor:style.color1,justifyContent: "center",alignItems:"center"}}>
                        <Text allowFontScaling={false} style={{color:style.backgroundColor,fontWeight:'bold',fontSize: style.textInputFontSize*1.1*( (this.props.maxiMode==true)?3:1),textAlign: 'center',}}>{"-"}</Text>
                      </TouchableHighlight>

                      <Text allowFontScaling={false} style={{minWidth:30, textAlign:"center", fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),color:(this.props.notAvailable==true)?style.textInputLineColor:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor)}}>{this.props.text}</Text>

                <TouchableHighlight underlayColor={style.color1} accessibilityLabel={strings.addElement}
                  onPress={() => {  
                    if(this.props.onChange!=null) this.props.onChange(1);
                  }} style={{borderRadius:style.textInputHeight*0.4*( (this.props.maxiMode==true)?3:1), height: style.textInputHeight*0.8*( (this.props.maxiMode==true)?3:1),width:style.textInputHeight*0.8*( (this.props.maxiMode==true)?3:1),backgroundColor:style.color1,justifyContent: "center",alignItems:"center"}}>
                  <Text allowFontScaling={false} style={{color:style.backgroundColor,fontWeight:'bold',fontSize: style.textInputFontSize*1.1*( (this.props.maxiMode==true)?3:1),textAlign: 'center',}}>{"+"}</Text>
                </TouchableHighlight>

            </View>}



          {(this.props.disabledText!=true && this.props.enableButtonsLH!=true) && <View style={{flex:1, justifyContent:(this.props.iconText!=undefined)?"flex-start":"center",flexDirection:(this.props.iconText!=undefined)?"row":"column",alignItems:(this.props.iconText!=undefined)?"center":"stretch"}}> 
            
            {this.props.iconText!=undefined && <View style={{marginRight:5,justifyContent: 'center',alignItems:"center"}}>   
    
                <Image source={this.props.iconText} style={{height:30,width:30,resizeMode:"contain"}}/> 
            </View>}

            {!(this.props.editable==false && (this.props.secureTextEntry==undefined || this.props.secureTextEntry==false)  ) && <TextInput allowFontScaling={false}
              accessibilityLabel={this.props.accessibilityLabel}
            	editable={(this.props.editable==undefined)?true:this.props.editable}
            	keyboardType={(this.props.keyboardType==undefined || Platform.OS=="web")?"default":this.props.keyboardType}
              placeholder={this.props.placeholder}
              secureTextEntry={(this.props.secureTextEntry==undefined)?false:((this.state.viewPassword==true)?false:this.props.secureTextEntry)}
              placeholderTextColor={style.placeholderTextInputColor}
              autoCorrect={false}   
              multiline={(this.props.multiline==true)?true:false}
              autoCapitalize={(this.props.autoCapitalize==undefined)?"sentences":this.props.autoCapitalize}
              underlineColorAndroid="rgba(0,0,0,0)"
              style={{color:(this.props.onlyTextInputColor!=undefined)?this.props.onlyTextInputColor:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor),fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?3:1),textAlign:"left",height:style.textInputHeight*((this.props.multiline==true)?2:1)}}
              onEndEditing={() => {
                if(this.props.onEndEditing!=null) this.props.onEndEditing();

              } }
              
              onChangeText={text => {
              	if(this.props.onChangeText!=null) this.props.onChangeText(text);


          		}}
              value={this.props.text}
              />}
              {(this.props.editable==false && (this.props.secureTextEntry==undefined || this.props.secureTextEntry==false)  )&& <Text allowFontScaling={false} style={{ textDecorationLine: (this.props.strike==true)?'line-through':undefined,     color:(this.props.onlyTextInputColor!=undefined)?this.props.onlyTextInputColor:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor),fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),textAlign:"left"}} >{this.props.text}</Text>}


          </View>}



          {(this.props.enableInfo!=undefined && this.props.enableInfo==true && this.props.maxiMode!=true) && <View style={{flexDirection:"column",justifyContent: 'center',alignItems:"center"}}>
              <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { if(this.props.onEnableInfo!=null) this.props.onEnableInfo(); }}>
                  <View style={{marginRight:0,}}>
                      <Image source={(style.mode=="dark")?require('../assets/iconiWhite.png'):require('../assets/iconi.png')} style={{ width: 15, height: 15,marginRight:(this.props.textRight!=undefined)?5:undefined}}/>              
                    </View>
              </TouchableHighlight>
            </View>}

          {this.props.textRight!=undefined && <View style={{flexDirection:"column",justifyContent: 'center',alignItems:"center"}}>
            <Text allowFontScaling={false} style={{ fontSize: style.textInputFontSize*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),fontWeight: "bold",color:(this.props.notAvailable==true)?style.textInputLineColor:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor)}}>{this.props.textRight}</Text>
            </View>}



          

          {(this.props.enableNext==true || this.props.enableNextBottom==true || this.props.enableNextTop===true) && <Image source={require('../assets/next.png')} style={{transform: [{rotate:(this.props.enableNextTop==true)?"270deg" :((this.props.enableNextBottom==true)?"90deg":"0deg")}], width: 25*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1), height: 25*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1),marginTop:(this.props.maxiMode=="partial")? ( (Dimensions.get('window').width>1000)?50:30):  (7*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1)) }}/>}

          {this.props.chooseMoney==true && <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {  this.setState({ moneyVisible:true}) }} >
              
              <Image source={this.props.flagMoney} style={{width:40, height: 20,marginTop:7, resizeMode:"contain" }}/>
          </TouchableHighlight>}


          {this.props.flag!=undefined && <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { if(this.props.flagOnPress!=undefined) this.props.flagOnPress() }} >
              <Image source={this.props.flag} style={{ height: 20,marginTop:7 }}/>
          </TouchableHighlight>}


          {this.props.choosePhone==true && <TouchableHighlight style={{width:30, height:20}} underlayColor={'rgba(255,255,255,0)'} onPress={() => { if(this.props.editable!=false) this.setState({ countryVisible:true}) }} >
              <Image source={this.props.flagPhone} style={{ height: 20,marginTop:7 }}/>
          </TouchableHighlight>}
          


          {(this.props.enableRemove==true && this.props.multiline!=true) && <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {if(this.props.onRemove!=null) this.props.onRemove(); }} >
               <Image source={require('../assets/favorite_cancel.png')} style={{ width: 20, height: 20,marginTop:9, marginRight:(this.props.disableLine==true)?5:undefined }}/>
          </TouchableHighlight>}

          {this.props.enableViewPassword==true && <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { this.setState({viewPassword:!this.state.viewPassword})  }} >
               <Image source={(this.state.viewPassword===true)?require('../assets/eyeB.png'):require('../assets/eye.png')} style={{ width: 20, height: 20,marginTop:9 }}/>
          </TouchableHighlight>}




          {this.props.enableCheck==true && <Image source={require('../assets/check_mark.png')} style={{ width: 20, height: 20,marginTop:9, marginLeft:2 }}/>}
          
          {this.props.enableError==true && <Image source={require('../assets/error_mark.png')} style={{ width: 20, height: 20,marginTop:9, marginLeft:2 }}/>}

          {this.props.enableQuestion==true && <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { if(this.props.onQuestion!=null) this.props.onQuestion(); }}>
                  <Image source={require('../assets/question_orange.png')} style={{ width: 20, height: 20,marginTop:9, marginLeft:2 }}/>
              </TouchableHighlight>}

          


          </View>


        </View>

        {( (this.props.icon==undefined || this.props.maxiMode=="partial") && this.props.disableLine!=true) && <View
              style={{
                height: style.textLineHeight,
                backgroundColor: (this.props.textInputLineColor!=undefined)?this.props.textInputLineColor:style.textInputLineColor,
                marginTop: style.textInputMarginTop,
                marginLeft:(this.props.marginSize!=undefined)?this.props.marginSize:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.1),
                marginRight:(this.props.marginSize!=undefined)?this.props.marginSize:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.1),
              }}
            />}
            
        </View>

         }</StyleContext.Consumer>


    	)}

    }

