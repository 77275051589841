import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Image,
  Picker,
  Platform,
  ScrollView,
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';
import strings from '../strings';
import Modal from './modal';
import MavButton from './mavButton';
const timer = require('react-native-timer');

export default class mavNotation extends Component {
  state = {
    modalVisible:false,
    noteCustomer:false, 
    activateComment:false, 
    note:5,
    comment:"", 
    raceDate:new Date(),
    driverName:"", 
    clientName:"", 

  };
componentDidMount(){
  this.setState({list:this.props.list})
}
  

  setModalVisible(visible) {
    this.setState({modalVisible: visible});
  }

  onNotation(value){

    console.log("on notation "+value.raceDate)
    this.setState({modalVisible:true,noteCustomer:value.noteCustomer,activateComment:value.activateComment,raceDate:new Date(value.raceDate),driverName:value.driverName,clientName:value.clientName  })
  }

  onNotationFinish(val){

    this.setState({modalVisible:false})

    if(val==true){
      var send={note:this.state.note, noteCustomer:this.state.noteCustomer}
      if(this.state.activateComment==true && this.state.comment!="") send.comment=this.state.comment;
      if(this.props.onNotationFinish!=undefined) this.props.onNotationFinish(send)
    }
    else if(this.props.onNotationFinish!=undefined) this.props.onNotationFinish({send:false})


  }

  render() {
    return (

    <StyleContext.Consumer>{style => 


     <Modal
          animationType="none"
          transparent={true}
          visible={this.state.modalVisible}>
          <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",justifyContent:"center"}}>

              <TouchableHighlight underlayColor={'#FFFFFF00'} style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,backgroundColor:"rgba(0,0,0,0.5)",position:"absolute",marginTop:0}} onPress={() => {this.setState({modalVisible:false})}}>
                  <View/>
              </TouchableHighlight>

              <View style={{width:Dimensions.get('window').width*0.9,backgroundColor:style.backgroundColor,position:"absolute",marginTop:Dimensions.get('window').height*0.15,marginLeft:Dimensions.get('window').width*0.05,shadowOpacity: 0.3,shadowRadius: 2,shadowOffset: {height: 2,width: 0},elevation: 1}}>
                <Text style={{marginTop:15,marginLeft:15,marginRight:15, color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",fontWeight: 'bold',}}>{strings.thanksForYourRace+" "+((this.state.raceDate.getDate()<10)?"0":"")+this.state.raceDate.getDate()+"/"+((this.state.raceDate.getMonth()<9)?"0":"")+(this.state.raceDate.getMonth()+1)+"/"+this.state.raceDate.getFullYear()+" "+strings.effectWith.toLowerCase()+" "+((this.state.noteCustomer==true)?this.state.clientName:this.state.driverName)}</Text>

                <Text style={{marginTop:7,marginLeft:15,marginRight:15, color: style.textInputColor,fontSize: style.textInputFontSize,textAlign: "center",}}>{((this.state.noteCustomer==true)?strings.noteCustomer:strings.noteDriver)+" :"}</Text>
                

                <View style={{flexDirection:'row',marginTop:7, justifyContent:"center", alignItems:"center"}}> 

                      <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={()=>{this.setState({note:1})}} ><Image source={(this.state.note>0)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:35,height:35,marginRight:2,}}></Image></TouchableHighlight>
                      <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={()=>{this.setState({note:2})}} ><Image source={(this.state.note>1)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:35,height:35,marginRight:2,}}></Image></TouchableHighlight>
                      <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={()=>{this.setState({note:3})}} ><Image source={(this.state.note>2)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:35,height:35,marginRight:2,}}></Image></TouchableHighlight>
                      <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={()=>{this.setState({note:4})}} ><Image source={(this.state.note>3)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:35,height:35,marginRight:2,}}></Image></TouchableHighlight>
                      <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={()=>{this.setState({note:5})}} ><Image source={(this.state.note>4)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:35,height:35,marginRight:2,}}></Image></TouchableHighlight>

                      <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize,textAlign: "center",marginLeft:10}}>{"("+this.state.note+"/5)"}</Text>

                </View>


                {this.state.activateComment==true && <Text style={{marginTop:15,marginLeft:15,marginRight:15, color: style.textInputColor,fontSize: style.textInputFontSize,textAlign: "center",}}>{strings.addComment+" :"}</Text>}

                {this.state.activateComment==true && <TextInput allowFontScaling={false} placeholder={strings.yourComment} placeholderTextColor={style.placeholderTextInputColor} autoCorrect={false}  underlineColorAndroid="rgba(0,0,0,0)"
                multiline={true} 
                style={{color:style.textInputColor,fontSize: style.textInputFontSize,textAlign:"left",height:style.textInputHeight*3, marginTop:15, borderWidth:1, borderColor:style.placeholderTextInputColor, marginLeft:20, marginRight:20}}
                onChangeText={text => {this.setState({comment:text}) }} value={this.state.comment}/>}

                <View style={{justifyContent:"center", alignItems:"center"}}> 
                    <MavButton onlyButton={true} key={"validate"} strokeMode={false} text={strings.confirm} style={{marginTop:15, marginBottom:10}} onPress={()=>{ this.onNotationFinish(true) }}/>
                    <MavButton onlyButton={true} key={"noThanks"} strokeMode={true} text={strings.noThanks} style={{marginBottom:10}} onPress={()=>{ this.onNotationFinish(false) }}/>
               </View>


              </View>

             

              

              
 
        </View>


        </Modal>
        }</StyleContext.Consumer>

      
      )}

    }


    export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

