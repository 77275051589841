import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  Dimensions,TextInput,
  Platform,
  ActivityIndicator,
  Alert,
  Image,
  Clipboard,
} from 'react-native';
import {StyleContext} from '../styleC';
import firebase from './firebase';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, startAt, endAt, query} from "firebase/database";
import strings from '../strings';
import MavButton from './mavButton';
import MavAlert from './mavAlert';
const timer = require('react-native-timer');
import Modal from './modal';
import MavSearch from './mavSearch';
import TextPresentation from './textPresentation';
import MavInfoBubble from './mavInfoBubble';


import MavNavigationMinimize from './mavNavigationMinimize';
import MavMiniButton from './mavMiniButton';
import example from '../example';

class HeaderRight extends Component {
render() {
    return (
      <StyleContext.Consumer>{style => 
        <View>
            {this.props.viewAdd==true && <TouchableHighlight style={{ marginRight:10}} underlayColor={"#FFFFFF00"} onPress={() => {this.props.addAccount()  }}>
                    <Image source={(style.mode=="dark")?require('../assets/plus_white.png'):require('../assets/plus_black.png')} style={{ width: 25, height: 25 }} /> 
            </TouchableHighlight>}
        </View>
        }</StyleContext.Consumer>
      )
  }
}




export default class help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverPricing:false,
      realCustomerOnly:false,
      waiting:false,
      loading:true,
      selectView:"all", 
      number:0,
      numberPartner:0,
      numberProvider:0,
      numberManager:0,
      contact: {},
      contactList:[],
      clientList:[],
      partnerList:[],
      managerList:[],
      alphabetList:[],
      seeAllContact:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
      pricingInfo:{},
      search:"",
      seeFavoriteDriverPhoneBookList:false,
      options:{},
      globalUser:true, 
      forceGlobalUser:false,
      partnerPartialAccessPhoneBook:false,
      autorizeChangeDriverFavoritePrice:false,
      selectClient:false,
      selectOnlyPro:false,
      vipMode:true, 
      dataExported:"",
      dataExportedArray:[], 
      lockedClient:false,
      viewLockedClient:false,
      defineColorForCustomer:false,
      enableOrderDriver:false



    };
  }


  _filter = () => {
      var selected=-1;
      var i;
      var list=[{id:"all",text:strings.all},{id:"customer", text:strings.customers},{id:"customerPro", text:strings.customersPro},{id:"manager", text:strings.managers},{id:"partner", text:strings.partners}, {id:"provider", text:strings.providersAffair} ]
      for(i=0;i<list.length;i++){
          if(this.state.selectView==list[i].id) selected=i;
      }
      this.alert.onAlert({title:strings.viewBy, items:list,itemSelected:selected, buttons:[{text: strings.validate,onPress: (id,value) => {this.setState({selectView:list[id].id}); if(Platform.OS!="web"){
      timer.setTimeout(generateUUID(), () => {   if(this.state.forceGlobalUser==false)    this.props.navigation.setOptions({ headerRight: props => <HeaderRight viewAdd={(this.state.forceGlobalUser==false && this.state.loading==false && this.state.search=="" && this.state.seeFavoriteDriverPhoneBookList!=true && this.state.selectView=="all" && (this.state.selectClient!==true) )} addAccount={this._addAccount} {...props} /> }) }, 500);
    }; 

    }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })  
  

  }

    _addAccount = () => {

        if (this.state.waiting==false) {
            
            if(this.props.navigation!=undefined) this.props.navigation.navigate('Account', {newClient: true, update:this.update })
            else this.props.params.navigate('Account', {newClient: true, update:this.update })
            timer.setTimeout(generateUUID(), () => {this.setState({waiting:false})}, 2000);
          }

    }





  componentDidMount() {

    console.log("client mount")
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    
    var forceGlobalUser=(params.forceGlobalUser!=undefined)?params.forceGlobalUser:false;

    if(Platform.OS!="web" && forceGlobalUser==false){this.props.navigation.setOptions({ headerRight: props => <HeaderRight viewAdd={(this.state.forceGlobalUser==false && this.state.loading==false && this.state.search=="" && this.state.seeFavoriteDriverPhoneBookList!=true && this.state.selectView=="all" && (this.state.selectClient!==true) )} addAccount={this._addAccount} {...props} /> })}

    
    

    var globalUser=(params!=undefined && params.driver!=undefined && params.driver==true)?true:false;
    if(params.driverPricing==true) this.setState({driverPricing:true})
    if(params!=undefined && params.realCustomerOnly!=undefined) this.setState({realCustomerOnly:params.realCustomerOnly})
    if(params!=undefined && params.selectClient!=undefined) this.setState({selectClient:params.selectClient})
    if(params!=undefined && params.selectOnlyPro!=undefined) this.setState({selectOnlyPro:params.selectOnlyPro})

    if(params!=undefined && params.driver!=undefined) this.setState({driver:params.driver})  
        if(params!=undefined && params.manager!=undefined) this.setState({manager:params.manager})   

    if(params!=undefined && params.disableNavigation==true && Platform.OS=="web") this.setState({disableNavigation:true})  
    if(params!=undefined && params.options!=undefined) this.setState({options:params.options}) 

    if(params!=undefined && params.defineColorForCustomer!=undefined) this.setState({defineColorForCustomer:params.defineColorForCustomer}) 
      if(params!=undefined && params.enableOrderDriver!=undefined) this.setState({enableOrderDriver:params.enableOrderDriver}) 
     
    if(globalUser==false && params!=undefined && params.partnerPartialAccessPhoneBook==true){
      this.setState({partnerPartialAccessPhoneBook:true})  
      if(params.autorizeChangeDriverFavoritePrice==true) this.setState({autorizeChangeDriverFavoritePrice:true})  

    }

     
      

 
    this.setState({globalUser:globalUser, forceGlobalUser:forceGlobalUser},() =>{


      

        this.updateData();
        if(this.state.globalUser==true) get(child(ref(getDatabase(firebase)), '/pricing')).then(snapshot => { 
          var val=snapshot.val();
          this.setState({pricingInfo:val})


        })


    })

    
  }



  update = data =>{
    console.log("le update de clients est appeler ")
    var uuid=generateUUID();

    var phone=(data.phone!=undefined && data.phone!="")?(data.countryPhoneIID+data.phone):undefined;

    var newClient={
      name:data.name,
      lastName:data.lastName,
      manualClient:true,
    }

    if(data.enterprise!=undefined && data.enterprise!="") newClient.enterprise=data.enterprise
    if(phone!=undefined) newClient.phone=phone
    if(data.email!=undefined && data.email!="") newClient.email=data.email
    if(data.adressBill!=undefined && data.adressBill!="") newClient.adressBill=data.adressBill
    if(data.cityBill!=undefined && data.cityBill!="") newClient.cityBill=data.cityBill

    var request=(this.state.globalUser==true)?"/users":"usersListClients/"+getAuth(firebase).currentUser.uid;

    const fireInfo={} 
    fireInfo[request+"/"+uuid]=newClient
    update(ref(getDatabase(firebase)), fireInfo)


    this.alert.onAlert({title:strings.addClientOK,buttons:[{text:strings.ok,onPress: () => {  if( this.props.navigation!=undefined)this.props.navigation.goBack();else this.props.params.goBack();    }} ],});



  }


  copyDataToFirebaseClients = data => {

      get(child(ref(getDatabase(firebase)), "usersListClients/"+this.state.forceGlobalUser )).then(snapshot => { 
          var val=snapshot.val();

          const fireInfo={} 
          var list=Object.keys(val); 
          var i; 

          for(i=0;i<list.length; i++){
            fireInfo["users/"+list[i]]=val[list[i]]
          }
          
          console.log(fireInfo)
          update(ref(getDatabase(firebase)), fireInfo)

          


      })



  }

  updateData = data => {
      this.setState({loading:true})
      if(Platform.OS!="web" && this.state.forceGlobalUser==false){this.props.navigation.setOptions({ headerRight: props => <HeaderRight viewAdd={false} addAccount={this._addAccount} {...props} /> })}


      var request=(this.state.globalUser==true)?"/users":"usersListClients/"+getAuth(firebase).currentUser.uid;
      if(this.state.forceGlobalUser!=false) request="usersListClients/"+this.state.forceGlobalUser; 
      
      get(child(ref(getDatabase(firebase)), request)).then(snapshot => { 
              var val=snapshot.val();
              if(val!=null) this.filterData(val)
              else this.setState({loading:false,number:0})
                  if(Platform.OS!="web" && this.state.forceGlobalUser==false){this.props.navigation.setOptions({ headerRight: props => <HeaderRight viewAdd={(this.state.forceGlobalUser==false && this.state.search=="" && this.state.seeFavoriteDriverPhoneBookList!=true && this.state.selectView=="all" && (this.state.selectClient!==true) )} addAccount={this._addAccount} {...props} /> })}




      });



  };

  filterData = val => {

                  var tempList = Object.keys(val);
                  var alphabetList=[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
                  var alphabetListCustomer=[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
                  var alphabetListCustomerPro=[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
                  var alphabetListProvider=[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
                  var alphabetListPartner=[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
                  var alphabetListManager=[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];

                  
                  var clientList=[];
                  var partnerList=[];
                  var managerList=[];
                  var number=0;
                  var numberManager=0;
                  var numberPartner=0;
                  var numberProvider=0;
                  var numberCustomer=0;
                  var numberCustomerPro=0;
                  var listTotal=[];
                  

                  var i;
                  for(i=0;i<tempList.length;i++){val[tempList[i]].clientID=tempList[i]}

                  for(i=0;i<tempList.length;i++){
                    var h=undefined;
                    if(this.state.realCustomerOnly==true && val[tempList[i]].manualClient==true) h=undefined;
                    else if(val[tempList[i]].enterprise!=undefined && val[tempList[i]].enterprise!=""){ h=(val[tempList[i]].enterprise[0]).toUpperCase().charCodeAt(0)-65}
                    else if((val[tempList[i]].lastName==undefined || val[tempList[i]].lastName=="") && val[tempList[i]].name!=undefined && val[tempList[i]].name!="") {h=(val[tempList[i]].name[0]).toUpperCase().charCodeAt(0)-65}
                    else if(val[tempList[i]].lastName!=undefined && val[tempList[i]].lastName!=""  ) {h=(val[tempList[i]].lastName).toUpperCase().charCodeAt(0)-65;}

                    if(h!==undefined){

                        
                        number++;
                        if(val[tempList[i]].accountClientDisabled==true){ this.setState({lockedClient:true})  }  
                        
                        if( this.state.selectOnlyPro!=true || (val[tempList[i]].provider==true || val[tempList[i]].customerPro==true || val[tempList[i]].partner==true) ) alphabetList[ (val[tempList[i]].accountClientDisabled==true)?27:((h<0 || h>25)?26:h)].push(tempList[i])
                        
                        if(val[tempList[i]].driver==true || val[tempList[i]].manager==true) {numberManager++; alphabetListManager[(val[tempList[i]].accountClientDisabled==true)?27:((h<0 || h>25)?26:h)].push(tempList[i]) }
                      
                        if(val[tempList[i]].partner==true) {numberPartner++; alphabetListPartner[(val[tempList[i]].accountClientDisabled==true)?27:((h<0 || h>25)?26:h)].push(tempList[i]) }
                        else if(val[tempList[i]].provider==true){ numberProvider++; alphabetListProvider[(val[tempList[i]].accountClientDisabled==true)?27:((h<0 || h>25)?26:h)].push(tempList[i]) }
                        else if(val[tempList[i]].customerPro==true){ numberCustomerPro++; alphabetListCustomerPro[(val[tempList[i]].accountClientDisabled==true)?27:((h<0 || h>25)?26:h)].push(tempList[i]) }
                        else { numberCustomer++; alphabetListCustomer[(val[tempList[i]].accountClientDisabled==true)?27:((h<0 || h>25)?26:h)].push(tempList[i]) } 

                        if( this.state.selectOnlyPro!=true || (val[tempList[i]].provider==true || val[tempList[i]].customerPro==true || val[tempList[i]].partner==true) ) listTotal.push(tempList[i])
                        

                    
                    if(val[tempList[i]].driver==true || val[tempList[i]].manager==true) managerList.push({id:tempList[i],text:val[tempList[i]].lastName.toUpperCase()+" "+val[tempList[i]].name,h:h });
                    else if(val[tempList[i]].partner==true) partnerList.push({id:tempList[i],text:val[tempList[i]].lastName.toUpperCase()+" "+val[tempList[i]].name,h:h });
                    else if(h!="") clientList.push({id:tempList[i],text:val[tempList[i]].lastName.toUpperCase()+" "+val[tempList[i]].name,h:h });

                    }
                  else console.log("error "+tempList[i])
                    

                    

                    
                  }
                 
                  if(clientList.length>0){
                    clientList.sort(function (a, b) {
                      if (a.h < b.h) return -1;
                      else if (a.h > b.h) return 1;
                      return 0;
                    });
                  }

                  if(managerList.length>0){
                    managerList.sort(function (a, b) {
                      if (a.h < b.h) return -1;
                      else if (a.h > b.h) return 1;
                      return 0;
                    });
                  }

                  if(partnerList.length>0){
                    partnerList.sort(function (a, b) {
                      if (a.h < b.h) return -1;
                      else if (a.h > b.h) return 1;
                      return 0;
                    });
                  }

                  


              
                  for(i=0;i<alphabetList.length;i++){
                    alphabetList[i]=alphabetList[i].sort(function (a, b) {
                      if (val[a].lastName < val[b].lastName) return -1;
                      else if (val[a].lastName > val[b].lastName) return 1;
                      return 0;
                    });
                  }

                  for(i=0;i<alphabetListManager.length;i++){
                    alphabetListManager[i]=alphabetListManager[i].sort(function (a, b) {
                      if (val[a].lastName < val[b].lastName) return -1;
                      else if (val[a].lastName > val[b].lastName) return 1;
                      return 0;
                    });
                  }

                  for(i=0;i<alphabetListProvider.length;i++){
                    alphabetListProvider[i]=alphabetListProvider[i].sort(function (a, b) {
                      if (val[a].lastName < val[b].lastName) return -1;
                      else if (val[a].lastName > val[b].lastName) return 1;
                      return 0;
                    });
                  }

                  for(i=0;i<alphabetListPartner.length;i++){
                    alphabetListPartner[i]=alphabetListPartner[i].sort(function (a, b) {
                      if (val[a].lastName < val[b].lastName) return -1;
                      else if (val[a].lastName > val[b].lastName) return 1;
                      return 0;
                    });
                  }

                  for(i=0;i<alphabetListCustomerPro.length;i++){
                    alphabetListCustomerPro[i]=alphabetListCustomerPro[i].sort(function (a, b) {
                      if (val[a].lastName < val[b].lastName) return -1;
                      else if (val[a].lastName > val[b].lastName) return 1;
                      return 0;
                    });
                  }

                  for(i=0;i<alphabetListCustomer.length;i++){
                    alphabetListCustomer[i]=alphabetListCustomer[i].sort(function (a, b) {
                      if (val[a].lastName < val[b].lastName) return -1;
                      else if (val[a].lastName > val[b].lastName) return 1;
                      return 0;
                    });
                  }

                  this.setState({ numberCustomer:numberCustomer, numberManager:numberManager, numberCustomerPro:numberCustomerPro, numberPartner:numberPartner, numberProvider:numberProvider,alphabetListManager:alphabetListManager, alphabetListPartner:alphabetListPartner, alphabetListPartnerTotal:alphabetListPartner.slice(0),alphabetListManagerTotal:alphabetListManager.slice(0), alphabetListProvider:alphabetListProvider, alphabetListProviderTotal:alphabetListProvider.slice(0), alphabetListCustomer:alphabetListCustomer,alphabetListCustomerTotal:alphabetListCustomer.slice(0), alphabetListCustomerPro:alphabetListCustomerPro, alphabetListCustomerProTotal:alphabetListCustomerPro.slice(0), clientList:clientList,partnerList:partnerList, managerList:managerList, contactList: tempList,contact:val,alphabetList:alphabetList,alphabetListTotal:alphabetList.slice(0), number:number,loading:false });



}














  exportData(choice){
   
   var refReq;

    if(choice=="provider") refReq=query(ref(getDatabase(firebase), '/users'), orderByChild('provider'), startAt(true), endAt(true))
    else if(choice=="partner") refReq=query(ref(getDatabase(firebase), '/users'), orderByChild('partner'), startAt(true), endAt(true))
    else refReq=child(ref(getDatabase(firebase)), "/users")

    get(refReq).then(snapshot => { 
      var value=snapshot.val();

     


      var list=Object.keys(value);
      var i;
      var elem=""; 
      var dataExportedArray=[]; 
      dataExportedArray.push("Prenom, Nom, Email, Telephone");
      var text="Prenom, Nom, Email, Telephone\r\n"
      for(i=0;i<list.length;i++){
        if(choice=="all" ||  (choice=="provider" && value[list[i]].provider==true) || (choice=="partner" && value[list[i]].partner==true) || (value[list[i]].provider!=true && value[list[i]].driver!=true && value[list[i]].partner!=true) ){
          elem=value[list[i]].name+","+value[list[i]].lastName+","+value[list[i]].email+","+value[list[i]].phone; 
          dataExportedArray.push(elem)
            text+=elem+"\r\n";
        }


      }

    

      this.setState({dataExported:text, dataExportedArray:dataExportedArray})

    })


    

  }
  
  getPartnerFavoriteUser = data => {

    this.setState({loading:true,number:0, seeFavoriteDriverPhoneBookList:true, contactList:[],clientList:[],partnerList:[],alphabetList:[],})

    getAuth(firebase).currentUser.getIdToken().then(token => {

      var body={userID:getAuth(firebase).currentUser.uid,token:token,}

      fetch("https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/getUsersFavorite", {body:JSON.stringify(body),method: "POST"}).then((response) => response.json()).then((responseJson) => {
        
            console.log(responseJson)

            this.filterData(responseJson)
            this.setState({seeFavoriteDriverPhoneBookList:true})
          }).catch((err) => { console.log("error getPartnerFavoriteUser"); console.log(err); timer.setTimeout("getPartnerFavoriteUser", () => { this.getPartnerFavoriteUser();  }, 2000);   })

       })


    



  }


  searchResult(){
    if(Platform.OS!="web" && this.state.forceGlobalUser==false){this.props.navigation.setOptions({ headerRight: props => <HeaderRight viewAdd={(this.state.forceGlobalUser==false && this.state.loading==false && this.state.search=="" && this.state.seeFavoriteDriverPhoneBookList!=true && this.state.selectView=="all" && (this.state.selectClient!==true) )} addAccount={this._addAccount} {...props} /> })}

    console.log("on cherche "+this.state.search)
    var list;
    var listName=[];
    var str="";
    var result;
    var search=this.state.search
    var total=(this.state.selectView=="manager")?this.state.alphabetListManagerTotal.slice(0):((this.state.selectView=="customer")?this.state.alphabetListCustomerTotal.slice(0):(  (this.state.selectView=="customerPro")?  this.state.alphabetListCustomerProTotal.slice(0) : (   (this.state.selectView=="partner")? this.state.alphabetListPartnerTotal.slice(0)  : ( (this.state.selectView=="provider")? this.state.alphabetListProviderTotal.slice(0)  : this.state.alphabetListTotal.slice(0) ) ) ) );


    var i,j;
    for(i=0;i<total.length;i++){
      list=total[i];
      if(list.length>0){
        listName=[]
       
        for(j=0;j<list.length;j++){

          str="";
          if(this.state.contact[list[j]].enterprise!=undefined) str+=this.state.contact[list[j]].enterprise+" ";
          if(this.state.contact[list[j]].lastName!=undefined) str+=this.state.contact[list[j]].lastName+" ";
          if(this.state.contact[list[j]].name!=undefined) str+=this.state.contact[list[j]].name+" ";
          if(this.state.contact[list[j]].phone!=undefined) str+=this.state.contact[list[j]].phone+" ";

          if(str.toLowerCase().indexOf(search.toLowerCase()) !== -1) listName.push(list[j])

        }

       
        
      total[i]=listName; 
      }
      
      

    }
    var st={}
    if(this.state.selectView=="customer") this.setState({alphabetListCustomer:total})
    else if(this.state.selectView=="customerPro") this.setState({alphabetListCustomerPro:total})
    else if(this.state.selectView=="partner") this.setState({alphabetListPartner:total})
    else if(this.state.selectView=="provider") this.setState({alphabetListProvider:total})
    else if(this.state.selectView=="manager") this.setState({alphabetListManager:total})
    else this.setState({alphabetList:total})

    



  }

  openClient = (value, findClientById) => { 
    console.log("openClient")
      if(this.state.selectClient===true) {
          if(this.props.route!=undefined){

            if(this.state.selectOnlyPro==true) value.orderFor=true; 
            this.props.route.params.onClientSelected(value)
            this.props.navigation.goBack();
          }
          else {
            if(this.state.selectOnlyPro==true) value.orderFor=true; 
            console.log("je retourne")
            console.log(value)
            this.props.params.onClientSelected(value)
            this.props.params.goBack();
          }
          
        }
      else if(Platform.OS=="web") this.props.params.navigate("Account",{defineColorForCustomer:this.state.defineColorForCustomer, enableOrderDriver:this.state.enableOrderDriver, disabledChange:this.state.seeFavoriteDriverPhoneBookList, autorizeChangeDriverFavoritePrice:this.state.autorizeChangeDriverFavoritePrice,driverPricing:this.state.driverPricing, reviews:this.props.params.reviews,manager:this.state.manager, driver:this.state.driver,globalUser:this.state.globalUser, pricingInfo:this.state.pricingInfo,clientInfo:(findClientById!=true)?value:undefined, findClientById:(findClientById==true)?value:undefined ,adminView:true,options:this.state.options, vipMode:this.state.vipMode, updateData:this.updateData,clientList:this.state.clientList,partnerList:this.state.partnerList})
      else this.props.navigation.navigate("Account",{defineColorForCustomer:this.state.defineColorForCustomer, enableOrderDriver:this.state.enableOrderDriver, disabledChange:this.state.seeFavoriteDriverPhoneBookList,autorizeChangeDriverFavoritePrice:this.state.autorizeChangeDriverFavoritePrice,driverPricing:this.state.driverPricing, reviews:this.props.route.params.reviews,manager:this.state.manager, driver:this.props.route.params.driver,globalUser:this.state.globalUser, pricingInfo:this.state.pricingInfo,clientInfo:(findClientById!=true)?value:undefined, findClientById:(findClientById==true)?value:undefined,adminView:true,options:this.state.options, vipMode:this.state.vipMode, updateData:this.updateData,clientList:this.state.clientList,partnerList:this.state.partnerList})


}                



  
  
  
  render() {
    return (
      <StyleContext.Consumer>{style => 
      <View style={{ backgroundColor: style.backgroundColor, flex: 1 }}>

        {(Platform.OS=="web" && this.state.disableNavigation!=true) && <MavNavigationMinimize clickLeft={() => { if(this.state.forceGlobalUser!=false) this.props.close(); else this.props.params.goBack();  }} left={strings.return} title={ strings.repertoire } />}



        <ScrollView style={{ width:"100%", height:(Platform.OS=="web")?(Dimensions.get('window').height-80):undefined }}>

            {(this.state.forceGlobalUser!=false && Platform.OS!="web") && <View style={{width:40, height:30}}/>}
            
            {this.state.loading==true && <ActivityIndicator style={{marginTop:20}} size="large" color={style.color1} />}

            {(this.state.loading==false && this.state.globalUser==false && this.state.forceGlobalUser!=false) && <Text style={{marginTop:10,marginLeft:"10%", marginRight:"10%",color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.phonebookCustomer}</Text> }

            {(this.state.loading==false && this.state.globalUser==false && this.state.forceGlobalUser==false) && <Text style={{marginTop:10,marginLeft:"10%", marginRight:"10%",color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{(this.state.seeFavoriteDriverPhoneBookList==true)?strings.favoriteDriverPhoneBookList:strings.manualPhoneBookList}</Text> }
            {(this.state.loading==false && this.state.globalUser==false && this.state.partnerPartialAccessPhoneBook==true && this.state.seeFavoriteDriverPhoneBookList==false) && <TouchableHighlight style={{ }} underlayColor={"#FFFFFF00"} onPress={() => {  this.getPartnerFavoriteUser()   }}>
                <Text style={{marginLeft:"10%", textDecorationLine:"underline", marginRight:"10%",color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.clickHereSeeUserFavoriteDriver}</Text> 
            </TouchableHighlight>}

            

            {(this.state.loading==false && this.state.number==0) && <Text style={{marginTop:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.noElement}</Text> }


            
              {(this.state.loading==false && Platform.OS=="web" && this.state.number==0 && this.state.forceGlobalUser==false && this.state.seeFavoriteDriverPhoneBookList!=true && this.state.selectView=="all" && (this.state.selectClient!==true) && this.state.disableNavigation!=true) && <View style={{alignItems:"center",justifyContent:"center", marginTop:10,marginBottom:10}}>
                 <MavButton text={strings.add} onlyButton={true} onPress={()=>{ this._addAccount()  }}/>
             </View>}


             
          

              {(this.state.loading==false && this.state.number!=0) && <View style={{flexDirection:"row",justifyContent:"center", alignItems:"center", marginLeft:(this.state.loading==false && Platform.OS=="web" && (this.state.disableNavigation==true || (this.state.forceGlobalUser==false && this.state.seeFavoriteDriverPhoneBookList!=true)) )?"10%":undefined, marginRight:(this.state.loading==false && Platform.OS=="web" && (this.state.disableNavigation==true || (this.state.forceGlobalUser==false && this.state.seeFavoriteDriverPhoneBookList!=true)))?"10%":undefined}}> 
                <MavSearch filter={(this.state.forceGlobalUser==false && this.state.seeFavoriteDriverPhoneBookList!=true && this.state.driver==true && this.state.selectOnlyPro!=true)?true:false} onFilter={data => {  this._filter() }} style={{marginTop:8, marginBottom:8, width:(this.state.loading==false && Platform.OS=="web" && this.state.disableNavigation==true)?"85%":"100%"}} noMargin={(this.state.loading==false && Platform.OS=="web" && (this.state.disableNavigation==true || (this.state.forceGlobalUser==false && this.state.seeFavoriteDriverPhoneBookList!=true)))?true:false} search={this.state.search} searchExplication={strings.search+" ..."} onSearch={data => { this.setState({search:data},() =>{this.searchResult()})  }} />
                

                {(this.state.loading==false && Platform.OS=="web" && this.state.search=="" && this.state.forceGlobalUser==false && this.state.seeFavoriteDriverPhoneBookList!=true && this.state.selectView=="all" && (this.state.selectClient!==true) ) && <TouchableHighlight style={{marginLeft:20}} underlayColor={"#FFFFFF00"} onPress={() => { this._addAccount() }}>
                      <Image source={(style.mode=="dark")?require('../assets/plus_white.png'):require('../assets/plus_black.png')} style={{ width: 25, height: 25 }} />                  
                </TouchableHighlight>} 


                </View>}  

                {(this.state.globalUser==true && this.state.driver==true && this.state.loading==false && false) && <MavInfoBubble  type={"help"} videoLink={"http://www.google.fr"} tutoLink={"http://www.google.fr"} others={[{title:"Comment créer un chauffeur ?", link:"ddedede"}, ]}  style={{marginTop:0,marginBottom:20}} /> }
                





            {(this.state.selectView=="all") && this.state.alphabetList.map((rowData,key) => (
              <View>{(this.state.alphabetList[key].length>0 && (key!=27 || this.state.viewLockedClient==true) ) && <View>

                    <View style={{height:30,backgroundColor:style.color1, alignItems:"center",flexDirection:"row" }}>
                      <Text style={{textAlign: 'left',fontSize: 20,color: style.backgroundColor,flex: 1,marginLeft:"5%",fontWeight:"bold"}}>{(key==27)?strings.lockedAccount:((key<26)?String.fromCharCode(65 + key):"123")}</Text>
                    </View>


                    {(this.state.forceGlobalUser!=false) && this.state.alphabetList[key].map((rowData) => (
                        <TextPresentation text={ this.state.contact[rowData].name+" "+this.state.contact[rowData].lastName }  >
                              <TextPresentation style={{marginTop:5}}  editable={false} text={(this.state.contact[rowData].name!=undefined)?this.state.contact[rowData].name:"--"} textExplication={strings.name} placeholder={example.name} />
                              <TextPresentation style={{marginTop:5}} editable={false} text={(this.state.contact[rowData].lastName!=undefined)?this.state.contact[rowData].lastName:"--"} textExplication={strings.lastName} placeholder={example.lastName} />
                              <TextPresentation style={{marginTop:5}} editable={false} text={(this.state.contact[rowData].enterprise!=undefined)?this.state.contact[rowData].enterprise:"--"} textExplication={strings.enterprise}  />
                              <TextPresentation style={{marginTop:5}} editable={false} text={(this.state.contact[rowData].adressBill!=undefined)?this.state.contact[rowData].adressBill:"--"} textExplication={strings.adressBill}  />
                              <TextPresentation style={{marginTop:5}} editable={false} text={(this.state.contact[rowData].cityBill!=undefined)?this.state.contact[rowData].cityBill:"--"} textExplication={strings.cityBill}  />
                              <TextPresentation style={{marginTop:5}} editable={false} text={(this.state.contact[rowData].phone!=undefined)?this.state.contact[rowData].phone:"--"} textExplication={strings.phone} placeholder={example.phone} />
                              <TextPresentation style={{marginTop:5}} editable={false} text={(this.state.contact[rowData].email!=undefined)?this.state.contact[rowData].email:"--"} textExplication={strings.email} placeholder={example.email} />
                        </TextPresentation>

                    ))}




                    {(this.state.forceGlobalUser==false) && this.state.alphabetList[key].map((rowData, elemID) => (
                          <View>

                          {(this.state.seeAllContact[key]==true || Platform.OS!="web" || elemID<10 || this.state.search!="") && <TextPresentation minimizeIcon={true} iconColorChip={(this.state.contact[rowData].partner==true)?((this.state.contact[rowData].raceInProgress!=undefined)?"#FEA812":((this.state.contact[rowData].partnerOnline==false)?style.backgroundColorContrast:"#4F8F00")):undefined} icon={(this.state.contact[rowData].partner==true)?( (style.mode!="dark")?require('../assets/partner_dark.png'):require('../assets/partner_light.png')) :((this.state.contact[rowData].manager==true)?( (style.mode!="dark")?require('../assets/user_manager_black.png'):require('../assets/user_manager_white.png')): ( (this.state.contact[rowData].provider==true)?( (style.mode!="dark")?require('../assets/user_partner_black.png'):require('../assets/user_partner_white.png')):((this.state.contact[rowData].customerPro==true)?( (style.mode!="dark")?require('../assets/user_pro_black.png'):require('../assets/user_pro_white.png')):  ( (style.mode!="dark")?require('../assets/user_black.png'):require('../assets/user_white.png')))) )} editable={false} style={{marginLeft:1, marginBottom:1}} reduceTextSizeOnOff={true} textWithBold={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?this.state.contact[rowData].enterprise:this.state.contact[rowData].lastName).toUpperCase()} text={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?"":this.state.contact[rowData].name)} textMini={(this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?(this.state.contact[rowData].lastName+" "+this.state.contact[rowData].name):undefined } onNext={value => {this.openClient(this.state.contact[rowData]); }} />}
                        </View>
                    ))}

                    {(this.state.seeAllContact[key]!=true && Platform.OS=="web" && this.state.alphabetList[key].length>10 && this.state.search=="") && <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { var seeAllContact=this.state.seeAllContact; seeAllContact[key]=true;  this.setState({seeAllContact:seeAllContact}) }} ><Text style={{marginBottom:10, textAlign: 'center',fontSize: 15,color: style.textInputColor,}} >{strings.seeAll+" ..."}</Text></TouchableHighlight>}

            </View>}
          </View>))}





          {this.state.selectView=="customer" && this.state.alphabetListCustomer.map((rowData,key) => (
              <View>{(this.state.alphabetListCustomer[key].length>0 && (key!=27 || this.state.viewLockedClient==true)) && <View>
                <View style={{height:30,backgroundColor:style.color1, alignItems:"center",flexDirection:"row" }}>
                  <Text style={{textAlign: 'left',fontSize: 20,color: style.backgroundColor,flex: 1,marginLeft:"5%",fontWeight:"bold"}}>{(key<26)?String.fromCharCode(65 + key):"123"}</Text>
                </View>

                {this.state.alphabetListCustomer[key].map((rowData) => (
                     <TextPresentation editable={false} style={{marginLeft:1, marginBottom:1}} reduceTextSizeOnOff={true} textWithBold={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?this.state.contact[rowData].enterprise:this.state.contact[rowData].lastName).toUpperCase()} text={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?"":this.state.contact[rowData].name)} textMini={(this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?(this.state.contact[rowData].lastName+" "+this.state.contact[rowData].name):undefined } onNext={value => {this.openClient(this.state.contact[rowData]); }} />
                
                ))}
              </View>}
          </View>))}



          {this.state.selectView=="customerPro" && this.state.alphabetListCustomerPro.map((rowData,key) => (
              <View>{(this.state.alphabetListCustomerPro[key].length>0 && (key!=27 || this.state.viewLockedClient==true)) && <View>
                <View style={{height:30,backgroundColor:style.color1, alignItems:"center",flexDirection:"row"}}>
                  <Text style={{textAlign: 'left',fontSize: 20,color: style.backgroundColor,flex: 1,marginLeft:"5%",fontWeight:"bold"}}>{(key<26)?String.fromCharCode(65 + key):"123"}</Text>
                </View>

                {this.state.alphabetListCustomerPro[key].map((rowData) => (
                     <TextPresentation editable={false} style={{marginLeft:1, marginBottom:1}} reduceTextSizeOnOff={true} textWithBold={this.state.contact[rowData].lastName.toUpperCase()} text={this.state.contact[rowData].name} onNext={value => {this.openClient(this.state.contact[rowData]); }} />
                
                ))}

              </View>}
          </View>
            
          ))}




          {this.state.selectView=="provider" && this.state.alphabetListProvider.map((rowData,key) => (
              <View>{(this.state.alphabetListProvider[key].length>0 && (key!=27 || this.state.viewLockedClient==true)) && <View>
                <View style={{height:30,backgroundColor:style.color1, alignItems:"center",flexDirection:"row"}}>
                  <Text style={{textAlign: 'left',fontSize: 20,color: style.backgroundColor,flex: 1,marginLeft:"5%",fontWeight:"bold"}}>{(key<26)?String.fromCharCode(65 + key):"123"}</Text>
                </View>

                {this.state.alphabetListProvider[key].map((rowData) => (
                     <TextPresentation editable={false} style={{marginLeft:1, marginBottom:1}} reduceTextSizeOnOff={true} textWithBold={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?this.state.contact[rowData].enterprise:this.state.contact[rowData].lastName).toUpperCase()} text={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?"":this.state.contact[rowData].name)} textMini={(this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?(this.state.contact[rowData].lastName+" "+this.state.contact[rowData].name):undefined } onNext={value => {this.openClient(this.state.contact[rowData]); }} />
                
                ))}

              </View>}
          </View>
            
          ))}


          {this.state.selectView=="partner" && this.state.alphabetListPartner.map((rowData,key) => (
              <View>{(this.state.alphabetListPartner[key].length>0 && (key!=27 || this.state.viewLockedClient==true)) && <View>
                <View style={{height:30,backgroundColor:style.color1, alignItems:"center",flexDirection:"row"}}>
                  <Text style={{textAlign: 'left',fontSize: 20,color: style.backgroundColor,flex: 1,marginLeft:"5%",fontWeight:"bold"}}>{(key<26)?String.fromCharCode(65 + key):"123"}</Text>
                </View>

                {this.state.alphabetListPartner[key].map((rowData) => (
                     <TextPresentation minimizeIcon={true} iconColorChip={((this.state.contact[rowData].raceInProgress!=undefined)?"#FEA812":((this.state.contact[rowData].partnerOnline==false)?style.backgroundColorContrast:"#4F8F00"))} icon={(style.mode!="dark")?require('../assets/partner_dark.png'):require('../assets/partner_light.png')} editable={false} style={{marginLeft:1, marginBottom:1}} reduceTextSizeOnOff={true} textWithBold={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?this.state.contact[rowData].enterprise:this.state.contact[rowData].lastName).toUpperCase()} text={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?"":this.state.contact[rowData].name)} textMini={(this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?(this.state.contact[rowData].lastName+" "+this.state.contact[rowData].name):undefined } onNext={value => {this.openClient(this.state.contact[rowData]); }} />
                
                ))}

                

              </View>}
          </View>
            
          ))}


          {this.state.selectView=="manager" && this.state.alphabetListManager.map((rowData,key) => (
              <View>{(this.state.alphabetListManager[key].length>0 && (key!=27 || this.state.viewLockedClient==true)) && <View>
                <View style={{height:30,backgroundColor:style.color1,alignItems:"center",flexDirection:"row"}}>
                  <Text style={{textAlign: 'left',fontSize: 20,color: style.backgroundColor,flex: 1,marginLeft:"5%",fontWeight:"bold"}}>{(key<26)?String.fromCharCode(65 + key):"123"}</Text>
                </View>


                 {this.state.alphabetListManager[key].map((rowData) => (
                     <TextPresentation editable={false} style={{marginLeft:1, marginBottom:1}} reduceTextSizeOnOff={true} textWithBold={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?this.state.contact[rowData].enterprise:this.state.contact[rowData].lastName).toUpperCase()} text={((this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?"":this.state.contact[rowData].name)} textMini={(this.state.contact[rowData].enterprise!=undefined && this.state.contact[rowData].enterprise!="")?(this.state.contact[rowData].lastName+" "+this.state.contact[rowData].name):undefined } onNext={value => {this.openClient(this.state.contact[rowData]); }} />
                
                ))}

                

              </View>}
          </View>
            
          ))}


          {(this.state.selectView=="all" && this.state.alphabetList.length>0 && this.state.search=="") && <Text style={{textAlign: 'center',fontSize: style.textInputFontSize,color: style.textInputColor,marginTop:30,marginBottom:0,fontWeight:"bold"}}>{this.state.number+" "+strings.contacts} </Text>}
          
          {(this.state.selectView=="customerPro" && this.state.alphabetListCustomerPro.length>0 && this.state.search=="") && <Text style={{textAlign: 'center',fontSize: style.textInputFontSize,color: style.textInputColor,marginTop:30,marginBottom:0,fontWeight:"bold"}}>{this.state.numberCustomerPro+" "+strings.customersPro}</Text>}
          {(this.state.selectView=="customer" && this.state.alphabetListCustomer.length>0 && this.state.search=="") && <Text style={{textAlign: 'center',fontSize: style.textInputFontSize,color: style.textInputColor,marginTop:30,marginBottom:0,fontWeight:"bold"}}>{this.state.numberCustomer+" "+strings.customers}</Text>}
          {(this.state.selectView=="partner" && this.state.alphabetListPartner.length>0 && this.state.search=="") && <Text style={{textAlign: 'center',fontSize: style.textInputFontSize,color: style.textInputColor,marginTop:30,marginBottom:0,fontWeight:"bold"}}>{this.state.numberPartner+" "+strings.partners}</Text>}
          {(this.state.selectView=="provider" && this.state.alphabetListProvider.length>0 && this.state.search=="") && <Text style={{textAlign: 'center',fontSize: style.textInputFontSize,color: style.textInputColor,marginTop:30,marginBottom:0,fontWeight:"bold"}}>{this.state.numberProvider+" "+strings.providersAffair} </Text>}
          {(this.state.selectView=="manager" && this.state.alphabetListManager.length>0 && this.state.search=="") && <Text style={{textAlign: 'center',fontSize: style.textInputFontSize,color: style.textInputColor,marginTop:30,marginBottom:0,fontWeight:"bold"}}>{this.state.numberManager+" "+strings.managers} </Text>}
          
          {this.state.lockedClient==true && this.state.viewLockedClient==false && <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { this.setState({viewLockedClient:true}) }} ><Text style={{textAlign: 'center',fontSize: style.textInputFontSize,color: style.textInputColor, fontWeight:"normal",marginBottom:(this.state.alphabetList.length>0 && this.state.selectClient!=true && this.state.selectView=="all" && this.state.search=="" && this.state.driver==true)?0:30, textDecorationLine: 'underline'}}>{strings.seeLockedAccount}</Text></TouchableHighlight>}  



          {(this.state.alphabetList.length>0 && this.state.selectClient!=true && this.state.selectView=="all" && this.state.search=="" && this.state.driver==true) && <View style={{alignItems:"center",justifyContent:"center", marginTop:10,marginBottom:30}}> 
              <MavButton text={strings.export} onlyButton={true} onPress={()=>{
               this.alert.onAlert({title:strings.export,subTitle:strings.whatExport,buttons:[
                {text: strings.customers,onPress: () => {this.exportData("customer")}},
                {text: strings.partners,onPress: () => {this.exportData("partner")}},
                {text: strings.providerAffair,onPress: () => {this.exportData("provider")}},
                {text: strings.all,onPress: () => {this.exportData("all")}},
                {text: strings.cancel,style: 'cancel',onPress: () => {}}]
              })
               

              }}/>
          </View>}



        </ScrollView>

        {(this.state.forceGlobalUser!=false && Platform.OS!="web") && <View style={{width:"100%",  position:"absolute", bottom:0}} >
            <MavMiniButton style={{marginBottom:20}} icon={(style.mode=="dark")?require('../assets/close_white.png'):require('../assets/close_black.png')} text={strings.close} onlyButton={true}  onPress={()=>{ this.props.close() }} />

        </View>}

        <MavAlert ref={alert => {this.alert = alert;}}/>

        <Modal
              animationType="slide"
              transparent={false}
              visible={(this.state.dataExported!="")}>
              <ScrollView style={{flex: 1,backgroundColor: style.backgroundColor}}>
                  <Text style={{marginTop:30,color: style.textInputColor,fontSize: style.textInputFontSize*1.5,textAlign: "center",}}>{strings.export}</Text>
                  <Text style={{marginTop:5,marginBottom:20,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",marginLeft:"5%", marginRight:"5%"}}>{strings.exportDataMoreInfo}</Text>


                  {(Platform.OS!="web") && <View style={{alignItems:"center",justifyContent:"center", marginTop:10}}> 
                        <MavButton text={strings.copy} onlyButton={true} onPress={()=>{Clipboard.setString(this.state.dataExported);this.setState({dataExported:"", dataExportedArray:[]}); this.alert.onAlert({title:strings.copiedClipboard}) }} /> 
                  </View>}

                  <View style={{marginLeft:"5%", marginRight:"5%"}}> 
                      {this.state.dataExportedArray.map((rowData) => (
                          <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,color:style.textInputColor}}>{rowData}</Text>
                      ))}
                      
                  </View>
                  
                  <View style={{alignItems:"center",justifyContent:"center", marginTop:30, marginBottom:30}}> 
                      <MavButton text={strings.close} onlyButton={true} onPress={()=>{this.setState({dataExported:"", dataExportedArray:[] }) }} /> 
                  </View>
              </ScrollView>
            
            </Modal>


       

        
      </View>}</StyleContext.Consumer>
    );
  }
}






export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

