export default[
	{"id":"fr","flag":require("./assets/flags/fr.png"), name:"Français" },
	{"id":"en","flag":require("./assets/flags/gb.png"), name:"English"  },
	{"id":"it","flag":require("./assets/flags/it.png"), name:"Italiano" },
	{"id":"es","flag":require("./assets/flags/es.png"), name:"Español"  },
	{"id":"ru","flag":require("./assets/flags/ru.png"), name:"Pусский"  },
	
	   ]


