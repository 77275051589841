export default[
   {
      "value": -12,
      "text": "Eniwetok"
   },
   {
      "value": -11,
      "text": "Samoa"
   },
   {
      "value": -10,
      "text": "Hawaii"
   },
   {
      "value": -9,
      "text": "Alaska"
   },
   {
      "value": -8,
      "text": "Los Angeles"
   },
   {
      "value": -7,
      "text": "Calgary"
   },
   {
      "value": -6,
      "text": "Mexico"
   },
   {
      "value": -5,
      "text": "Bogota"
   },
   {
      "value": -4,
      "text": "Caracas"
   },
   {
      "value": -4,
      "text": "Guadeloupe"
   },
   {
      "value": -3.5,
      "text": "Terre-Neuve"
   },
   {
      "value": -3,
      "text": "Rio de Janeiro"
   },
   {
      "value": -2,
      "text": "Pernambouc"
   },
   {
      "value": -1,
      "text": "Cap Vert"
   },
   {
      "value": 0,
      "text": "London"
   },
   {
      "value": 1,
      "text": "Paris"
   },
   {
      "value": 1,
      "text": "Madrid"
   },
   {
      "value": 1,
      "text": "Brussels"
   },
   {
      "value": 1,
      "text": "Roma"
   },
   {
      "value": 1,
      "text": "Copenhagen"
   },
   {
      "value": 2,
      "text": "Kaliningrad"
   },
   {
      "value": 3,
      "text": "Moscow"
   },
   {
      "value": 3.5,
      "text": "Tehran"
   },
   {
      "value": 4,
      "text": "Abu Dhabi"
   },
   {
      "value": 4.5,
      "text": "Kabul"
   },
   {
      "value": 5,
      "text": "Karachi"
   },
   {
      "value": 5.5,
      "text": " Bombay"
   },
   {
      "value": 5.75,
      "text": "Kathmandu"
   },
   {
      "value": 6,
      "text": "Colombo"
   },
   {
      "value": 7,
      "text": "Jakarta"
   },
   {
      "value": 8,
      "text": "Hong Kong"
   },
   {
      "value": 9,
      "text": "Tokyo"
   },
   {
      "value": 9.5,
      "text": "Adelaide"
   },
   {
      "value": 10,
      "text": "Guam"
   },
   {
      "value": 11,
      "text": "Magadan"
   },
   {
      "value": 12,
      "text": "Auckland"
   }
]
