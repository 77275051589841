import React, { Component } from 'react';
import {
View,
  Text,
  ScrollView,TouchableHighlight,FlatList,Platform,Dimensions,Animated,Image,Linking, Easing,Modal, Clipboard,ActivityIndicator,
} from 'react-native';
import {StyleContext} from '../styleC';

import firebase from './firebase';
import { getAuth, } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";


import strings from '../strings';
import info from '../info';
import MavTextInput from './mavTextInput';
import MavInfoBubble from './mavInfoBubble';
const timer = require('react-native-timer');
import MavButton from './mavButton';
import MavAlert from './mavAlert';
import MavNavigationMinimize from './mavNavigationMinimize';
import TextPresentation from './textPresentation';
import MavViewMore from './mavViewMore';


export default class help extends Component {
  constructor(props) {
    super(props);
    this.state={
      viewAddCar:false,
      viewAddNewSystem:false,
      addCar:{name:"",passagers:1,luggageMax:0},
      listCar:[],
      listCarModif:[],
      carListName:{},
      apiList:{},
      pricing:{}, 
      waiting:true, 
      withKarhoo:false,
      withLeTaxi:false,
      taxiMode:false,
      modeView:"",


      }
  }
  
   componentDidMount(){

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
    this.setState({modeView:params.modeView})

    if(params.modeView=="karhoo"){
        this.getAllCar();
        if(params.withKarhoo==true) {
          this.getKarhooInfo();
          this.setState({taxiMode:params.taxiMode,extraEUMode:params.extraEUMode })
        }
        else this.setState({waiting:false})
    }
    else if(params.modeView=="letaxi"){
      if(params.withLeTaxi==true) {

        this.getDriversTaxiList(); 

      }

      else this.setState({waiting:false})
    }

    else if(params.modeView=="normal") this.getAPIList();
    


    

   
    }

    getDriversTaxiList(){

      get(query(ref(getDatabase(firebase), "/users/"), orderByChild('partner'), startAt(true), endAt(true))).then(value => {

        var val=value.val()
        var letaxiActualConnected={}
        var leTaxiDriverList=[]

        if(val!=null){
          var i;
          var arrayDriver=Object.keys(val)
          for(i=0;i<arrayDriver.length;i++){
            val[arrayDriver[i]].id=arrayDriver[i]
            if(val[arrayDriver[i]].letaxiID!=undefined) letaxiActualConnected[arrayDriver[i]]=val[arrayDriver[i]]
            else leTaxiDriverList.push(val[arrayDriver[i]])
          }

        }
        this.setState({withLeTaxi:true, letaxiActualConnected:letaxiActualConnected, waiting:false, leTaxiDriverList:leTaxiDriverList})

      })
    }

    viewTaxiList(){

      /*j'affiche une alerte avec les chauffeurs */
      var selected=-1;
      var i;
      var partnerList=JSON.parse(JSON.stringify(this.state.leTaxiDriverList));
      for(i=0;i<this.state.leTaxiDriverList.length;i++){
          if(this.state.leTaxiDriverList[i].id==this.state.addTaxiInfo.id) selected=i;
          partnerList[i].text=(partnerList[i].name+" "+partnerList[i].lastName)
      }
      
     
      this.alert.onAlert({title:"Choisir le chauffeur", items:partnerList ,itemSelected:selected, buttons:[{text: strings.validate,onPress: (id,value) => {console.log(partnerList[id]); this.setState({addTaxiInfo:partnerList[id]}) }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })  
      


    }


    
    removeLeTaxiDriver(rowData){
          
          /*je supprime chez le chauffeur*/
          var letaxiActualConnected=JSON.parse(JSON.stringify(this.state.letaxiActualConnected))
          delete letaxiActualConnected[rowData];
          this.setState({letaxiActualConnected:letaxiActualConnected})

          console.log(rowData)
          const fireInfo={} 
          fireInfo['/users/'+rowData+"/letaxiID"]=null
          update(ref(getDatabase(firebase)), fireInfo)

    }


    validateCreateTaxi(){

      if(this.state.addTaxiInfo.ntaxi==undefined || this.state.addTaxiInfo.ntaxi=="") this.alert.onAlert({title:"Merci de mettre le numéro de licence"})
      else if(this.state.addTaxiInfo.carModel==undefined || this.state.addTaxiInfo.carModel[0]==undefined) this.alert.onAlert({title:"Merci de mettre les informations sur le véhicule"})
      else if(this.state.addTaxiInfo.carModel[0].immatriculation==undefined || this.state.addTaxiInfo.carModel[0].brand==undefined || this.state.addTaxiInfo.carModel[0].model==undefined || this.state.addTaxiInfo.carModel[0].immatriculation=="" || this.state.addTaxiInfo.carModel[0].brand=="" || this.state.addTaxiInfo.carModel[0].model=="") this.alert.onAlert({title:"Merci de mettre les informations sur le véhicule"})
      else if(this.state.addTaxiInfo.nads==undefined || this.state.addTaxiInfo.nads=="") this.alert.onAlert({title:"Merci de mettre le numéro ADS"})
      else if(this.state.addTaxiInfo.inseeAds==undefined || this.state.addTaxiInfo.inseeAds=="") this.alert.onAlert({title:"Merci de mettre la ville de l'ADS"})
      else {
        this.setState({waiting:true})
      var data=[
          {

            "driverInfo":{
              "departement": {
                "numero": this.state.addTaxiInfo.departement
              },
              "professional_licence": this.state.addTaxiInfo.ntaxi, 
              "first_name": this.state.addTaxiInfo.name,
              "last_name": this.state.addTaxiInfo.lastName,
            },

            "ads": {
              "insee": this.state.addTaxiInfo.inseeAds,
              "numero": this.state.addTaxiInfo.nads
            },
            "driver": {
              "departement": this.state.addTaxiInfo.departement,
              "professional_licence": this.state.addTaxiInfo.ntaxi, 
            },
            "vehicle": {
              "licence_plate": this.state.addTaxiInfo.carModel[0].immatriculation,
              "constructor": this.state.addTaxiInfo.carModel[0].brand,
              "model": this.state.addTaxiInfo.carModel[0].model,
            }
          }
      ]

      
      getAuth(firebase).currentUser.getIdToken().then(token => {
           var infoSend={token:token,securisedTask:true,userID:getAuth(firebase).currentUser.uid, data:data,type:"letaxiCreateDriver"}

           fetch("https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/api", {
            body: JSON.stringify(infoSend),
            method: "POST"}).then((response) => response.json()).then((responseJson) => {
              if(typeof(responseJson)=="string") responseJson=JSON.parse(responseJson)
              
              console.log(responseJson.data)
              if(responseJson!=undefined && responseJson.data!=undefined && responseJson.data[0]!=undefined && responseJson.data[0].id!=undefined){

                    /*je vais stocker l'id dans le chauffeur letaxiID*/

                    /*je vais mettre à jour les infos côté chauffeur à savoir inseeAds, departement, nads, ntaxi*/
                    const fireInfo={} 
                    fireInfo['/users/'+this.state.addTaxiInfo.id+"/inseeAds"]=this.state.addTaxiInfo.inseeAds
                    fireInfo['/users/'+this.state.addTaxiInfo.id+"/cityAds"]=this.state.addTaxiInfo.cityAds
                    fireInfo['/users/'+this.state.addTaxiInfo.id+"/departement"]=this.state.addTaxiInfo.departement
                    fireInfo['/users/'+this.state.addTaxiInfo.id+"/nads"]=this.state.addTaxiInfo.nads
                    fireInfo['/users/'+this.state.addTaxiInfo.id+"/ntaxi"]=this.state.addTaxiInfo.ntaxi
                    fireInfo['/users/'+this.state.addTaxiInfo.id+"/letaxiID"]=responseJson.data[0].id
                    update(ref(getDatabase(firebase)), fireInfo)


                     /*je supprime le taxi de la liste */
                      /*j'ajoute les infos dans letaxiActualConnected */
                      var i;
                      for(i=0; i<this.state.leTaxiDriverList.length;i++){
                        console.log(this.state.leTaxiDriverList[i])
                        console.log("next")
                        console.log(this.state.addTaxiInfo.id)
                        if(this.state.leTaxiDriverList[i].id==this.state.addTaxiInfo.id){
                          var leTaxiDriverList=JSON.parse(JSON.stringify(this.state.leTaxiDriverList))
                          leTaxiDriverList.splice(i, 1);
                          this.setState({leTaxiDriverList:leTaxiDriverList});
                        }
                      }
                      var letaxiActualConnected=JSON.parse(JSON.stringify(this.state.letaxiActualConnected))
                      var addTaxiInfo=this.state.addTaxiInfo;
                      addTaxiInfo.letaxiID=responseJson.data[0].id;
                      letaxiActualConnected[this.state.addTaxiInfo.id]=addTaxiInfo

                      this.setState({addTaxi:false, addTaxiInfo:{}, waiting:false,letaxiActualConnected:letaxiActualConnected }) 



                }
                          

               
               

            
            else { this.setState({waiting:false}); this.alert.onAlert({title:strings.errorDefault}); }



            })
      })

      }
    }





    getAPIList(){
      get(child(ref(getDatabase(firebase)), '/apiInfo')).then(value => {

        var val=value.val(); 
        if(val!=null){

          this.setState({waiting:false, apiList:val})

        } else this.setState({waiting:false})

      })
    }

    arrangePricing(){

      var i; 
      var pricing=JSON.parse(JSON.stringify(this.state.pricing));
      var pricingArray=Object.keys(pricing)
      var taxiZone=[];
      var taxiForfait=[];
      var zone=[];
      var forfait=[]

      for(i=0; i<pricingArray.length;i++){
        
        if(pricing[pricingArray[i]].taxiMode==true){
            if(pricing[pricingArray[i]].radius!=undefined) taxiZone.push({info:pricing[pricingArray[i]],id:pricingArray[i]} )
            else taxiForfait.push({info:pricing[pricingArray[i]],id:pricingArray[i]} )
        }
        else {
            if(pricing[pricingArray[i]].radius!=undefined) zone.push({info:pricing[pricingArray[i]],id:pricingArray[i]} )
            else forfait.push({info:pricing[pricingArray[i]],id:pricingArray[i]} )
        }
      }

          taxiZone.sort(function(a, b){if(a.info.radius>b.info.radius) return 1;if(b.info.radius>a.info.radius) return -1; else return 0;});
          taxiForfait.sort(function(a, b){if(a.info.startRadius>b.info.startRadius) return 1;if(b.info.startRadius>a.info.startRadius) return -1; else return 0;});
          zone.sort(function(a, b){if(a.info.radius>b.info.radius) return 1;if(b.info.radius>a.info.radius) return -1; else return 0;});
          forfait.sort(function(a, b){if(a.info.startRadius>b.info.startRadius) return 1;if(b.info.startRadius>a.info.startRadius) return -1; else return 0;});
          
          var finalPrArray=forfait.concat(zone).concat(taxiForfait).concat(taxiZone)
          var finalPr={};
          for(i=0; i<finalPrArray.length;i++){

              finalPr[ finalPrArray[i].id ]=finalPrArray[i].info;
          }


          this.setState({pricing:finalPr})

    }

    getKarhooInfo(){
        getAuth(firebase).currentUser.getIdToken().then(token => {
                  var body={typeRequest:"getConfig", adminID:getAuth(firebase).currentUser.uid, bundleIdentifer:info.bundleIdentifer,token:token,bundleAdress:info.firebaseProjectID,version:info.appliVersion}
                  
                  fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/karhooParams", {body: JSON.stringify(body),method: "POST"}).then((response) => response.json()).then((responseJson) => {
                    this.setState({waiting:false, withKarhoo:true})

                    if(responseJson.carList!=undefined) this.setState({listCar:responseJson.carList, listCarModif:responseJson.carList})
                    if(responseJson.pricing!=undefined) this.setState({pricing:responseJson.pricing},() =>{  this.arrangePricing()})

                    if(Object.keys(responseJson).length==0){
                      /*je créé et j'envoi un apiInfo*/
                      var clientID=basicUUID();
                      var clientSecret=basicUUID();
                      var clientSecretHash=hashFnv32a(clientSecret)

                      var fleetInfo={clientID:clientID, clientSecret:clientSecret, url:"https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/"}
                      this.sendModification("fleetInfo", fleetInfo);
                      var date=Date.parse(new Date())
                      /*je stocke aussi ça de mon côté */
                      const fireInfo={} 
                      fireInfo['/apiInfo/'+clientID]={secret:clientSecretHash, idSecret:(clientSecret[0]+""+clientSecret[1]+""+clientSecret[2]+""+clientSecret[3]+""+clientSecret[4]), karhoo:true,date:date  }
                      update(ref(getDatabase(firebase)), fireInfo)


                    }
                      
                    
                  }).catch((err) => { console.log("error getKarhooInfo"); console.log(err); timer.setTimeout("getKarhooInfo", () => { this.getKarhooInfo();  }, 2000);   })
                    

                })

    }

    getAllCar(){
      get(child(ref(getDatabase(firebase)), '/pricing')).then(value => {
        var val1=value.val();
        var val1array=Object.keys(val1)
        var carList={};
        var i, j;
        var pr,pra;

        if(val1!=null){
            for(i=0;i<val1array.length;i++){
                pr=val1[val1array[i]].price;
                pra=Object.keys(pr)
                for(j=0;j<pra.length;j++) carList[pr[pra[j]].name]=true;
            }

          }

          this.setState({carListName:carList})

       }) 

  }


    sendModification(typeModif, params){



      getAuth(firebase).currentUser.getIdToken().then(token => {
                  var body; 

                  if(typeModif=="carType")  body={typeRequest:"setCar",newParams:this.state.listCar, adminID:getAuth(firebase).currentUser.uid, bundleIdentifer:info.bundleIdentifer,token:token,bundleAdress:info.firebaseProjectID,version:info.appliVersion}
                  else if(typeModif=="fleetInfo") body={typeRequest:"setFleetInfo",newParams:params, adminID:getAuth(firebase).currentUser.uid, bundleIdentifer:info.bundleIdentifer,token:token,bundleAdress:info.firebaseProjectID,version:info.appliVersion}

                  fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/karhooParams", {body: JSON.stringify(body),method: "POST"}).then((response) => response.json()).then((responseJson) => {
                    
                    console.log(responseJson)
                    
                  }).catch((err) => { console.log("error sendModification"); console.log(err); timer.setTimeout("sendModification", () => { this.sendModification(typeModif, params);  }, 2000);   })

                })



    }

    viewPricingDetail(data,id){
      data=JSON.parse(JSON.stringify(data));
      data.id=id;

      var i,j;
      var priceArray=Object.keys(data.price); 

      for(i=0; i<priceArray.length;i++){

          for(j=0; j<this.state.listCar.length;j++){
            if(this.state.listCar[j].id==data.price[priceArray[i]].type){
              console.log("ici")
                data.price[priceArray[i]].name=this.state.listCar[j].name
                delete data.price[priceArray[i]].type; 
            }
          }
        }

      this.navigateTo('PricePlaceSettings', {data:data , extraEUMode:this.state.extraEUMode, karhooMode:true,  updateData: this.updatePricing, carListName:this.state.listCar})
    }

    viewListCarName(typeView,iView){


      if(typeView=="save"){
        
        var listCar=JSON.parse(JSON.stringify(this.state.listCar));
        var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif));
        delete listCarModif[iView].modified;
        listCar[iView]=JSON.parse(JSON.stringify(listCarModif[iView]));

        /*modifier parmi les forfaits / zones */
        this.setState({listCar:listCar, listCarModif:listCarModif},() =>{  this.sendModification("carType");  })

        this['viewCar_' + iView].close() 
        this.alert.onAlert({title:"Le véhicule a été modifié", subTitle:"Une fois que vous avez fini de modifier les véhicules, n'oubliez pas d'envoyer vos modifications à Karhoo (récupération de la configuration à l'étape suivante)"}); 
      }
      else if(typeView=="saveAdd"){
        if(this.state.addCar.name!=""){
            
            var listCar=JSON.parse(JSON.stringify(this.state.listCar)); 
            var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif)); 
            var infoCar=this.state.addCar;
            infoCar.id=generateUUID().split('-')[0]
            listCar.push(infoCar);
            listCarModif.push(infoCar); 
            this.setState({listCar:listCar,listCarModif:listCarModif,viewAddCar:false,addCar:{name:"",passagers:1,luggageMax:0} },() =>{  this.sendModification("carType");  })

        } else this.alert.onAlert({title:strings.pricingErrorCar}); 
      }
      else if(typeView=="remove"){

        var listCar=JSON.parse(JSON.stringify(this.state.listCar));
        var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif));
        var idCar=listCar[iView].id; 
        listCar.splice(iView, 1);
        listCarModif.splice(iView, 1);

        /*modifier parmi les forfaits / zones */
        this.setState({listCar:[], listCarModif:[]},() =>{ this.setState({listCar:listCar, listCarModif:listCarModif},() =>{ this.removeCarPricing(idCar); this.sendModification("carType");  })  })
        
        this.alert.onAlert({title:"Le véhicule a été supprimé", subTitle:"Une fois que vous avez fini de modifier les véhicules, n'oubliez pas d'envoyer vos modifications à Karhoo (récupération de la configuration à l'étape suivante)"}); 

      }
      else {
        var prov=Object.keys(this.state.carListName)
        var items=[]
        var i;
        var itemSelected=-1;

        
        for(i=0;i<prov.length;i++){
          let index=prov[i]
          if(typeView=="add" && prov[i]==this.state.addCar.name) itemSelected=i;
          else if(typeView=="modif" && prov[i]==this.state.listCarModif[iView].name) itemSelected=i;
          items.push({itemSelected:itemSelected,text: prov[i] })
        }

        this.alert.onAlert({title:strings.selectName,items:items,itemSelected:itemSelected, buttons:[{text: strings.validate,onPress: (id,value) => {
          if(typeView=="add"){
             var addCar=this.state.addCar;addCar.name=value;this.setState({addCar:addCar});

          }
          else {
              var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif)); listCarModif[iView].modified=true; listCarModif[iView].name=value;this.setState({listCarModif:listCarModif}) 
          }
         
        }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })

      }


    }

    navigateTo= (link,params) => {
   
    if(this.props.navigation!=undefined) this.props.navigation.navigate(link, params);
    else this.props.params.navigate(link, params);
  }

    addPartner= (id) => {

      this.alert.onAlert({title:"Vous allez devoir indiquer le compte client de l'entreprise", subTitle:"Pour connecter un système extérieur à votre application, l'entreprise en question doit posséder un compte. Si l'entreprise avec qui vous souhaitez travailler ne possède pas de compte, il faudra en créer un avant. Un compte 'manuel' ne suffit pas, c'est un 'vrai' compte qu'il faut", buttons:[
              {text: "Suivant",onPress: () => { this.navigateTo('Clients',{selectClient: true, onClientSelected: this.onClientSelected, driver:true, realCustomerOnly:true }) }},
              {text: strings.cancel, style:"cancel",onPress: () => {}}
              ]});
    }

      onClientSelected = data => {

        if(this.state.apiList[data.clientID]!=undefined) this.alert.onAlert({title:"Vous avez déjà un système connecté avec cette entreprise", subTitle:"Si vous voulez créer un deuxième accès, vous devrez recréer un autre compte. Si vous voulez remplacer l'accès (car vous avez perdu le secret_id par exemple, vous devrez d'abord supprimer l'ancien accès" })
        else{
            var name=(data.enterprise!=undefined && data.enterprise!="")?data.enterprise:(data.name+" "+data.lastName)
            var clientID=data.clientID;
            var clientSecret=basicUUID();
            var clientSecretHash=hashFnv32a(clientSecret)

            var fleetInfo={client_id:clientID, client_secret:clientSecret, url:"https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/", docURL:"https://mon-appli-vtc.com/api/"}
            

            var date=Date.parse(new Date())
            /*je stocke aussi ça de mon côté */
            var sendTF={name:name,secret:clientSecretHash, idSecret:(clientSecret[0]+""+clientSecret[1]+""+clientSecret[2]+""+clientSecret[3]+""+clientSecret[4]),date:date  }
           
            const fireInfo={} 
            fireInfo['/apiInfo/'+clientID]=sendTF
            update(ref(getDatabase(firebase)), fireInfo)


            /*je rafraichi l'affichage*/
            var apiList=JSON.parse(JSON.stringify(this.state.apiList)); 
            apiList[clientID]=sendTF;
            this.setState({apiList:apiList}) 

            Clipboard.setString(JSON.stringify(fleetInfo)); this.alert.onAlert({title:"Les infos à transmettre aux développeurs ont été copié dans le presse-papier", subTitle:"Attention, le secret_id n'est pas récupérable. Si vous le perdez, vous devrez supprimer cette connexion et en re-creer une autre"});


        }

        

      }


      removeApiList= id => {

        this.alert.onAlert({title:"Etes-vous sûr de vouloir supprimer ?", subTitle:"L'entreprise n'aura plus accès aux informations de courses, et ne pourra plus en ajouter non plus", buttons:[
              {text: strings.validate,onPress: () => { 
                  const fireInfo={} 
                  fireInfo['/apiInfo/'+id]=null
                  update(ref(getDatabase(firebase)), fireInfo)

                  var apiList=JSON.parse(JSON.stringify(this.state.apiList)); 
                  delete apiList[id];
                  this.setState({apiList:apiList}) 


               }},
              {text: strings.cancel, style:"cancel",onPress: () => {}}
              ]});

      }


    updatePricing= (id,sendToFirebase) => {
        
        if(sendToFirebase.price!=undefined){

            var i,j;
            var priceArray=Object.keys(sendToFirebase.price); 

            for(i=0; i<priceArray.length;i++){

              for(j=0; j<this.state.listCar.length;j++){
                if(this.state.listCar[j].name==sendToFirebase.price[priceArray[i]].name){
                    sendToFirebase.price[priceArray[i]].type=this.state.listCar[j].id
                    delete sendToFirebase.price[priceArray[i]].name; 
                    delete sendToFirebase.price[priceArray[i]].co2
                    delete sendToFirebase.price[priceArray[i]].passagersMin
                    delete sendToFirebase.price[priceArray[i]].provCom
                    
                }
              }
            }

        }
        
        var pricing=JSON.parse(JSON.stringify(this.state.pricing));
        if(sendToFirebase.action=="remove") delete pricing[id];
        else pricing[id]=sendToFirebase;
        this.setState({pricing:pricing},() =>{  this.arrangePricing()})

        getAuth(firebase).currentUser.getIdToken().then(token => {
          var body;
          if(sendToFirebase.price!=undefined) body={typeRequest:"setPrice",priceID:id, newParams:sendToFirebase, adminID:getAuth(firebase).currentUser.uid, bundleIdentifer:info.bundleIdentifer,token:token,bundleAdress:info.firebaseProjectID,version:info.appliVersion}
          else body={typeRequest:"setGlobalPrice",newParams:pricing, adminID:getAuth(firebase).currentUser.uid, bundleIdentifer:info.bundleIdentifer,token:token,bundleAdress:info.firebaseProjectID,version:info.appliVersion}
          
          fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/karhooParams", {body: JSON.stringify(body),method: "POST"}).then((response) => response.json()).then((responseJson) => {
            
            console.log(responseJson)
            
          }).catch((err) => { console.log("error updatePricing"); console.log(err); timer.setTimeout("updatePricing", () => { this.updatePricing();  }, 2000);   })

        })



    }

    removeCarPricing= (idCar) => {
      console.log("je supprime la voiture suivante "+idCar)
      var pricing=JSON.parse(JSON.stringify(this.state.pricing));
      var pricingArray=Object.keys(pricing)
      var i, j;
      var priceArray; 
      var pricingFinal={}

      for(i=0; i<pricingArray.length;i++){

        

        priceArray=Object.keys(pricing[pricingArray[i]].price)
        for(j=0; j<priceArray.length;j++){
            if(pricing[pricingArray[i]].price[priceArray[j]].type==idCar) delete pricing[pricingArray[i]].price[priceArray[j]]
        }

        
        if(Object.keys(pricing[pricingArray[i]].price).length>0) pricingFinal[pricingArray[i]]=pricing[pricingArray[i]]; 
      }

      getAuth(firebase).currentUser.getIdToken().then(token => {
          var body={typeRequest:"setGlobalPrice",newParams:pricingFinal, adminID:getAuth(firebase).currentUser.uid, bundleIdentifer:info.bundleIdentifer,token:token,bundleAdress:info.firebaseProjectID,version:info.appliVersion}
          
          fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/karhooParams", {body: JSON.stringify(body),method: "POST"}).then((response) => response.json()).then((responseJson) => {
            
            console.log(responseJson)
            
          })

        })
      
      this.setState({pricing:pricingFinal},() =>{  this.arrangePricing()})


    }



     sendMessageToKarhoo= (data) => {


        getAuth(firebase).currentUser.getIdToken().then(token => {
                    var body={
                      userID:getAuth(firebase).currentUser.uid,
                      bundleIdentifer:info.bundleIdentifer,
                      message:"Bonjour,<br>"+info.companyBrand+" dont l'identifiant de flotte est "+info.bundleIdentifer+" vous informe de ses nouveaux identifiants : "+JSON.stringify(this.state.listCar)+"<br>Bonne journée de la part de Mon Appli VTC",
                      from: {Email: info.mavEmail,Name: info.companyBrand},
                      to: [{Email: "di.fazio@hotmail.fr",Name: "Karhoo"},{Email: "nassima.mahdaoui@karhoo.com",Name: "Karhoo"}, ], 
                      token:token,
                      subject:"[MON APPLI VTC] Modification des véhicules pour "+info.companyBrand,
                      bundleAdress:info.firebaseProjectID,
                  }
                    fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/sendEmail", {body: JSON.stringify(body),method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {this.alert.onAlert({title:"Message envoyé",subTitle:"Votre conseiller Karhoo a été prévenu de la configuration de vos véhicules et fera le nécessaire"}) }).catch((err) => {this.alert.onAlert({title:strings.errorDefault}); })
                  
                  })      

  }
      







  render() {
    return (
      <StyleContext.Consumer>{style =>
        <View style={{backgroundColor:style.backgroundColor,flex:1,}}>
        <ScrollView style={{}} >



            {(this.state.modeView=="normal") && <View style={{flex:1 }}>


              <MavInfoBubble type={"menu"} icon={(style.mode=="dark")?require('../assets/api_white.png'):require('../assets/api_black.png')} title={"Soyez ouvert aux autres systèmes avec nos API !"} subTitle={"Nous vous proposons des API. Les API pour 'interfaces de programmation' permettent aux différents systèmes d’intéragir ensemble. Par exemple, on peut imaginer qu’une collectivité locale possédant une application de mobilité puisse proposer vos services dans son appli ou encore, une agence de voyages possédant un logiciel de gestion interne puisse commander le VTC en restant dans son interface. \nBien sûr, vous verrez les courses dans l’application comme avant"}  style={{marginTop:10,marginBottom:20}} />
              <MavInfoBubble type={"help"} videoLinek={"http://www.google.fr"} others={[{title:"En savoir plus sur nos API", link:"https://mon-appli-vtc.com/api/"}]}  style={{marginTop:0,marginBottom:20}} />
              <MavInfoBubble type={"info"} title={"Requêtes Google Maps"} subTitle={"Les API peuvent engendrer un nombre très important de demandes de prix. Compte tenu du prix facturé par Google pour l'accès à Google Maps et de la dé-corélation entre le nombre de requête et le nombre de chauffeurs, nous ne pouvons l'utiliser. Nous utilisons un algorithme à la place qui estime le nombre de kilomètre et le prix. Il peut donc y avoir un léger décalage entre l'API et l'appli. Si vous le souhaitez, nous pouvons tout de même utiliser Google Maps. Vous trouverez les tarifs à cette adresse : https://cloud.google.com/maps-platform/pricing/sheet?hl=fr"} style={{marginTop:10,}} />

              <Text style={{marginTop:3,marginTop:15, marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{"Comptes connectés à l'API"}</Text>

              {(Object.keys(this.state.apiList).length==0  || ( Object.keys(this.state.apiList).length==1 && this.state.apiList[Object.keys(this.state.apiList)[0]].karhoo==true ) ) && <MavInfoBubble type={"info"} miniLine={true} title={strings.noSystemConnected} subTitle={strings.clickOnAddForfait} style={{marginTop:0,}} />}

              {Object.keys(this.state.apiList).map((rowData,i) => (
                    <View style={{width:"100%"}}>
                       {this.state.apiList[rowData].karhoo!=true && <TextPresentation style={{marginBottom:5}} editable={false} text={this.state.apiList[rowData].name} icon={(style.mode=="dark")?require('../assets/api_white.png'):require('../assets/api_black.png')}>
                              
                            <TextPresentation editable={false} textExplication={"Client ID"} text={rowData.slice(0, 25)+" ..." } />
                            <TextPresentation editable={false} textExplication={"Client Secret"} textMini={"Pour des raisons de sécurité, si cet identifiant a été perdu, il n'est pas récupérable. Vous devrez supprimer ce compte et refaire la procedure d'ajout de compte"} text={this.state.apiList[rowData].idSecret+ " ..." } />
                            <TextPresentation editable={false} disableLine={true} textExplication={"Date d'ajout"} text={ ((new Date(this.state.apiList[rowData].date).getDate()<10)?"0":"")+new Date(this.state.apiList[rowData].date).getDate()+"/"+((new Date(this.state.apiList[rowData].date).getMonth()<9)?"0":"")+(new Date(this.state.apiList[rowData].date).getMonth()+1)+"/"+new Date(this.state.apiList[rowData].date).getFullYear()+" - "+((new Date(this.state.apiList[rowData].date).getHours()<10)?"0":"")+new Date(this.state.apiList[rowData].date).getHours()+":"+((new Date(this.state.apiList[rowData].date).getMinutes()<10)?"0":"")+new Date(this.state.apiList[rowData].date).getMinutes() } />
                              
                            <View style={{alignItems:"center",justifyContent:"center", marginTop:10, flexDirection:"row"}}> 
                                <MavButton onlyButton={true} style={{marginLeft:5}} strokeMode={true} text={strings.remove} onPress={()=>{ this.removeApiList(rowData) }}/>
                            </View>
                    </TextPresentation>}
                    </View>
                ))}
              </View>}

            {(this.state.waiting==false && this.state.modeView=="karhoo") && <MavInfoBubble type={"menu"} icon={(style.mode=="dark")?require('../assets/logo_karhoo_white.png'):require('../assets/logo_karhoo_black.png')} title={"Bienvenue chez Karhoo"} subTitle={(this.state.withKarhoo==true)?"Afin de travailler avec Karhoo, vous devez faire quelques réglages. L'application peut récupérer certains réglages mais pas tous. Compte tenu du fonctionnement de leur système, il est nécessaire de faire quelques réglages spécifiques. Par exemple, vous ne pouvez pas récupérer les prix défini dans 'Gestion des tarifs'. En effet, certaines informations doivent être envoyé en amont car leurs clients peuvent avoir des demandes spécifiques et cela permet de mieux cibler les entreprises à contacter":undefined}  style={{marginTop:10,marginBottom:20}} />}


            {(this.state.waiting==false && this.state.modeView=="letaxi") && <MavInfoBubble type={"menu"} icon={(style.mode=="dark")?require('../assets/letaxi_white.png'):require('../assets/letaxi_black.png')} title={"Bienvenue chez le.taxi"} subTitle={(this.state.withLeTaxi==true)?"Afin de travailler avec le.taxi, vous devez définir quelles sont les chauffeurs (uniquement les taxis) qui pourront travailler avec le.taxi. Attention, il s'agit d'une maraude électronique, on ne connait donc pas le trajet que souhaite faire le client et c'est pour ça que vous n'avez pas de réglages de prix à faire":undefined}  style={{marginTop:10,marginBottom:20}} />}

             




             
            {(this.state.waiting==true) && <ActivityIndicator style={{marginTop:30}} size="large" color={style.color1} />}


            {(this.state.waiting==false && this.state.withKarhoo==false && this.state.modeView=="karhoo") && <View>

                <View style={{width:"100%", alignItems:"center",justifyContent:"center"}}> 
                    <Image source={{uri:"https://www.karhoo.com/wp-content/uploads/2020/11/Img38_how-it-works-.png"}} style={{height:150,width:"80%",resizeMode:"contain"}}/>
                </View>

                <Text style={{marginLeft:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.1), marginRight:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.1), color: style.textInputColor,fontSize: style.textInputFontSize*0.9,textAlign: "justify",}}>{"Karhoo est une place de marché digitale qui connecte les flottes du monde entier à des partenaires commerciaux (SNCF, groupe Accor, Fidelia, Booking, Appartcity, etc).\n- On vous donne accès à des courses supplémentaires pour renforcer votre activité, sans commission !\n- On est connecté à Mon Appli VTC donc l’activation est simple, gratuite et ne change pas vos opérations.\n- C’est vous qui décidez : vos règles de dispatch, votre disponibilité, vos prix et vos conditions de vente. \nPour plus d’informations allez faire un tour sur notre site internet :"}</Text>
                
                <View style={{alignItems:"center",justifyContent:"center", marginTop:20,marginBottom:10, flexDirection:"row"}}>
                    <MavButton text={"Voir le site"} onlyButton={true} onPress={()=>{ Linking.openURL("https://www.karhoo.com/flottes-taxi-vtc/") }}/>
                </View>

            </View>}


            {(this.state.waiting==false && this.state.withLeTaxi==false && this.state.modeView=="letaxi") && <View>

                <View style={{width:"100%", alignItems:"center",justifyContent:"center"}}> 
                    <Image source={require('../assets/explication_letaxi.png')} style={{height:150,width:"80%",resizeMode:"contain"}}/>
                </View>

                <Text style={{marginLeft:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.05), marginRight:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.05), color: style.textInputColor,fontSize: style.textInputFontSize*0.9,textAlign: "justify",}}>{"Le.taxi est un organisme gouvernentale Français qui permet de mettre en relation des clients avec des taxis.\nCette connexion devrait normalement être rendu obligatoire par la loi mais la bonne nouvelle c’est que votre application est déjà compatible !\nSi vous voulez en savoir plus, n’hésitez pas à aller faire un tour sur leur site web et à les contacter : "}</Text>
                
                <View style={{alignItems:"center",justifyContent:"center", marginTop:20,marginBottom:10, flexDirection:"row"}}>
                    <MavButton text={"Voir le site"} onlyButton={true} onPress={()=>{ Linking.openURL("https://le.taxi") }}/>
                </View>

            </View>}

            {(this.state.waiting==false && this.state.withLeTaxi==true && this.state.modeView=="letaxi") && <View>

               <MavInfoBubble type={"menu"} title={"Liste des chauffeurs"} subTitle={"Définissez les chauffeurs qui peuvent travailler avec le.taxi. Cette liste sera transmise automatiquement et vous n'aurez rien d'autres à faire"}  style={{marginTop:0,marginBottom:10}} />

              
                <View style={{marginTop:15}}>
                  {Object.keys(this.state.letaxiActualConnected).map((rowData,i) => (
                      <TextPresentation editable={false} text={this.state.letaxiActualConnected[rowData].name+" "+this.state.letaxiActualConnected[rowData].lastName} textMini={strings.nlicence+" "+this.state.letaxiActualConnected[rowData].ntaxi+" | "+strings.nads+" "+this.state.letaxiActualConnected[rowData].nads} icon={(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png')}  enableRemove={true} onRemove={()=>{this.removeLeTaxiDriver(rowData) }} />      
                  ))}
              </View>


              {(this.state.addTaxi!=true && this.state.leTaxiDriverList!=undefined && Object.keys(this.state.leTaxiDriverList).length>0) && <View style={{alignItems:"center",justifyContent:"center"}}>  
                  <MavButton onlyButton={true} text={strings.addDriver} style={{marginTop:30}} onPress={()=>{ this.setState({addTaxi:true, addTaxiInfo:{} })   }}/>
              </View>}

              


              {(this.state.addTaxi==true) && <TextPresentation editable={false} defautVisible={true} text={strings.addDriver} icon={(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png')} >
                    <TextPresentation editable={false} viewEdit={true} onEditClick={()=>{ this.viewTaxiList() }} enableValidate={(this.state.addTaxiInfo.name!=undefined)?true:undefined} defautVisible={true} textExplication={strings.driverLastName} text={(this.state.addTaxiInfo.name!=undefined)?(this.state.addTaxiInfo.name+ " "+this.state.addTaxiInfo.lastName):strings.chooseDriver} />

                    {(this.state.addTaxiInfo.name!=undefined) && <TextPresentation enableValidate={(this.state.addTaxiInfo.ntaxi!=undefined && this.state.addTaxiInfo.ntaxi!="")?true:undefined} defautVisible={true} textExplication={strings.nlicence} placeholder={'Ex : 123456789'} onChangeText={(text)=>{ var ati=JSON.parse(JSON.stringify(this.state.addTaxiInfo)); ati.ntaxi=text; this.setState({addTaxiInfo:ati}) }} text={(this.state.addTaxiInfo.ntaxi!=undefined)?(this.state.addTaxiInfo.ntaxi):""} />}
                    
                    {(this.state.addTaxiInfo.name!=undefined) && <TextPresentation enableValidate={(this.state.addTaxiInfo.carModel!=undefined && this.state.addTaxiInfo.carModel[0]!=undefined && this.state.addTaxiInfo.carModel[0].immatriculation!=undefined && this.state.addTaxiInfo.carModel[0].immatriculation!="")?true:undefined} defautVisible={true} textExplication={strings.immatriculation} placeholder={'Ex : GH-456-HJ'} onChangeText={(text)=>{ var ati=JSON.parse(JSON.stringify(this.state.addTaxiInfo)); if(ati.carModel==undefined) ati.carModel=[{}]; ati.carModel[0].immatriculation=text; this.setState({addTaxiInfo:ati}) }} text={(this.state.addTaxiInfo.carModel[0].immatriculation!=undefined)?(this.state.addTaxiInfo.carModel[0].immatriculation):""} />}
                    {(this.state.addTaxiInfo.name!=undefined) && <TextPresentation enableValidate={(this.state.addTaxiInfo.carModel!=undefined && this.state.addTaxiInfo.carModel[0]!=undefined && this.state.addTaxiInfo.carModel[0].brand!=undefined && this.state.addTaxiInfo.carModel[0].brand!="")?true:undefined} defautVisible={true} textExplication={strings.brand} placeholder={'Ex : Renault'} onChangeText={(text)=>{ var ati=JSON.parse(JSON.stringify(this.state.addTaxiInfo)); if(ati.carModel==undefined) ati.carModel=[{}]; ati.carModel[0].brand=text; this.setState({addTaxiInfo:ati}) }} text={(this.state.addTaxiInfo.carModel[0].brand!=undefined)?(this.state.addTaxiInfo.carModel[0].brand):""} />}
                    {(this.state.addTaxiInfo.name!=undefined) && <TextPresentation enableValidate={(this.state.addTaxiInfo.carModel!=undefined && this.state.addTaxiInfo.carModel[0]!=undefined && this.state.addTaxiInfo.carModel[0].model!=undefined && this.state.addTaxiInfo.carModel[0].model!="")?true:undefined} defautVisible={true} textExplication={strings.model} placeholder={'Ex : Talisman'} onChangeText={(text)=>{ var ati=JSON.parse(JSON.stringify(this.state.addTaxiInfo)); if(ati.carModel==undefined) ati.carModel=[{}]; ati.carModel[0].model=text; this.setState({addTaxiInfo:ati}) }} text={(this.state.addTaxiInfo.carModel[0].model!=undefined)?(this.state.addTaxiInfo.carModel[0].model):""} />}
                    
                    {(this.state.addTaxiInfo.name!=undefined) && <TextPresentation enableValidate={(this.state.addTaxiInfo.nads!=undefined && this.state.addTaxiInfo.nads!="")?true:undefined} defautVisible={true} textExplication={strings.nads} placeholder={'Ex : 123456789'} onChangeText={(text)=>{ var ati=JSON.parse(JSON.stringify(this.state.addTaxiInfo)); ati.nads=text; this.setState({addTaxiInfo:ati}) }} text={(this.state.addTaxiInfo.nads!=undefined)?(this.state.addTaxiInfo.nads):""} />}
                    {(this.state.addTaxiInfo.name!=undefined) && <TextPresentation suggestions={"cityINSEE"} enableValidate={(this.state.addTaxiInfo.inseeAds!=undefined && this.state.addTaxiInfo.inseeAds!="")?true:undefined}  defautVisible={true} textExplication={strings.cityADS} placeholder={info.companyCity} onChangeText={(text)=>{ var ati=JSON.parse(JSON.stringify(this.state.addTaxiInfo)); ati.cityAds=text; this.setState({addTaxiInfo:ati}) }} text={(this.state.addTaxiInfo.cityAds!=undefined)?(this.state.addTaxiInfo.cityAds):""}  onSuggestion={(value)=>{ var ati=JSON.parse(JSON.stringify(this.state.addTaxiInfo)); ati.cityAds=value.name; ati.departement=value.departement; ati.inseeAds=value.inseeCode; this.setState({addTaxiInfo:ati})    }}/>}
                    

                    {(this.state.addTaxiInfo.name!=undefined) && <View style={{alignItems:"center",justifyContent:"center"}}>  
                        <MavButton onlyButton={true} text={strings.validate} style={{marginTop:30}} onPress={()=>{ this.validateCreateTaxi()   }}/>
                    </View>}

             </TextPresentation>}

             


            </View>}
            


            {(this.state.waiting==false && this.state.withKarhoo==true && this.state.modeView=="karhoo") && <View>

              <MavInfoBubble type={"help"} videoLinek={"http://www.google.fr"} others={[{title:"En savoir plus sur Karhoo", link:"https://www.karhoo.com/flottes-taxi-vtc/"}]}  style={{marginTop:-10,marginBottom:20}} />
              <MavInfoBubble type={"info"}  title={"Requêtes Google Maps"} subTitle={"Les API peuvent engendrer un nombre très important de demandes de prix. Compte tenu du prix facturé par Google pour l'accès à Google Maps et de la dé-corélation entre le nombre de requête et le nombre de chauffeurs, nous ne pouvons l'utiliser. Nous utilisons un algorithme à la place qui estime le nombre de kilomètre et le prix. Il peut donc y avoir un léger décalage entre l'API et l'appli. Si vous le souhaitez, nous pouvons tout de même utiliser Google Maps. Vous trouverez les tarifs à cette adresse : https://cloud.google.com/maps-platform/pricing/sheet?hl=fr"} style={{marginTop:0,}} />

              <MavInfoBubble type={"menu"} title={"Liste de vos véhicules"} subTitle={"Définissez les différents types de véhicules que vous proposez. Vous devrez envoyer cette liste à Karhoo"}  style={{marginTop:20,marginBottom:10}} />

              {this.state.listCar.map((rowData,i) => (
                    <TextPresentation ref={viewCar => {this['viewCar_' + i] = viewCar;}} style={{marginTop:5}} icon={(style.mode=="dark")?require('../assets/car_multiple_white.png'):require('../assets/car_multiple_black.png')} text={rowData.name+((this.state.listCarModif[i].modified==true)?" (Modifications non sauvegardées)":"") } >

                        <TextPresentation style={{marginTop:5}} editable={false} textExplication={strings.priceCarName} text={this.state.listCarModif[i].name} forceClick={() => { this.viewListCarName("modif",i) }} />

                        <TextPresentation selectedQuantity={this.state.listCarModif[i].passagers} onChangeQuantity={(value)=>{var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif)); listCarModif[i].modified=true; listCarModif[i].passagers+=value; if(listCarModif[i].passagers>0) this.setState({listCarModif:listCarModif}) }}  textMini={"Karhoo utilisera cette information pour proposer les bons véhicules aux clients"} textExplication={((this.state.listCarModif[i].passagers<2)?strings.onePassager:strings.morePassager)+" "+strings.max.toLowerCase()} editable={false}   />
                      
                        <TextPresentation selectedQuantity={this.state.listCarModif[i].luggageMax} onChangeQuantity={(value)=>{var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif)); listCarModif[i].modified=true; listCarModif[i].luggageMax+=value; if(listCarModif[i].luggageMax>-1) this.setState({listCarModif:listCarModif}) }}  textMini={"Les passagers sauront que le nombre de baggages est limité"} textExplication={((this.state.listCarModif[i].luggageMax<2)?strings.oneBaggageMax:strings.baggageMax)} editable={false}   />
                  
                        <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.listCarModif[i].childSeats==true)?true:false} text={strings.acceptChildSeats}  onOnOff={()=>{ var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif));if(listCarModif[i].childSeats==undefined) listCarModif[i].childSeats=true; else delete listCarModif[i].childSeats; listCarModif[i].modified=true; this.setState({listCarModif:listCarModif})}}/>

                        <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.listCarModif[i].wheelchair==true)?true:false} text={strings.acceptWheelchair}  onOnOff={()=>{ var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif)); if(listCarModif[i].wheelchair==undefined) listCarModif[i].wheelchair=true; else delete listCarModif[i].wheelchair; listCarModif[i].modified=true; this.setState({listCarModif:listCarModif}) }}/>

                        <View style={{alignItems:"center",justifyContent:"center", marginTop:30,marginBottom:10, flexDirection:"row"}}>
                            <MavButton text={strings.cancel} strokeMode={true} style={{marginRight:5}} onlyButton={true} onPress={()=>{ var listCarModif=JSON.parse(JSON.stringify(this.state.listCarModif)); listCarModif[i]=JSON.parse(JSON.stringify(this.state.listCar[i])); this.setState({listCarModif:listCarModif}); this['viewCar_' + i].close()  }}/>
                            <MavButton text={strings.validate} style={{marginLeft:5}} onlyButton={true} onPress={()=>{  this.viewListCarName("save",i); }}/>
                        </View>


                    <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.viewListCarName("remove",i)}}>
                        <Text style={{marginLeft:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.1), marginRight:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.1), marginTop:10,color: style.colorNotification,fontSize: style.textInputFontSize,textAlign: "center",}}>{strings.remove.toUpperCase()}</Text>
                    </TouchableHighlight>

                    </TextPresentation>

              ))}
              
              {this.state.viewAddCar!=true && <View style={{alignItems:"center",justifyContent:"center"}}>  
                  <MavButton onlyButton={true} text={strings.add} style={{marginTop:30}} onPress={()=>{ this.setState({viewAddCar:true, })   }}/>
              </View>}


              {this.state.viewAddCar==true && <TextPresentation  icon={(style.mode=="dark")?require('../assets/car_multiple_white.png'):require('../assets/car_multiple_black.png')}  text={ strings.add} defautVisible={true} >

                    <TextPresentation style={{marginTop:5}} editable={false} textExplication={strings.priceCarName} text={(this.state.addCar.name=="")?strings.modify:this.state.addCar.name} forceClick={() => { this.viewListCarName("add") }} />

                    <TextPresentation selectedQuantity={this.state.addCar.passagers} onChangeQuantity={(value)=>{var pass=JSON.parse(JSON.stringify(this.state.addCar)); pass.passagers+=value; if(pass.passagers>0) this.setState({addCar:pass})  }}  textMini={"Karhoo utilisera cette information pour proposer les bons véhicules aux clients"} textExplication={((this.state.addCar.passagers<2)?strings.onePassager:strings.morePassager)+" "+strings.max.toLowerCase()} editable={false}   />
                      
                    <TextPresentation selectedQuantity={this.state.addCar.luggageMax} onChangeQuantity={(value)=>{var lug=JSON.parse(JSON.stringify(this.state.addCar)); lug.luggageMax+=value; if(lug.luggageMax>-1) this.setState({addCar:lug}) }}  textMini={"Les passagers sauront que le nombre de baggages est limité"} textExplication={((this.state.addCar.luggageMax<2)?strings.oneBaggageMax:strings.baggageMax)} editable={false}   />
                    
                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.addCar.childSeats==true)?true:false} text={strings.acceptChildSeats}  onOnOff={()=>{ var listCarModif=JSON.parse(JSON.stringify(this.state.addCar));if(listCarModif.childSeats==undefined) listCarModif.childSeats=true; else delete listCarModif.childSeats; this.setState({addCar:listCarModif})}}/>

                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.addCar.wheelchair==true)?true:false} text={strings.acceptWheelchair}  onOnOff={()=>{ var listCarModif=JSON.parse(JSON.stringify(this.state.addCar)); if(listCarModif.wheelchair==undefined) listCarModif.wheelchair=true; else delete listCarModif.wheelchair; this.setState({addCar:listCarModif}) }}/>

                    <View style={{alignItems:"center",justifyContent:"center", marginTop:30,marginBottom:10, flexDirection:"row"}}>
                        <MavButton text={strings.cancel} strokeMode={true} style={{marginRight:5}} onlyButton={true} onPress={()=>{ this.setState({viewAddCar:false,addCar:{name:"",passagers:1,luggageMax:0} })  }}/>
                        <MavButton text={strings.validate} style={{marginLeft:5}} onlyButton={true} onPress={()=>{ this.viewListCarName("saveAdd") }}/>

                    </View>



            </TextPresentation>}


            {(this.state.listCar.length>0 && this.state.modeView=="karhoo") &&  <MavInfoBubble type={"menu"} title={"Vous avez fini de définir vos véhicules ?"} subTitle={"En appuyant sur le bouton 'Envoyer à Karhoo', votre conseiller karhoo sera automatiquement prévenu de l'ajout / la modification de vos véhicules. N'oubliez pas de définir / modifier vos prix pour Karhoo"}  style={{marginTop:20,marginBottom:10}} />}

            {(this.state.listCar.length>0 && this.state.modeView=="karhoo") && <View style={{alignItems:"center",justifyContent:"center"}}>  
                  <MavButton onlyButton={true} text={"Envoyer à Karhoo"} style={{marginTop:10}} onPress={()=>{ this.sendMessageToKarhoo()  }}/>
              </View>}



            </View>}


             {(this.state.listCar.length>0 && this.state.modeView=="karhoo") &&  <MavInfoBubble type={"menu"} title={"Vos prix pour Karhoo"} subTitle={"Saisissez vos prix (forfaits / zones) qui seront utilisés spécifiquement pour Karhoo. Attention, compte tenu du nombre conséquent de demande et pour des raisons de performances, vous ne pouvez définir que 20 forfaits / zones"}  style={{marginTop:30,marginBottom:0}} />}


             {this.state.modeView=="karhoo" && Object.keys(this.state.pricing).map((rowData,i) => (
              <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.viewPricingDetail(this.state.pricing[rowData], rowData)  }}>

                  <TextPresentation style={{marginTop:5}} icon={ (this.state.pricing[rowData].taxiMode==true) ? ((style.mode=="dark")?require('../assets/taxi_white.png'):require('../assets/taxi_black.png')):     ((style.mode=="dark")?require('../assets/favorite/pin_white.png'):require('../assets/favorite/pin_black.png'))} editable={false} textMini={(this.state.pricing[rowData].radius!=undefined)?("Zone : "+this.state.pricing[rowData].radius+" km"):(strings.to+" : "+this.state.pricing[rowData].endName) } enableNext={true} textExplication={(this.state.pricing[rowData].name!=undefined)?this.state.pricing[rowData].name:(strings.from+" : "+this.state.pricing[rowData].startName)} disabledText={true}  />
              </TouchableHighlight>

              ))}
             





             {(this.state.listCar.length>0 && Object.keys(this.state.pricing).length<30 && this.state.modeView=="karhoo") && <View style={{alignItems:"center",justifyContent:"center"}}>  
                  <MavButton onlyButton={true} text={strings.add} style={{marginTop:30, marginBottom:30}} onPress={()=>{ 
                    var i;

                    var buttonList=[
                    {
                      text: strings.zone,
                      onPress: () => { this.navigateTo('PricePlaceSettings', {extraEUMode:this.state.extraEUMode, karhooMode:true,  updateData: this.updatePricing, carListName:this.state.listCar});},
                    },
                    { 
                      text: strings.forfait,
                      onPress: () => {this.navigateTo('PricePlaceSettings',{extraEUMode:this.state.extraEUMode, karhooMode:true, type:"forfait", updateData: this.updatePricing, carListName:this.state.listCar}) },
                    }
                  ]

                  if(this.state.taxiMode==true){
                      buttonList.push({text: strings.zoneTaxi, onPress: () => {this.navigateTo('PricePlaceSettings', {extraEUMode:this.state.extraEUMode, karhooMode:true, taxiMode:true, updateData: this.updatePricing, carListName:this.state.listCar });}   })
                      buttonList.push({text: strings.forfaitTaxi, onPress: () => {this.navigateTo('PricePlaceSettings', {extraEUMode:this.state.extraEUMode, karhooMode:true,taxiMode:true, type:"forfait", updateData: this.updatePricing, carListName:this.state.listCar});}   })

                  }
                  this.alert.onAlert({title:strings.addTarif, buttons:buttonList, largeButton:true});   

                }}/>
             </View>}


             <View style={{width:50, height:30}} />

            
             
      
      
          
  
      </ScrollView>


       {(this.state.modeView=="normal") && <View style={{alignItems:"center",justifyContent:"center", marginTop:Dimensions.get('window').height * 0.05,marginBottom:Dimensions.get('window').height * 0.05}}>
          <MavButton text={strings.add} onlyButton={true}  onPress={()=>{ this.addPartner() }}/>

          </View>}

     

        <MavAlert ref={alert => {this.alert = alert;}}/>

      </View>
      }</StyleContext.Consumer>
    );
  }
}



export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export function basicUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + s4() + s4() +s4();
}


export function hashFnv32a(str, asString, seed) {
    /*jshint bitwise:false */
    var i, l,
        hval = (seed === undefined) ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    if( asString ){
        // Convert to 8 digit hex string
        return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
    }
    return hval >>> 0;
}

