import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  Linking,
  ScrollView,
  ActivityIndicator,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import countryList from '../countryList';
import moneyList from '../moneyList';
import flightList from '../flightList';

import Modal from './modal';
import MavTextInput from './mavTextInput';
import MavViewMore from './mavViewMore';

import MavButton from './mavButton';
import MavMiniButton from './mavMiniButton';


export default class sncfInfo extends Component {
constructor(props) { 
    super(props);


    
    var num=(this.props.numero!=undefined)?this.props.numero:"";
    num=num.toUpperCase(); 

    num=num.replace("AIR FRANCE","")
    num=num.replace("EASYJET","")
    num=num.replace("VOLOVEA","")
    num=num.replace("RYANAIR","")
    num=num.replace("TRANSAVIA","")
    num=num.replace("NUMERO","")
    num=num.replace("NUM","")
    num=num.replace("N°","")
    num=num.replace(/ /g,'')

    /*par défaut aeroport = point de départ sauf si on trouve aeroport comme destination */
    var airportIsDestination=false; 
    if(this.props.end!=undefined && (this.props.end.toUpperCase().search("AEROPORT")!='-1' || this.props.end.toUpperCase().search("AIRPORT")!='-1' || this.props.end.toUpperCase().search("AÉROPORT")!='-1')) airportIsDestination=true;


    this.state = {
      changeNumber:false,
      numFight:num, 
      companyName:undefined,
      viewDetailTrajet:false,
      airportIsDestination:airportIsDestination,
      raceDate:this.props.raceDate, 
      

    }












      


  }


  findFlight(){
    var fetchURLparams={flightRequest:true,date:this.state.raceDate, code:this.state.numFight}

     fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getMapsInfo", {body: JSON.stringify(fetchURLparams),method: "POST"}).then(response => response.json()).then(responseJson => {
        if(typeof(responseJson)!="object") responseJson=JSON.parse(responseJson);

        if(responseJson!=undefined && responseJson.data!=undefined && responseJson.data.length>0){
            var data=responseJson.data[0];

            data.arrival.scheduled=data.arrival.scheduled.replace(":00+00:00","")
            data.departure.scheduled=data.departure.scheduled.replace(":00+00:00","")

            data.arrival.estimated=data.arrival.estimated.replace(":00+00:00","")
            data.departure.estimated=data.departure.estimated.replace(":00+00:00","")


            var hourFlight=(this.state.airportIsDestination==false)?(new Date(data.arrival.scheduled)):(new Date(data.departure.scheduled))
            hourFlight=((hourFlight.getHours()<10)?"0":"")+hourFlight.getHours()+":"+((hourFlight.getMinutes()<10)?"0":"")+hourFlight.getMinutes()


            var hourFlightReal=(this.state.airportIsDestination==false)?(new Date(data.arrival.estimated)):(new Date(data.departure.estimated))



            hourFlightReal=((hourFlightReal.getHours()<10)?"0":"")+hourFlightReal.getHours()+":"+((hourFlightReal.getMinutes()<10)?"0":"")+hourFlightReal.getMinutes()

            var departure={name:data.departure.airport, terminal:data.departure.terminal, gate:data.departure.gate, date:((new Date(data.departure.estimated).getHours()<10)?"0":"")+new Date(data.departure.estimated).getHours()+":"+((new Date(data.departure.estimated).getMinutes()<10)?"0":"")+new Date(data.departure.estimated).getMinutes()}
            
            if(data.departure.delay!=null && data.departure.delay>5) {
              var dateNew=Date.parse(new Date(data.departure.estimated))+data.departure.delay*60*1000; 
              departure.newDate=((new Date(dateNew).getHours()<10)?"0":"")+new Date(dateNew).getHours()+":"+((new Date(dateNew).getMinutes()<10)?"0":"")+new Date(dateNew).getMinutes()
            }

            var arrival={name:data.arrival.airport, terminal:data.arrival.terminal, gate:data.arrival.gate, date:((new Date(data.arrival.estimated).getHours()<10)?"0":"")+new Date(data.arrival.estimated).getHours()+":"+((new Date(data.arrival.estimated).getMinutes()<10)?"0":"")+new Date(data.arrival.estimated).getMinutes()}

            if(data.arrival.delay!=null && data.arrival.delay>5) {
              var dateNew=Date.parse(new Date(data.arrival.estimated))+data.arrival.delay*60*1000; 
              arrival.newDate=((new Date(dateNew).getHours()<10)?"0":"")+new Date(dateNew).getHours()+":"+((new Date(dateNew).getMinutes()<10)?"0":"")+new Date(dateNew).getMinutes()
            }
            else if(data.arrival.delay==null && data.departure.delay!=null && data.departure.delay>15){
              var dateNew=Date.parse(new Date(data.arrival.estimated))+data.departure.delay*60*1000*0.8; 
              arrival.newDate=((new Date(dateNew).getHours()<10)?"0":"")+new Date(dateNew).getHours()+":"+((new Date(dateNew).getMinutes()<10)?"0":"")+new Date(dateNew).getMinutes()
              arrival.estimatedDelay=true;
              if(this.state.airportIsDestination==false) hourFlightReal=arrival.newDate
            }


            var logo=(flightList[data.airline.iata]!=undefined)?flightList[data.airline.iata].icon:undefined

            this.setState({logo:logo,departure:departure,arrival:arrival, companyName:data.airline.name,delay:(this.state.airportIsDestination==true)?data.arrival.delay:data.departure.delay, hourFlight:hourFlight,hourFlightReal:(hourFlightReal!=hourFlight)?hourFlightReal:undefined  })

            if(data.flight_status=="cancelled") this.setState({noService:true})
        }
        


      })
    

    
  }


  viewDetail(){
    this.setState({viewDetailTrajet:true, })
  }



  componentDidMount(){
      /*je vais tester si je trouve ce vol */

     this.findFlight()

    }


convertDate(dateConv){

  var date=dateConv[0]+dateConv[1]+dateConv[2]+dateConv[3]+"-"+dateConv[4]+dateConv[5]+"-"+dateConv[6]+dateConv[7]+"T"+dateConv[9]+dateConv[10]+":"+dateConv[11]+dateConv[12]+":00Z"
  date=new Date(date);
  return date

}


  render() {


    return (
      <StyleContext.Consumer>{style => 
          <View style={(this.props.style==undefined)?{}:this.props.style}>



            <View style={{justifyContent:"space-between", flexDirection:"row", alignItems:"center"}}>

                    <View style={{flexDirection:"row", alignItems:"center",}} >
                        <Image source={(this.state.logo!=undefined)?this.state.logo:(style.mode=="light")?require('../assets/favorite/flight_black.png'):require('../assets/favorite/flight_white.png')} style={{height:25,width:25,resizeMode:"contain", marginTop:-3, marginRight:6}}/>
                        
                        <View style={{alignItems:"flex-start"}}>
                            <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.state.viewDetailTrajet!=true && this.props.editable==true) this.setState({changeNumber:!this.state.changeNumber}) }}>
                               <View style={{flexDirection:"row"}}>
                                    <Text style={{color: style.textInputColor,fontStyle: 'italic',fontSize: style.textInputFontSize*0.75,textAlign: "center",}}>{(this.state.companyName!=undefined)?(this.state.companyName.toUpperCase()+" N°") :strings.numeroFlight.toUpperCase()}</Text>
                                    {(this.state.viewDetailTrajet!=true && this.props.editable==true) && <Image source={(style.mode=="dark")?require('../assets/edit_white.png'):require('../assets/edit_black.png')} style={{height:12,width:12,marginLeft:5, resizeMode:"contain"}}/>}
                              </View>
                            </TouchableHighlight>
                            <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.setState({changeNumber:!this.state.changeNumber}) }}>
                              
                                  <View style={{flexDirection:"row", alignItems:"center", marginTop:-3}}>
                                      <Text style={{marginLeft:-2,color: style.textInputColor,fontSize: style.textInputFontSize*1.35,textAlign: "center",}}>{this.state.numFight}</Text>
                                  </View>


                            </TouchableHighlight>
                        </View>

                    </View>


                    <View>

                    {(this.state.changeNumber!=true && this.state.hourFlight!=undefined && this.state.hourFlightReal==undefined && this.state.viewDetailTrajet!=true && this.state.noService!=true) && <View style={{ alignItems:"flex-end" }}>

                          <Text style={{fontSize:style.textInputFontSize*0.8,color:style.textInputColor,}}>{strings.arrivalAt+" "}<Text style={{fontSize:style.textInputFontSize*1,color:style.textInputColor,fontWeight: 'bold',}}>{this.state.hourFlight}</Text></Text> 
                          
                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.viewDetail() }}>
                              <View style={{flexDirection:"row", alignItems:"center" }} >
                                <Image source={require('../assets/iconi_green.png')} style={{height:13,width:13,resizeMode:"contain", }}/>
                              <Text style={{marginLeft:2,fontSize:style.textInputFontSize,color:style.colorValidate,fontWeight: 'bold'}}>{strings.trainAtTime}</Text>
                              </View>
                          </TouchableHighlight>

                    
                    </View>}

                     {(this.state.changeNumber!=true && this.state.hourFlight!=undefined && this.state.hourFlightReal!=undefined && this.state.viewDetailTrajet!=true) && <View style={{ alignItems:"flex-end" }}>

                          <Text style={{fontSize:style.textInputFontSize*0.8,color:style.textInputColor,}}>{strings.arrivalAt+" "}<Text style={{color:style.textInputColor,textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{this.state.hourFlight}</Text><Text style={{fontSize:style.textInputFontSize*1,color:style.colorNotification,fontWeight: 'bold',}}>{" "+this.state.hourFlightReal}</Text></Text> 
                          
                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.viewDetail() }}>

                            <View style={{flexDirection:"row", alignItems:"center" }} >
                              <Image source={require('../assets/iconi_red.png')} style={{height:13,width:13,resizeMode:"contain", }}/>
                              <Text style={{marginLeft:2, fontSize:style.textInputFontSize,color:style.colorNotification,fontWeight: 'bold'}}>{strings.delayOf+" "+this.state.delay+" "+strings.minutes}</Text>
                            </View>
                          </TouchableHighlight>

                    </View>}


                    {this.state.noService==true && <Text style={{fontSize:style.textInputFontSize,color:style.colorNotification,fontWeight: 'bold'}}>{strings.flightDeleted}</Text> }

                    {(this.state.changeNumber==true || this.state.viewDetailTrajet==true) && <MavMiniButton  minimize={true} icon={(style.mode=="dark")?require('../assets/close_black.png'):require('../assets/close_white.png')} text={strings.close} onlyButton={true}  onPress={()=>{ if(this.state.viewDetailTrajet==true) this.setState({viewDetailTrajet:false}) ; else this.setState({changeNumber:false})  }} />}
    

                    {(this.state.changeNumber!=true  && this.state.hourFlight==undefined && this.state.viewDetailTrajet!=true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { Linking.openURL("https://fr.flightaware.com/search/"+this.state.numFight)  }}>

                      <View style={{flexDirection:"row", alignItems:"center" }} >
                              <Image source={(style.mode=="dark")?require('../assets/iconiWhite.png'):require('../assets/iconi_black.png')} style={{height:13,width:13,resizeMode:"contain", }}/>
                              <Text style={{marginLeft:2, fontSize:style.textInputFontSize,color:style.textInputColor,fontWeight: 'bold'}}>{strings.moreInfos}</Text>
                            </View> 

                      </TouchableHighlight>}


                    </View>

          </View>

          {(this.state.viewDetailTrajet==true) && <MavViewMore hideTopbar={true} style={{marginTop:5 }} defautVisible={true} text={ "" }  >
              {(this.state.waitDetailTrajet==true) && <ActivityIndicator style={{marginTop:35,marginBottom:35}} size="large" color={style.color1} />}

              {true && <View style={{marginTop:7, marginLeft:10, marginRight:10}}>



                  <View style={{flexDirection:"row", alignItems:"center",marginBottom:10}} >
                        <Image source={require('../assets/flight/aviationstack.png')} style={{height:25,width:20,resizeMode:"contain", marginRight:6}}/>
                        {this.state.cause==undefined && <View>
                          <Text style={{color: style.textInputColor,fontStyle: 'italic',fontSize: style.textInputFontSize*0.8,textAlign: "left",}}>{strings.infoByFlight}</Text>
                          <Text style={{color: style.textInputColor,fontStyle: 'italic',fontSize: style.textInputFontSize*0.6,textAlign: "left",marginTop:-2}}>{strings.indicatifOnly}</Text>
                        </View>}
                        

                  
                  </View>


                <View style={{justifyContent: 'space-between',flexDirection: 'row',height:40, marginLeft:5 }}>
                      <View style={{flexDirection: 'row',maxWidth:"75%",}}>
                        <View style={{ marginRight:10, }}>
                            <View style={{width:10, height:10,borderRadius: 5, backgroundColor:style.color1, }}/>
                            <View style={{width:2, height:11, backgroundColor:style.color1, marginLeft:4 }}/>
                            <View style={{width:2, height:20, backgroundColor:style.color1, marginLeft:4 }}/>
                        </View>
                            <View>
                                <Text style={{ fontSize:style.textInputFontSize*0.8,color:style.textInputColor,marginTop:-1, fontWeight: (this.state.airportIsDestination==true)?'bold':undefined}}>{this.state.departure.name}</Text>
                                <Text style={{ fontSize:style.textInputFontSize*0.8*((this.state.airportIsDestination==true)?1.2:1),color:style.textInputColor,marginTop:-2,fontWeight: (this.state.airportIsDestination==true)?'bold':undefined}}>{strings.terminal+" "+this.state.departure.terminal+((this.state.departure.gate!=null )?(" - "+strings.gate+" "+this.state.departure.gate):"")}</Text>
                            </View>

                      </View>
                      <View style={{flexDirection:'row', alignItems:"center", marginTop:-10}}>
                              <Text style={{fontSize:style.textInputFontSize*((this.state.departure.newDate!=undefined)?0.7:1),color:style.textInputColor,marginTop:-2,textDecorationLine: (this.state.departure.newDate!=undefined)?'line-through':undefined, fontWeight: (this.state.airportIsDestination==true)?'bold':undefined}}>{this.state.departure.date}</Text>
                              {this.state.departure.newDate!=undefined && <Text style={{marginLeft:5, fontSize:style.textInputFontSize,color:style.colorNotification,marginTop:-2,  fontWeight: (this.state.airportIsDestination==true)?'bold':undefined}}>{this.state.departure.newDate}</Text>}
                          </View>

                </View>

                 <View style={{justifyContent: 'space-between',flexDirection: 'row', marginLeft:5 }}>
                      <View style={{flexDirection: 'row',maxWidth:"75%",}}>
                        <View style={{ marginRight:10, }}>
                            <View style={{width:10, height:10,borderRadius: 5, backgroundColor:style.color1, }}/>
                        </View>
                            <View>
                                <Text style={{ fontSize:style.textInputFontSize*0.8,color:style.textInputColor,marginTop:-2,fontWeight: (this.state.airportIsDestination==false)?'bold':undefined}}>{this.state.arrival.name}</Text>
                                <Text style={{ fontSize:style.textInputFontSize*0.8*((this.state.airportIsDestination==false)?1.2:1),color:style.textInputColor,marginTop:-2,fontWeight: (this.state.airportIsDestination==false)?'bold':undefined}}>{strings.terminal+" "+this.state.arrival.terminal+((this.state.arrival.gate!=null )?(" - "+strings.gate+" "+this.state.arrival.gate):"")}</Text>
                            </View>
                      </View>

                      <View style={{flexDirection:'row', alignItems:"center", marginTop:0}}>
                              <Text style={{fontSize:style.textInputFontSize*((this.state.arrival.newDate!=undefined)?0.7:1),color:style.textInputColor,marginTop:-2, textDecorationLine: (this.state.arrival.newDate!=undefined)?'line-through':undefined,  fontWeight: (this.state.airportIsDestination==false)?'bold':undefined}}>{this.state.arrival.date}</Text>
                              {this.state.arrival.newDate!=undefined && <Text style={{marginLeft:5, fontSize:style.textInputFontSize,color:style.colorNotification,marginTop:-2,  fontWeight: (this.state.airportIsDestination==false)?'bold':undefined}}>{this.state.arrival.newDate}</Text>}
                      </View>


                </View>





               </View>}

          </MavViewMore>}
          


          {(this.state.changeNumber==true && this.props.editable==true) && <MavViewMore hideTopbar={true} style={{marginTop:5 }} defautVisible={true} text={ "" }  >

                  <MavTextInput style={{marginTop:15}} textInputFlex={1.3} disableLine={true} text={this.state.numFight} backgroundColor={style.backgroundColorContrast } textExplication={strings.numeroFlight} placeholder={"ex : AF1234"} onChangeText={(text)=>{this.setState({numFight: text })}}/>
             
                  <View style={{width:"100%", alignItems:"center", justifyContent:"center", marginTop:10}}> 
                      <MavButton onlyButton={true} small={true} text={strings.confirm} onPress={()=>{if(this.props.onChangeNumber!=null) this.props.onChangeNumber(this.state.numFight); }} />
                  </View>

          </MavViewMore>}

            
        </View>

      }</StyleContext.Consumer>


    	)}

    }

