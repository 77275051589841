import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';


export default class MavButton extends Component {

  render() {
    return (
    <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>
      <TouchableHighlight
          underlayColor={(this.props.strokeMode==true)?"rgba(255,255,255,0)":((this.props.color!=undefined)?this.props.color:style.color1)}
          style={{ 
            height: style.buttonHeight*( (this.props.small==true)?0.65:1)*( (this.props.maxiMode==true)?3:1) ,
            width: style.buttonWidth*( (this.props.small==true)?0.65:1)* ( (this.props.large==true)?1.3:1)*  ( (this.props.maxiMode==true)?3:1)* ( (this.props.maxiWidth==true)?1.7:1),
            flexDirection: 'column',
            borderRadius: style.buttonRadius* ( (this.props.maxiMode==true)?3:1),
            borderColor:(this.props.color!=undefined)?this.props.color:style.color1,
            borderWidth:1,
            backgroundColor: (this.props.strokeMode==true)?"rgba(255,255,255,0)":(this.props.color!=undefined)?this.props.color:style.color1,
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: (this.props.onlyButton==true)?0:(Dimensions.get('window').width - style.buttonWidth) /2,
          }}
          onPress={() => {
           
            if(this.props.onPress!=null) this.props.onPress();
            
            
          }}>
          <View>

          {this.props.firstText!=undefined && <Text allowFontScaling={false}
            style={{
              color: (this.props.strokeMode==true)?((this.props.color!=undefined)?this.props.color:style.color1):((this.props.colorText!=undefined)?this.props.colorText:style.backgroundColor),
              fontSize: style.buttonFontSize*0.65*( (this.props.small==true)?0.65:1)* ( (this.props.maxiMode==true)?3:1),
              textAlign: 'center',
            }}>
            {this.props.firstText.toUpperCase()}
          </Text>}


          <Text
          allowFontScaling={false}
            style={{
              color: (this.props.strokeMode==true)?((this.props.color!=undefined)?this.props.color:style.color1):((this.props.colorText!=undefined)?this.props.colorText:style.backgroundColor),
              fontSize: style.buttonFontSize*( (this.props.small==true)?0.65:1)* ( (this.props.maxiMode==true)?3:1),
              textAlign: 'center',
            }}>
            {this.props.text.toUpperCase()}
          </Text>


          {this.props.bottomText!=undefined && <Text allowFontScaling={false}
            style={{
              color: (this.props.strokeMode==true)?((this.props.color!=undefined)?this.props.color:style.color1):((this.props.colorText!=undefined)?this.props.colorText:style.backgroundColor),
              fontSize: style.buttonFontSize*0.65*( (this.props.small==true)?0.65:1)* ( (this.props.maxiMode==true)?3:1),
              textAlign: 'center',
            }}>
            {this.props.bottomText.toUpperCase()}
          </Text>}

          </View>

        </TouchableHighlight>



    </View>
    }</StyleContext.Consumer>
    	)}

    }

