import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  Easing,
  TouchableHighlight,
  Animated,
  TextInput,
  Dimensions,
  FlatList,
  Platform, 
  ActivityIndicator,
  Image,
  Alert,
} from 'react-native';
import {StyleContext,styleInfo} from '../styleC';
import { NavigationActions } from './react-navigation'; // Version can be specified in package.json
import timezone from '../timezone';
import SortableList from './SortableList';
import firebase from './firebase';
import info from '../info';
import { getDatabase, set, ref, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";
import { getStorage, ref as refStorage, uploadBytes  } from "firebase/storage";
import strings from '../strings';
import MavSelector from './mavSelector';
import MavAlert from './mavAlert';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import moneyList from '../moneyList';
import Modal from './modal';
import RNFetchBlob from './RNFetchBlob';

const timer = require('react-native-timer');

class Row extends Component {

  constructor(props) {
    super(props);


    this._active = new Animated.Value(0);

    
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      Animated.timing(this._active, {
        duration: 300,
        useNativeDriver: true,
        easing: Easing.bounce,
        toValue: Number(nextProps.active),
      }).start();
    }
  }

  render() {
   const {rowData, active} = this.props;

    return (
      <StyleContext.Consumer>{style =>
      <Animated.View style={{flexDirection: 'row',justifyContent: 'space-between',  marginLeft:"10%",marginRight:"10%",marginTop:10, marginBottom:10}}>
        
          {(Platform.OS!="web") && <View style={{backgroundColor:style.headerBackgroundColor,}} > 
            <Image source={require('../assets/more_grey.png')} style={{height:30,width:30,marginRight:10}} /> 
          </View>}
          <TouchableHighlight style={{height:30, justifyContent:"center"}}><Text style={{fontSize: style.textInputFontSize,color:style.textInputColor, textAlign:"left", width:((Platform.OS=="web")?("80%-60"):((Dimensions.get('window').width*0.8)-60)) }} onPress={() => { this.props.actionLink({action:"update",keyID:this.props.keyID})  }}  >{(this.props.data.name[strings.idLangue]!=undefined)?this.props.data.name[strings.idLangue]:this.props.data.name["fr"]}</Text></TouchableHighlight>
        
          <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {
            
            this.props.actionLink({action:"remove",keyID:this.props.keyID}) 

          }} >
               <Image source={require('../assets/favorite_cancel.png')} style={{ width: 20, height: 20,marginTop:9 }}/>
          </TouchableHighlight>

      </Animated.View>





      }</StyleContext.Consumer>
      

      


      
      


      
    );
  }
}



export default class account extends Component {
  constructor(props) {
    super(props);

    var infoMoney=this.findMoney();
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;

    var picturesCar={carAvailable:{  default:{source:require('../assets/car.png')} }  }
    if(params.picturesCar!=undefined && params.picturesCar.carAvailable!=undefined){
      var objPCCA=Object.keys(params.picturesCar.carAvailable); 
      var i;
      for(i=0; i<objPCCA.length;i++){

        picturesCar.carAvailable[objPCCA[i]]={source: {uri:"https://firebasestorage.googleapis.com/v0/b/"+info.firebaseProjectID+".appspot.com/o/assets%2F"+objPCCA[i]+"%2Fpicture.png?alt=media"}      }
      }
    }

    if(params.picturesCar!=undefined && params.picturesCar.carUsed!=undefined){
      var objPCCA=Object.keys(params.picturesCar.carUsed); 
      var i;
      if(objPCCA.length>0) picturesCar.carUsed={}
      for(i=0; i<objPCCA.length;i++){
        picturesCar.carUsed[objPCCA[i]]={above:params.picturesCar.carUsed[objPCCA[i]], aboveSource: {uri:"https://firebasestorage.googleapis.com/v0/b/"+info.firebaseProjectID+".appspot.com/o/assets%2F"+params.picturesCar.carUsed[objPCCA[i]].above+"%2Fpicture.png?alt=media"}      }
      }
    }



    console.log("params option gestion =")
    console.log(params)



    this.state = {
      vipMode:params.vipMode, 
      extraEUMode:params.extraEUMode,
      carList:{}, 
      moneyList:{},
      money:infoMoney,
      seeDetail:false, 
      optionID:"", 
      option:{name:{},price:{},carList:{}, quantity:1, description:{} }, 
      selectedElement:"fr",
      optionalOrder:params.optionalOrder,
      loadPicture:false, 

      viewCarMode:(params.viewCarMode==true)?true:false,
      viewRealDriverForDriver:params.viewRealDriverForDriver,
      viewRealDriver:params.viewRealDriver,
      hideOtherCarIfRaceInProgress:params.hideOtherCarIfRaceInProgress,
      carDriverColor:params.carDriverColor,
      driverApproachingColor:params.driverApproachingColor, 
      viewAboveCar:false, 
      picturesCar:picturesCar, 
      picturesCarClassic:params.picturesCar, 
      
    };
  }


  
  componentDidMount() {

    this.getAllCar()
    

  }


  updatePictureCar(info){

      var picturesCar=this.state.picturesCar;
      var picturesCarClassic=this.state.picturesCarClassic;
      if(picturesCarClassic==undefined) picturesCarClassic={}; 
      if(info.type=="addCar"){
        if(picturesCar.carAvailable==undefined) picturesCar.carAvailable={}; 
        picturesCar.carAvailable[info.uuid]={source:{uri:"https://firebasestorage.googleapis.com/v0/b/"+info.firebaseProjectID+".appspot.com/o/assets%2F"+info.uuid+"%2Fpicture.png?alt=media"} }
        if(picturesCarClassic.carAvailable==undefined) picturesCarClassic.carAvailable={};
        picturesCarClassic.carAvailable[info.uuid]=true;
        
      }
      else if(info.type=="modifyAboveCar"){
        if(picturesCar.carUsed==undefined) picturesCar.carUsed={}
        if(picturesCar.carUsed[this.state.selectedAboveCar]==undefined) picturesCar.carUsed[this.state.selectedAboveCar]={};
        picturesCar.carUsed[this.state.selectedAboveCar].above=info.uuidCar
        picturesCar.carUsed[this.state.selectedAboveCar].aboveSource={uri:"https://firebasestorage.googleapis.com/v0/b/"+info.firebaseProjectID+".appspot.com/o/assets%2F"+picturesCar.carUsed[this.state.selectedAboveCar].above+"%2Fpicture.png?alt=media"}

        if(picturesCar.carUsed[this.state.selectedAboveCar].above=="default"){
          delete picturesCar.carUsed[this.state.selectedAboveCar].above; 
          delete picturesCar.carUsed[this.state.selectedAboveCar].aboveSource; 
        }



        if(picturesCar.carUsed[this.state.selectedAboveCar]!=undefined){
          if(picturesCarClassic.carUsed==undefined) picturesCarClassic.carUsed={};
          if(picturesCarClassic.carUsed[this.state.selectedAboveCar]==undefined) picturesCarClassic.carUsed[this.state.selectedAboveCar]={}
          picturesCarClassic.carUsed[this.state.selectedAboveCar].above=picturesCar.carUsed[this.state.selectedAboveCar].above;
          if(picturesCarClassic.carUsed[this.state.selectedAboveCar].above==undefined) delete picturesCarClassic.carUsed[this.state.selectedAboveCar]
          

        } else if(picturesCarClassic.carUsed!=undefined && picturesCarClassic.carUsed[this.state.selectedAboveCar].above!=undefined) delete picturesCarClassic.carUsed[this.state.selectedAboveCar].above; 
       

          
      }
      console.log("picturesCarClassic")
      console.log(picturesCarClassic)
      this.setState({picturesCar:picturesCar, picturesCarClassic:picturesCarClassic})
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
      params.updatePicturesCar(picturesCarClassic)






  }



  takePicture(){
    
   
    var options = {imageFileType: 'png', title: strings.selectPicture,chooseFromLibraryButtonTitle:strings.library, takePhotoButtonTitle:strings.camera,cancelButtonTitle:strings.cancel,storageOptions: {skipBackup: true,path: 'images',quality:0.5}};


    launchImageLibrary(options, (response) => {
      if (response.didCancel) {console.log('User cancelled image picker');}else if (response.error) {console.log('ImagePicker Error: ', response.error);}else if (response.customButton) {console.log('User tapped custom button: ', response.customButton);}
      else {
       const image = Platform.OS === 'ios' ? response.assets[0].uri.replace('file://', '') : response.assets[0].uri
 
        const Blob = RNFetchBlob.polyfill.Blob
        const fs = RNFetchBlob.fs
        window.XMLHttpRequest = RNFetchBlob.polyfill.XMLHttpRequest
        window.Blob = Blob
 
        this.setState({loadPicture:true})

        let uploadBlob = null
        var uuid=generateUUID();
        console.log("jenvoi la picture")
        const imageRef = refStorage(getStorage(), 'assets/'+uuid+'/picture.png');
        let mime = 'image/png'
        fs.readFile(image, 'base64')
        .then((data) => {
          return Blob.build(data, { type: `${mime};BASE64` })
        })
        .then((blob) => {
          uploadBlob = blob
          console.log("blob info")
          console.log(blob)
          return uploadBytes(imageRef, blob)
          })
        .then(() => {
          uploadBlob.close()
          console.log("blob fini appel à updatePictureCar")
          this.updatePictureCar({type:"addCar", uuid:uuid })

          this.setState({loadPicture:false}); 
          return getDownloadURL(imageRef)
        })
        .catch((error) => {
          this.alert.onAlert({title:strings.errorDefault})
           this.setState({loadPicture:false})
           console.log("error pict")
          console.log(error);
 
        })  

    }
    });

    
     
  }
  


  updateColor(color){


      
      let reg = /^#[0-9A-F]{6}$/i;
      if(reg.test(color)!=true){ this.alert.onAlert({title:strings.invalidColorCode,}) }
      else {
          color="rgba("+parseInt((color[1]+color[2]), 16)+","+parseInt((color[3]+color[4]), 16)+","+parseInt((color[5]+color[6]), 16)+",0.3)";
          var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;

          if(this.state.colorSelected=="carDriverColor"){
            this.setState({carDriverColor:color})
            params.updateColorDriver("carDriverColor",color)
          }
          else {
             this.setState({driverApproachingColor:color})
            params.updateColorDriver("driverApproachingColor",color)
          }

      }

      
      

      

  }



  findMoney(value){
    if(value==undefined) value="eu";
    var final="";
    var i;
    for(i=0;i<moneyList.length;i++){
      if(final==""){
        if(moneyList[i].id==value) final=moneyList[i];
      }
    }
    return final
  }


  getAllCar(){
    get(child(ref(getDatabase(firebase)), '/pricing')).then(value => {
        var moneyIDList={};
        var val1=value.val();
        var val1array=Object.keys(val1)
        get(child(ref(getDatabase(firebase)), '/pricingProvision')).then(value2 => {
          var val2=value2.val();
          var carList={};
          var i,j,pr,pra;
          if(val1!=null){
            for(i=0;i<val1array.length;i++){
                pr=val1[val1array[i]].price;
                pra=Object.keys(pr)
                for(j=0;j<pra.length;j++){
                    var name=pr[pra[j]].name;
                    if(pr[pra[j]].moneyID!=undefined) moneyIDList[pr[pra[j]].moneyID]=true; 
                    if(val1[val1array[i]].taxiMode==true) name="TAXI - "+name;
                    
                    carList[name]=false;


                } 
            }

          }
          if(val2!=null){
            var val2array=Object.keys(val2)
            for(i=0;i<val2array.length;i++){
              if(val2array[i]=="typeZone"){
                  var k,l;
                  var tttot=val2[val2array[i]]; 
                  for(k=0;k<tttot.length;k++){
                      if(tttot[k].moneyID!=undefined) moneyIDList[tttot[k].moneyID]=true;
                      var listTttot=Object.keys(tttot[k].price)
                      for(l=0;l<listTttot.length;l++){
                          carList[tttot[k].price[listTttot[l]].name]=false
                      }
                  }
              }
              else{
                  carList[val2[val2array[i]].name]=false;
                  if(val2[val2array[i]].moneyID!=undefined) moneyIDList[val2[val2array[i]].moneyID]=true;
              }
            }  
          }
          var allCar=this.state.carList
          if(allCar!={}){
            var cla=Object.keys(this.state.carList);
            for(i=0;i<cla.length;i++){
              if(carList[cla[i]]!=undefined) carList[cla[i]]=allCar[cla[i]]
            }
          }
          this.setState({carList:carList})

          if(this.state.extraEUMode==true && (Object.keys(moneyIDList).length>1 || (Object.keys(moneyIDList).length==1 && moneyIDList["eu"]!=true)) ){
              
              if(moneyIDList["eu"]!=true) {
                var infoMoney=this.findMoney(Object.keys(moneyIDList)[0]);
                this.setState({money:infoMoney})
              }
              else{
                var k;
                var moneyListInfo=[];
                for(k=0; k<moneyList.length;k++){
                  if( moneyIDList[ moneyList[k].id ] ===true ){
                    moneyListInfo.push(moneyList[k])
                  }
                }

                this.setState({moneyList:moneyListInfo})
              }


          }

         }) 

       }) 

  }

  _renderRow = ({key, index, data, active}) => {
    
    return <Row data={data} keyID={key} index={index} active={active} actionLink={this._actionLink} />
  }

   _actionLink= (info) => {
    var optionalOrder=this.state.optionalOrder;
    var optionalOrderArray=Object.keys(this.state.optionalOrder).sort((a,b)=>{ return this.state.optionalOrder[a].index-this.state.optionalOrder[b].index} )
    console.log(info)
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
    if(info.action=="remove"){
        
          delete optionalOrder[optionalOrderArray[info.keyID]]
          this.setState({optionalOrder:optionalOrder,updateValue:true},() =>{ this.setState({updateValue:false})})
          params.updateOptionalOrder(optionalOrder);
    }
    else if(info.action=="update"){
      var opt=this.state.optionalOrder[optionalOrderArray[info.keyID]];
      if(opt.description==undefined) opt.description={};
      if(opt.carList==undefined) opt.carList={};
      this.setState({seeDetail:true,optionID:optionalOrderArray[info.keyID], option:opt}) 
    }
    
  }


  updateDetail(){

      var opt=this.state.option;
      var td=false;
      if(opt.name["fr"]=="" || opt.name["fr"]==undefined ) {td=true; this.setState({seeDetail:false}); this.alert.onAlert({title:strings.thankWriteName,buttons:[{text: strings.ok,onPress: (value) => { this.setState({seeDetail:true}) }},] })}
      var i;
     
      
      var priceFinal={}
       if(opt.price!=undefined){
          var priceArray=Object.keys(opt.price)
          for(i=0; i<priceArray.length;i++){
              if(opt.price[priceArray[i]]!=""){
                opt.price[priceArray[i]]=parseFloat(opt.price[priceArray[i]])
                if(!isNaN(opt.price[priceArray[i]])) priceFinal[priceArray[i]]=opt.price[priceArray[i]]
              }
          }
      }
      opt.price=priceFinal;
      if(!(Object.keys(opt.description).length>0)) delete opt.description;

      if(this.state.vipMode==true && opt.target==undefined) opt.target={standard:true, vip:true, pro:true};

      if(td==false){

          var optionalOrder=this.state.optionalOrder;
          if(opt.index==undefined) opt.index=Object.keys(optionalOrder).length
          optionalOrder[this.state.optionID]=opt;
          this.setState({seeDetail:false, optionalOrder:optionalOrder,updateValue:true},() =>{ this.setState({updateValue:false})})
          var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
          params.updateOptionalOrder(optionalOrder);
      }
      
  }




  

  render() {
    let moneyListTotal=moneyList

    return (
      <StyleContext.Consumer>{style => 
      <ScrollView style={{flex:1,backgroundColor:style.backgroundColor}}>

        {(this.state.updateValue!=true && this.state.optionalOrder!=undefined) && <SortableList
                style={{height:Dimensions.get('window').height-(5*style.buttonHeight)-30}}
                contentContainerStyle={{}}
                data={Object.keys(this.state.optionalOrder).sort((a,b)=>{ return this.state.optionalOrder[a].index-this.state.optionalOrder[b].index} ).map(key => this.state.optionalOrder[key])}
                renderRow={this._renderRow}



                onReleaseRow={(val,currentOrder)=>{ 

                    var val2 = this.state.optionalOrder
                    var optionalOrderArray=Object.keys(val2);
                    var i;
                    for(i=0;i<currentOrder.length;i++){
                        val2[optionalOrderArray[currentOrder[i]]].index=i;
                    }
                    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
                    params.updateOptionalOrder(val2);
                    this.setState({optionalOrder:val2,updateValue:true},() =>{ this.setState({updateValue:false})})
                      


                }} 

            />}



                  


         {(this.state.viewAdmin!=true && this.state.optionalOrder!=undefined) && <View style={{alignItems:"center",justifyContent:"center", marginTop:Dimensions.get('window').height * 0.05,marginBottom:Dimensions.get('window').height * 0.05}}>
         <MavButton text={strings.add} onlyButton={true} onPress={()=>{ 
            var opt=generateUUID(); this.setState({seeDetail:true,optionID:opt,option:{name:{},price:{},carList:{}, description:{}, quantity:1  }, selectedElement:"fr", })

          }}/></View>}


          {(this.state.viewCarMode==true) && <View>

              <MavTextInput style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.viewRealDriver} textExplication={strings.viewRealDriver}   onChange={()=>{
                  var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
                  params.updateViewRealDriver(!this.state.viewRealDriver) 
                  this.setState({viewRealDriver:!this.state.viewRealDriver}) 
                }}/>

                <MavTextInput style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.viewRealDriverForDriver} textExplication={strings.viewRealDriverForDriver+". "+strings.clickMoreInfos} textExplicationOnPress={() => {this.alert.onAlert({title:strings.viewRealDriverForDriver, subTitle:strings.viewRealDriverForDriverMoreInfo}) }}  onChange={()=>{
                  var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
                  params.updateViewRealDriverForDriver(!this.state.viewRealDriverForDriver)
                  this.setState({viewRealDriverForDriver:!this.state.viewRealDriverForDriver}) 
                }}/>
            
                   <MavTextInput style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.hideOtherCarIfRaceInProgress} textExplication={strings.hideOtherCarIfRaceInProgress}   onChange={()=>{
                  var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
                  params.updateHideOtherCarIfRaceInProgress(!this.state.hideOtherCarIfRaceInProgress) 
                  this.setState({hideOtherCarIfRaceInProgress:!this.state.hideOtherCarIfRaceInProgress}) 
                }}/>

                <Text style={{fontSize: style.textInputFontSize*1.5, color:style.textInputColor,textAlign:"center",marginTop:20,marginRight:"10%", marginLeft:"10%"}}>{strings.carColor}</Text>
                <Text style={{fontSize: style.textInputFontSize*0.9, color:style.textInputColor,textAlign:"center",marginTop:5,marginRight:"10%", marginLeft:"10%"}}>{strings.carColorMoreInfo}</Text>

                <View style={{marginTop:20, width:"80%", marginLeft:"10%", marginRight:"10%", flexDirection: 'row',justifyContent: 'space-between', }}>
                    <View style={{alignItems:"center",width:"25%",}}>
                      <Image source={require('../assets/car.png')} style={{height: 60,width:60,resizeMode:"contain", }}/>
                      <Text style={{fontSize: style.textInputFontSize*0.75, color:style.textInputColor,  marginTop:5, textAlign:"center"}}>{strings.byDefault}</Text>
                    </View>


                    <View style={{alignItems:"center",width:"25%",}}>
                      <View style={{height: 60,width:60,}}>
                        <Image source={require('../assets/car.png')} style={{position:"absolute",height: 60,width:60,resizeMode:"contain", }}/>
                        {(this.state.carDriverColor!="" && Platform.OS=="web") && <View style={{position:"absolute",marginLeft:20, width:20, height:60, backgroundColor:this.state.carDriverColor}}/>}
                        {(this.state.carDriverColor!="" && Platform.OS!="web") && <Image source={require('../assets/car.png')} style={{position:"absolute",tintColor: this.state.carDriverColor,height: 60,width:60,resizeMode:"contain", }}/>}
                      </View>

                      <Text style={{fontSize: style.textInputFontSize*0.75,color:style.textInputColor, marginTop:5, marginBottom:5, textAlign:"center"}}>{strings.carDriver}</Text>
                      
                      <MavButton onlyButton={true} small={true}  text={strings.modify} style={{}} onPress={()=>{ 
                            var oldColor="";
                            if(this.state.carDriverColor!=""){
                              oldColor=this.state.carDriverColor
                              if(this.state.carDriverColor[0]=="r"){
                                oldColor=oldColor.replace('rgba(','')
                                oldColor=oldColor.replace(')','')
                                oldColor=oldColor.split(",");
                                oldColor="#"+((parseInt(oldColor[0])<16)?"0":"")+parseInt(oldColor[0]).toString(16) +((parseInt(oldColor[1])<16)?"0":"")+parseInt(oldColor[1]).toString(16)+((parseInt(oldColor[2])<16)?"0":"")+parseInt(oldColor[2]).toString(16)
                              } 
                            }
                            this.setState({colorSelected:"carDriverColor"}); 
                            this.alert.onAlert({title:strings.selectColor, subTitle:strings.explicationHexaColor,  choiceColor:true, textInput:{text:oldColor,sizeMultiplicator:1.5,placeholder:"(ex : #FF0000)"}, help:{title:strings.selectColor, subTitle:strings.explicationHexaColorMoreInfo, url:"https://fr.wikipedia.org/wiki/Couleur_du_Web" },buttons:[{text: strings.validate,onPress: (value) => { this.updateColor(value) }}, {text: strings.cancel, style:"cancel",onPress: () => {}}],})

                               
                       }}/>

                      <MavButton onlyButton={true} small={true} strokeMode={true} text={strings.remove} style={{marginTop:5}} onPress={()=>{ this.setState({carDriverColor:""});var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; params.updateColorDriver("carDriverColor","")}}/>


                    </View>


                    <View style={{alignItems:"center",width:"25%",}}>
                      <View style={{height: 60,width:60,}}>
                        <Image source={require('../assets/car.png')} style={{position:"absolute",height: 60,width:60,resizeMode:"contain", }}/>
                        {(this.state.driverApproachingColor!="" && Platform.OS=="web") && <View style={{position:"absolute",marginLeft:20, width:20, height:60, backgroundColor:this.state.driverApproachingColor}}/>}
                        {(this.state.driverApproachingColor!="" && Platform.OS!="web") && <Image source={require('../assets/car.png')} style={{position:"absolute",tintColor: this.state.driverApproachingColor,height: 60,width:60,resizeMode:"contain", }}/>}
                      </View>

                      <Text style={{fontSize: style.textInputFontSize*0.75,color:style.textInputColor, marginTop:5, marginBottom:5, textAlign:"center"}}>{strings.driverApproaching}</Text>
                      
                      <MavButton onlyButton={true} small={true}  text={strings.modify} style={{}} onPress={()=>{ 
                            var oldColor="";
                            if(this.state.driverApproachingColor!=""){
                              oldColor=this.state.driverApproachingColor
                              if(this.state.driverApproachingColor[0]=="r"){
                                oldColor=oldColor.replace('rgba(','')
                                oldColor=oldColor.replace(')','')
                                oldColor=oldColor.split(",");
                                oldColor="#"+((parseInt(oldColor[0])<16)?"0":"")+parseInt(oldColor[0]).toString(16) +((parseInt(oldColor[1])<16)?"0":"")+parseInt(oldColor[1]).toString(16)+((parseInt(oldColor[2])<16)?"0":"")+parseInt(oldColor[2]).toString(16)
                              } 
                            }
                            this.setState({colorSelected:"driverApproachingColor"}); 
                            this.alert.onAlert({title:strings.selectColor, subTitle:strings.explicationHexaColor,  choiceColor:true, textInput:{text:oldColor,sizeMultiplicator:1.5,placeholder:"(ex : #FF0000)"}, help:{title:strings.selectColor, subTitle:strings.explicationHexaColorMoreInfo, url:"https://fr.wikipedia.org/wiki/Couleur_du_Web" },buttons:[{text: strings.validate,onPress: (value) => { this.updateColor(value) }}, {text: strings.cancel, style:"cancel",onPress: () => {}}],})

                       }}/>

                      <MavButton onlyButton={true} small={true} strokeMode={true} text={strings.remove} style={{marginTop:5}} onPress={()=>{ this.setState({driverApproachingColor:""});var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; params.updateColorDriver("driverApproachingColor","")}}/>


                    </View>

                </View>

            
                <Text style={{fontSize: style.textInputFontSize*1.5, color:style.textInputColor,textAlign:"center",marginTop:20,marginRight:"10%", marginLeft:"10%"}}>{strings.seenFromAbove}</Text>
                <Text style={{fontSize: style.textInputFontSize*0.9, color:style.textInputColor, textAlign:"center",marginTop:5,marginRight:"10%", marginLeft:"10%"}}>{strings.seenFromAboveMoreInfo}</Text>

                {Object.keys(this.state.carList).map((rowData) => (

                  <View>
                    <View style={{width:"80%", marginLeft:"10%", marginRight:"10%",flexDirection: 'row',alignItems:"center", height:60,justifyContent: 'space-between',}}>
                        <Text style={{fontSize: style.textInputFontSize, color:style.textInputColor, width:"30%"}}>{rowData}</Text>
                        <Image source={(this.state.picturesCar.carUsed!=undefined && this.state.picturesCar.carUsed[rowData]!=undefined && this.state.picturesCar.carUsed[rowData].aboveSource!=undefined)?this.state.picturesCar.carUsed[rowData].aboveSource:   require('../assets/car.png')} style={{transform: [{rotate:"90deg"}], height: 60,width:60,resizeMode:"contain", }}/>
                        <MavButton onlyButton={true} small={true} text={strings.modify} style={{marginTop:5}} onPress={()=>{ this.setState({viewAboveCar:true, selectedAboveCar:rowData   });}}/>


                    </View>
                    <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,marginTop: style.textInputMarginTop,marginLeft:"10%",marginRight:"10%",}}/>

                  </View>

                         
                    ))}


                


          </View>}

          <Modal animationType="none" transparent={true} visible={this.state.viewAboveCar}>
            <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute", backgroundColor:style.backgroundColor, alignItems:"center"}}>
                 <Text style={{fontSize: style.textInputFontSize*1.5, color:style.textInputColor,textAlign:"center",marginTop:50,marginRight:"10%", marginLeft:"10%"}}>{strings.pictureAvaiable}</Text>
                 <Text style={{fontSize: style.textInputFontSize*0.9, color:style.textInputColor,textAlign:"center",marginTop:5,marginBottom:10,marginRight:"10%", marginLeft:"10%"}}>{strings.pictureAvaiableAboveMoreInfo}</Text>
                   <FlatList
                      data={Object.keys(this.state.picturesCar.carAvailable)}
                      renderItem={({ item }) => (
                        <View style={{ width:Dimensions.get('window').width*0.2,}}>

                            <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { this.updatePictureCar({type:"modifyAboveCar",uuidCar:item }) ; this.setState({viewAboveCar:false})    }} ><View> 
                                <Image source={this.state.picturesCar.carAvailable[item].source} style={{height: Dimensions.get('window').width*0.2,width:Dimensions.get('window').width*0.2,resizeMode:"contain", }}/>
                                {(( (this.state.picturesCar.carUsed==undefined || this.state.picturesCar.carUsed[this.state.selectedAboveCar]==undefined || this.state.picturesCar.carUsed[this.state.selectedAboveCar].above==undefined) && item=="default") || (this.state.picturesCar.carUsed!=undefined && this.state.picturesCar.carUsed[this.state.selectedAboveCar]!=undefined &&  this.state.picturesCar.carUsed[this.state.selectedAboveCar].above==item)) && <View style={{opacity:0.5,backgroundColor:style.backgroundColor,height:Dimensions.get('window').width*0.2,width:Dimensions.get('window').width*0.2, position:"absolute", alignItems:"center",justifyContent:"center",}}/>}
                                

                                {(( (this.state.picturesCar.carUsed==undefined || this.state.picturesCar.carUsed[this.state.selectedAboveCar]==undefined || this.state.picturesCar.carUsed[this.state.selectedAboveCar].above==undefined) && item=="default") || (this.state.picturesCar.carUsed!=undefined && this.state.picturesCar.carUsed[this.state.selectedAboveCar]!=undefined && this.state.picturesCar.carUsed[this.state.selectedAboveCar].above==item)) && <View style={{height:Dimensions.get('window').width*0.2,width:Dimensions.get('window').width*0.2, position:"absolute", alignItems:"center",justifyContent:"center",}}>
                                    <Text style={{fontSize: style.textInputFontSize*3,color:style.colorValidate}}>✓</Text>
                                </View>}
                            </View></TouchableHighlight>
                          
                        </View>
                      )}
                      
                      numColumns={4}
                      keyExtractor={(item, index) => index}
                    />

                    <View style={{alignItems:"center",justifyContent:"center",marginTop:10}}>
                          {(this.state.loadPicture!=true && Platform.OS!="web") && <MavButton text={strings.add} onlyButton={true} style={{marginTop:20}} onPress={()=>{this.takePicture() }}/>}
                          {this.state.loadPicture==true && <ActivityIndicator style={{marginTop:10}} size="large" color={style.color1} />}

                          <MavButton text={strings.close} onlyButton={true} strokeMode={true} style={{marginTop:10, marginBottom:50}} onPress={()=>{ 
                                this.setState({viewAboveCar:false})
                             }}/>


                        </View>



            </View>
          </Modal>

            
 

           

            <Modal animationType="none" transparent={true} visible={this.state.seeDetail}>
              <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute", backgroundColor:style.backgroundColor}}>
                <ScrollView style={{}}>
                        
                  <Text style={{fontSize: style.textInputFontSize*1.5, color:style.textInputColor,textAlign:"center",marginTop:50,marginRight:"10%", marginLeft:"10%"}}>{strings.lastName}</Text>
                  <Text style={{fontSize: style.textInputFontSize, color:style.textInputColor,textAlign:"center",marginRight:"10%", marginLeft:"10%"}}>{strings.defineNameOption}</Text>

                
                  <View style={{marginLeft:Dimensions.get('window').width * 0.1,marginTop:10, marginRight:Dimensions.get('window').width * 0.1, flexDirection: 'row',justifyContent:"center"}}>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"fr"}) }} > 
                            
                          <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/fr.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="fr" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>

                        </TouchableHighlight>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"en"}) }} > 
                            <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/gb.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="en" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>
                        </TouchableHighlight>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"it"}) }} > 
                            <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/it.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="it" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>
                        </TouchableHighlight>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"es"}) }} > 
                            <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/es.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="es" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>
                        </TouchableHighlight>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"ru"}) }} > 
                           <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/ru.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="ru" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>
                        </TouchableHighlight>
                  </View>

                   <MavTextInput style={{marginTop:5}} textInputFlex={1.5} text={this.state.option.name[this.state.selectedElement]} textExplication={strings.lastName} placeholder={"Bagage cabine"} onChangeText={(text)=>{ var opt=this.state.option; opt.name[this.state.selectedElement]=text;  this.setState({option:opt})}}/>
                   <MavTextInput style={{marginTop:5}} textInputFlex={1.5} text={this.state.option.description[this.state.selectedElement]} textExplication={strings.description} textExplicationMini={strings.facultatif} placeholder={strings.descriptionMoreOptionEx} multiline={true} onChangeText={(text)=>{ var opt=this.state.option; opt.description[this.state.selectedElement]=text;  this.setState({option:opt})}}/>


                   <Text style={{fontSize: style.textInputFontSize*1.5, color:style.textInputColor,textAlign:"center",marginTop:20,marginRight:"10%", marginLeft:"10%"}}>{strings.price}</Text>
                   <Text style={{fontSize: style.textInputFontSize, color:style.textInputColor,textAlign:"center",marginRight:"10%", marginLeft:"10%"}}>{strings.definePriceOption}</Text>

                   {(this.state.moneyList.length>1) && <View style={{marginLeft:Dimensions.get('window').width * 0.1,marginTop:10, marginRight:Dimensions.get('window').width * 0.1, flexDirection: 'row',justifyContent:"center"}}>

                        {this.state.moneyList.map((rowData) => (

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({money:rowData}) }} > 
                            
                          <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={rowData.flag} style={{height: 30,width:40, }}/>
                            {this.state.money.id==rowData.id && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>

                        </TouchableHighlight>

                        ))}

                        
                  </View>}



                   <MavTextInput style={{marginTop:5}} textInputFlex={1.5} keyboardType={"numeric"} text={(this.state.option.price!=undefined && this.state.option.price[this.state.money.id]!=undefined)?this.state.option.price[this.state.money.id].toString():""} textExplication={strings.price+" ("+this.state.money.symbole+")"} placeholder={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{  var opt=this.state.option; opt.price[this.state.money.id]=text; this.setState({option:opt})      }}/>

                   


                   <Text style={{fontSize: style.textInputFontSize*1.5, color:style.textInputColor,textAlign:"center",marginTop:20,marginRight:"10%", marginLeft:"10%"}}>{strings.quantity}</Text>
                   <Text style={{fontSize: style.textInputFontSize, color:style.textInputColor,textAlign:"center",marginRight:"10%", marginLeft:"10%"}}>{strings.quantityOption}</Text>

                   <MavTextInput style={{marginTop:5}} textInputFlex={1.5} enableButtonsLH={true} text={this.state.option.quantity} textExplication={strings.quantity}  onChange={(value)=>{ 
                          var opt=this.state.option;
                          if(!(this.state.option.quantity==1 && value===0)) this.state.option.quantity+=(value==0)?-1:1;
                          this.setState({option:opt})  
                      }} />
                   


                   <Text style={{fontSize: style.textInputFontSize*1.5, color:style.textInputColor,textAlign:"center",marginTop:20,marginRight:"10%", marginLeft:"10%"}}>{strings.target}</Text>
                   <Text style={{fontSize: style.textInputFontSize, color:style.textInputColor,textAlign:"center",marginRight:"10%", marginLeft:"10%"}}>{strings.targetOption}</Text>



                   {this.state.vipMode==true && <MavSelector style={{marginBottom:10}}  enabledItem={  (this.state.option.target!=undefined)?[this.state.option.target.standard,this.state.option.target.vip,this.state.option.target.pro] :[true,true, true] } textCenter={strings.vip} textLeft={strings.standard} textRight={strings.pro} onChange={(value)=>{ 
                                  
                      var opt=this.state.option; 
                      value*=2;
                      if(opt.target==undefined) opt.target={standard:true, vip:true, pro:true};
                      if(value==0) opt.target.standard=!opt.target.standard;
                      else if(value==1) opt.target.vip=!opt.target.vip;
                      else if(value==2) opt.target.pro=!opt.target.pro;


                      this.setState({option:opt})    


                    }}/>}


                    {Object.keys(this.state.carList).map((rowData) => (

                          <MavTextInput style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={(this.state.option.carList[rowData]==true)?true:false} textExplication={rowData} editable={!this.state.waiting} onChange={()=>{
                                var opt=this.state.option; 

                                opt.carList[rowData]=(opt.carList[rowData]==true)?false:true;
                                this.setState({option:opt})
                          }}/>
                    ))}







                        <View style={{alignItems:"center",justifyContent:"center",marginTop:10}}>
                          <MavButton text={strings.validate} onlyButton={true} style={{marginTop:20}} onPress={()=>{ this.updateDetail() }}/>
                        </View>

                        <MavButton text={strings.cancel} strokeMode={true} style={{marginTop:10, marginBottom:50}} onPress={()=>{ 
                                this.setState({seeDetail:false})
                             }}/>
                        
                      </ScrollView>
              </View>
            </Modal>

             <MavAlert ref={alert => {this.alert = alert;}}/>

      </ScrollView>

      }</StyleContext.Consumer>
    );
  }
}

account.navigationOptions = ({ navigation, screenProps }) => {
  return {
    headerTitle: strings.proposeOtherOptions,
    headerTintColor: screenProps.headerColor,
    headerStyle: {
      backgroundColor: screenProps.headerBackgroundColor,
    },
  };
};


export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
