import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  TextInput,
  Platform,
  Dimensions,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';

import firebase from './firebase';
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

import info from '../info';
import MavInfoBubble from './mavInfoBubble';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MapView from 'react-native-maps';
import MavAlert from './mavAlert';
import MajorationHighAffluence from './majorationHighAffluence';
import Modal from './modal';
import MavNavigationMinimize from './mavNavigationMinimize';


const timer = require('react-native-timer');
export default class account extends Component {
  constructor(props) {
    super(props);

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    this.state = {
      modify:false,
      highAffluence:{},
      night:"",
      ferie:"",
      saturday:"",
      sunday:"",
      morning:"",
      evening:"",
      dynamic:"",
      majoOther:{}, 

      zeroTwentyFive:"",
      twentyFiveFifty:"",
      oneHundredFifty:"",
      twoHundredFifty:"",

      fiftyOneHundred:"",
      oneHundredTwoHundred:"",
      twoHundredThreeHundred:"",
      moreThreeHundred:"",




      afternoon:"",
      viewMajorationHighAffluence:false, 
      passager:"",
      morethan:"",
      majoAvance:"",
      majoLast:"",
      majoAvancePercent:"",
      majoLastPercent:"",
      majoTwoHour:"",
      kilometerMax:"", 
      boosterSeats:"",
      wheelchair:"",
      animal:"", 
      childSeats:"", 
      noMajoForPro:false,
      noMajoForProvider:false,
      karhooMode:(params!=undefined && params.karhooMode!=undefined)?params.karhooMode:false,
      disabledChange:(params!=undefined && params.disabledChange!=undefined)?params.disabledChange:false,
      hideHoursMajoration:(params!=undefined && params.hideHoursMajoration!=undefined)?params.hideHoursMajoration:false,
      extraEUMode:(params!=undefined && params.extraEUMode!=undefined)?params.extraEUMode:false,
      majorationType:(params!=undefined && params.majorationType!=undefined)?params.majorationType:"local",
      money:(params!=undefined && params.money!=undefined)?params.money:{symbole:"€",position:"right"},
    };
  }

  majorationHighAffluenceGoBack= () => {
    this.setState({viewMajorationHighAffluence:false})
  }

  removeHighAffluence= uuid => {
      var ha=this.state.highAffluence;
      delete ha[uuid]
      this.setState({modify:true,highAffluence:ha})
  }

  updateHighAffluence= (value,uuid) => {
      var ha=this.state.highAffluence;
      if(uuid=="") uuid=generateUUID()
      ha[uuid]=value
      this.setState({modify:true,highAffluence:ha})
  }

  removeMajoOther= uuid => {
      var ha=this.state.majoOther;
      delete ha[uuid]
      this.setState({modify:true,majoOther:ha})
  }

  updateMajoOther= (value,uuid) => {

      console.log("updateMajoOther = ")
      console.log(value);
      var ha=this.state.majoOther;
      if(uuid=="") uuid=generateUUID()
      ha[uuid]=value


      this.setState({modify:true,majoOther:ha})
  }


  

  componentDidMount() {

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    if(params!=undefined && params.majoration!=undefined){
        console.log("ah on a une majoration")
        console.log(params.majoration)
        var r={raceMajoration:true}
        var list=Object.keys(params.majoration);
        var i;
        for(i=0;i<list.length;i++){
          if(list[i]=="highAffluence") r[list[i]]=params.majoration[list[i]]
          else if(list[i]=="majoOther") r[list[i]]=params.majoration[list[i]]
          else if(list[i]=="noMajoForPro" || list[i]=="noMajoForProvider") r[list[i]]=params.majoration[list[i]] 
          else {
              r[list[i]]=params.majoration[list[i]].toString();
              if(list[i]=="animal" || list[i]=="passager" || list[i]=="wheelchair" || list[i]=="childSeats" || list[i]=="majoAvance" || list[i]=="majoLast" || list[i]=="boosterSeats") r[list[i]]=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+r[list[i]]+((this.state.money.position=="right")?(this.state.money.symbole):"")
            }
          }
        this.setState(r)

    }
    else get(child(ref(getDatabase(firebase)), '/majoration')).then(value => {

        var val=value.val();
        console.log(val)
        if(val!=null){
            var r={night:"",ferie:"",kilometerMax:"",majoOther:{}, saturday:"",sunday:"",passager:"",morethan:"",fiftyOneHundred:"", zeroTwentyFive:"",twentyFiveFifty:"",oneHundredFifty:"",twoHundredFifty:"",oneHundredTwoHundred:"",twoHundredThreeHundred:"",moreThreeHundred:"",morning:"",evening:""}
            var list=Object.keys(val);
            var i;
            for(i=0;i<list.length;i++){
              if(list[i]=="highAffluence") r[list[i]]=val[list[i]]
              else if(list[i]=="majoOther") r[list[i]]=val[list[i]]
              else {
                r[list[i]]=val[list[i]].toString();
                if(list[i]=="animal" || list[i]=="passager" || list[i]=="wheelchair" ||list[i]=="childSeats" || list[i]=="majoAvance"  || list[i]=="majoLast" || list[i]=="boosterSeats") r[list[i]]=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+r[list[i]]+((this.state.money.position=="right")?(this.state.money.symbole):"")
              }
            }
            this.setState(r)
        }
      })
    
  }







render() {
    return (
      <StyleContext.Consumer>{style => 
      <ScrollView style={{ marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor }}>
         
          {this.state.viewMajorationHighAffluence==false && <View>
         {(Platform.OS=="web" && this.props.params!=undefined && this.props.params.goBack!=undefined) && <MavNavigationMinimize  clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={ strings.majoration } />}

        
        <MavInfoBubble type={"info"} title={strings.majoHowWork} subTitle={(this.state.majorationType!="global")?strings.localMajorationMoreInfo:strings.majoExplication} style={{marginTop:10,}} />

        {this.state.modify==true && <MavInfoBubble type={"warning"} title={strings.thinkSave} subTitle={strings.thinkSaveClickConfirm} style={{marginTop:10,}} />}


        <Text style={{marginTop:25,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.dayMajoration}</Text>



        <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {var params={disabledChange:this.state.disabledChange, highAffluence:this.state.highAffluence, updateHighAffluence:this.updateHighAffluence, removeHighAffluence:this.removeHighAffluence,  goBack:this.majorationHighAffluenceGoBack};if(Platform.OS=="web") this.setState({viewMajorationHighAffluence:params}) ;else this.props.navigation.navigate('MajorationHighAffluence',params); }}>
              <MavTextInput  disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={strings.majorationHighAffluence} />
        </TouchableHighlight>
        

        <MavTextInput style={{marginTop:5}} textInputFlex={0.65} editable={!this.state.disabledChange} keyboardType={"numeric"} text={this.state.saturday} textExplication={strings.majoSaturday} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,saturday:text})}}/>
        <MavTextInput style={{marginTop:5}} textInputFlex={0.65} editable={!this.state.disabledChange} keyboardType={"numeric"} text={this.state.sunday} textExplication={strings.majoSunday} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,sunday:text})}}/>
        
        <MavTextInput style={{marginTop:5}} textInputFlex={0.65} editable={!this.state.disabledChange} keyboardType={"numeric"} text={this.state.ferie} textExplication={strings.majoFerie} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,ferie:text})}}/> 
        







        <Text style={{marginTop:25,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.majoHourFrequency}</Text>

        {(this.state.raceMajoration!=true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={1.5} disabledText={true}  arrow={true} arrowEnabled={this.state.hideHoursMajoration} textExplication={strings.hideReasonMajoClient}  onChange={()=>{
          
            this.setState(() => ({hideHoursMajoration:(this.state.hideHoursMajoration==true)?false:true }),function(){  })
             }} 

             enableInfo={true} onEnableInfo={() => {this.alert.onAlert({title:strings.hideReasonMajoClient, subTitle:strings.hideReasonMajoClientExplication}) }}
        />}

         <MavTextInput style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.dynamic} textExplication={strings.majoDynamic}  placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,dynamic:text})}}  enableInfo={true} onEnableInfo={() => {this.alert.onAlert({title:strings.majoDynamic, subTitle:strings.majoDynamicMoreInfo}) }}/> 


        <MavTextInput style={{marginTop:5}} editable={!this.state.disabledChange} textExplicationMini={"07:00 - 09:00"} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.morning} textExplication={strings.majoMorning} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,morning:text})}}/>
        <MavTextInput style={{marginTop:5}} editable={!this.state.disabledChange} textExplicationMini={"16:00 - 19:00"} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.afternoon} textExplication={strings.majoAfternoon} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,afternoon:text})}}/> 
       

        
        <MavTextInput style={{marginTop:5}} editable={!this.state.disabledChange} textExplicationMini={"19:00 - 21:00"} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.evening} textExplication={strings.majoEvening} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,evening:text})}}/> 
        
        <MavTextInput style={{marginTop:5}} editable={!this.state.disabledChange} textExplicationMini={"21:00 - 07:00"} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.night} textExplication={strings.majoNight} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,night:text})}}/>
        
       
        <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { var params={hourOnly:true, disabledChange:this.state.disabledChange, majoOther:this.state.majoOther, updateMajoOther:this.updateMajoOther, removeMajoOther:this.removeMajoOther, goBack:this.majorationHighAffluenceGoBack};      if(Platform.OS=="web") this.setState({modify:true,viewMajorationHighAffluence:params}) ;else  this.props.navigation.navigate('MajorationHighAffluence',params);}}>
              <MavTextInput  disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={strings.otherMajorationHour} />
        </TouchableHighlight>
        



         



        
        


        {(this.state.extraEUMode==false || this.state.majorationType!="global") && <Text style={{marginTop:25,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.optionMajoration}</Text>}

        {(this.state.extraEUMode==false || this.state.majorationType!="global") && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.childSeats} textExplication={strings.majoChildSeats} placeholder={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{
          text=text.toString().replace(this.state.money.symbole,'')
          text=text.toString().replace(",",'.')
          this.setState({modify:true,childSeats:text})

          timer.setTimeout(generateUUID(), () => {
                    var test=this.state.childSeats.indexOf(this.state.money.symbole);
                    if(test==-1 && this.state.childSeats!="") {
                      this.setState({modify:true,childSeats: ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.childSeats+((this.state.money.position=="right")?(this.state.money.symbole):"") })
                    }
                }, 2000);


        }}/>}

        {(this.state.extraEUMode==false || this.state.majorationType!="global") && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.boosterSeats} textExplication={strings.majoBoosterSeats} placeholder={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{
          text=text.toString().replace(this.state.money.symbole,'')
          text=text.toString().replace(",",'.')
          this.setState({modify:true,boosterSeats:text})

          timer.setTimeout(generateUUID(), () => {
                    var test=this.state.boosterSeats.indexOf(this.state.money.symbole);
                    if(test==-1 && this.state.boosterSeats!="") {
                      this.setState({modify:true,boosterSeats: ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.boosterSeats+((this.state.money.position=="right")?(this.state.money.symbole):"") })
                    }
                }, 2000);


        }}/>}

        {(this.state.extraEUMode==false || this.state.majorationType!="global") && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.wheelchair} textExplication={strings.majoWheelchair} placeholder={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{
          text=text.toString().replace(this.state.money.symbole,'')
          text=text.toString().replace(",",'.')
          this.setState({modify:true,wheelchair:text})

          timer.setTimeout(generateUUID(), () => {
                    var test=this.state.wheelchair.indexOf(this.state.money.symbole);
                    if(test==-1 && this.state.wheelchair!="") {
                      this.setState({modify:true,wheelchair: ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.wheelchair+((this.state.money.position=="right")?(this.state.money.symbole):"") })
                    }
                }, 2000);


        }}/>}



        {(this.state.extraEUMode==false || this.state.majorationType!="global") && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.animal} textExplication={strings.majoAnimal} placeholder={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{
          text=text.toString().replace(this.state.money.symbole,'')
          text=text.toString().replace(",",'.')
          this.setState({modify:true,animal:text}) 

          timer.setTimeout(generateUUID(), () => {
                    var test=this.state.animal.indexOf(this.state.money.symbole);
                    if(test==-1 && this.state.animal!="") {
                      this.setState({modify:true,animal: ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.animal+((this.state.money.position=="right")?(this.state.money.symbole):"") })
                    }
                }, 2000);


        }}/>}

        {((this.state.extraEUMode==false || this.state.majorationType!="global") && this.state.raceMajoration!=true) && <MavTextInput style={{marginTop:5}} disableLine={true} editable={!this.state.disabledChange} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.passager} textExplication={strings.majoPassagers} placeholder={"1.5"} onChangeText={(text)=>{
          text=text.toString().replace(this.state.money.symbole,'')
          text=text.toString().replace(",",'.')
          this.setState({modify:true,passager:text})

          timer.setTimeout(generateUUID(), () => {
                    var test=this.state.passager.indexOf(this.state.money.symbole);
                    if(test==-1 && this.state.passager!="") {
                      this.setState({modify:true,passager: ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.passager+((this.state.money.position=="right")?(this.state.money.symbole):"") })
                    }
                }, 2000);

      }}/> }
        


        {((this.state.extraEUMode==false || this.state.majorationType!="global") && this.state.raceMajoration!=true) && <MavTextInput style={{marginTop:-5}} editable={!this.state.disabledChange} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.morethan} textExplication={strings.passagersMore} placeholder={"3"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,morethan:text})}}/> }
        


        

        


        <Text style={{marginTop:25,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.orderMajoration}</Text>

        <MavTextInput style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} editable={!this.state.disabledChange} text={this.state.majoAvancePercent} textExplication={strings.majoAvancePercent} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,majoAvancePercent:text})}}/> 

        {(this.state.extraEUMode!=true && this.state.majorationType!="global") && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.majoAvance} textExplication={strings.majoAvance} placeholder={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{
          text=text.toString().replace(this.state.money.symbole,'')
          text=text.toString().replace(",",'.')
          this.setState({modify:true,majoAvance:text})

          timer.setTimeout(generateUUID(), () => {
                    var test=this.state.majoAvance.indexOf(this.state.money.symbole);
                    if(test==-1 && this.state.majoAvance!="") {
                      this.setState({modify:true,majoAvance: ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.majoAvance+((this.state.money.position=="right")?(this.state.money.symbole):"") })
                    }
                }, 2000);


        }}/> }

        <MavTextInput style={{marginTop:5}} textInputFlex={0.65} editable={!this.state.disabledChange} keyboardType={"numeric"} text={this.state.majoLastPercent} textExplication={strings.majoLastPercent} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,majoLastPercent:text})}}/> 

        <MavTextInput style={{marginTop:5}} textInputFlex={0.65} editable={!this.state.disabledChange} keyboardType={"numeric"} text={this.state.majoTwoHour} textExplication={strings.majoTwoHour} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,majoTwoHour:text})}}/> 

        {(this.state.extraEUMode!=true && this.state.majorationType!="global")  && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.majoLast} textExplication={strings.majoLast} placeholder={((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{
          text=text.toString().replace(this.state.money.symbole,'')
          text=text.toString().replace(",",'.')
          this.setState({modify:true,majoLast:text})

          timer.setTimeout(generateUUID(), () => {
                    var test=this.state.majoLast.indexOf(this.state.money.symbole);
                    if(test==-1 && this.state.majoLast!="") {
                      this.setState({modify:true,majoLast: ((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.majoLast+((this.state.money.position=="right")?(this.state.money.symbole):"") })
                    }
                }, 2000);


        }}/> }


        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <Text style={{marginTop:25,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.majoKilometer}</Text>}
        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <Text style={{marginLeft:"10%", marginRight:"10%",marginBottom:10,marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.majoKMNegatif}</Text>}



        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:15}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.zeroTwentyFive} textExplication={strings.majoZeroTwentyFive} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.') ;this.setState({modify:true,zeroTwentyFive:text})}}/>} 
        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.twentyFiveFifty} textExplication={strings.majoTwentyFiveFifty} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.') ;this.setState({modify:true,twentyFiveFifty:text})}}/>} 
        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.fiftyOneHundred} textExplication={strings.majoFiftyOneHundred} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.') ;this.setState({modify:true,fiftyOneHundred:text})}}/>} 

        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.oneHundredFifty} textExplication={strings.majoOneHundredFifty} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.') ;this.setState({modify:true,oneHundredFifty:text})}}/>} 
        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.oneHundredTwoHundred} textExplication={strings.majoOneHundredTwoHundred} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,oneHundredTwoHundred:text})}}/> }
        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.twoHundredFifty} textExplication={strings.majoTwoHundredFifty} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,twoHundredFifty:text})}}/> }

        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.twoHundredThreeHundred} textExplication={strings.majoTwoHundredThreeHundred} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,twoHundredThreeHundred:text})}}/> }

        {(this.state.raceMajoration!=true || this.state.karhooMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} text={this.state.moreThreeHundred} textExplication={strings.majoMoreThreeHundred} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');this.setState({modify:true,moreThreeHundred:text})}}/> }
       


        {this.state.raceMajoration!=true && <Text style={{marginTop:25,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.others}</Text>}

        {this.state.raceMajoration!=true && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={1.5} disabledText={true}  arrow={true} arrowEnabled={this.state.noMajoForPro} textExplication={strings.noMajoForPro} onChange={()=>{this.setState({modify:true,noMajoForPro:!this.state.noMajoForPro}) }} />}
        {this.state.raceMajoration!=true && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={1.5} disabledText={true}  arrow={true} arrowEnabled={this.state.noMajoForProvider}  textExplication={strings.noMajoForProvider} onChange={()=>{this.setState({modify:true,noMajoForProvider:!this.state.noMajoForProvider}) }} />}




        



        {(!this.state.disabledChange) && <View style={{alignItems:"center",justifyContent:"center", marginTop:Dimensions.get('window').height * 0.05,marginBottom:Dimensions.get('window').height * 0.05}}>
          <MavButton text={strings.confirm} onlyButton={true} onPress={()=>{
            var val=this.state;
            var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
            var r={};
            var list=Object.keys(val);
            var t;
            var i; 
            for(i=0;i<list.length;i++){
              
              if(list[i]=="highAffluence") r[list[i]]=val[list[i]]
              else if(list[i]=="majoOther") r[list[i]]=val[list[i]]
              else if(list[i]=="noMajoForPro" || list[i]=="noMajoForProvider") {
                console.log("ra "+val[list[i]])
                console.log(val[list[i]]==true)
                r[list[i]]=(val[list[i]]==true || val[list[i]]=="true")?true:null 
                console.log(r[list[i]])
              }
              else {
                t=val[list[i]].toString()
                t=t.replace(this.state.money.symbole,'') 
                t=parseFloat(t)

                if(!isNaN(t) && t>0) r[list[i]]=t
              }
            }
            if(r.morethan!=undefined) r.morethan=parseInt(r.morethan)
            if(params!=undefined && params.majoration!=undefined)  params.updateMajoration(r)
            else {
              const fireInfo={} 
              fireInfo['/majoration']=r; 
              update(ref(getDatabase(firebase)), fireInfo)
            }

            if(this.state.raceMajoration!=true && params!=undefined && params.updateHideHoursMajoration!=undefined) params.updateHideHoursMajoration(this.state.hideHoursMajoration);

            if(this.props.navigation!=undefined) this.props.navigation.goBack();
            else this.props.params.goBack(); 

         }}/>

        
         </View>}

         {(this.state.disabledChange) &&<View style={{height:50}}/>}

         </View>}


         <MavAlert ref={alert => {this.alert = alert;}}/>

         {this.state.viewMajorationHighAffluence!=false &&  
              <View style={{alignItems:"center",justifyContent:"center",width:"100%", height:"90%"}}> 
                  <MajorationHighAffluence params={this.state.viewMajorationHighAffluence } />
              </View>}



      </ScrollView>
      }</StyleContext.Consumer>
    );
  }
}



export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
