import React, { Component } from 'react';
import {
View,
  Text,
  ScrollView,TouchableHighlight,FlatList,Dimensions,Animated,Image, Easing,Platform
} from 'react-native';
import {StyleContext} from '../styleC';

import firebase from './firebase';
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

import strings from '../strings';
import info from '../info';
import MavTextInput from './mavTextInput';
import SortableList from './SortableList';
import MavButton from './mavButton';
import MavAlert from './mavAlert';
import Modal from './modal';
import MavNavigationMinimize from './mavNavigationMinimize';
import MavViewMore from './mavViewMore';


export default class help extends Component {
  constructor(props) {
    super(props);
    var params=(this.props.route!=undefined)?this.props.route.params.params:this.props.params; 

   




    this.state={
      params:params,
      driverList:undefined,
      
    }
  }
  
   componentDidMount(){

    console.log("this.state.params.desiredDriver.other")

    }

    modifyWaiting(){

       this.alert.onAlert({title:"Voulez-vous continuer ?",subTitle:"Vous allez repasser en attente de validation. Si aucun chauffeur n'accepte la course, le client va se retrouver sans chauffeur alors qu'un chauffeur avait été attribué",buttons:[
        {text: strings.yes,onPress: () => { this.modify("status","waiting") }},   
        {text: strings.no, style:"cancel",onPress: () => {}}
              ],});

     
    }

    modify(type, data){
      
      if(type=="status"){

        var params=this.state.params;
        params.status=data;
        this.setState({params:params})
      }

      var sendInfo=(this.props.route!=undefined)?this.props.route.params:this.props; 

      console.log(sendInfo)
      sendInfo.modifyRace(type, data)

      /*status  
      duration
      provisionMode
      raceMode
      endAdress*/

    }

    getDriver(){

    get(query(ref(getDatabase(firebase), "users"), orderByChild('partner'), startAt(true), endAt(true))).then(value => {
        var i; 
        var val=value.val()
        if(val==null) val={}
        var partnerList=(this.state.params.desiredDriver!=undefined && this.state.params.desiredDriver.other!=undefined)?this.state.params.desiredDriver.other:[]; 
        for(i=0;i<partnerList.length;i++){
          delete val[partnerList[i].id]
        }
        this.setState({driverList:val})

      })
    }

    modifyKm(race){

      this.alert.onAlert({title:"Modifier le kilométrage",textInput:{placeholder:"10km",text:(this.state.params.estimateKm!=undefined)?this.state.params.estimateKm:"",sizeMultiplicator:2,detectSequencePosition:"right",detectSequence:" km",keyboardType:"numeric"},buttons:[
      {text: strings.validate,onPress: (value) => {
         var estg=value.toString().replace(" km",'')
         estg+=" km"; 
         var params=this.state.params;
         params.estimateKm=estg; 
         this.setState({params:params})
         this.modify("km",estg)
         if(race==true) this.modifyDuration("race"); 
        

      }},
      {text: strings.cancel, style:"cancel",onPress: () => {}}
      ],})

    }

    modifyDuration(typeModif){
      
      var duree=Math.trunc(this.state.params.estimateTimeValue/60)+" min"
      this.alert.onAlert({title:(typeModif=="provision")?"Durée de la mise à disposition":"Modifier la durée",textInput:{placeholder:"60 min",text:duree,sizeMultiplicator:2,detectSequencePosition:"right",detectSequence:" min",keyboardType:"numeric"},buttons:[
      {text: strings.validate,onPress: (value) => {
         var estg=value.toString().replace(" min",'')
         estg*=60;
         var params=this.state.params;
         params.estimateTimeValue=parseInt(estg); 
         if(typeModif=="provision") params.racePlaceEndNameTest=undefined
         this.setState({params:params})
         this.modify("duration",estg)
         if(typeModif=="provision") this.modify("provisionMode")
         else if(typeModif=="race") this.modify("raceMode")

        

      }},
      {text: strings.cancel, style:"cancel",onPress: () => {}}
      ],})

    }

    changeToRace(){
      var endAdress="";
      this.alert.onAlert({title:"Quelle est la destination ?",textInput:{placeholder:"Adresse",text:endAdress},buttons:[
      {text: strings.validate,onPress: (value) => {
         var params=this.state.params;
         params.racePlaceEndNameTest=value;
         this.setState({params:params})
         this.modify("endAdress", value)
         this.modifyKm(true)
         
        

      }},
      {text: strings.cancel, style:"cancel",onPress: () => {}}
      ],})


    }


    






  render() {
    return (
      <StyleContext.Consumer>{style => 
      <View style={{backgroundColor:style.backgroundColor,flex:1,}}>
      {(Platform.OS=="web") && <MavNavigationMinimize clickLeft={() => { this.props.goBack();  }} left={strings.return} title={ strings.help } />}

      <ScrollView >

        <MavViewMore text={(this.state.params.status=="invoiced" || this.state.params.status=="cancel")?"Pourquoi je ne peux pas modifier la course ?" :"Puis-je modifier le statut de la course"}>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Actuellement, votre course à le statut "}<Text style={{fontWeight: 'bold' }}>{(this.state.params.status!="validate")?( (this.state.params.status!="cancel")?"terminée" :"annulée" ):"validée"}</Text>{(this.state.params.status=="invoiced")?". Votre course est terminé et la facture a été édité. Vous ne pouvez donc plus modifier les informations. Si vous souhaitez tout de même le faire, vous pouvez revenir au statut 'course validée' et vous pourrez effectuer des modifications. Pour rappel, la loi Française n'autorise pas la modification des factures. Si vous étiez en paiement différé et que vous aviez édité la facture, la référence à la course ne sera pas supprimé." : ( (this.state.params.status=="cancel")?". Vous ne pouvez donc plus modifier les informations car votre course est annulée. Si vous le souhaitez tout de même le faire, vous pouvez re-valider cette course" : ". Vous pouvez forcer la modification de ce statut en cliquant ci-dessous. Nous vous deconseillons de le faire cependant (risque d'incompréhension côté client, risque d'erreur côté chauffeur ...)")}</Text>
            
             <View style={{width: "100%",alignItems:"center",justifyContent:"center",marginTop:10 }}>
                      {(this.state.params.status=="invoiced" || this.state.params.status=="cancel" || this.state.params.status=="finish") && <MavButton onlyButton={true} text={"Revenir en 'course validée'"}  onPress={()=>{ this.modify("status","validate")  }}/>}
                      {(this.state.params.status=="validate") && <MavButton onlyButton={true} text={"Revenir en 'attente de validation'"}  onPress={()=>{ this.modifyWaiting() }}/>}
             </View>

        
        </MavViewMore>

        <MavViewMore text={"Pourquoi c'est mon nom qui apparait et non celui du chauffeur dans le bon de commande" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"L'affichage du bon de commande est utile en cas de contrôle des forces de l'ordre. Ainsi c'est le nom de la personne qui consulte la course qui apparait dans le bon de commande. Cela veut dire que votre chauffeur verra bien son nom inscrit sur le bon de commande. En revanche, si pour une raison diverse vous vous retrouvez à faire la course et que vous avez oublié de vous passer en 'chauffeur', vous n'aurez aucun problème en cas de contrôle car la course sera automatiquement à votre nom"}</Text>
        </MavViewMore>

        <MavViewMore text={"Pourquoi certains chauffeurs n'apparaissent pas ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Au moment de la commande, l'application regarde quels sont les chauffeurs qui sont compatibles avec la demande du client. L'application se basera sur plusieurs paramètres tels que la voiture demandé par le client, la langue du client, les options (comme le siège auto, le réhausseur ...), la zone de travail, les clients / chauffeurs bloqués ... Vous pouvez modifier ces informations en allant dans Répertoire puis sur la fiche du chauffeur en question."+ ((info.enableOnOffButton==true)?" L'application se base aussi sur le statut du chauffeur 'en ligne' / 'hors ligne'. S'il était hors ligne au moment de la commande, il n'apparaitra donc pas dans la liste des chauffeurs compatibles.":"")+" Vous pouvez également tenter de comprendre pourquoi le chauffeur n'apparait pas ici."  }</Text>
        
            {(this.state.driverList==undefined) && <View style={{width: "100%",alignItems:"center",justifyContent:"center",marginTop:10 }}>
                <MavButton onlyButton={true} text={"Comprendre pourquoi"}  onPress={()=>{ this.getDriver() }}/>
             </View>}

             {this.state.driverList!=undefined && Object.keys(this.state.driverList).map((rowData, key) => (<View>
                
                <Text allowFontScaling={false} style={{fontWeight: 'bold', fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{this.state.driverList[rowData].name+" "+this.state.driverList[rowData].lastName}</Text>
                {(this.state.params.carName!=undefined && (this.state.driverList[rowData].carList==undefined || this.state.driverList[rowData].carList[this.state.params.carName]!=true)) && <Text allowFontScaling={false} style={{fontWeight: 'bold', fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%",color:style.colorNotification}}>{"Voiture incompatible ("+this.state.params.carName+")"}</Text>}
                {(this.state.params.vip==true && this.state.driverList[rowData].vip!=true) && <Text allowFontScaling={false} style={{fontWeight: 'bold', fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%",color:style.colorNotification}}>{"Incompatible avec les VIP"}</Text>}
               

                {(this.state.driverList[rowData].acceptedOptions==undefined) && <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize*0.8,marginLeft:"10%", marginRight:"10%",color:style.colorWaiting}}>{"Aucune option acceptée"}</Text>}
                {(this.state.driverList[rowData].acceptedOptions!=undefined) && <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize*0.8,marginLeft:"10%", marginRight:"10%",color:style.colorWaiting}}>{"Option(s) non acceptée(s) : "+((this.state.driverList[rowData].acceptedOptions.childSeats!=true)?"siège auto | ":"")+((this.state.driverList[rowData].acceptedOptions.boosterSeats!=true)?"réhausseur | ":"")+((this.state.driverList[rowData].acceptedOptions.wheelchair!=true)?"fauteuil roulant | ":"")+((this.state.driverList[rowData].acceptedOptions.animal!=true)?"animaux":"")}</Text>}
                {(this.state.driverList[rowData].noZone!=undefined) && <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize*0.8,marginLeft:"10%", marginRight:"10%",color:style.colorWaiting}}>{"Le chauffeur n'a pas le droit de travailler dans certaines zones"}</Text>}
                {(this.state.driverList[rowData].noLanguage!=undefined) && <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize*0.8,marginLeft:"10%", marginRight:"10%",color:style.colorWaiting}}>{"Le chauffeur n'accepte pas certaines langues"}</Text>}
                {(info.enableOnOffButton==true) && <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize*0.8,marginLeft:"10%", marginRight:"10%",color:(this.state.driverList[rowData].partnerOnline==false)?style.colorNotification:style.colorWaiting}}>{"Le chauffeur n'était peut être pas en ligne au moment de la commande"+((this.state.driverList[rowData].partnerOnline==false)?" (actuellement il est hors ligne)":"") }</Text>}
                
        



             </View>))}




        </MavViewMore>


        {this.state.params.status!="invoiced" && <MavViewMore text={"Que signifie le 'non payé' au niveau du prix de la course ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Le non payé signifie que la personne n'a pas payé par carte dans l'application et qu'elle n'est pas non plus en paiement différé. Il faudra donc l'encaisser à bord du véhicule"}</Text>
        </MavViewMore>}


        {(this.state.params.status!="invoiced" && this.state.params.racePlaceEndNameTest!=undefined) && <MavViewMore text={"Comment modifier le kilométrage ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Le kilométrage a été défini directement par l'API de Google Maps. Si vous avez changé le trajet ou que vous trouvez que ce chiffre n'est pas le bon, vous pouvez cliquer sur le bouton ci-dessous pour le modifier"}</Text>
            <View style={{width: "100%",alignItems:"center",justifyContent:"center",marginTop:10 }}>
                <MavButton onlyButton={true} text={"Changer le kilométrage"}  onPress={()=>{ this.modifyKm() }}/>
            </View>

        </MavViewMore>}

         {(this.state.params.status!="invoiced" && this.state.params.racePlaceEndNameTest!=undefined) && <MavViewMore text={"Comment changer la durée ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"La durée de la course a été défini directement par l'API de Google Maps. Si vous avez changé le trajet ou que vous trouvez que ce chiffre n'est pas le bon, vous pouvez cliquer sur le bouton ci-dessous pour la modifier"}</Text>
            <View style={{width: "100%",alignItems:"center",justifyContent:"center",marginTop:10 }}>
                <MavButton onlyButton={true} text={"Changer la durée"}  onPress={()=>{ this.modifyDuration() }}/>
            </View>

        </MavViewMore>}


        {this.state.params.status!="invoiced" && <MavViewMore text={"Comment changer la date de la course ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Pour modifier la date de la course, il suffit de cliquer sur la date au niveau de l'écran précédent (où il y a toutes les informations de la course). Il suffira d'indiquer la nouvelle date / nouvelle heure et de valider"}</Text>
        </MavViewMore>}

        {this.state.params.status!="invoiced" && <MavViewMore text={"Comment changer le chauffeur ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Pour modifier le chauffeur, il suffit de cliquer sur 'chauffeur attribué' dans l'écran précédent (où il y a toutes les informations de la course). Il suffira de changer le chauffeur par le nouveau"}</Text>
        </MavViewMore>}


        {(this.state.params.status!="invoiced" && this.state.params.racePlaceEndNameTest!=undefined) && <MavViewMore text={"Puis-je passer en mise à disposition ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Passer d'une course à une mise à disposition n'est pas forcement pertinent (difference de TVA par exemple). Si vous souhaitez tout de même passer sur une mise à disposition, cliquez ci-dessous"}</Text>
            <View style={{width: "100%",alignItems:"center",justifyContent:"center",marginTop:10 }}>
                <MavButton onlyButton={true} text={"Passer en mise à disposition"}  onPress={()=>{ this.modifyDuration("provision") }}/>
            </View>

        </MavViewMore>}

        {(this.state.params.status!="invoiced" && this.state.params.racePlaceEndNameTest==undefined) && <MavViewMore text={"Puis-je transformer cette course en course classique ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Cela serait probablement plus pertinent de supprimer cette mise à disposition et de créer une nouvelle course. Si vous voulez, vous pouvez cependant la transformer en cliquant ci-dessous"}</Text>
            <View style={{width: "100%",alignItems:"center",justifyContent:"center",marginTop:10 }}>
                <MavButton onlyButton={true} text={"Passer sur une course"}  onPress={()=>{ this.changeToRace() }}/>
            </View>

        </MavViewMore>}

        <MavViewMore text={"Les chauffeurs peuvent-ils avoir accès à cette FAQ ?" }>
            <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Non, seuls les admins peuvent voir cette FAQ. En effet, celle-ci contient des infos que les chauffeurs n'ont pas à connaitre (comme l'affectation des chauffeurs par exemple)"}</Text>
        </MavViewMore>
 

 
      
      
          
  
      </ScrollView>

      

        <MavAlert ref={alert => {this.alert = alert;}}/>

      </View>
      }</StyleContext.Consumer>
    );
  }
}





export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
