export default {
  name:"Camille",
  lastName:"Martin",
  company:"Société SAS",
  adress:"40 rue du platane",
  email:"camille@mail.com",
  phone:"0600000000",


  

}
 


  