import React, { Component } from 'react';

import Main from './main';
import {StyleProvider,StyleContext} from '../styleC';

export default class book extends Component {
  constructor(props) {
    super(props);

  }

  render() {
   
    return (
       <StyleProvider>

       <StyleContext.Consumer>
          {style => 
            <Main/>}
        </StyleContext.Consumer>


          
        </StyleProvider>


    );
  }
}

