import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  ScrollView,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import MavNavigationMinimize from './mavNavigationMinimize';
import MavAlert from './mavAlert';

const backgroundStyle=(info.bundleIdentifer=="BIPMOTO")?true:false;

import MavButton from './mavButton';
import MavTextInput from './mavTextInput';
export default class choiceCar extends Component {
constructor(props) { 
    super(props);

    
    var params=this.props.mainInfo
    /*console.log(params)*/

    
    this.state = {
      dispenser:{enabled:false}, 
      
    }
  }
  render() {


    return (
      <StyleContext.Consumer>{style => <View>

           {(this.props.mainInfo.dispenser.enabled==true) && <MavNavigationMinimize maxiMode={true} clickLeft={() => { this.props.selectDate()  }} left={strings.return} title={strings.priceChoiceVehicule } clickRight={() => { this.props.changeState({viewBook:true});  }} right={strings.next} />}

                {(this.props.mainInfo.dispenser.enabled!=true) && <Text style={{marginTop:10,color: (backgroundStyle===true)?"#FFFFFF":style.textInputColor,fontSize: style.textInputFontSize*1.4,textAlign: 'center'}}>{strings.priceEstimation.toUpperCase()}</Text>}
                
                {this.props.mainInfo.kmEstimation!="" && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
                  var mess=strings.delayKMExplication;
                  if(this.props.mainInfo.messageEstimation!=false && this.props.mainInfo.messageEstimation[strings.idLangue]!=undefined) mess=this.props.mainInfo.messageEstimation[strings.idLangue];
                  this.alert.onAlert({maxiMode:this.props.mainInfo.dispenser.enabled, title:strings.estimation,subTitle:mess});  

                }}>
                  <Text style={{color: (backgroundStyle===true)?"#FFFFFF":style.textInputColor,fontSize: style.textInputFontSize*0.8*((this.props.mainInfo.dispenser.enabled==true)?2.5:1),textAlign: 'center'}}>{this.props.mainInfo.kmEstimation+" - "+Math.round(this.props.mainInfo.timeEstimation/60)+" min ("}<Text style={{textDecorationLine: 'underline'}}>{strings.estimation.toLowerCase()}</Text><Text>)</Text></Text>
                </TouchableHighlight>}


                {(backgroundStyle===true && this.props.mainInfo.estimatePriceGo.length==1 && this.props.mainInfo.estimatePriceGoTaxi.length==0) && <View style={{width:"100%", height:style.textInputHeight, alignItems:"center", justifyContent:"center"}}>
                  <Text style={{color:"#FFFFFF", fontSize:30,textAlign:"center"}}>{((this.props.mainInfo.money.position=="left")?(this.props.mainInfo.money.symbole+" "):"")+this.props.mainInfo.estimatePriceGo[0].realPrice.priceTotal+((this.props.mainInfo.money.position=="right")?(this.props.mainInfo.money.symbole):"")}</Text>

                </View>}
                
                  
                {(!(backgroundStyle===true && this.props.mainInfo.estimatePriceGo.length==1 && this.props.mainInfo.estimatePriceGoTaxi.length==0) && this.props.mainInfo.estimatePriceGo!="") && this.props.mainInfo.estimatePriceGo.map((item,i) => (
                  <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { this.props.changeState({selected:i})}}>
                      <MavTextInput maxiMode={this.props.mainInfo.dispenser.enabled==true} backgroundColor={(backgroundStyle===true)?style.color1:undefined} textInputColor={(backgroundStyle===true)?"#FFFFFF":undefined} style={{marginTop:0}} enableNext={false} arrowCircle={true} arrow={true} arrowEnabled={(this.props.mainInfo.selected==i)?true:false} onChange={()=>{this.props.changeState({selected:i})}}  marginSize={(this.props.mainInfo.dispenser.enabled==true)?undefined:20}  textExplicationBold={true} disableLine={true} textExplication={item.name} disabledText={true} textInputFlex={0.2} textExplicationMini={(item.passagers!=undefined && (item.passagers>1 || (item.luggageMax!=undefined && item.luggageMax>1)  ) )?( ( (item.passagersMin!=undefined)?(item.passagersMin+"-"):"" )+item.passagers+" "+((item.passagers>1)?strings.morePassager.toLowerCase():strings.onePassager.toLowerCase() ) + ( (item.luggageMax!=undefined)?(" - "+  item.luggageMax+" "+((item.luggageMax>1)?strings.baggageMax.toLowerCase():strings.oneBaggageMax.toLowerCase() )    ):""    ) +((item.co2!=undefined && isNaN(item.co2)!=true && this.props.mainInfo.co2onOrder===true )?" - CO2 : "+item.co2+"g":"" )  ):((item.co2!=undefined && isNaN(item.co2)!=true && this.props.mainInfo.co2onOrder===true )?"CO2 : "+item.co2+"g":undefined )} textRight={((this.props.mainInfo.money.position=="left")?(this.props.mainInfo.money.symbole+" "):"")+item.realPrice.priceTotal+((this.props.mainInfo.money.position=="right")?(this.props.mainInfo.money.symbole):"") +((this.props.mainInfo.noVAT==true)?"HT":"") } enableInfo={(this.props.mainInfo.viewCalcPrice==true || this.props.mainInfo.driver==true)?true:false} onEnableInfo={() => { this.props.viewCalculDetail("vtc",i) }} />
                  </TouchableHighlight>
                ))}

                {(this.props.mainInfo.estimatePriceGoTaxi!="") && this.props.mainInfo.estimatePriceGoTaxi.map((item,i) => (
                  <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { this.props.changeState({selected:i+this.props.mainInfo.estimatePriceGo.length })}}>
                      <MavTextInput maxiMode={this.props.mainInfo.dispenser.enabled==true} backgroundColor={(backgroundStyle===true)?style.color1:undefined} textInputColor={(backgroundStyle===true)?"#FFFFFF":undefined} style={{marginTop:0}} enableNext={false} arrowCircle={true} arrow={true} arrowEnabled={(this.props.mainInfo.selected==i+this.props.mainInfo.estimatePriceGo.length)?true:false} onChange={()=>{this.props.changeState({selected:i+this.props.mainInfo.estimatePriceGo.length})}}  marginSize={(this.props.mainInfo.dispenser.enabled==true)?undefined:20} textExplicationBold={true} disableLine={true} textExplication={item.name} disabledText={true} textInputFlex={0.2} textExplicationMini={strings.taxi.toUpperCase()+" - "+( (item.passagers!=undefined)?( item.passagers+" "+((item.passagers>1)?strings.morePassager.toLowerCase():strings.onePassager.toLowerCase() ) + ( (item.luggageMax!=undefined)?(" - "+  item.luggageMax+" "+((item.luggageMax>1)?strings.baggageMax.toLowerCase():strings.oneBaggageMax.toLowerCase() )    ):""    ) +((item.co2!=undefined && isNaN(item.co2)!=true && this.props.mainInfo.co2onOrder===true )?" - CO2 : "+item.co2+"g":"" ) ): ((item.co2!=undefined && isNaN(item.co2)!=true && this.props.mainInfo.co2onOrder===true )?"CO2 : "+item.co2+"g":undefined ) ) } textRight={(item.cpam==true)?"CPAM": (((this.props.mainInfo.money.position=="left")?(this.props.mainInfo.money.symbole+" "):"")+item.realPrice.priceTotal+((this.props.mainInfo.money.position=="right")?(this.props.mainInfo.money.symbole):"")+((this.props.mainInfo.noVAT==true)?"HT":""))} enableInfo={(this.props.mainInfo.viewCalcPrice==true || this.props.mainInfo.driver==true)?true:false} onEnableInfo={() => { this.props.viewCalculDetail("taxi",i)  }}  />
                  </TouchableHighlight>
                ))}

                {(this.props.mainInfo.dispenser.enabled!=true) && <View style={{width:"100%", alignItems:"center",marginTop:15,marginBottom:15 }}>
                  <MavButton color={(backgroundStyle===true)?"#FFFFFF":undefined} colorText={(backgroundStyle===true)?style.color1:undefined} text={strings.book} onlyButton={true} onPress={()=>{this.props.order();}}/>
              </View>}
    
   
          <MavAlert ref={alert => {this.alert = alert;}}/>

        </View> }</StyleContext.Consumer>


    	)}

    }

