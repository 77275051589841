import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Image,
  Linking
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';
import strings from '../strings';
import MavTextInput from './mavTextInput';

export default class MavButton extends Component {
constructor(props) { 
    super(props);






    
    this.state = {
      test:false,
      

    }
  }

  openURL(link){
    if(Platform.OS==="web") window.open(link, '_blank');
    else Linking.openURL(link)
  }




  render() {
    return (
    <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>


      {this.props.type=="menu" && <View style={{width:"100%", alignItems:"center",justifyContent:"center" }}>
                {this.props.icon!=undefined && <Image source={this.props.icon} style={{marginTop:10,height:40,width:40,resizeMode:"contain"}}/>}
                <Text style={{marginLeft:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.05), marginRight:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.05),marginTop:3,marginBottom:0,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{this.props.title}</Text>
                <Text style={{marginLeft:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.05), marginRight:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.05),marginTop:3,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.9,textAlign: "justify",}}>{this.props.subTitle}</Text>


                {this.props.note!=undefined && <View style={{flexDirection:'row',marginBottom:10, alignItems:"center",justifyContent:"center"}}> 

                      <Image source={(this.props.note.note>0)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.props.note.note>1)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.props.note.note>2)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.props.note.note>3)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.props.note.note>4)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>

                      <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.7,textAlign: "center",marginLeft:5}}>{"("+this.props.note.note+"/5 - "+this.props.note.reviews+" "+strings.reviews.toLowerCase()+")"}</Text>

                </View>}











      </View>}


      {this.props.type!="menu" && <View style={{backgroundColor:(this.props.type=="help")?undefined:style.backgroundColorContrast,minHeight:20,marginLeft:(Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.05, marginRight:(Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeMarginLeft:Dimensions.get('window').width*0.05, shadowOpacity:(this.props.type=="help")?0:0.3,shadowRadius:2,shadowOffset: {height: 0,width: 0},elevation:1 }}> 
      <View style={{flexDirection:"row", alignItems:"center",marginTop:4, marginBottom:(this.props.miniLine==true)?6:undefined, }}>
        {this.props.type=="warning" && <Image source={require("../assets/warning.png") } style={{width:20,height:20,marginRight:2,marginLeft:4,}}></Image>}
        {this.props.type=="info"  && <Image source={(style.mode=="dark")?require('../assets/iconiWhite.png'):require('../assets/iconi.png') } style={{width:20,height:20,marginRight:0,marginLeft:4,}}></Image>}
        {(this.props.type=="help")  && <Image source={(style.mode=="dark")?require('../assets/help_white.png'):require('../assets/help_black.png') } style={{width:20,height:20,marginRight:0,marginLeft:4,}}></Image>}


        <View>
            <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize,fontWeight: 'bold',textAlign: "left",marginLeft:5}}>{(this.props.type=="help")?strings.needHelp:this.props.title}</Text>
            {(this.props.miniLine==true && this.props.subTitle!=undefined) && <Text style={{marginRight:4, marginLeft:4,  color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",marginTop:-2}}>{this.props.subTitle}</Text>}
        </View>
      </View>

      {(this.props.miniLine!=true && this.props.subTitle!=undefined) && <Text style={{marginRight:4, marginLeft:4, marginBottom:4, color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",marginTop:5}}>{this.props.subTitle}</Text>}
      {(this.props.type=="help" && this.props.videoLink!=undefined) && <TouchableHighlight underlayColor={"#00000000"} onPress={() => { this.openURL(this.props.videoLink) }} >  
      <View style={{flexDirection:"row", alignItems:"center",marginRight:4, marginLeft:4, marginBottom:4,marginLeft:30,marginTop:5  }}>
          <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.9,textAlign: "justify",textDecorationLine: 'underline'}}>{"- "+strings.videoTuto}</Text>
          <Image source={(style.mode=="dark")?require('../assets/share_white.png'):require('../assets/share_black.png') } style={{width:13,height:13,marginRight:0,marginLeft:4,}}></Image>
      </View>
      </TouchableHighlight>}

      {(this.props.type=="help" && this.props.tutoLink!=undefined) && <TouchableHighlight underlayColor={"#00000000"} onPress={() => { this.openURL(this.props.tutoLink) }} >  
      <View style={{flexDirection:"row", alignItems:"center",marginRight:4, marginLeft:4, marginBottom:4,marginLeft:30,marginTop:10  }}>
          <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.9,textAlign: "justify",textDecorationLine: 'underline'}}>{"- "+strings.seeBlog}</Text>
          <Image source={(style.mode=="dark")?require('../assets/share_white.png'):require('../assets/share_black.png') } style={{width:13,height:13,marginRight:0,marginLeft:4,}}></Image>
      </View>
      </TouchableHighlight>}


      {(this.props.type=="help" && this.props.others!=undefined) && this.props.others.map((rowData) => (
      <TouchableHighlight underlayColor={"#00000000"} onPress={() => { this.openURL(rowData.link) }} >  
      <View style={{flexDirection:"row", alignItems:"center",marginRight:4, marginLeft:4, marginBottom:4,marginLeft:30,marginTop:10  }}>
          <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.9,textAlign: "justify",textDecorationLine: 'underline'}}>{"- "+rowData.title}</Text>
          <Image source={(style.mode=="dark")?require('../assets/share_white.png'):require('../assets/share_black.png') } style={{width:13,height:13,marginRight:0,marginLeft:4,}}></Image>
      </View>
      </TouchableHighlight>))}

     


              
        </View>}


    </View>
    }</StyleContext.Consumer>
    	)}

    }

