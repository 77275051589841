export default{
     no:{name:"no",iconDark:"",iconLight:""},
     flight:{name:"flight",iconDark:require('./assets/favorite/flight_white.png'),iconLight:require('./assets/favorite/flight_black.png'), mapColor:"#37AFAE"},
     train:{name:"train",iconDark:require('./assets/favorite/train_white.png'),iconLight:require('./assets/favorite/train_black.png'), mapColor:"#37AFAE"},
     home:{name:"home",iconDark:require('./assets/favorite/home_white.png'),iconLight:require('./assets/favorite/home_black.png'), mapColor:"#9D110A"},
     bus:{name:"bus",iconDark:require('./assets/favorite/bus_white.png'),iconLight:require('./assets/favorite/bus_black.png'), mapColor:"#37AFAE"},
     portfolio:{name:"portfolio",iconDark:require('./assets/favorite/portfolio_white.png'),iconLight:require('./assets/favorite/portfolio_black.png'), mapColor:"#919191"},
     restaurant:{name:"restaurant",iconDark:require('./assets/favorite/restaurant_white.png'),iconLight:require('./assets/favorite/restaurant_black.png'), mapColor:"#DD8003"},
     bed:{name:"bed",iconDark:require('./assets/favorite/bed_white.png'),iconLight:require('./assets/favorite/bed_black.png'), mapColor:"#FF9F8F"},
     library:{name:"library",iconDark:require('./assets/favorite/library_white.png'),iconLight:require('./assets/favorite/library_black.png'), mapColor:"#919191"},
     foot:{name:"foot",iconDark:require('./assets/favorite/foot_white.png'),iconLight:require('./assets/favorite/foot_black.png'), mapColor:"#1DA611"},
     cinema:{name:"cinema",iconDark:require('./assets/favorite/cinema_white.png'),iconLight:require('./assets/favorite/cinema_black.png'), mapColor:"#9D110A"},
     ferry:{name:"ferry",iconDark:require('./assets/favorite/ferry_white.png'),iconLight:require('./assets/favorite/ferry_black.png'), mapColor:"#37AFAE"},
     bar:{name:"bar",iconDark:require('./assets/favorite/bar_white.png'),iconLight:require('./assets/favorite/bar_black.png'), mapColor:"#DD8003"},
     caddy:{name:"caddy",iconDark:require('./assets/favorite/caddy_white.png'),iconLight:require('./assets/favorite/caddy_black.png'), mapColor:"#DD8003"},
     heart:{name:"heart",iconDark:require('./assets/favorite/heart_white.png'),iconLight:require('./assets/favorite/heart_black.png'), mapColor:"#9D110A"},
     previous:{name:"previous",iconDark:require('./assets/favorite/previous_white.png'),iconLight:require('./assets/favorite/previous_black.png'), mapColor:"#919191"},
     customerFavorite:{name:"customerFavorite",iconDark:require('./assets/favorite/customer_favorite_white.png'),iconLight:require('./assets/favorite/customer_favorite_black.png'), mapColor:"#919191"}
   }




