export default[
	{"code":"#00A2FF", type:"light"},
	{"code":"#18E7CF", type:"light"},
	{"code":"#61D835", type:"light"},
	{"code":"#FAE232", type:"light"},
	{"code":"#FF644E", type:"light"},
	{"code":"#EF5FA7", type:"light"},

	{"code":"#004D80", type:"dark"},
	{"code":"#027B76", type:"dark"},
	{"code":"#017100", type:"dark"},
	{"code":"#FF9301", type:"dark"},
	{"code":"#B51700", type:"dark"},
	{"code":"#991A5E", type:"dark"},

	{"code":"#FFFFFF", type:"BandW"},
	{"code":"#E2E5E5", type:"BandW"},
	{"code":"#999A9A", type:"BandW"},
	{"code":"#6C6C6C", type:"BandW", checkWhite:true},
	{"code":"#313131", type:"BandW", checkWhite:true},
	{"code":"#000000", type:"BandW", checkWhite:true}
	
	   ]














 
 

 
 
