import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  ScrollView,
  Animated,
  ActivityIndicator,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';


const timer = require('react-native-timer');
import MavButton from './mavButton';

export default class TextPresentation extends Component {
constructor(props) { 
    super(props);



    
    
    this.state = {
      waitDetailTrajet:false, 
      viewDetailTrajet:false,
      detailTrajet:undefined,
      

    }
  }




  viewDetail(){
  if(this.state.viewDetailTrajet==false){
      if(this.state.detailTrajet==undefined){
          this.setState({waitDetailTrajet:true, viewDetailTrajet:true});
         
          fetch("https://api.sncf.com/v1/coverage/sncf/vehicle_journeys/"+this.props.journey+"/?", {method: "GET",headers: {'Authorization': '670396b7-c369-4eae-bb7e-eb3f17426463', 'Content-Type': 'application/x-www-form-urlencoded'}, }).then(response => response.json()).then(responseJson => {
          /*je vais une requête pour afficher le détail du trajet */
          this.setState({waitDetailTrajet:false});

          if(responseJson.vehicle_journeys!=undefined && responseJson.vehicle_journeys[0]!=undefined && responseJson.vehicle_journeys[0].stop_times!=undefined){
              var i,j;
              var stop=[]; 
              var stInfo;
              var stopSearch;
              var cause="";
              for(i=0; i<responseJson.vehicle_journeys[0].stop_times.length;i++){
                stInfo={bold:(responseJson.vehicle_journeys[0].stop_times[i].stop_point.label==this.props.stationName)?true:false,id:responseJson.vehicle_journeys[0].stop_times[i].stop_point.id, name:responseJson.vehicle_journeys[0].stop_times[i].stop_point.name, date:responseJson.vehicle_journeys[0].stop_times[i].departure_time[0]+responseJson.vehicle_journeys[0].stop_times[i].departure_time[1]+":"+responseJson.vehicle_journeys[0].stop_times[i].departure_time[2]+responseJson.vehicle_journeys[0].stop_times[i].departure_time[3]}

                if(responseJson.disruptions!=undefined && responseJson.disruptions[0]!=undefined && responseJson.disruptions[0].impacted_objects!=undefined && responseJson.disruptions[0].impacted_objects[0]!=undefined && responseJson.disruptions[0].impacted_objects[0].impacted_stops!=undefined ){
                  j=0;
                  stopSearch=false; 
                  while(j<responseJson.disruptions[0].impacted_objects[0].impacted_stops.length && stopSearch==false){
                      if( responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].stop_point.id==stInfo.id){
                        if(responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].departure_status=="deleted") stInfo.deleted=true;
                        else if(responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].amended_departure_time!=undefined) {
                          stInfo.newDate=responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].amended_departure_time[0]+responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].amended_departure_time[1]+":"+responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].amended_departure_time[2]+responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].amended_departure_time[3]
                          stInfo.date=responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].base_departure_time[0]+responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].base_departure_time[1]+":"+responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].base_departure_time[2]+responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].base_departure_time[3]
                          if(cause=="" && responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].base_departure_time[0]+responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].cause!="" && responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].base_departure_time[0]+responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].cause!="") cause=responseJson.disruptions[0].impacted_objects[0].impacted_stops[j].cause; 
                        }
                        stopSearch=true;
                      }
                      else j++;
                  }
                  
                }
                stop.push(stInfo); 
              

              }

              



              this.setState({detailTrajet:stop})
              if(cause!="") this.setState({cause:cause})
          }
          else this.setState({detailTrajet:"noResult"})
          

          })




      } else {
          this.setState({viewDetailTrajet:true})
      }

  }
  else this.setState({viewDetailTrajet:false})


  

  
}


  componentDidMount() {
      console.log("train detail mount")
      this.viewDetail()

  }

  render() {


    return (
      <StyleContext.Consumer>{style => 
          <View style={(this.props.style==undefined)?{}:this.props.style}>

            <View >
              {(this.state.waitDetailTrajet==true) && <ActivityIndicator style={{marginTop:35,marginBottom:35}} size="large" color={style.color1} />}
              {(this.state.detailTrajet=="noResult") && <Text style={{marginTop:15, marginBottom:15, color: style.textInputColor,fontStyle: 'italic',fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.noInfo}</Text>}
              
             {(this.state.detailTrajet!=undefined && this.state.detailTrajet!="noResult") && <View style={{marginTop:7, marginLeft:10, marginRight:10}}>



                  <View style={{flexDirection:"row", alignItems:"center",marginBottom:10}} >
                        <Image source={require('../assets/sncf/sncf.png')} style={{height:25,width:20,resizeMode:"contain", marginRight:6}}/>
                        {this.state.cause==undefined && <View>
                          <Text style={{color: style.textInputColor,fontStyle: 'italic',fontSize: style.textInputFontSize*0.8,textAlign: "left",}}>{strings.infoBySNCF}</Text>
                          <Text style={{color: style.textInputColor,fontStyle: 'italic',fontSize: style.textInputFontSize*0.6,textAlign: "left",marginTop:-2}}>{strings.indicatifOnly}</Text>
                        </View>}
                        {this.state.cause!=undefined && <View>
                          <Text style={{color: style.colorNotification,fontStyle: 'italic',fontSize: style.textInputFontSize*1,textAlign: "left",fontWeight:"bold"}}>{this.state.cause}</Text>
                          <Text style={{color: style.textInputColor,fontStyle: 'italic',fontSize: style.textInputFontSize*0.6,textAlign: "left",marginTop:-2}}>{strings.indicatifOnly}</Text>
                        </View>}

                  
                  </View>


                <View style={{justifyContent: 'space-between',flexDirection: 'row',height:30, marginLeft:5 }}>
                      <View style={{flexDirection: 'row',maxWidth:"75%",}}>
                        <View style={{ marginRight:10, }}>
                            <View style={{width:10, height:10,borderRadius: 5, backgroundColor:style.color1, }}/>
                            <View style={{width:2, height:11, backgroundColor:style.color1, marginLeft:4 }}/>
                            <View style={{width:2, height:11, backgroundColor:style.color1, marginLeft:4 }}/>
                        </View>
                        
                            <Text style={{ fontSize:style.textInputFontSize*0.8,color:style.textInputColor,marginTop:-1, textDecorationLine: (this.state.detailTrajet[0].deleted==true)?'line-through':undefined,  fontWeight: (this.state.detailTrajet[0].bold==true)?'bold':undefined}}>{this.state.detailTrajet[0].name}</Text>
                         
                      </View>
                      <View style={{flexDirection:'row', alignItems:"center", marginTop:-10}}>
                              <Text style={{fontSize:style.textInputFontSize*((this.state.detailTrajet[0].newDate!=undefined)?0.7:1),color:style.textInputColor,marginTop:-2, textDecorationLine: (this.state.detailTrajet[0].deleted==true || this.state.detailTrajet[0].newDate!=undefined)?'line-through':undefined,  fontWeight: (this.state.detailTrajet[0].bold==true)?'bold':undefined}}>{this.state.detailTrajet[0].date}</Text>
                              {this.state.detailTrajet[0].newDate!=undefined && <Text style={{marginLeft:5, fontSize:style.textInputFontSize,color:style.colorNotification,marginTop:-2,  fontWeight: (this.state.detailTrajet[0].bold==true)?'bold':undefined}}>{this.state.detailTrajet[0].newDate}</Text>}
                          </View>

                </View>


                {this.state.detailTrajet.map((rowData, stI)=>(     
                    <View>
                    {(stI!=0 && stI!=this.state.detailTrajet.length-1) &&  <View style={{justifyContent: 'space-between',flexDirection: 'row',height:30, marginLeft:5}}>
                          <View style={{flexDirection: 'row',maxWidth:"75%"}}>
                            <View style={{ marginRight:10,}}>
                                <View style={{width:10, height:10, borderRadius: 5, borderColor:style.color1,borderWidth:2 }}/>
                                <View style={{width:2, height:11,  backgroundColor:style.color1, marginLeft:4, }}/>
                                <View style={{width:2, height:11,  backgroundColor:style.color1, marginLeft:4, }}/>
                            </View>
                             
                            <Text style={{ fontSize:style.textInputFontSize*0.8,color:style.textInputColor,marginTop:-1,textDecorationLine: (this.state.detailTrajet[stI].deleted==true)?'line-through':undefined, fontWeight: (this.state.detailTrajet[stI].bold==true)?'bold':undefined}}>{this.state.detailTrajet[stI].name}</Text>
                            
                          </View>
                          <View style={{flexDirection:'row', alignItems:"center", marginTop:-10}}>
                              <Text style={{fontSize:style.textInputFontSize*((this.state.detailTrajet[stI].newDate!=undefined)?0.7:1),color:style.textInputColor,marginTop:-2, textDecorationLine: (this.state.detailTrajet[stI].deleted==true || this.state.detailTrajet[stI].newDate!=undefined)?'line-through':undefined,  fontWeight: (this.state.detailTrajet[stI].bold==true)?'bold':undefined}}>{this.state.detailTrajet[stI].date}</Text>
                              {this.state.detailTrajet[stI].newDate!=undefined && <Text style={{marginLeft:5, fontSize:style.textInputFontSize,color:style.colorNotification,marginTop:-2,  fontWeight: (this.state.detailTrajet[stI].bold==true)?'bold':undefined}}>{this.state.detailTrajet[stI].newDate}</Text>}
                          </View>

                    </View>}
                    </View>
                 ))}


                 <View style={{justifyContent: 'space-between',flexDirection: 'row', marginLeft:5,  }}>
                      <View style={{flexDirection: 'row',maxWidth:"75%",}}>
                        <View style={{ marginRight:10, }}>
                            <View style={{width:10, height:10,borderRadius: 5, backgroundColor:style.color1, }}/>
                        </View>
                        
                            <Text style={{ fontSize:style.textInputFontSize*0.8,color:style.textInputColor,marginTop:-2,fontWeight: (this.state.detailTrajet[this.state.detailTrajet.length-1].bold==true)?'bold':undefined}}>{this.state.detailTrajet[this.state.detailTrajet.length-1].name}</Text>
                         
                      </View>

                      <View style={{flexDirection:'row', alignItems:"center", marginTop:0}}>
                              <Text style={{fontSize:style.textInputFontSize*((this.state.detailTrajet[this.state.detailTrajet.length-1].newDate!=undefined)?0.7:1),color:style.textInputColor,marginTop:-2, textDecorationLine: (this.state.detailTrajet[this.state.detailTrajet.length-1].deleted==true || this.state.detailTrajet[this.state.detailTrajet.length-1].newDate!=undefined)?'line-through':undefined,  fontWeight: (this.state.detailTrajet[this.state.detailTrajet.length-1].bold==true)?'bold':undefined}}>{this.state.detailTrajet[this.state.detailTrajet.length-1].date}</Text>
                              {this.state.detailTrajet[this.state.detailTrajet.length-1].newDate!=undefined && <Text style={{marginLeft:5, fontSize:style.textInputFontSize,color:style.colorNotification,marginTop:-2,  fontWeight: (this.state.detailTrajet[this.state.detailTrajet.length-1].bold==true)?'bold':undefined}}>{this.state.detailTrajet[this.state.detailTrajet.length-1].newDate}</Text>}
                      </View>


                </View>

                </View>}

                {(this.props.close!=undefined) && <View style={{width:"100%", alignItems:"center", justifyContent:"center",marginBottom:15, marginTop:15 }}>
                    <MavButton onlyButton={true} text={strings.close} onPress={()=>{this.props.close() }} />
                </View>}
            </View>
          </View>

      }</StyleContext.Consumer>


    	)}

    }

