import React, { Component } from 'react';
import {
  View,
  Text,

  TouchableHighlight,
  Alert,
 
  Dimensions,
  Image,
} from 'react-native';
import style from '../style'; 
import firebase from './firebase';
import strings from '../strings';
import info from '../info';
const timer = require('react-native-timer');
import MavAlert from './mavAlert';
import Account from "./account"
import Payment from "./payment"
import Booking from "./booking"
import Contact from "./contact"
import Favorite from "./favorite"
import Help from "./help"
import Clients from "./clients"

export default class navigateView extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      visible:false, 
      view:false, 
      position:"right",
      listOpen:[], 
      items:{
        Account:false,
        Payment:false,
        Booking:false,
        Contact:false,
        Favorite:false,
        Help:false,
        Clients:false,
      }
    }; 


  }



  componentDidMount(){ 
    console.log("menu mount")




    

  }
open = (type, params,position) => {
    console.log("je rend le navigate visible"+type)

    var sd={}
    if(params!=undefined) sd=params;
    sd.goBack=() => {

      var listOpen=this.state.listOpen
      var remove=listOpen[listOpen.length-1];
      listOpen.pop();
      var items=this.state.items;
      items[remove]=false;

      this.setState({items:items, listOpen:listOpen, visible:(listOpen.length==0)?false:true})}
    

    sd.navigate=(type,params) => {this.open(type,params)}

    sd.resetNavigation=() => {
      var items=this.state.items;
      var i;
      var list=Object.keys(items);
      for(i=0;i<list.length;i++) items[list[i]]=false


      this.setState({items:items, listOpen:[], visible:false})
    }
    


    var items=this.state.items;
    items[type]=true;
    var listOpen=this.state.listOpen
    listOpen.push(type);

    console.log(listOpen)

    this.setState({visible:true, position:(position!=undefined)?position:"right", items:items, listOpen:listOpen, params:sd })
    
  }

  close = (type, cb) => {
    console.log("close navigate")
    this.setState({visible:false})
    

  }


  

  render() {


    return (
      <View style={{flex:1}}> 

            {this.props.children}
            {(this.state.visible===true) && <View style={{position:"absolute"}}>
              

             <View style={{marginLeft:(Dimensions.get('window').width>style.mobileSizeMax)?( (this.state.position=="left")?15:(Dimensions.get('window').width-style.minimizeWidth) ) :0,    width:(Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth:Dimensions.get('window').width, height:Dimensions.get('window').height+((Dimensions.get('window').width>style.mobileSizeMax && this.state.position=="left")?-30:0), marginTop:(Dimensions.get('window').width>style.mobileSizeMax && this.state.position=="left")?15:0, backgroundColor:"#FF0000", position:"absolute"}}>

                {this.state.items.Account===true && <View style={{flex:(this.state.listOpen[this.state.listOpen.length-1]=="Account")?1:0    }}><Account params= {this.state.params}  /></View>}
                {this.state.items.Payment===true && <View style={{flex:(this.state.listOpen[this.state.listOpen.length-1]=="Payment")?1:0      }}><Payment params= {this.state.params}  /></View>}
                {this.state.items.Booking===true && <View style={{flex:(this.state.listOpen[this.state.listOpen.length-1]=="Booking")?1:0    }}><Booking params= {this.state.params}  /></View>}
                {this.state.items.Contact===true && <View style={{flex:(this.state.listOpen[this.state.listOpen.length-1]=="Contact")?1:0      }}><Contact params= {this.state.params}  /></View>}
                {this.state.items.Favorite===true && <View style={{flex:(this.state.listOpen[this.state.listOpen.length-1]=="Favorite")?1:0      }}><Favorite params= {this.state.params}  /></View>}
                
                {this.state.items.Help===true && <View style={{flex:1, visible:(this.state.listOpen[this.state.listOpen.length-1]=="Help")?1:0      }}><Help params= {this.state.params}  /></View>}
                
                {this.state.items.Clients===true && <View style={{position:(this.state.listOpen[this.state.listOpen.length-1]=="Clients")?"relative":"absolute", width:(this.state.listOpen[this.state.listOpen.length-1]=="Clients")?"100%":"0%", height:(this.state.listOpen[this.state.listOpen.length-1]=="Clients")?"100%":"0%", marginTop:(this.state.listOpen[this.state.listOpen.length-1]=="Clients")?"0%":"-100%" }}><Clients params= {this.state.params}  /></View>}
                


             </View>

             </View>}





      </View>
    );
  }
}

navigateView.navigationOptions = params => {
  return {
    headerTintColor: style.headerColor,
    headerStyle: {
      backgroundColor: style.headerBackgroundColor,
    },
    
   
  };
};


export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

