import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  Dimensions,
  Image,
  Alert,
  ScrollView,
  ActivityIndicator,
  Platform, 
  Clipboard,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import firebase from './firebase';
import MavButton from './mavButton';
import MavAlert from './mavAlert';
import Wait3DSecure from './wait3DSecure';
const timer = require('react-native-timer');
import moneyList from '../moneyList';
import MavNavigationMinimize from './mavNavigationMinimize';
import AddCreditCard from './addCreditCard';
import TextPresentation from './textPresentation';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue} from "firebase/database";

export default class payment extends Component {
  constructor(props) {
    super(props);
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 


    var requireList=[];
    var i;
    for (i=0;i<info.paymentList.length;i++){
      var r;
      if(info.paymentList[i]=="paymentCash") r=require('../assets/paymentCash.png')
        else if (info.paymentList[i]=="paymentCard") r=require('../assets/paymentCard.png')
        else if (info.paymentList[i]=="paymentTransfer") r=require('../assets/paymentTransfer.png')
        else if (info.paymentList[i]=="paymentPaypal") r=require('../assets/paymentPaypal.png')
        else r=require('../assets/paymentBankCheck.png')

          requireList.push(r)

      
    }
    var exempleDateCard=new Date();
    exempleDateCard.setFullYear(exempleDateCard.getUTCFullYear()+2)
    exempleDateCard=exempleDateCard.getUTCFullYear().toString()[2]+exempleDateCard.getUTCFullYear().toString()[3]
    exempleDateCard="07/"+exempleDateCard;


    this.state={
      waiting:true,
      subscriptionAdmin:(params!=undefined && params.subscriptionAdmin==true)?true:false,
      mode:(info.paymentCardApp!==true)?"classic":"mainCard",
      extraEUMode:(params!=undefined && params.extraEUMode==true)?true:false,
      requireList:requireList,
      exempleDateCard:exempleDateCard,
      newCard:{number:"",expiration:"",cvv:""},
      cardList:[],
      params:params, 
      buyRechargePrice:"",
      useRechargeCode:"",
      balance:0,
      waiting3DSecure:false,
      balanceOther:[],
      subscriptionsList:{}, 
      subscriptionActual:{id:""},
      subscriptionOld:{id:""},
    }
    

  }

  findMoney(value){
    if(value==undefined) value="eu";
    var final="";
    var i; 
    for(i=0;i<moneyList.length;i++){
      if(final==""){
        if(moneyList[i].id==value) final=moneyList[i];
      }
    }
    return final
  }




removeCard(cardId){
  if(this.state.waiting===false){
    this.setState({waiting:true})
    console.log(cardId)

    getAuth(firebase).currentUser.getIdToken().then(token => {
              var body={
                  userID:getAuth(firebase).currentUser.uid,
                  bundleIdentifer:info.bundleIdentifer,
                  cardId:cardId,
                  token:token,
                  bundleAdress:info.firebaseProjectID,
                  version:info.appliVersion,
              }

              fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/deleteStripeCard", {
                    body: JSON.stringify(body),
                    method: "POST"}).then((response) => response.text())
                  .then((responseJson) => {
                if(responseJson==="ok"){this.setState({waiting:false}); this.getUserCards()}
                      else{console.log(responseJson); this.alert.onAlert({title:strings.errorDefault});this.setState({waiting:false})}


                     }).catch((err) => { console.log("error removeCard"); console.log(err); timer.setTimeout("removeCard", () => { this.removeCard();  }, 2000);   })
      })


    }

}

getUserBalance(){
  getAuth(firebase).currentUser.getIdToken().then(token => {
              var body={
                  userID:getAuth(firebase).currentUser.uid,
                  bundleIdentifer:info.bundleIdentifer,
                  token:token,
                  bundleAdress:info.firebaseProjectID,
                  version:info.appliVersion
              }
              fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getUserBalance", {
                      body: JSON.stringify(body),
                        method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {
                            console.log("reponse de getUserBalance")
                            
                            var res=responseJson
                            this.setState({balance:res.balance,waiting:false,  })
                            if(res.accountBalanceOther!=undefined){
                              var arrBO=Object.keys(res.accountBalanceOther)
                              var tot=[];
                              var mo;
                              var i;
                              for(i=0;i<arrBO.length;i++){

                                var ab=""+arrBO[i];
                                mo=this.findMoney(arrBO[i]);
                    

                                tot.push({amount: res.accountBalanceOther[ab], symbole:mo.symbole,position:mo.position  })
                              }
                              this.setState({balanceOther:tot})
                              this.getSubscriptionsList()

                            }
                            
                          
                        }).catch((err) => { console.log("error getUserBalance"); console.log(err); timer.setTimeout("getUserBalance", () => { this.getUserBalance();  }, 2000);   })
 

        })
}

getPromo(){
  var bodyPromo={
        uuid:getAuth(firebase).currentUser.uid,
        version:info.appliVersion
      }


      fetch("https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/testPermanentPromoCodeAndDeferredPayment", {
        body: JSON.stringify(bodyPromo),
        method: "POST"}).then((response) => response.json()).then((responseJson) => {
          var res=responseJson
          if(res.deferredPayment!=undefined && res.deferredPayment==true) this.setState({mode:"deferredPayment",})
        }).catch((err) => { console.log("error getPromo"); console.log(err); timer.setTimeout("getPromo", () => { this.getPromo();  }, 2000);   })
}



componentDidMount() {
  if(getAuth(firebase).currentUser!=undefined && this.state.subscriptionAdmin!=true){
      if(info.paymentCardApp===true && (this.state.params==undefined || this.state.params.addCredit!=true )) this.getUserCards();
      this.getUserBalance()
      this.getPromo();
  } 
  if(this.state.subscriptionAdmin==true) this.getSubscriptionsList()
}


getUserCards(){

  get(child(ref(getDatabase(firebase)), '/users/'+getAuth(firebase).currentUser.uid+"/email")).then(snapshot => { 
      var email=snapshot.val();
      getAuth(firebase).currentUser.getIdToken().then(token => {
              var body={
                  userID:getAuth(firebase).currentUser.uid,
                  bundleIdentifer:info.bundleIdentifer,
                  token:token,
                  bundleAdress:info.firebaseProjectID,
                  email:email,
                  version:info.appliVersion,
              }

        fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getUserCards", {
          body: JSON.stringify(body),
            method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {
                    var res=responseJson
                    var i;
                    for(i=0;i<res.length;i++){
                      
                      res[i].text= ((res[i].brand=="visa")?"Visa":( (res[i].brand=="mastercard")?"Mastercard":"Amex" ))+" *"+res[i].last4;

                      res[i].icon=(res[i].brand.toLowerCase()==="visa")?require("../assets/visa.png"):((res[i].brand.toLowerCase()==="mastercard")?require("../assets/mastercard.png"):((res[i].brand.toLowerCase()==="amex")?require("../assets/amex.png"):require("../assets/paymentCard.png")))
                      res[i].image=(res[i].brand.toLowerCase()==="visa")?require("../assets/visa.png"):((res[i].brand.toLowerCase()==="mastercard")?require("../assets/mastercard.png"):((res[i].brand.toLowerCase()==="amex")?require("../assets/amex.png"):require("../assets/paymentCard.png")))
                      if(i===0) this.setState({cardSelected:res[0]})
                    }
                    if(this.state.params!=undefined && (this.state.params.addCredit==true)){
                      this.state.params.useCard(res[i-1])
                      var params=(this.props.navigation!=undefined)?this.props.navigation:this.props.params;  params.goBack();
                    }
                    this.setState({cardList:res})
              
              
            }).catch((err) => { console.log("error getUserCards"); console.log(err); timer.setTimeout("getUserCards", () => { this.getUserCards();  }, 2000);   })
 

    });

    })
}


  viewCard(){
      var selected=-1;
      var i;
      for(i=0;i<this.state.cardList.length;i++){
          if(this.state.cardList[i].id==this.state.cardSelected.id) selected=i;
      }
      this.alert.onAlert({title:strings.payment, items:this.state.cardList ,itemSelected:selected, buttons:[{text: strings.validate,onPress: (id,value) => {
        if(this.state.cardList[id].id==="addCard") this.setState({viewAddCard:true})
        else { this.setState({cardSelected:this.state.cardList[id]}) }
       
      }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })  

  }

buyRecharge(){
  var value=parseInt(this.state.buyRechargePrice)
  if(value.toString()==="NaN" || value<0) this.alert.onAlert({title:strings.priceBillError}) 
  else {
    this.setState({waiting:true});

    getAuth(firebase).currentUser.getIdToken().then(token => {
    var body={
      userID:getAuth(firebase).currentUser.uid,
      bundleIdentifer:info.bundleIdentifer,
      price:value,
      source:this.state.cardSelected.id,
      token:token,
      bundleAdress:info.firebaseProjectID,
      version:info.appliVersion
    }

    fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/buyRecharge", {
                body: JSON.stringify(body),
                method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {
                
                            var rep=responseJson
                            console.log(rep)
                  if(rep.payment==="ok"){
                    console.log("le paiement est ok")
                    this.setState({waiting:false,mode:"mainCard" })
                     this.alert.onAlert({
                          title:strings.yourRechargeCode,
                          subTitle:strings.rechargeNowOrGive,
                          code:rep.recharge.rechargeID,
                          buttons:[{text: strings.copy,onPress: () => {this.copyToClipboard(rep.recharge.rechargeID)}},{text: strings.close, onPress: () => {}}]

                        });


                    
                  }
                  else if(rep.payment=="requiresAction"){
                      this.setState({waiting:false,waiting3DSecure:true,waiting:false,mode:"mainCard" })

                  } 
                  else {this.alert.onAlert({title:strings.errorDefault}); this.setState({waiting:false})} 
                }).catch((err) => { console.log("error buyRecharge"); console.log(err); timer.setTimeout("buyRecharge", () => { this.buyRecharge();  }, 2000);   })


              })

 

  }

}
buySub(){

   getAuth(firebase).currentUser.getIdToken().then(token => {
       var body={
          userID:getAuth(firebase).currentUser.uid,
          bundleIdentifer:info.bundleIdentifer,
          source:this.state.cardSelected.id,
          token:token,
          type:"createSub", 
          bundleAdress:info.firebaseProjectID,
          version:info.appliVersion,
          subInfo:this.state.subscriptionsList[(this.state.subscriptionActual.id==undefined || this.state.subscriptionActual.id=="")?Object.keys(this.state.subscriptionsList)[0]:this.state.subscriptionActual.id],
        }
        this.setState({waiting:true})

       fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/stripeSubscription",  {body: JSON.stringify(body),method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {
                    console.log(responseJson)
                    this.setState({waiting:false})
        })


        /*si un abonnement est en cours, il faut le résilier */

    })




}


useRecharge(){
  if(this.state.useRechargeCode==="") this.alert.onAlert({title:strings.noCode}) 
  else {
    this.setState({waiting:true});

    getAuth(firebase).currentUser.getIdToken().then(token => {
    var body={
      userID:getAuth(firebase).currentUser.uid,
      bundleIdentifer:info.bundleIdentifer,
      token:token,
      rechargeID:this.state.useRechargeCode,
      bundleAdress:info.firebaseProjectID,
    }

    fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/useRecharge", {
                body: JSON.stringify(body),
                method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {
                
                            var rep=responseJson
                  if(rep.add===true){
                        this.setState({waiting:false,mode:"mainCard",balance:this.state.balance+rep.amount,useRechargeCode:"" })
                        this.alert.onAlert({
                          title:strings.thanks,
                          subTitle:strings.yourAccountHasBeenCreditate+" "+rep.amount+"€",
                        });

                  }
                  else {
                    if(rep.error==="alreadyUse") this.alert.onAlert({title:strings.codeAlreadyUse});
                    else this.alert.onAlert({title:strings.codeNotFound,subTitle:strings.codeNotFoundExplication});
                    this.setState({waiting:false})
                  } 
                }).catch((err) => { console.log("error useRecharge"); console.log(err); timer.setTimeout("useRecharge", () => { this.useRecharge();  }, 2000);   })


              })

 

  }

}

copyToClipboard(code){
  Clipboard.setString(code);

}

createSub(){

 if ((this.state.newSubscriptionInfo.name!=undefined && this.state.newSubscriptionInfo.name=="") || (this.state.newSubscriptionInfo.description!=undefined && this.state.newSubscriptionInfo.description=="") || (this.state.newSubscriptionInfo.km!=undefined && (this.state.newSubscriptionInfo.km=="" || isNaN(parseFloat(this.state.newSubscriptionInfo.km))) ) || (this.state.newSubscriptionInfo.amount!=undefined && (this.state.newSubscriptionInfo.amount=="" || isNaN(parseFloat(this.state.newSubscriptionInfo.amount.replace(this.state.newSubscriptionInfo.money.symbole,"")))) )) this.alert.onAlert({title:strings.thanksAllItems})
 else {
      this.setState({waitingSubcriptions:true})
      const fireInfo={} 
      var subInfo={ 
            name:this.state.newSubscriptionInfo.name,
            description:this.state.newSubscriptionInfo.description,
            type:"km",
            km:parseFloat(this.state.newSubscriptionInfo.km),
            amount:parseFloat(this.state.newSubscriptionInfo.amount.replace(this.state.newSubscriptionInfo.money.symbole,"")),
            moneyID:"eu"
      }
      var id=Date.parse(new Date());




   
      getAuth(firebase).currentUser.getIdToken().then(token => {
         var body={
            userID:getAuth(firebase).currentUser.uid, 
            token:token,
            name:this.state.newSubscriptionInfo.name,
            description:this.state.newSubscriptionInfo.description,
            subID:id, 
            amount:subInfo.amount,
            currency:"eur"
          }



          fetch("https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/stripeConnect/createSub/", {
          body: JSON.stringify(body),
          headers: {"Content-Type": "application/json"},method: "POST"}).then((response) => response.json()).then((responseJson) => {
            console.log(responseJson)
            this.setState({waitingSubcriptions:false})

            if(responseJson.error!=true){
                subInfo.productID=responseJson.productID
                subInfo.subID=responseJson.subID
                fireInfo['/subscriptionsList/'+id]=subInfo
                update(ref(getDatabase(firebase)), fireInfo)
                var subscriptionsList=JSON.parse(JSON.stringify(this.state.subscriptionsList))
                subscriptionsList[id]=subInfo;
                subscriptionsList[id].money=this.findMoney(subInfo.moneyID)

                this.setState({newSubscription:false,subscriptionsList:subscriptionsList})
            } 
          

          })
      })
     

  }
}


hideEnableSub(rowData){
  const fireInfo={} 
  fireInfo['/subscriptionsList/'+rowData+"/offline"]=(this.state.subscriptionsList[rowData].offline==true)?null:true;
  update(ref(getDatabase(firebase)), fireInfo)

  var subscriptionsList=JSON.parse(JSON.stringify(this.state.subscriptionsList));
  subscriptionsList[rowData].offline=(this.state.subscriptionsList[rowData].offline==true)?undefined:true;
  this.setState({subscriptionsList:subscriptionsList})
   
}


getSubscriptionsList(){
    this.setState({waitingSubcriptions:true})
    get(child(ref(getDatabase(firebase)), '/subscriptionsList')).then(value => {
        var val=value.val();
        this.setState({waitingSubcriptions:false})
        if(val!=null){
          var i; 
          var valArray=Object.keys(val)
          var newVal={}
          for(i=0; i<valArray.length;i++){
            val[valArray[i]].money=this.findMoney(val[valArray[i]].moneyID)
            if(this.state.subscriptionAdmin==true || val[valArray[i]].offline!=true) newVal[valArray[i]]=val[valArray[i]]
          }
        
          this.setState({subscriptionsList:newVal});

        }
      })


}




render() {
    return (
      <StyleContext.Consumer>{style => 
        <View style={{flex:1}}>
        {(Platform.OS=="web") && <MavNavigationMinimize clickLeft={() => { this.state.params.goBack();  }} left={strings.return} title={ strings.payment } />}

        {this.state.subscriptionAdmin==true && <ScrollView style={{flex:1,backgroundColor:style.backgroundColor}}>

              {this.state.waitingSubcriptions==true && <ActivityIndicator style={{marginTop:30}} size="large" color={style.color1} />}


              {(Object.keys(this.state.subscriptionsList).length==0 && this.state.newSubscription!=true && this.state.waitingSubcriptions!=true) && <View style={{width:"100%",alignItems:"center"}}> 
                    <Image source={(style.mode=="dark")?require('../assets/driver_cancel_light.png'):require('../assets/driver_cancel_dark.png')} style={{width:100,height:100,marginTop:20 }}/>
                        <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*2,textAlign: "center",}}>{strings.noSubscription}</Text>
                        <Text style={{marginTop:10,marginBottom:50,marginLeft:"10%", marginRight:"10%", color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.createSubNow}</Text>
                    

                </View>}

                {(Object.keys(this.state.subscriptionsList).length>0 && this.state.newSubscription!=true) && <View style={{}}> 
                      {Object.keys(this.state.subscriptionsList).map((rowData,i)=>( 
                          <TextPresentation  icon={(style.mode=="dark")?require('../assets/subscription_white.png'):require('../assets/subscription_black.png')} style={{marginTop:10}} disableViewEdit={true} editable={false} text={this.state.subscriptionsList[rowData].name} textExplication={(this.state.subscriptionsList[rowData].offline==true)?strings.hidenSub:strings.kmSub} >
                              <TextPresentation style={{marginTop:5}} text={ this.state.subscriptionsList[rowData].name}  textExplication={strings.lastName} editable={false} />
                              <TextPresentation style={{marginTop:5}} text={ this.state.subscriptionsList[rowData].description}  textExplication={strings.description} editable={false} />
                              <TextPresentation style={{marginTop:5}} text={ this.state.subscriptionsList[rowData].km+"km"}  textExplication={strings.km+" "+strings.perMonth} editable={false} />
                              <TextPresentation style={{marginTop:5}} text={ ((this.state.subscriptionsList[rowData].money.position=="left")?(this.state.subscriptionsList[rowData].money.symbole+" "):"")+this.state.subscriptionsList[rowData].amount+((this.state.subscriptionsList[rowData].money.position=="right")?this.state.subscriptionsList[rowData].money.symbole:"")}  textExplication={strings.amount+" "+strings.perMonth}  editable={false} />

                              <TextPresentation onTextExplication={()=>{this.hideEnableSub(rowData) }} onEditClick={()=>{this.hideEnableSub(rowData) }} iconCircle={true} textExplicationSmall={(this.state.subscriptionsList[rowData].offline==true)?strings.enableSubMoreInfo:strings.hideSubMoreInfo} textExplication={(this.state.subscriptionsList[rowData].offline==true)?strings.enableSub:strings.hideSub} icon={(this.state.subscriptionsList[rowData].offline==true)?((style.mode=="dark")?require('../assets/show_black.png'):require('../assets/show_white.png')):((style.mode=="dark")?require('../assets/hidden_black.png'):require('../assets/hidden_white.png'))} disabledText={true}/>


                          </TextPresentation>

                      ))}

                </View>}




                 {(this.state.newSubscription!=true &&this.state.waitingSubcriptions!=true) && <MavButton text={strings.createSub.toUpperCase()} style={{marginTop:15,marginBottom:15}} onPress={()=>{ this.setState({newSubscription:true, newSubscriptionInfo:{money:this.findMoney("eu")} }) }}/>}

                 {(this.state.newSubscription==true && this.state.waitingSubcriptions!=true) && <TextPresentation defautVisible={true} icon={(style.mode=="dark")?require('../assets/subscription_white.png'):require('../assets/subscription_black.png')} style={{marginTop:10}} disableViewEdit={true} editable={false} text={(this.state.newSubscriptionInfo!=undefined && this.state.newSubscriptionInfo.name!=undefined && this.state.newSubscriptionInfo.name!="")?this.state.newSubscriptionInfo.name:strings.noNameSub} textExplication={strings.newSubscription} >
                    
                    <TextPresentation style={{marginTop:5}} enableValidate={(this.state.newSubscriptionInfo.name!=undefined && this.state.newSubscriptionInfo.name!="")} enableError={(this.state.newSubscriptionInfo.name!=undefined && this.state.newSubscriptionInfo.name=="")} text={(this.state.newSubscriptionInfo.name!=undefined)?this.state.newSubscriptionInfo.name:""}  textExplication={strings.lastName} placeholder={"Happy"} onChangeText={(text)=>{var sub=this.state.newSubscriptionInfo; sub.name=text; this.setState({newSubscriptionInfo: sub })}}/>
                    <TextPresentation style={{marginTop:5}} enableValidate={(this.state.newSubscriptionInfo.description!=undefined && this.state.newSubscriptionInfo.description!="")} enableError={(this.state.newSubscriptionInfo.description!=undefined && this.state.newSubscriptionInfo.description=="")}  text={(this.state.newSubscriptionInfo.description!=undefined)?this.state.newSubscriptionInfo.description:""} multiline={true} textExplication={strings.description} placeholder={"Exemple"} onChangeText={(text)=>{var sub=this.state.newSubscriptionInfo; sub.description=text; this.setState({newSubscriptionInfo: sub })}}/>
                    <TextPresentation style={{marginTop:5}} enableValidate={(this.state.newSubscriptionInfo.km!=undefined && this.state.newSubscriptionInfo.km!="" && !isNaN(parseFloat(this.state.newSubscriptionInfo.km) ) )} enableError={(this.state.newSubscriptionInfo.km!=undefined && (this.state.newSubscriptionInfo.km=="" || isNaN(parseFloat(this.state.newSubscriptionInfo.km))) )}  text={((this.state.newSubscriptionInfo.km!=undefined)?this.state.newSubscriptionInfo.km:"")}  textExplication={strings.km+" "+strings.perMonth} placeholder={"100km"} onChangeText={(text)=>{var sub=this.state.newSubscriptionInfo;text=text.toString().replace(",",'.'); text=text.toString().replace("km",''); text=text.toString().replace("k",'');  text=text.toString().replace("m",''); sub.km=text; this.setState({newSubscriptionInfo: sub }); timer.setTimeout("changeKm", () => { var sub=this.state.newSubscriptionInfo;text=sub.km; text=text.toString().replace("km",''); text=text.toString().replace("k",'');  text=text.toString().replace("m",''); text+="km"; if(text!="") sub.km=text; this.setState({newSubscriptionInfo: sub });  }, 2500); }}/>
                    <TextPresentation style={{marginTop:5}} enableValidate={(this.state.newSubscriptionInfo.amount!=undefined && this.state.newSubscriptionInfo.amount!="" && !isNaN(parseFloat(this.state.newSubscriptionInfo.amount.replace(this.state.newSubscriptionInfo.money.symbole,"")) ) )} enableError={(this.state.newSubscriptionInfo.amount!=undefined && (this.state.newSubscriptionInfo.amount=="" || isNaN(parseFloat(this.state.newSubscriptionInfo.amount.replace(this.state.newSubscriptionInfo.money.symbole,"")))) )} text={(this.state.newSubscriptionInfo.amount!=undefined)?this.state.newSubscriptionInfo.amount:""}  textExplication={strings.amount+" "+strings.perMonth} placeholder={"20€"} onChangeText={(text)=>{var sub=this.state.newSubscriptionInfo;text=text.toString().replace(",",'.'); text=text.toString().replace(this.state.newSubscriptionInfo.money.symbole,''); sub.amount=text; this.setState({newSubscriptionInfo: sub }); timer.setTimeout("changeAmount", () => { var sub=this.state.newSubscriptionInfo;text=sub.amount; text=text.toString().replace(this.state.newSubscriptionInfo.money.symbole,''); text=((this.state.newSubscriptionInfo.money.position=="left")?(this.state.newSubscriptionInfo.money.symbole+" "):"")+text+((this.state.newSubscriptionInfo.money.position=="right")?(this.state.newSubscriptionInfo.money.symbole):""); if(text!="") sub.amount=text; this.setState({newSubscriptionInfo: sub });  }, 2500); }}/>

                    <TextPresentation style={{marginTop:5}} text={""} textExplication={strings.overview} disableText={true} />
                    <View style={{backgroundColor:style.backgroundColor, borderRadius:10, borderColor:style.color1, borderWidth:1, marginLeft:'5%', marginRight:'5%',marginTop:-20}}>
                      <TextPresentation editable={false} text={(this.state.newSubscriptionInfo.name!=undefined)?this.state.newSubscriptionInfo.name:"{"+strings.lastName+"}"} textMini={(this.state.newSubscriptionInfo.description!=undefined)?this.state.newSubscriptionInfo.description:"{"+strings.description+"}"}  textRightMini={strings.perMonth} textRight={(this.state.newSubscriptionInfo.amount!=undefined)?this.state.newSubscriptionInfo.amount:"{"+strings.amount+"}"}  editable={false}/>
                    </View>


                    <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:20}}>
                        <MavButton text={strings.validate}  onlyButton={true} onPress={()=>{ this.createSub() }}/>
                        <MavButton text={strings.cancel} style={{marginTop:5}} strokeMode={true} small={true} onlyButton={true} onPress={()=>{ this.setState({newSubscription:false, }) }}/>
                    </View>


                 </TextPresentation>}



        </ScrollView>}


        {this.state.subscriptionAdmin!=true && <ScrollView style={{flex:1,backgroundColor:style.backgroundColor}}>
          
          {false && ((this.state.waiting3DSecure==true && this.state.params!=undefined && (this.state.params.waiting3DSecureStatus!="validate" || this.state.params.waiting3DSecureStatus!="fail")) || this.state.params.waiting3DSecure==true) && <Wait3DSecure status={this.state.params.waiting3DSecureStatus} />}


          {(this.state.waiting==true && this.state.mode==="mainCard") && <ActivityIndicator style={{marginTop:30}} size="large" color={style.color1} />}


          {(this.state.mode==="classic") && <View>
              <Text style={{textAlign: 'center', color: style.textInputColor,fontSize: style.textInputFontSize, marginTop:Dimensions.get('window').height * 0.07 ,marginLeft:"10%", marginRight:"10%" }}>{strings.paiementExplication}</Text>

              { info.paymentList.map((rowData,i)=>( <View style={{marginTop:Dimensions.get('window').height * 0.07,justifyContent: 'center',alignItems: 'center',}}>
                        <Image source={this.state.requireList[i]} style={{height:Dimensions.get('window').height*0.07,width:Dimensions.get('window').height*0.07,resizeMode:"contain"}}/>
                        <Text style={{fontSize: 17,color: style.textInputColor, }}>{strings[rowData]}</Text>
                    </View>
                  ))}

              <MavButton text={strings.ImUnderstand.toUpperCase()} style={{marginTop:50,marginBottom:15}} onPress={()=>{var params=(this.props.navigation!=undefined)?this.props.navigation:this.props.params;  params.goBack(); }}/>

          </View>}

          {(this.state.mode==="deferredPayment") && <View>

              <Text style={{textAlign: 'center', fontSize: style.textInputFontSize, marginTop:20 ,marginLeft:"10%",marginRight:"10%",color: style.textInputColor,}}>{strings.deferredPaymentEnabled}</Text>

              <View style={{alignItems:"center",justifyContent:"center", marginTop:50,marginBottom:15}}> 
                  <MavButton text={strings.ImUnderstand.toUpperCase()} onlyButton={true} onPress={()=>{var params=(this.props.navigation!=undefined)?this.props.navigation:this.props.params;  params.goBack(); }}/>
              </View>

          </View>}


          {(this.state.mode==="mainCard" && this.state.waiting!=true) && <View>



          <TextPresentation style={{marginTop:10}} disableViewEdit={true} editable={false} text={(this.state.cardList.length===0)?strings.youHaveNotCard:((this.state.cardList.length===1)?("1 "+strings.paymentCard.toLowerCase()):(this.state.cardList.length+" "+strings.paymentCards.toLowerCase()))} textExplication={strings.yourCards} />

                {this.state.cardList.map(card => (
                    <TextPresentation style={{marginTop:5, marginLeft:'5%', marginRight:'5%'}}  text={card.text} textMini={strings.cardExpiration+" : "+((card.exp_month<10)?"0":"")+card.exp_month+"/"+card.exp_year} enableRemove={true} editable={false} onRemove={()=>{this.removeCard(card.id)}} icon={card.icon}  /> 
                ))}
            

              <View style={{marginTop:10}}> 
                <AddCreditCard newCardAdded={() => { this.getUserCards() }} />
              </View>





            {( this.state.balance>0 || this.state.extraEUMode==false || this.state.balanceOther.length==0) && <TextPresentation style={{marginTop:20}} editable={false} text={(this.state.balance===0)?strings.yourHaveNotCredit:this.state.balance+"€"} textExplication={strings.yourCredit} />}
            

            {this.state.balanceOther.map(list => (
                <View>
                  {list.amount>0 && <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize,textAlign: "center"}}>{strings.creditYouHave+" : "+((list.position=="left")?(list.symbole+" "):"")+list.amount+((list.position=="right")?(list.symbole):"")}</Text>}
                </View>
                ))}



            {this.state.extraEUMode==false && <TextPresentation disableViewEdit={true} style={{marginTop:5}} text={strings.useRecharge} editable={false} icon={require("../assets/use_recharge.png")} explicationChild={strings.howUseCode} >
                <TextPresentation style={{marginTop:10}} editable={!this.state.waiting} text={this.state.useRechargeCode} textExplication={strings.code} placeholder={"1234abcd54"} onChangeText={text => {this.setState({useRechargeCode:text})}}/>
                <View style={{alignItems:"center",justifyContent:"center", marginTop:20, marginBottom:15}}>
                    <MavButton text={strings.validate.toUpperCase()} onlyButton={true} onPress={()=>{this.useRecharge()}}/>
                </View>
            </TextPresentation>}


            {(this.state.cardList.length>0 && this.state.cardList.length>0 && this.state.extraEUMode==false) && <TextPresentation disableViewEdit={true} style={{marginTop:5}} text={strings.buyRecharge} editable={false} icon={require("../assets/buy_recharge.png")} explicationChild={strings.whyBuyRecharge} >

                <TextPresentation style={{marginTop:15}} editable={!this.state.waiting} text={this.state.buyRechargePrice} textExplication={strings.montant} placeholder={"100€"} keyboardType={"numeric"} 

                  elementRight={
                                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.viewCard() }} >
                                            <View style={{flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                                                <Image source={this.state.cardSelected.icon} style={{height:25,width:25,resizeMode:"contain", marginTop:-3, marginRight:6}}/>
                                                <Text style={{marginLeft:-2,color: style.textInputColor,fontSize: style.textInputFontSize, textAlign: "right",marginTop:-2}}>{"*"+this.state.cardSelected.last4}</Text>
                                            </View>
                                          </TouchableHighlight>

                                        }

                    onChangeText={text => {
                             this.setState({buyRechargePrice:text.toString().replace("€",'')})
                             timer.setTimeout("timer", () => {
                              var test=this.state.buyRechargePrice.indexOf("€");
                                if(test==-1 && this.state.buyRechargePrice!="") {
                                  this.setState({buyRechargePrice:this.state.buyRechargePrice+"€"})
                                }
                             }, 1000);
                            }}/>
                
                <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:15}}> 
                    <MavButton text={strings.validate} onlyButton={true} onPress={()=>{this.buyRecharge()}}/>
                </View>
            

            </TextPresentation>}






            
              





              {(this.state.subscriptionsList!=undefined && Object.keys(this.state.subscriptionsList).length>0) && <TextPresentation style={{marginTop:20}} editable={false} text={(this.state.subscriptionOld.id=="")?strings.noSubscription:( (this.state.subscriptionsList[this.state.subscriptionOld.id]!=undefined)?this.state.subscriptionsList[this.state.subscriptionOld.id].name:strings.oldSub ) } textExplication={strings.subscription} />}


              {(this.state.subscriptionsList!=undefined && Object.keys(this.state.subscriptionsList).length>0 && this.state.cardSelected!=undefined) && <TextPresentation disableViewEdit={true} style={{marginTop:5}} text={strings.chooseSub} editable={false} icon={require("../assets/subscription.png")} explicationChild={strings.chooseSubMoreInfo} >
                    
                    {Object.keys(this.state.subscriptionsList).map((rowData,i)=>( 
                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.setState({subscriptionActual:{id:rowData} }) }}  style={{ backgroundColor:style.backgroundColor, borderRadius:10,marginTop:5, borderColor:(this.state.subscriptionActual.id==rowData || (this.state.subscriptionActual.id=="" && i==0))?style.color1:style.backgroundColor, borderWidth:1, marginLeft:'5%', marginRight:'5%',}}>
                              <TextPresentation editable={false} text={this.state.subscriptionsList[rowData].name} textMini={this.state.subscriptionsList[rowData].description} textRightMini={strings.perMonth} textRight={((this.state.subscriptionsList[rowData].money.position=="left")?(this.state.subscriptionsList[rowData].money.symbole+" "):"")+this.state.subscriptionsList[rowData].amount+((this.state.subscriptionsList[rowData].money.position=="right")?this.state.subscriptionsList[rowData].money.symbole:"")} />
                          </TouchableHighlight>
                    ))}


                   <TextPresentation  viewEdit={true} onEditClick={() => {this.viewCard();}} style={{marginTop:5}} icon={(this.state.cardSelected!=undefined)?this.state.cardSelected.icon:undefined} editable={false} text={(this.state.cardSelected.load===true)?"": (this.state.cardSelected.withBancontact===true)? strings.bancontact   : ((this.state.cardSelected.withCredit===true)?strings.withCredit:(this.state.cardSelected.onBoard===true)?strings.onBoard:this.state.cardSelected.last4)} textExplication={strings.paymentMode}  />
                    

                    <View style={{alignItems:"center",justifyContent:"center", marginTop:20, marginBottom:15}}>
                        <MavButton text={strings.validate.toUpperCase()} onlyButton={true} onPress={()=>{this.buySub() }}/>
                    </View>
                </TextPresentation>}


              <View style={{width:50, height:50}} />



          </View>}



          


          




          


         




          <MavAlert ref={alert => {this.alert = alert;}}/>

          
        </ScrollView>}

        </View>
        }</StyleContext.Consumer>

    );
  }
}

export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}




