import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  Dimensions,
  Clipboard,
  Platform,
  Image,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import firebase from './firebase';
import { getAuth,  } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

import MavButton from './mavButton';
import MavInfoBubble from './mavInfoBubble';
import MavAlert from './mavAlert';
import moneyList from '../moneyList';
import MavNavigationMinimize from './mavNavigationMinimize';
import TextPresentation from './textPresentation';





export default class help extends Component {
  constructor(props) {
    super(props);

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    console.log(params)


    this.state = {
      classic: [],
      classicTaxi:[],
      forfait: [],
      forfaitTaxi:[],
      promoCode:[],
      carListName:{},
      viewAdmin:(params!=undefined && params.viewAdmin===true)?true:false,
      driverID:(params!=undefined && params.driverID!=undefined)?params.driverID:"",
      customerPricing:(params!=undefined && params.customerPricing===true)?true:false,
      customerID:(params!=undefined && params.customerID!=undefined)?params.customerID:"",
      taxiMode:(params!=undefined && params.taxiMode===true)?true:false, 
      driverPricing:(params!=undefined && params.driverPricing===true)?true:false,
      luggageOption:(params!=undefined && params.luggageOption===true)?true:false,
      vipMode:(params!=undefined && params.vipMode===true)?true:false,
      extraEUMode:(params!=undefined && params.extraEUMode===true)?true:false,
      defineMinimalPassager:(params!=undefined && params.defineMinimalPassager===true)?true:false,
      co2onBill:(params!=undefined && params.co2onBill===true)?true:false,
      providerFixeCommission:(params!=undefined && params.providerFixeCommission===true)?true:false,
      viewPricing:(params!=undefined && params.view=="onlyPromoCode")?false:true,
      viewPromoCode:(params!=undefined && params.view=="onlyPrice")?false:true, 
      hideHoursMajoration:(params!=undefined && params.hideHoursMajoration!=undefined)?params.hideHoursMajoration:false,
      getMissingInfo:(params!=undefined && params.getMissingInfo!=undefined)?params.getMissingInfo:false,
    };
  }

  componentDidMount() {
  

    if(Platform.OS!="web"){
        this.props.navigation.setOptions({ title:(this.state.viewPromoCode==true)?strings.promoCode:strings.pricingGestion})
    }
   





    if(this.state.viewPromoCode==true) this.updateCode();

    if(this.state.driverPricing==true || this.state.customerPricing==true) {this.updatePersonalData();this.getAllCar()}
    else if(this.state.viewPricing==true) this.updateData();
  }

  updateHideHoursMajoration= value => {
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    if(params!=undefined && params.updateHideHoursMajoration!=undefined) params.updateHideHoursMajoration(value);
  }

  updateCode = data => {
    get(child(ref(getDatabase(firebase)), '/promoCode')).then(snapshot => { 
         var val=snapshot.val();
         var promoCode=[];
         if(val!=null){
          
          var list=Object.keys(val);
          var i,j;


          for(i=0;i<list.length;i++){
            var users=[];


            if(val[list[i]].users!=undefined){
              var uL=Object.keys(val[list[i]].users);
              var r;
              
              for(j=0;j<uL.length;j++){
                r=Object.keys(val[list[i]].users[uL[j]]);
                users.push(val[list[i]].users[uL[j]][r[0]])
              }
              
            }


            promoCode[i]={
              codeName:val[list[i]].codeName,
              codeReduc:val[list[i]].codeReduc,
              dateStart:val[list[i]].dateStart,
              dateEnd:val[list[i]].dateEnd,
              id:list[i],
              users:users
            }
            if(val[list[i]].poiSelected!=undefined) promoCode[i].poiSelected=val[list[i]].poiSelected
            if(val[list[i]].usersMax!=undefined) promoCode[i].usersMax=val[list[i]].usersMax
            if(val[list[i]].validMoreUser!=undefined) promoCode[i].validMoreUser=val[list[i]].validMoreUser
            if(val[list[i]].takeCost!=undefined) promoCode[i].takeCost=val[list[i]].takeCost
            if(val[list[i]].onlyProvision!=undefined) promoCode[i].onlyProvision=val[list[i]].onlyProvision
            if(val[list[i]].amount==true){

                 promoCode[i].amount=true; 
                 promoCode[i].codeFrom=(val[list[i]].codeFrom!=undefined)?val[list[i]].codeFrom:""; 
                 promoCode[i].moneyID=(val[list[i]].moneyID!=undefined)?val[list[i]].moneyID:""; 
                 promoCode[i].money=this.findMoney(val[list[i]].moneyID);

                 


            }
              
          
          


         }
        
         this.setState({promoCode:promoCode})
            }
     })
  } 


  updatePersonalData = data => {
    
      var id=(this.state.customerPricing==true)?this.state.customerID: (   (this.state.viewAdmin==true)?this.state.driverID:getAuth(firebase).currentUser.uid )
      if(this.state.customerPricing==true) this.updateData(data, ("/pricingCustomer/"+id) )
      else this.updateData(data, ("/pricingDriver/"+id) )
  }


  updateCustomerData = data => {

      var id=this.state.customerID;
      this.updateData(data, ("/pricingCustomer/"+id) )
  }


  




  findMoney(value){
    if(value==undefined) value="eu";
    var final="";
    var i; 
    for(i=0;i<moneyList.length;i++){
      if(final==""){
        if(moneyList[i].id==value) final=moneyList[i];
      }
    }
    return final
  }



  sortData = (val,mode) => {

          var listForfait = new Array();
          var listClassic = new Array();
          var listForfaitRad = new Array();
          var listClassicRad = new Array();

          var i, j;
          var test;

          var tempList = Object.keys(val);

          for (i = 0; i < tempList.length; i++) {
            test = false;
            if (val[tempList[i]].radius != undefined) {
              for (j = 0; j < listClassicRad.length; j++) {
                if (test == false && val[tempList[i]].radius < listClassicRad[j]) {
                  test = true;
                  listClassicRad.splice(j, 0, val[tempList[i]].radius);
                  listClassic.splice(j, 0, tempList[i]);
                }
              }

              if (test == false) {
                listClassicRad.push(val[tempList[i]].radius);
                listClassic.push(tempList[i]);
              }
            } else {
              for (j = 0; j < listForfaitRad.length; j++) {
                if (
                  test == false &&
                  val[tempList[i]].startRadius < listForfaitRad[j]
                ) {
                  test = true;
                  listForfaitRad.splice(j, 0, val[tempList[i]].startRadius);
                  listForfait.splice(j, 0, tempList[i]);
                }
              }

              if (test == false) {
                listForfaitRad.push(val[tempList[i]].startRadius);
                listForfait.push(tempList[i]);
              }
            }
          }



          var listForfait2 = new Array();
          var listClassic2 = new Array();

          for (i = 0; i < listForfait.length; i++) {
            listForfait2[i] = val[listForfait[i]];
            listForfait2[i].i = i;
            listForfait2[i].id = listForfait[i];
          }

          for (i = 0; i < listClassic.length; i++) {
            listClassic2[i] = val[listClassic[i]];
            listClassic2[i].i = i;
            listClassic2[i].id = listClassic[i];
          }
          if(mode=="vtc") this.setState({ classic: listClassic2, forfait: listForfait2 });
          else this.setState({ classicTaxi: listClassic2, forfaitTaxi: listForfait2 });
          

  }

  navigateTo= (link,params) => {
   
    if(this.props.navigation!=undefined) this.props.navigation.navigate(link, params);
    else this.props.params.navigate(link, params);
  }




  updateData = (data,reference) => {

    if(reference==undefined) reference="/pricing"; 
    get(child(ref(getDatabase(firebase)), reference)).then(snapshot => { 
      
      var val=snapshot.val();

      if(val!=null){ 

        var tempList = Object.keys(val);
        var vtc={}
        var taxi={};
        var i; 
        for (i = 0; i < tempList.length; i++) {
          if(val[tempList[i]].taxiMode==true) taxi[tempList[i]]=val[tempList[i]];
          else vtc[tempList[i]]=val[tempList[i]];
        }
        if(Object.keys(vtc).length>0) this.sortData(vtc,"vtc");
        if(this.state.taxiMode==true && Object.keys(taxi).length>0 ) this.sortData(taxi,"taxi");

      }
      else {
        this.setState({ classic: new Array(), forfait: new Array(),classicTaxi: new Array(), forfaitTaxi: new Array() });

      }
  })};



    addPlace(){
      get(child(ref(getDatabase(firebase)), "users/" + getAuth(firebase).currentUser.uid+"/lastCopiedPrice")).then(value => {
            var clip=value.val();
            var messageCopy=(this.state.classic.length>0 || this.state.forfait.length>0)?true:false; 

            if(this.state.viewPromoCode==true && this.state.viewPricing==false) this.navigateTo('PromoCode', {extraEUMode:this.state.extraEUMode,updateCode: this.updateCode})
            else {
              var buttonList=[
                {
                  text: strings.zone,
                  onPress: () => {this.navigateTo('PricePlaceSettings', {extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission, defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption,carListName:this.state.carListName, updateData: this.updateData,updatePersonalData:this.updatePersonalData,customerPricing:this.state.customerPricing,customerID:this.state.customerID,driverPricing:this.state.driverPricing,});},
                },
                { 
                  text: strings.forfait,
                  onPress: () => {this.navigateTo('PricePlaceSettings', {extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission, defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption, carListName:this.state.carListName, updateData: this.updateData,updatePersonalData:this.updatePersonalData,type:"forfait",customerPricing:this.state.customerPricing,customerID:this.state.customerID,driverPricing:this.state.driverPricing,}); },
                }
                ]

                if(this.state.taxiMode==true){
                    buttonList.push({text: strings.zoneTaxi, onPress: () => {this.navigateTo('PricePlaceSettings', {extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission, defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption,taxiMode:true, updateData: this.updateData,updatePersonalData:this.updatePersonalData,customerPricing:this.state.customerPricing,driverPricing:this.state.driverPricing,});}   })
                    buttonList.push({text: strings.forfaitTaxi, onPress: () => {this.navigateTo('PricePlaceSettings', {extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission,defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption,taxiMode:true,type:"forfait", updateData: this.updateData,updatePersonalData:this.updatePersonalData,customerPricing:this.state.customerPricing,driverPricing:this.state.driverPricing,});}   })

                }

                if(clip!=undefined){
                   if((clip.type=="forfait" || clip.type=="classic") && (clip.taxiMode!=true || (this.state.driverPricing!=true && this.state.customerPricing!=true) ) ) {
                      buttonList.push({text: strings.createFromClipboard,onPress: () => {this.navigateTo('PricePlaceSettings', {data: clip,extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission, defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption,carListName:this.state.carListName, updateData: this.updateData,updatePersonalData:this.updatePersonalData,customerPricing:this.state.customerPricing,customerID:this.state.customerID,driverPricing:this.state.driverPricing,});} })
                      messageCopy=false
                    }

                }

                buttonList.push({text: strings.cancel,style:"cancel",onPress: () => {},})

              this.alert.onAlert({title:strings.addTarif,help:{title:strings.createZoneForfait, subTitle:strings.createZoneForfaitMoreInfo+((this.state.taxiMode==true)?(". "+strings.createZoneForfaitMoreInfoTaxi ):"")}, subTitle:(messageCopy==true)?strings.saveTimeCopyForfaitZone:undefined, buttons:buttonList, largeButton:true});

            }

           }) 

          }


    getAllCar(){
      get(child(ref(getDatabase(firebase)), '/pricing/')).then(value => {
        var val1=value.val();
        var val1array=Object.keys(val1)
        get(child(ref(getDatabase(firebase)), '/pricingProvision')).then(value2 => {
          var val2=value2.val();
          var carList={};
          var i,j,pr,pra;
          if(val1!=null){
            for(i=0;i<val1array.length;i++){
                pr=val1[val1array[i]].price;
                pra=Object.keys(pr)
                if(val1[val1array[i]].taxiMode!=true) for(j=0;j<pra.length;j++) carList[pr[pra[j]].name]=true;
            }

          }
          if(val2!=null){
          var val2array=Object.keys(val2)
          for(i=0;i<val2array.length;i++){
            if(val2array[i]=="typeZone"){
                  var k,l;
                  var tttot=val2[val2array[i]]; 
                  for(k=0;k<tttot.length;k++){
                      var listTttot=Object.keys(tttot[k].price)
                      for(l=0;l<listTttot.length;l++){
                          carList[tttot[k].price[listTttot[l]].name]=true
                      }
                  }
              }
              else{
                  carList[val2[val2array[i]].name]=true;
              }
            }  
        }
          var allCar=this.state.carListName
          if(allCar!={}){
            var cla=Object.keys(this.state.carListName);
            for(i=0;i<cla.length;i++){
              if(carList[cla[i]]!=undefined) carList[cla[i]]=allCar[cla[i]]
            }
          }

          this.setState({carListName:carList})

         }) 

       }) 

  }

  render() {
    return (
      <StyleContext.Consumer>{style => 
      <View style={{ backgroundColor: style.backgroundColor, flex: 1}}>

        <ScrollView style={{ flex: 1 }}>


          {(Platform.OS=="web" && (this.state.driverPricing==true || this.state.customerPricing==true)) && <MavNavigationMinimize clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={ strings.price } />}




          {(this.state.viewPricing==true) && <MavInfoBubble type={"menu"} icon={(style.mode=="dark")?require('../assets/route_light.png'):require('../assets/route_dark.png')} title={strings.yourForfaitZone} subTitle={(this.state.driverPricing==true || this.state.customerPricing==true)?undefined:strings.yourForfaitZoneMoreInfo}  style={{marginTop:10,marginBottom:20}} />}
          {(this.state.viewPricing==true && false) && <MavInfoBubble type={"help"} videoLink={"http://www.google.fr"} tutoLink={"http://www.google.fr"} others={[{title:strings.customTarifCustomer, link:"ddedede"}]}  style={{marginTop:0,marginBottom:20}} />}
            

          {this.state.viewPricing==true && <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",marginBottom:10}}>{strings.pricingForfait}</Text>}

          {(this.state.viewPricing==true && this.state.forfait.length == 0) && <MavInfoBubble type={"info"} miniLine={true} title={strings.noForfait} subTitle={(this.state.viewAdmin!=true)?strings.clickOnAddForfait:undefined} style={{marginTop:0,}} />} 

          {this.state.forfait.map((rowData) => (
                 <TextPresentation textExplication={((rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name).length>43)?((rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name).slice(0,40)+"..."):(rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name)} style={{marginTop:5}} editable={false} onNext={value => {this.navigateTo('PricePlaceSettings', {data: rowData,updateData: this.updateData,updatePersonalData:this.updatePersonalData,extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission,defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption,driverPricing:this.state.driverPricing,customerPricing:this.state.customerPricing,carListName:this.state.carListName, disabledChange:this.state.viewAdmin, viewSpecificID:this.state.viewAdmin,driverID:this.state.driverID,customerID:this.state.customerID, }); }} icon={(rowData.taxiMode==true) ? ((style.mode=="dark")?require('../assets/taxi_white.png'):require('../assets/taxi_black.png')):     ((style.mode=="dark")?require('../assets/favorite/pin_white.png'):require('../assets/favorite/pin_black.png'))} textMini={(((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName)).length>63)?(((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName)).slice(0, 60)+" ...") :((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName))}    />
            ))}  

          {this.state.taxiMode==true && <Text style={{marginTop:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",marginBottom:10}}>{strings.pricingForfait+" "+strings.taxi.toLowerCase()}</Text>}
          {(this.state.taxiMode==true && this.state.forfaitTaxi.length == 0) && <MavInfoBubble type={"info"} miniLine={true} title={strings.noForfait} subTitle={(this.state.viewAdmin!=true)?strings.clickOnAddForfait:undefined} style={{marginTop:0,}} />} 

          {this.state.forfaitTaxi.map((rowData) => (
                 <TextPresentation textExplication={((rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name).length>43)?((rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name).slice(0,40)+"..."):(rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name)} style={{marginTop:5}} editable={false} onNext={value => {this.navigateTo('PricePlaceSettings', {data: rowData,updateData: this.updateData,updatePersonalData:this.updatePersonalData,extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission,defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption,driverPricing:this.state.driverPricing,customerPricing:this.state.customerPricing,carListName:this.state.carListName, disabledChange:this.state.viewAdmin, viewSpecificID:this.state.viewAdmin,driverID:this.state.driverID,customerID:this.state.customerID, }); }} icon={(rowData.taxiMode==true) ? ((style.mode=="dark")?require('../assets/taxi_white.png'):require('../assets/taxi_black.png')):     ((style.mode=="dark")?require('../assets/favorite/pin_white.png'):require('../assets/favorite/pin_black.png'))} textMini={(((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName)).length>63)?(((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName)).slice(0, 60)+" ...") :((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName))}    />
          ))} 



          {this.state.viewPricing==true && <Text style={{marginTop:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",marginBottom:10}}>{strings.pricingClassic}</Text>}
          {(this.state.viewPricing==true && this.state.classic.length == 0) && <MavInfoBubble type={(this.state.viewAdmin==true || this.state.driverPricing==true || this.state.customerPricing==true)?"info":"warning"} miniLine={(this.state.viewAdmin!=true && this.state.driverPricing!=true && this.state.customerPricing!=true)?false:true} title={strings.noZone} subTitle={(this.state.viewAdmin!=true)?(strings.clickOnAddZone+((this.state.driverPricing==true || this.state.customerPricing==true)?"": (". "+strings.clickOnAddZoneMore) )     ):undefined} style={{marginTop:0,}} />} 

          {this.state.classic.map((rowData) => (
                 <TextPresentation textExplication={((rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name).length>43)?((rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name).slice(0,40)+"..."):(rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name)} style={{marginTop:5}} editable={false} onNext={value => {this.navigateTo('PricePlaceSettings', {data: rowData,updateData: this.updateData,updatePersonalData:this.updatePersonalData,extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission,defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption,driverPricing:this.state.driverPricing,customerPricing:this.state.customerPricing,carListName:this.state.carListName, disabledChange:this.state.viewAdmin, viewSpecificID:this.state.viewAdmin,driverID:this.state.driverID,customerID:this.state.customerID, }); }} icon={(rowData.taxiMode==true) ? ((style.mode=="dark")?require('../assets/taxi_white.png'):require('../assets/taxi_black.png')):     ((style.mode=="dark")?require('../assets/favorite/pin_white.png'):require('../assets/favorite/pin_black.png'))} textMini={(((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName)).length>63)?(((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName)).slice(0, 60)+" ...") :((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName))}    />
            ))}  

            
          {this.state.taxiMode==true && <Text style={{marginTop:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",marginBottom:10}}>{strings.pricingClassic+" "+strings.taxi.toLowerCase()}</Text>}
          {(this.state.classicTaxi.length == 0 && this.state.taxiMode==true) && <MavInfoBubble type={"info"} miniLine={true} title={strings.noZone} subTitle={strings.clickOnAddZone} style={{marginTop:0,}} />} 


          {this.state.classicTaxi.map((rowData) => (
                 <TextPresentation textExplication={((rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name).length>43)?((rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name).slice(0,40)+"..."):(rowData.radius == undefined? strings.from+' : ' +rowData.startName : rowData.name)} style={{marginTop:5}} editable={false} onNext={value => {this.navigateTo('PricePlaceSettings', {data: rowData,updateData: this.updateData,updatePersonalData:this.updatePersonalData,extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission,defineMinimalPassager:this.state.defineMinimalPassager,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption,driverPricing:this.state.driverPricing,customerPricing:this.state.customerPricing,carListName:this.state.carListName, disabledChange:this.state.viewAdmin, viewSpecificID:this.state.viewAdmin,driverID:this.state.driverID,customerID:this.state.customerID, }); }} icon={(rowData.taxiMode==true) ? ((style.mode=="dark")?require('../assets/taxi_white.png'):require('../assets/taxi_black.png')):     ((style.mode=="dark")?require('../assets/favorite/pin_white.png'):require('../assets/favorite/pin_black.png'))} textMini={(((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName)).length>63)?(((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName)).slice(0, 60)+" ...") :((rowData.radius!=undefined)?("Zone : "+rowData.radius+" km"):(strings.to+" : "+rowData.endName))}    />
            ))}  


            {(this.state.viewPromoCode==true) && <MavInfoBubble type={"menu"} icon={(style.mode=="dark")?require('../assets/promo_white.png'):require('../assets/promo_black.png')} title={strings.yourPromoCode} subTitle={strings.promoCodeMoreInfo}  style={{marginTop:10,marginBottom:20}} />}
            {(this.state.viewPromoCode==true) && <MavInfoBubble type={"help"} videoeLink={"http://www.google.fr"} tutoLink={"https://mon-appli-vtc.com/2022/04/21/campagne-promotionnelle-avec-mon-appli-vtc/"}  style={{marginTop:10,marginBottom:20}} />}
            {(this.state.promoCode.length == 0 && this.state.viewPromoCode==true) && <MavInfoBubble type={"info"} miniLine={true} title={strings.noPromoCode} subTitle={strings.clickOnAddForfait} style={{marginTop:5,}} />} 

            {this.state.promoCode.map((rowData) => (
                 <TextPresentation style={{marginTop:5}} editable={false} onNext={value => {this.navigateTo('PromoCode', {data: rowData,extraEUMode:this.state.extraEUMode,updateCode: this.updateCode,}); }} icon={((style.mode=="dark")?require('../assets/promo_white.png'):require('../assets/promo_black.png'))} text={rowData.codeName}  textExplication={strings.promoCodePercent+" "+((rowData.amount==true && rowData.money.position=="left")?(rowData.money.symbole+" "):"")+rowData.codeReduc+((rowData.amount!=true)?"%":"") + ((rowData.amount==true && rowData.money.position=="right")?rowData.money.symbole:"")   }  />
            ))}      
        </ScrollView>







        {this.state.viewAdmin!=true && <View style={{alignItems:"center",justifyContent:"center", marginTop:20,marginBottom:20}}>
          <MavButton text={strings.add} onlyButton={true}  onPress={()=>{ this.addPlace() }}/>

          </View>}

          <MavAlert ref={alert => {this.alert = alert;}}/>

        
      </View>
      }</StyleContext.Consumer>

    );
  }
}


