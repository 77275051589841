import React, { Component } from 'react';

import {StyleContext,styleInfo} from '../styleC';
import strings from '../strings';
import info from '../info';
import MavSelector from './mavSelector';
import MavNavigationMinimize from './mavNavigationMinimize';
import {
  View,
  Text,
  TouchableHighlight,
  Alert,
  ScrollView,
  Image,
  TextInput,
  Dimensions,Picker,
Platform,
Linking,
ActivityIndicator
 
} from 'react-native';
import Qs from 'qs';
const timer = require('react-native-timer');
import TextPresentation from './textPresentation';
import MavSearch from './mavSearch';
var iconList=[
        {name:"no",iconDark:"",iconLight:""},

        {name:"flight",iconDark:require('../assets/favorite/flight_white.png'),iconLight:require('../assets/favorite/flight_black.png')},
        {name:"train",iconDark:require('../assets/favorite/train_white.png'),iconLight:require('../assets/favorite/train_black.png')},
        {name:"home",iconDark:require('../assets/favorite/home_white.png'),iconLight:require('../assets/favorite/home_black.png')},
        {name:"bus",iconDark:require('../assets/favorite/bus_white.png'),iconLight:require('../assets/favorite/bus_black.png')},
        {name:"portfolio",iconDark:require('../assets/favorite/portfolio_white.png'),iconLight:require('../assets/favorite/portfolio_black.png')},
        {name:"restaurant",iconDark:require('../assets/favorite/restaurant_white.png'),iconLight:require('../assets/favorite/restaurant_black.png')},
        {name:"bed",iconDark:require('../assets/favorite/bed_white.png'),iconLight:require('../assets/favorite/bed_black.png')},
        {name:"library",iconDark:require('../assets/favorite/library_white.png'),iconLight:require('../assets/favorite/library_black.png')},
        {name:"foot",iconDark:require('../assets/favorite/foot_white.png'),iconLight:require('../assets/favorite/foot_black.png')},
        {name:"cinema",iconDark:require('../assets/favorite/cinema_white.png'),iconLight:require('../assets/favorite/cinema_black.png')},
        {name:"ferry",iconDark:require('../assets/favorite/ferry_white.png'),iconLight:require('../assets/favorite/ferry_black.png')},
        {name:"bar",iconDark:require('../assets/favorite/bar_white.png'),iconLight:require('../assets/favorite/bar_black.png')},
        {name:"caddy",iconDark:require('../assets/favorite/caddy_white.png'),iconLight:require('../assets/favorite/caddy_black.png')},
        {name:"heart",iconDark:require('../assets/favorite/heart_white.png'),iconLight:require('../assets/favorite/heart_black.png')},
        {name:"park",iconDark:require('../assets/favorite/park_white.png'),iconLight:require('../assets/favorite/park_black.png')},
        {name:"attraction",iconDark:require('../assets/favorite/attraction_white.png'),iconLight:require('../assets/favorite/attraction_black.png')},
        {name:"hospital",iconDark:require('../assets/favorite/hospital_white.png'),iconLight:require('../assets/favorite/hospital_black.png')},
        {name:"pharmacy",iconDark:require('../assets/favorite/pharmacy_white.png'),iconLight:require('../assets/favorite/pharmacy_black.png')},
        {name:"doctor",iconDark:require('../assets/favorite/doctor_white.png'),iconLight:require('../assets/favorite/doctor_black.png')},
        {name:"briefcase",iconDark:require('../assets/favorite/briefcase_white.png'),iconLight:require('../assets/favorite/briefcase_black.png')},
        {name:"university",iconDark:require('../assets/favorite/university_white.png'),iconLight:require('../assets/favorite/university_black.png')},
        {name:"hatGrad",iconDark:require('../assets/favorite/hatGrad_white.png'),iconLight:require('../assets/favorite/hatGrad_black.png')},
        {name:"previous",iconDark:require('../assets/favorite/previous_white.png'),iconLight:require('../assets/favorite/previous_black.png')},
        {name:"customerFavorite",iconDark:require('../assets/favorite/customer_favorite_white.png'),iconLight:require('../assets/favorite/customer_favorite_black.png')},
        {name:"pin",iconDark:require('../assets/favorite/pin_white.png'),iconLight:require('../assets/favorite/pin_black.png')},
        {name:"vehiculeR",iconDark:require('../assets/favorite/vehiculeR_white.png'),iconLight:require('../assets/favorite/vehiculeR_black.png')}
        
      ]



export default class trajet extends Component {
  constructor(props) {
    super(props);
    console.log("trajet ici")
    const style=styleInfo();

    var predefinedPlacesVal=[];

       var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

       if(Platform.OS!="web" && this.props.navigation!=undefined ){
        this.props.navigation.setOptions({ title:(params.onFavoritePlace!=null)?strings.placeSelect:(params.onChangeTo!=null)?((info.disableProvision==true)?strings.destination: strings.destinationOrDuration):((params.onChangeIntermediateStop!=undefined)?strings.multiStopInfo:strings.yourStart) })
        } 

       



  if(params.latitude!=undefined){
    if( (params.dispenser!=true) && ( (params.onChangeTo!=null && params.mapFrom.description!=strings.myPosition) || (params.onChangeFrom!=null && params.mapTo.description!=strings.myPosition))){
          predefinedPlacesVal=[ {iconDark:require('../assets/favorite/target_white.png'),iconLight:require('../assets/favorite/target_black.png'), description: strings.myPosition, geometry: { location: { lat: params.latitude, lng: params.longitude } }}]
    } 
 
  }

  

if(params.favorite!=undefined && params.favorite!=null){
      var val=params.favorite;
      var valList=Object.keys(val);
      var i;
      var previousUserDescr;
      var previousUserDescrI;
      var  descriptionPlace;
      for(i=0;i<valList.length;i++){
        var prov={};

        if(val[valList[i]].previous===true){
          previousUserDescr=val[valList[i]].userDescription; 
          previousUserDescr=previousUserDescr.split(', ');
          descriptionPlace="";
          for(previousUserDescrI=1; previousUserDescrI<previousUserDescr.length; previousUserDescrI++){
            descriptionPlace+=(previousUserDescr[previousUserDescrI]!="France")?((previousUserDescr[previousUserDescrI])+" "):""
          }

          prov.description=previousUserDescr[0]; 
          if(descriptionPlace!="" && descriptionPlace!=" ") prov.descriptionPlace=descriptionPlace; 
        }
        else prov.description=val[valList[i]].userDescription;
        prov.realDescription=val[valList[i]].description;
        prov.geometry={location:{lat:val[valList[i]].coordinates.latitude,lng:val[valList[i]].coordinates.longitude}}
        if(val[valList[i]].descriptionPlace!=undefined) prov.descriptionPlace=val[valList[i]].descriptionPlace;
        prov.favID=valList[i];

        if(val[valList[i]].icon && iconList.findIndex(x => x.name === val[valList[i]].icon)>0 && iconList[iconList.findIndex(x => x.name === val[valList[i]].icon)]!=undefined){
          prov.iconDark=iconList[iconList.findIndex(x => x.name === val[valList[i]].icon)].iconDark
          prov.iconLight=iconList[iconList.findIndex(x => x.name === val[valList[i]].icon)].iconLight
          prov.icon=val[valList[i]].icon;
          prov.previousSearch=(val[valList[i]].previous===true)?true:false; 
          
          
        } 
        
        predefinedPlacesVal.push(prov);
      }

    }

    

    var actualDuration="";
    var listHour=[];

    if(params.choiceDuration===true){

        var i,j;
        if(params.durationMin!=undefined){
          j=Math.round(params.durationMin/30)
        } else j=0;

          var list=new Array;
          var max=49-j;
          for(i=0;i<max;i++){
            list[i]=1800*j;
            j++;
          }
          actualDuration=params.duration;
          listHour=list
    }

    

    
    this.state={
      textInput:"",
      predefinedPlaces:predefinedPlacesVal,
      predefinedPlacesView:predefinedPlacesVal,
      search:"",
      prediction:[],
      viewProvisionDuration:(params.durationSelected==true)?true:false,
      sessiontoken:generateUUID(),
      dispenser:(params.dispenser==true)?true:false,
      actualDuration:actualDuration,
      listHour:listHour,
      params:params, 
      minimizeMode:(this.props.navigation!=undefined || params.hideNavBar==true)?false:true,
      forceBackgroundColor:params.backgroundColor,
      searchLimited:params.searchLimited, 
      
      
    }




    
   

 
  }


  mapAutocomplete(types){
    console.log("mapAutocomplete "+types)
    var i;
    var corresp={
      airport:"flight", 
      amusement_park:"attraction",
      bar:"bar",
      bus_station:"bus",
      city_hall:"library", 
      furniture_store:"caddy",
      hardware_store:"caddy",
      light_rail_station:"bus", 
      movie_theater:"cinema",
      museum:"library",
      lodging:"bed",
      night_club:"bar",
      shopping_mall:"caddy", 
      store:"caddy", 
      supermarket:"caddy",
      stadium:"foot",
      restaurant:"restaurant", 
      train_station:"train",
      transit_station:"train", 
      doctor:"doctor",
      hospital:"hospital",
      pharmacy:"pharmacy",
      park:"park",
      tourist_attraction:"attraction", 
      school:"university",
      secondary_school:"university",
      primary_school:"university",
      university:"university",

    }
    var returnInfo=undefined; 

    for(i=0; i<types.length;i++){
        if(returnInfo==undefined && corresp[types[i]]!=undefined){
          returnInfo=corresp[types[i]]; 
        }
    }
    return returnInfo; 

  }

  searchResult(){

    
    var find=this.state.search;
    var result=this.state.predefinedPlaces;
    var result2 = result.filter(word => ( (word.description!=undefined && word.description.toLowerCase().search(find.toLowerCase())!=-1 ) || (word.realDescription!=undefined && word.realDescription.toLowerCase().search(find.toLowerCase())!=-1) ) );
    this.setState({predefinedPlacesView:result2})

    if(result2.length<5 && this.state.search.length>3 && info.disableTrajetAutocomplete!=true){

          var searchWord=encodeURIComponent(this.state.search);
          var paramsRqs=Qs.stringify({fields: "geometry, description",key: info.googleMapsKey,language: 'fr',sessiontoken:this.state.sessiontoken})

          var body={searchWord:searchWord, paramsRqs:paramsRqs, type:"autocomplete" }
          
          var autoClt;
          if(Platform.OS=="web") autoClt=fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getMapsInfo", {body: JSON.stringify(body),method: "POST"})
          else autoClt=fetch('https://maps.googleapis.com/maps/api/place/autocomplete/json?&input=' + searchWord + '&' + paramsRqs, {headers: {"Content-Type": "application/json"},method: "GET"})

            autoClt.then((response) => response.json()).then((responseJson) => {
                      if(typeof(responseJson)!="object") responseJson=JSON.parse(responseJson);

                      var i;
                      for(i=0; i<responseJson.predictions.length;i++){
                        var test=this.mapAutocomplete(responseJson.predictions[i].types);
                          responseJson.predictions[i].icon=(test!=undefined)?test:"pin"; 
                          if(responseJson.predictions[i].icon && iconList.findIndex(x => x.name === responseJson.predictions[i].icon)>0 && iconList[iconList.findIndex(x => x.name === responseJson.predictions[i].icon)]!=undefined){
                              responseJson.predictions[i].iconDark=iconList[iconList.findIndex(x => x.name === responseJson.predictions[i].icon)].iconDark
                              responseJson.predictions[i].iconLight=iconList[iconList.findIndex(x => x.name === responseJson.predictions[i].icon)].iconLight
                              var previousUserDescr=responseJson.predictions[i].description; 
                              previousUserDescr=previousUserDescr.split(', ');
                              var descriptionPlace="";
                              var previousUserDescrI; 
                              for(previousUserDescrI=1; previousUserDescrI<previousUserDescr.length; previousUserDescrI++){
                                descriptionPlace+=(previousUserDescr[previousUserDescrI]!="France")?((previousUserDescr[previousUserDescrI])+" "):""
                              }

                              
                              if(descriptionPlace!="" && descriptionPlace!=" ") {
                                responseJson.predictions[i].descriptionPlace=descriptionPlace; 
                                responseJson.predictions[i].descriptionMax=previousUserDescr[0]; 
                              }

                            }
                      }






                      
                    

                       

                      this.setState({prediction:responseJson.predictions})

                    }).catch((err) => { console.log("error searchResult"); console.log(err); if(false) timer.setTimeout("searchResult", () => { this.searchResult();  }, 2000);   })



    }
    else { 
      this.setState({prediction:[]})
    }





    

  }

clickOnFavorite(value){
    var val=new Object;
    val.id=this.state.predefinedPlacesView[value].favID
    val.coordinates=new Object;
    val.coordinates.latitude=this.state.predefinedPlacesView[value].geometry.location.lat;
    val.coordinates.longitude=this.state.predefinedPlacesView[value].geometry.location.lng;
      
    if(this.state.predefinedPlacesView[value].realDescription!=undefined) {
      val.description=this.state.predefinedPlacesView[value].realDescription;
      val.userDescription=this.state.predefinedPlacesView[value].description;


    }
    else {
      val.description=this.state.predefinedPlacesView[value].description
      val.userDescription=this.state.predefinedPlacesView[value].description;
      if(this.state.predefinedPlacesView[value].description==strings.myPosition) val.descriptionPosition=true;
          
    }
    
    if(this.state.predefinedPlacesView[value].iconDark!=undefined) val.iconDark=this.state.predefinedPlacesView[value].iconDark;
    if(this.state.predefinedPlacesView[value].iconLight!=undefined) val.iconLight=this.state.predefinedPlacesView[value].iconLight;
    if(this.state.predefinedPlacesView[value].icon!=undefined) val.icon=this.state.predefinedPlacesView[value].icon; 

    this.returnResponse(val)     



}

removePreviousSearch(value){
    if(this.state.params.removePreviousSearch!=null) this.state.params.removePreviousSearch(this.state.predefinedPlaces[value].favID);
    var pp=this.state.predefinedPlaces;
    pp.splice(value,1);
    this.setState({predefinedPlaces:pp})

}

clickOnResult(value){
  
  var paramsRqs=Qs.stringify({fields: "geometry",key: info.googleMapsKey,placeid:this.state.prediction[value]["place_id"], language: 'fr',})

  var body={paramsRqs:paramsRqs, type:"placeDetails" }
  var rqsMapInfo;
  if(Platform.OS=="web") rqsMapInfo=fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getMapsInfo", {body: JSON.stringify(body),method: "POST"})
  else rqsMapInfo=fetch('https://maps.googleapis.com/maps/api/place/details/json?' +paramsRqs, {headers: {"Content-Type": "application/json"},method: "GET"})

  rqsMapInfo.then((response) => response.json()).then((responseJson) => {
                if(typeof(responseJson)!="object") responseJson=JSON.parse(responseJson);
                var val=new Object;
                val.coordinates=new Object;
                val.coordinates.latitude=responseJson.result.geometry.location.lat;
                val.coordinates.longitude=responseJson.result.geometry.location.lng;
                val.description=this.state.prediction[value].description
                val.userDescription=this.state.prediction[value].description;
                if(this.state.prediction[value].icon=="train" || this.state.prediction[value].icon=="flight") val.icon=this.state.prediction[value].icon;
                
                this.returnResponse(val,true)  
  }).catch((err) => { console.log("error clickOnResult"); console.log(err); timer.setTimeout("clickOnResult", () => { this.clickOnResult(value);  }, 2000);   })






}

returnResponse(val,search){
  var val2=JSON.parse(JSON.stringify(val)); 
  delete val.icon; 
  if(search==true && (this.state.params.onChangeTo!=null || this.state.params.onChangeIntermediateStop!=null || this.state.params.onChangeFrom!=null) && this.state.params.addPreviousSearch!=null) {
    this.state.params.addPreviousSearch(val2);
  }
  
  if(this.state.params.onChangeTo!=null) {this.state.params.onChangeTo(val2); } 
  else if(this.state.params.onChangeFrom!=null){this.state.params.onChangeFrom(val2);}
  else if(this.state.params.onChangeIntermediateStop!=null){this.state.params.onChangeIntermediateStop(val2,this.state.params.intermediateStop );}
  else if(this.state.params.onPricePlace!=null){this.state.params.onPricePlace(val);}
  else if(this.state.params.onFavoritePlace!=null){this.state.params.onFavoritePlace(val);}

  if(this.state.params.hideNavBar==true) this.props.params.goBack();
  else if(this.state.minimizeMode!=true) this.props.navigation.goBack();
  else this.props.params.goBack();

}
  
  

  render() {
    return (
      <StyleContext.Consumer>{style => 
     <ScrollView style={{height:(this.state.searchLimited!=undefined)?(55*(this.state.searchLimited+1 +((this.state.params.choiceDuration===true && this.state.dispenser!=true)?1:0 )   )):"100%",width:"100%",  backgroundColor:(this.state.forceBackgroundColor!=undefined)?this.state.forceBackgroundColor:style.backgroundColor}}>


          {(this.state.minimizeMode==true || this.state.dispenser==true) && <MavNavigationMinimize maxiMode={this.state.dispenser} clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={(this.state.dispenser==true)?strings.whereWantToGo :(this.state.params.onFavoritePlace!=null)?strings.placeSelect:(this.state.params.onChangeTo!=null)?strings.destinationOrDuration:((this.state.params.onChangeIntermediateStop!=undefined)?strings.multiStopInfo:strings.yourStart)} />}

          {(this.state.params.choiceDuration===true && this.state.dispenser!=true) && <MavSelector maxiMode={this.state.dispenser} style={{marginBottom:0}} selected={(this.state.viewProvisionDuration==false)?0:1 } textLeft={strings.destination} textRight={strings.provision} onChange={(value)=>{ 
              this.setState({viewProvisionDuration:!this.state.viewProvisionDuration})
          }}/>}
          
          {(this.state.viewProvisionDuration==false ) && <View>




          <MavSearch highContrast={(this.state.searchLimited!=undefined)?true:false} style={{marginTop:15, marginBottom:10,}} search={this.state.search} searchExplication={strings.enterLocation} onSearch={text => { if(text!="?") this.setState({search:text},() =>{this.searchResult()})    }} />


          

          {this.state.predefinedPlacesView.map((rowData,i)=>(
            <View>
            {(this.state.searchLimited==undefined || i<this.state.searchLimited) &&<TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.clickOnFavorite(i);}} >
             <View>
             {(rowData.descriptionPlace==undefined && rowData.iconLight==undefined) && <TextPresentation maxiMode={this.state.dispenser} style={{marginTop:5}} forceClick={() => {this.clickOnFavorite(i); }} textExplication={rowData.description} disabledText={true} enableRemove={(rowData.previousSearch==true)?true:undefined}  onRemove={()=>{  this.removePreviousSearch(i) }} />}
             {(rowData.descriptionPlace==undefined && rowData.iconLight!=undefined) && <TextPresentation maxiMode={this.state.dispenser} style={{marginTop:5}} forceClick={() => {this.clickOnFavorite(i); }} textExplication={rowData.description} disabledText={true} icon={(style.mode=="dark")?rowData.iconDark:rowData.iconLight} enableRemove={(rowData.previousSearch==true)?true:undefined} onRemove={()=>{  this.removePreviousSearch(i) }} />}
             {(rowData.descriptionPlace!=undefined && rowData.iconLight==undefined) && <TextPresentation maxiMode={this.state.dispenser} style={{marginTop:5}} forceClick={() => {this.clickOnFavorite(i); }} textExplication={rowData.description} disabledText={true}  textExplicationMini={rowData.descriptionPlace} enableRemove={(rowData.previousSearch==true)?true:undefined} onRemove={()=>{  this.removePreviousSearch(i) }} />}
             {(rowData.descriptionPlace!=undefined && rowData.iconLight!=undefined) && <TextPresentation maxiMode={this.state.dispenser} style={{marginTop:5}} forceClick={() => {this.clickOnFavorite(i); }} textExplication={rowData.description} disabledText={true} icon={(style.mode=="dark")?rowData.iconDark:rowData.iconLight} textMini={rowData.descriptionPlace} enableRemove={(rowData.previousSearch==true)?true:undefined} onRemove={()=>{  this.removePreviousSearch(i) }} />}
               </View>
             </TouchableHighlight>}

             </View>


          ))}

          {(  this.state.prediction!=[]) && this.state.prediction.map((rowData,i)=>(

            <View>
                {(this.state.searchLimited==undefined || i<(this.state.searchLimited-this.state.predefinedPlacesView.length)) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.clickOnResult(i);}} >
                  <View>
                   <TextPresentation textMini={(rowData.descriptionPlace!=undefined)?rowData.descriptionPlace:undefined} icon={(style.mode=="dark")?rowData.iconDark:rowData.iconLight} forceClick={() => {this.clickOnResult(i); }} maxiMode={this.state.dispenser} style={{marginTop:5}} textExplication={(rowData.descriptionMax!=undefined)?rowData.descriptionMax: rowData.description} disabledText={true} />
                   </View>
                </TouchableHighlight>}
            </View>

          ))}

          </View>}



          {(this.state.viewProvisionDuration==true  ) && <View>
            <Text style={{textAlign:'center',marginTop:10,fontWeight:"bold",fontSize: style.textInputFontSize,color:style.textInputColor}}>{strings.thanksSelectDuration}</Text>
            {this.state.listHour.map((rowData)=>(
              <TouchableHighlight underlayColor={style.backgroundColor} style={{height:50,justifyContent: 'center',}} onPress={() => {
                this.setState({actualDuration:rowData})
                if(this.state.params.changeDuration!=null){this.state.params.changeDuration(rowData);}
                if(this.state.minimizeMode!=true) this.props.navigation.goBack();else this.props.params.goBack();}}>
                      <Text style={{fontWeight:(this.state.actualDuration==rowData)?"bold":"normal",textAlign:'center',fontSize: style.textInputFontSize,color:(this.state.actualDuration==rowData)?style.color1:style.textInputColor}}>{(((Math.floor(rowData/3600))<10)?"0":"")+Math.floor(rowData/3600)+":"+((Math.floor((rowData%3600)/60)<10)?"0":"")+Math.floor((rowData%3600)/60)}</Text>
              </TouchableHighlight> 

              ))}
            </View>}





     </ScrollView>
     }</StyleContext.Consumer>

    );
  }
}




export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
