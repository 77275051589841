import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableHighlight,
  ActivityIndicator,
  Dimensions,
  TextInput,
  Image,
  Alert,
  Platform,
  Clipboard,
  ScrollView,
  Linking,
} from 'react-native';
import {StyleContext, styleInfo} from '../styleC';
import strings from '../strings';
import info from '../info';
import firebase from './firebase';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword,sendPasswordResetEmail, signInWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential, updatePassword, deleteUser} from "firebase/auth";
import { getDatabase, ref, set, query, update, remove, get, onValue, child, orderByChild, startAt, endAt} from "firebase/database";
import Modal from './modal';
const timer = require('react-native-timer');

import TextPresentation from './textPresentation';
import MavButton from './mavButton';
import MavSelector from './mavSelector';
import MavQRCode from './mavQRCode';
import MavAlert from './mavAlert';
import moneyList from '../moneyList';
import MavNavigationMinimize from './mavNavigationMinimize';
import MapView from './mapView';
import Clients from "./clients"
import Account from "./account"
import Settings from "./settings"
import Chat from "./chat"
import Revenue from "./revenue"
import Favorite from "./favorite"
import Trajet from "./trajet"
import CurrencyGestion from "./currencyGestion"
import OptionGestion from "./optionGestion"
import Ads from "./ads"
import PricePlace from "./pricePlace"
import PricePlaceSettings from "./pricePlaceSettings"
import Majoration from "./majoration"
import API from "./API"
import SetDrawCard from "./setDrawCard"
import PromoCode from "./promoCode"
import PriceProvision from "./priceProvision"
import Planning from "./planning"
import NoteInfo from "./noteInfo"
import Booking from "./booking"
import Mavvous from "./mavvous"
import NotificationPanel from "./notificationPanel"


import Help from "./help"
export default class book extends Component {
  constructor(props) {
    super(props);
    
    var sd={}
    sd.goBack=() => {


      console.log("goBack")
    }
    sd.navigate=(type,params) => {
      console.log("navigate admin "+type)



      if(this.state.selected==3){
          var sd={}
          if(params!=undefined) sd=params;
          sd.disableNavigation=true; 
          sd.resetNavigation=() => {  this.setState({selected:-1,viewClient:false,},() =>{ this.setState({selected:3,})})  }
        
          sd.navigate=(type,params) => { this.state.params.navigate(type,params)      }
          sd.goBack=() => {  this.setState({selected:-1,viewClient:false,},() =>{ this.setState({selected:3,})})  }
          

          if(type=="Account"){
            console.log("ici 1")
              this.setState({selected:3, viewClient:false, },() =>{console.log("ici 2"); this.setState({paramsClient:sd, viewOtherElem:"", viewOtherElemLevel2:"", viewOtherElemLevel3:"", saveClientParams:sd, viewClient:true}); })
          } 
          else {
            console.log("ici et la" +type)


            if(type=="Revenue"){

              this.setState({selected:-1,viewClient:false,},() =>{ this.setState({selected:4,params:params  })})
            }

            else if(type=="Trajet" || type=="Majoration" || type=="SetDrawCard" ){
                 sd.goBack=() => { this.setState({viewOtherElemLevel3:""})  }
                this.setState({viewOtherElemLevel3:type, paramsviewOtherElemLevel3:sd });
            }

            else if(type=="PricePlaceSettings" ){
                sd.goBack=() => { this.setState({viewOtherElemLevel2:""})  }
                this.setState({viewOtherElemLevel2:type, paramsviewOtherElemLevel2:sd });

            }

            else {
              sd.goBack=() => { this.setState({viewOtherElem:""})  }
              this.setState({viewOtherElem:type, paramsViewOtherElem:sd });
            }
            

          }






          
          
           

      }
      if(this.state.selected==5){
        console.log("selected5")
            var sd={}
            if(params!=undefined) sd=params;
            sd.disableNavigation=true; 

            sd.navigate=(type,params) => { this.state.params.navigate(type,params)      }
            sd.goBack=(typeB,paramsB) => { 
              
              if(this.state.viewSettings==="PricePlaceSettings" || this.state.viewSettings==="Clients" || this.state.viewSettings==="Majoration" || this.state.viewSettings==="PriceProvision" || this.state.viewSettings==="PromoCode" ){
                if(this.state.typeOfSave=="API") this.setState({viewSettings:"API", paramsSettings:this.state.savePricePlaceParams}) ;
                else this.setState({viewSettings:"PricePlace", paramsSettings:this.state.savePricePlaceParams}) ;
              } 
              else this.setState({viewSettings:""})   
            }
            

            if(type=="PricePlace" || type=="API"){this.setState({savePricePlaceParams:sd, typeOfSave:type}) }
            if(this.state.viewSettings==="API" && type=="Clients") {sd.disableNavigation=false; this.setState({viewOtherElem:""},() =>{ sd.goBack=() => { this.setState({viewOtherElem:""})}; this.setState({paramsViewOtherElem:sd, viewOtherElem:type}); }) }
            else if((this.state.viewSettings==="PricePlaceSettings" || this.state.viewSettings==="PriceProvision" ) && (type=="Trajet" || type=="Majoration" || type=="SetDrawCard") ) this.setState({viewOtherElem:""},() =>{ sd.goBack=() => { this.setState({viewOtherElem:""})}; this.setState({paramsViewOtherElem:sd, viewOtherElem:type}); })
            else this.setState({viewSettings:"",},() =>{this.setState({paramsSettings:sd, viewSettings:type}); })
            
      }
    

  } 
    sd.driver=true; 
    sd.disableNavigation=true; 
    sd.billWithoutDetail=this.props.info.billWithoutDetail;

    var sdCli=JSON.parse(JSON.stringify(sd))
    sdCli.navigate=(type,params) => { this.state.params.navigate(type,params)      }

    this.state = {
      menuList:[],
      selected:0,
      driverSelected:0, 
      waitDrivers:true,
      driversList:[], 
      viewClient:false,
      driversListLastUpdate:"", 
      driverPosition:undefined,
      mapRegion: info.initialRegion,
      params:sd, 
      paramsClient:"",
      paramsSettings:"",
      paramsViewOtherElem:"",
      viewOtherElem:"",
      viewOtherElemLevel2:"",
      paramsviewOtherElemLevel2:"",
      viewOtherElemLevel3:"",
      paramsviewOtherElemLevel3:"",
      driverAlphabeticalList:{}, 


      paramsClients:Object.assign(sdCli, { select: "all",driver:true, defineColorForCustomer:this.props.info.defineColorForCustomer, enableOrderDriver:this.props.info.enableOrderDriver, driverPricing:this.props.info.driverPricing, reviews:{activateCustomerNotation:this.props.info.activateCustomerNotation, activateDriverNotation:this.props.info.activateDriverNotation}, options:{childSeats:this.props.info.childSeats,boosterSeats:this.props.info.boosterSeats,wheelchair:this.props.info.wheelchair, animal:this.props.info.animal} }),
  


    };


    
    
  }
  
  

  componentDidMount() {

    console.log("admin mount")
    this.getDrivers();
    
     if(this.props.info.driverPositionPrecise==true) onValue(ref(getDatabase(firebase), '/driverPosition'), (value) => {

          var val=value.val()
          if(val!=null) {
            this.setState({driverPosition:val})
            var arrayVal=Object.keys(val);

          var i;
          var position={latitude: "",lat:"", lng:"", longitude:"", latitudeMax:0, latitudeMin:0, longitudeMax:0, longitudeMin:0 }
          
          if(this.state.driverPositionPreciseMapRegion==undefined){
          for(i=0; i<arrayVal.length;i++){

            if(val[arrayVal[i]].coor.latitude!==0 && this.state.driversListObj[arrayVal[i]]!=undefined){


            if(position.latitude==""){
              position.latitude=val[arrayVal[i]].coor.latitude;
               position.lat=val[arrayVal[i]].coor.latitude
               position.longitude=val[arrayVal[i]].coor.longitude
               position.lng=val[arrayVal[i]].coor.longitude
               position.longitudeMax=val[arrayVal[i]].coor.longitude
               position.longitudeMin=val[arrayVal[i]].coor.longitude
               position.latitudeMin=val[arrayVal[i]].coor.latitude
               position.atitudeMax=val[arrayVal[i]].coor.latitude
            }
            else {
               if(val[arrayVal[i]].coor.longitude>position.longitudeMax) position.longitudeMax=val[arrayVal[i]].coor.longitude
               else if(val[arrayVal[i]].coor.longitude<position.longitudeMin) position.longitudeMin=val[arrayVal[i]].coor.longitude
              
               if(val[arrayVal[i]].coor.latitude>position.latitudeMax) position.latitudeMax=val[arrayVal[i]].coor.latitude
               else if(val[arrayVal[i]].coor.latitude<position.latitudeMin) position.latitudeMin=val[arrayVal[i]].coor.latitude

            }
        }
            

          
        }

          position.latitudeDelta=(position.latitudeMax-position.latitudeMin);
          position.longitudeDelta=(position.longitudeMax-position.longitudeMin);

         
          this.setState({mapRegion:position, driverPositionPreciseMapRegion:true})
        }
          }

      })


    }


getDrivers(){

  get(query(ref(getDatabase(firebase), '/users'), orderByChild('partner'), startAt(true), endAt(true))).then(snapshot => {


      var val=snapshot.val(); 

      

      var i
      var finalObj=[]; 
      var onlinePartner=[]; 
      var offlinePartner=[]; 
      var driverAlphabeticalList={}; 

      if(val!=null){


        var arrayVal=Object.keys(val);
        var i;
        var position={latitude: "",lat:"", lng:"", longitude:"", latitudeMax:0, latitudeMin:0, longitudeMax:0, longitudeMin:0 }
        for(i=0; i<arrayVal.length;i++){

          if(val[arrayVal[i]].lastDriverPositionLatitude!=undefined){

            if(position.latitude==""){
              position.latitude=val[arrayVal[i]].lastDriverPositionLatitude;
               position.lat=val[arrayVal[i]].lastDriverPositionLatitude
               position.longitude=val[arrayVal[i]].lastDriverPositionLongitude
               position.lng=val[arrayVal[i]].lastDriverPositionLongitude
               position.longitudeMax=val[arrayVal[i]].lastDriverPositionLongitude
               position.longitudeMin=val[arrayVal[i]].lastDriverPositionLongitude
               position.latitudeMin=val[arrayVal[i]].lastDriverPositionLatitude
               position.atitudeMax=val[arrayVal[i]].lastDriverPositionLatitude
            }
            else {
               if(val[arrayVal[i]].lastDriverPositionLongitude>position.longitudeMax) position.longitudeMax=val[arrayVal[i]].lastDriverPositionLongitude
               else if(val[arrayVal[i]].lastDriverPositionLongitude<position.longitudeMin) position.longitudeMin=val[arrayVal[i]].lastDriverPositionLongitude
              
               if(val[arrayVal[i]].lastDriverPositionLatitude>position.latitudeMax) position.latitudeMax=val[arrayVal[i]].lastDriverPositionLatitude
               else if(val[arrayVal[i]].lastDriverPositionLatitude<position.latitudeMin) position.latitudeMin=val[arrayVal[i]].lastDriverPositionLatitude

            }
            

          }


          val[arrayVal[i]].id=arrayVal[i]
          if(val[arrayVal[i]].lastDriverPositionDate==undefined) {val[arrayVal[i]].lastDriverPositionDate=-1; val[arrayVal[i]].date="--/-- - --:--"}
          else {
            val[arrayVal[i]].date=new Date(val[arrayVal[i]].lastDriverPositionDate)
            val[arrayVal[i]].date=((val[arrayVal[i]].date.getDate()<10)?"0":"")+val[arrayVal[i]].date.getDate()+"/"+((val[arrayVal[i]].date.getMonth()<9)?"0":"")+(val[arrayVal[i]].date.getMonth()+1)+" - " +   ((val[arrayVal[i]].date.getHours()<10)?"0":"")+val[arrayVal[i]].date.getHours()+":"+((val[arrayVal[i]].date.getMinutes()<10)?"0":"")+val[arrayVal[i]].date.getMinutes()
          }
          if(val[arrayVal[i]].partnerOnline==false) offlinePartner.push(val[arrayVal[i]])
          else onlinePartner.push(val[arrayVal[i]])

          
          driverAlphabeticalList[arrayVal[i]]={
            text:val[arrayVal[i]].name+" "+val[arrayVal[i]].lastName,
            id:arrayVal[i],
            online:(val[arrayVal[i]].raceInProgress!=undefined)?"progress":((val[arrayVal[i]].partnerOnline==false)?false:true),
            raceList:{}, 
          }


        }

        if(onlinePartner.length>0){
              onlinePartner.sort(function (a, b) {
                if (a.lastDriverPositionDate < b.lastDriverPositionDate) return 1;
                else if (a.lastDriverPositionDate > b.lastDriverPositionDate) return -1;
                return 0;
              });
        }

        if(offlinePartner.length>0){
              offlinePartner.sort(function (a, b) {
                if (a.lastDriverPositionDate < b.lastDriverPositionDate) return 1;
                else if (a.lastDriverPositionDate > b.lastDriverPositionDate) return -1;
                return 0;
              });
        }

        var listOrd = Object.keys(driverAlphabeticalList).sort(function(a,b){ return driverAlphabeticalList[a].text.localeCompare(driverAlphabeticalList[b].text) })

        var driverAlphabeticalListFinal={}
        for(i=0;i<listOrd.length;i++){
          driverAlphabeticalListFinal[listOrd[i]]=driverAlphabeticalList[listOrd[i]];
        }
        
        
        finalObj = onlinePartner.concat(offlinePartner); 


      }
     
      if(this.props.info.driverPositionPrecise!=true && position.latitude!=""){
        position.latitudeDelta=(position.latitudeMax-position.latitudeMin);
        position.longitudeDelta=(position.longitudeMax-position.longitudeMin);
        this.setState({mapRegion:position})
       
      }
      
  

      this.setState({waitDrivers:false,driversListObj:val, driversList:finalObj,driverAlphabeticalList:driverAlphabeticalListFinal, driversListLastUpdate:new Date()})
  


  })

}

_handleMapRegionChange = (value) => {

  console.log("_handleMapRegionChange change")
  console.log(value)
    this.setState({mapRegion:value})
  };

changeMenu(id) {

console.log("changeMenu")
console.log(this.state.params)

/*
var params=JSON.parse(JSON.stringify(this.state.params))
params.driver=true; params:params, */

 this.setState({ selected:id, viewClient:false})
 var menu=[{name:"Accueil",count:0}]
  
  if(this.props.info.manager==false || this.props.info.managerInfo.phonebook==true) menu.push({name:"Chauffeurs", count:1})
  if(this.props.info.manager==false || this.props.info.managerInfo.dispatch==true) menu.push({name:"Planning", count:2})
  if(this.props.info.manager==false || this.props.info.managerInfo.phonebook==true) menu.push({name:"Répertoire", count:3})
  if(this.props.info.manager==false || this.props.info.managerInfo.revenu==true) menu.push({name:"Facturation", count:4})
  if(this.props.info.manager==false || this.props.info.managerInfo.settings==true) menu.push({name:"Réglages", count:5})
  if(this.props.info.manager==false || this.props.info.managerInfo.settings==true) menu.push({name:"Plus d'outils", count:6})
  if(menu!=this.state.menuList) this.setState({menuList:menu})

 
  
  if(id==1){
    console.log("je suis dans chauffeurs")
    if(this.state.driversListLastUpdate!="" && ((new Date()-new Date(this.state.driversListLastUpdate)) > 180000)  ) {this.getDrivers();console.log("mise à jour quand je reviens")}

    

  }


} 

viewInfoDriver(id){
   this.changeMenu(3); 
   timer.setTimeout(generateUUID(), () => {this.clientsView.openClient(id, true) },1000)
}

  
notificationClick(data){
  console.log("je vais tester le data.type "+data.type)
  if(data.type=="onoff") {
    this.changeMenu(3); 
    timer.setTimeout(generateUUID(), () => {this.clientsView.openClient(data.driverID, true) },1000)
  }  else if(data.type=="liverace") {
    console.log("change menu 2")
      this.changeMenu(2);
      timer.setTimeout(generateUUID(), () => {this.planningView.findRace(data, true) },200)

      


      /*changeDay je change le jour et */ 

  }

   
}


  
render() {

  var menuHeight=70; 

    return (
      <StyleContext.Consumer>{style => 
        <View>
          <View style={{ width:"100%", height:"100%"}} >

              {this.state.selected!=0 && <View style={{width:"100%", height:menuHeight, backgroundColor:style.backgroundColor, flexDirection: 'row',alignItems:"center"}}>

                <Image source={require("../assets/login_logo.png")} style={{height:menuHeight*0.9,width:menuHeight*2, resizeMode:"contain"}}/>

                <View style={{flex:1, marginRight:menuHeight,flexDirection: 'row',alignItems:"center", justifyContent: 'space-between',}}>

                    {this.state.menuList.map((item, count) => ( 

                     
                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.changeMenu(item.count)  }}>
                            <Text style={{color: (item.count==this.state.selected)?style.color1:style.textInputLineColor,fontSize: style.textInputFontSize*1.3,fontWeight: (item.count==this.state.selected)?'bold':undefined, }}>{item.name}</Text>
                          </TouchableHighlight>


                      ))}


                       


                </View>

                {this.props.info.manager==false && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.notifPanel.open() }} >
                   <Image source={(style.mode=="dark")? require('../assets/bell_white.png')  :require('../assets/bell_black.png')} style={{ width: 25, height: 25, marginLeft:10, marginRight:15}}/>
                </TouchableHighlight>}


        

              </View>}

              <View style={{width:"100%", height:1, backgroundColor:style.textInputLineColor}}/>

          

                <View style={{ flex: 1,  }}>


                    {this.state.selected===0 && <View style={{flex:1,alignItems:"center",backgroundColor:style.backgroundColor}}>

                    <Image source={require("../assets/login_logo.png")} style={{height:130,width:130, marginTop:20, resizeMode:"contain"}}/>

                    <Text style={{color: style.textInputColor, fontSize: style.textInputFontSize*2, fontWeight: 'bold', textAlign:"center", marginTop:20 }}>{strings.whatDoing}</Text>

                    {(this.props.info.manager==false || this.props.info.managerInfo.phonebook==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.changeMenu(1)  }}>
                        <Text style={{color: style.textInputColor, textDecorationLine:"underline", fontSize: style.textInputFontSize*1.5, textAlign:"center", marginTop:20 }}>{strings.seeDriver}</Text>
                    </TouchableHighlight>}


                    {(this.props.info.manager==false || this.props.info.managerInfo.dispatch==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.changeMenu(2)  }}>
                        <Text style={{color: style.textInputColor, textDecorationLine:"underline", fontSize: style.textInputFontSize*1.5, textAlign:"center", marginTop:20 }}>{strings.seePlaning}</Text>
                    </TouchableHighlight>}

                    {(this.props.info.manager==false || this.props.info.managerInfo.phonebook==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.changeMenu(3)  }}>
                        <Text style={{color: style.textInputColor, textDecorationLine:"underline", fontSize: style.textInputFontSize*1.5, textAlign:"center", marginTop:20 }}>{strings.seePhoneBook}</Text>
                    </TouchableHighlight>}

                    {(this.props.info.manager==false || this.props.info.managerInfo.revenu==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.changeMenu(4)  }}>
                        <Text style={{color: style.textInputColor, textDecorationLine:"underline", fontSize: style.textInputFontSize*1.5, textAlign:"center", marginTop:20 }}>{strings.seeBill}</Text>
                    </TouchableHighlight>}

                    {(this.props.info.manager==false || this.props.info.managerInfo.settings==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.changeMenu(5)  }}>
                        <Text style={{color: style.textInputColor, textDecorationLine:"underline", fontSize: style.textInputFontSize*1.5, textAlign:"center", marginTop:20 }}>{strings.seeSettings}</Text>
                    </TouchableHighlight>}

                    {(this.props.info.manager==false || this.props.info.managerInfo.settings==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.changeMenu(6)  }}>
                        <Text style={{color: style.textInputColor, textDecorationLine:"underline", fontSize: style.textInputFontSize*1.5, textAlign:"center", marginTop:20 }}>{"Voir les outils supplémentaires"}</Text>
                    </TouchableHighlight>}


                    <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { getAuth(firebase).signOut(); document.location.reload() }}>
                        <Text style={{color: style.textInputColor, textDecorationLine:"underline", fontSize: style.textInputFontSize*1.5, textAlign:"center", marginTop:40 }}>{strings.meLogout}</Text>
                    </TouchableHighlight>




                    </View>}


                    {this.state.selected===1 && <View style={{flex:1, flexDirection: 'row',backgroundColor:style.backgroundColor}}>

                        <ScrollView style={{flex:1, maxHeight:Dimensions.get('window').height-menuHeight}}>

                          {this.state.waitDrivers===true && <View style={{flex:1,alignItems:"center",justifyContent:"center" }}>
                              <ActivityIndicator size="large" color={style.color1} />
                          </View>}

                          {(this.state.waitDrivers===false && this.state.driversList.length==0) && <Text style={{color: style.textInputColor, fontSize: style.textInputFontSize*1.1, textAlign:"center", marginTop:20 }}>{strings.nothingToView}</Text>}


                          {(this.state.waitDrivers===false && this.state.driversList.length>0) && this.state.driversList.map((item, count) => (
                                <TouchableHighlight style={{backgroundColor:(this.state.driverSelected==count)?"#7FE53B":undefined}} underlayColor={'#FFFFFF00'} onPress={() => {  this.setState({driverSelected:count})   }}>

                                  <TextPresentation enableInfo={true} onInfo={() => {this.viewInfoDriver(item.id) }} editable={false} forceClick={() => {  this.setState({driverSelected:count})   }} statusMode={(item.raceInProgress!=undefined)?"#FEA812" :((item.partnerOnline==false)?style.backgroundColorContrast:"#4F8F00")}   textInputColor={  (this.state.driverSelected==count)?"#000000": (   (item.partnerOnline==false)?style.textInputLineColor:undefined) }  disabledText={true} text={item.name+" "+item.lastName} textMini={strings.lastMAJ+" : "+((this.state.driverPosition!=undefined && this.state.driverPosition[item.id]!=undefined)?( ( ((new Date(this.state.driverPosition[item.id].date).getDate()<10)?"0":"")+new Date(this.state.driverPosition[item.id].date).getDate()+"/"+((new Date(this.state.driverPosition[item.id].date).getMonth()<9)?"0":"")+(new Date(this.state.driverPosition[item.id].date).getMonth()+1)+" - " +   ((new Date(this.state.driverPosition[item.id].date).getHours()<10)?"0":"")+new Date(this.state.driverPosition[item.id].date).getHours()+":"+((new Date(this.state.driverPosition[item.id].date).getMinutes()<10)?"0":"")+new Date(this.state.driverPosition[item.id].date).getMinutes()) ) :item.date)} />
                                  
                                </TouchableHighlight>))
 
                          }


                        </ScrollView>

                        
                        <MapView adminView={true}  enableZoom={true}  showsCompass={false}  height={Dimensions.get('window').height-menuHeight} style={{flex:2,}} region={this.state.mapRegion} onRegionChangeComplete={(value) => this._handleMapRegionChange(value)} >
                            
                            {(this.state.waitDrivers===false && this.state.driversList.length>0) && this.state.driversList.map((item, count) => ( 
                                  
                                  <MapView.Marker coordinate={{latitude:(this.state.driverPosition!=undefined && this.state.driverPosition[item.id]!=undefined)?this.state.driverPosition[item.id].coor.latitude:item.lastDriverPositionLatitude,longitude: (this.state.driverPosition!=undefined && this.state.driverPosition[item.id]!=undefined)?this.state.driverPosition[item.id].coor.longitude:item.lastDriverPositionLongitude}}  icon={ (this.state.driverSelected==count)?require('../assets/pinGreenLight.png'): ((item.raceInProgress!=undefined)? require('../assets/pinOrange.png')  :  ( (item.partnerOnline==false)? require('../assets/pinGrey.png') : require('../assets/pinGreen.png')     )  )   }  onClick={() => { this.setState({driverSelected:count}) }} />
                                  
                              ))}

                        </MapView>
                       



                    </View>}

                    {this.state.selected===2 && 
                      <View style={{flex:1,flexDirection: 'row',}}>
                        
                            <Planning   ref={planningView => {this.planningView = planningView;}} purposeReturnValid={this.props.purposeReturnValid} saveBook={this.props.saveBook} updateAfterBook={this.props.updateAfterBook} changeState={this.props.changeState} changeDate={this.props.changeDate} trajetMultiStop={this.props.trajetMultiStop} onChangeIntermediateStop={this.props.onChangeIntermediateStop} onChangeTo={this.props.onChangeTo} changeDuration={this.props.changeDuration} infoMain={this.props.info} addPreviousSearch={this.props.addPreviousSearch} removePreviousSearch={this.props.removePreviousSearch} onChangeFrom={this.props.onChangeFrom}   params= {this.state.params} driverAlphabeticalList={this.state.driverAlphabeticalList} actualUserName={this.props.info.name } actualUserLastName={this.props.info.lastName } saveRemovedRace={this.props.info.saveRemovedRace}  />
                        

                        
                      </View>}


                    {this.state.selected===3 && 
                      <View style={{flex:1,flexDirection: 'row',}}>
                        <View style={{flex:1}}>
                            <Clients params= {this.state.paramsClients }  ref={clientsView => {this.clientsView = clientsView;}} />
                        </View>
                        <View style={{width:1, height:("100%-"+menuHeight), backgroundColor:style.textInputLineColor }} />
                        <View style={{flex:1, backgroundColor:style.backgroundColorContrast}}>

                            
                            {(this.state.viewClient==true) &&  <View style={{flex:(this.state.viewOtherElem=="")?1:0,}}>
                                <Account params= {this.state.paramsClient}  />
                            </View>}



                            {(this.state.viewClient==true) && <View style={{flex:(this.state.viewOtherElem!="" && this.state.viewOtherElemLevel2=="")?1:0, backgroundColor:style.backgroundColorContrast}}>
                                  {this.state.viewOtherElem=="NoteInfo" && <NoteInfo style={{position:"absolute",flex:1}} params= {this.state.paramsViewOtherElem}  />}
                                   {this.state.viewOtherElem=="Booking" && <Booking style={{position:"absolute",flex:1}} params= {this.state.paramsViewOtherElem}  />}
                                   {this.state.viewOtherElem=="PricePlace" && <PricePlace style={{position:"absolute",flex:1}} params= {this.state.paramsViewOtherElem}  />}
                            </View>}


                            {(this.state.viewClient==true && this.state.viewOtherElem!="") && <View style={{flex:(this.state.viewOtherElemLevel2!="" && this.state.viewOtherElemLevel3=="" )?1:0, backgroundColor:style.backgroundColorContrast}}>
                                   {this.state.viewOtherElemLevel2=="PricePlaceSettings" && <PricePlaceSettings style={{position:"absolute",flex:1}} params= {this.state.paramsviewOtherElemLevel2}  />}
                            </View>}

                            {(this.state.viewClient==true && this.state.viewOtherElem!="" && this.state.viewOtherElem2!="") && <View style={{flex:(this.state.viewOtherElemLevel3!="")?1:0, backgroundColor:style.backgroundColorContrast}}>
                                   {this.state.viewOtherElemLevel3=="Trajet" && <Trajet style={{position:"absolute",flex:1}} params= {this.state.paramsviewOtherElemLevel3}  />}
                                   {this.state.viewOtherElemLevel3=="Majoration" && <Majoration style={{position:"absolute",flex:1}} params= {this.state.paramsviewOtherElemLevel3}  />}
                                   {this.state.viewOtherElemLevel3=="SetDrawCard" && <SetDrawCard style={{position:"absolute",flex:1}} params= {this.state.paramsviewOtherElemLevel3}  />}
                            </View>}




                        </View>

                        
                      </View>}

                      {this.state.selected===4 && 
                      <View style={{flex:1,flexDirection: 'row',}}>
                        
                            <Revenue params= {this.state.params}  />
                        

                        
                      </View>}



                      {this.state.selected===5 && 
                      <View style={{flex:1,flexDirection: 'row',}}>
                        <View style={{flex:1}}>
                            <Settings params= {this.state.params}  />
                        </View>
                        <View style={{width:1, height:("100%-"+menuHeight), backgroundColor:style.textInputLineColor }} />
                        <View style={{flex:(this.state.viewOtherElem=="")?1:0, opacity:(this.state.viewOtherElem=="")?1:0, backgroundColor:style.backgroundColorContrast}}>
                              {this.state.viewSettings=="Favorite" && <Favorite params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="Help" && <Help params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="CurrencyGestion" && <CurrencyGestion params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="OptionGestion" && <OptionGestion params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="PricePlace" && <PricePlace params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="Majoration" && <Majoration params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="API" && <API params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="PricePlaceSettings" && <PricePlaceSettings params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="PromoCode" && <PromoCode params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="PriceProvision" && <PriceProvision params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="Chat" && <Chat params= {this.state.paramsSettings}  />}
                              {this.state.viewSettings=="Ads" && <Ads params= {this.state.paramsSettings}  />}
                              
                              

                              
                        </View>
                        

                        <View style={{flex:(this.state.viewOtherElem!="")?1:0, backgroundColor:style.backgroundColorContrast}}>
                            {this.state.viewOtherElem=="Trajet" && <Trajet style={{position:"absolute",flex:1}} params= {this.state.paramsViewOtherElem}  />}
                            {this.state.viewOtherElem=="Majoration" && <Majoration style={{position:"absolute",flex:1}} params= {this.state.paramsViewOtherElem}  />}
                            {this.state.viewOtherElem=="SetDrawCard" && <SetDrawCard style={{position:"absolute",flex:1}} params= {this.state.paramsViewOtherElem}  />}
                            {this.state.viewOtherElem=="Clients" && <Clients style={{position:"absolute",flex:1}} params= {this.state.paramsViewOtherElem}  />}
                            
                            
                        </View>
                        
                      </View>}


                      {this.state.selected===6 && 
                      <View style={{flex:1,flexDirection: 'row',}}>
                        
                            <Mavvous params= {this.state.params}  />
                        

                        
                      </View>}


                    <MavAlert ref={alert => {this.alert = alert;}}/>

                </View>

          </View>

          <View style={{position:"absolute", top:0, right:0,}}>
            <NotificationPanel ref={notifPanel => {this.notifPanel = notifPanel;}} onNotificationClick={(data) => { this.notificationClick(data) }} />
          </View>
          
          

          

          </View>
      }</StyleContext.Consumer>
    );
  }
}




export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
