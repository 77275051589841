import React, { Component } from 'react';
import { Text, View,Image,ScrollView,Dimensions,TouchableHighlight,Linking,Alert,ActivityIndicator,Platform } from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';
import strings from '../strings';
import firebase from './firebase';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, query,startAt, endAt } from "firebase/database";
import MavButton from './mavButton';
import MavSelector from './mavSelector';
import MavAlert from './mavAlert';

import MavNavigationMinimize from './mavNavigationMinimize';
import MavQRCode from './mavQRCode';
import MavTextInput from './mavTextInput';
import BookingView from './bookingView';
import MavViewMore from './mavViewMore';
import Modal from './modal';
import Qs from 'qs';

const timer = require('react-native-timer');

export default class App extends Component {
  constructor(props) { 
    super(props);
   var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
    this.state = {
      raceList:{},
      loading:true,
      mode:"future",
      dateStart:Date.parse(new Date()),
      params:params,
      deferredList:{},
      disableCancelButton: (params!=undefined && params.disableCancelButton==true)?true:false,
      viewSelector:( (params!=undefined && ( params.userID!=undefined ||  params.parnerID!=undefined )) || params.disabledOldRace!=true)?true:false,
      userID:(params!=undefined && params.userID!=undefined)?params.userID:( (params!=undefined && params.partnerID!=undefined)?params.partnerID:getAuth(firebase).currentUser.uid ),
      dataURL: (params!=undefined && params.partnerID!=undefined)?/partnersRace/:"/usersRace/",
      adminView:(params!=undefined && params.adminView!=undefined)?params.adminView:undefined,
      notUser:(params!=undefined && ( params.userID!=undefined ||  params.partnerID!=undefined ))?true:false,
      enableDriverNameInRace:(params!=undefined && params.enableDriverNameInRace==true)?true:false,
      viewPDF:false,

      viewRaceInProgress:false,

    };


  }

  changeInterval(type){
    var date=new Date(this.state.dateStart);
    if(type=="before") date.setMonth(date.getMonth()-1);
    else date.setMonth(date.getMonth()+1);
    this.setState({dateStart:Date.parse(date)},() =>{this.getData()})
  }

  getData(){
    var startAtI;
    var endAtI; 
    var orderBy;
    this.setState({loading:true})

    if(this.state.mode=="invoiced") {
      var date= new Date(this.state.dateStart);
      date.setDate(1)
      date.setHours(0)
      date.setMinutes(0)

      var date2 = new Date(date);
      date2.setMonth(date2.getMonth()+1)
      startAtI=Date.parse(date)
        endAtI=Date.parse(date2)
      
    }
    else {
      var date = new Date();
      date.setDate(date.getDate()-1)
      startAtI=Date.parse(date);
      endAtI=4102444800000;
    }


    

    get(query(ref(getDatabase(firebase), this.state.dataURL + this.state.userID+"/"), orderByChild('raceDate'), startAt(startAtI), endAt(endAtI))).then(value => {
        var val=value.val();
        if(val!=null){
          var deferredList={}
          var valList=Object.keys(val);
          var i;
          if(this.state.mode=="future"){
            for (i=0;i<valList.length;i++){if(val[valList[i]].status=="invoiced") delete val[valList[i]];}
            valList=Object.keys(val);
          }
          else {
            for (i=0;i<valList.length;i++){
              if(info.disableBill==true && (new Date(val[valList[i]].raceDate)>new Date() || val[valList[i]].status=="waiting" ) ) delete val[valList[i]];
              else if(info.disableBill!=true && (val[valList[i]].status=="validate" || val[valList[i]].status=="waiting") ) delete val[valList[i]];

            }
            valList=Object.keys(val);

          }

          for (i=0;i<valList.length;i++){

            console.log(val[valList[i]])
            var date=new Date(val[valList[i]].raceDate)
            var date2=new Date(val[valList[i]].raceDate+1000*val[valList[i]].estimateTimeValue)

            val[valList[i]].date=((date.getDate()<10)?"0":"")+date.getDate()+"/"+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+"/"+date.getFullYear()
            val[valList[i]].date=strings.dayListComplete[ ((date.getDay()==0)?6:(date.getDay()-1))]+" "+date.getDate()+" "+strings.monthList[date.getMonth()]
            val[valList[i]].hour=((date.getHours()<10)?"0":"")+date.getHours()+":"+((date.getMinutes()<10)?"0":"")+date.getMinutes()
            val[valList[i]].hourDestination=((date2.getHours()<10)?"0":"")+date2.getHours()+":"+((date2.getMinutes()<10)?"0":"")+date2.getMinutes()
            if( (val[valList[i]].providerID!=undefined || val[valList[i]].customerPro!=undefined) && info.trackLink!=undefined) val[valList[i]].trackLink=(info.trackLink+"/?trackLink="+valList[i])
            if(val[valList[i]].attributedPartner!=undefined && val[valList[i]].attributedPartner.text!=undefined) val[valList[i]].driverInfo=val[valList[i]].attributedPartner.text
            if(val[valList[i]].providerID!=undefined && val[valList[i]].clientName!=undefined && val[valList[i]].clientName!="") val[valList[i]].clientInfo=val[valList[i]].clientName+" "+val[valList[i]].clientLastName;  

            if(val[valList[i]].deferredPaymentID!=undefined){
                if(deferredList[val[valList[i]].deferredPaymentID]==undefined) deferredList[val[valList[i]].deferredPaymentID]={billNumber:val[valList[i]].billNumber, list:[]}
                deferredList[val[valList[i]].deferredPaymentID].list.push(val[valList[i]])

              deferredList[val[valList[i]].deferredPaymentID].list.sort(function(a, b) {return (orderBy=="raceDate")?a.raceDate - b.raceDate:b.raceDate - a.raceDate;})

            }


          } 
          

          var val2=JSON.parse(JSON.stringify(val))
          var valProv={};
          var deferredListDef={};
          Object.keys(val2).sort(function(a, b){return (orderBy=="raceDate")?val2[a].raceDate - val2[b].raceDate:val2[b].raceDate - val2[a].raceDate }).forEach(function(key) {console.log(key); valProv[key] = val2[key];});
          
          if(Object.keys(deferredList).length>0) Object.keys(deferredList).sort(function(a, b){return (orderBy=="raceDate")?deferredList[a].billNumber - deferredList[b].billNumber:deferredList[b].billNumber - deferredList[a].billNumber }).forEach(function(key) {console.log(key); deferredListDef[key] = deferredList[key];});
          

          this.setState({raceList:valProv,deferredList:deferredListDef,loading:false}) 
        }
        else{this.setState({raceList:{},deferredList:{},loading:false }) } 
    
    })
  }


  sendMessage(val,id){
    var mess="Je vous contacte au sujet de la course du "+this.state.raceList[id].date+" à "+this.state.raceList[id].hour;
    var can=false
    if(val=="cancel") {mess+=". Je souhaiterai annuler cette course"; can=true};

    var requestModif=false;
    if(val=="requestModif") {
      requestModif=this.state.raceList[id]
      mess="";
    }

    
    if(Platform.OS=="web") this.state.params.navigate("Contact",{description:mess, cancel:can, id:id, requestModif:requestModif});
    else this.props.navigation.navigate("Contact",{description:mess, cancel:can, id:id, requestModif:requestModif});
  }

  componentDidMount() {
    this.getData();
  }

  viewPDFFile(link){
    var viewPDFFile="https://firebasestorage.googleapis.com/v0/b/"+info.storageBucket+"/o/bill%2F"+link+"%2Fbill.pdf?alt=media";
    if(Platform.OS==="web") Linking.openURL(viewPDFFile)
    else this.props.navigation.navigate("MavPDF",{mode:"viewer", url:viewPDFFile, title:strings.bill});

  }

  generatePDFInfo(rowData){

    var orderDate=new Date(this.state.raceList[rowData].orderDate)
    orderDate=((orderDate.getDate()<10)?"0":"")+orderDate.getDate()+"/"+(((orderDate.getMonth()+1)<10)?"0":"")+(orderDate.getMonth()+1)+"/"+orderDate.getFullYear()+" - "+((orderDate.getHours()<10)?"0":"")+orderDate.getHours()+":"+((orderDate.getMinutes()<10)?"0":"")+orderDate.getMinutes()

    var pdfInfo={
      placeStart:this.state.raceList[rowData].racePlaceStartName,
      date:this.state.raceList[rowData].date,
      orderDate:orderDate,
      estimateTimeValue:(Math.floor(this.state.raceList[rowData].estimateTimeValue/3600)<10?"0":"")+Math.floor(this.state.raceList[rowData].estimateTimeValue/3600)+":"+((Math.floor((this.state.raceList[rowData].estimateTimeValue-3600*Math.floor(this.state.raceList[rowData].estimateTimeValue/3600))/60)<10)?"0":"")+Math.floor((this.state.raceList[rowData].estimateTimeValue-3600*Math.floor(this.state.raceList[rowData].estimateTimeValue/3600))/60),
      roundTrip:(this.state.raceList[rowData].goReturnTaxi==true)?true:false,
      description:this.state.raceList[rowData].description,
      subTitle:"Voici un récapitulatif des informations "
    }

   


    if(this.state.raceList[rowData].trackLink!=undefined)  pdfInfo.tracklink=(info.trackLink+"/?trackLink="+rowData)


    if(this.state.raceList[rowData].racePlaceEndNameTest!=undefined && this.state.raceList[rowData].racePlaceEndNameTest!="") {pdfInfo.placeEnd=this.state.raceList[rowData].racePlaceEndName; pdfInfo.title="VOTRE COURSE"; pdfInfo.subTitle+="pour votre course"}
    else {pdfInfo.title="VOTRE MISE À DISPOSITION"; pdfInfo.subTitle+="pour votre mise à disposition"}

    if(pdfInfo.tracklink!=undefined) pdfInfo.subTitle+=". En flashant le QR code ci-dessous, vous pourrez suivre cette course dans votre navigateur";

    if(this.state.raceList[rowData].estimatePrice!=undefined && this.state.raceList[rowData].estimatePrice!="") pdfInfo.estimatePrice=this.state.raceList[rowData].estimatePrice;
    if(this.state.raceList[rowData].numFlight!=undefined && this.state.raceList[rowData].numFlight!="") pdfInfo.numFlight=this.state.raceList[rowData].numFlight;
    if(this.state.raceList[rowData].numTrain!=undefined && this.state.raceList[rowData].numTrain!="") pdfInfo.numTrain=this.state.raceList[rowData].numTrain;
    if(this.state.raceList[rowData].estimateKm!=undefined && this.state.raceList[rowData].estimateKm!="") pdfInfo.estimateKm=this.state.raceList[rowData].estimateKm;
    if(this.state.raceList[rowData].stopList!=undefined && this.state.raceList[rowData].stopList!="") pdfInfo.stopList=this.state.raceList[rowData].stopList;
    if(this.state.raceList[rowData].providerEnterprise!=undefined && this.state.raceList[rowData].providerEnterprise!="") pdfInfo.providerEnterprise=this.state.raceList[rowData].providerEnterprise;
    if(this.state.raceList[rowData].providerName!=undefined && this.state.raceList[rowData].providerName!="") pdfInfo.providerName=this.state.raceList[rowData].providerName;
    if(this.state.raceList[rowData].providerLastName!=undefined && this.state.raceList[rowData].providerLastName!="") pdfInfo.providerLastName=this.state.raceList[rowData].providerLastName;



    var passagers=[this.state.raceList[rowData].clientName+" "+this.state.raceList[rowData].clientLastName];
    if(this.state.raceList[rowData].passagersList!=undefined && this.state.raceList[rowData].passagersList!="" && this.state.raceList[rowData].passagersList.length>0){
      var i;
      for(i=0;i<this.state.raceList[rowData].passagersList.length;i++){
        passagers.push(this.state.raceList[rowData].passagersList[i].name+" "+this.state.raceList[rowData].passagersList.lastName)
      }
    }
    pdfInfo.passagers=passagers;

    if(this.state.raceList[rowData].desiredDriver!=undefined && this.state.raceList[rowData].desiredDriver!=""){
      pdfInfo.driver=this.state.raceList[rowData].desiredDriver.text;
    }
    if(this.state.raceList[rowData].carName!=undefined && this.state.raceList[rowData].carName!="") pdfInfo.carName=this.state.raceList[rowData].carName

      return pdfInfo

  }

  startChatBooking= (rowData) => {
   /*const style=styleInfo();
   if(Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax ){
                    this.setState({viewChat:true, controlePanelOpen:true});
                    if(Platform.OS!="web") this.props.navigation.setOptions({headerShown:false })
                }
                else */
  var race=JSON.parse(JSON.stringify(this.state.raceList[rowData]));

  this.props.navigation.navigate("Chat",{outRace:true,sender:race.attributedPartner.text,raceID:rowData,senderID:race.attributedPartner.id})



  }


  viewRaceInProgress= (rowData) => {

    onValue(ref(getDatabase(firebase), "race/"+rowData), (value) => {
        var val=value.val(); 
        if(val==null) val={status:"error"}
        else {
          if(val.attributedPartner!=undefined) val.picture="https://firebasestorage.googleapis.com/v0/b/"+info.firebaseProjectID+".appspot.com/o/partners%2F"+val.attributedPartner.id+"%2Fpicture.jpg?alt=media";
          var date=new Date(val.raceDate)
          var date2=new Date(val.raceDate+1000*val.estimateTimeValue)

          val.date=((date.getDate()<10)?"0":"")+date.getDate()+"/"+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+"/"+date.getFullYear()
          val.date=strings.dayListComplete[ ((date.getDay()==0)?6:(date.getDay()-1))]+" "+date.getDate()+" "+strings.monthList[date.getMonth()]
          val.hour=((date.getHours()<10)?"0":"")+date.getHours()+":"+((date.getMinutes()<10)?"0":"")+date.getMinutes()
          val.hourDestination=((date2.getHours()<10)?"0":"")+date2.getHours()+":"+((date2.getMinutes()<10)?"0":"")+date2.getMinutes()
        }
        
        val.raceID=rowData; 

        var calcArrivalDate=false; 
        if(val.raceInProgress==true && val.immediateMode=="driverNavigateToCustomer" && val.attributedPartner!=undefined && val.attributedPartner.coor!=undefined && val.attributedPartner.coor!=null) {
            if(this.state.viewRaceInProgress==false || this.state.viewRaceInProgress.lastArrivateDate==undefined || (this.state.viewRaceInProgress.lastArrivateDate-Date.parse(new Date())>180000) ) calcArrivalDate=true;
        }

        if(this.state.viewRaceInProgress!=false) {
          val.arrivalDate=this.state.viewRaceInProgress.arrivalDate;
          val.coordDriver=this.state.viewRaceInProgress.coordDriver;
          val.mapRegion=this.state.viewRaceInProgress.mapRegion;
          val.lastArrivateDate=this.state.viewRaceInProgress.lastArrivateDate;
        }
        




        this.setState({viewRaceInProgress:val},() =>{if(calcArrivalDate==true) this.calculateArrivalDate() })


    }); 

    

  }

  calculateArrivalDate= rowData => {

    console.log("calculateArrivalDate lancé")

    var fetchURLparams={origin:this.state.viewRaceInProgress.attributedPartner.coor.latitude +',' +this.state.viewRaceInProgress.attributedPartner.coor.longitude ,key: info.googleMapsKey,destination:this.state.viewRaceInProgress.racePlaceStartName}
        var fetchURL=Qs.stringify(fetchURLparams)
        var body={paramsRqs:fetchURL, type:"directions" }
        var getDir=fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getMapsInfo", {body: JSON.stringify(body),method: "POST"})

        getDir.then(response => response.json())
              .then(responseJson => {
                if(typeof(responseJson)!="object") responseJson=JSON.parse(responseJson);
                if (responseJson.status != 'ZERO_RESULTS' && responseJson.routes.length){
                  var duration=responseJson.routes[0].legs[0].duration.value;
                  var calcArrivalDate=new Date(this.state.viewRaceInProgress.attributedPartner.lastPositionDate+duration*1000);
                  calcArrivalDate=((calcArrivalDate.getHours()<10)?"0":"")+ calcArrivalDate.getHours()+":"+((calcArrivalDate.getMinutes()<10)?"0":"")+ calcArrivalDate.getMinutes()
                  var viewRaceInProgress=this.state.viewRaceInProgress;
                  viewRaceInProgress.arrivalDate=calcArrivalDate;

                  var coordDriver=this.decode(responseJson.routes[0].overview_polyline.points)
                  viewRaceInProgress.coordDriver=coordDriver;


                  var mapRegion={}
                  mapRegion.latitude=this.state.viewRaceInProgress.attributedPartner.coor.latitude+((coordDriver[coordDriver.length-1].latitude-this.state.viewRaceInProgress.attributedPartner.coor.latitude)/2);
                  mapRegion.longitude=this.state.viewRaceInProgress.attributedPartner.coor.longitude+((coordDriver[coordDriver.length-1].longitude-this.state.viewRaceInProgress.attributedPartner.coor.longitude)/2);
                  mapRegion.latitudeDelta=Math.abs(coordDriver[coordDriver.length-1].latitude-this.state.viewRaceInProgress.attributedPartner.coor.latitude)*3+0.02;
                  mapRegion.longitudeDelta=Math.abs(coordDriver[coordDriver.length-1].longitude-this.state.viewRaceInProgress.attributedPartner.coor.longitude)*3+0.02;
                  viewRaceInProgress.mapRegion=mapRegion
                  viewRaceInProgress.lastArrivateDate=Date.parse(new Date())
                  this.setState({viewRaceInProgress:viewRaceInProgress, });

                }
              })

  }

  decode(t, e) {
          for (
            var n,
              o,
              u = 0,
              l = 0,
              r = 0,
              d = [],
              h = 0,
              i = 0,
              a = null,
              c = Math.pow(10, e || 5);
            u < t.length;

          ) {
            (a = null), (h = 0), (i = 0);
            do
              (a = t.charCodeAt(u++) - 63), (i |= (31 & a) << h), (h += 5);
            while (a >= 32);
            (n = 1 & i ? ~(i >> 1) : i >> 1), (h = i = 0);
            do
              (a = t.charCodeAt(u++) - 63), (i |= (31 & a) << h), (h += 5);
            while (a >= 32);
            (o = 1 & i ? ~(i >> 1) : i >> 1), (l += n), (r += o), d.push([
              l / c,
              r / c,
            ]);
          }
          return (d = d.map(function(t) {
            return { latitude: t[0], longitude: t[1], lat:t[0], lng:t[1] };
          }));
  }


  generatePDF= rowData => {
    
    
    var pdfInfo=this.generatePDFInfo(rowData)
    this.props.navigation.navigate("MavPDF",{mode:"generate", info:pdfInfo, title:strings.race});
    

  }

  render() {

    return (
      <StyleContext.Consumer>{style => 
      <View style={{flex:1}}>
          {(Platform.OS=="web") && <MavNavigationMinimize clickLeft={() => { this.state.params.goBack();  }} left={strings.return} title={ strings.myOldRace } />}

      <ScrollView style={{flex:1,backgroundColor:style.backgroundColor}}> 

      {(this.state.viewSelector==true) && <MavSelector style={{marginBottom:10}} selected={(this.state.mode=="future")?0:1 } textLeft={strings.comming} textRight={strings.old} onChange={(value)=>{ 
          this.setState(() => ({mode:(value==0)?"future":"invoiced"}),function(){ this.getData()}) }}
      />}

      {this.state.loading==true && <ActivityIndicator style={{marginTop:15}} size="large" color={style.color1} />}

      {(this.state.loading==false && this.state.mode=="invoiced") && <View style={{ height: 50, marginBottom:10, backgroundColor: style.colorDark,flexDirection: 'row', }}>
         
          <View style={{flex: 1,flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center',marginLeft: "10%",marginRight: "10%"}}>
            <TouchableHighlight accessibilityLabel={strings.previousMonth} underlayColor="rgba(0,0,0,0)" onPress={() => {this.changeInterval("before");}}>
              <Image source={require('../assets/revenue_before.png')} style={{ width: 30, height: 30 }} />
            </TouchableHighlight>

            <View style={{flex: 1,width: Dimensions.get('window').width * 0.6,}}>
                <Text allowFontScaling={false} style={{textAlign: 'center',fontSize: style.textInputFontSize*1.2,color: '#ffffff', }}>{strings.monthList[new Date(this.state.dateStart).getMonth()]+" "+(new Date(this.state.dateStart).getFullYear())}</Text>
                {Object.keys(this.state.deferredList).length>0 && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={() => {this.setState({deferredList:{} })  }}>
                    <Text allowFontScaling={false} style={{textAlign: 'center',fontSize: style.textInputFontSize*0.8,color: '#ffffff', }}>{strings.viewRace}</Text>
                </TouchableHighlight>}
              </View>
            
            <TouchableHighlight accessibilityLabel={strings.nextMonth}  underlayColor="rgba(0,0,0,0)" onPress={() => {this.changeInterval("after")}}>
              <Image source={require('../assets/revenue_next.png')} style={{ width: 30, height: 30 }} />
            </TouchableHighlight>
          </View>

      </View>}


      {(this.state.loading==false && Object.keys(this.state.raceList).length==0) && <Text style={{marginTop:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.noElement}</Text> }

      {(this.state.loading==false && Object.keys(this.state.deferredList).length==0) && Object.keys(this.state.raceList).map(rowData=>(
         <View style={{alignItems:"center", }}>

            <BookingView  mode={this.state.mode} viewRaceInProgress={() => { this.viewRaceInProgress(rowData) }} enableDriverNameInRace={this.state.enableDriverNameInRace} data={this.state.raceList[rowData]} style={{width:"80%"}}/>
            
            <View style={{width:"100%"}}>
            {((this.state.raceList[rowData].status!="invoiced" || this.state.raceList[rowData].limited!=true) && this.state.userID==getAuth(firebase).currentUser.uid) && <MavViewMore text={strings.options}  >

                <View style={{marginLeft:"8%"}}>


                    { (this.state.raceList[rowData].status=="validate" && Platform.OS!="web") && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ this.generatePDF(rowData) }}> 
                        <MavTextInput iconClassicSmall={true} marginSize={5} textInputFlex={2} backgroundColor={style.backgroundColorContrast} textExplication={strings.generatePDFRecap} icon={(style.mode=="dark")?require('../assets/pdf_black.png'):require('../assets/pdf_white.png')} disabledText={true}/>
                    </TouchableHighlight>}

                    
                    { (this.state.mode!="invoiced" && Platform.OS!="web" && this.state.raceList[rowData].status=="validate" && this.state.raceList[rowData].attributedPartner!=undefined) && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ this.startChatBooking(rowData)  }}> 
                        <MavTextInput iconClassicSmall={true} marginSize={5} textInputFlex={2} backgroundColor={style.backgroundColorContrast} textExplication={strings.contactDriver} icon={(style.mode=="dark")?require('../assets/help_black.png'):require('../assets/help_white.png')} disabledText={true}/>
                    </TouchableHighlight>}

                    { (this.state.notUser==false) && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ this.sendMessage("contact",rowData)  }}> 
                        <MavTextInput iconClassicSmall={true} marginSize={5} textInputFlex={2} backgroundColor={style.backgroundColorContrast} textExplication={strings.contactPlateform} icon={(style.mode=="dark")?require('../assets/driver_mail_dark.png'):require('../assets/driver_mail_light.png')} disabledText={true}/>
                    </TouchableHighlight>}

                    {(this.state.raceList[rowData].status=="invoiced" && (this.state.raceList[rowData].deferredPayment!=true || this.state.raceList[rowData].deferredPaymentID!=undefined)) && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ var link=(this.state.raceList[rowData].deferredPaymentID!=undefined)?this.state.raceList[rowData].deferredPaymentID:rowData; this.viewPDFFile(link)  }}> 
                        <MavTextInput iconClassicSmall={true} marginSize={5} textInputFlex={2} backgroundColor={style.backgroundColorContrast} textExplication={strings.seeOneBill} icon={(style.mode=="dark")?require('../assets/driver_pay_dark.png'):require('../assets/driver_pay_light.png')} disabledText={true}/>
                    </TouchableHighlight>}

                    {(this.state.raceList[rowData].status!="invoiced" && this.state.raceList[rowData].trackLink!=undefined) && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{  var qrInfo={url:this.state.raceList[rowData].trackLink }; if(this.state.raceList[rowData].clientPhone!="" && this.state.raceList[rowData].clientPhone!=undefined) qrInfo.phone=this.state.raceList[rowData].clientPhone; this.qrCode.onPicker(qrInfo);  }}> 
                        <MavTextInput iconClassicSmall={true} marginSize={5} textInputFlex={2} backgroundColor={style.backgroundColorContrast} textExplication={strings.trackLink} icon={(style.mode=="dark")?require('../assets/route_dark.png'):require('../assets/route_light.png')} disabledText={true}/>
                    </TouchableHighlight>}
                    
                    { (this.state.disableCancelButton==false && this.state.mode!="invoiced" && this.state.notUser==false && this.state.raceList[rowData].status=="validate" && this.state.raceList[rowData].reqMod!=true ) && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ this.sendMessage("requestModif",rowData) }}> 
                          <MavTextInput iconClassicSmall={true} marginSize={5} textInputFlex={2} backgroundColor={style.backgroundColorContrast} textExplication={strings.requestModif} icon={(style.mode=="dark")?require('../assets/modify_light.png'):require('../assets/modify_dark.png')} disabledText={true}/>
                      </TouchableHighlight>} 

                    { (this.state.disableCancelButton==false && this.state.mode!="invoiced" && this.state.notUser==false && (this.state.raceList[rowData].status=="waiting" || this.state.raceList[rowData].status=="validate") ) && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ this.alert.onAlert({title:strings.cancelRace,subTitle:strings.sureCancelRace,buttons:[{text: strings.yes,onPress: () => {this.sendMessage("cancel",rowData)},},{text: strings.no,onPress: () => {},style: 'cancel',},]});}}> 
                          <MavTextInput iconClassicSmall={true} marginSize={5} textInputFlex={2} backgroundColor={style.backgroundColorContrast} textExplication={strings.cancelRace} icon={(style.mode=="dark")?require('../assets/driver_cancel_dark.png'):require('../assets/driver_cancel_light.png')} disabledText={true}/>
                      </TouchableHighlight>}

                      { (this.state.mode=="invoiced") && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ this.viewRaceInProgress(rowData) }}> 
                          <MavTextInput iconClassicSmall={true} marginSize={5} textInputFlex={2} backgroundColor={style.backgroundColorContrast} textExplication={strings.raceRecap} icon={(style.mode=="dark")?require('../assets/driver_info_dark.png'):require('../assets/driver_info_light.png')} disabledText={true}/>
                      </TouchableHighlight>} 




                </View>

            </MavViewMore>}


            { (this.state.mode=="invoiced" && ((this.state.userID==getAuth(firebase).currentUser.uid && this.state.raceList[rowData].limited==true) || this.state.adminView==true))  && <TouchableHighlight style={{marginTop:10}} underlayColor="rgba(0,0,0,0)" onPress={()=>{ this.viewRaceInProgress(rowData) }}> 
                <MavTextInput iconClassicSmall={true} style={{marginLeft:"8%", marginRight:"10%"}} marginSize={5} textInputFlex={2} textExplication={strings.raceRecap} icon={(style.mode=="dark")?require('../assets/driver_info_dark.png'):require('../assets/driver_info_light.png')} disabledText={true}/>
            </TouchableHighlight>} 

            {(this.state.adminView==true && this.state.raceList[rowData].status=="invoiced" && (this.state.raceList[rowData].deferredPayment!=true || this.state.raceList[rowData].deferredPaymentID!=undefined)) && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ var link=(this.state.raceList[rowData].deferredPaymentID!=undefined)?this.state.raceList[rowData].deferredPaymentID:rowData; this.viewPDFFile(link)  }}> 
                <MavTextInput iconClassicSmall={true} style={{marginLeft:"8%", marginRight:"10%"}} marginSize={5} textInputFlex={2} textExplication={strings.seeOneBill} icon={(style.mode=="dark")?require('../assets/driver_pay_dark.png'):require('../assets/driver_pay_light.png')} disabledText={true}/>
            </TouchableHighlight>}



            {(!((this.state.raceList[rowData].status!="invoiced" || this.state.raceList[rowData].limited!=true) && this.state.userID==getAuth(firebase).currentUser.uid)) && <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,marginTop: 10,marginLeft:"10%",marginRight:"10%",}}/>}

            

            <View style={{height:15, width:10}}/> 
            </View>



            


            
 

         </View> 

      ))}


      {Object.keys(this.state.deferredList).map((rowData,i)=>(
          <View style={{width:"80%", marginLeft:"10%"}}> 

              <View style={{justifyContent: 'space-between',flexDirection: 'row',marginBottom:10,alignItems:"center", marginTop:(Platform.OS=="web" && this.props.close!=undefined)?10:undefined }}>
                  <View>
                      <Text style={{ fontSize:style.textInputFontSize*1.1,color:style.textInputColor, fontStyle: 'italic',}}>{strings.bill.toUpperCase() +" "+this.state.deferredList[rowData].billNumber}</Text>
                      <Text style={{ fontSize:style.textInputFontSize*0.9,color:style.textInputColor,fontWeight: 'bold',}}>{this.state.deferredList[rowData].list.length+" "+((this.state.deferredList[rowData].list.length>1)?strings.races.toLowerCase():strings.race.toLowerCase()) }</Text>
                  </View>

                  <View style={{alignItems:"center"}} >
                    <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{this.viewPDFFile(rowData) }}>
                      <Image source={(style.mode=="dark")?require('../assets/download_dark.png'):require('../assets/download_light.png')}  style={{resizeMode:"contain", width: 30, height: 25, marginBottom:5 }} />
                    </TouchableHighlight>
                  </View>

              </View>

              {this.state.deferredList[rowData].list.map(raceI=>(
                  <View style={{marginBottom:10, flexDirection: 'row',alignItems:"center"}}>
                      <View style={{width:20, height:20,borderRadius: 10, backgroundColor:style.color1,marginRight:5 }}/>
                      <Text style={{ fontSize:style.textInputFontSize,color:style.textInputColor}}>{raceI.date+" - "+raceI.hour}</Text>
                  </View>
              ))}


            {(i<Object.keys(this.state.deferredList).length-1) && <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,marginTop: 10,marginBottom:10,width:"100%"}}/>}

              




          </View>
      ))}


       

            
     
      <MavQRCode ref={qrCode => {this.qrCode = qrCode;}} />
      <MavAlert ref={alert => {this.alert = alert;}}/>

      
      <Modal animationType="none" transparent={true} visible={(this.state.viewRaceInProgress!=false)}>
          <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",backgroundColor:(Dimensions.get('window').width>1000)?"rgba(0,0,0,0.7)": style.backgroundColor, alignItems:"center",justifyContent:"center" }}>

          <ScrollView style={{height:Dimensions.get('window').height*((Dimensions.get('window').width>1000)?0.92:1),width:((Dimensions.get('window').width>1000)?550:Dimensions.get('window').width*0.8),position:"absolute",marginLeft:((Dimensions.get('window').width>1000)?undefined:"10%"), backgroundColor:style.backgroundColor}}>
               {Platform.OS=="web" && <View style={{width:"100%", height:30, alignItems:"center", justifyContent: 'space-between',flexDirection: 'row'}}> 
                   <Text style={{ fontSize: style.textInputFontSize*1.1,color:style.textInputColor, marginLeft:10, fontWeight: 'bold' }}>{strings.raceInProgress} </Text>
                  
                  <TouchableHighlight underlayColor={"#00000000"} onPress={() => { this.setState({viewRaceInProgress:false}) }} >
                      <Image source={(style.mode=="dark")?require('../assets/driver_cancel_light.png'):require('../assets/driver_cancel_dark.png')} style={{ width: 20, height: 20, marginRight:10 }} />
                  </TouchableHighlight>
                </View>}
              {Platform.OS=="web" && <View style={{width:"100%",height:1, backgroundColor:style.textInputLineColor, shadowOpacity: 0.2,shadowRadius: 2,shadowOffset: {height: 2,width: 0},elevation: 1}} />}

              <BookingView  mode={"liveBooking"} close={() => { this.setState({viewRaceInProgress:false}) }} data={this.state.viewRaceInProgress} style={{marginLeft:(Dimensions.get('window').width>1000)?45:undefined,width:((Dimensions.get('window').width>1000)?460:Dimensions.get('window').width*0.8)}}/>
          </ScrollView>

          </View>
      </Modal>

    </ScrollView>
    </View>
    }</StyleContext.Consumer>
    );
  }
}




