import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  ActivityIndicator,
  Dimensions,
  Platform,
  Linking,
  Image,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import firebase from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

import MavViewMore from './mavViewMore';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MavMiniButton from './mavMiniButton';

import MavAlert from './mavAlert';
import MavInfoBubble from './mavInfoBubble';
import languageList from '../languageList';
import MavSelectDate from './mavSelectDate';
import moneyList from '../moneyList';

const timer = require('react-native-timer');
class HeaderLeft extends Component {
render() {
    return (
      <StyleContext.Consumer>{style => 
        <View />
      }</StyleContext.Consumer>
      )}
  }
export default class help extends Component {

 
  
  constructor(props) {
    super(props);



    this.state = {
      waiting:false,
      seeStats:false,
      seeHelp:false,
      offer:"",
      numberPartner:0,
      billList:[],
      viewTrajet:"",

      dateStart:"",
      dateEnd:"",
      dateStartInteger:-1,
      dateEndInteger:-1, 
      updateWaiting:true,
      dataToView:false,
      topPlace:[],
      topPlaceViewMore:false,
      seeNotif:false,
      topPlaceView:"total",
      topPlaceViewDetail:-1,
      topPlaceColor:{start:"#669C35", end:"#CE2C19", intermediate:"#F28E02",total:"#0DA7A9" },


      topClients:[],
      topClientsViewMore:false,
      topClientsView:"total",
      topClientsColor:{client:"#669C35", provider:"#CE2C19", pro:"#F28E02",total:"#0DA7A9" },


      topDrivers:[],
      topDriversViewMore:false,

      notificationWaiting:false,
      newNotification:{},
      oldNotifications:[],




    };
  }



  componentDidMount() {
    if(Platform.OS!="web") this.props.navigation.setOptions({ title:"Plus d'infos", headerLeft: props => <HeaderLeft goBack={this.goBack} /> })




    



    getAuth(firebase).currentUser.getIdToken().then(token => {
          var body={
              userID:getAuth(firebase).currentUser.uid,
              bundleIdentifer:info.bundleIdentifer,
              bundleAdress:info.firebaseProjectID,
              token:token,
          }


          if(false) fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getMavInfos", {
                  body:JSON.stringify(body),
                  headers: {"Content-Type": "application/json"},method: "POST"}).then((response) => response.json())
                  .then((responseJson) => {
                    console.log("tout est ok")
                    console.log(responseJson)
                    
                    var ans=responseJson;
                    var i;
                    var bl=[]
                    for(i=0;i<ans.billList.length;i++){
                      bl.push({url:ans.billList[i].url,date:new Date(ans.billList[i].date)})
                    }


                    this.setState({offer:ans.offer,numberPartner:ans.numberPartner,billList:bl,waiting:false})
                  
                  }).catch((error) => {console.log(error) })

      }) 

  }


 
  changeDateStart= (value) => {
      this.changeDate(value, true); 
      this.changeDateSt.close()
  }
  changeDateEnd= (value) => {
      this.changeDate(value, false)
      this.changeDateEd.close()
  }

  changeDate= (value, start) => {
    var dateCalc=new Date(value.date);
    var dateTxt=((dateCalc.getDate())<10?"0":"")+   (dateCalc.getDate())+"/"+((dateCalc.getMonth()+1)<10?"0":"")+   (dateCalc.getMonth()+1)+"/"+dateCalc.getFullYear()
    dateCalc.setMinutes(0);
    dateCalc.setHours(2); 
    dateCalc=Date.parse(dateCalc);
    if(start==true) this.setState({dateStart:dateTxt, dateStartInteger:dateCalc, updateWaiting:true});  
    else this.setState({dateEnd:dateTxt, dateEndInteger:dateCalc,updateWaiting:true, topPlaceViewMore:false,topPlaceView:"total"});
  }

  findLangue= (id) => {
      var final={};
      if(id==undefined) id=strings.idLangue;
      var i; 
      for(i=0; i<languageList.length;i++){
        if(languageList[i].id==id){
            final=languageList[i]
        }
      }

      return final;
    
  }


  viewLangue= (id) => {

      var selected=-1;
      var i;
      var list=[]
      for(i=0;i<languageList.length;i++){
          if(languageList[i].id==this.state.newNotification.idLangue) selected=i;
          list.push({id:languageList[i].id, text:languageList[i].name})
      }
      
     
      this.alert.onAlert({title:strings.langue, items:list ,itemSelected:selected, buttons:[{text: strings.validate,onPress: (id,value) => { 

        var selectedLangue=this.findLangue(languageList[id].id);
        console.log(selectedLangue)
        var newNotification=JSON.parse(JSON.stringify(this.state.newNotification))
        newNotification.idLangue=selectedLangue.id;newNotification.flagLangue=selectedLangue.flag;newNotification.nameLangue=selectedLangue.name;
        this.setState({newNotification:newNotification})

       }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })  

  }

  sendNotification= () => {

    var notif=JSON.parse(JSON.stringify(this.state.newNotification));
    if(notif.title=="" || notif.subTitle=="" || Object.keys(notif.target).length==0) this.alert.onAlert({title:strings.thanksAllItems });
    else {
      console.log("on envoi la notification")
      delete notif.flagLangue; 
      delete notif.nameLangue;
      notif.date=Date.parse(new Date())
      const fireInfo={} 
      fireInfo['/adsNotification/'+generateUUID()]=notif
      update(ref(getDatabase(firebase)), fireInfo)


      this.setState({notificationWaiting:true}); 
      timer.setTimeout("notifWaitingFalse", () => {this.getOldNotification();  this.alert.onAlert({title:strings.notifHasBeenSend }); }, 2500)
      
      


    }

  }

  findMoney(value){
    if(value==undefined) value="eu";
    var final="";
    var i; 
    for(i=0;i<moneyList.length;i++){
      if(final==""){
        if(moneyList[i].id==value) final=moneyList[i];
      }
    }
    return final
  }

  getOldNotification= () => {
    console.log("getOldNotification start")
    this.setState({notificationWaiting:true}); 
    get(query(ref(getDatabase(firebase), "/adsNotification"), orderByChild('date'))).then(value => {
      var val=value.val();
      
      if(val!=null) {

        var list=Object.keys(val).sort( function ( a, b ) { return val[b].date - val[a].date  } )
        var oldNotifications=[];
        var i; 
        for(i=0; i<list.length;i++){
          oldNotifications.push(val[list[i]])
        }

        this.setState({oldNotifications:oldNotifications});

      }
        
      var selectedLangue=this.findLangue();
    
      
      this.setState({notificationWaiting:false, newNotification:{idLangue:selectedLangue.id, flagLangue:selectedLangue.flag, nameLangue:selectedLangue.name, target:{}, title:"", subTitle:""}}); 

    })
  }

  getStatsInfo= (value) => {

    this.setState({waitingStatsInfo:true});
    get(query(ref(getDatabase(firebase), '/race'), orderByChild('raceDate'), startAt(this.state.dateStartInteger), endAt(this.state.dateEndInteger))).then(value => {

      var val=value.val();


      if(val==null || Object.keys(val).length<20) {this.alert.onAlert({title:strings.thanksIntervalHigh }); this.setState({waitingStatsInfo:false});}
      else {
        

        var list=Object.keys(val);
        var i,j;
        var topPlace={};
        var topClients={};
        var topDrivers={};
        var maxTopClients={total:0, client:0, provider:0, pro:0}
        var maxTopDrivers={total:0}
        var orderByDay=[0,0,0,0,0,0,0]
        var caByDay=[0,0,0,0,0,0,0]
        var orderByHour=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        var caByHour=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        var carNameList={}; 

        

        var caInfo={min:1000000000000, max:0, total:0, totalPromo:0, totalPromoPermanente:0, num:0}
        var km={min:1000000000000, max:0, total:0}

        var kmC;
        var price;


        var moneyID=(val[list[0]]!=undefined && val[list[0]].moneyID!=undefined)?val[list[0]].moneyID:"eu";
        var money=this.findMoney(moneyID)

        for(i=0;i<list.length;i++){
            /*console.log(val[list[i]])*/
            var date=new Date(val[list[i]].raceDate)
            var getDay=date.getDay() -1; 
            if(getDay==-1) getDay=6
            orderByDay[getDay]+=1;
            orderByHour[date.getHours()]+=1;

            if(val[list[i]].carName!=undefined && carNameList[val[list[i]].carName]==undefined){
              carNameList[val[list[i]].carName]={name:val[list[i]].carName, number:1};
            } else if(val[list[i]].carName!=undefined){
              carNameList[val[list[i]].carName].number+=1; 
            }

            price=((val[list[i]].finalPrice!=undefined)?val[list[i]].finalPrice:val[list[i]].estimatePrice).toString()
            price=price.replace('€', '').replace('$', '').replace('CHF', '').replace('XPF', '').replace('£', '').replace('MAD', '').replace('DA', '').replace('pyб', '').replace('CFA', '').replace('RMB', '').replace('¥', '')
            price=parseFloat(price); 
            if(!isNaN(price)){
              caInfo.num++;
              if(price<caInfo.min && price>0) caInfo.min=price;
              if(price>caInfo.max) caInfo.max=price;
              caInfo.total+=price;
              
              if(val[list[i]].promoCode!=undefined){
                  if(val[list[i]].promoCode.codeID=="PERMANENT") caInfo.totalPromoPermanente+=price;
                  else caInfo.totalPromo+=price;
              }

              caByDay[getDay]+=price; 
              caByHour[date.getHours()]+=price; 

            }

            if(val[list[i]].estimateKm!=undefined){
              kmC=val[list[i]].estimateKm;
              kmC=kmC.replace(',', '.'); 
              kmC=parseFloat(kmC);
            }
            else kmC=0; 
            

            if(!isNaN(kmC)){
              if(kmC<km.min && kmC>0) km.min=kmC;
              if(kmC>km.max) km.max=kmC;
              km.total+=kmC;
            }




            maxTopClients.total+=1;

            if(val[list[i]].providerID!=undefined){ 
                if(val[list[i]].customerPro===true) maxTopClients.pro+=1;
                else maxTopClients.provider+=1;

                if(topClients[val[list[i]].providerID]==undefined) topClients[val[list[i]].providerID]={total:1, name:val[list[i]].providerName+" "+val[list[i]].providerLastName, provider:(val[list[i]].customerPro===true)?false:true, pro:(val[list[i]].customerPro===true)?true:false }
                else {topClients[val[list[i]].providerID].total+=1; if(val[list[i]].customerPro===true && topClients[val[list[i]].providerID].pro!=true) topClients[val[list[i]].providerID].pro=true;  if(val[list[i]].customerPro!=true && topClients[val[list[i]].providerID].provider!=true) topClients[val[list[i]].providerID].provider=true; } 
            }
            else if(val[list[i]].clientID!=undefined){
                maxTopClients.client+=1;
                if(topClients[val[list[i]].clientID]==undefined) topClients[val[list[i]].clientID]={total:1, name:val[list[i]].clientName+" "+val[list[i]].clientLastName}
                else topClients[val[list[i]].clientID].total+=1;

            }

            if(val[list[i]].attributedPartner!=undefined){ 
              maxTopDrivers.total+=1;
              if(topDrivers[val[list[i]].attributedPartner.id]==undefined) topDrivers[val[list[i]].attributedPartner.id]={total:1, name:val[list[i]].attributedPartner.text}
              else topDrivers[val[list[i]].attributedPartner.id].total+=1;




            }
            

            

  
            if(topPlace[val[list[i]].racePlaceStartName]==undefined) topPlace[val[list[i]].racePlaceStartName]={total:1, start:1, end:0, intermediate:0}
            else {topPlace[val[list[i]].racePlaceStartName].total=topPlace[val[list[i]].racePlaceStartName].total+1; topPlace[val[list[i]].racePlaceStartName].start=topPlace[val[list[i]].racePlaceStartName].start+1;   }
            
            if(topPlace[val[list[i]].racePlaceEndName]==undefined) topPlace[val[list[i]].racePlaceEndName]={total:1, end:1, start:0, intermediate:0}
            else {topPlace[val[list[i]].racePlaceEndName].total=topPlace[val[list[i]].racePlaceEndName].total+1; topPlace[val[list[i]].racePlaceEndName].end=topPlace[val[list[i]].racePlaceEndName].end+1;   }
            
            if(val[list[i]].stopList!=undefined){
              for(j=0;j<val[list[i]].stopList.length;j++){

                if(topPlace[val[list[i]].stopList[j]]==undefined) topPlace[val[list[i]].stopList[j]]={total:1, intermediate:1, start:0, end:0}
                else {topPlace[val[list[i]].stopList[j]].total=topPlace[val[list[i]].stopList[j]].total+1; topPlace[val[list[i]].stopList[j]].intermediate=topPlace[val[list[i]].stopList[j]].intermediate+1;   }
               
              }

            }


        }

        
        
        





        
        var topPlaceOK=[]
        var maxTopPlace={total:0, start:0, end:0, intermediate:0}

        var topPlaceList=Object.keys(topPlace).sort( function ( a, b ) { return topPlace[b].total - topPlace[a].total  } )
        for(i=0;i<topPlaceList.length;i++){

          if(topPlace[topPlaceList[i]].total>maxTopPlace.total) maxTopPlace.total=topPlace[topPlaceList[i]].total;  
          if(topPlace[topPlaceList[i]].start>maxTopPlace.start) maxTopPlace.start=topPlace[topPlaceList[i]].start; 
          if(topPlace[topPlaceList[i]].end>maxTopPlace.end) maxTopPlace.end=topPlace[topPlaceList[i]].end;  
          if(topPlace[topPlaceList[i]].intermediate>maxTopPlace.intermediate) maxTopPlace.intermediate=topPlace[topPlaceList[i]].intermediate;   

          topPlace[topPlaceList[i]].name= topPlaceList[i];   
          topPlaceOK.push(topPlace[topPlaceList[i]])  
        }


        var topClientsOK=[]
        var topClientsList=Object.keys(topClients).sort( function ( a, b ) { return topClients[b].total - topClients[a].total  } )
        for(i=0;i<topClientsList.length;i++){
          topClientsOK.push(topClients[topClientsList[i]])  

        }

        var topDriversOK=[]
        var topDriversList=Object.keys(topDrivers).sort( function ( a, b ) { return topDrivers[b].total - topDrivers[a].total  } )
        for(i=0;i<topDriversList.length;i++){
          topDriversOK.push(topDrivers[topDriversList[i]])  

        }

        var orderByDayMax=0;
        for(i=0;i<orderByDay.length;i++){
          if(orderByDay[i]>orderByDayMax) orderByDayMax=orderByDay[i]
        }

        var caByDayMax=0;
        for(i=0;i<caByDay.length;i++){
          if(caByDay[i]>caByDayMax) caByDayMax=caByDay[i]
        }


      

        var orderByHourMax=0;
        for(i=0;i<orderByHour.length;i++){
          if(orderByHour[i]>orderByHourMax) orderByHourMax=orderByHour[i]
        }

        var caByHourMax=0;
        for(i=0;i<caByHour.length;i++){
          if(caByHour[i]>caByHourMax) caByHourMax=caByHour[i]
        }

      
        if(caInfo.min==1000000000000) caInfo.min=0;
        caInfo.moy=parseFloat(caInfo.total/caInfo.num)
        if(caInfo.km==1000000000000) caInfo.km=0;
        km.moy=parseFloat(km.total/caInfo.num)

        console.log(carNameList)
        var carNameFinal=[]; 
        var carNameMax=0; 

        var carNameListArray=Object.keys(carNameList).sort( function ( a, b ) { return carNameList[b].number - carNameList[a].number  } )
        for(i=0; i<carNameListArray.length;i++){
          if(carNameList[carNameListArray[i]].number>carNameMax) carNameMax=carNameList[carNameListArray[i]].number; 
          carNameFinal.push(carNameList[carNameListArray[i]])
        }

        /*je vais regarder s'il y a des nouveaux comptes sur la période*/

        get(query(ref(getDatabase(firebase), '/users'), orderByChild('creationDate'), startAt(this.state.dateStartInteger), endAt(this.state.dateEndInteger))).then(valueUsers => {

            var valUsers=valueUsers.val();

            var listUsers=[]; 

            if(valUsers!=undefined && valUsers!=null){
                var valUsersObj=Object.keys(valUsers); 
                for(i=0; i<valUsersObj.length;i++){

                    listUsers.push({name:(valUsers[valUsersObj[i]].enterprise!=undefined && valUsers[valUsersObj[i]].enterprise!="")?valUsers[valUsersObj[i]].enterprise:(valUsers[valUsersObj[i]].name+" "+valUsers[valUsersObj[i]].lastName), date: new Date(valUsers[valUsersObj[i]].creationDate).getDate()+" "+strings.monthList[new Date(valUsers[valUsersObj[i]].creationDate).getMonth()].toLowerCase()+" "+new Date(valUsers[valUsersObj[i]].creationDate).getFullYear() })
                }

            }

            console.log(listUsers)


       
            this.setState({listUsers:listUsers, carName:carNameFinal,carNameMax:carNameMax, km:km,caInfo:caInfo,money:money,caByHour:caByHour, caByHourMax:caByHourMax, caByDay:caByDay, caByDayMax:caByDayMax, waitingStatsInfo:false,topDrivers:topDriversOK,orderByDay:orderByDay, orderByDayMax:orderByDayMax,orderByHour:orderByHour,orderByHourMax:orderByHourMax, topClients:topClientsOK,topClientsSave:topClientsOK, updateWaiting:false, dataToView:true,maxTopClients:maxTopClients, topPlace:topPlaceOK, maxTopPlace:maxTopPlace, maxTopDrivers:maxTopDrivers, topPlaceView:"total", topClientsView:"total", topPlaceViewDetail:-1});

          })


      }

    })


  }

  viewPDFFile(link){
    if(Platform.OS==="web") Linking.openURL(link)
    else this.props.navigation.navigate("MavPDF",{mode:"viewer", url:link, title:"Guide de démarrage"});
  }

  changeTopPlaceView= (value) => {
      if(this.state.topPlaceView!=value){
          var topPlaceOK=[]
          var list=JSON.parse(JSON.stringify(this.state.topPlace) )
          var topPlaceList=list.sort( function ( a, b ) { return b[value] - a[value]  } )
          
           this.setState({topPlaceOther:topPlaceList, topPlaceViewDetail:-1,topPlaceView:value,topPlaceViewMore:false })

      }
        

  }


  changeTopClientsView= (value) => {
      var i;
      if(this.state.topClientsView!=value){
          var topClientsOK=[]
          var list=JSON.parse(JSON.stringify(this.state.topClientsSave) )
          var listOK=[];
          
          if(value!="total"){
            for(i=0;i<list.length;i++){
              if(value=="pro" && list[i].pro==true) listOK.push(list[i])
              else if(value=="provider" && list[i].provider==true) listOK.push(list[i])
              else if(value=="client" && list[i].provider!=true && list[i].pro!=true) listOK.push(list[i])

            }
          }
          else listOK=list;

          var topClientsList=listOK.sort( function ( a, b ) { return b.total - a.total  } )          
          this.setState({topClients:topClientsList, topClientsView:value,topClientsViewMore:false })

      }
        

  }

  seeStats= (value) => {

    if(this.state.seeStats==false){
      this.setState({seeStats:true, seeNotif:false, seeHelp:false});
      if(Platform.OS!="web") this.props.navigation.setOptions({ title:strings.stats    })
    }
    else {
      this.setState({seeStats:false});
      if(Platform.OS!="web") this.props.navigation.setOptions({ title:"Plus d'infos"    })

    }

  }

  seeNotif= (value) => {

    if(this.state.seeNotif==false){
      this.setState({seeNotif:true,seeStats:false, seeHelp:false });
      this.getOldNotification();
      if(Platform.OS!="web") this.props.navigation.setOptions({ title:strings.setNotification    })
    }
    else {
      this.setState({seeNotif:false});
      if(Platform.OS!="web") this.props.navigation.setOptions({ title:"Plus d'infos"    })

    }

  }



  

  seeHelp= (value) => {

    if(this.state.seeHelp==false){
      this.setState({seeHelp:true,seeNotif:false, seeStats:false});
      if(Platform.OS!="web") this.props.navigation.setOptions({ title:strings.help    })
    }
    else {
      this.setState({seeHelp:false});
      if(Platform.OS!="web") this.props.navigation.setOptions({ title:"Plus d'infos"    })

    }

  }

  

  goBack= (value) => {

    console.log("goBack")
    if(this.state.seeStats===true) this.seeStats();
    else if(this.state.seeHelp===true) this.seeHelp();
    else if(this.state.seeNotif===true) this.seeNotif();
    else this.props.navigation.goBack();


  }


  



  




  render() {
    return (
      <StyleContext.Consumer>{style => 
      <View style={{ backgroundColor: style.backgroundColor, flex: 1, flexDirection:(Platform.OS=="web")?"row":undefined, height:(Platform.OS=="web")?Dimensions.get('window').height-75:undefined }}>

        {((this.state.waiting==false && this.state.seeStats!=true && this.state.seeHelp!=true && this.state.seeNotif!=true) || Platform.OS=="web") && <ScrollView style={{ flex: 1 }}>

            
           
                <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.stats}</Text>
                
                <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.seeStats() }}>
                  <MavTextInput disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={strings.seeStats} />
                </TouchableHighlight>


                <Text style={{marginTop:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.adsNotification}</Text>
                
                <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.seeNotif() }}>
                  <MavTextInput disabledText={true} enableNext={true} style={{marginTop:10}} textExplication={strings.setNotification} />
                </TouchableHighlight>



                  {false && <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.myBill}</Text>}
                  
                  {(false && this.state.billList.length==0) && <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize,textAlign: "center",}}>{strings.nothingBill}</Text>}
                  
                  {(false && this.state.billList.length>0) && this.state.billList.map((rowData, i) => (

                    <TouchableHighlight key={i} underlayColor={'#FFFFFF00'} onPress={() => {Linking.openURL(rowData.url)}}>
                    <MavTextInput disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={strings.monthList[rowData.date.getMonth()]+" "+rowData.date.getFullYear()} />
                  </TouchableHighlight>
                  ))
                }





                 <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.theBlog}</Text>
                  
                  <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {Linking.openURL("http://mon-appli-vtc.com/blog-conseils-vtc/")}}>
                    <MavTextInput disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={strings.accessBlog} />
                  </TouchableHighlight>

                  <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.help}</Text>
                  
                  <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.seeHelp() }}>
                    <MavTextInput disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={"Accéder aux questions / réponses"} />
                  </TouchableHighlight>


          

                  {Platform.OS!="web" && <View style={{flex:1}}>
                          {true && <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{"Guide PDF"}</Text>}

                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.viewPDFFile("https://mon-appli-vtc.com/wp-content/uploads/2021/02/guidedemarrage2101.pdf") }}>
                              <Image source={{uri:"https://mon-appli-vtc.com/wp-content/uploads/2021/02/capture-decran-2021-02-11-a-20.52.16.png" }} style={{marginLeft:Dimensions.get('window').width*0.1,height:Dimensions.get('window').width*0.7,width:Dimensions.get('window').width*0.8,resizeMode:"contain"}}/> 
                          </TouchableHighlight>

                  </View>}

        </ScrollView>}


        {(Platform.OS=="web" && this.state.waiting==false) && <View style={{width:1, height:"100%",backgroundColor:style.textInputLineColor}}/> }

        {(Platform.OS=="web" && this.state.waiting==false && this.state.seeStats!=true && this.state.seeHelp!=true && this.state.seeNotif!=true) && <View style={{flex:1}}>
              {true && <Text style={{marginTop:10,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{"Guide PDF"}</Text>}

              <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.viewPDFFile("https://mon-appli-vtc.com/wp-content/uploads/2021/02/guidedemarrage2101.pdf") }}>
                  <Image source={{uri:"https://mon-appli-vtc.com/wp-content/uploads/2021/02/capture-decran-2021-02-11-a-20.52.16.png" }} style={{height:Dimensions.get('window').width*0.37,width:Dimensions.get('window').width*0.5,resizeMode:"contain"}}/> 
              </TouchableHighlight>

         </View>}

        {this.state.waiting==true && <ActivityIndicator style={{marginTop:30}} size="large" color={style.color1} />}

        {this.state.seeStats==true && <ScrollView style={{ flex: 1 }}>

              <MavInfoBubble type={"info"} title={strings.seeStats} subTitle={strings.statsMoreInfo} style={{marginTop:10,}} />
              
                <MavViewMore ref={changeDateSt => {this.changeDateSt = changeDateSt;}}  text={strings.startDate} textBold={true} textInputFlex={1}  textSup={((this.state.dateStart=="")?strings.chooseDate:this.state.dateStart)} iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/calendar_white.png'):require('../assets/calendar_black.png')} >
                    <View style={{height:(Platform.OS=="web")?400:undefined,}}>
                        <MavSelectDate params={{hideNavBar:true, changeDate: this.changeDateStart, navigate:true,date:(this.state.dateStartInteger!=-1)?new Date(this.state.dateStartInteger):new Date(), disableHour:true }}/>
                    </View>
                </MavViewMore>

                <MavViewMore ref={changeDateEd => {this.changeDateEd = changeDateEd;}}  text={strings.endDate} textBold={true} textInputFlex={1}  textSup={((this.state.dateEnd=="")?strings.chooseDate:this.state.dateEnd)} iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/calendar_white.png'):require('../assets/calendar_black.png')} >
                    <View style={{height:(Platform.OS=="web")?400:undefined,}}>
                        <MavSelectDate params={{hideNavBar:true, changeDate: this.changeDateEnd, navigate:true,date:(this.state.dateEndInteger!=-1)?new Date(this.state.dateEndInteger):new Date(), disableHour:true }}/>
                    </View>
                </MavViewMore>


              {(this.state.updateWaiting==true && this.state.waitingStatsInfo!=true && this.state.dateStartInteger!=-1 && this.state.dateEndInteger!=-1) && <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:10}}>
                <MavButton text={strings.validate} onlyButton={true} onPress={()=>{ this.getStatsInfo() }}/>
              </View>}



              {this.state.waitingStatsInfo==true && <ActivityIndicator style={{marginTop:30}} size="large" color={style.color1} />}




              {(this.state.dataToView==true && this.state.updateWaiting!=true) && <View>




                <MavViewMore icon={(style.mode=="dark")?require('../assets/favorite/library_white.png'):require('../assets/favorite/library_black.png')} text={strings.topPlace} defautVisible={true} maxiMode={"partial"} >

                            <View style={{alignItems:"center",justifyContent:"center", flexDirection:"row", marginTop:15,marginBottom:0}}>
                                  <MavMiniButton icon={(this.state.topPlaceView=="total")?((style.mode=="dark")?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')):((style.mode=="dark")?require('../assets/pin_blue.png'):require('../assets/pin_blue.png'))} maximizeText={true}  style={{marginLeft:5, marginBottom:5}} color={this.state.topPlaceColor.total} text={strings.total} strokeMode={(this.state.topPlaceView!="total")} onPress={()=>{ if(this.state.topPlaceView!="total") this.setState({ topPlaceViewDetail:-1, topPlaceViewMore:false, topPlaceView:"total" }) }}/>
                                  <MavMiniButton icon={(this.state.topPlaceView=="start")?((style.mode=="dark")?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')):((style.mode=="dark")?require('../assets/pin_green.png'):require('../assets/pin_green.png'))}  maximizeText={true} style={{marginLeft:5, marginBottom:5}} color={this.state.topPlaceColor.start} text={strings.start} strokeMode={(this.state.topPlaceView!="start")} onPress={()=>{this.changeTopPlaceView("start"); }}/>
                                  {this.state.maxTopPlace.intermediate>0 && <MavMiniButton icon={(this.state.topPlaceView=="intermediate")?((style.mode=="dark")?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')):((style.mode=="dark")?require('../assets/pin_orange.png'):require('../assets/pin_orange.png'))}  maximizeText={true} style={{marginLeft:5, marginBottom:5}} color={this.state.topPlaceColor.intermediate} text={strings.onlyStop} strokeMode={(this.state.topPlaceView!="intermediate")}  onPress={()=>{this.changeTopPlaceView("intermediate"); }}/>}
                                  <MavMiniButton icon={(this.state.topPlaceView=="end")?((style.mode=="dark")?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')):((style.mode=="dark")?require('../assets/pin_red.png'):require('../assets/pin_red.png'))}  maximizeText={true} style={{marginLeft:5, marginBottom:5}} color={this.state.topPlaceColor.end} text={strings.destination} strokeMode={(this.state.topPlaceView!="end")}  onPress={()=>{this.changeTopPlaceView("end"); }}/>
                            </View>



                              {(this.state.topPlaceView=="total") && this.state.topPlace.map((place,i) => ( <View key={i}>

                              {(i<5 || this.state.topPlaceViewMore==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.setState({topPlaceViewDetail: (this.state.topPlaceViewDetail==i)?-1:i}) }}  style={{marginLeft:"10%", marginRight:"10%", marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8) , height:25, alignItems:"center" }}>

                                  <View style={{width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8) , height:25, alignItems:"center"}}> 

                                        <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(place.total/this.state.maxTopPlace.total) , backgroundColor:this.state.topPlaceColor.total, height:25}} />

                                        <Text numberOfLines={1} style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}><Text style={{fontWeight: 'bold',fontSize: style.textInputFontSize}}>{" "+place.total+" - "}</Text>{place.name}</Text>

                                  </View>

                              </TouchableHighlight>}


                              {this.state.topPlaceViewDetail==i && <View style={{marginLeft:"10%", marginRight:"10%",width:Dimensions.get('window').width*0.8*(place.total/this.state.maxTopPlace.total),  }} >

                               {(place.start>0) && <View style={{width:Dimensions.get('window').width*0.8 , height:25, alignItems:"center"}}> 
                                        <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(place.start/this.state.maxTopPlace.total) , backgroundColor:this.state.topPlaceColor.start, height:25}} />
                                        <Text numberOfLines={1} style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}>{"  "+strings.start+" : "+place.start}</Text>
                               </View>}

                               {(place.intermediate>0) && <View style={{width:Dimensions.get('window').width*0.8 , height:25, alignItems:"center"}}> 
                                        <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(place.intermediate/this.state.maxTopPlace.total) , backgroundColor:this.state.topPlaceColor.intermediate, height:25}} />
                                        <Text numberOfLines={1} style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}>{"  "+strings.multiStopInfo+" : "+place.intermediate}</Text>
                               </View>}

                               {(place.end>0) && <View style={{width:Dimensions.get('window').width*0.8 , height:25, alignItems:"center"}}> 
                                        <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(place.end/this.state.maxTopPlace.total) , backgroundColor:this.state.topPlaceColor.end, height:25}} />
                                        <Text numberOfLines={1} style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}>{"  "+strings.destination+" : "+place.end}</Text>
                               </View>}

                                </View>}

                              </View>

                              ))}



                              {(this.state.topPlaceView!="total") && this.state.topPlaceOther.map((place,i) => ( <View key={i}>

                              {(i<5 || this.state.topPlaceViewMore==true && place.name!=undefined && (((this.state.topPlaceView!="start")?( this.state.topPlaceView=="end"?place.end:place.intermediate) :place.start )>0) ) && <View style={{marginLeft:"10%", marginRight:"10%", marginTop:5, width:Dimensions.get('window').width*0.8 , height:25, alignItems:"center" }}>

                                   <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*( ((this.state.topPlaceView!="start")?( this.state.topPlaceView=="end"?place.end:place.intermediate) :place.start )   / ((this.state.topPlaceView!="start")?( this.state.topPlaceView=="end"?this.state.maxTopPlace.end:this.state.maxTopPlace.intermediate) :this.state.maxTopPlace.start )         ) , backgroundColor:(this.state.topPlaceView!="start")?( this.state.topPlaceView=="end"?this.state.topPlaceColor.end:this.state.topPlaceColor.intermediate) :this.state.topPlaceColor.start, height:25}} />
                                   <Text numberOfLines={1} style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}><Text style={{fontWeight: 'bold',fontSize: style.textInputFontSize}}>{" "+((this.state.topPlaceView!="start")?( this.state.topPlaceView=="end"?place.end:place.intermediate) :place.start )  +" - "}</Text>{place.name}</Text>

                              </View>}

                              </View>
                            ))}
                              
                              {this.state.topPlaceViewMore==false && <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:10}}>
                                  <MavButton small={true} color={this.state.topPlaceColor[this.state.topPlaceView]} text={strings.seeAll} strokeMode={true} onlyButton={true} onPress={()=>{ this.setState({topPlaceViewMore:true, topPlaceViewDetail:-1}) }}/>
                              </View>}



                </MavViewMore>



                <MavViewMore icon={(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png')} text={strings.topClients} defautVisible={true} maxiMode={"partial"} >

                   

                      <View style={{justifyContent:"center", flexDirection:"row", marginTop:15,marginBottom:5}}>

                          <MavMiniButton icon={(this.state.topClientsView=="total")?((style.mode=="dark")?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')):require('../assets/user_blue.png')} maximizeText={true} style={{marginLeft:5}} color={this.state.topClientsColor.total} text={strings.total} strokeMode={(this.state.topClientsView!="total")}  onPress={()=>{ this.changeTopClientsView("total"); }}/>
                          <MavMiniButton icon={(this.state.topClientsView=="client")?((style.mode=="dark")?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')):require('../assets/user_green.png')} style={{marginLeft:5}} maximizeText={true} color={this.state.topClientsColor.client} text={strings.customers} strokeMode={(this.state.topClientsView!="client")} onPress={()=>{this.changeTopClientsView("client"); }}/>
                          <MavMiniButton icon={(this.state.topClientsView=="pro")?((style.mode=="dark")?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')):require('../assets/user_orange.png')} style={{marginLeft:5}} maximizeText={true} color={this.state.topClientsColor.pro} text={strings.customersPro} strokeMode={(this.state.topClientsView!="pro")}  onPress={()=>{this.changeTopClientsView("pro"); }}/>
                          <MavMiniButton icon={(this.state.topClientsView=="provider")?((style.mode=="dark")?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')):require('../assets/user_red.png')} style={{marginLeft:5}} maximizeText={true} color={this.state.topClientsColor.provider} text={strings.providersAffair} strokeMode={(this.state.topClientsView!="provider")}  onPress={()=>{this.changeTopClientsView("provider"); }}/>
                          
                      </View>



                      {this.state.topClients.map((place,i) => ( <View key={i}>

                      {(i<5 || this.state.topClientsViewMore==true) &&  <View style={{marginLeft:"10%", marginRight:"10%", marginTop:5, width:Dimensions.get('window').width*0.8 , height:25, alignItems:"center" }}>
                                <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(place.total/this.state.maxTopClients[this.state.topClientsView]) , backgroundColor:this.state.topClientsColor[this.state.topClientsView], height:25}} />
                                <Text style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}><Text style={{fontWeight: 'bold',fontSize: style.textInputFontSize}}>{" "+place.total+" - "}</Text>{place.name}</Text>
                      </View>}

                      </View>

                      ))}



                       {(this.state.topClientsViewMore==false && this.state.topClients.length>5) && <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:10}}>
                          <MavButton small={true} color={this.state.topClientsColor[this.state.topClientsView]} text={strings.seeAll} strokeMode={true} onlyButton={true} onPress={()=>{ this.setState({topClientsViewMore:true}) }}/>
                      </View>}



                </MavViewMore>


                {(this.state.listUsers!=undefined && this.state.listUsers.length>0) && <MavViewMore icon={(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png')} text={strings.newClient} maxiMode={"partial"} >

                      <Text style={{marginTop:10,marginBottom:10, color: style.textInputColor,fontSize: style.textInputFontSize*1,textAlign: "center",width:"100%"}}>{this.state.listUsers.length+" "+strings.newClientOnPeriod}</Text>


                      {this.state.listUsers.map((place,i) => ( <View key={i}>

                      <View style={{marginLeft:"10%", marginRight:"10%", marginTop:5, width:Dimensions.get('window').width*0.8 , height:25, alignItems:"center" }}>
                                <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}><Text style={{fontWeight: 'bold',fontSize: style.textInputFontSize}}>{place.name+" - "}</Text>{place.date}</Text>
                      </View>

                      </View>

                      ))}




                </MavViewMore>}


                




                <MavViewMore icon={(style.mode=="dark")?require('../assets/partner_light.png'):require('../assets/partner_dark.png')} text={strings.topDrivers} defautVisible={true} maxiMode={"partial"} >


                        {this.state.topDrivers.map((place,i) => ( <View key={i}>

                        {(i<5 || this.state.topDriversViewMore==true) &&  <View style={{marginLeft:"10%", marginRight:"10%", marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8) , height:25, alignItems:"center" }}>
                                  <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(place.total/this.state.maxTopDrivers.total) , backgroundColor:this.state.topClientsColor.total, height:25}} />
                                  <Text style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}><Text style={{fontWeight: 'bold',fontSize: style.textInputFontSize}}>{" "+place.total+" - "}</Text>{place.name}</Text>
                        </View>}

                        </View>

                        ))}



                         {(this.state.topDriversViewMore==false && this.state.topDrivers.length>5) && <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:10}}>
                            <MavButton small={true} color={this.state.topClientsColor.total} text={strings.seeAll} strokeMode={true} onlyButton={true} onPress={()=>{ this.setState({topDriversViewMore:true}) }}/>
                        </View>}

                </MavViewMore>


                <MavViewMore icon={(style.mode=="dark")?require('../assets/promo_white.png'):require('../assets/promo_black.png')} text={strings.billedToClient} defautVisible={true} maxiMode={"partial"} >

                    <View style={{flexDirection:"row", justifyContent:"space-between", marginLeft:"10%", "marginRight":"10%", marginTop:20, alignItems:"center"}}>
                          <Text style={{fontSize:style.textInputFontSize*1.3, color:style.textInputColor, fontWeight: 'bold',}}>{strings.totalOnPeriod}</Text>
                          <Text style={{fontSize:style.textInputFontSize*1.5, color:style.textInputColor,}}>{((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.caInfo.total.toFixed(2)+((this.state.money.position=="right")?(this.state.money.symbole):"")}</Text>
                      </View> 

                      <View style={{flexDirection:"row", justifyContent:"space-between", marginLeft:"10%", "marginRight":"10%", marginTop:15, alignItems:"center"}}>
                          <Text style={{fontSize:style.textInputFontSize*1.3, color:style.textInputColor, fontWeight: 'bold',}}>{strings.inAverage}</Text>
                          <Text style={{fontSize:style.textInputFontSize*1.5, color:style.textInputColor,}}>{((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.caInfo.moy.toFixed(2)+((this.state.money.position=="right")?(this.state.money.symbole):"")}</Text>
                      </View> 


                      <View style={{flexDirection:"row", justifyContent:"space-between", marginLeft:"10%", "marginRight":"10%", marginTop:15, alignItems:"center"}}>
                          <Text style={{fontSize:style.textInputFontSize*1.3, color:style.textInputColor, fontWeight: 'bold',}}>{strings.priceLessRace}</Text>
                          <Text style={{fontSize:style.textInputFontSize*1.5, color:style.textInputColor,}}>{((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.caInfo.min.toFixed(2)+((this.state.money.position=="right")?(this.state.money.symbole):"")}</Text>
                      </View> 

                      <View style={{flexDirection:"row", justifyContent:"space-between", marginLeft:"10%", "marginRight":"10%", marginTop:15, marginBottom:5, alignItems:"center"}}>
                          <Text style={{fontSize:style.textInputFontSize*1.3, color:style.textInputColor, fontWeight: 'bold',}}>{strings.priceMoreRace}</Text>
                          <Text style={{fontSize:style.textInputFontSize*1.5, color:style.textInputColor,}}>{((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.caInfo.max.toFixed(2)+((this.state.money.position=="right")?(this.state.money.symbole):"")}</Text>
                      </View> 

                  </MavViewMore>


                  <MavViewMore icon={(style.mode=="dark")?require('../assets/charth_white.png'):require('../assets/charth_black.png')} text={strings.caByDay} defautVisible={true} maxiMode={"partial"} >


                        {this.state.caByDay.map((place,i) => ( 

                        <View key={i} style={{marginLeft:"10%", marginRight:"10%", marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8) , height:25, alignItems:"center" }}>
                                  <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(place/this.state.caByDayMax) , backgroundColor:this.state.topClientsColor.total, height:25}} />
                                  <Text style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}><Text style={{fontWeight: 'bold',fontSize: style.textInputFontSize}}>{" "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+place.toFixed(0)+((this.state.money.position=="right")?(this.state.money.symbole):"")+" - "}</Text>{strings.dayListComplete[i]}</Text>
                        </View>

                        ))}


                  </MavViewMore>


                  <MavViewMore icon={(style.mode=="dark")?require('../assets/charth_white.png'):require('../assets/charth_black.png')} text={strings.orderByDay} defautVisible={true} maxiMode={"partial"} >


                        {this.state.orderByDay.map((place,i) => ( 

                        <View key={i} style={{marginLeft:"10%", marginRight:"10%", marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8) , height:25, alignItems:"center" }}>
                                  <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(place/this.state.orderByDayMax) , backgroundColor:this.state.topClientsColor.total, height:25}} />
                                  <Text style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}><Text style={{fontWeight: 'bold',fontSize: style.textInputFontSize}}>{" "+place+" - "}</Text>{strings.dayListComplete[i]}</Text>
                        </View>

                        ))}


                  </MavViewMore>




                  <MavViewMore icon={(style.mode=="dark")?require('../assets/chart_white.png'):require('../assets/chart_black.png')} text={strings.orderByHour} defautVisible={true} maxiMode={"partial"} >


                  <View style={{marginLeft:"10%", marginRight:"10%", flexDirection:"row",height:200}}>
                      
                  
                      {this.state.orderByHour.map((place,i) => ( 

                         <View key={i} style={{justifyContent:"flex-end", left:0,height:200*(place/this.state.orderByHourMax), marginTop:200-( 200*(place/this.state.orderByHourMax) ), backgroundColor:this.state.topClientsColor.total, width:(Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8))/24}} />

                      ))}

                      <View style={{position:"absolute",flexDirection:'row', marginTop:158, marginLeft:"-10%",}}>
                        {this.state.orderByHour.map((place,i) => ( <View>
                         {place>0 && <Text style={{textAlign:"left", width:70, position:"absolute", marginLeft:((Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8))/24)*i+3, transform: [{ rotate:"-90deg" }] , fontSize:style.textInputFontSize*0.7, color:style.textInputColor,fontWeight: 'bold', }}>{" "+place+" - "}<Text style={{fontWeight: 'normal'}}>{((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+this.state.caByHour[i].toFixed(0)+((this.state.money.position=="right")?(this.state.money.symbole):"")}</Text> </Text>}
                        </View>
                        ))}
                      </View>

                   


                  </View>
                  <View style={{marginLeft:"5%", marginRight:"5%", justifyContent: 'space-between', flexDirection:"row",}}><Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{"00:00"}</Text><Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{"06:00"}</Text><Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{"12:00"}</Text><Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{"18:00"}</Text><Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{"24:00"}</Text></View>


                  </MavViewMore>
                  

                  <MavViewMore icon={(style.mode=="dark")?require('../assets/route_light.png'):require('../assets/route_dark.png')} text={strings.kmInfo} defautVisible={true} maxiMode={"partial"} >

                      <View style={{flexDirection:"row", justifyContent:"space-between", marginLeft:"10%", "marginRight":"10%", marginTop:20, alignItems:"center"}}>
                          <Text style={{fontSize:style.textInputFontSize*1.3, color:style.textInputColor, fontWeight: 'bold',}}>{strings.totalOnPeriod}</Text>
                          <Text style={{fontSize:style.textInputFontSize*1.5, color:style.textInputColor,}}>{this.state.km.total.toFixed(2)+" km"}</Text>
                      </View> 

                      <View style={{flexDirection:"row", justifyContent:"space-between", marginLeft:"10%", "marginRight":"10%", marginTop:15, alignItems:"center"}}>
                          <Text style={{fontSize:style.textInputFontSize*1.3, color:style.textInputColor, fontWeight: 'bold',}}>{strings.inAverage}</Text>
                          <Text style={{fontSize:style.textInputFontSize*1.5, color:style.textInputColor,}}>{this.state.km.moy.toFixed(2)+" km"}</Text>
                      </View> 


                      <View style={{flexDirection:"row", justifyContent:"space-between", marginLeft:"10%", "marginRight":"10%", marginTop:15, alignItems:"center"}}>
                          <Text style={{fontSize:style.textInputFontSize*1.3, color:style.textInputColor, fontWeight: 'bold',}}>{strings.kmLessRace}</Text>
                          <Text style={{fontSize:style.textInputFontSize*1.5, color:style.textInputColor,}}>{this.state.km.min.toFixed(2)+" km"}</Text>
                      </View> 

                      <View style={{flexDirection:"row", justifyContent:"space-between", marginLeft:"10%", "marginRight":"10%", marginTop:15, marginBottom:5, alignItems:"center"}}>
                          <Text style={{fontSize:style.textInputFontSize*1.3, color:style.textInputColor, fontWeight: 'bold',}}>{strings.kmMoreRace}</Text>
                          <Text style={{fontSize:style.textInputFontSize*1.5, color:style.textInputColor,}}>{this.state.km.max.toFixed(2)+" km"}</Text>
                      </View> 

                      
                      

                  </MavViewMore>



                  {(this.state.carName.length>1) && <MavViewMore icon={(style.mode=="dark")?require('../assets/charth_white.png'):require('../assets/charth_black.png')} text={strings.carType} defautVisible={true} maxiMode={"partial"} >


                        {this.state.carName.map((car,i) => ( 

                        <View key={i} style={{marginLeft:"10%", marginRight:"10%", marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8) , height:25, alignItems:"center" }}>
                                  <View style={{position:"absolute", left:0,marginTop:5, width:Dimensions.get('window').width*((Platform.OS=="web")?0.4:0.8)*(car.number/this.state.carNameMax) , backgroundColor:this.state.topClientsColor.total, height:25}} />
                                  <Text style={{position:"absolute",zIndex:100,marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "left",width:"100%"}}><Text style={{fontWeight: 'bold',fontSize: style.textInputFontSize}}>{car.name+" - "}</Text>{car.number}</Text>
                        </View>

                        ))}


                  </MavViewMore>}


                  


                  





                  <View style={{width:50, height:50}} />


              </View>}


              



        </ScrollView>}        


        {this.state.seeHelp==true && <ScrollView style={{ flex: 1 }}>

              <MavViewMore text={"Peut-on faire de la réservation instantanée ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Oui, il suffit d’aller dans « Réglages » et de cocher « Mode de réservation instantanée ». En cochant cette case, vous pourrez également facturer plus en cas de bouchon sur la route. Pour cela, l’application se basera sur les conditions de circulation au moment de la réservation. Attention à cette option cependant : cela s’additionnera aux majorations horaires que vous avez pu définir"}</Text>
              </MavViewMore>


              <MavViewMore text={"Puis-je autoriser le client à demander des arrêts intermédiaires ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Oui, il suffit d’aller dans « Réglages » et de cocher « Autoriser le multi arrêt »"}</Text>
              </MavViewMore>

              <MavViewMore text={"Puis-je gérer moi-même les commandes ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Oui. Par défaut, l’application gère le dispatch de manière automatique en proposant la course aux chauffeurs compatibles. Mais vous pourrez reprendre la main en allant dans « Réglages » puis en cochant « Forcer le mode manuel ». A noter que cette option peut s’activer et se désactiver à tout moment. Vous pourrez donc faire de la gestion manuelle en journée et automatique en soirée"}</Text>
              </MavViewMore>

              <MavViewMore text={"Puis-je dupliquer des zones / forfaits ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Oui, vous le pouvez. Il suffit d’aller dans la zone ou le forfait en question et de cliquer sur «Copier ». Ensuite, vous pourrez cliquez sur le bouton « Ajouter » et il vous sera proposé de « Créer depuis le presse papier »"}</Text>
              </MavViewMore>

              <MavViewMore text={"J’ai des tarifs négociés avec certains clients, puis-je les mettre dans l’application ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Oui, vous pouvez. Il vous suffit d’aller dans « Répertoire », de cliquer sur le nom de la personne puis de cliquer sur « Définir les prix ». Vous pourrez rentrer vos tarifs. Afin de gagner du temps, pensez à dupliquer les zones et les forfaits !"}</Text>
              </MavViewMore>
              <MavViewMore text={"Quelle est la différence entre une majoration locale et globale ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"La majoration globale s’appliquera à l’ensemble des zones et des forfaits que vous allez définir. La majoration globale s’appliquera également aux forfaits / zones qui auraient pu être définis par les chauffeurs ainsi que les forfaits / zones spécifiques à certains clients. En revanche, une majoration locale ne s’appliquera qu’au forfait ou qu’à la zone en question."}</Text>
              </MavViewMore>

              <MavViewMore text={"Quelles sont les possibilités de l’application pour un groupement?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Au moment de la création de l’application, vous avez pu dire si vous acceptiez que les clients puissent choisir leur chauffeur favori. A partir de là, si le chauffeur est compatible, c’est lui qui recevra la course en priorité (vous pouvez mettre un garde fou en laissant le chauffeur que 30 minutes pour prendre la décision dans « Réglages »)."}</Text>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Vous pouvez également autoriser les chauffeurs à définir des tarifs personnalisés qui seront appliqués aux personnes les ayants désignés comme chauffeur favori (« Réglages » > « Tarif définissable par les chauffeurs »)"}</Text>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Vous pouvez aussi autoriser les chauffeurs à accéder aux clients les ayants désignés comme chauffeur favori (« Réglages » > « Autoriser les chauffeurs à accéder partiellement au répertoire de l’application ») ainsi que les autoriser à voir les courses qu’ils ne font pas mais où ils étaient désignés chauffeurs favoris (« Réglages » > « Autoriser les chauffeurs à voir les courses non réalisées par eux»)"}</Text>

              </MavViewMore>

              <MavViewMore text={"Pourquoi mettre des points d’intérêt ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"5%", marginRight:"5%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Mettre des points d’intérêt présentent plusieurs avantages : diminuer les risques d’erreurs de destination, accélérer le processus de commandes, permettre de faire de la publicité pour vos partenaires (hôtel, restaurant …)"}</Text>
              </MavViewMore>
              <MavViewMore text={"Pourquoi malgré la case « Demander le numéro de vol / de train » rien n'apparait ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Plusieurs raisons sont possibles : cette case est facultative côté client, il n’est donc pas obligatoire de la remplir. De plus, l’application se base sur les points d’interêt « Train » et « Avion » pour afficher la demande. Si le client n’a pas utilisé un point d’intérêt ou si vous n’avez pas choisi l’icone « train » ou l’icone « avion », ça ne fonctionnera pas"}</Text>
              </MavViewMore>
              <MavViewMore text={"Est-ce que l’application peut prendre en compte les péages ?"}>
                  <Text allowFontScaling={false} style={{fontSize:style.textInputFontSize,marginLeft:"10%", marginRight:"10%", marginTop:5, textAlign:"justify",color:style.textInputColor}}>{"Malheureusement, l’API Google Maps ne retourne pas cette information. Nous serions obligés d’utiliser celle de Via Michelin. Cependant, son prix ne nous permet pas de la proposer. Si vous voulez, vous pouvez souscrire à un abonnement de votre côté et nous pourrons l’intégrer gratuitement. Pour plus d’information : https://api.viamichelin.fr/tarifs/ (l’offre business)"}</Text>
              </MavViewMore>


        </ScrollView>}  


        {this.state.seeNotif==true && <ScrollView style={{ flex: 1 }}> 

        <MavInfoBubble type={"info"} title={strings.adsNotification} subTitle={strings.adsNotificationMoreInfo} style={{marginTop:10,}} />
              

        {this.state.notificationWaiting==true && <ActivityIndicator style={{marginTop:30}} size="large" color={style.color1} />}

        {this.state.notificationWaiting==false && <MavViewMore text={strings.newNotification} iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/notification_white.png'):require('../assets/notification_black.png') } >

              <View style={{marginTop:15,marginLeft:"10%", marginRight:"10%", minHeight:50, backgroundColor:style.backgroundColor,borderRadius: 11,borderWidth: 1, borderColor:style.backgroundColor,shadowOpacity: 0.3,shadowRadius:2,shadowOffset: {height: 0,width: 0},elevation:1 }}>

                <View style={{marginLeft:10, marginRight:10, marginTop:5, flexDirection:"row", justifyContent: 'space-between', alignItems:"center"}} >
                    <View style={{flexDirection:"row", alignItems:"center"}} >
                        <Image source={require("../assets/login_logo.png")} style={{borderRadius: 5,borderWidth: 1,borderColor:style.backgroundColor, height:20, width:20,resizeMode:"contain"}}/>
                        <Text style={{marginLeft:5, color: style.textInputColor,fontSize: style.textInputFontSize}}>{info.companyBrand.toUpperCase()}</Text>
                    </View>
                    <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.7}}>{strings.overview}</Text>

                </View>
                <Text style={{marginLeft:10, marginTop:5, color: style.textInputColor,fontSize: style.textInputFontSize,fontWeight: 'bold',}}>{(this.state.newNotification.title!="")?this.state.newNotification.title:strings.title}</Text>
                <Text style={{marginLeft:10, marginTop:0, marginBottom:5, color: style.textInputColor,fontSize: style.textInputFontSize}}>{(this.state.newNotification.subTitle!="")?this.state.newNotification.subTitle:strings.subTitle}</Text>


              </View>



              <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={2}  flag={this.state.newNotification.flagLangue} text={this.state.newNotification.nameLangue} textExplication={strings.langue} editable={false} flagOnPress={()=>{ this.viewLangue() }} />
              

              <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={2}  text={this.state.newNotification.title} textExplication={strings.title} placeholder={strings.title} onChangeText={(text)=>{var notif=JSON.parse(JSON.stringify(this.state.newNotification)); notif.title=text; this.setState({newNotification:notif})  }}/>
              <MavTextInput   multiline={true}  backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={2}  text={this.state.newNotification.subTitle} textExplication={strings.subTitle} placeholder={strings.subTitle} onChangeText={(text)=>{var notif=JSON.parse(JSON.stringify(this.state.newNotification)); notif.subTitle=text; this.setState({newNotification:notif})  }}/>
              

              <Text style={{marginLeft:"10%", marginTop:15, color: style.textInputColor,fontSize: style.textInputFontSize}}>{strings.target}</Text>
              <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.newNotification.target.customer==true} textExplication={strings.customers}   onChange={()=>{var notif=JSON.parse(JSON.stringify(this.state.newNotification)); if(notif.target.customer==true) delete notif.target.customer; else notif.target.customer=true; this.setState({newNotification:notif})  }}/>
              <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.newNotification.target.customerPro==true} textExplication={strings.customersPro}   onChange={()=>{var notif=JSON.parse(JSON.stringify(this.state.newNotification)); if(notif.target.customerPro==true) delete notif.target.customerPro; else notif.target.customerPro=true; this.setState({newNotification:notif})  }}/>
              <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.newNotification.target.provider==true} textExplication={strings.providersAffair}   onChange={()=>{var notif=JSON.parse(JSON.stringify(this.state.newNotification)); if(notif.target.provider==true) delete notif.target.provider; else notif.target.provider=true; this.setState({newNotification:notif})  }}/>
              <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:-5}} textInputFlex={0.8} disabledText={true} arrow={true} arrowEnabled={this.state.newNotification.target.driver==true} textExplication={strings.partners}   onChange={()=>{var notif=JSON.parse(JSON.stringify(this.state.newNotification)); if(notif.target.driver==true) delete notif.target.driver; else notif.target.driver=true; this.setState({newNotification:notif})  }}/>

              <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:10}}>
                <MavButton text={strings.validate} onlyButton={true} onPress={()=>{ this.sendNotification() }}/>
              </View>

              </MavViewMore>}


              {(this.state.notificationWaiting==false && this.state.oldNotifications.length>0) && <Text style={{marginTop:20,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.oldNotifications}</Text>}

              {(this.state.notificationWaiting==false) && this.state.oldNotifications.map((rowData, i) => (

                   <MavViewMore key={i} text={rowData.title} textMini={ ((new Date(rowData.date).getDate()<10)?"0":"")+   new Date(rowData.date).getDate()+"/"+((new Date(rowData.date).getMonth()<9)?"0":"")+   (new Date(rowData.date).getMonth()+1)+"/"+new Date(rowData.date).getFullYear()} iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/notification_white.png'):require('../assets/notification_black.png') } >
                        <View style={{marginTop:15,marginLeft:"10%", marginRight:"10%", minHeight:50, backgroundColor:style.backgroundColor,borderRadius: 11,borderWidth: 1, borderColor:style.backgroundColor,shadowOpacity: 0.3,shadowRadius:2,shadowOffset: {height: 0,width: 0},elevation:1 }}>

                          <View style={{marginLeft:10, marginRight:10, marginTop:5, flexDirection:"row", justifyContent: 'space-between', alignItems:"center"}} >
                              <View style={{flexDirection:"row", alignItems:"center"}} >
                                  <Image source={require("../assets/login_logo.png")} style={{borderRadius: 5,borderWidth: 1,borderColor:style.backgroundColor, height:20, width:20,resizeMode:"contain"}}/>
                                  <Text style={{marginLeft:5, color: style.textInputColor,fontSize: style.textInputFontSize}}>{info.companyBrand.toUpperCase()}</Text>
                              </View>
                              <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.7}}>{strings.overview}</Text>

                          </View>
                          <Text style={{marginLeft:10, marginTop:5, color: style.textInputColor,fontSize: style.textInputFontSize,fontWeight: 'bold',}}>{rowData.title}</Text>
                          <Text style={{marginLeft:10, marginTop:0, marginBottom:5, color: style.textInputColor,fontSize: style.textInputFontSize}}>{rowData.subTitle}</Text>


                        </View>


                        <Text style={{marginLeft:"10%", marginTop:15, color: style.textInputColor,fontSize: style.textInputFontSize}}>{strings.target}</Text>
                        <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={rowData.target.customer==true} textExplication={strings.customers}  editable={false} />
                        <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={rowData.target.customerPro==true} textExplication={strings.customersPro} editable={false}/>
                        <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={rowData.target.provider==true} textExplication={strings.providersAffair} editable={false} />
                        <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={rowData.target.driver==true} textExplication={strings.partners} editable={false}/>



                   </MavViewMore>

              ))}
             




        </ScrollView>}  



        

          <MavAlert ref={alert => {this.alert = alert;}}/>

        
      </View>
      }</StyleContext.Consumer>
    );
  }
}


export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}


