import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  ScrollView,
  Animated,
  ActivityIndicator,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import countryList from '../countryList';
import moneyList from '../moneyList';

import Modal from './modal';

const timer = require('react-native-timer');
import MavButton from './mavButton';
import TrainDetail from './trainDetail';
import firebase from './firebase';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, equalTo, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";
import MavAlert from './mavAlert';

export default class TextPresentation extends Component {
constructor(props) { 
    super(props);



   
    
    this.state = {
      view:false,
    }
  }


close(){
    Animated.timing(this.onOffButtonPlace, {toValue: 376,duration: 300, useNativeDriver: false}).start()
    timer.setTimeout("viewOff", () => { this.setState({view:false})  }, 400);
  }

  open(){
    this.setState({view:true},() =>{Animated.timing(this.onOffButtonPlace, {toValue: 0,duration: 300, useNativeDriver: false}).start() })
    
  }

  clickOnNotif(){
    this.props.onNotificationClick(this.props.data)

     /* (this.props.data.type=="chat") */

  }





  render() {

    this.onOffButtonPlace = new Animated.Value(376);

    return (
      <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>


    

              <TouchableHighlight underlayColor={'#FFFFFFFF'} onPress={() => { this.clickOnNotif() }} style={{width:350, borderRadius: 10,borderWidth: 1, borderColor:"#B3B3B3", shadowOpacity: 0.3,shadowRadius: 5,shadowOffset: {height: 0,width: 0},elevation: 1, backgroundColor:style.backgroundColor,}}>
              <View style={{flexDirection:"row", alignItems:"center",height:70,}}>
                  <View>
                    <Image source={(this.props.data.type=="onoff")?require('../assets/switch.png'):((this.props.data.type=="newRace")?require('../assets/futureRace_black.png'):( (this.props.data.type=="liverace")?require('../assets/route_dark.png'):( (this.props.data.type=="chat")?require('../assets/driver_message_dark.png'):"") ))} style={{ width: 45, height: 45, marginLeft:10, marginRight:10}}/>
                  </View>

                  <View style={{width:275}}>
                      <View style={{justifyContent: 'space-between', flexDirection:"row", alignItems:"center" }}>
                          <Text style={{fontSize: style.textInputFontSize*1.2, fontWeight: 'bold' ,color:style.textInputColor, marginTop:-2, }}>{(this.props.data.type=="newRace")?"Nouvelle course":(this.props.data.type=="chat")?"Nouveau message":this.props.data.title}</Text>
                          <Text style={{fontSize: style.textInputFontSize*0.7 ,color:"#B3B3B3"}}>{ ((new Date(this.props.data.date).getHours()<10)?"0":"")+new Date(this.props.data.date).getHours()+":"+((new Date(this.props.data.date).getMinutes()<10)?"0":"")+new Date(this.props.data.date).getMinutes()}</Text>

                      </View>

                      {(this.props.data.type=="chat") && <Text numberOfLines={2} ellipsizeMode='tail' style={{fontSize: style.textInputFontSize*0.8 ,color:style.textInputColor, }}>{this.props.data.text}</Text>}
                      
                      {(this.props.data.type=="newRace" || this.props.data.type=="liverace") && <Text style={{fontSize: style.textInputFontSize*0.8 ,color:style.textInputColor, }}>{ strings.dayList[ (new Date(this.props.data.raceDate).getDay()-1>0)?(new Date(this.props.data.raceDate).getDay()-1):6 ]+". "+ new Date(this.props.data.raceDate).getDate()+" "+ strings.monthList[new Date(this.props.data.raceDate).getMonth()]+" à "+  ((new Date(this.props.data.raceDate).getHours()<10)?"0":"")+new Date(this.props.data.raceDate).getHours()+":"+((new Date(this.props.data.raceDate).getMinutes()<10)?"0":"")+new Date(this.props.data.raceDate).getMinutes()}</Text>}
                      {(this.props.data.type=="newRace"|| this.props.data.type=="liverace") && <Text style={{fontSize: style.textInputFontSize*0.7 ,color:style.textInputColor, }}>{this.props.data.name+" "+((this.props.data.customerPro!="" && this.props.data.customerPro!=undefined)?("( via "+this.props.data.customerPro+")"):((this.props.data.providerID!=undefined && this.props.data.providerID!="" && this.props.data.providerID!=getAuth(firebase).currentUser.uid)?("(via "+this.props.data.provider+")" ):"")) }</Text>}
                  
                  </View>
              </View>
          </TouchableHighlight>


         

    </View>

         }</StyleContext.Consumer>


    	)}

    }

