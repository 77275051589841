import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  TextInput,
  Platform,
  KeyboardAvoidingView,
  Image,
  Dimensions,
  Alert,
} from 'react-native';
import KeyboardAwareScrollView from './keyboardAwareScrollView';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import CommonActions from "./CommonActions";
import firebase from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

import example from '../example';
import MavTextInput from './mavTextInput';
import TextPresentation from './textPresentation';
import MavButton from './mavButton';
import MavAlert from './mavAlert';
import MavViewMore from './mavViewMore';
import MavNavigationMinimize from './mavNavigationMinimize';
import AfterRacePay from './afterRacePay';
import MavSelectDate from './mavSelectDate';
import MavInfoBubble from './mavInfoBubble';
import MavMiniButton from './mavMiniButton';
import ManageOptions from './manageOptions';
import RaceTrajetInfo from './raceTrajetInfo';

import Trajet from './trajet';
const timer = require('react-native-timer');

export default class account extends Component {
  constructor(props) {
    super(props);

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;    



     var estimatePriceCalcFinal=params.data.estimatePrice
     var driverPrice=params.data.driverPrice;
     driverPrice=driverPrice.replace(params.data.money.symbole,"")
     driverPrice=parseFloat(driverPrice);

     
     console.log("params.data.driverPlaceInfoAdmin "+params.data.driverPlaceInfoAdmin)
     console.log(params.data)

     var originalPrice;
     if(params.data.originalPrice==undefined){
      var n = estimatePriceCalcFinal.indexOf("-");
      if(n!=-1) originalPrice=estimatePriceCalcFinal;



     } 






    this.state={ 

      paymentDriverMode:params.data.paymentDriverMode,
      alreadyPaid:params.data.alreadyPaid,
      hidePriceRaceForDriver:params.data.hidePriceRaceForDriver,
      partner:params.data.partner,
      driver:params.data.driver,
      privilegeDriver:params.data.privilegeDriver,
      providerID:params.data.providerID,
      providerName:params.data.providerName,
      providerLastName:params.data.providerLastName,
      racePlaceStartName:params.data.racePlaceStartName,
      racePlaceEndName:params.data.racePlaceEndName,
      clientName:params.data.clientName,
      description:params.data.description,
      customerPro:params.data.customerPro,
      providerBill:params.data.providerBill, 
      customerProName:params.data.customerProName,
      customerProEmail:params.data.customerProEmail,
      customerProLastName:params.data.customerProLastName,
      customerProEnterprise:params.data.customerProEnterprise,
      customerProAdressBill:params.data.customerProAdressBill,
      customerProCityBill:params.data.customerProCityBill,
      providerAdressBill:params.data.providerAdressBill,
      providerCityBill:params.data.providerCityBill,
      clientAdressBill:params.data.clientAdressBill,
      clientCityBill:params.data.clientCityBill,
      clientEnterprise:params.data.clientEnterprise,
      providerEnterprise:params.data.providerEnterprise,
      carName:params.data.carName,
      clientID:params.data.clientID,
      selectedOptions:params.data.selectedOptions, 
      feeOptions:0,
      originalPrice:originalPrice,
      originPrice:params.data.estimatePrice,
      charge:params.data.charge,
      chargeAfterRace:params.data.chargeAfterRace,
      viewStopList:[false, false, false, false, false, false, false, false, false, false, false, false],
      deferredPayment:params.data.deferredPayment,
      money:params.data.money,
      addDriverPrice:params.data.addDriverPrice,
      clientLastName:params.data.clientLastName,
      suggestedDelay:params.suggestedDelay,
      suggestedPause:params.suggestedPause,
      clientPhone:params.data.clientPhone,
      clientEmail:params.data.clientEmail,
      status:params.data.status,
      noVAT:params.data.noVAT,
      estimatePrice:estimatePriceCalcFinal,
      estimateFinalAmount:estimatePriceCalcFinal,
      estimateTimeValue:(params.data.estimateTimeValue/60).toString(),
      raceDate:params.data.raceDate,
      estimateKm:params.data.estimateKm,
      stopList:params.data.stopList,
      driverPrice:driverPrice,
      id:params.data.id,
      orderDate:params.data.orderDate,
      racePlaceEndNameOnStart:params.data.racePlaceEndName,
      noShow:params.data.noShow, 
      driverPlaceInfoAdmin:params.data.driverPlaceInfoAdmin, 
      karhooProvider:params.data.karhooProvider,
      viewDriverPlaceInfoAdmin:(params.data.driverPlaceInfoAdmin!=undefined && params.data.driverPlaceInfoAdmin!=false)?true:false,

      date:((new Date(params.data.raceDate).getDate()<10)?"0":"")+new Date(params.data.raceDate).getDate()+"/"+(((new Date(params.data.raceDate).getMonth()+1)<10)?"0":"")+(new Date(params.data.raceDate).getMonth()+1)+"/"+new Date(params.data.raceDate).getFullYear()+" - "+((new Date(params.data.raceDate).getHours()<10)?"0":"")+new Date(params.data.raceDate).getHours()+":"+((new Date(params.data.raceDate).getMinutes()<10)?"0":"")+ new Date(params.data.raceDate).getMinutes(),
      companyBrand:info.companyBrand,
      companyAdress:info.companyAdress,
      companyCity:info.companyCity,
      companyPhone:info.companyPhone,
      companyEmail:info.companyEmail,
      viewAddStop:false,
      suggestionsEnterprise:undefined,
      forcePaymentOnBoard:"",
      notes:"",
      waiting:false,
      applyFeeDelay:false,
      waitingPrice:{
          pricePerMinuteTTC:"",
          minute:"",
      }, 
      pausePrice:{
        pricePerMinuteTTC:"",
        minute:"",
      }


    }

  }


 
componentDidMount(){
  var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;  

  if (getAuth(firebase).currentUser != null && params.data.attributedPartner==undefined) {
      firebase.database().ref('/users/' + getAuth(firebase).currentUser.uid).once('value').then(value => {
        var val = value.val();
        this.setState({driverName:val.name+" "+val.lastName});
      });
    }
    else if(params.data.attributedPartner!=undefined) this.setState({driverName:params.data.attributedPartner.text})


  this.calculateOptionFee("before");

    
}





verifyCharge(){
  var newPrice=parseFloat(this.state.estimateFinalAmount.toString().replace(this.state.money.symbole,''));

  if(this.state.karhooProvider==true) this.sendBill()
  else if(this.state.charge!=undefined && this.state.charge.amount!=newPrice && this.state.chargeAfterRace==undefined){
    if(newPrice>this.state.charge.amount) {
      var somme=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(newPrice-this.state.charge.amount)+((this.state.money.position=="right")?(this.state.money.symbole):""); 
      var avancedList=[{text: strings.paiementOnBoard,bottomText:(info.paiementOnBoardCard==true)?strings.paymentCash:undefined, help:strings.paiementOnBoardMoreInfo,onPress: () => {this.sendBill()}}];
      if(info.paiementOnBoardCard==true) avancedList.push({ help:strings.paiementOnBoardCardMoreInfo, text:strings.paiementOnBoard,bottomText:strings.paymentCard ,onPress: () => {this.sendBill("cardOnBoard")}} )





      if(this.state.providerID==undefined && this.state.customerPro!=true && info.stripePublicKey!=undefined && info.noSendMailAfterBill!=true) avancedList.push({bottomText:strings.withBill, help:strings.withBillMoreInfo,text:strings.linkPayment ,onPress: () => {this.sendBill(true,(newPrice-this.state.charge.amount))}} )
      if(this.state.providerID==undefined && info.paymentCardApp==true && this.state.customerPro!=true) avancedList.push({bottomText:strings.inApp,help:strings.inAppMoreInfo,text:strings.payment ,onPress: () => { this.afterRacePay.onInfo({driver:true, raceID:this.state.id, amount:(newPrice-this.state.charge.amount),currency:this.state.money, clientID:this.state.clientID }) }} )
      
      if(avancedList.length>1){
        avancedList.push({text: strings.cancel,style:"cancel", onPress: () => {}})
        this.alert.onAlert({largeButton:true, title:strings.userPaidLower+"\n"+strings.amountPaid+" : "+somme,subTitle:strings.whatDoing,buttons:avancedList});
      }

      else this.alert.onAlert({title:strings.userPaidLower+"\n"+strings.amountPaid+" : "+somme,subTitle:strings.paidOnBoard,buttons:[
          {text: strings.yes,onPress: () => {this.sendBill()}},
          {text: strings.cancel,style:"cancel",onPress: () => {}},
          ]});
      
    }
    else{

      var somme=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(this.state.charge.amount-newPrice)+((this.state.money.position=="right")?(this.state.money.symbole):""); 

      this.alert.onAlert({title:strings.userPaidHighter+" ("+somme+")",subTitle:strings.userAlreadyPaidRefundQuetionPart2,buttons:[
          {text: strings.yes,onPress: () => {this.sendRefundRequest(); this.sendBill()}},
          {text: strings.cancel,style:"cancel",onPress: () => {}},
          ]});

     

    }

      

  }
  else if(this.state.deferredPayment!=true && this.state.charge==undefined && this.state.chargeAfterRace==undefined && this.state.alreadyPaid!=true && (this.state.paymentDriverMode!="onlyDriver" && this.state.paymentDriverMode!="driverAndClient")){
    
    var avancedList=[{text: strings.paiementOnBoard, bottomText:(info.paiementOnBoardCard==true)?strings.paymentCash:undefined, help:strings.paiementOnBoardMoreInfo,onPress: () => {this.sendBill()}}];
    if(info.paiementOnBoardCard==true) avancedList.push({help:strings.paiementOnBoardCardMoreInfo, text:strings.paiementOnBoard,bottomText:strings.paymentCard,onPress: () => {this.sendBill("cardOnBoard")}} )
    if(this.state.providerID==undefined && this.state.customerPro!=true && info.stripePublicKey!=undefined && info.noSendMailAfterBill!=true) avancedList.push({bottomText:strings.withBill, help:strings.withBillMoreInfo, text:strings.linkPayment ,onPress: () => {this.sendBill(true,newPrice)}} )
    if(this.state.providerID==undefined && info.paymentCardApp==true && this.state.customerPro!=true) avancedList.push({bottomText:strings.inApp, help:strings.inAppMoreInfo,text:strings.payment ,onPress: () => { this.afterRacePay.onInfo({driver:true, raceID:this.state.id, amount:newPrice,currency:this.state.money, clientID:this.state.clientID }) }} )
    
    if(avancedList.length>1){

      avancedList.push({text: strings.cancel,style:"cancel", onPress: () => {}})
      this.alert.onAlert({largeButton:true, title:strings.clientNotActualyPaid,subTitle:strings.whatDoing,buttons:avancedList, help:{enable:true, title:strings.payment, subTitle:strings.necessairePaid }  });
    }
    else this.alert.onAlert({title:strings.clientNotActualyPaid,subTitle:strings.continueQuestion,buttons:[
          {text: strings.yes,onPress: () => {this.sendBill()}},
          {text: strings.cancel,style:"cancel", onPress: () => {}},
          ]});
   
  } 
  else this.sendBill()

}

sendBill= (withLink, linkPrice) => { 
  var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;  
                  var error=false;

                  var date=this.state.date;
                      date=new Date(date[6]+date[7]+date[8]+date[9]+'-'+date[3]+date[4]+'-'+date[0]+date[1]+'T'+date[13]+date[14]+':'+date[16]+date[17]+':00')
                      date=Date.parse(date)+date.getTimezoneOffset()*60000;

                    var raceDateFixed=new Date(date);
                    raceDateFixed=((raceDateFixed.getDate()<10)?"0":"")+raceDateFixed.getDate()+"/"+(((raceDateFixed.getMonth()+1)<10)?"0":"")+(raceDateFixed.getMonth()+1)+"/"+raceDateFixed.getFullYear()+" - "+((raceDateFixed.getHours()<10)?"0":"")+raceDateFixed.getHours()+"h"+((raceDateFixed.getMinutes()<10)?"0":"")+raceDateFixed.getMinutes();
                    
                    var orderDateFixed=new Date();
                    orderDateFixed=((orderDateFixed.getDate()<10)?"0":"")+orderDateFixed.getDate()+"/"+(((orderDateFixed.getMonth()+1)<10)?"0":"")+(orderDateFixed.getMonth()+1)+"/"+orderDateFixed.getFullYear();

                    var newPrice=parseFloat(this.state.estimateFinalAmount.replace(this.state.money.symbole,''))
                    



                  var sendToFirebase={
                    racePlaceStartName:(this.state.racePlaceStartName!=undefined)?this.state.racePlaceStartName:"",
                    clientName:(this.state.clientName!=undefined)?this.state.clientName:"",
                    clientLastName:(this.state.clientLastName!=undefined)?this.state.clientLastName:"",
                    clientPhone:(this.state.clientPhone!=undefined)?this.state.clientPhone:"",
                    carName:(this.state.carName!=undefined)?this.state.carName:"",
                    description:(this.state.description!=undefined)?this.state.description:"",
                    orderDate:this.state.orderDate,
                    orderDateFixed:orderDateFixed,
                    estimatePrice:((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+newPrice+((this.state.money.position=="right")?(this.state.money.symbole):""),
                    finalPrice:newPrice,
                    
                    driverName:this.state.driverName,
                    estimateTimeValue:parseInt(this.state.estimateTimeValue*60),
                    status:"WaitingInvoice",
                    notes:this.state.notes,

                  }
                  sendToFirebase.stopList=(this.state.stopList!=undefined && this.state.stopList!=[])?this.state.stopList:null;

                  if(this.state.noShow==true) sendToFirebase.noShow=true; 
                  
                  if(this.state.clientEmail!=undefined && this.state.clientEmail!="") sendToFirebase.clientEmail=this.state.clientEmail;

                  if(withLink==true) {
                      sendToFirebase.withPaymentLink=true;
                      sendToFirebase.stripePublicKey=info.stripePublicKey;
                      sendToFirebase.currency=this.state.money.stripeCode;
                      sendToFirebase.linkPrice=linkPrice;

                    }
                  if(withLink=="cardOnBoard") sendToFirebase.cardOnBoard=true; 

                  /*c'est ici qu'on calcul le montant finale de la commission chauffeur */
                  var driverPlaceInfoAdmin =(this.state.viewDriverPlaceInfoAdmin==true)?(parseFloat(this.state.driverPlaceInfoAdmin.toString().replace(this.state.money.symbole,''))):false; 
                  if(driverPlaceInfoAdmin!=false){
                    if(isNaN(driverPlaceInfoAdmin)) error=strings.priceBillError;
                    else sendToFirebase.finalPriceDriverTTCEstimate=driverPlaceInfoAdmin; 
                  } 

                  else if(params.commission!=undefined && params.commission.calculateCommission==true){
                      var driverPrice=parseFloat(newPrice);
                      console.log("je suis ici lol")
                      console.log("this.state.addDriverPrice "+this.state.addDriverPrice)
                      if(this.state.addDriverPrice!=undefined) {driverPrice+=this.state.addDriverPrice; } 
                      console.log("driverPrice "+driverPrice)
                      if(params.commission.addCommissionFixe===true && params.commission.addCommissionFixeAmount>0) driverPrice-=params.commission.addCommissionFixeAmount;
                      if(params.commission.addCommissionVariable===true && params.commission.addCommissionVariableAmount>0) driverPrice=driverPrice*((100-params.commission.addCommissionVariableAmount)/100);
                      if(params.commission.commissionDriver>0) driverPrice=driverPrice*((100-params.commission.commissionDriver)/100);
                      driverPrice=parseFloat(parseFloat(driverPrice).toFixed(2));
                      sendToFirebase.finalPriceDriverTTCEstimate=driverPrice;

                  }


                  if(this.state.applyFeeDelay===true){
                    sendToFirebase.waitingPrice={pricePerMinuteTTC:parseFloat( this.state.waitingPrice.pricePerMinuteTTC.toString().replace(this.state.money.symbole,'')),minute:parseFloat(this.state.waitingPrice.minute)};
                  }

                  if(this.state.applyFeePause===true){
                    sendToFirebase.pausePrice={pricePerMinuteTTC:parseFloat( this.state.pausePrice.pricePerMinuteTTC.toString().replace(this.state.money.symbole,'')),minute:parseFloat(this.state.pausePrice.minute)};
                  }

                  if(this.state.selectedOptions==undefined || this.state.selectedOptions=={}) sendToFirebase.selectedOptions=null
                  else {sendToFirebase.selectedOptions=this.state.selectedOptions; sendToFirebase.feeOptions=this.state.feeOptions}


                  if(this.state.clientID!="" && this.state.clientID!=undefined) sendToFirebase.clientID=this.state.clientID

                  if(this.state.forcePaymentOnBoard!="Paiement à bord du véhicule" && this.state.forcePaymentOnBoard!="" && this.state.forcePaymentOnBoard!="Paiement : à bord du véhicule") sendToFirebase.fpob=this.state.forcePaymentOnBoard



                  if(this.state.racePlaceEndNameOnStart=="" || this.state.racePlaceEndNameOnStart==undefined){
                    sendToFirebase.timeValue=parseInt(this.state.estimateTimeValue*60)
                  }
                  else sendToFirebase.racePlaceEndName=this.state.racePlaceEndName

                  

                  if(sendToFirebase.clientLastName=="") error=strings.clientNameError
                  
                  else if((this.state.racePlaceEndNameOnStart=="" || this.state.racePlaceEndNameOnStart==undefined) && (isNaN(sendToFirebase.timeValue) || sendToFirebase.timeValue==0)) error=strings.timeBillError
                  else if(sendToFirebase.racePlaceStartName=="") error=strings.startBillError
                  else if(this.state.racePlaceEndNameOnStart!="" && this.state.racePlaceEndNameOnStart!=undefined && sendToFirebase.racePlaceEndName=="") error=strings.endBillError
                  else if(sendToFirebase.estimatePrice=="" || isNaN(sendToFirebase.finalPrice)) error=strings.priceBillError 


                  
                  if(this.state.customerPro!=true && this.state.providerBill!=true && this.state.deferredPayment!=true){
                      sendToFirebase.clientEnterprise=(this.state.clientEnterprise!=undefined && this.state.clientEnterprise!="")?this.state.clientEnterprise:null;
                      sendToFirebase.clientAdressBill=(this.state.clientAdressBill!=undefined && this.state.clientAdressBill!="")?this.state.clientAdressBill:null;
                      sendToFirebase.clientCityBill=(this.state.clientCityBill!=undefined && this.state.clientCityBill!="")?this.state.clientCityBill:null;
                  }

                  else if(this.state.customerPro==true && this.state.deferredPayment!=true){
                    sendToFirebase.customerProEnterprise=(this.state.customerProEnterprise!=undefined && this.state.customerProEnterprise!="")?this.state.customerProEnterprise:null;
                    sendToFirebase.customerProName=(this.state.customerProName!=undefined && this.state.customerProName!="")?this.state.customerProName:null;
                    sendToFirebase.customerProLastName=(this.state.customerProLastName!=undefined && this.state.customerProLastName!="")?this.state.customerProLastName:null;
                    sendToFirebase.customerProEmail=(this.state.customerProEmail!=undefined && this.state.customerProEmail!="")?this.state.customerProEmail:null;
                    sendToFirebase.customerProAdressBill=(this.state.customerProAdressBill!=undefined && this.state.customerProAdressBill!="")?this.state.customerProAdressBill:null;
                    sendToFirebase.customerProCityBill=(this.state.customerProCityBill!=undefined && this.state.customerProCityBill!="")?this.state.customerProCityBill:null;

                  } else if(this.state.customerPro!=true && this.state.providerBill==true && this.state.deferredPayment!=true){
                    sendToFirebase.providerEnterprise=(this.state.providerEnterprise!=undefined && this.state.providerEnterprise!="")?this.state.providerEnterprise:null;
                    sendToFirebase.providerName=(this.state.providerName!=undefined && this.state.providerName!="")?this.state.providerName:null;
                    sendToFirebase.providerLastName=(this.state.providerLastName!=undefined && this.state.providerLastName!="")?this.state.providerLastName:null;
                    sendToFirebase.providerEmail=(this.state.providerEmail!=undefined && this.state.providerEmail!="")?this.state.providerEmail:null;
                    sendToFirebase.providerAdressBill=(this.state.providerAdressBill!=undefined && this.state.providerAdressBill!="")?this.state.providerAdressBill:null;
                    sendToFirebase.providerCityBill=(this.state.providerCityBill!=undefined && this.state.providerCityBill!="")?this.state.providerCityBill:null;

                  }



                  if(error!=false) this.alert.onAlert({title:error}); 
                  else {
                  
                  console.log(sendToFirebase);
                  const fireInfo={} 
                  for (const [key, value] of Object.entries(sendToFirebase)) {
                  fireInfo['race/' +this.state.id+"/"+key]=value;
                  }
                  update(ref(getDatabase(firebase)), fireInfo)

                  

                   
                    this.alert.onAlert({title:(this.state.deferredPayment==true)?strings.billGenerateWithDeferredPayment:strings.billEnd,buttons:[{text:strings.ok,onPress: () => {
                     if(Platform.OS=="web") this.props.params.goBack(true);
                     else this.props.navigation.dispatch(CommonActions.reset({index: 0,routes: [{ name: 'Main' },],}) );

                    }}],});

                    

                    

                    
                  }
}

 sendRefundRequest(){
  if(this.state.chargeAfterRace!=undefined) Alert.onAlert({title:"Impossible de rembourser. Utilisez Stripe directement"})
  else {

    var refurn=-1;
    if(this.state.charge.capture==="stripeError") refurn=this.state.charge.creditAmount-parseInt(this.state.estimateFinalAmount)
    else refurn=this.state.charge.amount-parseInt(this.state.estimateFinalAmount);
      
      getAuth(firebase).currentUser.getIdToken().then(token => {
                var body={
                    userID:getAuth(firebase).currentUser.uid,
                    bundleIdentifer:info.bundleIdentifer,
                    partialRefurn:refurn,
                    chargeID:this.state.charge.chargeID,
                    token:token,
                    bundleAdress:info.firebaseProjectID,
                }
                body=JSON.stringify(body);
      
                if(refurn>0) fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/refundRace", {
                        body: body,
                        headers: {"Content-Type": "application/json"},method: "POST"}).then(response => {
                          console.log(response)
                     }).catch((err) => { console.log("error sendRefundRequest"); console.log(err); timer.setTimeout("sendRefundRequest", () => { this.sendRefundRequest();  }, 2000);   })

        })

  }
  


  }


  
                             

   changeDriverPrice = data => {

     this.alert.onAlert({title:strings.priceSettings,textInput:{placeholder:((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"40"+((this.state.money.position=="right")?(this.state.money.symbole):""),text:this.state.driverPlaceInfoAdmin,sizeMultiplicator:2,detectSequencePosition:this.state.money.position,detectSequence:this.state.money.symbole,keyboardType:"numeric"},buttons:[
      {text: strings.validate,onPress: (value) => {
         var estg=value.toString().replace(this.state.money.symbole,'')
        estg=parseFloat(estg);

        if(!isNaN(estg) ){
          this.setState({modifiedDriverPrice:true,driverPlaceInfoAdmin:((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+value.toString().replace(this.state.money.symbole,'')+((this.state.money.position=="right")?(this.state.money.symbole):"")},() =>{ if(data==true) this.verifyCharge() })

        
          
        }
      }},
      {text: strings.cancel, style:"cancel",onPress: () => {}}
      ],})

  }



  sendMessageToAdmin= (data) => {
        /*Envoyer peut être le retard et les pauses calculés ??? */
        this.alert.onAlert({title:strings.sendCommentAdmin,textInput:{placeholder:strings.thankToWriteMessage,text:"", multiline:true},buttons:[
                    {text: strings.send,onPress: (value) => {
                      console.log(value)
                      if(value!=""){
                       
                        /*je mets à jour la course avec les infos */
                        const fireInfo={} 
                        fireInfo['race/' + this.state.id+"/driverMessage/"+Date.parse(new Date())]={text:value }
                        fireInfo['race/' + this.state.id+"/status"]="finish"
                        fireInfo['race/' + this.state.id+"/notes"]=this.state.notes

                        update(ref(getDatabase(firebase)), fireInfo)
                        
                        

                        /*j'envoi un mail aux admins */

                        getAuth(firebase).currentUser.getIdToken().then(token => {
                              var date=date=new Date(this.state.raceDate)
                              date=((date.getDate()<10)?"0":"")+date.getDate()+"/"+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+"/"+date.getFullYear()+" - "+((date.getHours()<10)?"0":"")+date.getHours()+":"+((date.getMinutes()<10)?"0":"")+date.getMinutes()

                              var message= "Bonjour, "+this.state.driverName+" vous a envoyé un message à propos de la course du "+date+". Voici son message : "+value+". Vous pouvez aussi retrouver son message dans l'application ou dans l'interface web."
                              var body={
                                  userID:getAuth(firebase).currentUser.uid,
                                  bundleIdentifer:info.bundleIdentifer, 
                                  message:message,
                                  token:token,
                                  title:"Vous avez un message d'un chauffeur",
                                  bundleAdress:info.firebaseProjectID,
                                  onlyMessage:true,
                              }

                          console.log(JSON.stringify(body))
                          fetch("https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/sendMessageToAdmin", {
                                body:JSON.stringify(body),
                                method: "POST"})

                        })

                        this.alert.onAlert({title:strings.messageHasBeenSend,buttons:[
                            {text: strings.validate,onPress: (value) => { 
                                  if(Platform.OS=="web") this.props.params.goBack(true);
                                  else this.props.navigation.dispatch(CommonActions.reset({index: 0,routes: [{ name: 'Main' },],}) );
                            }}]
                        });

                      } else this.alert.onAlert({title:strings.thankToWriteMessage});
                       
                    }},
                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                    ],})
                      

    }
                          


  changePriceClient = data => {

    console.log("this.state.estimatePrice = "+this.state.estimatePrice)




    var valueText=this.state.estimatePrice;
    var min=undefined
    var max=undefined
    var n = valueText.indexOf("-"); 
    if(n!=-1) {
      valueText=valueText.replace(this.state.money.symbole,'');
      console.log("valueText = "+valueText)
      n = valueText.indexOf("-");
      valueText=valueText.split("-")[0]
      valueText=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+valueText+((this.state.money.position=="right")?(this.state.money.symbole):"")

    }

    if(this.state.originalPrice!=undefined &&  !((this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" )))) ){
      var ori=this.state.originalPrice.replace(this.state.money.symbole,''); 
      n = ori.indexOf("-"); 
      if(n!=-1) {
          min=ori.split("-")[0] 
          max=ori.split("-")[1]
      }
    }
      

     this.alert.onAlert({title:strings.priceSettings,textInput:{placeholder:((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"40"+((this.state.money.position=="right")?(this.state.money.symbole):""),text:valueText,sizeMultiplicator:2,detectSequencePosition:this.state.money.position,detectSequence:this.state.money.symbole,keyboardType:"numeric"},buttons:[
      {text: strings.validate,onPress: (value) => {
         var estg=value.toString().replace(this.state.money.symbole,'')
        estg=parseFloat(estg);
        var error=false; 
        if(min!=undefined){
          if(estg<min || estg>max){
            error=true; 
            this.alert.onAlert({largeButton:true, title:strings.incorrectPrice, subTitle:strings.priceInThisInterval+" "+this.state.originalPrice+". "+strings.alertAdminPrice,buttons:[
                {text: strings.modify,onPress: (value) => { this.changePriceClient() }},
                {text: strings.sendCommentAdmin,onPress: (value) => { this.sendMessageToAdmin() }},
                {text: strings.cancel, style:"cancel",onPress: () => {}}]
            });
          }
        }
        if(!isNaN(estg) && error==false){
          this.setState({changeOriginalPrice:true, estimatePrice:((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+value.toString().replace(this.state.money.symbole,'')+((this.state.money.position=="right")?(this.state.money.symbole):"")},() =>{this.calculateFinalAmount()})
        }
      }},
      {text: strings.cancel, style:"cancel",onPress: () => {}}
      ],})

  }


  changeDuration = data => {
   var estTV= (Math.floor(this.state.estimateTimeValue/60)<10?"0":"")+Math.floor(this.state.estimateTimeValue/60)+":"+((Math.floor((this.state.estimateTimeValue-60*Math.floor(this.state.estimateTimeValue/60))/60)<10)?"0":"")+Math.floor((this.state.estimateTimeValue-60*Math.floor(this.state.estimateTimeValue/60))/60)
    console.log("estimateTimeValue "+this.state.estimateTimeValue)

    this.alert.onAlert({title:strings.duration,textInput:{placeholder:"00:00",text:estTV,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                    {text: strings.validate,onPress: (value) => {
                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ){
                        var valueFinal=(parseInt(value[0]+value[1])*60+parseInt(value[3]+value[4]) )*1;
                        console.log("valueFinal "+valueFinal)
                        this.setState({estimateTimeValue:valueFinal})
                       }
                       else {
                         this.alert.onAlert({title:strings.errorHour,})
                       }
                    }},
                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                    ],})
    
    


  }



   onPricePlaceFrom = (data) => {
    this.setState({racePlaceStartName: data.description})

   
  }

  onPricePlaceStop = (data,i, remove) => {
    var stopList
    if(this.state.stopList==undefined) stopList=[];
    else stopList=JSON.parse(JSON.stringify(this.state.stopList))
    if(remove!=true) stopList[i]=data.description;
    else stopList.splice(i, 1)
    this.setState({stopList:[] },() =>{ this.setState({stopList: stopList}) })
    

  }


  onPricePlaceTo = (data) => {
    this.setState({racePlaceEndName: data.description})
    /*this.onChangeQuestionPrice()*/
  }

  changeSelectedOptions= (name, value, price) => {
    var selectedOptions=this.state.selectedOptions;
    if(selectedOptions==undefined) selectedOptions={}
    if(selectedOptions[name]==undefined) selectedOptions[name]={}
    selectedOptions[name]={value:value, price:price}; 

    this.setState({selectedOptions:selectedOptions},() =>{this.calculateOptionFee()})

  }

  calculateOptionFee(bef){
    var i;
    
    if(this.state.selectedOptions!=undefined && this.state.selectedOptions!=false){
      var selectedOptionsArray=Object.keys(this.state.selectedOptions); 
      var amount=0

      for(i=0; i<selectedOptionsArray.length;i++){

        if(this.state.selectedOptions[selectedOptionsArray[i]].price!=undefined && this.state.selectedOptions[selectedOptionsArray[i]].price>0){
          var nombre=(this.state.selectedOptions[selectedOptionsArray[i]].value==true)?1:this.state.selectedOptions[selectedOptionsArray[i]].value
          if(this.state.selectedOptions[selectedOptionsArray[i]].value!=false) amount+=(this.state.selectedOptions[selectedOptionsArray[i]].price* nombre )
        }
        
        this.setState({feeOptions:amount},() =>{this.calculateFinalAmount()})

        if(bef=="before" && amount>0){
              var valueText=this.state.estimatePrice.toString().replace(this.state.money.symbole,'');
              var n = valueText.indexOf("-");
              if(n!=-1){
                  valueText=(parseFloat(valueText.split("-")[0])+amount)+"-"+(parseFloat(valueText.split("-")[1])+amount)
              }
              else valueText=parseFloat(valueText)-amount;

              valueText=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+valueText+((this.state.money.position=="right")?(this.state.money.symbole):"")

              this.setState({estimatePrice:valueText})
              

        } 

       

      }
    }


  }

  questionModifyPriceDriver= () => {

    this.alert.onAlert({title:strings.modifyPrice, subTitle:strings.updateDriverPrice, 
      buttons:[
          {text: strings.yes,onPress: (value) => { this.changeDriverPrice(true) }},
          {text: strings.no,onPress: () => {this.verifyCharge() }},
          {text: strings.cancel, style:"cancel",onPress: () => {}}

    ]});




    

  }



  calculateFinalAmount = () => {

    var amount=this.state.feeOptions+((this.state.applyFeeDelay==true)?(this.state.waitingPrice.pricePerMinuteTTC*this.state.waitingPrice.minute):0)+((this.state.applyFeePause==true)?(this.state.pausePrice.pricePerMinuteTTC*this.state.pausePrice.minute):0)

    var originalPrice=this.state.estimatePrice;
    var valueText=this.state.estimatePrice.toString().replace(this.state.money.symbole,'');
    var n = valueText.indexOf("-");
    if(n!=-1){
        valueText=(parseFloat(valueText.split("-")[0])+amount)+"-"+(parseFloat(valueText.split("-")[1])+amount)
    }
    else valueText=parseFloat(valueText)+amount;

    valueText=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+valueText+((this.state.money.position=="right")?(this.state.money.symbole):"")

    this.setState({estimateFinalAmount:valueText})

  }

  changeWaitClient = (data) => {


    var buttons=[
    {text: strings.validate,onPress: (value) => {
         var car=(data=="pause")?this.state.pausePrice:this.state.waitingPrice;
        car.minute=parseInt(value);
        if(isNaN(car.minute)) car.minute=0;

        if(data=="pause") this.setState({pausePrice: car })
        else this.setState({waitingPrice: car })

        

        this.alert.onAlert({title:strings.priceDelay, textInput:{placeholder:((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"1"+((this.state.money.position=="right")?(this.state.money.symbole):"") ,text:( (data=="pause")? ((this.state.pausePrice.pricePerMinuteTTC!=undefined && this.state.pausePrice.pricePerMinuteTTC!="")?(this.state.pausePrice.pricePerMinuteTTC):"" ) : ((this.state.waitingPrice.pricePerMinuteTTC!=undefined && this.state.waitingPrice.pricePerMinuteTTC!="")?(this.state.waitingPrice.pricePerMinuteTTC):"" )).toString(),sizeMultiplicator:2, detectSequencePosition:this.state.money.position,detectSequence:this.state.money.symbole,  keyboardType:"numeric"},buttons:[
          {text: strings.validate,onPress: (value) => {
                value=value.replace(this.state.money.symbole,'')
                if(value=="") value=0; 
                var car=(data=="pause")?this.state.pausePrice:this.state.waitingPrice;
                car.pricePerMinuteTTC=value
                if(data=="pause") this.setState({pausePrice: car, applyFeePause:true },() =>{this.calculateFinalAmount()})
                else this.setState({waitingPrice: car, applyFeeDelay:true },() =>{this.calculateFinalAmount()}) 

             }},
            {text: strings.cancel, style:"cancel",onPress: () => {}}
          ],})

        
      }}
    ]

    if(data=="pause"){
        if(this.state.pausePrice.minute!=undefined && this.state.pausePrice.minute!="") buttons.push({text: strings.remove, bottomText:strings.pause, style:"cancel",onPress: () => { 
          this.setState({applyFeePause:false, pausePrice:{pricePerMinuteTTC:"",minute:"", }},() =>{this.calculateFinalAmount() })
        }})
    }
    else if(this.state.waitingPrice.minute!=undefined && this.state.waitingPrice.minute!="") buttons.push({text: strings.remove, bottomText:strings.clientHasDelay, style:"cancel",onPress: () => { 
      this.setState({applyFeeDelay:false, waitingPrice:{pricePerMinuteTTC:"",minute:"", }},() =>{this.calculateFinalAmount() })
    }})

    buttons.push({text: strings.cancel, style:"cancel",onPress: () => {}})

    
     this.alert.onAlert({title:(data=="pause")?strings.delayPauseWhatduration:strings.delayWhatduration, subTitle:(data=="pause")?((this.state.suggestedPause!=undefined && this.state.suggestedPause>3)?(strings.delaySuggestion+" : "+this.state.suggestedPause+" "+strings.minutes ):undefined):((this.state.suggestedDelay!=undefined && this.state.suggestedDelay>3)?(strings.delaySuggestion+" : "+this.state.suggestedDelay+" "+strings.minutes ):undefined), textInput:{placeholder:'30',text:( (data=="pause")?((this.state.pausePrice.minute!=undefined && this.state.pausePrice.minute!="")?(this.state.pausePrice.minute):( (this.state.suggestedPause!=undefined && this.state.suggestedPause>3)?(this.state.suggestedPause):"" )):((this.state.waitingPrice.minute!=undefined && this.state.waitingPrice.minute!="")?(this.state.waitingPrice.minute):( (this.state.suggestedDelay!=undefined && this.state.suggestedDelay>3)?(this.state.suggestedDelay):"" ))).toString(),sizeMultiplicator:2, addText:strings.minutes.toLowerCase(), keyboardType:"numeric"},buttons:buttons,})

  



      
  }





  render() {

    const {rowData, active} = this.props;
   


    return (
      <StyleContext.Consumer>{style => 
      <KeyboardAwareScrollView extraHeight={100} innerRef={ref => {this.scroll = ref}} scrollEnabled={true} style={{ marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor }}>

            {(Platform.OS=="web") && <MavNavigationMinimize clickLeft={() => { var params=(this.props.navigation!=undefined)?this.props.navigation:this.props.params; params.goBack();  }} left={strings.return} title={ strings.generateBill } />}


              <MavInfoBubble type={"warning"} title={strings.warningInfos} subTitle={strings.billCaution} style={{marginTop:20,}} />


              {!((this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" )))) && <MavInfoBubble type={"warning"} title={strings.notModify} subTitle={strings.notModifyMoreInfo+( (this.state.originalPrice!=undefined)?(" "+strings.notModifyMoreInfoPriceInverval):"" )} style={{marginTop:10,}} />}





              <TextPresentation style={{marginTop:5}} textExplication={strings.principalCustomer.toUpperCase()} text={((this.state.clientName!=undefined)?(this.state.clientName+" "):"")+((this.state.clientLastName!=undefined)?this.state.clientLastName:"")} editable={false} disableChild={!(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="max" || this.state.privilegeDriver=="avance")))} icon={(style.mode=="light")?require('../assets/user_black.png'):require('../assets/user_white.png')} >

                
                      <TextPresentation style={{marginTop:5}} text={this.state.clientName} textExplication={strings.name} placeholder={example.name} onChangeText={(text)=>{this.setState({clientName: text })}}/>
                      <TextPresentation  text={this.state.clientLastName} textExplication={strings.lastName} textInputFlex={2} placeholder={example.lastName} onChangeText={(text)=>{this.setState({clientLastName: text })}}/>
                           
                      {(this.state.customerPro!=true && this.state.providerBill!=true && this.state.deferredPayment!=true) && <TextPresentation text={this.state.clientEmail} editable={true} textExplication={strings.email} textInputFlex={2} placeholder={example.email} onChangeText={(text)=>{this.setState({clientEmail: text })}}/>}

                       {(this.state.customerPro!=true && this.state.providerBill!=true && this.state.deferredPayment!=true) && <TextPresentation suggestions={(info.countryCode=="fr")?"enterprise":undefined}  text={(this.state.clientEnterprise!=undefined)?this.state.clientEnterprise:""} editable={true} textExplication={strings.enterprise} textInputFlex={2} placeholder={example.company} onChangeText={(text)=>{ this.setState({clientEnterprise: text })  }} onSuggestion={(value)=>{ this.setState({clientEnterprise: value.name, clientAdressBill:value.adress, clientCityBill:value.city })  }} />}                 
                       {(this.state.customerPro!=true && this.state.providerBill!=true && this.state.deferredPayment!=true) && <TextPresentation  text={(this.state.clientAdressBill!=undefined)?this.state.clientAdressBill:""} editable={true} textExplication={strings.adressBill} textInputFlex={2} placeholder={example.adress} onChangeText={(text)=>{this.setState({clientAdressBill: text })}}/>}                 
                       {(this.state.customerPro!=true && this.state.providerBill!=true && this.state.deferredPayment!=true) && <TextPresentation  text={(this.state.clientCityBill!=undefined)?this.state.clientCityBill:""}  editable={true} textExplication={strings.cityBill} textInputFlex={2} placeholder={info.companyCity} onChangeText={(text)=>{this.setState({clientCityBill: text })}}/>}
                       

              </TextPresentation>



              {(this.state.customerPro==true || this.state.providerBill==true) && <TextPresentation style={{marginTop:5}} textExplication={strings.principalCustomer.toUpperCase()} text={(this.state.customerPro!=true && this.state.providerBill==true)?((this.state.providerEnterprise!=undefined)?this.state.providerEnterprise:(this.state.providerName+" "+this.state.providerLastName)):(this.state.customerProEnterprise!=undefined)?this.state.customerProEnterprise:(this.state.customerProName+" "+this.state.customerProLastName)} editable={false} disableChild={!(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="max" || this.state.privilegeDriver=="avance")))} icon={(this.state.customerPro==true)?((style.mode=="light")?require('../assets/user_pro_black.png'):require('../assets/user_pro_white.png')):((style.mode=="light")?require('../assets/user_partner_black.png'):require('../assets/user_partner_white.png'))} >

                      {(this.state.customerPro==true ) && <TextPresentation style={{marginTop:15}} text={(this.state.customerProEnterprise!=undefined)?this.state.customerProEnterprise:""} suggestions={(info.countryCode=="fr")?"enterprise":undefined} editable={true} textExplication={strings.enterprise} textInputFlex={2} placeholder={"Ma Sociéte SAS"} onChangeText={(text)=>{this.setState({customerProEnterprise: text })}} onSuggestion={(value)=>{ this.setState({customerProEnterprise: value.name, customerProAdressBill:value.adress, customerProCityBill:value.city })  }}/>}
                      {(this.state.customerPro==true ) && <TextPresentation style={{marginTop:5}}  text={(this.state.customerProName!=undefined)?this.state.customerProName:""} textExplication={strings.name} textInputFlex={2} placeholder={example.name} onChangeText={(text)=>{this.setState({customerProName: text })}}/>}
                      {(this.state.customerPro==true ) && <TextPresentation style={{marginTop:5}} text={(this.state.customerProLastName!=undefined)?this.state.customerProLastName:""} textExplication={strings.lastName} textInputFlex={2} placeholder={example.lastName} onChangeText={(text)=>{this.setState({customerProLastName: text })}}/>}
                      {(this.state.customerPro==true ) && <TextPresentation style={{marginTop:5}} text={(this.state.customerProEmail!=undefined)?this.state.customerProEmail:""} textExplication={strings.email} textInputFlex={2} placeholder={example.email} onChangeText={(text)=>{this.setState({customerProEmail: text })}}/>}
                      {(this.state.customerPro==true ) && <TextPresentation style={{marginTop:5}} text={(this.state.customerProAdressBill!=undefined)?this.state.customerProAdressBill:""} editable={true} textExplication={strings.adressBill} textInputFlex={2} placeholder={example.adress} onChangeText={(text)=>{this.setState({customerProAdressBill: text })}}/>  }              
                      {(this.state.customerPro==true ) && <TextPresentation style={{marginTop:5}} text={(this.state.customerProCityBill!=undefined)?this.state.customerProCityBill:""} disableLine={true} editable={true} textExplication={strings.cityBill} textInputFlex={2} placeholder={info.companyCity} onChangeText={(text)=>{this.setState({customerProCityBill: text })}}/>}
                  


                      {(this.state.customerPro!=true ) && <TextPresentation style={{marginTop:15}} text={(this.state.providerEnterprise!=undefined)?this.state.providerEnterprise:""} editable={true} suggestions={(info.countryCode=="fr")?"enterprise":undefined} textExplication={strings.enterprise} textInputFlex={2} placeholder={"Ma Sociéte SAS"} onChangeText={(text)=>{this.setState({providerEnterprise: text })}} onSuggestion={(value)=>{ this.setState({providerEnterprise: value.name, providerAdressBill:value.adress, providerCityBill:value.city })  }}/>}
                      {(this.state.customerPro!=true ) && <TextPresentation style={{marginTop:5}} text={(this.state.providerName!=undefined)?this.state.providerName:""} textExplication={strings.name} textInputFlex={2} placeholder={example.name} onChangeText={(text)=>{this.setState({providerName: text })}}/>}
                      {(this.state.customerPro!=true ) && <TextPresentation style={{marginTop:5}} text={(this.state.providerLastName!=undefined)?this.state.providerLastName:""} textExplication={strings.lastName} textInputFlex={2} placeholder={example.lastName} onChangeText={(text)=>{this.setState({providerLastName: text })}}/>}
                      {(this.state.customerPro!=true ) && <TextPresentation style={{marginTop:5}} text={(this.state.providerEmail!=undefined)?this.state.providerEmail:""} textExplication={strings.email} textInputFlex={2} placeholder={example.company} onChangeText={(text)=>{this.setState({providerEmail: text })}}/>}
                      {(this.state.customerPro!=true ) && <TextPresentation style={{marginTop:5}} text={(this.state.providerAdressBill!=undefined)?this.state.providerAdressBill:""} editable={true} textExplication={strings.adressBill} textInputFlex={2} placeholder={example.adress} onChangeText={(text)=>{this.setState({providerAdressBill: text })}}/>}              
                      {(this.state.customerPro!=true ) && <TextPresentation style={{marginTop:5}} text={(this.state.providerCityBill!=undefined)?this.state.providerCityBill:""} disableLine={true} editable={true} textExplication={strings.cityBill} textInputFlex={2} placeholder={info.companyCity} onChangeText={(text)=>{this.setState({providerCityBill: text })}}/>}



              </TextPresentation>}



             <TextPresentation style={{marginTop:5}} icon={((style.mode=="light")?require('../assets/partner_dark.png'):require('../assets/partner_light.png'))}  text={this.state.driverName} editable={(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="max" || this.state.privilegeDriver=="avance")))} textExplication={strings.driverInfo} placeholder={example.name+" "+example.lastName} onChangeText={(text)=>{this.setState({driverName: text })}}/>


             <TextPresentation style={{marginTop:5}} icon={(style.mode=="light")?require('../assets/calendar_black.png'):require('../assets/calendar_white.png')} viewEdit={(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="max" || this.state.privilegeDriver=="avance")))}  text={((new Date(this.state.raceDate).getDate()<10)?"0":"")+new Date(this.state.raceDate).getDate()+" "+strings.monthList[new Date(this.state.raceDate).getMonth()].toLowerCase()} editable={false} textExplication={strings.race} onEditClick={(text)=>{ this.selectDate.onPicker(new Date(this.state.raceDate))   }}/>




             <View style={{marginLeft:'5%', marginRight:'5%',marginTop:15,}} > 
                  <RaceTrajetInfo changeDuration={this.changeDuration} addStopAutorization={( (this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && this.state.viewAddStop==false && this.state.racePlaceEndName!=undefined)} goReturnTaxi={this.state.goReturnTaxi} estimateTimeValue={this.state.estimateTimeValue*60} racePlaceEndName={this.state.racePlaceEndName} onPricePlaceFrom={this.onPricePlaceFrom} onPricePlaceTo={this.onPricePlaceTo} onPricePlaceStop={this.onPricePlaceStop} stopList={this.state.stopList} racePlaceStartName={this.state.racePlaceStartName} raceDate={this.state.raceDate} disable={!(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" )))} /> 
             </View>



                   <ManageOptions money={this.state.money} disableChange={!(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" )))} selectedOptions={this.state.selectedOptions} onSelectedOption={(name, value, price)=>{ this.changeSelectedOptions(name,value, price);  }}  style={{marginTop:10}} />



                    {(this.state.hidePriceRaceForDriver!=true || (this.state.deferredPayment!=true && this.state.karhooProvider!=true && this.state.charge==undefined && this.state.alreadyPaid!=true && (this.state.paymentDriverMode!="onlyDriver" && this.state.paymentDriverMode!="driverAndClient")) || this.state.originalPrice!=undefined) && <TextPresentation style={{marginTop:10}} textExplication={strings.amount+((this.state.noVAT==true)?" HT":"")} text={this.state.estimateFinalAmount} colorNotification={(this.state.originalPrice!=undefined && this.state.changeOriginalPrice!=true)?true:undefined} colorWaitingOnlyRight={((this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && this.state.originalPrice==undefined && (((this.state.suggestedDelay!=undefined && this.state.suggestedDelay>3) && !(this.state.waitingPrice!=undefined && this.state.waitingPrice.pricePerMinuteTTC!=undefined && this.state.waitingPrice.pricePerMinuteTTC!=""))) || ((this.state.suggestedPause!=undefined && this.state.suggestedPause>3) && !(this.state.pausePrice!=undefined && this.state.pausePrice.pricePerMinuteTTC!=undefined && this.state.pausePrice.pricePerMinuteTTC!="")) ) } editable={false} icon={(this.state.originalPrice!=undefined && this.state.changeOriginalPrice!=true)?require('../assets/warning.png'):( ((this.state.deferredPayment==true || this.state.karhooProvider==true || this.state.alreadyPaid==true)?((style.mode=="light")?require('../assets/waiting_black.png'):require('../assets/waiting_white.png')): ( (this.state.charge==undefined)?(style.mode=="light")?require('../assets/payOnBoard_black.png'):require('../assets/payOnBoard_white.png'): ((style.mode=="light")?require('../assets/cardCB_black.png'):require('../assets/cardCB_white.png')) )))}
                           disableChild={!(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" )))}
                           viewEdit={(!(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && this.state.originalPrice!=undefined)?true:undefined}
                           onEditClick={(this.state.originalPrice!=undefined)?(   ()=>{ this.changePriceClient() } ) :undefined}

                           elementRight={(this.state.originalPrice!=undefined && this.state.changeOriginalPrice!=true)?(
                                      <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.changePriceClient() }} >
                                        <View>
                                            <Text style={{marginLeft:-2,color: style.colorNotification,fontSize: style.textInputFontSize*1.1,textAlign: "right",}}>{ strings.finalPriceNecessary}</Text>
                                            <Text style={{marginLeft:-2,color: style.colorNotification,fontSize: style.textInputFontSize*0.75,textAlign: "right",marginTop:-2}}>{strings.modifyNow}</Text>
                                        </View>
                                      </TouchableHighlight>

                                    ) :(((this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && ( (this.state.suggestedDelay!=undefined && this.state.suggestedDelay>3) && !(this.state.waitingPrice!=undefined && this.state.waitingPrice.pricePerMinuteTTC!=undefined && this.state.waitingPrice.pricePerMinuteTTC!="")) || ((this.state.suggestedPause!=undefined && this.state.suggestedPause>3) && !(this.state.pausePrice!=undefined && this.state.pausePrice.pricePerMinuteTTC!=undefined && this.state.pausePrice.pricePerMinuteTTC!="")) )?
                                    <View>
                                      <Text style={{marginLeft:-2,color: style.colorWaiting,fontSize: style.textInputFontSize*1.1,textAlign: "right",}}>{ (((this.state.suggestedDelay!=undefined && this.state.suggestedDelay>3)?strings.clientHasDelay:strings.pause)) + ((this.state.suggestedDelay!=undefined && this.state.suggestedDelay>3 && this.state.suggestedPause>3)?(" + "+strings.pause.toLowerCase()):(" "+ ((this.state.suggestedPause>3)?this.state.suggestedPause:this.state.suggestedDelay)+" "+strings.minutes.toLowerCase()))}</Text>
                                      <Text style={{marginLeft:-2,color: style.colorWaiting,fontSize: style.textInputFontSize*0.75,textAlign: "right",marginTop:-2}}>{strings.billAgree}</Text>

                                    </View>
                                   :undefined) }


                       >

                        <TextPresentation textExplication={strings.racePrice} textExplicationSmall={strings.racePriceMoreInfo} textRight={this.state.estimatePrice} editable={false} onTextRightClick={()=>{ this.changePriceClient() }} icon={(style.mode=="dark")?require('../assets/route_light.png'):require('../assets/route_dark.png')} />
                        {(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && <TextPresentation textExplication={strings.feeDelay} textExplicationSmall={strings.feeDelayMoreInfo} textRight={(this.state.applyFeeDelay==true)? (((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(this.state.waitingPrice.pricePerMinuteTTC*this.state.waitingPrice.minute)+((this.state.money.position=="right")?(this.state.money.symbole):"")):"--"} colorWaiting={( (!(this.state.waitingPrice!=undefined && this.state.waitingPrice.pricePerMinuteTTC!=undefined && this.state.waitingPrice.pricePerMinuteTTC!="") && (this.state.suggestedDelay!=undefined && this.state.suggestedDelay>3) )? true :undefined)} editable={false} onTextRightClick={()=>{ this.changeWaitClient() }} icon={ (!(this.state.waitingPrice!=undefined && this.state.waitingPrice.pricePerMinuteTTC!=undefined && this.state.waitingPrice.pricePerMinuteTTC!="") && (this.state.suggestedDelay!=undefined && this.state.suggestedDelay>3) )?require('../assets/delay_warning.png')  :((style.mode=="dark")?require('../assets/delay_white.png'):require('../assets/delay_black.png'))} />}

                        {(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && <TextPresentation textExplication={strings.feePause} textExplicationSmall={strings.feePauseMoreInfo} textRight={(this.state.applyFeePause==true)? (((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(this.state.pausePrice.pricePerMinuteTTC*this.state.pausePrice.minute)+((this.state.money.position=="right")?(this.state.money.symbole):"")):"--"} colorWaiting={( (!(this.state.pausePrice!=undefined && this.state.pausePrice.pricePerMinuteTTC!=undefined && this.state.pausePrice.pricePerMinuteTTC!="") && (this.state.suggestedPause!=undefined && this.state.suggestedPause>3) )? true :undefined)} editable={false} onTextRightClick={()=>{ this.changeWaitClient("pause") }} icon={ (!(this.state.pausePrice!=undefined && this.state.pausePrice.pricePerMinuteTTC!=undefined && this.state.pausePrice.pricePerMinuteTTC!="") && (this.state.suggestedPause!=undefined && this.state.suggestedPause>3) )?require('../assets/delay_pause_waiting.png')  :((style.mode=="dark")?require('../assets/delay_pause_white.png'):require('../assets/delay_pause_black.png'))} />}




                        {((this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && this.state.feeOptions>0) && <TextPresentation textExplication={strings.optionsFee} textExplicationSmall={strings.optionsFeeMoreInfo} textRight={(((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+(this.state.feeOptions)+((this.state.money.position=="right")?(this.state.money.symbole):""))} icon={(style.mode=="dark")?require('../assets/options_white.png'):require('../assets/options_black.png')} />}





                        {(this.state.viewDriverPlaceInfoAdmin==true && this.state.driver==true) && <TextPresentation textExplication={strings.priceDriver} textExplicationSmall={strings.priceDriverMoreInfo} textRight={(this.state.driverPlaceInfoAdmin!=undefined && this.state.driverPlaceInfoAdmin!="")?this.state.driverPlaceInfoAdmin:"--"} editable={true} onTextRightClick={()=>{ this.changeDriverPrice() }} icon={(style.mode=="dark")?require('../assets/route_light.png'):require('../assets/route_dark.png')} />}

                      </TextPresentation>}

                      <TextPresentation style={{marginTop:5}} textExplication={strings.notes} text={(this.state.notes!="" || this.state.forcePaymentOnBoard!="")?strings.personnalized:strings.byDefault} editable={false} icon={(style.mode=="dark")?require('../assets/driver_bon_light.png'):require('../assets/driver_bon_dark.png')} >
                                {(((this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && this.state.deferredPayment!=true && this.state.charge==undefined && this.state.alreadyPaid!=true)) && <TextPresentation style={{marginTop:5}} textExplication={strings.forcePaymentOnBoard} text={this.state.forcePaymentOnBoard}  editable={true} placeholder={strings.textDefault} onChangeText={(text)=>{ this.setState({forcePaymentOnBoard:text})  }}  />}
                                <TextPresentation style={{marginTop:5}} textExplication={strings.notes} text={this.state.notes} multiline={true}  editable={true} placeholder={strings.otherInfoBill} onChangeText={(text)=>{this.setState({notes:text})}} />
                      </TextPresentation>


                      <View style={{alignItems:"center",justifyContent:"center", marginTop:50}}>
                          <MavButton text={strings.validate} large={true} onlyButton={true} onPress={()=>{ 
                            if ((this.state.viewDriverPlaceInfoAdmin==true && this.state.driver==true) && (this.state.originPrice!=this.state.estimateFinalAmount) && this.state.modifiedDriverPrice!=true && (this.state.driverPlaceInfoAdmin!=undefined && this.state.driverPlaceInfoAdmin!="") ) this.questionModifyPriceDriver() 
                            else this.verifyCharge();  
                          }}/>
                      </View>

                      {!(this.state.driver==true || (this.state.partner==true && (this.state.privilegeDriver=="avance" || this.state.privilegeDriver=="max" ))) && <View style={{alignItems:"center",justifyContent:"center", marginTop:10}}>
                          <MavButton text={strings.sendCommentAdmin} large={true} onlyButton={true} onPress={()=>{  this.sendMessageToAdmin();   }}/>
                      </View>}

                       





                      <View style={{width:100, height:50}} />

              


        
        <MavSelectDate 
            minDate={(this.state.roundTrip==true && this.state.date!="")?this.state.date:this.state.dateMin}
            maxDate={(this.state.roundTrip==true && this.state.date2!="")?this.state.date2:"2100-01-01"}
        
            onDateChange={dateTxt => { 

                var date=dateTxt;
                date=new Date(date[6]+date[7]+date[8]+date[9]+'-'+date[3]+date[4]+'-'+date[0]+date[1]+'T'+date[13]+date[14]+':'+date[16]+date[17]+':00Z')
                date=Date.parse(date)+date.getTimezoneOffset()*60000;


                 var date=dateTxt;
                date=new Date(date[6]+date[7]+date[8]+date[9]+'-'+date[3]+date[4]+'-'+date[0]+date[1]+'T'+date[13]+date[14]+':'+date[16]+date[17]+':00Z')
                date=Date.parse(date)+date.getTimezoneOffset()*60000;


                this.setState({ date: dateTxt,raceDate:date});


          
            }}

            ref={select => {this.selectDate = select;}}/>
        
        
          
        <AfterRacePay params={{  sendBill: this.sendBill, }}  ref={afterRacePay => {this.afterRacePay = afterRacePay;}}/>
        
        <MavAlert ref={alert => {this.alert = alert;}}/>

      </KeyboardAwareScrollView>
      }</StyleContext.Consumer>
    );
  }
}



export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
