import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';


export default class MavButton extends Component {

  render() {
    return (
    <StyleContext.Consumer>{style => 
      <View style={(this.props.style==undefined)?{}:this.props.style}>
          <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { if(this.props.onPress!=null) this.props.onPress(); }}>
              <View style={{alignItems:"center"}}>

                  <View style={{shadowOpacity: 0.5, shadowColor:(style.mode=="dark")?'#A0A2A2':"#4D4D4D", shadowRadius: 0.8,shadowOffset: {height: 0,width: 0},elevation: 1, width:((Dimensions.get('window').width>style.mobileSizeMax)?style.buttonHeight*0.85:style.buttonHeight)*((this.props.maximize==true)?1.2:1)*((this.props.minimize==true)?0.7:1), height:((Dimensions.get('window').width>style.mobileSizeMax)?style.buttonHeight*0.85:style.buttonHeight)*((this.props.maximize==true)?1.2:1)*((this.props.minimize==true)?0.7:1), backgroundColor:(this.props.strokeMode!=true)?((this.props.color!=undefined)?this.props.color:style.color1):undefined,borderColor:(this.props.color!=undefined)?this.props.color:style.color1,borderWidth:1,borderRadius:(((Dimensions.get('window').width>style.mobileSizeMax)?style.buttonHeight*0.85:style.buttonHeight)/2)*((this.props.maximize==true)?1.2:1)*((this.props.minimize==true)?0.7:1), marginBottom:2, alignItems:"center", justifyContent:"center"}}  >
                      {this.props.icon!=undefined && <Image source={this.props.icon} style={{shadowOpacity: 0.2, shadowColor:(style.mode=="dark")?'#A0A2A2':"#4D4D4D", shadowRadius: 0.8,shadowOffset: {height: 0,width: 0}, width: (((Dimensions.get('window').width>style.mobileSizeMax)?style.buttonHeight*0.85:style.buttonHeight)/2)*((this.props.maximize==true)?1.2:1)*((this.props.minimize==true)?0.7:1), height: (((Dimensions.get('window').width>style.mobileSizeMax)?style.buttonHeight*0.85:style.buttonHeight)/2)*((this.props.maximize==true)?1.2:1)*((this.props.minimize==true)?0.7:1),}} />}
                      {this.props.iconText!=undefined && <Text style={{color:style.backgroundColor,fontSize:style.textInputFontSize*(((this.props.iconText).toString().length<2)?2.1:1.3), textAlign:"center", width:50,}}>{this.props.iconText}</Text>}

                  </View>

                  {this.props.badgeNotification!=undefined && <View style={{width:25, height:25, backgroundColor:"#FF0000", position:"absolute", alignItems:"center", justifyContent:"center", right:(this.props.maximize==true)?5:0, marginTop:(this.props.maximize==true)?-5:-10,shadowOpacity: 0.5, shadowRadius: 0.8,shadowOffset: {height: 0,width: 0},elevation: 1, borderRadius:12.5  }}>
                      <Text style={{color:"#FFFFFF",fontWeight: 'bold', fontSize:style.textInputFontSize, textAlign:"center",}}>{this.props.badgeNotification}</Text>
                  </View>}

                  <Text numberOfLines={2} style={{shadowOpacity: 0.2, marginTop:(this.props.minimize==true)?-2:undefined, shadowColor:(style.mode=="dark")?'#A0A2A2':"#4D4D4D", shadowRadius: 0.8,shadowOffset: {height: 0,width: 0},elevation: 1,color:(this.props.colorText!=undefined)?this.props.colorText:style.textInputColor,fontSize:(style.textInputFontSize*((Dimensions.get('window').width>style.mobileSizeMax)?0.7:1)*0.8)*((this.props.maximize==true)?1.2:1)*((this.props.minimize==true)?0.65:1), textAlign:"center", width:55*((this.props.maximize==true)?1.5:1)*((this.props.minimize==true)?0.7:1)*((this.props.maximizeText==true)?1.3:1),}}>{this.props.text}</Text>
                 
              </View>

          </TouchableHighlight>
    </View>

    }</StyleContext.Consumer>
      )}

    }

