import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Image,
  Picker,
  Platform,
  ScrollView
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';
import strings from '../strings';
const timer = require('react-native-timer');
import Modal from './modal';


 
export default class MavTextInput extends Component {

  state = {
    paymentValidate:true,
    hideWebView:false, 
  };

  
   


  render() {
    
    return (
    <StyleContext.Consumer>{style => 
     <Modal
          animationType="none"
          transparent={true}
          visible={true}>
          <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",alignItems:'center',justifyContent:'center',backgroundColor:style.backgroundColor}}>

            

            <View style={{height:Dimensions.get('window').height*0.8,width:Dimensions.get('window').width*0.8,}}>

            {(this.state.hideWebView!=true && this.props.urlRedirect!=undefined && Platform.OS=="web") && <iframe src={this.props.urlRedirect} title="Stripe" width="100%" height="100%"></iframe>}


                {(this.state.hideWebView!=true && this.props.urlRedirect!=undefined && Platform.OS!="web") && <WebView style={{width:"100%", height:'100%' }} source={{ uri: this.props.urlRedirect}} onLoadProgress={({ nativeEvent }) => {console.log("onLoadProgress"); console.log(nativeEvent); console.log("path = "+nativeEvent) }}


                 onNavigationStateChange={(path) => {
                  
                  if(path.url=="http://www.mon-appli-vtc.com/stripeURL" || path.url=="https://www.blank.org") {this.setState({hideWebView:true})}

                 }}/>}

               {(this.state.hideWebView==true || this.props.urlRedirect==undefined) && <View style={{alignItems:"center", justifyContent:"center", height:Dimensions.get('window').height*0.8,width:Dimensions.get('window').width*0.8,}}> 
                    <View style={{alignItems:'center',justifyContent:'center',marginTop:15,width:50,height:50, borderRadius:25,borderWidth:1, borderColor:(this.props.status=="fail")?"#9a0000":((this.props.status=="validate")?"#009a00":style.textInputColor)}}> 
                        {this.props.status=="validate" && <Text style={{color: "#009a00",fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>}
                        {this.props.status=="fail" && <Text style={{color: "#9a0000",fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"X"}</Text>}
                      </View>

                      <Text style={{marginTop:15,marginLeft:15,marginRight:15, color: (this.props.status=="validate")?style.colorValidate:( (this.props.status=="fail")?style.colorNotification:style.textInputColor),fontSize: style.textInputFontSize*1.1,textAlign: "center",}}>{(this.props.status=="validate")?strings.acceptedPayment:( (this.props.status=="fail")?strings.errorCard:strings.transactionTraitement)}</Text>





                </View>}

            </View>

            {false && <View style={{alignItems:'center',justifyContent:'center',height:Dimensions.get('window').height*0.3,width:Dimensions.get('window').width*0.7,backgroundColor:style.backgroundColorContrast}} >
                <Text style={{marginLeft:15,marginRight:15, color: style.textInputColor,fontSize: style.textInputFontSize*1.5,textAlign: "center",}}>{strings.validate3DSecure}</Text>
                <Text style={{marginTop:15,marginLeft:15,marginRight:15, color: style.textInputColor,fontSize: style.textInputFontSize*1.1,textAlign: "center",}}>{strings.validatePaiementToContinue}</Text>

                <View style={{alignItems:'center',justifyContent:'center',marginTop:15,width:50,height:50, borderRadius:25,borderWidth:1, borderColor:(this.props.status=="fail")?"#9a0000":((this.props.status=="validate")?"#009a00":style.textInputColor)}}> 
                  {this.props.status=="validate" && <Text style={{color: "#009a00",fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>}
                  {this.props.status=="fail" && <Text style={{color: "#9a0000",fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"X"}</Text>}
                </View>

            </View>}

              
 
        </View>


        </Modal>
        }</StyleContext.Consumer>

      
    	)}

    }

