import React, { Component } from 'react';
import {
View,
  Text,
  ScrollView,TouchableHighlight,FlatList,Platform,Dimensions,Animated,Image, Easing,Modal, 
} from 'react-native';
import {StyleContext} from '../styleC';

import firebase from './firebase';
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, query, startAt,endAt} from "firebase/database";

import strings from '../strings';
import info from '../info';
import MavTextInput from './mavTextInput';

import MavButton from './mavButton';
import MavAlert from './mavAlert';
import MavNavigationMinimize from './mavNavigationMinimize';
import MavViewMore from './mavViewMore';



export default class help extends Component {
  constructor(props) {
    super(props);
    this.state={
      note:"",
      list:[],


      }
  }
  
   componentDidMount(){

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    this.setState({note:params.note, disableForceNote:(params.changeNoteReview!=undefined)?false:true })

      var sch=query(ref(getDatabase(firebase), "/reviews/race"), orderByChild("clientID"), startAt(params.id), endAt(params.id)) ;
      if(params.partner==true) sch=query(ref(getDatabase(firebase), "/reviews/race"), orderByChild("partnerID"), startAt(params.id), endAt(params.id))
        get(sch).then(snapshot => {


        var val=snapshot.val();

        if(val!=null){
            var list=Object.keys(val);
            var list2=new Array;
            var i;
            list=Object.keys(val).sort( function ( a, b ) { return val[b].date - val[a].date  } )


            for(i=0;i<list.length;i++){
              var calcDate=new Date(val[list[i]].date)
              calcDate=((calcDate.getDate<10)?"0":"")+calcDate.getDate()+"/"+((calcDate.getMonth()<9)?"0":"")+(calcDate.getMonth()+1)+"/"+calcDate.getFullYear()+" - "+((calcDate.getHours<10)?"0":"")+calcDate.getHours()+":"+((calcDate.getMinutes<10)?"0":"")+calcDate.getMinutes()

              if((val[list[i]].client!=undefined && val[list[i]].client.note!=undefined) || (val[list[i]].partner!=undefined && val[list[i]].partner.note!=undefined) ) list2.push({date:calcDate,client:val[list[i]].client,partner:val[list[i]].partner,})

        }

        this.setState({list:list2})  

      }
        

      });
    }



  forceNote(){

    this.alert.onAlert({title:strings.modifyNote, subTitle:strings.modifyNoteMoreInfo , textInput:{placeholder:"5",text:this.state.note.note.toString(),sizeMultiplicator:2,keyboardType:"numeric"},buttons:[
                  {text: strings.validate,onPress: (value) => {
                    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
                    value=parseFloat(value);
                    if(value.toString()!="NaN" && value>0 && value<5.0001 ) {
                      params.changeNoteReview("note", value); 

                      var note=this.state.note;
                      note.note=value;
                      this.setState({note:note})
                      this.forceReviews()
                    }
                    else this.alert.onAlert({title:strings.incorrectNote,});
                  }},
                  {text: strings.cancel, style:"cancel",onPress: () => {}}
                  ],})


  }

  forceReviews(){

    this.alert.onAlert({title:strings.modifyNumberReview, subTitle:strings.modifyNumberReviewMoreInfo , textInput:{placeholder:"1",text:this.state.note.reviews.toString(),sizeMultiplicator:2,keyboardType:"numeric"},buttons:[
                  {text: strings.validate,onPress: (value) => {
                    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
                    value=parseFloat(value);
                    if(value.toString()!="NaN") {
                      params.changeNoteReview("reviews", value); 
                      var note=this.state.note;
                      note.reviews=value;
                      this.setState({note:note})
                    }
                    else this.alert.onAlert({title:strings.incorrectNumberReview,});
                  }},
                  {text: strings.cancel, style:"cancel",onPress: () => {}}
                  ],})


  }








  render() {
    return (
      <StyleContext.Consumer>{style =>
        <View style={{backgroundColor:style.backgroundColor,flex:1,}}>
        <ScrollView >
            {(Platform.OS=="web" && this.props.params!=undefined && this.props.params.goBack!=undefined) && <MavNavigationMinimize  clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={ strings.notes } />}


            {this.state.note!="" && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={() => {  if(this.state.disableForceNote!=true) this.forceNote() }}>
                    <View style={{flexDirection:'row',marginBottom:10,marginTop:20, alignItems:"center",justifyContent:"center"}}> 

                      <Image source={(this.state.note.note>0)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.state.note.note>1)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.state.note.note>2)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.state.note.note>3)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.state.note.note>4)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_light.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_light.png") )} style={{width:20,height:20,marginRight:2,}}></Image>

                      <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.7,textAlign: "center",marginLeft:5}}>{"("+this.state.note.note+"/5 - "+this.state.note.reviews+" "+strings.reviews.toLowerCase()+")"}</Text>

                      </View>
                </TouchableHighlight>}



      {this.state.list.map(item => (
          <MavViewMore text={ item.date } iconClassic={(style.mode=="dark")?require('../assets/star1_dark.png'):require('../assets/star1_light.png')} >

              <MavTextInput style={{marginTop:5}} backgroundColor={style.backgroundColorContrast} disabledText={true} textRight={((item.client!=undefined && item.client.note!=undefined)?item.client.note:"--")+"/5"} textExplication={strings.clientNote} />
              {(item.client!=undefined && item.client.comment!=undefined) && <MavTextInput multiline={true} style={{marginTop:5}} backgroundColor={style.backgroundColorContrast} editable={false} text={item.client.comment} textExplication={strings.clientComment} />}
              
              <MavTextInput style={{marginTop:5}} backgroundColor={style.backgroundColorContrast} disableLine={(item.partner!=undefined && item.partner.comment!=undefined)?false:true}  disabledText={true} textRight={((item.partner!=undefined && item.partner.note!=undefined)?item.partner.note:"--")+"/5"} textExplication={strings.driverNote} />
              {(item.partner!=undefined && item.partner.comment!=undefined) && <MavTextInput disableLine={true} multiline={true} style={{marginTop:5}} backgroundColor={style.backgroundColorContrast} editable={false} text={item.partner.comment} textExplication={strings.driverComment} />}

          </MavViewMore>
      ))}

      <View style={{width:50, height:70}}/>

      
       


      
      
          
  
      </ScrollView>

     

        <MavAlert ref={alert => {this.alert = alert;}}/>

      </View>
      }</StyleContext.Consumer>
    );
  }
}



export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
