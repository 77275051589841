import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  Dimensions,
  Image,
  Alert,
  ScrollView,
  ActivityIndicator,
  Platform, 
  Clipboard,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import firebase from './firebase';
import MavButton from './mavButton';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

import TextPresentation from './textPresentation';
const timer = require('react-native-timer');

export default class payment extends Component {
  constructor(props) {
    super(props);
    
    var selectedOptions=(this.props.selectedOptions!=undefined)?this.props.selectedOptions:{}
    var counterSelected=0; 
    var i;  
    var selectedOptionsArray=Object.keys(selectedOptions); 
    for(i=0; i<selectedOptionsArray.length;i++){
      if(selectedOptions[selectedOptionsArray[i]].value!=false && selectedOptions[selectedOptionsArray[i]].value!=0) counterSelected++;
    }

    this.state={
      priceChildSeats:(selectedOptions["childSeats"]!=undefined && selectedOptions["childSeats"].price!=undefined)?selectedOptions["childSeats"].price:undefined,
      priceBoosterSeats:(selectedOptions["boosterSeats"]!=undefined && selectedOptions["boosterSeats"].price!=undefined)?selectedOptions["boosterSeats"].price:undefined,
      priceAnimal:(selectedOptions["animal"]!=undefined && selectedOptions["animal"].price!=undefined)?selectedOptions["animal"].price:undefined,
      priceWheelchairs:(selectedOptions["wheelchair"]!=undefined && selectedOptions["wheelchair"].price!=undefined)?selectedOptions["wheelchair"].price:undefined,

      viewOption:false,
      childSeats:false,
      boosterSeats:false,
      animal:false,
      wheelchair:false,
      optionalOrder:false,
      counterSelected:counterSelected,

    }
    

  }


findPrice(type){

  get(child(ref(getDatabase(firebase)), '/majoration/'+type)).then(valueCS => {
    var val=valueCS.val(); 


    if(type=="childSeats") this.setState({priceChildSeats:(val!=null)?val:0})
    if(type=="boosterSeats") this.setState({priceBoosterSeats:(val!=null)?val:0})
    if(type=="animal") this.setState({priceAnimal:(val!=null)?val:0})
    else this.setState({priceWheelchairs:(val!=null)?val:0})

  })

}



componentDidMount() {
  console.log("manageOption mount")
    /*je vais récupérer les options définis dans l'application */

   


  if(this.props==undefined || this.props.listOption==undefined) {

     /*si je dois récupérer les prix */
    get(child(ref(getDatabase(firebase)), '/settings/extraEUMode')).then(valueCS => {
      var extraEUMode=valueCS.val()
      if(this.props.money==undefined) extraEUMode=true; 

        

        get(child(ref(getDatabase(firebase)), '/settings/childSeats')).then(valueCS => {
          var val=valueCS.val(); 
          if(val==true) {
            this.setState({childSeats:val,viewOption:true});
            if(this.state.extraEUMode!=true && this.state.priceChildSeats==undefined) this.findPrice("childSeats"); 
          }



          get(child(ref(getDatabase(firebase)), '/settings/boosterSeats')).then(valueCS => {
              var val=valueCS.val(); 
              if(val==true) {
                this.setState({boosterSeats:val,viewOption:true})
                if(this.state.extraEUMode!=true && this.state.priceBoosterSeats==undefined) this.findPrice("boosterSeats"); 
              }

              get(child(ref(getDatabase(firebase)), '/settings/animal')).then(valueCS => {
                var val=valueCS.val(); 
                if(val==true) {this.setState({animal:val,viewOption:true})
                  if(this.state.extraEUMode!=true && this.state.priceAnimal==undefined) this.findPrice("animal"); }

                get(child(ref(getDatabase(firebase)), '/settings/wheelchair')).then(valueCS => {
                  var val=valueCS.val(); 
                  if(val==true) {
                      this.setState({wheelchair:val,viewOption:true}); 
                      if(this.state.extraEUMode!=true && this.state.priceWheelchairs==undefined) this.findPrice("wheelchair"); 
                  }


                  get(child(ref(getDatabase(firebase)), '/settings/optionalOrder')).then(valueCS => {
                      var val=valueCS.val(); 
                      
                      if(val!=null) this.setState({optionalOrder:val,viewOption:true}); 

                    })

                   /* optionalOrder*/

                })

              })

            })

        })
    })


  }

  else if(this.props!=undefined && this.props.listOption!=undefined){

    var updateList={}
    if(this.props.listOption.childSeats==true) {updateList.childSeats=true; if(this.props.listOption.priceChildSeats!=undefined && this.props.listOption.priceChildSeats>0) updateList.priceChildSeats=this.props.listOption.priceChildSeats}
    if(this.props.listOption.boosterSeats==true) {updateList.boosterSeats=true; if(this.props.listOption.priceBoosterSeats!=undefined && this.props.listOption.priceBoosterSeats>0) updateList.priceBoosterSeats=this.props.listOption.priceBoosterSeats } 
    if(this.props.listOption.wheelchair==true) {updateList.wheelchair=true; if(this.props.listOption.priceWheelchair!=undefined && this.props.listOption.priceWheelchair>0) updateList.priceWheelchair=this.props.listOption.priceWheelchair}
    if(this.props.listOption.animal==true) {updateList.animal=true; if(this.props.listOption.priceAnimal!=undefined && this.props.listOption.priceAnimal>0) updateList.priceAnimal=this.props.listOption.priceAnimal}
   
    

    if(this.props.listOption.optionalOrder!=undefined && Object.keys(this.props.listOption.optionalOrder).length>0 ) { updateList.optionalOrder=this.props.listOption.optionalOrder;}
    
    if(this.props.listOption.luggageMax!=undefined) {
      if(updateList.optionalOrder==undefined) updateList.optionalOrder={}
      updateList.optionalOrder.luggage={
        quantity:this.props.listOption.luggageMax,
        name:{"fr":strings.luggage},
        index:-1,
      }

    

    }
    


    if(Object.keys(updateList).length>0) updateList.viewOption=true; 


    console.log("length = "+Object.keys(updateList).length)
    console.log(updateList)



    
    this.setState(updateList)
  }


          


}






render() {
    return (
      <StyleContext.Consumer>{style => 


            <View style={(this.props.style==undefined || this.state.viewOption==false)?{}:this.props.style}>

                {this.state.viewOption==true && <TextPresentation maxiMode={this.props.maxiMode} defautVisible={this.props.defautVisible} disableChild={(this.props.disableChange==true && this.state.counterSelected==0)} ref={maVM => {this.maVM = maVM;}} defautVisible={this.props.defautVisible} text={(this.state.counterSelected==0)?strings.zeroOption:(this.state.counterSelected+" "+((this.state.counterSelected==1)?strings.oneOption.toLowerCase():strings.options.toLowerCase()) )} textExplication={strings.options} editable={false} icon={(style.mode=="dark")?require('../assets/luggage_white.png'):require('../assets/luggage_black.png')} >



                    {(this.state.childSeats==true && (this.props.disableChange!=true || (this.props.selectedOptions!=undefined && this.props.selectedOptions["childSeats"]!=undefined && this.props.selectedOptions["childSeats"].value==true))) && <TextPresentation maxiMode={this.props.maxiMode} textMini={(this.props.disableChange==true)?undefined:(this.state.priceChildSeats==0 || this.state.priceChildSeats==undefined)?strings.freeOption.toUpperCase():( strings.price.toUpperCase()+" : "+ ((this.props.money.position=="left")?(this.props.money.symbole+" "):"")+this.state.priceChildSeats+((this.props.money.position=="right")?(this.props.money.symbole):"") )} disableOnOff={this.props.disableChange} onoff={(this.props.selectedOptions!=undefined && this.props.selectedOptions["childSeats"]!=undefined && this.props.selectedOptions["childSeats"].value==true)?true:false} onOnOff={()=>{ var cptresult=(this.props.selectedOptions!=undefined && this.props.selectedOptions["childSeats"]!=undefined && this.props.selectedOptions["childSeats"].value==true)?false:true;  this.setState({counterSelected:this.state.counterSelected+( (cptresult==true)?1:-1 ) }); if(this.props.onSelectedOption!=undefined) this.props.onSelectedOption("childSeats",cptresult,(this.state.priceChildSeats==0 || this.state.priceChildSeats==undefined)?0:this.state.priceChildSeats)  }} defautVisible={this.props.defautVisible}  text={strings.childSeats} editable={false}   />}
                    {(this.state.boosterSeats==true && (this.props.disableChange!=true || (this.props.selectedOptions!=undefined && this.props.selectedOptions["boosterSeats"]!=undefined && this.props.selectedOptions["boosterSeats"].value==true)))&& <TextPresentation maxiMode={this.props.maxiMode} textMini={(this.props.disableChange==true)?undefined:(this.state.priceBoosterSeats==0 || this.state.priceBoosterSeats==undefined)?strings.freeOption.toUpperCase():( strings.price.toUpperCase()+" : "+ ((this.props.money.position=="left")?(this.props.money.symbole+" "):"")+this.state.priceBoosterSeats+((this.props.money.position=="right")?(this.props.money.symbole):"") )} disableOnOff={this.props.disableChange} onoff={(this.props.selectedOptions!=undefined && this.props.selectedOptions["boosterSeats"]!=undefined && this.props.selectedOptions["boosterSeats"].value==true)?true:false} onOnOff={()=>{ var cptresult=(this.props.selectedOptions!=undefined && this.props.selectedOptions["boosterSeats"]!=undefined && this.props.selectedOptions["boosterSeats"].value==true)?false:true;  this.setState({counterSelected:this.state.counterSelected+( (cptresult==true)?1:-1 ) }); if(this.props.onSelectedOption!=undefined) this.props.onSelectedOption("boosterSeats",cptresult,(this.state.priceBoosterSeats==0 || this.state.priceBoosterSeats==undefined)?0:this.state.priceBoosterSeats)  }} defautVisible={this.props.defautVisible}  text={strings.boosterSeats} editable={false}   />}
                    {(this.state.animal==true && (this.props.disableChange!=true || (this.props.selectedOptions!=undefined && this.props.selectedOptions["animal"]!=undefined && this.props.selectedOptions["animal"].value==true))) && <TextPresentation maxiMode={this.props.maxiMode} textMini={(this.props.disableChange==true)?undefined:(this.state.priceAnimal==0 || this.state.priceAnimal==undefined)?strings.freeOption.toUpperCase():( strings.price.toUpperCase()+" : "+ ((this.props.money.position=="left")?(this.props.money.symbole+" "):"")+this.state.priceAnimal+((this.props.money.position=="right")?(this.props.money.symbole):"") )} disableOnOff={this.props.disableChange} onoff={(this.props.selectedOptions!=undefined && this.props.selectedOptions["animal"]!=undefined && this.props.selectedOptions["animal"].value==true)?true:false} onOnOff={()=>{ var cptresult=(this.props.selectedOptions!=undefined && this.props.selectedOptions["animal"]!=undefined && this.props.selectedOptions["animal"].value==true)?false:true;  this.setState({counterSelected:this.state.counterSelected+( (cptresult==true)?1:-1 ) }); if(this.props.onSelectedOption!=undefined) this.props.onSelectedOption("animal",cptresult,(this.state.priceAnimal==0 || this.state.priceAnimal==undefined)?0:this.state.priceAnimal)  }} defautVisible={this.props.defautVisible}  text={strings.animal} editable={false}   />}
                    {(this.state.wheelchair==true && (this.props.disableChange!=true || (this.props.selectedOptions!=undefined && this.props.selectedOptions["wheelchair"]!=undefined && this.props.selectedOptions["wheelchair"].value==true))) && <TextPresentation maxiMode={this.props.maxiMode} textMini={(this.props.disableChange==true)?undefined:(this.state.priceWheelchairs==0 || this.state.priceWheelchairs==undefined)?strings.freeOption.toUpperCase():( strings.price.toUpperCase()+" : "+ ((this.props.money.position=="left")?(this.props.money.symbole+" "):"")+this.state.priceWheelchairs+((this.props.money.position=="right")?(this.props.money.symbole):"") )} disableOnOff={this.props.disableChange} onoff={(this.props.selectedOptions!=undefined && this.props.selectedOptions["wheelchair"]!=undefined && this.props.selectedOptions["wheelchair"].value==true)?true:false} onOnOff={()=>{ var cptresult=(this.props.selectedOptions!=undefined && this.props.selectedOptions["wheelchair"]!=undefined && this.props.selectedOptions["wheelchair"].value==true)?false:true;  this.setState({counterSelected:this.state.counterSelected+( (cptresult==true)?1:-1 ) }); if(this.props.onSelectedOption!=undefined) this.props.onSelectedOption("wheelchair",cptresult,(this.state.priceWheelchairs==0 || this.state.priceWheelchairs==undefined)?0:this.state.priceWheelchairs)  }} defautVisible={this.props.defautVisible}  text={strings.wheelchair} editable={false}   />}
                    



                    {this.state.optionalOrder!=false && Object.keys(this.state.optionalOrder).map((rowData,id) => ( 
                     <View>
                          {(this.state.optionalOrder[rowData].quantity==1 && (this.props.disableChange!=true || (this.state.optionalOrder[rowData].value==true))) && <TextPresentation maxiMode={this.props.maxiMode} textMini={(this.props.disableChange==true)?undefined:(this.state.optionalOrder[rowData].price==undefined || this.state.optionalOrder[rowData].price[this.props.money.id]==undefined)?strings.freeOption.toUpperCase():( strings.price.toUpperCase()+" : "+ ((this.props.money.position=="left")?(this.props.money.symbole+" "):"")+this.state.optionalOrder[rowData].price[this.props.money.id]+((this.props.money.position=="right")?(this.props.money.symbole):"") )} disableOnOff={this.props.disableChange} onoff={(this.props.selectedOptions!=undefined && this.props.selectedOptions[rowData]!=undefined && this.props.selectedOptions[rowData].value==true)?true:false} onOnOff={()=>{ var cptresult=(this.props.selectedOptions!=undefined && this.props.selectedOptions[rowData]!=undefined && this.props.selectedOptions[rowData].value==true)?false:true;  this.setState({counterSelected:this.state.counterSelected+( (cptresult==true)?1:-1 ) }); if(this.props.onSelectedOption!=undefined) this.props.onSelectedOption(rowData,cptresult,(this.state.optionalOrder[rowData].price==undefined || this.state.optionalOrder[rowData].price[this.props.money.id]==undefined)?0:this.state.optionalOrder[rowData].price[this.props.money.id])  }}  defautVisible={this.props.defautVisible}  text={(this.state.optionalOrder[rowData].name[strings.idLangue]!=undefined)?this.state.optionalOrder[rowData].name[strings.idLangue]:this.state.optionalOrder[rowData].name["fr"] } editable={false}   />}

                          {(this.state.optionalOrder[rowData].quantity>1 && (this.props.disableChange!=true || (this.state.optionalOrder[rowData].value==true))) && <TextPresentation maxiMode={this.props.maxiMode} textMini={(this.props.disableChange==true)?undefined:((this.state.optionalOrder[rowData].price==undefined || this.state.optionalOrder[rowData].price[this.props.money.id]==undefined)?strings.freeOption.toUpperCase():( strings.price.toUpperCase()+" : "+ ((this.props.money.position=="left")?(this.props.money.symbole+" "):"")+this.state.optionalOrder[rowData].price[this.props.money.id]+((this.props.money.position=="right")?(this.props.money.symbole):"") ))+" - "+strings.quantity.toUpperCase()+" : "+this.state.optionalOrder[rowData].quantity+" MAX"} disableQuantity={this.props.disableChange} 
                          
                            selectedQuantity={(this.props.selectedOptions!=undefined && this.props.selectedOptions[rowData]!=undefined && this.props.selectedOptions[rowData].value!=undefined)?this.props.selectedOptions[rowData].value:0} 
                            onChangeQuantity={(quantity)=>{ var cptresult=(this.props.selectedOptions!=undefined && this.props.selectedOptions[rowData]!=undefined && this.props.selectedOptions[rowData].value!=undefined)?this.props.selectedOptions[rowData].value:0;  if(quantity==-1 && cptresult>0) cptresult--; else if(quantity==1 && cptresult<this.state.optionalOrder[rowData].quantity) cptresult++; var counterSelected=this.state.counterSelected; if((this.props.selectedOptions==undefined || this.props.selectedOptions[rowData]==undefined || this.props.selectedOptions[rowData].value==undefined || this.props.selectedOptions[rowData].value==0) && cptresult>0) counterSelected++; else if(this.props.selectedOptions!=undefined && this.props.selectedOptions[rowData]!=undefined && this.props.selectedOptions[rowData].value==1 && cptresult==0) counterSelected--;  this.setState({counterSelected:counterSelected }); if(this.props.onSelectedOption!=undefined) this.props.onSelectedOption(rowData,cptresult,(this.state.optionalOrder[rowData].price==undefined || this.state.optionalOrder[rowData].price[this.props.money.id]==undefined)?0:this.state.optionalOrder[rowData].price[this.props.money.id])  }}  
                            defautVisible={this.props.defautVisible}  
                            text={(this.state.optionalOrder[rowData].name[strings.idLangue]!=undefined)?this.state.optionalOrder[rowData].name[strings.idLangue]:this.state.optionalOrder[rowData].name["fr"] } editable={false}   />}


                      </View>
                      ))}

                </TextPresentation>}

            </View>



          

        }</StyleContext.Consumer>

    );
  }
}




