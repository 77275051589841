export default[
	{"id":"eu","flag":require("./assets/flags/eu.png"), fr:"Euro", en:"Euro", it:"Euro", ru:"Euro", es:"Euro", symbole:"€", position:"right",stripeCode:"eur", },
	{"id":"usd","flag":require("./assets/flags/us.png"), fr:"Dollar", en:"Dollar", it:"Dollar", ru:"Dollar", es:"Dollar", symbole:"$", position:"right",stripeCode:"usd", },

	{"id":"ch","flag":require("./assets/flags/ch.png"), fr:"Franc Suisse", en:"Swiss franc", it:"Franco svizzero", ru:"Швейцарский франк", es:"Franco suizo", symbole:"CHF", position:"left",stripeCode:"chf", },
	{"id":"xpf","flag":require("./assets/flags/fr.png"), fr:"Franc Pacifique", en:"Pacific Franc", it:"Franco del Pacifico", ru:"Тихоокеанский франк", es:"Franco del Pacífico", symbole:"XPF", position:"right",stripeCode:"xpf", },
	
	{"id":"gbp","flag":require("./assets/flags/gb.png"), fr:"Livre sterling", en:"Pound sterling", it:"Pound sterling", ru:"Pound sterling", es:"Pound sterling", symbole:"£", position:"left",stripeCode:"gbp", },
	{"id":"ma","flag":require("./assets/flags/ma.png"), fr:"Dirham", en:"Dirham", it:"Dirham", ru:"Dirham", es:"Dirham", symbole:"MAD", position:"right",stripeCode:"mad", },
	{"id":"dz","flag":require("./assets/flags/dz.png"), fr:"Dinar", en:"Dinar", it:"Dinar", ru:"Dinar", es:"Dinar", symbole:"DA", position:"right",stripeCode:"dzd", },

	{"id":"ru","flag":require("./assets/flags/ru.png"), fr:"Rouble", en:"Ruble", it:"Rublo", ru:"рубль", es:"Rublo", symbole:"pyб", position:"right",stripeCode:"rub", },
	
	{"id":"xof","flag":require("./assets/flags/fr.png"), fr:"CFA", en:"CFA", it:"CFA", ru:"CFA", es:"CFA", symbole:"CFA", position:"left",stripeCode:"xof", },

	{"id":"cny","flag":require("./assets/flags/cn.png"), fr:"Yuan", en:"Yuan", it:"Yuan", ru:"Yuan", es:"Yuan", symbole:"RMB", position:"left",stripeCode:"cny", },

	{"id":"jpy","flag":require("./assets/flags/jp.png"), fr:"Yen", en:"Yen", it:"Yen", ru:"Yen", es:"Yen", symbole:"¥", position:"left",stripeCode:"jpy", },

	   ]


