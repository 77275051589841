import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  Platform,
  TextInput,
  Dimensions,
  Image,
  Modal,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';

import info from '../info';
var ref="/pricing";

import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MapView from './mapView';
import MavAlert from './mavAlert';
 import MavSelector from './mavSelector';
import MavNavigationMinimize from './mavNavigationMinimize';

const timer = require('react-native-timer');
export default class account extends Component {
  constructor(props) {
    super(props);

    this.state = {

      regionZoom:{latitude: 47.245996,longitude: 2.62,latitudeDelta: 12,longitudeDelta: 12,},
      coordinates:[],
      path:[], 
      lastPoint:0,
      elementColor:"rgba(0,100,0,1)",
      elementColor2:"rgba(0,100,0,0.5)",
      selectedZone:"",
      

      goReturn:false
      
  }

}

  componentDidMount() {

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
    if(params!=undefined){
      var path=[]
      var i; 
      for(i=0; i<params.data.zone.length;i++){
          path.push({lat:params.data.zone[i].latitude, lng:params.data.zone[i].longitude })
      }
      
      var list={selectedZone: params.selectedZone, regionZoom: params.data.regionZoom,elementColor:params.data.elementColor,elementColor2:params.data.elementColor2,coordinates:params.data.zone, path:path, lastPoint:params.data.zone.length, }


      this.setState(list)
    }


    console.log(params.data)
    console.log("mount")
    
    
    
  }



render() {
    return (
      <StyleContext.Consumer>{style =>
      <View style={{ marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor }}>

            {(Platform.OS=="web") && <MavNavigationMinimize  clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={ strings.place } />}


<MapView region={this.state.regionZoom} style={{alignSelf: 'stretch',width: "100%",height:(Platform.OS=="web")?Dimensions.get('window').height*0.8:Dimensions.get('window').height}}               onRegionChangeComplete={e=> { this.setState({regionZoom:e}) }}
              onLongPress={e => {
                console.log(e)
                var coordinates=(e.web==true)?{latitude:e.lat, longitude:e.lng}:e.nativeEvent.coordinate
                var path=(e.web==true)?{lat:e.lat, lng:e.lng}:e.nativeEvent.coordinate
                
                var list=this.state.coordinates.slice();
                list.splice(this.state.lastPoint, 0, coordinates);

                var listPath=this.state.path.slice();
                listPath.splice(this.state.lastPoint, 0, path);

                this.setState({coordinates:list,lastPoint:this.state.lastPoint+1, path:listPath})
                

              }} >

                {this.state.coordinates.map((marker,i) => (

              <MapView.Marker icon={(Platform.OS=="web")?( (this.state.elementColor=="rgba(100,0,0,1)")?  require('../assets/pinSetDrawRed.png'):require('../assets/pinSetDrawGreen.png')   ):null}  coordinate={marker} onClick={e=> {this.setState({lastPoint:i+1})   }} onPress={e=> {this.setState({lastPoint:i+1})   }} >

                  {(Platform.OS!="web") && <View style={{width:20,height:20,borderRadius:10,backgroundColor:(i==this.state.lastPoint-1)?this.state.elementColor:this.state.elementColor2}} />}
                
              </MapView.Marker>
              ))}



                    
           


              {this.state.coordinates.length>0 && <MapView.Polygon path = {this.state.path} options={{strokeColor: this.state.elementColor, fillColor:this.state.elementColor2}}  coordinates={this.state.coordinates} strokeColor={this.state.elementColor} fillColor={this.state.elementColor2}   />}


              
            </MapView>

          <View style={{position:"absolute", justifyContent:"center",marginTop:(Platform.OS=="web")?60:5,width:"100%",flexDirection:"row"}}>
                <Text style={{textAlign:"center"}}>{(Platform.OS=="web")?strings.pressToAddPoint:strings.longPressToAddPoint}</Text>
          </View>

          <View style={{position:"absolute", justifyContent:"center",marginTop:(Platform.OS=="web")?"70%":(Dimensions.get('window').height-170),width:"100%",flexDirection:"row"}}>
            
            

           {this.state.coordinates.length>0 && <MavButton text={strings.removeLastPoint} onlyButton={true}  onPress={()=>{  
           
            var list=this.state.coordinates.slice();
            list.splice(this.state.lastPoint-1,1);

            var listPath=this.state.path.slice();
            listPath.splice(this.state.lastPoint-1,1);
            this.setState({coordinates:list,lastPoint:list.length, path:listPath})

          }}/>}

          {this.state.coordinates.length>2 && <MavButton text={strings.validate} onlyButton={true}   style={{marginLeft:10}} onPress={()=>{  
             var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
             if(params.onZoneChange!=null) params.onZoneChange({selectedZone:this.state.selectedZone, coordinates:this.state.coordinates});
              

              if(this.props.route!=undefined) this.props.navigation.goBack();
              else params.goBack(); 


          }}/>}

          </View>

         


          <MavAlert ref={alert => {this.alert = alert;}}/>
        
        

      </View>
      }</StyleContext.Consumer>
    );
  }
}

account.navigationOptions = ({ navigation, screenProps }) => {
  return {

    headerTitle: strings.place,
    headerTintColor: screenProps.headerColor,
    headerStyle: {
      backgroundColor: screenProps.headerBackgroundColor,
    },
  };
};

export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
