import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Linking,
  Image,
  Picker,
  Platform,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';
import strings from '../strings';
import MavButton from './mavButton';
import colorList from '../colorList';
import TextPresentation from './textPresentation';
const timer = require('react-native-timer');
import Modal from './modal';

export default class mavAlert extends Component {
  state = {
    modalVisible: false,
    textInput:"",
    title:"",
    subTitle:"",
    largeButton:false,
    code:"",
    items:[], 
    itemSelected:-1,
    buttons:[{text: strings.ok,onPress: () => {}}],
    maxiMode:false,
    notSelectable:false,
    drawerMini:false, 
    arrowQuestion:false,
    arrowQuestions:false,
    arrowEnabled:false,
    help:false,
    check:false,
    view:"basic", 
    colorList:[],
    choiceColor:false,
    notClose:false,
    waiting:false,



  };
componentDidMount(){
  this.setState({list:this.props.list})



}

  setChoiceColor(){

    var listFinal=[]; 
    var i;
    var j=0;
    var k=0;
    for(i=0;i<colorList.length;i++){
        
        if(k>5) {k=1; j++}
        else k++;
        if(listFinal[j]==undefined) listFinal[j]=[];
        listFinal[j].push(colorList[i]);

    }
    this.setState({colorList:listFinal});

  }
  

  setModalVisible(visible) {
    this.setState({modalVisible: visible});
  }

  forceClose(){
    this.setState({modalVisible:false}); 
  }

  onAlert(value){

    if(value.choiceColor==true) this.setChoiceColor();

    var st={check:(value.check==true)?true:false,arrowQuestions:(value.arrowQuestions!=undefined)?value.arrowQuestions:false,choiceColor:(value.choiceColor==true)?true:false, help:(value.help!=undefined)?value.help:false, arrowQuestion:(value.arrowQuestion!=undefined)?value.arrowQuestion:false,modalVisible:true,largeButton:(value.largeButton!=undefined)?value.largeButton:false,items:(value.items!=undefined)?value.items:[],itemSelected:(value.itemSelected!=undefined)?value.itemSelected:-1, drawerMini:(value.drawerMini==true)?true:false, title:(value.title!=undefined)?value.title:"",subTitle:(value.subTitle!=undefined)?value.subTitle:"", maxiMode:(value.maxiMode==true)?true:false, code:(value.code!=undefined)?value.code:"", textInput:(value.textInput!=undefined)?value.textInput:"", buttons:(value.buttons!=undefined)?value.buttons:[{text: strings.ok,onPress: () => {}}] };

    if(value.arrowEnabled!=undefined) st.arrowEnabled=value.arrowEnabled;
    st.notClose=(value.notClose==true)?true:false;
    st.waiting=(value.waiting==true)?true:false;
    st.notSelectable=(value.notSelectable==true)?true:false;
    
    this.setState(st)
  }

  render() {
    return (
      <StyleContext.Consumer>{style => 
    


     <Modal
          animationType="none"
          transparent={true}
          visible={this.state.modalVisible}>
          <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",justifyContent:"center"}}>

              <TouchableHighlight underlayColor={"rgba(0,0,0,0.5)"} style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,backgroundColor:"rgba(0,0,0,0.5)",position:"absolute",marginTop:0}} onPress={() => {console.log("click nodal") }}>
                  <View/>
              </TouchableHighlight>



              {this.state.view=="basic" && <View style={{width:(Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth:(Dimensions.get('window').width*0.8*((this.state.drawerMini==true)?0.5:1)) ,backgroundColor:style.backgroundColor,position:"absolute",marginLeft:(Dimensions.get('window').width>style.mobileSizeMax)?((Dimensions.get('window').width-style.minimizeWidth)/2):(Dimensions.get('window').width*0.1*((this.state.drawerMini==true)?0.5:1)),shadowOpacity: 0.6,shadowRadius: 2,shadowOffset: {height: 0,width: 0},elevation: 3}}>
                
                {(this.state.check==true) && <View style={{alignItems:"center", justifyContent:"center"}}>
                  <View style={{marginTop:15,flex:1,backgroundColor:style.color1}}>
                    <Image source={(style.mode=="dark")?require('../assets/check_black.png'):require('../assets/check_white.png')} style={{ width: 45, height: 45 }} />
                  </View>
                </View>}

                {this.state.waiting==true && <ActivityIndicator style={{marginTop:40,marginBottom:5}} size="large" color={style.color1} />}

                <Text style={{marginTop:(this.state.check==true || this.state.waiting==true)?-5:15,marginLeft:15,marginRight:15, marginBottom:(this.state.waiting==true && this.state.subTitle=="")?40:undefined, color: style.textInputColor,fontSize: style.textInputFontSize*1.5*( (this.state.maxiMode==true)?((Dimensions.get('window').width>1000)?2:1.8):1),textAlign: "center",}}>{this.state.title}</Text>
                {this.state.subTitle!="" && <Text style={{marginTop:(this.state.waiting==true)?0:10, marginBottom:(this.state.waiting==true)?40:undefined, marginLeft:15,marginRight:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.1*( (this.state.maxiMode==true)?((Dimensions.get('window').width>1000)?2:1.8):1),textAlign: "center",}}>{this.state.subTitle}</Text>}
                {this.state.code!="" && <Text style={{marginTop:20,color: style.textInputColor,fontSize: style.textInputFontSize*2,textAlign: "center",marginBottom:5}}>{this.state.code}</Text>}
                

                {this.state.choiceColor==true && <View style={{marginTop:10}}>

                    {this.state.colorList.map((rowData,key) => (
                      <View style={{flexDirection:"row", justifyContent:"space-between", marginBottom:5, marginLeft:5, marginRight:5}}>
                        {rowData.map((rowDataColor,key) => (
                            <TouchableHighlight underlayColor={"rgba(0,0,0,0.0)"} style={{flex:(Dimensions.get('window').width>style.mobileSizeMax)?undefined:0.15, width:(Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth/6.5:undefined, height:(Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth/6.5:undefined, backgroundColor:rowDataColor.code, aspectRatio:1}} onPress={() => {var txt=JSON.parse(JSON.stringify(this.state.textInput)); txt.text=rowDataColor.code; this.setState({textInput:txt}) }}>
                                <View style={{flex:1, alignItems:"center",justifyContent:"center"}}>
                                  {( this.state.textInput.text!=undefined && this.state.textInput.text.toUpperCase()==rowDataColor.code.toUpperCase()  ) && <Image source={(rowDataColor.checkWhite==true)?require('../assets/checkBasic_white.png'):require('../assets/checkBasic_black.png')} style={{ width: 30, height: 30 }} />}
                                </View>
                            </TouchableHighlight>
                        ))}
                    </View>))}

                </View>}






                {this.state.textInput!="" && <TextInput allowFontScaling={false}
                editable={(this.state.textInput.editable==false)?false:true}
              keyboardType={(this.state.textInput.keyboardType==undefined || Platform.OS=="web")?"default":this.state.textInput.keyboardType}
              placeholder={this.state.textInput.placeholder}
              secureTextEntry={(this.state.textInput.secureTextEntry==undefined)?false:this.state.textInput.secureTextEntry}
              placeholderTextColor={style.placeholderTextInputColor}
              autoCorrect={false}   
              multiline={(this.state.textInput.multiline==true)?true:undefined}
              autoCapitalize={(this.state.textInput.autoCapitalize==undefined)?"sentences":this.state.textInput.autoCapitalize}
              underlineColorAndroid="rgba(0,0,0,0)"
              style={{marginTop:10,marginLeft:15,marginRight:15,color:style.textInputColor,fontSize: style.textInputFontSize*((this.state.textInput.sizeMultiplicator!=undefined)?this.state.textInput.sizeMultiplicator:1),textAlign:"center",height:style.textInputHeight*((this.state.textInput.sizeMultiplicator!=undefined)?this.state.textInput.sizeMultiplicator:1)*((this.state.textInput.multiline==true)?2:1) }}

              onChangeText={text => {
                
                if(this.state.textInput.detectSequence!=undefined){
                  text=text.toString().replace(this.state.textInput.detectSequence+" ",'')
                  text=text.toString().replace(this.state.textInput.detectSequence,'')
                  text=text.toString().replace(",",'.')
                }

                var teIn=this.state.textInput;

                if(this.state.textInput.typeHour==true){
                
                    
                    if(text[1]==":") text=text[0]
                    if(text.length==2 && this.state.textInput.text.length==3) {teIn.text=text[0]+text[1] }
                    else if(text.length==0 && this.state.textInput.text.length==1) teIn.text="";

                    else {
                      if(text.length>0) { if(text[0]<3) teIn.text=text[0]; else teIn.text="0"; }
                      if(text.length>1) { if((text[0]<2) || (text[0]==2 && text[1]<4) )  teIn.text+=text[1]; else teIn.text+="0"; teIn.text+=":" } 
                      if(text.length>3) { if(text[3]<6) teIn.text+=text[3]; else teIn.text+="0";  }
                      if(text.length>4) { if(text[4]<10) teIn.text+=text[4]; else teIn.text+="0";  }
                    }
                  
                }

                else if(this.state.textInput.typeDate==true){

                
                
                    if(text.length==2 && this.state.textInput.text.length==3) {teIn.text=text[0]+text[1] }
                    else if(text.length==5 && this.state.textInput.text.length==6) {teIn.text=text[0]+text[1]+"/"+text[3]+text[4] }
                    else if(text.length==7 && this.state.textInput.text.length==8) {teIn.text=text[0]+text[1]+"/"+text[3]+text[4] }
                    else if(text.length==8 && this.state.textInput.text.length==9) {teIn.text=text[0]+text[1]+"/"+text[3]+text[4]+"/20" }
                      else if(text.length==9 && this.state.textInput.text.length==10) {teIn.text=text[0]+text[1]+"/"+text[3]+text[4]+"/20"+text[8] }
                    else if(text.length==0 && this.state.textInput.text.length==1) teIn.text="";

                    else {


                      if(text.length>0) { if(text[0]<4) teIn.text=text[0]; else teIn.text="0"; }
                      if(text.length>1) { if(((text[0]<3) || (text[0]==3 && text[1]<2) ) && (text[1]!="0" || text[0]!="0"))   teIn.text+=text[1]; else teIn.text+="1"; teIn.text+="/" } 

                      
                      if(text.length>3) { if(text[3]<2) teIn.text+=text[3]; else teIn.text+="1";  }
                      if(text.length>4) { if((text[4]<3 && text[3]<3) || (text[3]==0))  {if(text[3]=="0" && text[4]=="0") teIn.text+="1";  else teIn.text+=text[4];} else teIn.text+="2"; teIn.text+="/20"  }
                      
                      if(text.length>7) { if(text[8]>1) teIn.text+=text[8]; else teIn.text+="2"; }
                      if(text.length>9) { teIn.text+=text[9];}

                    }
                  
                }

                else {
                  teIn.text=text;
                }

                this.setState({textInput:teIn})

                if(this.state.textInput.detectSequence!=undefined){
                  timer.setTimeout(generateUUID(), () => {
                    var teIn=this.state.textInput;

                    var value=this.state.textInput.text
                    var test=(value!=undefined)?value.indexOf(this.state.textInput.detectSequence):-1;
                    if(test==-1 && this.state.textInput.text!="") {
                      var position=(this.state.textInput.detectSequencePosition!=undefined)?this.state.textInput.detectSequencePosition:"right"
                      teIn.text=((position=="left")?(this.state.textInput.detectSequence+" "):"")+teIn.text+((position=="right")?(this.state.textInput.detectSequence):"")
                      this.setState({ textInput:teIn });
                    }
                   }, 1000);
                }


              }}
              value={this.state.textInput.text}
              />}


              {(this.state.textInput!=undefined && this.state.textInput.addText!=undefined) && <Text style={{color: style.textInputColor,fontSize: style.buttonFontSize*0.8,textAlign: 'center',marginTop:-15}} >{this.state.textInput.addText}</Text>}

               {(this.state.items.length>0 && this.state.items.length<6) && <View style={{alignItems:"center",marginTop:20,marginBottom:10, }}>
                  {this.state.items.map((rowData,key) => (
                    <TouchableHighlight key={key} underlayColor={"rgba(0,0,0,0.0)"} style={{marginBottom:10,}} onPress={() => {if(this.state.notSelectable!=true) this.setState({itemSelected:key}) }}>
                      
                          <View style={{width:((Dimensions.get('window').width>style.mobileSizeMax)?(style.minimizeWidth*1.15):Dimensions.get('window').width)*0.68, justifyContent:"space-between",alignItems:"center", flexDirection: 'row', height: style.buttonHeight*( (this.props.maxiMode==true)?3:1) ,borderRadius: style.buttonRadius* ( (this.props.maxiMode==true)?3:1),borderColor:(this.state.itemSelected==key)?(style.color1):(style.textInputLineColor)   ,borderWidth:(this.state.itemSelected==key)?2:1,}}>
                            <View style={{flexDirection: 'row',}}>

                              {rowData.pellet!=undefined && <View style={{elevation: 1,width: 20, height: 20, borderRadius:10, marginLeft:20, backgroundColor:rowData.pellet}} /> }
                        
                              {(rowData.image==undefined && this.state.itemSelected==key) && <View style={{marginTop:2,marginLeft:(rowData.pellet!=undefined)?10:20,width: 20, height: 20,backgroundColor:style.color1}}>
                                <Image source={(style.mode=="dark")?require('../assets/check_black.png'):require('../assets/check_white.png')} style={{ width: 20, height: 20 }} />
                              </View>}

                              {rowData.image!=undefined && <Image source={rowData.image} style={{ height:20,width:20,resizeMode:"contain",marginLeft:20,  }}/>}
                               <View style={{justifyContent:"center", marginLeft:20, }}>
                                  <Text allowFontScaling={false} style={{color: (this.state.itemSelected==key)?style.color1:style.textInputColor,fontWeight:(this.state.itemSelected==key)?"bold":undefined,fontSize: style.buttonFontSize* ( (this.props.maxiMode==true)?3:1),textAlign: 'left',}}>{rowData.text} </Text>
                                  {rowData.textSmall!=undefined && <Text allowFontScaling={false} style={{color: (this.state.itemSelected==key)?style.color1:style.textInputColor,fontSize: style.buttonFontSize*0.5* ( (this.props.maxiMode==true)?3:1),textAlign: 'left',}}>{rowData.textSmall} </Text>}
                               </View>
                            </View>

                             
                              {rowData.textRight!=undefined && <Text style={{marginRight:10, color: (this.state.itemSelected==key)?style.color1:style.textInputColor,fontWeight:(this.state.itemSelected==key)?"bold":undefined,fontSize: style.buttonFontSize* ( (this.props.maxiMode==true)?3:1),textAlign: 'left',}} >{rowData.textRight}</Text>}
                              


                          </View>

                          
                    </TouchableHighlight>

                    
                    ))}
                </View>}

                {(this.state.items.length>0 && this.state.items.length>5) && <ScrollView style={{marginTop:20,marginBottom:10,height:6.5*style.buttonHeight }}>
                  {this.state.items.map((rowData,key) => (
                    <TouchableHighlight key={key} underlayColor={"rgba(0,0,0,0.0)"} style={{marginBottom:10, alignItems:"center",}} onPress={() => {if(this.state.notSelectable!=true) this.setState({itemSelected:key}) }}>
                      <View style={{width:((Dimensions.get('window').width>style.mobileSizeMax)?(style.minimizeWidth*1.15):Dimensions.get('window').width)*0.68, alignItems:"center", flexDirection: 'row', height: style.buttonHeight*( (this.props.maxiMode==true)?3:1) ,borderRadius: style.buttonRadius* ( (this.props.maxiMode==true)?3:1),borderColor:(this.state.itemSelected==key)?(style.color1):(style.textInputLineColor)   ,borderWidth:(this.state.itemSelected==key)?2:1,}}>
                        
                        {rowData.pellet!=undefined && <View style={{elevation: 1,width: 20, height: 20, borderRadius:10, marginLeft:20, backgroundColor:rowData.pellet}} /> }
                            

                        {(rowData.image==undefined && this.state.itemSelected==key) && <View style={{marginTop:2,marginLeft:(rowData.pellet!=undefined)?10:20,width: 20, height: 20,backgroundColor:style.color1}}>
                          <Image source={(style.mode=="dark")?require('../assets/check_black.png'):require('../assets/check_white.png')} style={{ width: 20, height: 20 }} />
                        </View>}

                        {rowData.image!=undefined && <Image source={rowData.image} style={{ height:20,width:20,resizeMode:"contain",marginLeft:20,  }}/>}
                         <View style={{justifyContent:"center", marginLeft:(rowData.image==undefined && this.state.itemSelected==key)?5:20, width:Dimensions.get('window').width*0.5, }}>
                            <Text allowFontScaling={false} style={{color:(this.state.itemSelected==key)?style.color1:style.textInputColor,fontWeight:(this.state.itemSelected==key)?"bold":undefined,fontSize: style.buttonFontSize* ( (this.props.maxiMode==true)?3:1),textAlign: 'left',}}>{rowData.text} </Text>
                            {rowData.textSmall!=undefined && <Text allowFontScaling={false} style={{color:(this.state.itemSelected==key)?style.color1:style.textInputColor,fontSize: style.buttonFontSize*0.5* ( (this.props.maxiMode==true)?3:1),textAlign: 'left',}}>{rowData.textSmall} </Text>}
                         </View>


                      </View>
                    </TouchableHighlight>

                    
                    ))}
                </ScrollView>}

                {(this.state.arrowQuestion!=false) && <TextPresentation style={{marginTop:5}} textExplication={this.state.arrowQuestion} onoff={this.state.arrowEnabled} onOnOff={()=>{ this.setState({arrowEnabled:!this.state.arrowEnabled}) }} editable={false}  disableText={true} />}
                        
                {(this.state.arrowQuestions!=false) && this.state.arrowQuestions.map((rowData,i) => (

                    <TextPresentation style={{marginTop:5}} textExplication={rowData.text} onoff={rowData.value} onOnOff={()=>{ var arrowQuestions=this.state.arrowQuestions; arrowQuestions[i].value=!arrowQuestions[i].value; this.setState({arrowQuestions:arrowQuestions}) }} editable={false}  disableText={true} />
                ))}   

                {this.state.waiting!=true && <View style={{alignItems:"center",marginTop:20,marginBottom:10}}>
                  {this.state.buttons.map((rowData,i) => (
                    <MavButton large={this.state.largeButton} firstText={rowData.firstText} bottomText={rowData.bottomText} maxiMode={this.state.maxiMode} onlyButton={true} key={rowData.text+"d"+i} strokeMode={(rowData.style=="cancel")?true:false} text={rowData.text.toUpperCase()} style={{marginBottom:10}} onPress={()=>{ 
                    if(this.state.notClose!=true) this.setState({modalVisible:false}); 
                    if(this.state.textInput!=undefined && this.state.textInput!="") rowData.onPress(this.state.textInput.text)
                    else if(this.state.items.length>0 && this.state.arrowQuestion!=undefined && this.state.arrowQuestion!=false) rowData.onPress(this.state.itemSelected, {text:((this.state.itemSelected>-1)?this.state.items[this.state.itemSelected].text:""), arrowQuestion:this.state.arrowEnabled  }) 
                    else if(this.state.items.length>0 && this.state.arrowQuestions!=undefined && this.state.arrowQuestions!=false) rowData.onPress(this.state.itemSelected, {text:((this.state.itemSelected>-1)?this.state.items[this.state.itemSelected].text:""), arrowQuestions:this.state.arrowQuestions  }) 
                    else if(this.state.items.length>0) rowData.onPress(this.state.itemSelected, ((this.state.itemSelected>-1)?this.state.items[this.state.itemSelected].text:""))
                    else if(this.state.arrowQuestion!=undefined && this.state.arrowQuestion!=false) rowData.onPress(this.state.arrowEnabled)
                    else if(this.state.arrowQuestions!=undefined && this.state.arrowQuestions!=false) rowData.onPress(this.state.arrowQuestions)
                    else rowData.onPress() }}/>
                    ))}
                </View>}


                {this.state.help!=false && <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { this.setState({view:"help"}) }}> 
                    <View style={{justifyContent:"center",marginTop:15,marginBottom:15, flexDirection:"row"}}>
                      <View style={{alignItems:"center",width:20, height:20,  borderRadius:10,borderColor:style.color1,borderWidth:1,alignItems:"center", justifyContent:"center" }}>
                          <Text style={{marginLeft:1,fontSize: style.textInputFontSize,color:style.color1,fontWeight:"bold"}}>{"?"}</Text>
                      </View>
                      <Text style={{marginLeft:5,fontSize: style.textInputFontSize,color:style.color1,textAlign:"center"}}>{strings.needHelp}</Text>
                    </View>
                </TouchableHighlight>}

              </View>}


              {this.state.view=="help" && <View style={{width:Dimensions.get('window').width*0.8*((this.state.drawerMini==true)?0.5:1),backgroundColor:style.backgroundColor,position:"absolute",marginLeft:Dimensions.get('window').width*0.1*((this.state.drawerMini==true)?0.5:1),shadowOpacity: 0.3,shadowRadius: 2,shadowOffset: {height: 2,width: 0},elevation: 1}}>
                  
                <Text style={{marginTop:15,marginLeft:15,marginRight:15, color: style.textInputColor,fontSize: style.textInputFontSize*1.5*( (this.state.maxiMode==true)?((Dimensions.get('window').width>1000)?2:1.8):1),textAlign: "center",}}>{this.state.help.title}</Text>
                {this.state.help.subTitle!="" && <Text style={{marginTop:10, marginLeft:15,marginRight:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.1*( (this.state.maxiMode==true)?((Dimensions.get('window').width>1000)?2:1.8):1),textAlign: "center",}}>{this.state.help.subTitle}</Text>}
                {this.state.help.url!=undefined && <TouchableHighlight underlayColor={"rgba(0,0,0,0.0)"}  onPress={() => { Linking.openURL(this.state.help.url) }}>
                    <Text style={{marginTop:10, marginLeft:15,marginRight:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.1*( (this.state.maxiMode==true)?((Dimensions.get('window').width>1000)?2:1.8):1),textAlign: "center",textDecorationLine:"underline",}}>{strings.clickMoreInfos}</Text>
                </TouchableHighlight>}
                
                {this.state.buttons.map((rowData) => (<View>

                  {rowData.help!=undefined && <Text style={{marginTop:10, marginLeft:15,marginRight:15,fontWeight: 'bold',color: style.color1,fontSize: style.textInputFontSize*1.1,textAlign: "center",}}>{((rowData.firstText!=undefined)?rowData.firstText:"")+" "+rowData.text+" "+((rowData.bottomText!=undefined)?rowData.bottomText:"") }</Text>}
                  {rowData.help!=undefined && <Text style={{marginTop:2, marginLeft:15,marginRight:15,color: style.textInputColor,fontSize: style.textInputFontSize,textAlign: "center",}}>{rowData.help}</Text>}

                    </View>
                  ))}
                  <View style={{alignItems:"center",justifyContent:"center",marginTop:15,marginBottom:10}}>
                     <MavButton text={strings.return} onlyButton={true} onPress={()=>{ this.setState({view:"basic"})    }}/>
                  </View>
              </View>}

              
              

              
 
        </View>


        </Modal>

        }</StyleContext.Consumer>
      
      )}

    }


    export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

