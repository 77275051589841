import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';
import MavTextInput from './mavTextInput';

export default class MavButton extends Component {
constructor(props) { 
    super(props);






    
    this.state = {
      view:(this.props.defautVisible==true)?true:false,
      arrowEnabled:false,
      

    }
  }

  changeArrow(force){
    var newArrow=!this.state.arrowEnabled; 
    if(force!=undefined) newArrow=force;
    if(this.props.onArrowChange!=undefined) this.props.onArrowChange(newArrow)
    this.setState({arrowEnabled:newArrow,view:newArrow })

    
  }

  close(){
    this.changeArrow(false)
  }

  open(){
    this.changeArrow(true);
  }


  render() {
    return (
    <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>
      {(this.props.arrow!=true && this.props.hideTopbar!=true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.props.disable!=true) {this.setState({view:!this.state.view}) ; if(this.props.onOpen!=undefined) this.props.onOpen()   }   }}>



              <MavTextInput iconBackgroundColor={style.backgroundColorContrast} maxiMode={this.props.maxiMode} textInputFlex={(this.props.textInputFlex!=undefined)?this.props.textInputFlex:undefined}  marginSize={this.props.marginSize} textInputColor={(this.props.modification==true)?"orange":((this.props.warning==true)?style.colorNotification:undefined) } arrow={(this.props.arrow==true)?true:false} arrowEnabled={(this.props.arrow==true && this.state.arrowEnabled==true)?true:false} style={{marginTop:5}} editable={false} textInputFlex={(this.props.textInputFlex!=undefined)?this.props.textInputFlex:1.5} disabledText={(this.props.textSup!=undefined)?false:true} text={this.props.textSup} disableLine={(this.state.view==true)?true:false} 

              icon={(this.state.view==true && this.props.maxiMode!="partial" )?undefined:this.props.icon} 
              iconClassicSmall={(this.props.icon!=undefined && (this.state.view!=true || this.props.maxiMode=="partial") )?false:true}   
              iconClassic={( (this.props.icon!=undefined && this.state.view!=true) || this.props.maxiMode=="partial")?undefined:this.props.iconClassic} 



              enableNextTop={(this.state.view==true && this.props.disable!=true && this.props.arrow!=true)?true:false} enableNextBottom={(this.state.view==true || this.props.disable==true || this.props.arrow==true)?false:true} textExplication={ this.props.text } textExplicationMini={this.props.textMini} textRight={this.props.textRight}  textExplicationOnPress={(this.props.textExplicationOnPress!=undefined)?this.props.textExplicationOnPress:undefined} textExplicationBold={(this.props.textBold==true)?true:false}  onChange={()=>{ this.changeArrow()}}  />
      </TouchableHighlight>}

      {this.props.arrow==true && <MavTextInput maxiMode={(this.props.maxiMode==true)} textInputFlex={(this.props.textInputFlex!=undefined)?this.props.textInputFlex:undefined}  marginSize={this.props.marginSize} textInputColor={(this.props.warning==true)?style.colorNotification:undefined } arrow={(this.props.arrow==true)?true:false} arrowEnabled={(this.props.arrow==true && this.state.arrowEnabled==true)?true:false} style={{marginTop:5}} editable={true} textInputFlex={(this.props.textInputFlex!=undefined)?this.props.textInputFlex:1.5} disabledText={(this.props.textSup!=undefined)?false:true} text={this.props.textSup} disableLine={(this.state.view==true)?true:false} iconClassicSmall={(this.props.icon!=undefined && this.state.view!=true)?false:true} icon={(this.state.view==true)?undefined:this.props.icon}  iconClassic={(this.props.icon!=undefined && this.state.view!=true)?undefined:this.props.iconClassic} enableNextTop={(this.state.view==true && this.props.disable!=true && this.props.arrow!=true)?true:false} enableNextBottom={(this.state.view==true || this.props.disable==true || this.props.arrow==true)?false:true} textExplication={ this.props.text } textExplicationMini={this.props.textMini} textRight={this.props.textRight}  textExplicationOnPress={(this.props.textExplicationOnPress!=undefined)?this.props.textExplicationOnPress:undefined} textExplicationBold={(this.props.textBold==true)?true:false}  onChange={()=>{this.changeArrow()}}  />}
    
        {this.state.view==true && <View style={{backgroundColor:(this.props.modification==true)?"#EAC893":((this.props.warning==true)?"#DC7676":style.backgroundColorContrast),shadowOpacity: 0.3,shadowRadius:2,shadowOffset: {height: 2,width: 0},elevation:1 }}> 
              {this.props.children}

              <View style={{width:15, height:15}} />
        </View>}


    </View>
    }</StyleContext.Consumer>
    	)}

    }

