import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  ScrollView,
  Animated,
  ActivityIndicator,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import countryList from '../countryList';
import moneyList from '../moneyList';

import Modal from './modal';

const timer = require('react-native-timer');
import MavButton from './mavButton';
import TrainDetail from './trainDetail';
import firebase from './firebase';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, equalTo, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";
import MavAlert from './mavAlert';

export default class TextPresentation extends Component {
constructor(props) { 
    super(props);



    var forceView=false
    if(this.props.defautVisible==true && this.props.children!=undefined) forceView=true; 
    
    this.state = {
      view:forceView,
      suggestions:undefined, 
      lockSearch:false
      

    }
  }
capitalizeFirstLetter(string) {
  string=(string!=undefined)?string:"";
  string=string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}

close(){
    this.setState({view:false})
  }


  suggestionsSearchCityINSEE = (text) => {

    if(text.length>3 && this.state.lockSearch==false) {
      
      fetch("https://geo.api.gouv.fr/communes?nom="+text+"&fields=nom,codesPostaux,codeDepartement&format=json&geometry=centre", {
                        headers: {"Content-Type": "application/json"},method: "GET"}).then((response) => response.json())
                    .then((responseJson) => { 
                          var list=[];
                          var i; 
                          var listProv={}
                          if(responseJson.length>0){
                            var max=responseJson.length;
                            if(max>10) max=10;
                            for(i=0; i<max;i++){


                              listProv={name:this.capitalizeFirstLetter(responseJson[i].nom), inseeCode:responseJson[i].code, postalCode:responseJson[i].codesPostaux[0], departement:responseJson[i].codeDepartement}
                              list.push(listProv)
                            }

                            var listPartial=[]
                            for(i=0; i<list.length;i++){
                             if(i%2==0) listPartial.push(list[i]);  
                            }

                            this.setState({suggestions: list, suggestionsPartial:listPartial,lockSearch:true})
                            timer.setTimeout("getSuggestionsinsee", () => { this.setState({lockSearch:false})  }, 200); 



                          }

                          

                        })






      

    }

   this.setState({clientEnterprise: text })


 }


  suggestionsSearchEnterprise = (text) => {

    if(text.length>3 && this.state.lockSearch==false) {
      
      fetch("https://entreprise.data.gouv.fr/api/sirene/v1/full_text/"+text, {
                        headers: {"Content-Type": "application/json"},method: "GET"}).then((response) => response.json())
                    .then((responseJson) => { 
                          var list=[];
                          var i; 
                          var listProv={}
                          if(responseJson.etablissement!=undefined){
                            for(i=0; i<responseJson.etablissement.length;i++){


                              listProv={name:this.capitalizeFirstLetter(responseJson.etablissement[i].nom_raison_sociale), adress:"",city:"" }
                              if(responseJson.etablissement[i].geo_adresse!=undefined){
                                responseJson.etablissement[i].geo_adresse=responseJson.etablissement[i].geo_adresse.split(responseJson.etablissement[i].code_postal)
                                listProv.adress=responseJson.etablissement[i].geo_adresse[0];
                                listProv.city=responseJson.etablissement[i].code_postal+responseJson.etablissement[i].geo_adresse[1];
                              }


                              list.push(listProv)
                            }

                            var listPartial=[]
                            for(i=0; i<list.length;i++){
                             if(i%2==0) listPartial.push(list[i]);  
                            }

                            this.setState({suggestions: list, suggestionsPartial:listPartial,lockSearch:true})
                            timer.setTimeout("getPartnersList", () => { this.setState({lockSearch:false})  }, 200); 



                          }

                          

                        })






      

    }

   this.setState({clientEnterprise: text })


 }

 triAccountSuggestion(listName){
      var list=[];
      var i; 
      var listProv={}
      var listNameArray=Object.keys(listName)

      for(i=0; i<listNameArray.length;i++){
          listProv={clientID:listNameArray[i],name:this.capitalizeFirstLetter(listName[listNameArray[i]].name), lastName:this.capitalizeFirstLetter(listName[listNameArray[i]].lastName),email:listName[listNameArray[i]].email, phone:listName[listNameArray[i]].phone}
          
          if(listName[listNameArray[i]].enterprise!=undefined) listProv.enterprise=this.capitalizeFirstLetter(listName[listNameArray[i]].enterprise)
          if(listName[listNameArray[i]].adressBill!=undefined) listProv.adressBill=listName[listNameArray[i]].adressBill;
          if(listName[listNameArray[i]].cityBill!=undefined) listProv.cityBill=listName[listNameArray[i]].cityBill;
          if(listName[listNameArray[i]].usersMoreInfosDriver!=undefined) listProv.usersMoreInfosDriver=listName[listNameArray[i]].usersMoreInfosDriver;

          list.push(listProv)
      }

    var listPartial=[]
    for(i=0; i<((list.length>9)?9:list.length) ;i++){
     if(i%2==0) listPartial.push(list[i]);  
    }

    this.setState({suggestions: list, suggestionsPartial:listPartial})

 }

suggestionsSearchAccount= (text,type) => {

  if(text.length>2){
      if(this.state.oldAccountText==undefined || this.state.oldAccountText.includes(text[0]+text[1]+text[2])==false ){
       /*je lance une recherche pour les retrouver les clients*/
       getAuth(firebase).currentUser.getIdToken().then(token => {

          var body={userID:getAuth(firebase).currentUser.uid,token:token,driverMode:this.props.driverMode,suggestion:type, text:text }

          fetch("https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/getUsersFavorite", {body:JSON.stringify(body),method: "POST"}).then((response) => response.json()).then((responseJson) => {
            
              
              if(responseJson==null) responseJson={}
              this.triAccountSuggestion(responseJson)
              this.setState({oldAccountText:text, oldAccountSuggestions:responseJson})

          }).catch((err) => {console.log(err) })

       })

      }
      else {
        console.log("je trie les résultats")
        /*je trie les résultats*/
        var listNameArray=Object.keys(this.state.oldAccountSuggestions)
        var i; 
        var listName={}
        for(i=0; i<listNameArray.length;i++){
          console.log("je vais tester "+this.state.oldAccountSuggestions[listNameArray[i]].name+" avec "+text)
          if(this.state.oldAccountSuggestions[listNameArray[i]].name.includes(text)) listName[listNameArray[i]]=this.state.oldAccountSuggestions[listNameArray[i]];
        }

        this.triAccountSuggestion(listName)
      }


  } else this.setState({suggestions:undefined, suggestionsPartial:undefined})



  

 }


 resetSuggestions=()=>{
    this.setState({suggestions:undefined, suggestionsPartial:undefined})
 }


 suggestionsSearch = (text) => {

  console.log("suggestionsSearch lancé")
    if(this.props.suggestions=="enterprise") this.suggestionsSearchEnterprise(text); 
    if(this.props.suggestions=="cityINSEE") this.suggestionsSearchCityINSEE(text); 
    else if(this.props.suggestions=="name") this.suggestionsSearchAccount(text, "name")
    else if(this.props.suggestions=="lastName") this.suggestionsSearchAccount(text, "lastName")

 }

 changeOnOff = (text) => {
  console.log("changeOnOff ")
  if(this.props.disableAnimation!=true){
      if(this.props.onoff==true) {
        Animated.timing(this.onOffButtonPlace, {toValue: -1,duration: 100, useNativeDriver: false}).start()
        Animated.timing(this.onOffOpacity, {toValue: 0,duration: 100, useNativeDriver: false}).start()
      }
      else {
        Animated.timing(this.onOffButtonPlace, {toValue: 19*((this.props.maxiMode==true)?1.7:1),duration: 100, useNativeDriver: false}).start()
        Animated.timing(this.onOffOpacity, {toValue: 1,duration: 100, useNativeDriver: false}).start()
      }
      timer.setTimeout("timerBut", () => {if(this.props.onOnOff!=undefined) this.props.onOnOff() }, 120);
     /**/
   } else if(this.props.onOnOff!=undefined) this.props.onOnOff()
 }

 changeQuantity= (text) => {

  if(this.props.onChangeQuantity!=undefined) this.props.onChangeQuantity(text)

 }





findPlaceSNCF(position){
  
      fetch("https://api.sncf.com/v1/coverage/sncf/places/"+this.props.stationCoor.longitude+"%3B"+this.props.stationCoor.latitude+"/places_nearby?", {method: "GET",headers: {'Authorization': '670396b7-c369-4eae-bb7e-eb3f17426463', 'Content-Type': 'application/x-www-form-urlencoded'}, }).then(response => response.json()).then(responseJson => {
          console.log(responseJson)
          if(responseJson!=undefined && responseJson.places_nearby!=undefined){
              this.findStationInfo(responseJson.places_nearby[0])
              this.setState({stationName:responseJson.places_nearby[0].name})
          }
      })
  }


findStationInfo(placeID){
      var date=(this.props.stationCoor.type=="arrival")?new Date(this.props.raceDate-15*60*1000):new Date(this.props.raceDate+5*60*1000)
      date=date.getFullYear()+""+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+""+((date.getDate()<10)?"0":"")+date.getDate()+"T"+((date.getHours()<10)?"0":"")+date.getHours()+""+((date.getMinutes()<10)?"0":"")+date.getMinutes()+"00"

      var found=false;
      console.log("https://api.sncf.com/v1/coverage/sncf/stop_areas/"+placeID.id+"/"+((this.props.stationCoor.type=="arrival")?"arrivals":"departures")+"?from_datetime="+date+"&")
      fetch("https://api.sncf.com/v1/coverage/sncf/stop_areas/"+placeID.id+"/"+((this.props.stationCoor.type=="arrival")?"arrivals":"departures")+"?from_datetime="+date+"&", {method: "GET",headers: {'Authorization': '670396b7-c369-4eae-bb7e-eb3f17426463', 'Content-Type': 'application/x-www-form-urlencoded'}, }).then(response => response.json()).then(responseStation => {

                        if(responseStation!=undefined){

                            var listToTest=(this.props.stationCoor.type=="arrival")?responseStation.arrivals:responseStation.departures
                            if(listToTest==undefined) listToTest=[];

                            var i;
                            var hourTrain;
                            var list=[]
                            var listPartial=[]
                            for(i=0; i<listToTest.length;i++){
                              

                              hourTrain=((this.props.stationCoor.type=="arrival")?listToTest[i].stop_date_time.base_arrival_date_time:listToTest[i].stop_date_time.base_departure_date_time)
                              hourTrain=hourTrain[9]+hourTrain[10]+":"+hourTrain[11]+hourTrain[12]
                              hourTrain=((this.props.stationCoor.type=="arrival")?"Arrivée prévue à":"Départ prévu à")+" "+hourTrain
                              list.push({journey:listToTest[i].links[1].id, name:listToTest[i].display_informations.trip_short_name, hourTrain:hourTrain, trainName:listToTest[i].display_informations.commercial_mode, adress:"",city:"" })

                              }
                              var listPartial=[]
                              for(i=0; i<list.length;i++){
                               if(i%2==0) listPartial.push(list[i]);  
                              }
                              this.setState({suggestions: list, suggestionsPartial:listPartial})

                            }

                      })

  }



    viewMoney(){
      var selected=-1;
      var i;
      var moneyListTotal=[]
      for(i=0;i<moneyList.length;i++){
          if(moneyList[i].id==this.props.money.id) selected=i;
          moneyListTotal.push({id:moneyList[i].id, text:moneyList[i][strings.idLangue]})
      }
      


      this.alert.onAlert({title:strings.moneySelected, items:moneyListTotal,itemSelected:selected, buttons:[{text: strings.validate,onPress: (id,value) => {
        console.log(moneyList[id])
        if(this.props.onChangeMoney!=undefined)this.props.onChangeMoney(moneyList[id]);
       
      }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })  

  }





  componentDidMount() {
      if(this.props.suggestions=="train"){
        this.findPlaceSNCF()
      }

  }

  render() {

    this.onOffButtonPlace = new Animated.Value((this.props.onoff==true)?(19*((this.props.maxiMode==true)?1.7:1)):-1);
    this.onOffOpacity = new Animated.Value((this.props.onoff==true)?1:0);

    return (
      <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>

   



            <View style={{marginLeft:'5%', marginRight:'5%', width:"90%",}}>

                          {this.props.hideTopbar!=true && <View style={{justifyContent:"space-between", flexDirection:"row", alignItems:"center", marginTop:10, marginBottom:10,  }}>

                                      <View style={{flexDirection:"row",  alignItems:(this.props.multiline==true)?"flex-start":"center",   maxWidth:((this.props.onoff!=undefined && this.props.disableOnOff!=true)  || (this.props.reduceTextSize==true && (this.props.children!=undefined && this.props.disableChild!=true)) )?((Platform.OS!="web")?"72%":"85%"):((this.props.textRight!=undefined || (this.props.selectedQuantity!=undefined && this.props.disableQuantity!=true))?"65%":((this.props.multiline==true)?"90%":( (this.props.radio!=undefined && this.props.disableRadio!=true)?"85%":"100%")) )}} >
                                         
                                          {(this.props.statusMode!=undefined || this.props.statusModeMini!=undefined) && <View style={{width: (this.props.statusModeMini!=undefined)?20:40, height: (this.props.statusModeMini!=undefined)?20:40, marginRight:(this.props.statusModeMini!=undefined)?7:5,justifyContent: 'center',alignItems:"center"}}>   
    
                                            <View style={{width: 20, height: 20,shadowRadius: 0.8,shadowOffset: {height: 0,width: 0},elevation: 1, borderRadius: 10,backgroundColor:(this.props.statusModeMini!=undefined)?this.props.statusModeMini:this.props.statusMode}} /> 
                                        </View>}

                                         {(this.props.icon!=undefined && this.props.iconCircle!=true && this.props.onNext==undefined) && <Image source={ this.props.icon} style={{ height:((this.props.minimizeTopBar==true)?17:25)*((this.props.maxiMode==true)?1.7:1)*((this.props.minimizeIcon==true)?0.7:1),width:((this.props.minimizeTopBar==true)?17:25)*((this.props.maxiMode==true)?1.7:1)*((this.props.minimizeIcon==true)?0.7:1),resizeMode:"contain", marginTop:-3, marginRight:6}}/>}
                                         
                                         {(this.props.icon!=undefined && this.props.iconCircle!=true && this.props.onNext!=undefined) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {if(this.props.onNext!=undefined) this.props.onNext(); else if(this.props.children!=undefined && this.props.disableChild!=true) this.setState({view:!this.state.view}) ; else if((this.props.viewEdit==true || this.props.iconCircle==true) && this.props.onEditClick!=undefined) this.props.onEditClick()  }}>
                                            <View>
                                                <Image source={ this.props.icon} style={{ height:((this.props.minimizeTopBar==true)?17:25)*((this.props.maxiMode==true)?1.7:1)*((this.props.minimizeIcon==true)?0.7:1),width:((this.props.minimizeTopBar==true)?17:25)*((this.props.maxiMode==true)?1.7:1)*((this.props.minimizeIcon==true)?0.7:1),resizeMode:"contain", marginTop:-3, marginRight:6}}/>
                                                {this.props.iconColorChip!=undefined && <View style={{width:10, height:10, backgroundColor:this.props.iconColorChip, position:"absolute",borderRadius:5, marginTop:((this.props.minimizeTopBar==true)?17:25)*((this.props.maxiMode==true)?1.7:1)*((this.props.minimizeIcon==true)?0.7:1)-10, marginLeft:((this.props.minimizeTopBar==true)?17:25)*((this.props.maxiMode==true)?1.7:1)*((this.props.minimizeIcon==true)?0.7:1)-10 }} />}
                                            </View>
                                          </TouchableHighlight>}

                                         {(this.props.icon!=undefined && this.props.iconCircle==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {if(this.props.onNext!=undefined) this.props.onNext(); else if(this.props.children!=undefined && this.props.disableChild!=true) this.setState({view:!this.state.view}) ; else if((this.props.viewEdit==true || this.props.iconCircle==true) && this.props.onEditClick!=undefined) this.props.onEditClick()  }}>
                                              <View style={{shadowOpacity: 0.5,  shadowColor:(style.mode=="dark")?'#A0A2A2':"#4D4D4D", shadowRadius: 0.8,shadowOffset: {height: 0,width: 0},elevation: 1,borderRadius:(this.props.minimizeTopBar==true)?10:20, backgroundColor:(this.props.iconCircleColor!=undefined)?this.props.iconCircleColor:style.color1, height:(this.props.minimizeTopBar==true)?20:40,width:(this.props.minimizeTopBar==true)?20:40,marginTop:-3, marginRight:6*((this.props.maxiMode==true)?1.7:1), alignItems:"center", justifyContent:"center" }}>
                                                    <Image source={this.props.icon} style={{height:((this.props.minimizeIcon==true)?0.7:1)*((this.props.minimizeTopBar==true)?15:22),width:((this.props.minimizeIcon==true)?0.7:1)*((this.props.minimizeTopBar==true)?15:22),resizeMode:"contain", }}/>
                                              </View>
                                              </TouchableHighlight>
                                            }
                                          


                                          <View style={{alignItems:"flex-start", width:(Platform.OS=="web")?"100%":undefined,}}>
                                              {this.props.textExplication!=undefined && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {if(this.props.forceClick!=undefined) this.props.forceClick(); else if(this.props.children!=undefined && this.props.disableChild!=true) this.setState({view:!this.state.view}) ; else if( (this.props.viewEdit==true || this.props.iconCircle==true) && this.props.onEditClick!=undefined) this.props.onEditClick(); else if(this.props.onNext!=undefined) this.props.onNext();  }}>
                                                  <View style={{flexDirection:"row"}}>
                                                        <Text style={{height:(this.props.textExplicationMultiLine==true)?"100%":undefined, marginRight:(this.props.editable==false && this.props.text=="")?"8%":undefined, color:(this.props.noAvailable==true)?"#9A9A9A":((this.props.colorYellow==true)?"#9B9019":((this.props.colorYellow==true)?"#9B9019":((this.props.colorNotification==true)?style.colorNotification :((this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor)))),fontStyle: 'italic',fontSize: style.textInputFontSize*((this.props.textExplication!=undefined && this.props.textExplication.length>50)?0.75:0.80)*((this.props.maxiMode==true)?2.5:1),textAlign: "left"}}>{(this.props.textExplication!=undefined)?this.props.textExplication.toUpperCase():""}</Text>
                                                        {((this.props.viewEdit==true || (this.props.children!=undefined && this.props.disableChild!=true)) && this.props.disableViewEdit!=true)  && <Image source={(this.props.noAvailable==true)?require('../assets/edit_grey.png'):((this.props.colorNotification==true)?require('../assets/edit_notification.png'):((this.props.colorWaiting==true)?require('../assets/edit_waiting.png'): ((style.mode=="dark")?require('../assets/edit_white.png'):require('../assets/edit_black.png'))))} style={{height:12*((this.props.maxiMode==true)?2:1),width:12*((this.props.maxiMode==true)?2:1),marginLeft:5*((this.props.maxiMode==true)?1.7:1),marginTop:((this.props.maxiMode==true)?3:undefined), resizeMode:"contain"}}/>}
                                                  </View>
                                              </TouchableHighlight>}

                                              <View style={{flexDirection:"row", alignItems:"center", marginTop:-3, width:(Platform.OS=="web")?"100%":undefined, }}>
                                                  <TouchableHighlight style={{ width:(Platform.OS=="web")?"100%":undefined,}} underlayColor={'#FFFFFF00'} onPress={() => {if(this.props.forceClick!=undefined) this.props.forceClick(); if(this.props.onNext!=undefined) this.props.onNext() ;else if(this.props.onTextExplication!=undefined) this.props.onTextExplication() }}>

                                                      <View style={{alignItems:"flex-start", flex:1}}>
                                                        {this.props.textExplicationSmall!=undefined && <Text style={{marginLeft:0, marginTop:2,color: (this.props.colorYellow==true)?"#9B9019":((this.props.colorNotification==true)?style.colorNotification :((this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor)),fontSize: style.textInputFontSize*0.6,textAlign: "left",width:(this.props.textRight!=undefined || this.props.icon!=undefined )?((this.props.textRight!=undefined && this.props.textRight.length>5)?"55%":"63%"):"98%"}}>{this.props.textExplicationSmall}</Text>}

                                                        {((this.props.text!=undefined && this.props.editable==false) && !((this.props.children!=undefined && this.props.disableChild!=true) || (this.props.viewEdit==true && this.props.onEditClick!=undefined))) && <View style={{flexDirection:"row", alignItems:"center", }}>
                                                              <Text numberOfLines={(this.props.textWithBold!=undefined && Platform.OS!="web")?1:undefined}  style={{ marginLeft:-2,marginRight:(this.props.reduceTextSize==true)?"5%":undefined, color:(this.props.noAvailable==true)?"#9A9A9A":((this.props.colorNotification==true)?style.colorNotification : style.textInputColor),fontSize: style.textInputFontSize*((this.props.minimizeTopBar==true)?1.1:1.35)*((this.props.reduceTextSize==true)?0.75:1)*((this.props.reduceTextSizeOnOff==true)?0.85:1) *((this.props.maxiMode==true)?2.5:1),textAlign: "left",}}><Text style={{fontWeight: 'bold',}} >{this.props.textWithBold!=undefined?(this.props.textWithBold+' '):""}</Text>{this.props.text}</Text>
                                                              {this.props.textPrecision!=undefined && <Text style={{color:(this.props.noAvailable==true)?"#9A9A9A":((this.props.colorNotification==true)?style.colorNotification : style.textInputColor),fontSize: style.textInputFontSize*0.8*((this.props.maxiMode==true)?3:1),textAlign: "left", marginLeft:3, marginTop:3}}>{this.props.textPrecision}</Text>}

                                                        </View>}

                                                        {((this.props.text!=undefined && (this.props.editable==false || this.props.children!=undefined )) && ((this.props.children!=undefined && this.props.disableChild!=true) || (this.props.viewEdit==true && this.props.onEditClick!=undefined))) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.props.children!=undefined && this.props.disableChild!=true) this.setState({view:!this.state.view}) ; else if(this.props.viewEdit==true && this.props.onEditClick!=undefined) this.props.onEditClick()  }}>
                                                          <View style={{flexDirection:"row", alignItems:"center",}}>
                                                              <Text numberOfLines={(this.props.textWithBold!=undefined && Platform.OS!="web")?1:undefined} style={{ marginTop:((this.props.maxiMode==true)?-5:undefined), marginLeft:-2,marginRight:(this.props.reduceTextSize==true)?"5%":undefined,color:(this.props.noAvailable==true)?"#9A9A9A":((this.props.colorNotification==true)?style.colorNotification : style.textInputColor),fontSize: style.textInputFontSize*((this.props.minimizeTopBar==true)?1.1:1.35)*((this.props.reduceTextSize==true)?0.75:1)*((this.props.maxiMode==true)?2.5:1),textAlign: "left",}}><Text style={{fontWeight: 'bold',}} >{this.props.textWithBold!=undefined?(this.props.textWithBold+' '):""}</Text>{this.props.text}</Text>
                                                              {this.props.textPrecision!=undefined && <Text style={{color:(this.props.colorNotification==true)?style.colorNotification : style.textInputColor,fontSize: style.textInputFontSize*0.8*((this.props.maxiMode==true)?3:1),textAlign: "left", marginLeft:3, marginTop:3}}>{this.props.textPrecision}</Text>}

                                                          </View>

                                                        </TouchableHighlight>}




                                                        

                                                        {(this.props.text!=undefined && this.props.editable!=false && this.props.children==undefined) && <TextInput allowFontScaling={false}
                                                              accessibilityLabel={this.props.accessibilityLabel}
                                                              keyboardType={(this.props.keyboardType==undefined || Platform.OS=="web")?"default":this.props.keyboardType}
                                                              placeholder={this.props.placeholder}
                                                              secureTextEntry={(this.props.secureTextEntry==undefined)?false:((this.state.viewPassword==true)?false:this.props.secureTextEntry)}
                                                              placeholderTextColor={style.placeholderTextInputColor}
                                                              autoCorrect={false}   
                                                              multiline={(this.props.multiline==true)?true:false}
                                                              autoCapitalize={(this.props.autoCapitalize==undefined)?"sentences":this.props.autoCapitalize}
                                                              underlineColorAndroid="rgba(0,0,0,0)"

                                                              style={{textAlign:"left", marginTop:((Platform.OS=="web")?4:undefined), fontSize:style.textInputFontSize*((this.props.icon!=undefined)?1.35:1.2)*((this.props.reduceTextSize==true)?0.75:1)*((this.props.maxiMode==true)?2.5:1),color:(this.props.onlyTextInputColor!=undefined)?this.props.onlyTextInputColor:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor), minWidth:(this.props.multiline==true && Platform.OS=="web")?400:250,  minHeight:(this.props.multiline==true)?70:undefined, }}
                                                              onEndEditing={() => {
                                                                if(this.props.onEndEditing!=null) this.props.onEndEditing();

                                                              } }
                                                              
                                                              onChangeText={text => {
                                                                if(this.props.onChangeText!=null) this.props.onChangeText(text);
                                                                if(this.props.suggestions!=undefined) this.suggestionsSearch(text); 


                                                              }}
                                                              value={this.props.text}
                                                        />}


                                                        {this.props.textMini!=undefined && <Text style={{marginLeft:0, marginTop:(this.props.text==undefined)?2:-2,color: (this.props.colorYellow==true)?"#9B9019":((this.props.colorNotification==true)?style.colorNotification :((this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor)),fontSize: style.textInputFontSize*0.6*((this.props.maxiMode==true)?2.5:1),textAlign: "left", }}>{this.props.textMini}</Text>}
















                                                    </View>
                                                  </TouchableHighlight>

                                              </View>
                                          </View>


                                          
                                          


                                      </View>

                                      <View style={{alignItems:"center", flexDirection:"row"}}>
                                              
                                              {(this.props.selectedQuantity!=undefined && this.props.disableQuantity!=true) && <View style={{flexDirection:"row", alignItems:"center",justifyContent:"center"}}>

                                                   <TouchableHighlight underlayColor={"#D6D6D6"} onPress={() => {this.changeQuantity(-1) }} style={{borderRadius: 15*((this.props.maxiMode==true)?1.7:1), borderColor:"#ADADAD", alignItems:"center",justifyContent:"center", borderWidth:1, width:30*((this.props.maxiMode==true)?1.7:1), height:30*((this.props.maxiMode==true)?1.7:1), backgroundColor:(style.mode=="dark")?"#ADADAD":style.backgroundColor}}>
                                                          <Text style={{marginTop:-2, textAlign:"center",fontSize:style.textInputFontSize*1.4*((this.props.maxiMode==true)?1.7:1),color:(style.mode!="dark")?"#ADADAD":style.backgroundColor}}>{"-"}</Text>
                                                  </TouchableHighlight>

                                                  <View style={{}}>
                                                      <Text style={{textAlign:"center", marginLeft:5*((this.props.maxiMode==true)?1.7:1),marginRight:5*((this.props.maxiMode==true)?1.7:1), fontSize:style.textInputFontSize*1.1*((this.props.maxiMode==true)?1.7:1),color:(this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor,fontWeight: 'bold',}}>{""+this.props.selectedQuantity}</Text>
                                                      {this.props.selectedQuantityText!=undefined && <Text style={{textAlign:"center",fontSize:style.textInputFontSize*0.5*((this.props.maxiMode==true)?1.7:1),color:(this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor,fontWeight: 'bold',}}>{""+this.props.selectedQuantityText}</Text>}
                                                  </View>

                                                  <TouchableHighlight underlayColor={"#D6D6D6"} onPress={() => {this.changeQuantity(1)}} style={{borderRadius: 15*((this.props.maxiMode==true)?1.7:1), borderColor:"#ADADAD", alignItems:"center",justifyContent:"center", borderWidth:1, width:30*((this.props.maxiMode==true)?1.7:1), height:30*((this.props.maxiMode==true)?1.7:1), backgroundColor:(style.mode=="dark")?"#ADADAD":style.backgroundColor,}}>
                                                          <Text style={{marginTop:-2, textAlign:"center",fontSize:style.textInputFontSize*1.4*((this.props.maxiMode==true)?1.7:1),color:(style.mode!="dark")?"#ADADAD":style.backgroundColor,}}>{"+"}</Text>
                                                  </TouchableHighlight>


                                              </View>}

                                              {this.props.enableInfo==true && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.props.onInfo!=undefined) this.props.onInfo() }}> 
                                                    <View style={{flexDirection:"row", }} >
                                                       <Image source={(style.mode=="light")?require('../assets/iconi_black.png'):require('../assets/iconi_white.png')} style={{height:13,width:13,resizeMode:"contain", }}/>
                                                      <Text style={{marginLeft:2, color: style.textInputColor,fontSize: style.textInputFontSize*0.9,textAlign: "center",fontWeight: 'bold'}}>{strings.infos}</Text>
                                                    </View>
                                                </TouchableHighlight>}

                                              

                                              {(this.props.onoff!=undefined && this.props.disableOnOff!=true) && <View style={{flexDirection:"row", alignItems:"center"}}>

                                                  {this.props.onOffText!=undefined && <View style={{}}>
                                                      <Text style={{textAlign:"center", marginLeft:5*((this.props.maxiMode==true)?1.7:1),marginRight:5*((this.props.maxiMode==true)?1.7:1), fontSize:style.textInputFontSize*1.1*((this.props.maxiMode==true)?1.7:1),color:(this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor,fontWeight: 'bold',}}>{""+this.props.onOffText}</Text>
                                                      {this.props.onOffTextMini!=undefined && <Text style={{textAlign:"center",fontSize:style.textInputFontSize*0.5*((this.props.maxiMode==true)?1.7:1),color:(this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor,fontWeight: 'bold',marginTop:-2}}>{""+this.props.onOffTextMini}</Text>}
                                                  </View>}

                                                  <TouchableHighlight underlayColor={((style.mode=="dark")?style.backgroundColorContrast:"#D6D6D6")} onPress={() => {this.changeOnOff() }} style={{borderRadius: 15*((this.props.maxiMode==true)?1.7:1), borderColor:"#ADADAD", borderWidth:1*((this.props.maxiMode==true)?1.7:1), width:50*((this.props.maxiMode==true)?1.7:1), height:30*((this.props.maxiMode==true)?1.7:1), backgroundColor:((style.mode=="dark")?style.backgroundColorContrast:"#D6D6D6")}}>
                                                    <View style={{flex:1}}>
                                                    <Animated.View style={{position:"absolute",borderRadius: 15*((this.props.maxiMode==true)?1.7:1),  width:48*((this.props.maxiMode==true)?1.7:1), height:28*((this.props.maxiMode==true)?1.7:1), opacity:this.onOffOpacity, backgroundColor:((style.color1=="#FFFFFF" || style.color1=="#000000")?style.colorValidate:style.color1)}} />
                                                    <Animated.View  style={{borderRadius: 15*((this.props.maxiMode==true)?1.7:1),borderColor:"#ADADAD", marginTop:-1, marginLeft:this.onOffButtonPlace, borderWidth:1*((this.props.maxiMode==true)?1.7:1), width:30*((this.props.maxiMode==true)?1.7:1), height:30*((this.props.maxiMode==true)?1.7:1), backgroundColor:(style.mode=="dark")?"#ADADAD":style.backgroundColor}}/>
                                                    </View>
                                                  </TouchableHighlight>

                                                  


                                                  
                                                </View>}



                                                {(this.props.radio!=undefined && this.props.disableRadio!=true) && <TouchableHighlight underlayColor={((style.mode=="dark")?style.backgroundColorContrast:"#D6D6D6")} onPress={() => {if(this.props.onRadio!=undefined) this.props.onRadio(); }} style={{borderRadius: 15*((this.props.maxiMode==true)?1.7:1), borderColor:"#ADADAD", borderWidth:1*((this.props.maxiMode==true)?1.7:1), width:30*((this.props.maxiMode==true)?1.7:1), height:30*((this.props.maxiMode==true)?1.7:1), backgroundColor:((style.mode=="dark")?"#ADADAD":style.backgroundColor)}}>
                                                  <View style={{flex:1}}>
                                                  <Animated.View style={{position:"absolute",borderRadius: 11*((this.props.maxiMode==true)?1.7:1), marginTop:3, marginLeft:3,  width:22*((this.props.maxiMode==true)?1.7:1), height:22*((this.props.maxiMode==true)?1.7:1), backgroundColor:(this.props.radio==true)?((style.color1=="#FFFFFF" || style.color1=="#000000")?style.colorValidate:style.color1):undefined}} />
                                                  </View>
                                                </TouchableHighlight>}




                                               {((this.props.elementRight!=undefined) && (this.props.children!=undefined && this.props.disableChild!=true)) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {if(this.props.children!=undefined && this.props.disableChild!=true) this.setState({view:!this.state.view}) ; }} style={{right:(this.props.children!=undefined && this.props.disableChild!=true)?-25:undefined}}>{this.props.elementRight}</TouchableHighlight>}

                                               {((this.props.elementRight!=undefined) && !(this.props.children!=undefined && this.props.disableChild!=true)) && <View style={{right:(this.props.children!=undefined && this.props.disableChild!=true)?-25:undefined}}>{this.props.elementRight}</View>}



                                              {(this.props.children!=undefined && this.props.disableChild!=true)  && <TouchableHighlight underlayColor={'#FFFFFF00'} style={{alignItems:"flex-end", justifyContent:"center", height:30, marginTop:-3, width:70}} onPress={() => {if(this.props.children!=undefined && this.props.disableChild!=true) this.setState({view:!this.state.view}) ; }}>


                                                    <Image source={(this.props.colorNotification==true)? require('../assets/next_notification.png')  :((this.props.colorWaitingOnlyRight!=true)?require('../assets/next.png'):require('../assets/next_waiting.png'))} style={{transform: [{rotate:(this.state.view==true)?"270deg" :"90deg"}], width: 35*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1), height: 35*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1) }}/>
                                                  
                                              </TouchableHighlight>}


                                              {(this.props.onNext!=undefined)  && <TouchableHighlight underlayColor={'#FFFFFF00'} style={{alignItems:"flex-end", justifyContent:"center", height:30, marginTop:-3, width:30}} onPress={() => {this.props.onNext() }}>
                                                    <Image source={(this.props.colorNotification==true)? require('../assets/next_notification.png')  :((this.props.colorWaitingOnlyRight!=true)? ( (style.mode=="light")?require('../assets/calendar_after_light.png'):require('../assets/calendar_after_dark.png') )   :require('../assets/next_waiting.png'))} style={{width: 25*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1), height: 25*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1) }}/>
                                              </TouchableHighlight>}


                                               {(this.props.enableValidate==true) && <Image source={require('../assets/check_mark.png')} style={{width: 20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1), height: 20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1) }}/>}
                                               {(this.props.enableError==true) && <Image source={require('../assets/error_mark.png')} style={{width: 20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1), height: 20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1) }}/>}



                                               {(this.props.enableRemove!=undefined)  && <TouchableHighlight underlayColor={'#FFFFFF00'} style={{alignItems:"flex-end", justifyContent:"center", height:30, marginTop:-3, width:70}} onPress={() => {if(this.props.onRemove!=undefined) this.props.onRemove() }}>

                                                    <Image source={require('../assets/favorite_cancel.png')} style={{width: 25*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1), height: 25*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1) }}/>
                                                  
                                              </TouchableHighlight>}


                                              {(this.props.viewLock==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} style={{alignItems:"flex-end", justifyContent:"center", height:30, marginTop:-3, width:70}} onPress={() => {if(this.props.onLock!=undefined) this.props.onLock() }}>

                                                    <Image source={(style.mode=="dark")?require('../assets/lock_white.png'):require('../assets/lock_black.png') } style={{width: 20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1), height: 20*( (this.props.maxiMode==true)?((Dimensions.get('window').width>1000)?3:2.3):1) }}/>
                                                  
                                              </TouchableHighlight>}

                                              {this.props.chooseMoney==true && <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { this.viewMoney()  }} >
                                                    <Image source={this.props.flagMoney} style={{width:50, height: 25,resizeMode:"contain" }}/>
                                                </TouchableHighlight>}

                                              {(this.props.textRight!=undefined && this.props.editable!=false && this.props.onTextRightClick==undefined) && <TextInput allowFontScaling={false}
                                                    accessibilityLabel={this.props.accessibilityLabel}
                                                    keyboardType={(this.props.keyboardType==undefined || Platform.OS=="web")?"default":this.props.keyboardType}
                                                    placeholder={this.props.placeholder}
                                                    secureTextEntry={(this.props.secureTextEntry==undefined)?false:((this.state.viewPassword==true)?false:this.props.secureTextEntry)}
                                                    placeholderTextColor={style.placeholderTextInputColor}
                                                    autoCorrect={false}   
                                                    multiline={(this.props.multiline==true)?true:false}
                                                    autoCapitalize={(this.props.autoCapitalize==undefined)?"sentences":this.props.autoCapitalize}
                                                    underlineColorAndroid="rgba(0,0,0,0)"

                                                    style={{textAlign:"right",fontSize:style.textInputFontSize*1.4,fontWeight: 'bold',color:(this.props.onlyTextInputColor!=undefined)?this.props.onlyTextInputColor:((this.props.textInputColor!=undefined)?this.props.textInputColor:style.textInputColor), }}
                                                    onEndEditing={() => {
                                                      if(this.props.onEndEditing!=null) this.props.onEndEditing();

                                                    } }
                                                    
                                                    onChangeText={text => {
                                                      if(this.props.onChangeText!=null) this.props.onChangeText(text);


                                                    }}
                                                    value={this.props.textRight}
                                                />}


                                             {(this.props.textRight!=undefined && this.props.onTextRightClick!=undefined) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.props.onTextRightClick()}}>
                                                <View style={{ flexDirection:'row', alignItems:"center" }}>
                                                    <Image source={(this.props.noAvailable==true)?require('../assets/edit_grey.png'):( (this.props.colorWaiting==true)?require('../assets/edit_waiting.png'):((style.mode=="dark")?require('../assets/edit_white.png'):require('../assets/edit_black.png')))} style={{height:8,width:8,marginRight:2, resizeMode:"contain"}}/>
                                                        <Text style={{textAlign:"right",fontSize:style.textInputFontSize*1.4,color:(this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor,fontWeight: 'bold',}}>{this.props.textRight}</Text>
                                                </View>
                                              </TouchableHighlight>}


                                              {(this.props.textRight!=undefined && this.props.editable==false && this.props.onTextRightClick==undefined ) && <View>
                                                  <Text style={{textAlign:"right",fontSize:style.textInputFontSize*((this.props.textRightRemoved==true)?0.85:1.4),color:(this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor,fontWeight: (this.props.textRightRemoved==true)?undefined:'bold', textDecorationLine: (this.props.textRightRemoved==true)?'line-through':undefined, }}>{this.props.textRight}</Text>
                                                  {(this.props.textRightMini!=undefined) && <Text style={{textAlign:"right",fontSize:style.textInputFontSize*0.7,color:(this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor,marginTop:-5,fontWeight: 'bold',}}>{this.props.textRightMini}</Text>}

                                              </View>}
                                          </View>

                                      
                                      
                          </View>}


              </View>



            {(this.state.suggestions!=undefined && (Object.keys(this.state.suggestions).length>0) && !(this.props.suggestions=="train" && this.props.text!="")) && <View style={{marginLeft:'5%', marginRight:'5%', width:"90%",  }}> 
                <Text style={{textAlign:"left",fontSize:style.textInputFontSize*0.8,color:style.textInputColor,marginBottom:5}}>{strings.suggestions}</Text>


                {this.state.suggestionsPartial.map((rowData,id) => ( 
                
                <View style={{flexDirection:"row", alignItems:"center", marginTop:(id>0)?5:0, justifyContent:"space-between",}}>

                  <TouchableHighlight style={{width:'49%',  height:(this.props.suggestions=="train" || this.props.suggestions=="name"  || this.props.suggestions=="cityINSEE" || this.props.suggestions=="lastName")?38:44, backgroundColor:(style.mode=="dark")?"#292D36":"#EFEFEF",borderWidth:0.5,borderColor: style.textInputLineColor }} underlayColor={'#FFFFFF00'} onPress={() => {if(this.props.onSuggestion!=undefined) this.props.onSuggestion(this.state.suggestionsPartial[id]); this.setState({suggestions:undefined, suggestionsPartial:undefined});}} >
                      <View>
                      {this.props.suggestions=="cityINSEE" && <View style={{width:"100%", height:"100%", alignItems:"center", justifyContent:"center"}}>
                        <Text numberOfLines={1} style={{textAlign:"left",fontSize:style.textInputFontSize*0.9,color:style.textInputColor,}}>{this.state.suggestionsPartial[id].postalCode+" "+this.state.suggestionsPartial[id].name}</Text>
                        
                      </View>}

                      {this.props.suggestions=="enterprise" && <View >
                        <Text numberOfLines={1} style={{marginLeft:4, marginTop:4, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.85,color:style.textInputColor,}}>{this.state.suggestionsPartial[id].name}</Text>
                        <Text numberOfLines={1} style={{marginLeft:4, marginTop:-1, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.67,color:style.textInputColor,}}>{this.state.suggestionsPartial[id].adress}</Text>
                        <Text numberOfLines={1} style={{marginLeft:4, marginTop:-1, marginRight:2, marginBottom:4, textAlign:"left",fontSize:style.textInputFontSize*0.67,color:style.textInputColor,}}>{this.state.suggestionsPartial[id].city}</Text>
                      </View>}

                      {(this.props.suggestions=="name" || this.props.suggestions=="lastName") && <View >
                        <Text numberOfLines={1} style={{marginLeft:4, marginTop:4, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.95,color:style.textInputColor,}}>{this.state.suggestionsPartial[id].name+" "+this.state.suggestionsPartial[id].lastName}</Text>
                        {(this.state.suggestionsPartial[id].entreprise!=undefined || this.state.suggestionsPartial[id].phone!=undefined || this.state.suggestionsPartial[id].email!=undefined) &&<Text numberOfLines={1} style={{marginLeft:4, marginTop:-1, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.67,color:style.textInputColor,}}>{(this.state.suggestionsPartial[id].entreprise!=undefined)?this.state.suggestionsPartial[id].entreprise: ((this.state.suggestionsPartial[id].phone!=undefined)?this.state.suggestionsPartial[id].phone:this.state.suggestionsPartial[id].email)}</Text>}
                        
                      </View>}

                      {(this.props.suggestions=="train") && <View style={{justifyContent:"center",justifyContent:"space-between", flexDirection:"row", }}>

                        <View>
                            <View style={{marginLeft:4, marginTop:4, flexDirection:"row", alignItems:"center",}} >
                              <Image source={(this.state.suggestionsPartial[id].trainName=="TGV")?require('../assets/sncf/sncf.png'): ((this.state.suggestionsPartial[id].trainName=="OUIGO")?require('../assets/sncf/ouigo.png'):( (this.state.suggestionsPartial[id].trainName=="TER")? require('../assets/sncf/ter.png') :( (this.state.suggestionsPartial[id].trainName=="RER")? require('../assets/sncf/rer.png'): ( (this.state.suggestionsPartial[id].trainName=="TGV Lyria")? require('../assets/sncf/lyria.png'): ((this.state.suggestionsPartial[id].trainName=="Thalys")? require('../assets/sncf/thalys.png') :( (this.state.suggestionsPartial[id].trainName=="TGV INOUI")? (style.mode=="light")?require('../assets/sncf/inoui_light.png'):require('../assets/sncf/inoui_dark.png') : ((style.mode=="light")?require('../assets/favorite/train_black.png'):require('../assets/favorite/train_white.png'))  )  ) )  )  ))} style={{height:15,width:15,resizeMode:"contain",marginRight:4}}/>
                              <Text numberOfLines={1} style={{ textAlign:"left",fontSize:style.textInputFontSize*0.95,color:style.textInputColor,}}>{this.state.suggestionsPartial[id].trainName.toUpperCase()+" N°"+this.state.suggestionsPartial[id].name}</Text>
                            </View>

                            <Text numberOfLines={1} style={{marginLeft:4, marginTop:-1, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.75,color:style.textInputColor,}}>{this.state.suggestionsPartial[id].hourTrain}</Text>
                        </View>

                        <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.setState({viewTrailDetail:true, journey:this.state.suggestionsPartial[id].journey}) }} style={{width:30, height:"100%",marginTop:2, alignItems:"center", justifyContent:"center",}}>
                            <Image source={(style.mode=="dark")?require('../assets/iconiWhite.png'):require('../assets/iconi.png') } style={{width:12,height:12,}}></Image>
                        </TouchableHighlight>

                      </View>}

                  </View>

                  </TouchableHighlight>









                  {(this.state.suggestions[id*2+1]!=undefined) && <TouchableHighlight style={{width:'49%',  height:(this.props.suggestions=="train" || this.props.suggestions=="cityINSEE" || this.props.suggestions=="name" || this.props.suggestions=="lastName")?38:44, backgroundColor:(style.mode=="dark")?"#292D36":"#EFEFEF",borderWidth:0.5,borderColor: style.textInputLineColor }} underlayColor={'#FFFFFF00'} onPress={() => { if(this.props.onSuggestion!=undefined) this.props.onSuggestion(this.state.suggestions[id*2+1]); this.setState({suggestions:undefined, suggestionsPartial:undefined});   }} >
                    <View>
                      {this.props.suggestions=="cityINSEE" && <View style={{width:"100%", height:"100%", alignItems:"center", justifyContent:"center"}}>
                        <Text numberOfLines={1} style={{textAlign:"left",fontSize:style.textInputFontSize*0.9,color:style.textInputColor,}}>{this.state.suggestionsPartial[id].postalCode+" "+this.state.suggestionsPartial[id].name}</Text>
                        
                      </View>}

                      {(this.props.suggestions=="enterprise") && <View >
                        <Text numberOfLines={1} style={{marginLeft:4, marginTop:4, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.85,color:style.textInputColor,}}>{this.state.suggestions[id*2+1].name}</Text>
                        <Text numberOfLines={1} style={{marginLeft:4, marginTop:-1, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.67,color:style.textInputColor,}}>{this.state.suggestions[id*2+1].adress}</Text>
                        <Text numberOfLines={1} style={{marginLeft:4, marginTop:-1, marginRight:2, marginBottom:4, textAlign:"left",fontSize:style.textInputFontSize*0.67,color:style.textInputColor,}}>{this.state.suggestions[id*2+1].city}</Text>
                      </View>}

                      {(this.props.suggestions=="name" || this.props.suggestions=="lastName") && <View >
                        <Text numberOfLines={1} style={{marginLeft:4, marginTop:4, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.95,color:style.textInputColor,}}>{this.state.suggestions[id*2+1].name+" "+this.state.suggestions[id*2+1].lastName}</Text>
                        {(this.state.suggestions[id*2+1].entreprise!=undefined || this.state.suggestions[id*2+1].phone!=undefined || this.state.suggestions[id*2+1].email!=undefined) &&<Text numberOfLines={1} style={{marginLeft:4, marginTop:-1, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.67,color:style.textInputColor,}}>{(this.state.suggestions[id*2+1].entreprise!=undefined)?this.state.suggestions[id*2+1].entreprise: ((this.state.suggestions[id*2+1].phone!=undefined)?this.state.suggestions[id*2+1].phone:this.state.suggestions[id*2+1].email)}</Text>}
                        
                      </View>}


                      {(this.props.suggestions=="train") && <View style={{justifyContent:"center",justifyContent:"space-between", flexDirection:"row", }}>

                        <View>
                            <View style={{marginLeft:4, marginTop:4, flexDirection:"row", alignItems:"center",}} >
                              <Image source={(this.state.suggestions[id*2+1].trainName=="TGV")?require('../assets/sncf/sncf.png'): ((this.state.suggestions[id*2+1].trainName=="OUIGO")?require('../assets/sncf/ouigo.png'):( (this.state.suggestions[id*2+1].trainName=="TER")? require('../assets/sncf/ter.png') :( (this.state.suggestions[id*2+1].trainName=="RER")? require('../assets/sncf/rer.png'): ( (this.state.suggestions[id*2+1].trainName=="TGV Lyria")? require('../assets/sncf/lyria.png'): ((this.state.suggestions[id*2+1].trainName=="Thalys")? require('../assets/sncf/thalys.png') :( (this.state.suggestions[id*2+1].trainName=="TGV INOUI")? (style.mode=="light")?require('../assets/sncf/inoui_light.png'):require('../assets/sncf/inoui_dark.png') : ((style.mode=="light")?require('../assets/favorite/train_black.png'):require('../assets/favorite/train_white.png'))  )  ) )  )  ))} style={{height:15,width:15,resizeMode:"contain",marginRight:4}}/>
                              <Text numberOfLines={1} style={{ textAlign:"left",fontSize:style.textInputFontSize*0.95,color:style.textInputColor,}}>{this.state.suggestions[id*2+1].trainName.toUpperCase()+" N°"+this.state.suggestions[id*2+1].name}</Text>
                            </View>

                            <Text numberOfLines={1} style={{marginLeft:4, marginTop:-1, marginRight:2, textAlign:"left",fontSize:style.textInputFontSize*0.75,color:style.textInputColor,}}>{this.state.suggestions[id*2+1].hourTrain}</Text>
                        </View>

                        <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.setState({viewTrailDetail:true, journey:this.state.suggestions[id*2+1].journey}) }} style={{width:30, height:"100%",marginTop:2, alignItems:"center", justifyContent:"center",}}>
                            <Image source={(style.mode=="dark")?require('../assets/iconiWhite.png'):require('../assets/iconi.png') } style={{width:12,height:12,}}></Image>
                        </TouchableHighlight>

                      </View>}



                    </View>


                  </TouchableHighlight>}

                </View>))}



            </View>}

            {(this.state.view==true || this.props.manualView==true) && <View style={{marginLeft:'5%', marginRight:'5%', width:"90%",  backgroundColor:(this.props.modification==true)?"#EAC893":((this.props.warning==true)?"#DC7676":style.backgroundColorContrast),shadowOpacity: 0.3,shadowRadius:2,shadowOffset: {height: 2,width: 0},elevation:1 }}> 
              {this.props.explicationChild!=undefined && <View style={{marginLeft:'5%', marginRight:'5%', width:"90%", marginTop:10 }}>
                  <View style={{flexDirection:"row"}}>
                      <Image source={(style.mode=="dark")?require('../assets/iconiWhite.png'):require('../assets/iconi.png') } style={{width:20,height:20,marginRight:10,}}></Image>
                      <Text style={{width:"90%", color:(this.props.colorYellow==true)?"#9B9019":((this.props.colorNotification==true)?style.colorNotification :((this.props.colorWaiting==true)?style.colorWaiting: style.textInputColor)),fontStyle: 'italic',fontSize: style.textInputFontSize*0.80,textAlign: "justify"}}>{this.props.explicationChild}</Text>
                  </View>
              </View>}

              {this.props.children}

              <View style={{width:15, height:15}} />
        </View>}

        {this.state.viewTrailDetail==true && <Modal animationType="slide" transparent={true} visible={true}> 

          <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",justifyContent:"center"}}>

              <TouchableHighlight underlayColor={"rgba(0,0,0,0.5)"} style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,backgroundColor:"rgba(0,0,0,0.5)",position:"absolute",marginTop:0}} onPress={() => {console.log("click nodal") }}>
                  <View/>
              </TouchableHighlight>



              <View style={{width:(Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth:(Dimensions.get('window').width*0.8*((this.state.drawerMini==true)?0.5:1)) ,backgroundColor:style.backgroundColor,position:"absolute",marginLeft:(Dimensions.get('window').width>style.mobileSizeMax)?((Dimensions.get('window').width-style.minimizeWidth)/2):(Dimensions.get('window').width*0.1*((this.state.drawerMini==true)?0.5:1)),shadowOpacity: 0.6,shadowRadius: 2,shadowOffset: {height: 0,width: 0},elevation: 3}}>
                    <TrainDetail stationName={this.state.stationName} journey={this.state.journey} close={(text)=>{ this.setState({viewTrailDetail:false}) }} />
              </View>

          </View>

        </Modal>}

        <MavAlert ref={alert => {this.alert = alert;}}/>

        </View>

         }</StyleContext.Consumer>


    	)}

    }

