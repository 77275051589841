import React, { Component } from 'react';
import {
  View,
  Text,

  TouchableHighlight,
  Alert,
 Platform, 
  Dimensions,
  Image,
} from 'react-native';
import {StyleContext,styleInfo} from '../styleC';
import firebase from './firebase';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue} from "firebase/database";

import strings from '../strings';
import info from '../info';
const timer = require('react-native-timer');
import MavAlert from './mavAlert';
import DeviceInfo from './deviceInfo';
import languageList from '../languageList';

export default class drawer extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      place:"",
      nextRaceLength:0,
      disabledShopping:true,
      lock:false,
    }; 


  }



  update = (value) => {
    console.log("update")
  };

  startDispenserMode = (value) => {
    const style=styleInfo();
   if(this.props.info.dispenser.adress!=undefined) {
      
      this.alert.onAlert({title:strings.dispenserMode,subTitle:strings.dispenserModeSure,drawerMini:((Dimensions.get('window').width>style.mobileSizeMax )?true:false),buttons:[{text: strings.yes,onPress: () => {
        var devInfo=DeviceInfo.getUniqueId(); 
        
        const fireInfo={} 
        fireInfo['/users/'+getAuth(firebase).currentUser.uid+"/dispenserMode/"+devInfo+"/actif"]=true
        update(ref(getDatabase(firebase)), fireInfo)
        
        this.props.close()


        

      },},{text: strings.no,style: 'cancel',onPress: () => { } },]})
    }
      else {
        this.alert.onAlert({title:strings.dispenserMode,subTitle:strings.dispenserModeMoreInfo,drawerMini:((Dimensions.get('window').width>style.mobileSizeMax )?true:false),buttons:[{text: strings.ok,onPress: () => {  }}]  }); 
      }



    
    
  }


  componentDidMount(){ 
    console.log("menu mount")




    

  }

  

  

  render() {
    const style=styleInfo();
    var list=[]
    list.push({name:strings.myAccount, linkTo:"Account", params:{update:this.update},    icon:(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png') })

    if(this.props.info.driver==false && this.props.info.partner==false && this.props.info.manager==false) list.push({name:strings.myRaces, linkTo:"Booking", params:{enableDriverNameInRace:this.props.info.enableDriverNameInRace, disableCancelButton: this.props.info.disableCancelButton, disabledOldRace:this.props.info.disabledOldRace },    icon:(style.mode=="dark")?require('../assets/favorite/pin_white.png'):require('../assets/favorite/pin_black.png') })
    
    
    if(this.props.info.partner==false || (this.props.info.partner==true && (this.props.info.privilegeDriver=="avance" || this.props.info.privilegeDriver=="max" ))) list.push({name:strings.myFavorite, linkTo:"Favorite",    icon:(style.mode=="dark")?require('../assets/favorite/customer_favorite_white.png'):require('../assets/favorite/customer_favorite_black.png') })
    if(this.props.info.driver==false && this.props.info.manager==false && this.props.info.partner==false && info.disablePayment==false) list.push({name:strings.payment, linkTo:"Payment", params:{extraEUMode:this.props.info.extraEUMode, waiting3DSecure: this.props.info.waiting3DSecure,waiting3DSecureStatus:this.props.info.waiting3DSecureStatus },    icon:(style.mode=="dark")?require('../assets/cardCB_white.png'):require('../assets/cardCB_black.png') })
    if(this.props.info.driver==false && this.props.info.manager==false && this.props.info.partner==false) list.push({name:strings.help, linkTo:"Help",    icon:(style.mode=="dark")?require('../assets/help_white.png'):require('../assets/help_black.png') })
    if(this.props.info.pickUpLocation==true && this.props.info.manager==false) list.push({name:strings.pickUpLocation, linkTo:"Favorite", params:{viewPickUp:true, favoritePickUp:this.props.info.favoriteGlobal },    icon:(style.mode=="dark")?require('../assets/route_light.png'):require('../assets/route_dark.png') })

    if(this.props.info.driver==false && this.props.info.manager==false && this.props.info.partner==false) list.push({name:strings.contact, linkTo:"Contact", params:{source:"drawer",contactFavoriteDriver:this.props.info.contactFavoriteDriver, hidePhoneApp:this.props.info.hidePhoneApp, favoriteDriver:this.props.info.favoriteDriver } ,  icon:(style.mode=="dark")?require('../assets/driver_mail_light.png'):require('../assets/driver_mail_dark.png') })


    if( Object.keys(this.props.info.raceWaiting).length>0 && ( this.props.info.driver==true || (this.props.info.manager==true && this.props.info.managerInfo.dispatch==true) || (this.props.info.partner==true && info.mavOffer=="multi") ) ) list.push({name:strings.nextRace+" ("+Object.keys(this.props.info.raceWaiting).length+")", linkTo:"Calendar", params:{ findRace: true,info:this.props.info, },    icon:(style.mode=="dark")?require('../assets/futureRace_white.png'):require('../assets/futureRace_black.png') })
    if(this.props.info.driver==true || this.props.info.partner==true || (this.props.info.manager==true && this.props.info.managerInfo.dispatch==true) ) list.push({name:strings.planning, linkTo:"Calendar", params:{info:this.props.info, partner:this.props.info.partner,driver:this.props.info.driver },    icon:(style.mode=="dark")?require('../assets/calendar_white.png'):require('../assets/calendar_black.png') })
    if(this.props.info.driver==true || (this.props.info.manager==true && this.props.info.managerInfo.phonebook==true) || this.props.info.customerPro==true || (this.props.info.partner==true && (this.props.info.privilegeDriver=="avance" || this.props.info.privilegeDriver=="max" || this.props.info.basicDriverAddRace==true )) || this.props.info.provider==true ) list.push({name:strings.repertoire, linkTo:"Clients", params:{ select: "all",manager:this.props.info.manager,driver:(this.props.info.driver==true || (this.props.info.manager==true && this.props.info.managerInfo.phonebook==true))?true:false, defineColorForCustomer:this.props.info.defineColorForCustomer, autorizeChangeDriverFavoritePrice:(this.props.info.autorizeChangeDriverFavoritePrice!=undefined && this.props.info.autorizeChangeDriverFavoritePrice==true && this.props.info.partner==true)?true:false ,partnerPartialAccessPhoneBook:(this.props.info.partnerPartialAccessPhoneBook!=undefined && this.props.info.partnerPartialAccessPhoneBook==true && this.props.info.partner==true)?true:false, driverPricing:this.props.info.driverPricing, reviews:{activateCustomerNotation:this.props.info.activateCustomerNotation, activateDriverNotation:this.props.info.activateDriverNotation}, options:{childSeats:this.props.info.childSeats,boosterSeats:this.props.info.boosterSeats,wheelchair:this.props.info.wheelchair, animal:this.props.info.animal} },    icon:(style.mode=="dark")?require('../assets/phoneBook_white.png'):require('../assets/phoneBook_black.png') })

    if( ( this.props.info.driver==true || this.props.info.partner==true || (this.props.info.manager==true && this.props.info.managerInfo.revenu==true)  )  && info.disableBill==false  ) list.push({name:strings.revenue, linkTo:"Revenue", params:{hidePriceRaceForDriver: this.props.info.hidePriceRaceForDriver, stripeManualOnRevenus:(this.props.info.stripeInfo!=undefined && this.props.info.stripeInfo.modeSelected=="stripeManualOnRevenus")?true:false, driver:( this.props.info.driver==true || (this.props.info.manager==true && this.props.info.managerInfo.revenu==true))?true:false,email:this.props.info.email,extraEUMode:this.props.info.extraEUMode,viewSelect:"revenue" },    icon:(style.mode=="dark")?require('../assets/revenue_white.png'):require('../assets/revenue_black.png') })
    
    if( this.props.info.driver==true && this.props.info.debugRevenue==true ) list.push({name:"Debug Facture", linkTo:"Revenue", params:{ debugRevenue:true, hidePriceRaceForDriver: this.props.info.hidePriceRaceForDriver, driver:this.props.info.driver,email:this.props.info.email,extraEUMode:this.props.info.extraEUMode,viewSelect:"revenue" },    icon:(style.mode=="dark")?require('../assets/revenue_white.png'):require('../assets/revenue_black.png') })


    if( ( this.props.info.driver==true || (this.props.info.manager==true && this.props.info.managerInfo.revenu==true)  || (this.props.info.partner==true && this.props.info.privilegeDriver=="max" ) )  && info.disableBill==false ) list.push({name:strings.billWaiting, linkTo:"Revenue", params:{ driver:( this.props.info.driver==true || (this.props.info.manager==true && this.props.info.managerInfo.revenu==true))?true:false,extraEUMode:this.props.info.extraEUMode,viewSelect:"waitBill",billWithoutDetail:this.props.info.billWithoutDetail },    icon:(style.mode=="dark")?require('../assets/waiting_white.png'):require('../assets/waiting_black.png') })


    if(this.props.info.driver==false && this.props.info.partner==true && (this.props.info.privilegeDriver=="max" || this.props.info.privilegeDriver=="avance") && this.props.info.driverPricing==true ) list.push({name:strings.priceSettings, linkTo:"PricePlace",params:{view:"onlyPrice", extraEUMode:this.props.info.extraEUMode, vipMode:this.props.info.vipMode, luggageOption:this.props.info.luggageOption,driverPricing:true},    icon:(style.mode=="dark")?require('../assets/settings_white.png'):require('../assets/settings_black.png') })

    


    if(this.props.info.driver==true || (this.props.info.manager==true && this.props.info.managerInfo.settings==true)) list.push({name:strings.settings, linkTo:"Settings",    icon:(style.mode=="dark")?require('../assets/settings_white.png'):require('../assets/settings_black.png') })
    if((this.props.info.driver==true || (this.props.info.manager==true && this.props.info.managerInfo.settings==true)) && this.props.info.hideMAV!=true) list.push({name:"PLUS D'OUTILS", linkTo:"Mavvous",    icon:(style.mode=="dark")?require('../assets/partner_light.png'):require('../assets/partner_dark.png') })

    if(this.props.info.provider==true && Platform.OS!="web" && info.disableOnBoardPayment!=true) list.push({name:strings.dispenserMode, linkTo:"dispenserMode",    icon:(style.mode=="dark")?require('../assets/tablet_white.png'):require('../assets/tablet_black.png') })



    list.push({name:strings.logout, linkTo:"logout",  icon:(style.mode=="dark")?require('../assets/logout_white.png'):require('../assets/logout_black.png') })

    var heightItem=(62*list.length)>((Dimensions.get('window').height-20)*0.75)?(( (Dimensions.get('window').height-20)*0.75)/list.length-1):62;
           




    let listFinal=list;


    return (
      <StyleContext.Consumer>{style => 
      <View style={{height: Dimensions.get('window').height,backgroundColor: (style.mode=="dark")?style.backgroundColor:style.backgroundColorContrast}}> 

            <View style={{backgroundColor:style.color1,height: Dimensions.get('window').height*0.25,flexDirection: 'row',alignItems: 'center',justifyContent: 'center',shadowOpacity: 0.3,shadowRadius: 2,shadowOffset: {height: 2,width: 0},elevation: 1}}>

                
                <Image source={require("../assets/drawer_background.png")} style={{height:Dimensions.get('window').height*0.25,width:"100%",resizeMode:"cover",position:"absolute"}}/>
                <View style={{height:Dimensions.get('window').height*0.25,width:"100%", position:"absolute",backgroundColor: 'rgba(0,0,0,0.5)'}}/>
              

                <View style={{alignItems:"center"}}>
                
                <Text style={{ fontSize: 25, color: '#ffffff',backgroundColor: 'rgba(0,0,0,0)', }}>{this.props.info.name+" "+((this.props.info.lastName!=undefined)?this.props.info.lastName.toUpperCase():"")}</Text>
            
                



                

                <View style={{flexDirection:"row"}}>
                  {this.props.info.customerPro==true && <View style={{width:40, height:20, marginRight:(this.props.info.vipMode==true && this.props.info.vip==true && (this.props.info.driver==false && this.props.info.partner==false))?5:0, borderColor:"#FFFFFF", borderWidth:1,alignItems:"center", justifyContent:"center", borderRadius:5}}><Text style={{ fontSize: 12, color: '#ffffff',backgroundColor: 'rgba(0,0,0,0)', }}>{"PRO"}</Text></View>}
                  {(this.props.info.vipMode==true && this.props.info.vip==true && (this.props.info.driver==false && this.props.info.partner==false)) && <View style={{width:40, height:20, borderColor:"#FFFFFF", borderWidth:1,alignItems:"center", justifyContent:"center", borderRadius:5}}><Text style={{ fontSize: 12, color: '#ffffff',backgroundColor: 'rgba(0,0,0,0)', }}>{strings.vip.toUpperCase()}</Text></View>}
                  {(this.props.info.driver==true) && <View style={{width:60, height:20, borderColor:"#FFFFFF", borderWidth:1,alignItems:"center", justifyContent:"center", borderRadius:5}}><Text style={{ fontSize: 12, color: '#ffffff',backgroundColor: 'rgba(0,0,0,0)', }}>{"ADMIN"}</Text></View>}
                  {(this.props.info.manager==true) && <View style={{width:100, height:20, borderColor:"#FFFFFF", borderWidth:1,alignItems:"center", justifyContent:"center", borderRadius:5}}><Text style={{ fontSize: 12, color: '#ffffff',backgroundColor: 'rgba(0,0,0,0)', }}>{strings.manager.toUpperCase()}</Text></View>}
                </View>

                {(this.props.info.note!=undefined) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {
                  if(Platform.OS!="web"){
                    if(this.state.lock==false){
                      this.setState({lock:true}); 
                      timer.setTimeout(generateUUID(), () => {this.setState({lock:false})}, 2000);
                      var pars={partner:this.state.partner, id:getAuth(firebase).currentUser.uid,note:this.props.info.note};
                      if(this.props.info.driver==true || this.props.info.partner==true) pars.partner=true
                      this.props.close("NoteInfo",pars)
                    

                    }  
                  }
                }}>

                  <View style={{flexDirection:'row',marginTop:7, justifyContent:"center", alignItems:"center"}}> 

                      <Image source={(this.props.info.note.note>0)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_dark.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_dark.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.props.info.note.note>1)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_dark.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_dark.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.props.info.note.note>2)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_dark.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_dark.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.props.info.note.note>3)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_dark.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_dark.png") )} style={{width:20,height:20,marginRight:2,}}></Image>
                      <Image source={(this.props.info.note.note>4)?((style.mode=="dark")?require("../assets/star1_dark.png"):require("../assets/star1_dark.png")): ((style.mode=="dark")?require("../assets/star2_dark.png"):require("../assets/star2_dark.png") )} style={{width:20,height:20,marginRight:2,}}></Image>

                      <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",marginLeft:10,color: '#ffffff',}}>{"("+this.props.info.note.note+"/5 - "+this.props.info.note.reviews+" "+strings.reviews.toLowerCase()+")"}</Text>

                </View>
                </TouchableHighlight>}

                </View>



            </View>

            {listFinal.map(item => (


              <View>
                    <TouchableHighlight underlayColor={'#FFFFFF00'} style={{height:heightItem,flexDirection: 'row',alignItems: 'center',}}
                      onPress={() => {
                        if(this.state.lock==false){
                          this.setState({lock:true})
                          timer.setTimeout(generateUUID(), () => {this.setState({lock:false})}, 2000);
                            
                          if(item.linkTo=="logout") {
                            
                            this.alert.onAlert({title:strings.disconnect,subTitle:strings.disconnectAnswer,drawerMini:((Dimensions.get('window').width>style.mobileSizeMax )?true:false), buttons:[{text: strings.yes,onPress: () => {
                            this.props.removeOldPricing(); 
                            if(getAuth(firebase).currentUser.uid!=null){

                              const fireInfo={} 
                              fireInfo['/users/'+getAuth(firebase).currentUser.uid+"/notificationsID/"+DeviceInfo.getUniqueId()]=null
                              update(ref(getDatabase(firebase)), fireInfo)

                            }
                            if(Platform.OS!='web'){
                                var i; 
                                for(i=0; i<languageList.length;i++){
                                  
                                  messaging().unsubscribeFromTopic("ads"+languageList[i].id);
                                  messaging().unsubscribeFromTopic("adsPro"+languageList[i].id);
                                  messaging().unsubscribeFromTopic("adsProvider"+languageList[i].id);
                                  messaging().unsubscribeFromTopic("adsDriver"+languageList[i].id);
                                  
                                }
                            }

                            getAuth(firebase).signOut()},},{text: strings.no,style: 'cancel',onPress: () => {  } },]})
                          }
                          else if(item.linkTo=="dispenserMode") {this.startDispenserMode();} 
                          else if(item.params!=undefined) this.props.close(item.linkTo,item.params)
                          else this.props.close(item.linkTo)
                        }
                      }}>
                        <View style={{alignItems:"flex-start"}}>
                            <Image source={item.icon} style={{marginLeft:12,height:22,width:22,resizeMode:"cover",position:"absolute"}}/>
                            <View style={{justifyContent: 'center',alignItems: 'center',height:22,}}>
                                <Text style={{color:style.textInputColor,marginLeft:42, textAlign:"center",fontSize: style.drawerFontSize*0.9,}}>{item.name.toUpperCase()}</Text>
                            </View>

                        </View>

                      </TouchableHighlight>
                      <View style={{height:style.textLineHeight,backgroundColor:"#ffffff",width:"100%"}}/>
                </View>



              ))}


        <MavAlert ref={alert => {this.alert = alert;}}/>

      </View>
      }</StyleContext.Consumer>
    );
  }
}

drawer.navigationOptions = ({ navigation, screenProps }) => {
  return {
    headerTintColor: screenProps.headerColor,
    headerStyle: {
      backgroundColor: screenProps.headerBackgroundColor,
    },
    
   
  };
};


export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

