import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  Dimensions,
  ActivityIndicator, 
  Image,
  Alert,
  Switch,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import languageList from '../languageList';
import firebase from './firebase';
import MavButton from './mavButton';
import TextPresentation from './textPresentation';

import MavAlert from './mavAlert';
const timer = require('react-native-timer');

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

export default class help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted:true,
      waitValue:true, 
      delayRaceMin:60,
      immediateMode:false,
      delayRaceMinTimer:"",
      disableAutomaticMapFrom:false, 
      childSeats:false,
      forceManualDispatch:false,
      removeRace5minutes:false,
      removeFavoriteDriver30:false,
      viewCalcPrice:false, 
      activateGoogleMyBusiness:false, 
      activateGoogleMyBusinessTimer:"", 
      googleMyBusinessText:{fr:""}, 
      googleMyBusinessTextTimer:"",
      disableCancelButton:false,
      disabledOldRace:false,
      addCommissionFixe:false,
      addCommissionVariable:false, 
      driverStatus:false,
      wheelchair:false,
      reminder:{},
      reminderTimer:"",
      animal:false, 
      animalTimer:"",
      enableRealPosition:false,
      viewRealDriver:false,
      viewRealDriverForDriver:false,
      viewRealDriverTimer:"",
      viewRealDriverForDriverTimer:"",
      enableTimezone:false, 
      enableTimezoneTimer:"", 
      taxiMode:false, 
      taxiModeTimer:"", 
      defineMinimalPassager:false,
      hideOtherCarIfRaceInProgress:false,
      hideOtherCarIfRaceInProgressTimer:"",
      removeRace5minutesTimer:"",
      carDriverColor:"",
      driverApproachingColor:"", 
      colorTimer:"",
      saveRemovedRace:false, 
      purposeReturn:false,
      seePOIMap:true,
      messageBeforeBook:false,
      messageBeforeBookTimer:"",
      messageEstimation:false,
      messageEstimationTimer:"",
      disabledPromoCode:false,
      withKarhoo:false, 
      withLeTaxi:false,
      viewKarhooForDrivers:false, 
      viewPartnerForDrivers:false,
      defineColorForCustomer:false,
      pricingPrecise:false,
      debugOtherOption:false, 

      driverPricing:false,
      unavailableIfRace:false,
      viewTrajetCustomerDriver:false,
      viewMajorationDynamicDriver:false,
      extraEUMode:false,
      optionalOrder:{},
      takingTraffic:false, 
      hidePriceRaceForDriver:false,
      boosterSeats:false,
      hideHoursMajoration:false,
      luggageOption:false,
      viewCalcPriceTimer:"",
      addCommissionFixeAmount:"",
      addCommissionVariableAmount:"",
      preblockAccount:false,
      addCommissionVariable:"",
      priceNetForDriver:false,
      extraEUModeTimer:"",
      optionalOrderTimer:"",
      viewAccurency:false,
      viewAccurencyTimer:"", 
      viewAccurencyList:{}, 
      viewAccurencyListTimer:"",
      hideMajoration:false, 
      hideMajorationTimer:"",
      hideParticularRqs:false,
      sendMailToAdmin:false, 
      modifRaceAdmin:false,
      raceStatusAdmin:false,
      orderFor:false,
      picturesCar:undefined, 
      picturesCarTimer:"",
      billWithoutDetail:false, 
      basicDriverAddRace:false,
      autoriseDriverSeeRace:false, 
      partnerPartialAccessPhoneBook:false,
      autorizeChangeDriverFavoritePrice:false,
      viewWaitingPaymentRace:false,
      viewWaitingPaymentRace:"",
      activateDriverNotation:false,
      activateCustomerNotation:false,
      activateComment:false,
      multiStop:false,
      multiStopTimer:"",
      activateCommentTimer:"",
      activateDriverNotationTimer:"",
      activateCustomerNotationTimer:"",
      pickUpLocation:false,
      pickUpLocationTimer:"",
      customerAutomaticCancel:false, 
      customerAutomaticCancelTimer:"", 
      providerFixeCommission:false,
      co2onBill:false,       
      trajetBird:false,
      addButtonCancelMain:false,
      co2onOrder:false,
      co2onBillTimer:"",  
      co2onOrderTimer:"",
      contactFavoriteDriver:false,
      enableOrderDriver:false, 
      longRank:false,
      customerMessageFreeCancel:false,
      customerMessageFreeCancelTimer:"",
      delayRaceMax:false,
      delayRaceMaxTimer:"",
      userMaxRace:false,
      userMaxRaceTimer:"",
      vipMode:false, 
      vipModeTimer:"",
      sendMessageIfPaiementError:false, 
      qrCustomerVerif:false,
      enableFlightTrainVerif:false,
      hidePhoneApp:false,
      enableDriverNameInRace:false,
      addClientBook:false,
      preblockAccountMessage:{}, 
      roomHotel:false,
    };
  }

  componentDidMount() {
      console.log("setting mount")

      get(child(ref(getDatabase(firebase)), '/settings')).then(value => {
        var val=value.val();
        if(val!=null){
          var st={};
          if(val.addCommissionFixeAmount!=undefined) val.addCommissionFixeAmount+="€";
          if(val.addCommissionVariableAmount!=undefined) val.addCommissionVariableAmount+="%";

          val.waitValue=false;
          
          this.setState(val)

        }

        else this.setState({waitValue:false})

      })

  }

  updateInfotoFB= (name,value) => { 
      console.log("je suis dans updateInfotoFB avec "+name+" = "+value)

      var st=JSON.parse(JSON.stringify(this.state))
      st[name]=value; 
      this.setState(st);

      var linkToFB='/settings/'+name;
      const fireInfo={} 
      fireInfo[linkToFB]=(value==true)?true:null;
      update(ref(getDatabase(firebase)), fireInfo)

  }

  






  reminder= (type,value) => { 

    var reminder=this.state.reminder;
    if(type=="race") reminder.race=value; 
    else if(type=="tomorrow") reminder.tomorrow=value; 
    else if(type=="arrivate") reminder.arrivate=value; 
    else if(type=="raceAdmin15") reminder.raceAdmin15=value; 
    else if(type=="notStarted20") reminder.notStarted20=value; 

    

    this.setState({reminder:reminder, reminderTimer:timer.setTimeout("timer", () => {

          const fireInfo={} 
          if(type=="race") fireInfo['/settings/reminder/race']=(this.state.reminder.race==true)?true:null; 
          else if(type=="tomorrow") fireInfo['/settings/reminder/tomorrow']=(this.state.reminder.tomorrow==true)?true:null; 
          else if(type=="arrivate") fireInfo['/settings/reminder/arrivate']=(this.state.reminder.arrivate==true)?true:null; 
          else if(type=="raceAdmin15") fireInfo['/settings/reminder/raceAdmin15']=(this.state.reminder.raceAdmin15==true)?true:null; 
          else if(type=="notStarted20") fireInfo['/settings/reminder/notStarted20']=(this.state.reminder.notStarted20==true)?true:null;

          update(ref(getDatabase(firebase)), fireInfo)

        }, 300)    
    })
  }



    updatePicturesCar= value => { 
    this.setState({picturesCar:value, picturesCarTimer:timer.setTimeout("timer", () => {
      const fireInfo={} 
      fireInfo['/settings/picturesCar']=this.state.picturesCar
      update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
  }


   updateColorDriver= (mode,value) => { 
    if(mode=="carDriverColor") this.setState({carDriverColor:value, colorTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/carDriverColor']=this.state.carDriverColor; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
    else this.setState({driverApproachingColor:value, colorTimer:timer.setTimeout("timer", () => {
      const fireInfo={} ; fireInfo['/settings/driverApproachingColor']=this.state.driverApproachingColor ; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
  
  }


  updateHideOtherCarIfRaceInProgress= value => { 
    this.setState({hideOtherCarIfRaceInProgress:value, hideOtherCarIfRaceInProgressTimer:timer.setTimeout("timer", () => {const fireInfo={}; fireInfo['/settings/hideOtherCarIfRaceInProgress']=this.state.hideOtherCarIfRaceInProgress; update(ref(getDatabase(firebase)), fireInfo) }) })
 }

   updateDriverPositionPrecise= value => { 
    this.setState({driverPositionPrecise:value, driverPositionPreciseTimer:timer.setTimeout("timer", () => {const fireInfo={}; fireInfo['/settings/driverPositionPrecise']=this.state.driverPositionPrecise; update(ref(getDatabase(firebase)), fireInfo) }) })
 }

 

  updatePickUpLocation= value => { 
    this.setState({pickUpLocation:value, pickUpLocationTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; 
      fireInfo['/settings/pickUpLocation']=(this.state.pickUpLocation==true)?true:null;
       update(ref(getDatabase(firebase)), fireInfo) 
     }, 2000)    })
  }

  
  updateActivateComment= value => { 
    this.setState({activateComment:value, activateCommentTimer:timer.setTimeout("timer", () => {const fireInfo={}; fireInfo['/settings/activateComment']=this.state.activateComment; update(ref(getDatabase(firebase)), fireInfo)}, 2000)    })
  }


  updateGoogleMyBusinessText= value => { 
    this.setState({googleMyBusinessText:value, googleMyBusinessTextTimer:timer.setTimeout("timer", () => {const fireInfo={}; fireInfo['/settings/googleMyBusinessText']=this.state.googleMyBusinessText; update(ref(getDatabase(firebase)), fireInfo)}, 2000)    })
  }

   updateActivateGoogleMyBusiness= value => { 
    this.setState({activateGoogleMyBusiness:value, activateGoogleMyBusinessTimer:timer.setTimeout("timer", () => {const fireInfo={}; fireInfo['/settings/activateGoogleMyBusiness']=this.state.activateGoogleMyBusiness; update(ref(getDatabase(firebase)), fireInfo)    }, 2000)    })
  }






  updateEnableTimezone= value => { 
    this.setState({enableTimezone:value, enableTimezoneTimer:timer.setTimeout("timer", () => {const fireInfo={}; fireInfo['/settings/enableTimezone']=this.state.enableTimezone; update(ref(getDatabase(firebase)), fireInfo)}, 2000)    })
  }

  

  updateActivateDriverNotation= value => { 
    this.setState({activateDriverNotation:value, activateDriverNotationTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/activateDriverNotation']=this.state.activateDriverNotation; update(ref(getDatabase(firebase)), fireInfo)
      if(this.state.activateDriverNotation==false && this.state.activateCustomerNotation==false && this.state.activateComment==true) this.updateActivateComment(false)
    }, 2000)

      })
  }

  updateActivateCustomerNotation= value => { 
    this.setState({activateCustomerNotation:value, activateCustomerNotationTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/activateCustomerNotation']=this.state.activateCustomerNotation; update(ref(getDatabase(firebase)), fireInfo)
      if(this.state.activateDriverNotation==false && this.state.activateCustomerNotation==false && this.state.activateComment==true) this.updateActivateComment(false)
        }, 2000)    })
  }
  

  updateCustomerAutomaticCancel(value){

    if(this.state.customerAutomaticCancel!=false ) this.setState({customerAutomaticCancel:false, customerAutomaticCancelTimer:timer.setTimeout("timer", () => {const fireInfo={}; fireInfo['/settings/customerAutomaticCancel']=false; update(ref(getDatabase(firebase)), fireInfo) }, 2000)    })
    else {

      this.alert.onAlert({title:strings.customerAutomaticCancel, subTitle:strings.timeBeforeRace , textInput:{placeholder:"60",text:"",sizeMultiplicator:2,keyboardType:"numeric"},buttons:[
                  {text: strings.validate,onPress: (value) => {
                   console.log(value)
                   value=parseInt(value);
                   if(value.toString()!="NaN" ) this.setState({customerAutomaticCancel:value, customerAutomaticCancelTimer:timer.setTimeout("timer", () => {
                    const fireInfo={}; fireInfo['/settings/customerAutomaticCancel']=value; update(ref(getDatabase(firebase)), fireInfo)
                    }, 2000)    })

                  }},
                  ],})

    }
  }


    updateCustomerMessageFreeCancel(value){

    if(this.state.customerMessageFreeCancel!=false ) this.setState({customerMessageFreeCancel:false, customerMessageFreeCancelTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/customerMessageFreeCancel']=false; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
    else {

      this.alert.onAlert({title:strings.customerMessageFreeCancel, subTitle:strings.timeBeforeRace , textInput:{placeholder:"60",text:"",sizeMultiplicator:2,keyboardType:"numeric"},buttons:[
                  {text: strings.validate,onPress: (value) => {
                   console.log(value)
                   value=parseInt(value);
                   if(value.toString()!="NaN" ) this.setState({customerMessageFreeCancel:value, customerMessageFreeCancelTimer:timer.setTimeout("timer", () => {
                    const fireInfo={}; fireInfo['/settings/customerMessageFreeCancel']=value; update(ref(getDatabase(firebase)), fireInfo)
                    }, 2000)    })

                  }},
                  ],})

    }
  }


     updateDelayRaceMax(value){

    if(this.state.delayRaceMax!=false ) this.setState({delayRaceMax:false, delayRaceMaxTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/delayRaceMax']=false; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
    else {

      this.alert.onAlert({title:strings.delayRaceMax, subTitle:strings.delayRaceMaxFixed , textInput:{placeholder:"60",text:"",sizeMultiplicator:2,keyboardType:"numeric"},buttons:[
                  {text: strings.validate,onPress: (value) => {
                   console.log(value)
                   value=parseInt(value);
                   if(value.toString()!="NaN" ) this.setState({delayRaceMax:value, delayRaceMaxTimer:timer.setTimeout("timer", () => {
                    const fireInfo={}; fireInfo['/settings/delayRaceMax']=value; update(ref(getDatabase(firebase)), fireInfo)
                    }, 2000)    })

                  }},
                  ],})

    }
  }

  updateUserMaxRace(value){

    if(this.state.userMaxRace!=false ) this.setState({userMaxRace:false, userMaxRaceTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/userMaxRace']=false; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
    else {

      this.alert.onAlert({title:strings.userMaxRace, subTitle:strings.userMaxRaceFixed , textInput:{placeholder:"10",text:"",sizeMultiplicator:2,keyboardType:"numeric"},buttons:[
                  {text: strings.validate,onPress: (value) => {
                   console.log(value)
                   value=parseInt(value);
                   if(value.toString()!="NaN" ) this.setState({userMaxRace:value, userMaxRaceTimer:timer.setTimeout("timer", () => {
                    const fireInfo={}; fireInfo['/settings/userMaxRace']=value; update(ref(getDatabase(firebase)), fireInfo)
                    }, 2000)    })

                  }},
                  ],})

    }
  }
  


  


  

  updateDelayRaceMin(value){
    this.setState({delayRaceMin:value, delayRaceMinTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/delayRaceMin']=this.state.delayRaceMin; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
  }

  updateViewCalcPrice(value){
    this.setState({viewCalcPrice:value, viewCalcPriceTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/viewCalcPrice']=this.state.viewCalcPrice; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
    if(this.state.hideMajoration==true && value==true) this.updateHideMajoration(false)

  }

  updateHideMajoration(value){
    this.setState({hideMajoration:value, hideMajorationTimer:timer.setTimeout("timer", () => {
      const fireInfo={}; fireInfo['/settings/hideMajoration']=this.state.hideMajoration; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
    if(this.state.viewCalcPrice==true && value==true) this.updateViewCalcPrice(false)

  }


  updateExtraEUMode = value => { 
    if(value==true) this.updateInfotoFB("addCommissionFixe",false)
      this.setState({extraEUMode:value, extraEUModeTimer:timer.setTimeout("extraEUMode", () => {
        const fireInfo={}; fireInfo['/settings/extraEUMode']=this.state.extraEUMode; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
 
  }

  updateOptionalOrder = value => { 
      this.setState({optionalOrder:value, optionalOrderTimer:timer.setTimeout("optionalOrder", () => {
        const fireInfo={}; fireInfo['/settings/optionalOrder']=this.state.optionalOrder; update(ref(getDatabase(firebase)), fireInfo)
      }, 2000)    })
 
  }

  

  updateViewAccurency = value => { 
      this.setState({viewAccurency:value, viewAccurencyTimer:timer.setTimeout("viewAccurency", () => {
        const fireInfo={}; fireInfo['/settings/viewAccurency']=this.state.viewAccurency; update(ref(getDatabase(firebase)), fireInfo)
      }, 2000)    })
 
  }

  updateViewAccurencyList = value => { 
      this.setState({viewAccurencyList:value, viewAccurencyListTimer:timer.setTimeout("viewAccurencyList", () => {
        const fireInfo={}; fireInfo['/settings/viewAccurencyList']=this.state.viewAccurencyList; update(ref(getDatabase(firebase)), fireInfo)
      }, 2000)    })
 
  }

  

  



  updateViewRealDriver= value => { 
    this.setState({viewRealDriver:value, viewRealDriverTimer:timer.setTimeout("viewRealDriver", () => {
      const fireInfo={}; fireInfo['/settings/viewRealDriver']=this.state.viewRealDriver; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
  }

  updateViewRealDriverForDriver= value => { 
    this.setState({viewRealDriverForDriver:value, viewRealDriverForDriverTimer:timer.setTimeout("viewRealDriverForDriver", () => {
      const fireInfo={}; fireInfo['/settings/viewRealDriverForDriver']=this.state.viewRealDriverForDriver; update(ref(getDatabase(firebase)), fireInfo)
    }, 2000)    })
  }

  

  

  



  updateHideHoursMajoration= value => {
      console.log("je passe par updateHideHoursMajoration de settings")
      const fireInfo={}; fireInfo['/settings/hideHoursMajoration']=this.state.hideHoursMajoration; update(ref(getDatabase(firebase)), fireInfo)
  }

  changeStripe= (select, value) => {
      var stripeInfo=(this.state.stripeInfo==undefined)?{}:(JSON.parse(JSON.stringify(this.state.stripeInfo))); 
      var stripeInfoSend=(this.state.stripeInfo==undefined)?{}:(JSON.parse(JSON.stringify(this.state.stripeInfo))); 
      
      if(select=="modeSelected"){
        stripeInfo.modeSelected=(value=="stripeOnMyAccount")?undefined:value; 
        stripeInfoSend.modeSelected=(value=="stripeOnMyAccount")?null:value; 
      }
      else if(select=="feeForDriver"){
        stripeInfo.feeForDriver=(stripeInfo.feeForDriver==true)?undefined:true;
        stripeInfoSend.feeForDriver=(stripeInfoSend.feeForDriver==true)?null:true;
      }
      
      this.setState({stripeInfo:stripeInfo})
      const fireInfo={}; fireInfo['/settings/stripeInfo']=stripeInfoSend; update(ref(getDatabase(firebase)), fireInfo)

      
  }


  



  updateMultiStop= value => {

          if(this.state.multiStop==false){
            this.alert.onAlert({title:strings.warning, subTitle:strings.multiStopWarning, buttons:[{text: strings.ok,onPress: () => { this.setState({multiStop:true, multiStopTimer:timer.setTimeout("multiStop", () => {
              const fireInfo={}; fireInfo['/settings/multiStop']=true; update(ref(getDatabase(firebase)), fireInfo)

            }, 2000)    }) }}] })
          }
          else this.setState({multiStop:false, multiStopTimer:timer.setTimeout("multiStop", () => {
            const fireInfo={}; fireInfo['/settings/multiStop']=null; update(ref(getDatabase(firebase)), fireInfo)
          }, 2000)    })
  }




    updateMessageBeforeBook= value => {
          if(value!=undefined){
              var i; 
              var objVal=Object.keys(value);
              var objFin={}; 
              for(i=0;i<objVal.length;i++){
                if(value[objVal[i]]!="" && value[objVal[i]]!=" ") objFin[objVal[i]]=value[objVal[i]]
              }
              if(Object.keys(objFin).length>0) {
                const fireInfo={}; fireInfo['/settings/messageBeforeBook']=objFin; update(ref(getDatabase(firebase)), fireInfo)
              }
              else {
                this.setState({messageBeforeBook:false,});
                const fireInfo={}; fireInfo['/settings/messageBeforeBook']=null; update(ref(getDatabase(firebase)), fireInfo)
              }


                
          }
          else {
            if(this.state.messageBeforeBook==false) value={}
            else value=false;
            this.setState({messageBeforeBook:value, messageBeforeBookTimer:timer.setTimeout("messageBeforeBook", () => {if(this.state.messageBeforeBook==false) {
              const fireInfo={}; fireInfo['/settings/messageBeforeBook']=null; update(ref(getDatabase(firebase)), fireInfo)
            }  }, 2000)    })
    
          }

    }


    updatePreblockAccountMessage= value => {
          if(value!=undefined){
              var i; 
              var objVal=Object.keys(value);
              var objFin={}; 
              for(i=0;i<objVal.length;i++){
                if(value[objVal[i]]!="" && value[objVal[i]]!=" ") objFin[objVal[i]]=value[objVal[i]]
              }
              const fireInfo={}; fireInfo['/settings/preblockAccountMessage']=objFin; update(ref(getDatabase(firebase)), fireInfo)
              
          }
    }


    


    updateMessageEstimation= value => {
          if(value!=undefined){
              var i; 
              var objVal=Object.keys(value);
              var objFin={}; 
              for(i=0;i<objVal.length;i++){
                if(value[objVal[i]]!="" && value[objVal[i]]!=" ") objFin[objVal[i]]=value[objVal[i]]
              }
              if(Object.keys(objFin).length>0) {
                const fireInfo={}; fireInfo['/settings/messageEstimation']=objFin; update(ref(getDatabase(firebase)), fireInfo)
              }
              else {
                this.setState({messageEstimation:false,});
                const fireInfo={}; fireInfo['/settings/messageEstimation']=null; update(ref(getDatabase(firebase)), fireInfo)
              }
                
          }
          else {
            if(this.state.messageEstimation==false) value={}
            else value=false;
            this.setState({messageEstimation:value, messageEstimationTimer:timer.setTimeout("messageEstimation", () => {if(this.state.messageEstimation==false) {const fireInfo={}; fireInfo['/settings/messageEstimation']=null; update(ref(getDatabase(firebase)), fireInfo)} }, 2000)    })
    
          }

    }

  updateAddCommissionFixeAmount= text => {
          text=text.toString().replace(",",'.'); 
          text.replace("€",'')
          this.setState({addCommissionFixeAmount:text}); 
          timer.setTimeout("timerAmountFixe", () => {
            var test=parseFloat(this.state.addCommissionFixeAmount).toString();if(test=="NaN") test="0";else test+="€"; this.setState({addCommissionFixeAmount:test})
            const fireInfo={}; fireInfo['/settings/addCommissionFixeAmount']=parseFloat(test); update(ref(getDatabase(firebase)), fireInfo)

          },3000)

  }

  updateAddCommissionVariableAmount= text => {
    text=text.toString().replace(",",'.'); 
    text.replace("%",'')
    if(text>100) test="100"; 
    this.setState({addCommissionVariableAmount:text}); 
    timer.setTimeout("timerAmountVariable", () => {
      var test=parseFloat(this.state.addCommissionVariableAmount); 
      if(test.toString()=="NaN") test="0%"; 
      else if(test>100) test="100%";
       else test+="%"; 
       this.setState({addCommissionVariableAmount:test})
       const fireInfo={}; fireInfo['/settings/addCommissionVariableAmount']=parseFloat(test);  update(ref(getDatabase(firebase)), fireInfo)

     },3000)  
             
  }


navigateTo(link, params){
  
  if(this.props.navigation!=undefined && this.props.navigation.navigate!=undefined) this.props.navigation.navigate(link,params); 
  else this.props.params.navigate(link,params)


}
  

   

  
  

  


  


  render() {

    
    return (
      <StyleContext.Consumer>{style => 
      <View style={{ backgroundColor: style.backgroundColor, flex: 1}}>

        {this.state.waitValue===false && <ScrollView style={{ flex: 1 }}>



           

            <TextPresentation style={{marginTop:30}} iconCircle={true} explicationChild={strings.tarifReducMoreInfo} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/pricetag_white.png'):require('../assets/pricetag_black.png')} text={strings.settingsPricing}  >
             
                 <TextPresentation style={{marginTop:5}} reduceTextSizeOnOff={true}  editable={false} onoff={(this.state.taxiMode!=undefined && this.state.taxiMode==true)} text={strings.activateTaxiMode} textMini={strings.activateTaxiModeMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("taxiMode",!this.state.taxiMode) }}/>
                 <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.defineMinimalPassager!=undefined && this.state.defineMinimalPassager==true)} text={strings.defineMinimalPassager} textMini={strings.defineMinimalPassagerMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("defineMinimalPassager",!this.state.defineMinimalPassager) }}/>
                 <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.pricingPrecise!=undefined && this.state.pricingPrecise==true)} text={strings.pricingPrecise} textMini={strings.pricingPreciseMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("pricingPrecise",!this.state.pricingPrecise) }}/>
                 <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.providerFixeCommission!=undefined && this.state.providerFixeCommission==true)} text={strings.providerFixeCommission} textMini={strings.providerFixeCommissionMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("providerFixeCommission",!this.state.providerFixeCommission) }}/>

                 <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.disabledPromoCode!=undefined && this.state.disabledPromoCode==true)} text={strings.disabledPromoCode}  onOnOff={()=>{ this.updateInfotoFB("disabledPromoCode",!this.state.disabledPromoCode) }}/>

                 {this.state.disabledPromoCode!=true && <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/promo_black.png'):require('../assets/promo_white.png')} iconCircle={true} editable={false}  text={strings.promoCode} onNext={() => {this.navigateTo('PricePlace',{view:"onlyPromoCode", extraEUMode:this.state.extraEUMode});}} />}

                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/currencyConv_dark.png'):require('../assets/currencyConv_light.png')} iconCircle={true} editable={false}  text={strings.international} onNext={() => {this.navigateTo('CurrencyGestion',{updateExtraEUMode:this.updateExtraEUMode, extraEUMode:this.state.extraEUMode, viewAccurency:this.state.viewAccurency, updateViewAccurency:this.updateViewAccurency, viewAccurencyList:this.state.viewAccurencyList, updateViewAccurencyList:this.updateViewAccurencyList, enableTimezone:this.state.enableTimezone, updateEnableTimezone:this.updateEnableTimezone});}} />

                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/route_dark.png'):require('../assets/route_light.png')} iconCircle={true} editable={false}  text={strings.pricingGestion} onNext={() => {this.navigateTo('PricePlace',{view:"onlyPrice",providerFixeCommission:this.state.providerFixeCommission, co2onBill:this.state.co2onBill, defineMinimalPassager:this.state.defineMinimalPassager, taxiMode:this.state.taxiMode, vipMode:this.state.vipMode, extraEUMode:this.state.extraEUMode, luggageOption:this.state.luggageOption, updateHideHoursMajoration:this.updateHideHoursMajoration,hideHoursMajoration:this.state.hideHoursMajoration}); }} />
                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/delay_black.png'):require('../assets/delay_white.png')} iconCircle={true} editable={false}  text={strings.globalMajoration} onNext={() => {this.navigateTo('Majoration', {updateHideHoursMajoration:this.updateHideHoursMajoration,hideHoursMajoration:this.state.hideHoursMajoration,extraEUMode:this.state.extraEUMode,co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission,defineMinimalPassager:this.state.defineMinimalPassager, majorationType:"global",  });}} />
                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/clock_black.png'):require('../assets/clock_white.png')} iconCircle={true} editable={false}  text={strings.provision} onNext={() => {this.navigateTo('PriceProvision',{co2onBill:this.state.co2onBill,providerFixeCommission:this.state.providerFixeCommission,defineMinimalPassager:this.state.defineMinimalPassager, extraEUMode:this.state.extraEUMode,vipMode:this.state.vipMode,luggageOption:this.state.luggageOption});}} />

              </TextPresentation>
            

            <TextPresentation style={{marginTop:30}} iconCircle={true} explicationChild={strings.settingsBookMoreInfo} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/calendar_white.png'):require('../assets/calendar_black.png')} text={strings.settingsBook} maxiMode={"partial"} >
                  
                  <TextPresentation reduceTextSizeOnOff={true}  style={{marginTop:5}} selectedQuantity={this.state.delayRaceMin} selectedQuantityText={"min"} onChangeQuantity={(quantity)=>{  var newVal=this.state.delayRaceMin;newVal+=((quantity==1)?5:-5); if(newVal<0) newVal=0; this.updateDelayRaceMin(newVal) }}  textMini={strings.delayReservationMinutesMoreInfo} text={strings.delayReservationMinutes } editable={false}   />
                  
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.delayRaceMax==false)?false:true} text={strings.delayRaceMax} textMini={strings.delayRaceMaxMoreInfo} onOffText={((this.state.delayRaceMax!=false)?(this.state.delayRaceMax):undefined)} onOffTextMini={((this.state.delayRaceMax!=false)?(strings.days.toLowerCase()):undefined)} onOnOff={()=>{ this.updateDelayRaceMax() }}/>

                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.userMaxRace==false)?false:true} text={strings.userMaxRace} textMini={strings.userMaxRaceMoreInfo} onOffText={((this.state.userMaxRace!=false)?(this.state.userMaxRace):undefined)} onOffTextMini={((this.state.userMaxRace!=false)?(strings.races.toLowerCase()):undefined)} onOnOff={()=>{ this.updateUserMaxRace() }}/>

                  

                  <TextPresentation reduceTextSizeOnOff={true}  editable={false} onoff={(this.state.immediateMode!=undefined && this.state.immediateMode==true)} text={strings.enabledBookNow} textMini={strings.enabledBookNowMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("immediateMode",!this.state.immediateMode) }}/>
                      {(this.state.immediateMode==true) && <TextPresentation reduceTextSizeOnOff={true} editable={false} style={{marginLeft:"5%", marginRight:"5%"}} onoff={(this.state.takingTraffic!=undefined && this.state.takingTraffic==true)} text={strings.takingTraffic} textMini={strings.takingTrafficMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("takingTraffic",!this.state.takingTraffic) }}/>}
                      {(this.state.immediateMode==true) && <TextPresentation reduceTextSizeOnOff={true} editable={false} style={{marginLeft:"5%", marginRight:"5%"}} onoff={(this.state.immediateModeDefaut!=undefined && this.state.immediateModeDefaut==true)} text={strings.immediateModeDefaut} textMini={strings.immediateModeDefautMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("immediateModeDefaut",!this.state.immediateModeDefaut) }}/>}
                      {(this.state.immediateMode==true && this.state.immediateModeDefaut==true) && <TextPresentation reduceTextSizeOnOff={true} editable={false} style={{marginLeft:"5%", marginRight:"5%"}} onoff={(this.state.onlyImmediate!=undefined && this.state.onlyImmediate==true)} text={strings.onlyImmediate} textMini={strings.onlyImmediateMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("onlyImmediate",!this.state.onlyImmediate) }}/>}
               
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} style={{marginTop:(this.state.immediateMode==true)?5:undefined}} onoff={(this.state.multiStop!=undefined && this.state.multiStop==true)} text={strings.multiStop} textMini={strings.multiStopMoreInfo}  onOnOff={()=>{ this.updateMultiStop(!this.state.multiStop)  }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.purposeReturn!=undefined && this.state.purposeReturn==true)} text={strings.purposeReturn} textMini={strings.purposeReturnMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("purposeReturn",!this.state.purposeReturn) }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.removeRace5minutes!=undefined && this.state.removeRace5minutes==true)} text={strings.removeRace5minutes} textMini={strings.removeRace5minutesMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("removeRace5minutes",!this.state.removeRace5minutes) }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.forceManualDispatch!=undefined && this.state.forceManualDispatch==true)} text={strings.forceManualDispatch} textMini={strings.forceManualDispatchExplication}  onOnOff={()=>{ this.updateInfotoFB("forceManualDispatch",!this.state.forceManualDispatch) }}/>
                  
                  <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/rating_black.png'):require('../assets/rating_white.png')} iconCircle={true} editable={false} style={{marginTop:5}} text={strings.customerReviews} onNext={() => {this.navigateTo('Help',{driver:true, customerReviews:true, activateGoogleMyBusiness:this.state.activateGoogleMyBusiness, updateActivateGoogleMyBusiness:this.updateActivateGoogleMyBusiness, googleMyBusinessText:this.state.googleMyBusinessText, updateGoogleMyBusinessText:this.updateGoogleMyBusinessText, activateComment:this.state.activateComment,activateDriverNotation:this.state.activateDriverNotation,activateCustomerNotation:this.state.activateCustomerNotation,updateActivateComment:this.updateActivateComment,updateActivateDriverNotation:this.updateActivateDriverNotation,updateActivateCustomerNotation:this.updateActivateCustomerNotation,});}} />


           </TextPresentation>



           <TextPresentation style={{marginTop:30}} iconCircle={true} explicationChild={strings.settingsDriversMoreInfo} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/partner_light.png'):require('../assets/partner_dark.png')} text={strings.settingsDrivers}  >
             

                    {this.state.extraEUMode!=true && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.addCommissionFixe!=undefined && this.state.addCommissionFixe==true)} text={strings.addCommissionFixe} textMini={strings.addCommissionFixeMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("addCommissionFixe",!this.state.addCommissionFixe) }}/>}
                   
                     {this.state.addCommissionFixe==true && <TextPresentation placeholder={"2€"} textExplication={strings.addCommissionFixeAmount} text={this.state.addCommissionFixeAmount} keyboardType={"numeric"} onChangeText={text => {  this.updateAddCommissionFixeAmount(text)  }}   />}



                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.addCommissionVariable!=undefined && this.state.addCommissionVariable==true)} text={strings.addCommissionVariable} textMini={strings.addCommissionVariableMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("addCommissionVariable",!this.state.addCommissionVariable) }}/>
                    
                    {this.state.addCommissionVariable==true && <TextPresentation  placeholder={"1%"} textExplication={strings.addCommissionVariableAmount} text={this.state.addCommissionVariableAmount} keyboardType={"numeric"} onChangeText={text => {this.updateAddCommissionVariableAmount(text);  }}   />}


                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.priceNetForDriver!=undefined && this.state.priceNetForDriver==true)} text={strings.priceNetForDriver} textMini={strings.priceNetForDriverMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("priceNetForDriver",!this.state.priceNetForDriver) }}/>
                    {this.state.priceNetForDriver===true && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.hidePriceRaceForDriver!=undefined && this.state.hidePriceRaceForDriver==true)} text={strings.hidePriceRaceForDriver} textMini={strings.hidePriceRaceForDriverMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("hidePriceRaceForDriver",!this.state.hidePriceRaceForDriver) }}/>}
                    

                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.viewMajorationDynamicDriver!=undefined && this.state.viewMajorationDynamicDriver==true)} text={strings.viewMajorationDynamicDriver} textMini={strings.viewMajorationDynamicDriverMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("viewMajorationDynamicDriver",!this.state.viewMajorationDynamicDriver) }}/>
                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.enableOrderDriver!=undefined && this.state.enableOrderDriver==true)} text={strings.enableOrderDriver} textMini={strings.enableOrderDriverMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("enableOrderDriver",!this.state.enableOrderDriver) }}/>
                    {this.state.enableOrderDriver==true &&  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.longRank!=undefined && this.state.longRank==true)} text={strings.longRank} textMini={strings.longRankMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("longRank",!this.state.longRank) }}/>}
                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.unavailableIfRace!=undefined && this.state.unavailableIfRace==true)} text={strings.unavailableIfRace} textMini={strings.unavailableIfRaceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("unavailableIfRace",!this.state.unavailableIfRace) }}/>
                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.basicDriverAddRace!=undefined && this.state.basicDriverAddRace==true)} text={strings.basicDriverAddRace} textMini={strings.basicDriverAddRaceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("basicDriverAddRace",!this.state.basicDriverAddRace) }}/>
                    <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.viewPartnerForDrivers!=undefined && this.state.viewPartnerForDrivers==true)} text={strings.viewPartnerForDrivers} textMini={strings.viewPartnerForDriversMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("viewPartnerForDrivers",!this.state.viewPartnerForDrivers) }}/>
                    {this.state.withKarhoo==true && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.viewKarhooForDrivers!=undefined && this.state.viewKarhooForDrivers==true)} text={strings.viewKarhooForDrivers} textMini={strings.viewKarhooForDriversMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("viewKarhooForDrivers",!this.state.viewKarhooForDrivers) }}/>}
                    <TextPresentation reduceTextSizeOnOff={true}  editable={false} onoff={(this.state.defineColorForCustomer!=undefined && this.state.defineColorForCustomer==true)} text={strings.defineColorForCustomer} textMini={strings.defineColorForCustomerMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("defineColorForCustomer",!this.state.defineColorForCustomer) }}/>
                    <TextPresentation reduceTextSizeOnOff={true}  editable={false} onoff={(this.state.confirmAttributedRace!=undefined && this.state.confirmAttributedRace==true)} text={strings.confirmAttributedRace} textMini={strings.confirmAttributedRaceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("confirmAttributedRace",!this.state.confirmAttributedRace) }}/>

                    
            </TextPresentation>

            
            
            {(info.customerChoicePartner==true || info.disableFavoriteDriverForCustomer==false || info.disableFavoriteDriverForAdmin==false) && <TextPresentation style={{marginTop:30}} iconCircle={true} explicationChild={strings.favoriteDriverMoreInfo} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/star1_dark.png'):require('../assets/star1_light.png')} text={strings.favoriteDriver}  >
           

                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.driverPricing!=undefined && this.state.driverPricing==true)} text={strings.driverPricing} textMini={strings.driverPricingMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("driverPricing",!this.state.driverPricing) }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.removeFavoriteDriver30!=undefined && this.state.removeFavoriteDriver30==true)} text={strings.removeFavoriteDriver30} textMini={strings.removeFavoriteDriver30MoreInfo}  onOnOff={()=>{ this.updateInfotoFB("removeFavoriteDriver30",!this.state.removeFavoriteDriver30) }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.contactFavoriteDriver!=undefined && this.state.contactFavoriteDriver==true)} text={strings.contactFavoriteDriver} textMini={strings.contactFavoriteDriverMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("contactFavoriteDriver",!this.state.contactFavoriteDriver) }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.autoriseDriverSeeRace!=undefined && this.state.autoriseDriverSeeRace==true)} text={strings.autoriseDriverSeeRace} textMini={strings.autoriseDriverSeeRaceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("autoriseDriverSeeRace",!this.state.autoriseDriverSeeRace) }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.partnerPartialAccessPhoneBook!=undefined && this.state.partnerPartialAccessPhoneBook==true)} text={strings.partnerPartialAccessPhoneBook} textMini={strings.partnerPartialAccessPhoneBookMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("partnerPartialAccessPhoneBook",!this.state.partnerPartialAccessPhoneBook) }}/>
                  {(this.state.partnerPartialAccessPhoneBook==true)  &&<TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.autorizeChangeDriverFavoritePrice!=undefined && this.state.autorizeChangeDriverFavoritePrice==true)} text={strings.autorizeChangeDriverFavoritePrice} textMini={strings.autorizeChangeDriverFavoritePriceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("autorizeChangeDriverFavoritePrice",!this.state.autorizeChangeDriverFavoritePrice) }}/>}

            </TextPresentation>}
            


            <TextPresentation style={{marginTop:30}} iconCircle={true} explicationChild={strings.customInterfaceMoreInfo} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/paint_brush_white.png'):require('../assets/paint_brush_black.png')} text={strings.settingsView}  >

             
                <Text style={{marginTop:10,marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "justify",}}>{strings.poi}</Text>
                <Text style={{marginLeft:"5%", marginRight:"5%",marginBottom:5, color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",}}>{strings.poiMoreInfo}</Text>
                      <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/driver_info_dark.png'):require('../assets/driver_info_light.png')} iconCircle={true} editable={false}  text={strings.gestionPOI} onNext={() => {this.navigateTo('Favorite',{view:"admin", pickUpLocation:this.state.pickUpLocation});}} />
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.seePOIMap!=undefined && this.state.seePOIMap==true)} text={strings.seePOIMap} textMini={strings.seePOIMapMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("seePOIMap",!this.state.seePOIMap) }}/>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.pickUpLocation!=undefined && this.state.pickUpLocation==true)} text={strings.pickUpLocation} textMini={strings.pickUpLocationMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("pickUpLocation",!this.state.pickUpLocation) }}/>


                <Text style={{marginTop:17,marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "justify",}}>{strings.finaliseOrder}</Text>
                <Text style={{marginLeft:"5%", marginRight:"5%",marginBottom:5, color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",}}>{strings.finaliseOrderMoreInfo}</Text>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.enableFlightTrainVerif!=undefined && this.state.enableFlightTrainVerif==true)} text={strings.enableFlightTrainVerif} textMini={strings.enableFlightTrainVerifMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("enableFlightTrainVerif",!this.state.enableFlightTrainVerif) }}/>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.addClientBook!=undefined && this.state.addClientBook==true)} text={strings.addClientBook} textMini={strings.addClientBookMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("addClientBook",!this.state.addClientBook) }}/>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.customerMessageFreeCancel==false)?false:true} text={strings.customerMessageFreeCancel} onOffText={((this.state.customerMessageFreeCancel!=false)?(this.state.customerMessageFreeCancel):undefined)} onOffTextMini={((this.state.customerMessageFreeCancel!=false)?(strings.minutes.toLowerCase()):undefined)} textMini={strings.customerMessageFreeCancelMoreInfo}  onOnOff={()=>{ this.updateCustomerMessageFreeCancel() }}/>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.hideMajoration!=undefined && this.state.hideMajoration==true)} text={strings.hideMajoration} textMini={strings.hideMajorationMoreInfo}  onOnOff={()=>{ this.updateHideMajoration(!this.state.hideMajoration)  }}/>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.hideParticularRqs!=undefined && this.state.hideParticularRqs==true)} text={strings.hideParticularRqs} textMini={strings.hideParticularRqsMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("hideParticularRqs",!this.state.hideParticularRqs) }}/>



                <Text style={{marginTop:20,marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "justify",}}>{strings.mainScreenCustom}</Text>
                <Text style={{marginLeft:"5%", marginRight:"5%",marginBottom:5, color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",}}>{strings.mainScreenCustomMoreInfo}</Text>
                       
                      <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/megaphone_black.png'):require('../assets/megaphone_white.png')} iconCircle={true} editable={false}  text={strings.ads} onNext={() => {this.navigateTo('Ads',{params:""});}} />
                      <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/carFront_black.png'):require('../assets/carFront_white.png')} iconCircle={true} editable={false}  text={strings.viewCar} onNext={() => {this.navigateTo('OptionGestion',{picturesCar:this.state.picturesCar, updatePicturesCar:this.updatePicturesCar,viewCarMode:true, updateColorDriver:this.updateColorDriver,updateDriverPositionPrecise:this.updateDriverPositionPrecise, updateHideOtherCarIfRaceInProgress:this.updateHideOtherCarIfRaceInProgress,driverPositionPrecise:this.state.driverPositionPrecise, hideOtherCarIfRaceInProgress:this.state.hideOtherCarIfRaceInProgress,carDriverColor:this.state.carDriverColor, driverApproachingColor:this.state.driverApproachingColor, vipMode:this.state.vipMode, viewRealDriver:this.state.viewRealDriver, updateViewRealDriver:this.updateViewRealDriver, viewRealDriverForDriver:this.state.viewRealDriverForDriver,updateViewRealDriverForDriver:this.updateViewRealDriverForDriver,  });}} />
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.viewTrajetCustomerDriver!=undefined && this.state.viewTrajetCustomerDriver==true)} text={strings.viewTrajetCustomerDriver} textMini={strings.viewTrajetCustomerDriverMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("viewTrajetCustomerDriver",!this.state.viewTrajetCustomerDriver) }}/>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.trajetBird!=undefined && this.state.trajetBird==true)} text={strings.trajetBird} textMini={strings.trajetBirdMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("trajetBird",!this.state.trajetBird) }}/>


                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.viewCalcPrice!=undefined && this.state.viewCalcPrice==true)} text={strings.viewCalcPrice} textMini={strings.viewCalcPriceMoreInfo}  onOnOff={()=>{ this.updateViewCalcPrice(!this.state.viewCalcPrice) }}/>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.disableAutomaticMapFrom!=undefined && this.state.disableAutomaticMapFrom==true)} text={strings.disableAutomaticMapFrom} textMini={strings.disableAutomaticMapFromMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("disableAutomaticMapFrom",!this.state.disableAutomaticMapFrom) }}/>
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.enableRealPosition!=undefined && this.state.enableRealPosition==true)} text={strings.enableRealPosition} textMini={strings.enableRealPositionMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("enableRealPosition",!this.state.enableRealPosition) }}/>

                
                <Text style={{marginTop:20,marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "justify",}}>{strings.cancelView}</Text>
                <Text style={{marginLeft:"5%", marginRight:"5%",marginBottom:5, color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",}}>{strings.cancelViewMoreInfo}</Text>
                    
                      <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.disableCancelButton!=undefined && this.state.disableCancelButton==true)} text={strings.disableCancelButton} textMini={strings.disableCancelButtonMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("disableCancelButton",!this.state.disableCancelButton) }}/>
                      {this.state.disableCancelButton==false && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.customerAutomaticCancel==false)?false:true} text={strings.customerAutomaticCancel} onOffText={((this.state.customerAutomaticCancel!=false)?(this.state.customerAutomaticCancel):undefined)} onOffTextMini={((this.state.customerAutomaticCancel!=false)?(strings.minutes.toLowerCase()):undefined)} textMini={strings.customerAutomaticCancelMoreInfo}  onOnOff={()=>{ this.updateCustomerAutomaticCancel(); }}/>}
                      {this.state.disableCancelButton==false && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.addButtonCancelMain!=undefined && this.state.addButtonCancelMain==true)} text={strings.addButtonCancelMain} textMini={strings.addButtonCancelMainMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("addButtonCancelMain",!this.state.addButtonCancelMain) }}/>}


                  <Text style={{marginTop:20,marginBottom:5,marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', color: style.textInputColor,fontSize: style.textInputFontSize,textAlign: "justify",}}>{strings.others}</Text>
                   
                   <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.disabledOldRace!=undefined && this.state.disabledOldRace==true)} text={strings.disabledOldRace} textMini={strings.disabledOldRaceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("disabledOldRace",!this.state.disabledOldRace) }}/>
                   <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.enableDriverNameInRace!=undefined && this.state.enableDriverNameInRace==true)} text={strings.enableDriverNameInRace} textMini={strings.enableDriverNameInRaceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("enableDriverNameInRace",!this.state.enableDriverNameInRace) }}/>
                   <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.hidePhoneApp!=undefined && this.state.hidePhoneApp==true)} text={strings.hidePhoneApp} textMini={strings.hidePhoneAppMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("hidePhoneApp",!this.state.hidePhoneApp) }}/>


            </TextPresentation>







            <TextPresentation style={{marginTop:30}} iconCircle={true}  iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/text_white.png'):require('../assets/text_black.png')} text={strings.text}  >

                

                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/help_black.png'):require('../assets/help_white.png')} iconCircle={true} editable={false}  text={strings.helpGestion} onNext={() => {this.navigateTo('Help',{driver:true});}} />
                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/driver_message_dark.png'):require('../assets/driver_message_light.png')} iconCircle={true} editable={false}  text={strings.chatPrefill} onNext={() => {this.navigateTo('Chat',{prefillMode:true, prefillAdmin:true }); }} />
                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/driver_message_dark.png'):require('../assets/driver_message_light.png')} iconCircle={true} editable={false}  text={strings.smsPrefill} onNext={() => {this.navigateTo('Help',{driver:true, smsPrefill:true,  });}} />



                 <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.messageBeforeBook==false)?false:true} text={strings.messageBeforeBook} textMini={strings.messageBeforeBookMoreInfo}  onOnOff={()=>{ this.updateMessageBeforeBook(); }}/>

                 {this.state.messageBeforeBook!=false && <View>
                        {Object.keys(languageList).map((item,languageListI)  => (
                             <TextPresentation icon={languageList[item].flag }  text={(this.state.messageBeforeBook[languageList[item].id]!=undefined)?this.state.messageBeforeBook[languageList[item].id]:""} textExplication={languageList[item].name}   placeholder={"Texte"} onChangeText={(text)=>{ var el=this.state.messageBeforeBook; el[languageList[item].id]=text;     this.setState({messageBeforeBook:el})   }}/>
                          ))}

                          <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:20, flexDirection:"row"}}>
                              <MavButton text={strings.validate} style={{marginLeft:5,}} onlyButton={true} onPress={()=>{ this.updateMessageBeforeBook(this.state.messageBeforeBook)  }}/>
                          </View>



                    </View>}


                <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.messageEstimation==false)?false:true} text={strings.messageEstimation} textMini={strings.messageEstimationMoreInfo}  onOnOff={()=>{ this.updateMessageEstimation(); }}/>

                {this.state.messageEstimation!=false && <View >

                    {Object.keys(languageList).map((item,languageListI) => (
                         <TextPresentation icon={languageList[item].flag } text={(this.state.messageEstimation[languageList[item].id]!=undefined)?this.state.messageEstimation[languageList[item].id]:""} textExplication={languageList[item].name}  placeholder={"Texte"} onChangeText={(text)=>{ var el=this.state.messageEstimation; el[languageList[item].id]=text;     this.setState({messageEstimation:el})   }}/>
                      ))}
                      <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:10, flexDirection:"row"}}>
                          <MavButton text={strings.validate} style={{marginLeft:5}} onlyButton={true} onPress={()=>{ this.updateMessageEstimation(this.state.messageEstimation)  }}/>
                      </View>

                  </View>}

            </TextPresentation>




          <TextPresentation style={{marginTop:30}} iconCircle={true} explicationChild={strings.optionMoreInfo} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/luggage_white.png'):require('../assets/luggage_black.png')} text={strings.options}  >

              <TextPresentation  reduceTextSizeOnOff={true} editable={false} onoff={(this.state.childSeats!=undefined && this.state.childSeats==true)} text={strings.acceptChildSeats} onOnOff={()=>{ this.updateInfotoFB("childSeats",!this.state.childSeats) }}/>
              <TextPresentation  reduceTextSizeOnOff={true} editable={false} onoff={(this.state.boosterSeats!=undefined && this.state.boosterSeats==true)} text={strings.acceptBoosterSeats} onOnOff={()=>{ this.updateInfotoFB("boosterSeats",!this.state.boosterSeats) }}/>
              <TextPresentation  reduceTextSizeOnOff={true} editable={false} onoff={(this.state.wheelchair!=undefined && this.state.wheelchair==true)} text={strings.acceptWheelchair} onOnOff={()=>{ this.updateInfotoFB("wheelchair",!this.state.wheelchair) }}/>
              <TextPresentation  reduceTextSizeOnOff={true} editable={false} onoff={(this.state.animal!=undefined && this.state.animal==true)} text={strings.acceptAnimal} onOnOff={()=>{ this.updateInfotoFB("animal",!this.state.animal) }}/>
              <TextPresentation  reduceTextSizeOnOff={true} editable={false} onoff={(this.state.luggageOption!=undefined && this.state.luggageOption==true)} text={strings.addBaggageItem} onOnOff={()=>{ this.updateInfotoFB("luggageOption",!this.state.luggageOption) }}/>
              <TextPresentation  reduceTextSizeOnOff={true} editable={false} onoff={(this.state.roomHotel!=undefined && this.state.roomHotel==true)} text={strings.roomHotel} textMini={strings.roomHotelMoreInfo} onOnOff={()=>{ this.updateInfotoFB("roomHotel",!this.state.roomHotel) }}/>

              <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/plus_black.png'):require('../assets/plus_white.png')} iconCircle={true} editable={false}  text={strings.proposeOtherOptions} onNext={() => {this.navigateTo('OptionGestion',{updateOptionalOrder:this.updateOptionalOrder, optionalOrder:this.state.optionalOrder,vipMode:this.state.vipMode, extraEUMode:this.state.extraEUMode });}} />
                    
          </TextPresentation>


            {(info.stripePublicKey!=undefined && info.countryCode=="fr") && <TextPresentation style={{marginTop:30}} iconCircle={true} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/stripe_white.png'):require('../assets/stripe_black.png')} ref={stripeVM => {this.stripeVM = stripeVM;}} text={"Stripe"}  >

                <TextPresentation icon={(info.stripeKeyPartial!="sk_test")?require('../assets/check_mark.png'):require('../assets/error_mark.png')} editable={false}  style={{marginTop:5}} text={(info.stripeKeyPartial!="sk_test")?strings.stripeCorrectyConf:strings.stripeNotConf} textMini={(info.stripeKeyPartial!="sk_test")?undefined:strings.contactMAV}  />
                

                <Text style={{marginTop:10,marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "justify",}}>{strings.subscription}</Text>
                <Text style={{marginBottom:10,marginLeft:"5%", marginRight:"5%",color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",}}>{strings.subscriptionStripeMoreInfo}</Text>
               

                {false && <TextPresentation icon={(style.mode=="dark")?require('../assets/subscription_white.png'):require('../assets/subscription_black.png')} editable={false} style={{marginTop:5}} text={strings.manageSubscription} onNext={() => {this.navigateTo('Payment',{subscriptionAdmin:true });}}   />}
                

                <Text style={{marginTop:10,marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "justify",}}>{strings.paymentTransfer}</Text>               
                <Text style={{marginBottom:10,marginLeft:"5%", marginRight:"5%",color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",}}>{strings.stripeConnectMoreInfo}</Text>
               
               
                {(this.state.stripeInfo!=undefined && (this.state.stripeInfo.modeSelected=="stripeManualOnRevenus" || this.state.stripeInfo.modeSelected=="stripeCBAuto")) && <Text style={{marginTop:10, marginLeft:"5%", marginRight:"5%",fontWeight: 'bold',marginBottom:10, color: style.colorNotification,fontSize: style.textInputFontSize*0.8,textAlign: "justify"}}>{strings.stripeWarning + ((this.state.stripeInfo.modeSelected=="stripeCBAuto")?(" "+strings.paymentAutoErrorWarning):"")}</Text>}

                <TextPresentation reduceTextSizeOnOff={true} editable={false} radio={(this.state.stripeInfo==undefined || this.state.stripeInfo.modeSelected==undefined)} text={"1. "+strings.stripeOnMyAccount} textMini={strings.stripeOnMyAccountMoreInfo}  onRadio={()=>{ this.changeStripe("modeSelected", "stripeOnMyAccount") }}/>
                <TextPresentation reduceTextSizeOnOff={true} editable={false} radio={(this.state.stripeInfo!=undefined && this.state.stripeInfo.modeSelected=="stripeManualOnRevenus")} text={"2. "+strings.stripeManualOnRevenus} textMini={strings.stripeManualOnRevenusMoreInfo} onRadio={()=>{ this.changeStripe("modeSelected", "stripeManualOnRevenus") }}/>
                <TextPresentation reduceTextSizeOnOff={true} editable={false} radio={(this.state.stripeInfo!=undefined && this.state.stripeInfo.modeSelected=="stripeCBAuto")} text={"3. "+strings.stripeCBAuto} textMini={strings.stripeCBAutoMoreInfo} onRadio={()=>{ this.changeStripe("modeSelected", "stripeCBAuto") }}/>
               

                {(this.state.stripeInfo!=undefined && this.state.stripeInfo.modeSelected=="stripeCBAuto") && <Text style={{marginLeft:"5%", marginTop:10, marginRight:"5%",color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify"}}>{strings.stripeCBAutoPrice}</Text>}
               
                {(this.state.stripeInfo!=undefined && this.state.stripeInfo.modeSelected=="stripeCBAuto") && <TextPresentation onoff={(this.state.stripeInfo!=undefined && this.state.stripeInfo.feeForDriver==true)} textExplication={strings.transferFeeStripeDriver}  onOnOff={()=>{ this.changeStripe("feeForDriver") }}/>}
               

              
            </TextPresentation>}




           
            <TextPresentation style={{marginTop:30}} iconCircle={true} explicationChild={strings.rappelMoreInfo} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/notification_white.png'):require('../assets/notification_black.png')} text={strings.reminder}  >

                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.reminder.race==true)?true:false} text={strings.raceReminder} textMini={strings.raceReminderMoreInfo}  onOnOff={()=>{ this.reminder("race",!this.state.reminder.race)  }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.reminder.notStarted20==true)?true:false} text={strings.raceNotStartedReminder} textMini={strings.raceNotStartedReminderMoreInfo}  onOnOff={()=>{ this.reminder("notStarted20",!this.state.reminder.notStarted20)  }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.reminder.raceAdmin15==true)?true:false} text={strings.raceAdmin15} textMini={strings.raceAdmin15MoreInfo}  onOnOff={()=>{ this.reminder("raceAdmin15",!this.state.reminder.raceAdmin15)  }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.reminder.tomorrow==true)?true:false} text={strings.raceReminderTomorrow} textMini={strings.raceReminderTomorrowMoreInfo}  onOnOff={()=>{ this.reminder("tomorrow",!this.state.reminder.tomorrow)  }}/>
                  <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.reminder.arrivate==true)?true:false} text={strings.raceReminderArrivate} textMini={strings.raceReminderArrivateMoreInfo}  onOnOff={()=>{ this.reminder("arrivate",!this.state.reminder.arrivate)  }}/>

            </TextPresentation>



            <TextPresentation style={{marginTop:30}} iconCircle={true} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/user_manager_white.png'):require('../assets/user_manager_black.png')} text={strings.admin}  >

                  

                  {info.enableOnOffButton==true && <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.driverStatus!=undefined && this.state.driverStatus==true)} text={strings.driverStatus} textMini={strings.driverStatusMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("driverStatus",!this.state.driverStatus) }}/>}

                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.modifRaceAdmin!=undefined && this.state.modifRaceAdmin==true)} text={strings.modifRaceAdmin} textMini={strings.modifRaceAdminMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("modifRaceAdmin",!this.state.modifRaceAdmin) }}/>
                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.sendMailToAdmin!=undefined && this.state.sendMailToAdmin==true)} text={strings.sendMailToAdmin} textMini={strings.sendMailToAdminMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("sendMailToAdmin",!this.state.sendMailToAdmin) }}/>
                  {info.paymentCardApp===true && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.sendMessageIfPaiementError!=undefined && this.state.sendMessageIfPaiementError==true)} text={strings.sendMessageIfPaiementError} textMini={strings.sendMessageIfPaiementErrorMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("sendMessageIfPaiementError",!this.state.sendMessageIfPaiementError) }}/>}
                  {(info.stripePublicKey!=undefined) && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.viewWaitingPaymentRace!=undefined && this.state.viewWaitingPaymentRace==true)} text={strings.viewWaitingPaymentRace} textMini={strings.viewWaitingPaymentRaceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("viewWaitingPaymentRace",!this.state.viewWaitingPaymentRace) }}/>}
                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.raceStatusAdmin!=undefined && this.state.raceStatusAdmin==true)} text={strings.raceStatusAdmin} textMini={strings.raceStatusAdminMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("raceStatusAdmin",!this.state.raceStatusAdmin) }}/>
                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.orderFor!=undefined && this.state.orderFor==true)} text={strings.orderFor} textMini={strings.orderForMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("orderFor",!this.state.orderFor) }}/>

            </TextPresentation>


            <TextPresentation style={{marginTop:30}} iconCircle={true} explicationChild={strings.interconnexionMoreInfo} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/api_white.png'):require('../assets/api_black.png')} text={strings.interconnexion}  >

                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/logo_karhoo_black.png'):require('../assets/logo_karhoo_white.png')} iconCircle={true} editable={false}  text={"Karhoo"} onNext={() => {this.navigateTo('API',{modeView:"karhoo", withKarhoo:this.state.withKarhoo, taxiMode:this.state.taxiMode, extraEUMode:this.state.extraEUMode });}} />
                 {true && <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/letaxi_black.png'):require('../assets/letaxi_white.png')} iconCircle={true} editable={false}  text={"Le.taxi"} onNext={() => {this.navigateTo('API',{modeView:"letaxi", withLeTaxi:this.state.withLeTaxi, taxiMode:this.state.taxiMode });}} />}

                 <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/api_black.png'):require('../assets/api_white.png')} iconCircle={true} editable={false}  text={"API"} onNext={() => {this.navigateTo('API',{modeView:"normal", })}} />

             </TextPresentation>

            
            <TextPresentation style={{marginTop:30}} iconCircle={true} iconCircleColor={style.backgroundColorContrast} icon={(style.mode=="dark")?require('../assets/etc_white.png'):require('../assets/etc_black.png')} text={strings.settingsOther}  >

           
                 
                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.co2onBill!=undefined && this.state.co2onBill==true)} text={strings.co2onBill} textMini={strings.co2onBillMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("co2onBill",!this.state.co2onBill) }}/>
                  {(this.state.co2onBill==true) && <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.co2onOrder!=undefined && this.state.co2onOrder==true)} text={strings.co2onOrder} textMini={strings.co2onOrderMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("co2onOrder",!this.state.co2onOrder) }}/>}
                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.billWithoutDetail!=undefined && this.state.billWithoutDetail==true)} text={strings.billWithoutDetail} textMini={strings.billWithoutDetailMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("billWithoutDetail",!this.state.billWithoutDetail) }}/>
                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.saveRemovedRace!=undefined && this.state.saveRemovedRace==true)} text={strings.saveRemovedRace} textMini={strings.saveRemovedRaceMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("saveRemovedRace",!this.state.saveRemovedRace) }}/>
                  {false && <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.qrCustomerVerif!=undefined && this.state.qrCustomerVerif==true)} text={strings.qrCustomerVerif} textMini={strings.qrCustomerVerifMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("qrCustomerVerif",!this.state.qrCustomerVerif) }}/>}

                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.vipMode!=undefined && this.state.vipMode==true)} text={strings.vipMode} textMini={strings.vipModeMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("vipMode",!this.state.vipMode) }}/>
                  <TextPresentation editable={false} reduceTextSizeOnOff={true} onoff={(this.state.preblockAccount!=undefined && this.state.preblockAccount==true)} text={strings.preblockAccount} textMini={strings.preblockAccountMoreInfo}  onOnOff={()=>{ this.updateInfotoFB("preblockAccount",!this.state.preblockAccount) }}/>
                  {this.state.preblockAccount==true &&  <View>

                        {Object.keys(languageList).map((item,languageListI)  => (
                             <TextPresentation icon={languageList[item].flag }   text={(this.state.preblockAccountMessage[languageList[item].id]!=undefined)?this.state.preblockAccountMessage[languageList[item].id]:""} textExplication={languageList[item].name}  placeholder={"Texte"} onChangeText={(text)=>{ var el=this.state.preblockAccountMessage; el[languageList[item].id]=text;     this.setState({preblockAccountMessage:el})   }}/>
                          ))}
                          <View style={{alignItems:"center",justifyContent:"center", marginTop:15,marginBottom:20, flexDirection:"row"}}>
                              <MavButton text={strings.validate} style={{marginLeft:5,}} onlyButton={true} onPress={()=>{ this.updatePreblockAccountMessage(this.state.preblockAccountMessage)  }}/>
                          </View>
                    </View>}

                 


                   {this.state.debugOtherOption==true || true && <TextPresentation reduceTextSizeOnOff={true} icon={(style.mode=="dark")?require('../assets/logo_karhoo_black.png'):require('../assets/logo_karhoo_white.png')} iconCircle={true} editable={false}  text={"Définir voitures simulées"} onNext={() => {this.navigateTo('Favorite',{view:"admin", defineFakeCar:true});}} />}                  


            </TextPresentation>
            

   



            <View style={{height:50, width:50,}}/>

            



            


            




        </ScrollView>}

        {this.state.waitValue==true && <View style={{flex:1, alignItems:"center", justifyContent:"center"}}>
            <ActivityIndicator size="large" color={style.color1} />

          </View>}








        

          <MavAlert ref={alert => {this.alert = alert;}}/>

        
      </View>
      }</StyleContext.Consumer>
    );
  }
}


