import React, { Component } from 'react';
import {
  View,
  Text,

  TouchableHighlight,
  Alert,
 
  Dimensions,
  Image,
} from 'react-native';
import style from '../style'; 
import firebase from './firebase';
import strings from '../strings';
import info from '../info';
const timer = require('react-native-timer');
import MavAlert from './mavAlert';

/*
import DeviceInfo from 'react-native-device-info';
*/

export default class drawer extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      drawerVisible:false, 
    }; 


  }



  componentDidMount(){ 
    console.log("menu mount")




    

  }
open = (type, cb) => {
    console.log("je rend le drawer visible")
    this.setState({drawerVisible:true})
    if(this.props.onOpenStart!=undefined) this.props.onOpenStart()
  }

  close = (type, cb) => {
    this.setState({drawerVisible:false})
    if(this.props.onCloseStart!=undefined) this.props.onCloseStart()

  }


  

  render() {



    return (
      <View style={{flex:1}}> 


            {this.props.children}
            {(this.state.drawerVisible===true) && <View style={{position:"absolute"}}>
              
                  <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => {this.close(); this.props.close()}} > 

                      <View style={{position:"absolute", marginLeft:(Dimensions.get('window').width>style.mobileSizeMax)?((this.props.openDrawerOffset*Dimensions.get('window').width)-Dimensions.get('window').height*0.10-10):((1-this.props.openDrawerOffset)*Dimensions.get('window').width+10) ,alignItems:"center", justifyContent:"center", marginTop:10,width:Dimensions.get('window').height*0.10, height:Dimensions.get('window').height*0.10, borderWidth:3, borderColor:"#FFFFFF", borderRadius: Dimensions.get('window').height*0.05,}} >
                          <Image source={require("../assets/driver_cancel_light.png")} style={{height:"65%",width:"65%",resizeMode:"cover", }}/>
                      </View>
                  </TouchableHighlight>
           

             <View style={{marginLeft:(Dimensions.get('window').width>style.mobileSizeMax)?(this.props.openDrawerOffset*Dimensions.get('window').width):0,    width:(1-this.props.openDrawerOffset)*Dimensions.get('window').width, height:Dimensions.get('window').height, position:"absolute"}}>{this.props.content}</View>

             </View>}





      </View>
    );
  }
}

drawer.navigationOptions = params => {
  return {
    headerTintColor: style.headerColor,
    headerStyle: {
      backgroundColor: style.headerBackgroundColor,
    },
    
   
  };
};


export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

