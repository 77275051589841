/*
import RNFetchBlob from 'react-native-fetch-blob';
export default RNFetchBlob;

*/


export default {
  testDemo: function() {
    return "demo";
  },
}