import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  Easing,
  TouchableHighlight,
  TextInput,
  Dimensions,
  ActivityIndicator,
  Image,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';

import timezone from '../timezone';

import strings from '../strings';
import MavSelector from './mavSelector';
import MavAlert from './mavAlert';
import MavButton from './mavButton';
import moneyList from '../moneyList';
import MavInfoBubble from './mavInfoBubble';
import TextPresentation from './textPresentation';
import Modal from './modal';

const timer = require('react-native-timer');


export default class account extends Component {
  constructor(props) {
    super(props);

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;

    this.state = {
      extraEUMode:params.extraEUMode,
      viewAccurency:params.viewAccurency,
      viewAccurencyList:params.viewAccurencyList,
      enableTimezone:params.enableTimezone, 
      
    };
  }


  viewTimezone(){
      var i;
      var timezoneTotal=[]
      for(i=0;i<timezone.length;i++){
        console.log(timezone[i])
        timezoneTotal.push({id:i, text:timezone[i].text, textSmall:((timezone[i].value>0)?"+":"")+timezone[i].value+" UTC"})
      }
      
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

      if(this.state.enableTimezone!=false){
        this.setState({enableTimezone:false})
        params.updateEnableTimezone(false) 
      }
      else{
          this.alert.onAlert({title:strings.select, items:timezoneTotal,itemSelected:-1, buttons:[{text: strings.validate,onPress: (id,value) => {this.setState({enableTimezone:timezone[id]}); params.updateEnableTimezone(timezone[id])  }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })
      }

  }


  render() {

    let moneyListTotal=moneyList

    return (
      <StyleContext.Consumer>{style => 
      <ScrollView style={{flex:1,backgroundColor:style.backgroundColor}}>

          <MavInfoBubble type={"menu"} icon={(style.mode=="dark")?require('../assets/currencyConv_light.png'):require('../assets/currencyConv_dark.png')} title={strings.internationalCustomer} subTitle={strings.conversationGestionMoreInfo} style={{marginTop:10,}} />

          <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.extraEUMode==true)?true:false} text={strings.extraEUMode} textMini={strings.extraEUModeMoreInfo} onOnOff={()=>{ var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;params.updateExtraEUMode(!this.state.extraEUMode); this.setState({extraEUMode:!this.state.extraEUMode}) }}/>
          <TextPresentation reduceTextSizeOnOff={true} disableAnimation={true}  editable={false} onoff={(this.state.enableTimezone==false)?false:true} text={strings.enableTimezone} textMini={((this.state.enableTimezone!=false)?this.state.enableTimezone.text:undefined)} onOnOff={()=>{ this.viewTimezone(); }}/>
          <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.viewAccurency==true)?true:false} text={strings.viewAccurency} textMini={strings.viewAccurencyMoreInfo} onOnOff={()=>{this.setState({viewAccurency:!this.state.viewAccurency});var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;params.updateViewAccurency(!this.state.viewAccurency)  }}/>

          {this.state.viewAccurency==true && <TextPresentation hideTopbar={true} defautVisible={true} explicationChild={strings.selectDeviseToView}>
              {moneyListTotal.map((countryListregion, key)=>( 
                  <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { var list=this.state.viewAccurencyList; if(list[countryListregion.id]==true) delete list[countryListregion.id]; else list[countryListregion.id]=true; this.setState({viewAccurencyList:list},() =>{ var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;params.updateViewAccurencyList(this.state.viewAccurencyList)   })  }}>
                        <TextPresentation enableValidate={(this.state.viewAccurencyList[countryListregion.id]==true)?true:false} forceClick={() => {var list=this.state.viewAccurencyList; if(list[countryListregion.id]==true) delete list[countryListregion.id]; else list[countryListregion.id]=true; this.setState({viewAccurencyList:list},() =>{ var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;params.updateViewAccurencyList(this.state.viewAccurencyList)   }) }} icon={countryListregion.flag} reduceTextSizeOnOff={true} editable={false} text={countryListregion[strings.idLangue]} />
                  </TouchableHighlight>
              ))}
          </TextPresentation>}

          <View style={{width:50, height:80}} />

           
          <MavAlert ref={alert => {this.alert = alert;}}/>
           
      </ScrollView>

      }</StyleContext.Consumer>
    );
  }
}


