import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  TextInput,
  Platform,
  Dimensions,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import firebase from './firebase';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";
import MavAlert from './mavAlert';
import MavSelector from './mavSelector';
import moneyList from '../moneyList';
import Modal from './modal';
import MavNavigationMinimize from './mavNavigationMinimize';
import MavViewMore from './mavViewMore';

const timer = require('react-native-timer');
export default class account extends Component {
  constructor(props) {
    super(props);

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
    var infoMoney=this.findMoney();
    this.state = {
        kilometerMax:"",
        money:infoMoney,
        moneySelected:infoMoney,
        durationMin:0,
        carTypeVisible:false,
        providerFixeCommission:(params!=undefined && params.providerFixeCommission!=undefined)?params.providerFixeCommission:false,
        carList:{},
        carInfo:{},
        extraEUMode:false, 
        vipMode:false,
        luggageOption:false,
        multipleCarProvision:false,
        multipleCarProvisionOld:false,
        co2onBill:(params!=undefined && params.co2onBill!=undefined)?params.co2onBill:false,
        typeZone:[], 
    };
  }


  findMoney(value){
    if(value==undefined) value="eu";
    var final="";
    var i;
    for(i=0;i<moneyList.length;i++){
      if(final==""){
        console.log(moneyList[i])
        if(moneyList[i].id==value) final=moneyList[i];
      }
    }
    return final
  }


  changePassagers(value){
    var car=this.state.carInfo;
    var val=(car.passagers==undefined)?1:car.passagers;
    val=val+((value==0)?-1:+1) ;
    if(val<1) val=1;
    car.passagers=val;

    this.setState({carInfo:car});

  }

  changeCO2(value){
    var car=this.state.carInfo;
    car.co2=(value!="")?parseFloat(value):"";
    this.setState({carInfo:car});

  }

  navigateTo= (link,params) => {
   
    if(this.props.navigation!=undefined) this.props.navigation.navigate(link, params);
    else this.props.params.navigate(link, params);
  }

  onPricePlace = data => {
    var typeZone=JSON.parse(JSON.stringify(this.state.typeZone)); 
    typeZone[this.state.typeZoneSelected].name=data.description;
    typeZone[this.state.typeZoneSelected].latitude=data.coordinates.latitude
    typeZone[this.state.typeZoneSelected].longitude=data.coordinates.longitude
    this.setState({typeZone:typeZone})
}



  changeBaggage(value){
    var car=this.state.carInfo;
    var val=(car.luggageMax==undefined)?1:car.luggageMax;
    val=val+((value==0)?-1:+1) ;
    if(val<1) val=1;
    car.luggageMax=val;

    this.setState({carInfo:car});

  }
  changeAllPrice(value, idZone){
    console.log("changeAllPrice lancée")
      var car=this.state.carList; 
      var money=this.state.money.symbole;

      if(idZone!=undefined) {
        car=(this.state.typeZone[idZone].price!=undefined)?this.state.typeZone[idZone].price:{};
        if(this.state.typeZone[idZone].money!=undefined) money=this.state.typeZone[idZone].money.symbole;
      }

      
      var carArray=Object.keys(car)
      var type=["1","2","3","4","5","more","provCom"];
      var i;
      for(i=0;i<carArray.length;i++){
          for(j=0;j<type.length;j++){
            if(car[carArray[i]][type[j]]!=undefined){
              car[carArray[i]][type[j]]=car[carArray[i]][type[j]].replace(money,'')
              car[carArray[i]][type[j]]=((value.position=="left")?(value.symbole+" "):"")+car[carArray[i]][type[j]]+((value.position=="right")?(value.symbole):"")
            }
            
              
          }  
      }
      
      
      if(idZone!=undefined){
        var typeZone=JSON.parse(JSON.stringify(this.state.typeZone)); 
        typeZone[idZone].price=car;
        typeZone[idZone].money=value
        this.setState({typeZone:typeZone})
      }
      else this.setState({money:value,carList:car})
  }


  changePrice(value,type){


    var car=this.state.carInfo;    
    timer.setTimeout("changePrice"+type, () => {
        var test=this.state.carInfo[type].indexOf(this.state.money.symbole);
        if(test==-1 && this.state.carInfo[type]!="") {
          var car=this.state.carInfo;
          car[type]=((this.state.moneySelected.position=="left")?(this.state.moneySelected.symbole+" "):"")+car[type]+((this.state.moneySelected.position=="right")?(this.state.moneySelected.symbole):"")
          this.setState({carInfo:car}); 
        }
    }, 1000);

    car[type]=value.toString().replace(",",'.')
    car[type]=value.toString().replace(this.state.moneySelected.symbole,'')
    
    this.setState({ carInfo:car });

}

changeKm(value,radius){

  timer.setTimeout(generateUUID(), () => {
    var value=(radius=="radius")?this.state.typeZone[this.state.typeZoneSelected].radius:this.state.kilometerMax
        var test=value.indexOf("km");

        if(test==-1 && value!="" && isNaN(parseFloat(value))==false ) {
          value+="km";
          if(radius=="radius"){
            var typeZone=JSON.parse(JSON.stringify(this.state.typeZone)); 
            typeZone[this.state.typeZoneSelected].radius=value
            this.setState({typeZone:typeZone})
          }
          else this.setState({ kilometerMax:value });
        } 
    }, 1000);
    value=value.toString().replace("km",'')
    value=value.toString().replace(",",'.')
    if(radius=="radius"){
      var typeZone=JSON.parse(JSON.stringify(this.state.typeZone)); 
      typeZone[this.state.typeZoneSelected].radius=value
      this.setState({typeZone:typeZone})
    }
    else this.setState({ kilometerMax:value });

}

testCar(car, money){

  var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

  var result={
    1:parseFloat(car[1].toString().replace(money.symbole,'')),
    2:parseFloat(car[2].toString().replace(money.symbole,'')),
    3:parseFloat(car[3].toString().replace(money.symbole,'')),
    4:parseFloat(car[4].toString().replace(money.symbole,'')),
    5:parseFloat(car[5].toString().replace(money.symbole,'')),
    more:parseFloat(car.more.toString().replace(money.symbole,'')),
    provCom:(this.state.providerFixeCommission==true && car.provCom!=undefined)?parseFloat(car.provCom.toString().replace(money.symbole,'')):null,
    passagers:car.passagers,
    name:car.name.trim(),
    co2:( (typeof car.co2 !== 'undefined')  && (car.co2.toString().length>0 || car.co2.toString()=="0" ) )?car.co2:null,
  }

  if(params!=undefined && params.luggageOption===true){result.luggageMax=(car.luggageMax!=undefined)?car.luggageMax:1}
  if(params!=undefined && params.extraEUMode===true){result.moneyID=money.id}

  if(params!=undefined && params.vipMode===true){
      var target=car.target;
      if(target==undefined) target=[true, true, true];
      result.target={ standard:target[0], vip:target[1], pro:target[2]  }
    }

  if(result.name=="" || isNaN(result[1]) || isNaN(result[2]) || isNaN(result[3]) || isNaN(result[4]) || isNaN(result[5]) || isNaN(result.more) ) result.error=strings.pricingErrorCar+" "+result.name;

  return result; 

}

confirmModification(){
      var error=false;
      var sendToFirebase=new Object;
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
      var carListArray=Object.keys(this.state.carList);
      var result;
      var i,j; 

      for(i=0;i<carListArray.length;i++){
        if(error==false){
            result=this.testCar(this.state.carList[carListArray[i]], this.state.money)
            if(result.error!=undefined) error=result.error;
            else sendToFirebase[carListArray[i]]=result;
        }
      }
      if(this.state.typeZone.length>0 && error==false){
        var typeZoneList=[];

        /*pour chaque zone, je teste tout les prix*/
        for(j=0;j<this.state.typeZone.length;j++){
          console.log("test zone "+this.state.typeZone[j].name)
          
          var priceOftheZone;
          
            if(this.state.typeZone[j].name==undefined || this.state.typeZone[j].name=="" || this.state.typeZone[j].radius==undefined || this.state.typeZone[j].radius=="")  error=strings.thanksAllItems+" "+strings.forTheZone.toLowerCase()+" "+this.state.typeZone[j].name; 
            else if(this.state.typeZone[j].price==undefined || Object.keys(this.state.typeZone[j].price).length==0) error=strings.noDefinedPrice+" "+strings.forTheZone.toLowerCase()+" "+this.state.typeZone[j].name; 
            else {

              carListArray=Object.keys(this.state.typeZone[j].price);
              priceOftheZone={};
              for(i=0;i<carListArray.length;i++){
                if(error==false){
                    result=this.testCar(this.state.typeZone[j].price[carListArray[i]], ((this.state.typeZone[j].money!=undefined)?this.state.typeZone[j].money:this.state.money))
                    if(result.error!=undefined) error=result.error+" "+strings.forTheZone.toLowerCase()+" "+this.state.typeZone[j].name; 
                    else priceOftheZone[carListArray[i]]=result;
                }
              }

              var sendRI={name:this.state.typeZone[j].name, latitude:this.state.typeZone[j].latitude, longitude:this.state.typeZone[j].longitude,radius:parseFloat(this.state.typeZone[j].radius), price:priceOftheZone}
              if(this.state.typeZone[j].money!=undefined) sendRI.moneyID=this.state.typeZone[j].money.id
              typeZoneList.push(sendRI)
              
            }


        }

        if(error==false) sendToFirebase.typeZone=typeZoneList; 

      }

      if(error!=false) this.alert.onAlert({title:error}); 
      else {
      var kilometerMax=(this.state.kilometerMax!="" && isNaN(parseFloat(this.state.kilometerMax))==false)?parseFloat(this.state.kilometerMax):null;

      console.log(JSON.stringify(sendToFirebase))

      const fireInfo={} 
      fireInfo["/pricingProvision"]=sendToFirebase; 
      fireInfo["/settings/kilometerMax"]=kilometerMax;
      fireInfo["/settings/durationMin"]=this.state.durationMin; 
      fireInfo["/settings/multipleCarProvision"]=(this.state.multipleCarProvision==true)?true:null;

      update(ref(getDatabase(firebase)), fireInfo)
      
      if(Platform.OS=="web") this.props.params.goBack();
      else this.props.navigation.goBack()
      

    }
}

  

  componentDidMount() {
   var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    if (getAuth(firebase).currentUser!= null && this.state.id!="") {
      get(child(ref(getDatabase(firebase)), '/pricingProvision')).then(value => {
        var val = value.val();
        
        if(val!=null){
        
              var price=Object.keys(val);
              var i,j,k,l,temp, temp2;

              for(i=0;i<price.length;i++){

                if(price[i]=="typeZone"){
                  var typeZoneProv=JSON.parse(JSON.stringify(val[price[i]]));
                  delete val[price[i]]; 
                  for(k=0;k<typeZoneProv.length;k++){
                      if(typeZoneProv[k].moneyID!=undefined)  typeZoneProv[k].money=this.findMoney(typeZoneProv[k].moneyID); 
                      temp2=Object.keys(typeZoneProv[k].price);
                      typeZoneProv[k].radius+="km";
                      for(l=0;l<temp2.length;l++){
                          temp=Object.keys(typeZoneProv[k].price[temp2[l]]);
                          for(j=0;j<temp.length;j++){
                            if(temp[j]!="passagers" && temp[j]!="co2" && temp[j]!="target" && temp[j]!="name" && temp[j]!="luggageMax" && temp[j]!="moneyID" ) typeZoneProv[k].price[temp2[l]][temp[j]]= ((infoMoney.position=="left")?(infoMoney.symbole+" "):"")+typeZoneProv[k].price[temp2[l]][temp[j]]+((infoMoney.position=="right")?(infoMoney.symbole):"")
                          }
                          if(typeZoneProv[k].price[temp2[l]].target!=undefined) typeZoneProv[k].price[temp2[l]].target=[typeZoneProv[k].price[temp2[l]].target.standard, typeZoneProv[k].price[temp2[l]].target.vip, typeZoneProv[k].price[temp2[l]].target.pro ]
                          
                      }

                      

                      

                  }
                  



                  this.setState({typeZone:typeZoneProv});
                  

                }
                else{ 
                    temp=Object.keys(val[price[i]]);

                    if(i==0){
                          var infoMoney=this.state.money
                          if(params!=undefined && params.extraEUMode===true){
                            infoMoney=this.findMoney((val[price[i]].moneyID!=undefined)?val[price[i]].moneyID:"eu"); 
                            this.setState({money:infoMoney})
                          }
                      }

                    for(j=0;j<temp.length;j++){
                        if(temp[j]!="passagers" && temp[j]!="co2" && temp[j]!="target" && temp[j]!="name" && temp[j]!="luggageMax" && temp[j]!="moneyID" ) val[price[i]][temp[j]]= ((infoMoney.position=="left")?(infoMoney.symbole+" "):"")+val[price[i]][temp[j]]+((infoMoney.position=="right")?(infoMoney.symbole):"")
                     
                    }

                  if(params!=undefined){this.setState({extraEUMode: (params.extraEUMode==true)?true:false, vipMode:(params.vipMode==true)?true:false,  luggageOption:(params.luggageOption==true)?true:false  }) }
                  if(val[price[i]].target!=undefined) val[price[i]].target=[val[price[i]].target.standard, val[price[i]].target.vip, val[price[i]].target.pro ]
                  if(val[price[i]].passagers==undefined) val[price[i]].passagers=1;

              }


            }        
              this.setState({carList:val})
      
      }
  
        

      });
      get(child(ref(getDatabase(firebase)), '/settings')).then(value => {

        var val=value.val();
        if(val!=null){
            var st={}
            if(val.kilometerMax!=undefined) st.kilometerMax=val.kilometerMax+"km";
            if(val.durationMin!=undefined) st.durationMin=val.durationMin;
            if(val.multipleCarProvision!=undefined) st.multipleCarProvision=val.multipleCarProvision;

            this.setState(st)
        } 
      })


    }
    
  }


  render() {
    return (
      <StyleContext.Consumer>{style => 
      <ScrollView style={{ marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor }}>



      {(Platform.OS=="web" && this.props.params!=undefined && this.props.params.goBack!=undefined) && <MavNavigationMinimize  clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={ strings.provision } />}



        

        


        <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",marginTop:10}}>{strings.provisionPriceDefault}</Text>
        <Text style={{marginLeft:"10%", marginRight:"10%",marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.priceProvisionExplication}</Text>

        {(this.state.extraEUMode===true) && <MavTextInput style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} editable={false} disabledText={false} text={this.state.money[strings.idLangue]} chooseMoney={true} flagMoney={this.state.money.flag} textExplication={strings.moneySelected} onChangeMoney ={(info)=>{ this.changeAllPrice(info); }}  />}

        {Object.keys(this.state.carList).map((rowData) => (
            <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.setState({carTypeVisible:true, moneySelected:this.state.money, otherZoneSelected:false, carInfo:this.state.carList[rowData],carInfoID:rowData }) }} >
                <MavTextInput style={{marginTop:5}} textInputFlex={0.8} disabledText={true} textExplication={this.state.carList[rowData].name}  enableRemove={true} onRemove={() => {
                  var cl={}
                  var i;
                  var obj=Object.keys(this.state.carList)
                  var test;
                  for (i=0;i<obj.length;i++){
                    console.log("je teste "+i+ " "+rowData+" ")
                    test="type"+(i+1);
                    if(test!=rowData) cl["type"+(Object.keys(cl).length+1)]=this.state.carList[obj[i]];
                  }
                  this.setState({carList:cl})
                }} />
            </TouchableHighlight>
            ))}


        {Object.keys(this.state.carList).length<7 && 
          <View style={{alignItems:"center",justifyContent:"center", marginTop:30}}>

            <MavButton text={strings.add} onlyButton={true}onPress={()=>{ this.setState({carTypeVisible:true, moneySelected:this.state.money,otherZoneSelected:false, carInfo:{1: '',2: '',3: '',4: '',5:'',more:'',name:'',passagers:1,},carInfoID:"" })   }}/>

        </View>}

        <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,marginTop: style.textInputMarginTop+10,marginLeft:"10%",width: "80%",}}/>


        <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",marginTop:10}}>{strings.otherProvisionZone}</Text>
        <Text style={{marginLeft:"10%", marginRight:"10%",marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.otherProvisionZoneMoreInfo}</Text>

        {this.state.typeZone.map((rowData,indexR) => (
                <MavViewMore defautVisible={true} text={ (rowData.name!="")?rowData.name:strings.newZone }  >


                  <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.setState({typeZoneSelected:indexR}); this.navigateTo('Trajet', {onPricePlace: this.onPricePlace,})  }}>
                      <MavTextInput disabledText={true} backgroundColor={style.backgroundColorContrast }  enableNext={true} style={{marginTop:15}} textExplication={(rowData.name=="")?strings.selectFrom:(strings.from+" : "+rowData.name)} />
                  </TouchableHighlight>

                  <MavTextInput style={{marginTop:5}} textInputFlex={1} backgroundColor={style.backgroundColorContrast } keyboardType={"numeric"} text={(rowData.radius!=undefined)?rowData.radius:""} textExplication={strings.pricingKmPoint} placeholder={"15 km"} onChangeText={(text)=>{this.setState({typeZoneSelected:indexR},() =>{this.changeKm(text,"radius");})   }}/>
                  {(this.state.extraEUMode===true) && <MavTextInput backgroundColor={style.backgroundColorContrast } style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} editable={false} disabledText={false} text={(rowData.money!=undefined)?rowData.money[strings.idLangue]:this.state.money[strings.idLangue]} chooseMoney={true} flagMoney={(rowData.money!=undefined)?rowData.money.flag:this.state.money.flag} textExplication={strings.moneySelected} onChangeMoney ={(info)=>{this.changeAllPrice(info,indexR); }}  />}

                  <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",marginTop:10}}>{strings.carType}</Text>
                  <Text style={{marginLeft:"10%", marginRight:"10%",marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.priceProvisionExplication}</Text>


                  {(rowData.price!=undefined) && Object.keys(rowData.price).map((rowDataPrice) => (
                        <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { this.setState({carTypeVisible:true, moneySelected:(rowData.money!=undefined)?rowData.money:this.state.money, typeZoneSelected:indexR, otherZoneSelected:true, carInfo:rowData.price[rowDataPrice],carInfoID:rowDataPrice }) }} >
                            <MavTextInput style={{marginTop:5}} backgroundColor={style.backgroundColorContrast } textInputFlex={0.8} disabledText={true} textExplication={rowData.price[rowDataPrice].name}  enableRemove={true} onRemove={() => {
                              var cl={}
                              var i;
                              var obj=Object.keys(rowData.price)
                              var test;
                              for (i=0;i<obj.length;i++){
                                test="type"+(i+1);
                                if(test!=rowDataPrice) cl["type"+(Object.keys(cl).length+1)]=rowData.price[obj[i]];
                              }
                              var typeZone=JSON.parse(JSON.stringify(this.state.typeZone)); 
                              typeZone[indexR].price=cl;
                              this.setState({typeZone:typeZone})
                            }} />
                        </TouchableHighlight>
                  ))}







                  {(rowData.price==undefined || Object.keys(rowData.price).length<7) && 
                      <View style={{alignItems:"center",justifyContent:"center", marginTop:30}}>
                        <MavButton text={strings.add} onlyButton={true} onPress={()=>{ this.setState({carTypeVisible:true, moneySelected:(rowData.money!=undefined)?rowData.money:this.state.money, typeZoneSelected:indexR, otherZoneSelected:true,  carInfo:{1: '',2: '',3: '',4: '',5:'',more:'',name:'',passagers:1,},carInfoID:"" })   }}/>
                    </View>}

                      <View style={{alignItems:"center",justifyContent:"center", marginTop:10}}>
                        <MavButton text={strings.remove} small={true} strokeMode={true} onlyButton={true} onPress={()=>{ var typeZone=JSON.parse(JSON.stringify(this.state.typeZone)); typeZone=typeZone.slice(0,indexR).concat(typeZone.slice(indexR+1,typeZone.length)); this.setState({typeZone:typeZone})   }}/>
                    </View>

                </MavViewMore>
         ))}

        <View style={{alignItems:"center",justifyContent:"center", marginTop:30}}><MavButton text={strings.add} onlyButton={true} onPress={()=>{ console.log(this.state.typeZone); var typeZone=JSON.parse(JSON.stringify(this.state.typeZone)); console.log(typeZone); typeZone.push({name:""}); this.setState({typeZone:typeZone})   }}/></View>



        <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,marginTop: style.textInputMarginTop+10,marginLeft:"10%",width: "80%",}}/>

        
        <Text style={{marginTop:20,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.settingsOther}</Text>

       

        <MavTextInput style={{marginTop:15}} textInputFlex={1} enableButtonsLH={true} text={this.state.durationMin} textExplicationMini={strings.durationInMinute} textExplication={strings.durationMinimumMinutes}  onChange={(value)=>{ 
                var tt=this.state.durationMin;
                tt+=(value==1)?30:-30;
                if(tt<0) tt=0;
                this.setState({durationMin:tt})
              }} />

        <MavTextInput style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} text={this.state.kilometerMax} textExplication={strings.kmMaxHour} placeholder={"30km"} onChangeText={(text)=>{this.changeKm(text)}}/>

        <MavTextInput style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.multipleCarProvision} textExplication={strings.multipleCarProvision+". "+strings.clickMoreInfos} textExplicationOnPress={() => {this.alert.onAlert({title:strings.multipleCarProvision, subTitle:strings.multipleCarProvisionMoreInfo}) }} onChange={()=>{
              this.setState({multipleCarProvision:(this.state.multipleCarProvision==true)?false:true})
          }}/>

        
        



      <View style={{alignItems:"center",justifyContent:"center", marginTop:20,marginBottom:30}}>

        <MavButton text={strings.confirm} onlyButton={true} onPress={()=>{ this.confirmModification()}}/>

        </View>






        
        
        
        
        
        <MavAlert ref={alert => {this.alert = alert;}}/>
        
        <Modal
            animationType="slide"
            transparent={false}
            visible={this.state.carTypeVisible}>
                <View style={{flex: 1,backgroundColor: style.backgroundColor}}>
                                
                      <View style={{marginTop: 50,backgroundColor:style.backgroundColor}}>

                            <Text style={{marginLeft:"10%", marginRight:"10%",marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.priceProvisionAddExplication}</Text>

                            {(this.state.vipMode===true) && <MavSelector style={{marginBottom:10}}  enabledItem={  (this.state.carInfo.target!=undefined)?this.state.carInfo.target:[true,true, true] } textCenter={strings.vip} textLeft={strings.standard} textRight={strings.pro} onChange={(value)=>{ 
                                 var carInfo=this.state.carInfo;
                                 value*=2;
                                 if(carInfo.target==undefined) carInfo.target=[true,true,true];
                                 carInfo.target[value]=!carInfo.target[value];

                                 this.setState({carInfo:carInfo})

                            }}/>}
                            
                            <MavTextInput style={{marginTop:5}} textInputFlex={1} text={this.state.carInfo.name} textExplication={strings.priceCarName} placeholder={"Berline"} onChangeText={(text)=>{text=text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');var car=this.state;car.carInfo.name=text;this.setState(car);}}/>

                            <MavTextInput style={{marginTop:5}} textInputFlex={1} enableButtonsLH={true} text={this.state.carInfo.passagers} textExplication={((this.state.carInfo.passagers<2)?strings.onePassager:strings.morePassager)}  onChange={(value)=>{ this.changePassagers(value) }} />

                            {(this.state.luggageOption===true) && <MavTextInput style={{marginTop:5}} textInputFlex={1} enableButtonsLH={true} text={(this.state.carInfo.luggageMax!=undefined)?this.state.carInfo.luggageMax:1} textExplication={((this.state.carInfo.luggageMax==undefined || this.state.carInfo.luggageMax<2)?strings.oneBaggageMax:strings.baggageMax)}  onChange={(value)=>{ this.changeBaggage(value) }} />}


                            {this.state.co2onBill==true && <MavTextInput style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={(this.state.carInfo.co2!=undefined)?this.state.carInfo.co2.toString():""} textExplication={strings.co2+" g/km"} placeholder={"115 g/km"} onChangeText={(text)=>{this.changeCO2(text)}}/>}



                            <MavTextInput style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} text={this.state.carInfo[1]} textExplication={strings.hour+" n°1 : "} placeholder={((this.state.moneySelected.position=="left")?(this.state.moneySelected.symbole+" "):"")+ "50"+((this.state.moneySelected.position=="right")?(this.state.moneySelected.symbole):"")} onChangeText={(text)=>{this.changePrice(text,"1")}}/>

                            <MavTextInput style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} text={this.state.carInfo[2]} textExplication={strings.hour+" n°2 : "} placeholder={((this.state.moneySelected.position=="left")?(this.state.moneySelected.symbole+" "):"")+ "50"+((this.state.moneySelected.position=="right")?(this.state.moneySelected.symbole):"")} onChangeText={(text)=>{this.changePrice(text,"2")}}/>

                            <MavTextInput style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} text={this.state.carInfo[3]} textExplication={strings.hour+" n°3 : "} placeholder={((this.state.moneySelected.position=="left")?(this.state.moneySelected.symbole+" "):"")+ "50"+((this.state.moneySelected.position=="right")?(this.state.moneySelected.symbole):"")} onChangeText={(text)=>{this.changePrice(text,"3")}}/>

                            <MavTextInput style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} text={this.state.carInfo[4]} textExplication={strings.hour+" n°4 : "} placeholder={((this.state.moneySelected.position=="left")?(this.state.moneySelected.symbole+" "):"")+ "50"+((this.state.moneySelected.position=="right")?(this.state.moneySelected.symbole):"")} onChangeText={(text)=>{this.changePrice(text,"4")}}/>

                            <MavTextInput style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} text={this.state.carInfo[5]} textExplication={strings.hour+" n°5 : "} placeholder={((this.state.moneySelected.position=="left")?(this.state.moneySelected.symbole+" "):"")+ "50"+((this.state.moneySelected.position=="right")?(this.state.moneySelected.symbole):"")} onChangeText={(text)=>{this.changePrice(text,"5")}}/>

                            <MavTextInput style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} text={this.state.carInfo.more} textExplication={strings.hourMore} placeholder={((this.state.moneySelected.position=="left")?(this.state.moneySelected.symbole+" "):"")+ "50"+((this.state.moneySelected.position=="right")?(this.state.moneySelected.symbole):"")} onChangeText={(text)=>{this.changePrice(text,"more")}}/>

                            {this.state.providerFixeCommission==true && <MavTextInput style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carInfo.provCom} textExplication={strings.providerCommission} placeholder={((this.state.moneySelected.position=="left")?(this.state.moneySelected.symbole+" "):"")+"2"+((this.state.moneySelected.position=="right")?(this.state.moneySelected.symbole):"")} onChangeText={(text)=>{this.changePrice(text,"provCom")}}/>}





                            <MavButton text={strings.validate} style={{marginTop:40}} onPress={()=>{ 
                               
                                if(this.state.otherZoneSelected==true){
                                    var price=(this.state.typeZone[this.state.typeZoneSelected].price!=undefined)?this.state.typeZone[this.state.typeZoneSelected].price:{}
                                    if(this.state.carInfoID==""){
                                        var genID="type"+(Object.keys(price).length+1)
                                        price[genID]=this.state.carInfo;
                                    }
                                    else price[this.state.carInfoID]=this.state.carInfo;
                                    var typeZone=JSON.parse(JSON.stringify(this.state.typeZone)); 
                                    typeZone[this.state.typeZoneSelected].price=price;
                                    this.setState({carTypeVisible:false,typeZone:typeZone}) 

                                }
                                else {
                                    var cl=this.state.carList;
                                    if(this.state.carInfoID==""){
                                      var genID="type"+(Object.keys(this.state.carList).length+1)
                                      cl[genID]=this.state.carInfo;
                                    } 
                                    else cl[this.state.carInfoID]=this.state.carInfo;
                                    this.setState({carTypeVisible:false,carList:cl})
                                }
                             }}/>


                            <MavButton text={strings.cancel} strokeMode={true} style={{marginTop:10}} onPress={()=>{ 
                                this.setState({carTypeVisible:false})
                             }}/>

                      </View>

                  </View>

        </Modal>
        
        
        
        

      </ScrollView>
      }</StyleContext.Consumer>
    );
  }
}

account.navigationOptions = ({ navigation, screenProps }) => {
  return {
    headerTitle: strings.provision,
    headerTintColor: screenProps.headerColor,
    headerStyle: {
      backgroundColor: screenProps.headerBackgroundColor,
    },
  };
};

export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

