import React, { Component } from 'react';
import {View,Text,ListView,ScrollView,TouchableHighlight,FlatList,Dimensions, Image,Linking,Alert,Platform,ActivityIndicator} from 'react-native';
import {StyleContext, styleInfo} from '../styleC';

import firebase from './firebase';
import strings from '../strings';
import info from '../info';
const timer = require('react-native-timer');
import MavButton from './mavButton';

export default class App extends Component {
  constructor(props) {
    super(props);
    
    
    
    var testAdr=this.props.data.description.split(this.props.data.userDescription+", ").join("");
    testAdr=testAdr.split(", France").join("");
    testAdr=testAdr.split(", Suisse").join("");
    testAdr=testAdr.split(", Belgique").join("");
    var adress=testAdr.split(", ").join("\n");

    console.log(testAdr)
    
    this.state={
        adress:adress,
        
    } 

  }

  

 

     

 

  componentDidMount(){
   console.log("POIMapDetail mount")
   
    
    

  }

  


  render() {
    
    return (
      <StyleContext.Consumer>{style => 
       <View style={{backgroundColor:style.backgroundColor, marginLeft:"5%", marginRight:"5%",  }}> 

        <View style={{justifyContent:"space-between",marginTop:10,flexDirection:"row",}}>
            <View>
                <Text numberOfLines={1} style={{color: style.textInputColor,fontSize: style.textInputFontSize*1.4,fontWeight: 'bold',maxWidth:((Dimensions.get('window').width<style.mobileSizeMax)?Dimensions.get('window').width:style.minimizeWidth)*0.8}}>{this.props.data.userDescription}</Text>
                <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{this.state.adress}</Text>
            </View>

          
                    
            <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { this.props.close(-1) }} style={{width:30,height:30, backgroundColor:style.backgroundColorContrast, alignItems:"center", justifyContent:"center", borderRadius:15 }}>
                <Text style={{color: (style.mode=="dark")?style.textInputColor:style.colorDark,fontSize: style.textInputFontSize*1.5,fontWeight: 'bold', marginTop:-2}}>{"x"}</Text>
            </TouchableHighlight>


        </View>


       {this.props.disableFromTo!=true && <View style={{width: "100%",alignItems:"center",justifyContent:"center",marginTop:10,flexDirection:"row"}}>
          <MavButton onlyButton={true} style={{marginRight:5}} text={strings.startFromHere} onPress={()=>{ this.props.close("from")  }}/>
          <MavButton onlyButton={true} style={{marginLeft:5}}  text={strings.goToHere} onPress={()=>{ this.props.close("to")  }}/>
       </View>}

       {(this.props.data.descriptionPlace!=undefined && this.props.data.descriptionPlace!="") && <View>
          <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,fontWeight: 'bold'}}>{strings.description}</Text>
          <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{this.props.data.descriptionPlace}</Text>

          </View>}

      {(this.props.data.pickUpLocationDescription!=undefined && this.props.data.pickUpLocationDescription!="") && <View>
          <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,fontWeight: 'bold'}}>{strings.pickUpLocation}</Text>
          <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.8}}>{this.props.data.pickUpLocationDescription}</Text>

          </View>}
        
        
          <View style={{width:50, height:40}} />



    </View>
    }</StyleContext.Consumer>  
    );
  }
} 

