import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  Keyboard,
  TouchableHighlight,
  DatePickerIOS,
  Image,
  Picker,
  Platform,
  ScrollView,
  
} from 'react-native';
import {StyleContext,styleInfo} from '../styleC';
import info from '../info';
import strings from '../strings';
const timer = require('react-native-timer');
import Wait3DSecure from './wait3DSecure';
import MavTextInput from './mavTextInput';
import MavAlert from './mavAlert';
import MavNavigationMinimize from './mavNavigationMinimize';
import MavButton from './mavButton';
import Modal from './modal';




class HeaderRight extends Component {
render() {
    return (
      <StyleContext.Consumer>{style => 

        <TouchableHighlight style={{ marginRight:10}} underlayColor={"#FFFFFF00"} onPress={() => { this.props.validateNewHour(); }}>
          <View style={{width:70, height:30,backgroundColor:(style.headerBackgroundColor==style.color1)?style.headerColor:style.color1,borderRadius:20, alignItems:"center", justifyContent:"center"}}>
              <Text style={{fontSize: 14,color:(style.headerBackgroundColor==style.color1)?style.backgroundColor:style.headerBackgroundColor}}>{strings.validate}</Text>
           </View>
          </TouchableHighlight>

        }</StyleContext.Consumer>
      )
  }
}



export default class MavSelectDate extends Component {

  

  state = {
    modalVisible: false,
    immediateMode:false,
    immediateModeSelected:false, 
    daySelected:0,
    dateMin:"2010-10-10",
    daySelectedDefault:0,
    dispenser:false,
    monthDate:new Date(new Date().setDate(10)),
    monthDayMax:1,
    hour:0,
    reducedSize:false,
    minute:0,
    hideNavBar:false,
    keyboardVisible:false,
    disableHour:false, 
    navigateTrue:false,
    enableValidateButton:false,
    enableTimezone:false, 
    testDate:new Date(), 
    
  };

  

  setModalVisible(visible) {
    this.setState({modalVisible: visible});
  }

  
  updateHourPicker= (value) => {
    console.log(value)
    this.setState({testDate:value, hour:value.getHours(), minute:value.getMinutes() })
  }

  _validateNewHour = () => {
      var date=((this.state.daySelected<10)?"0":"")+this.state.daySelected+"/"+(((this.state.monthDate.getMonth()+1)<10)?"0":"")+(this.state.monthDate.getMonth()+1)+"/"+this.state.monthDate.getFullYear()+" - "+((this.state.hour<10)?"0":"")+this.state.hour+":"+((this.state.minute<10)?"0":"")+this.state.minute
      var actualHour=date[13]+date[14]+':'+date[16]+date[17];
      
      date=new Date(date[6]+date[7]+date[8]+date[9]+'-'+date[3]+date[4]+'-'+date[0]+date[1]+'T'+date[13]+date[14]+':'+date[16]+date[17]+':00Z')


      var decalZone=date.getTimezoneOffset()*60000
      date=Date.parse(date)+date.getTimezoneOffset()*60000

      if(this.state.enableTimezone==false){
          this._validateNewHourAfterVerification(date);
      }
      else {
        
        var dateHourLocalCor=date-this.state.enableTimezone.value*60*60000-decalZone
        var dateHourDestCor=date+this.state.enableTimezone.value*60*60000+decalZone


        var localHour=new Date(dateHourLocalCor);
        localHour=((localHour.getHours()<10)?"0":"")+localHour.getHours()+":"+((localHour.getMinutes()<10)?"0":"")+localHour.getMinutes()

         var destHour=new Date(dateHourDestCor);
        destHour=((destHour.getHours()<10)?"0":"")+destHour.getHours()+":"+((destHour.getMinutes()<10)?"0":"")+destHour.getMinutes()


        this.alert.onAlert({title: strings.doyouMean+" \n\n- "+actualHour+" "+strings.hourOf.toLowerCase()+" "+this.state.enableTimezone.text+" ("+localHour+" "+strings.localHour.toLowerCase()+")"+"\n-"+" "+actualHour+" "+strings.localHour.toLowerCase()+" ("+destHour+" "+strings.hourOf.toLowerCase()+" "+this.state.enableTimezone.text+")", buttons:[{text:strings.hourOf.toLowerCase()+" "+this.state.enableTimezone.text,onPress: () => {this._validateNewHourAfterVerification(dateHourLocalCor)}} ,  {text:strings.localHour.toLowerCase(),onPress: () => {  this._validateNewHourAfterVerification(date); }} , {text: strings.cancel, style:"cancel",onPress: () => {}}   ]}) 
        


      }
     } 




  

  _validateNewHourAfterVerification = (date) => {
    var send={}
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;

    if(this.state.immediateModeSelected===true){
     send.immediate=true;

    } else {
     
     /*
     var dateTxt=((this.state.daySelected<10)?"0":"")+this.state.daySelected+"/"+(((this.state.monthDate.getMonth()+1)<10)?"0":"")+(this.state.monthDate.getMonth()+1)+"/"+this.state.monthDate.getFullYear()+" - "+((this.state.hour<10)?"0":"")+this.state.hour+":"+((this.state.minute<10)?"0":"")+this.state.minute
     var date=dateTxt;
     date=new Date(date[6]+date[7]+date[8]+date[9]+'-'+date[3]+date[4]+'-'+date[0]+date[1]+'T'+date[13]+date[14]+':'+date[16]+date[17]+':00')
     
     date=Date.parse(date)+date.getTimezoneOffset()*60000
    */
     

     if(date<(this.state.dateMin-60000)===true && this.state.driver!=true  ){
      this.alert.onAlert({maxiMode:this.state.dispenser, title:strings.reservationTimeBefore1+" "+((Math.trunc(params.delayRaceMin/60)<10)?"0":"")+(Math.trunc(params.delayRaceMin/60))+":"+(((params.delayRaceMin%60)<10)?"0":"")+params.delayRaceMin%60+" "+strings.reservationTimeBefore2 ,buttons:(this.state.dispenser==true)?null:[{text: strings.ok,onPress: () => {}},{text: strings.contactUs,onPress: () => {this.contactUs()}}]   ,});


      send.error=true
     }
     else{
      send.dateTxt=((this.state.daySelected<10)?"0":"")+this.state.daySelected+"/"+(((this.state.monthDate.getMonth()+1)<10)?"0":"")+(this.state.monthDate.getMonth()+1)+"/"+this.state.monthDate.getFullYear()+" - "+((this.state.hour<10)?"0":"")+this.state.hour+":"+((this.state.minute<10)?"0":"")+this.state.minute

      send.date=date; 

     }
     

     

    }
    if(send.error!=true){

      if(params.changeDate!=undefined) params.changeDate(send)
      if(this.props.navigation!=undefined) this.props.navigation.goBack();
      else if(this.props.params.goBack!=undefined) this.props.params.goBack("validate");
    }
    

/*
     var date=((this.state.daySelected<10)?"0":"")+this.state.daySelected+"/"+(((this.state.monthDate.getMonth()+1)<10)?"0":"")+(this.state.monthDate.getMonth()+1)+"/"+this.state.monthDate.getFullYear()+" - "+((this.state.hour<10)?"0":"")+this.state.hour+":"+((this.state.minute<10)?"0":"")+this.state.minute
     this.setState({modalVisible:false})
     timer.setTimeout("timerPicker", () => {if(this.props.onDateChange!=undefined) this.props.onDateChange(date)}, 300);
 */




  };

  contactUs(){
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
      if(this.props.navigation!=undefined) this.props.navigation.goBack();
      else this.props.params.goBack();
      if(params.contactUs!=undefined) params.contactUs()

  }

  onPicker(setDate){
    console.log("onPicker start"+this.props.minDate)
    
    var e;

    if(setDate!=undefined && setDate!="") e=new Date(Date.parse(setDate));
    else e=new Date();

    console.log(e)
    if(Date.parse(this.props.minDate)>=Date.parse(e)) {
      e=new Date(Date.parse(this.props.minDate));
      this.setState({ dateMin:Date.parse(this.props.minDate)})
    }
    var monthDate=new Date(Date.parse(e)).setDate(10)
    this.setState({modalVisible:true,testDate:e,hour:e.getHours(),minute:e.getMinutes(),monthDate:new Date(monthDate),daySelectedDefault:e.getDate()},()=>this.changeMonth(0))

  }

  componentDidMount () {


   if(this.props!=undefined && (this.props.navigation!=undefined || this.props.params!=undefined )){

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params;
    if(this.props.navigation!=undefined) this.props.navigation.setOptions({ headerRight: props => <HeaderRight validateNewHour={this._validateNewHour} {...props} /> })

    var st={navigateTrue:true, };
    if(params.driver==true) st.driver=true;
    var e;  
    if(params.hideNavBar==true) st.hideNavBar=true; 
    if(params.reducedSize==true) st.reducedSize=true; 
    if(params.enableValidateButton==true) st.enableValidateButton=true;
    

    if(params.disableHour!=undefined) st.disableHour=params.disableHour;
    
    if(params.dateMin!=undefined) st.dateMin=params.dateMin
    st.immediateMode=params.immediateMode;

    if(params.delayRaceMax!=undefined && params.delayRaceMax!=false && params.driver!=true) {
      st.delayRaceMax=new Date()
      st.delayRaceMax.setDate(st.delayRaceMax.getDate()+params.delayRaceMax)
    }

    if(params.date!=""){
      e=new Date(params.date);
      if(params.immediateMode==true && params.immediate==true) st.immediateModeSelected=true;


    }
    else{
      if(params.dateMin!=undefined && params.driver!=true && params.partner!=true) e=new Date(params.dateMin)
      else e=new Date();
      st.immediateModeSelected=params.immediateMode;
    }

    st.daySelectedDefault=e.getDate();
    var monthDate=new Date(Date.parse(e)).setDate(10)
    st.hour=e.getHours();st.minute=e.getMinutes(); st.testDate=e; st.monthDate=new Date(monthDate);
    

    if(params.enableTimezone!=undefined && params.enableTimezone!=false && new Date('December 1, 2020 12:20:00').getTimezoneOffset()!=(-params.enableTimezone.value*60) ){
      st.enableTimezone=params.enableTimezone;
      st.immediateMode=false;
      st.immediateModeSelected=false; 
    } 
    else st.enableTimezone=false;

    if(params.dispenser==true) st.dispenser=true;

    this.setState(st,()=>this.changeMonth(0))

    
   }

    
    
   if(Platform.OS!="web"){
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow',this._keyboardDidShow.bind(this),);
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide',this._keyboardDidHide.bind(this),);

  }

        
  }

  componentWillUnmount () {
    if(Platform.OS!="web"){
      this.keyboardDidShowListener.remove();
      this.keyboardDidHideListener.remove();
    }
  }

  _keyboardDidShow (event) {
    this.setState({keyboardVisible:true})
  }

  _keyboardDidHide (event) {
    this.setState({keyboardVisible:false})
  }





changeMonth(month){
  var selectedMonth=this.state.monthDate;

  selectedMonth.setMonth(selectedMonth.getMonth()+month);

  var userMonth=Date.parse(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth()+1, 0));
  var isThisMonth=new Date();
  isThisMonth=Date.parse(new Date(isThisMonth.getFullYear(), isThisMonth.getMonth()+1, 0));
  isThisMonth=(isThisMonth===userMonth)?true:false;


  var dayMax=new Date(selectedMonth.getFullYear(), selectedMonth.getMonth()+1, 0).getDate();


  this.setState(() => ({monthDate:selectedMonth,monthDayMax:dayMax,daySelected:(month==0)?this.state.daySelectedDefault:((isThisMonth===true)?this.state.daySelectedDefault:1)}),function(){ })
  

}

changeHour(hour){
  var h=this.state.hour+hour;
  if(h<0) h=23;
  else if(h>23) h=0;
  this.setState({hour:h})

}

changeMinute(minute){
  var m=this.state.minute+minute;
  if(m<0) m=59;
  else if(m>59) m=0;
  this.setState({minute:m})

}


  render() {
    
    const style=styleInfo();
    var maxWidth;
    if(Dimensions.get('window').height<Dimensions.get('window').width) maxWidth=(Dimensions.get('window').height*0.8 > Dimensions.get('window').height-300)?(Dimensions.get('window').height-300):Dimensions.get('window').height*0.8;
    else maxWidth=(Dimensions.get('window').width*0.8< Dimensions.get('window').width-150)?Dimensions.get('window').width-150:Dimensions.get('window').width*0.8;
    
    if(this.state.dispenser==true) maxWidth*=0.8
    else if(this.state.reducedSize==true) maxWidth*=0.6;
    else if(Dimensions.get('window').width>style.mobileSizeMax) maxWidth=style.minimizeWidth*0.8

    if(this.state.reducedSize==true) maxWidth=style.minimizeWidth*0.6
   


    var dayList=[]
    let dayCalculate=0;
    var sizeHour=170;
    var acDate=new Date();
    acDate.setHours(12)
    acDate.setMinutes(0)
    acDate.setDate(10);

    var dateMin=this.state.dateMin
    
    var dateMax="2099-01-01"

    if(this.state.delayRaceMax!=undefined) dateMax=this.state.delayRaceMax;
    console.log("dateMax "+dateMax)

    acDate.setMonth(this.state.monthDate.getMonth())
    acDate.setFullYear(this.state.monthDate.getFullYear());
    var decalDate=new Date(acDate);
    decalDate.setDate(1);
    decalDate=(decalDate.getDay()==0)?6:decalDate.getDay()-1;
    acDate.setHours(23)
    acDate.setMinutes(59)


    var dL=[];
    for(let i = 0; i < 7; i++){
    dL.push(<View key={"key"+i} style={{flexDirection: 'row',alignItems:"center"}}>
              <Text accessibilityLabel={strings.dayListComplete[i]} style={{color:style.textInputColor,textAlign:"center",width:maxWidth/7,fontSize:style.textInputWidth*0.9*( (this.state.dispenser==true)?2:1)}}>{strings.dayList[i][0]}</Text>
              </View>)
    }

    dayList.push(<View style={{flexDirection: 'row',marginLeft:(((Dimensions.get('window').width>style.mobileSizeMax && this.state.dispenser!=true)?(style.minimizeWidth-((this.state.reducedSize==true)?sizeHour:0)):Dimensions.get('window').width)-maxWidth)/2,marginBottom:0,marginTop:10}}>{dL}</View>)

    var numberLine=0;

    for(let i = 0; i < 8; i++){
      var dL=[];
      for(let j = 0; j < 7; j++){

        if(i>0 || j>decalDate-1) dayCalculate++;
        if(dayCalculate<32){

            if(dayCalculate>0){acDate.setDate(dayCalculate)}
            var dateAc=Date.parse(acDate);
            let lock=false;

            if(dayCalculate!=0 && dayCalculate==this.state.monthDayMax && numberLine==0 ){numberLine=i+1;}

            
            if(dateAc<dateMin || dateAc>(dateMax)){lock=true;}

            if(dayCalculate==0) dL.push(<View key={"key"+j+"-"+i} style={{width:maxWidth/7,height:maxWidth/7,borderRadius:maxWidth/14,flexDirection: 'row',alignItems:"center"}} />)
            else dL.push(
            <View key={"key"+j+"-"+i} >
              {(dayCalculate-1)<this.state.monthDayMax && <TouchableHighlight onPress={() => { if(lock!==true) this.setState({daySelected:j+7*i+1-decalDate},() =>{if(this.state.disableHour==true && this.state.enableValidateButton!=true) this._validateNewHour()   })          }} underlayColor={"#00000000"} style={{backgroundColor:(this.state!=null && this.state.daySelected==dayCalculate && lock===false)?style.color1:"#ffffff00",width:maxWidth/7,height:maxWidth/7,borderRadius:maxWidth/14,flexDirection: 'row',alignItems:"center"}}>
              <Text accessibilityLabel={dayCalculate+" "+strings.monthList[this.state.monthDate.getMonth()]}  style={{color:(lock===true)?style.textInputLineColor:(this.state!=null && this.state.daySelected==dayCalculate)?style.backgroundColorContrast:style.textInputColor,textAlign:"center",width:maxWidth/7,fontWeight:(this.state!=null && this.state.daySelected==dayCalculate)?"bold":"normal",fontSize:17*( (this.state.dispenser==true)?2:1)}}>{dayCalculate}</Text>
              </TouchableHighlight>}
             </View> 
            )
          }
        }

        dayList.push(<View style={{flexDirection: 'row',marginRight:(this.state.reducedSize==true)?(style.minimizeWidth-sizeHour-maxWidth)/2:undefined , marginLeft:( ((Dimensions.get('window').width>style.mobileSizeMax && this.state.dispenser!=true)?(style.minimizeWidth-((this.state.reducedSize==true)?sizeHour:0)  ):Dimensions.get('window').width) -maxWidth)/2,marginBottom:0,}}>{dL}</View>)
    }



    var monthView=[<View>{(this.state.keyboardVisible==false) && <View >
       
                      <View style={{backgroundColor:style.backgroundColorContrast}}>
                          <View style={{height:50*( (this.state.dispenser==true)?1.5:1),flexDirection: 'row',alignItems:"center", backgroundColor:style.colorDark}}>

                            <TouchableHighlight accessibilityLabel={strings.previousMonth} underlayColor={"#00000000"} onPress={() => {this.changeMonth(-1)}} style={{width:50*( (this.state.dispenser==true)?1.5:1), alignItems:'center',justifyContent:'center'}} >
                                <View style={{flex:1,alignItems:'center',justifyContent:'center'}}>
                                    <Image source={(style.mode=="dark")?require('../assets/revenue_before.png'):require('../assets/revenue_before.png')} style={{ width: 30*( (this.state.dispenser==true)?1.5:1), height: 30*( (this.state.dispenser==true)?1.5:1) }} />
                                </View> 
                            </TouchableHighlight>
                            
                            <Text style={{fontSize: 20*( (this.state.dispenser==true)?1.5:1),flex:7,textAlign:"center",color:"#FFFFFF"}}>{strings.monthList[this.state.monthDate.getMonth()]+" "+this.state.monthDate.getFullYear()}</Text>
                            <TouchableHighlight underlayColor={"#00000000"} onPress={() => {this.changeMonth(1)}} style={{width:50*( (this.state.dispenser==true)?1.5:1),alignItems:'center',justifyContent:'center'}} >
                                <View style={{flex:1,alignItems:'center',justifyContent:'center'}}>
                                    <Image accessibilityLabel={strings.nextMonth} source={(style.mode=="dark")?require('../assets/revenue_next.png'):require('../assets/revenue_next.png')} style={{ width: 30*( (this.state.dispenser==true)?1.5:1), height: 30*( (this.state.dispenser==true)?1.5:1) }} />
                                </View> 
                            </TouchableHighlight>
        

                          </View>
                          
                            {dayList}

       
                      </View>
                  </View>}</View>]

    var genI;
    var monthAlternativeItem=[];
    var monthAlternativeDate=new Date();
    for(genI=0; genI<5; genI++){
      
      monthAlternativeItem.push({day:monthAlternativeDate.getDate(),month:monthAlternativeDate.getMonth()})
      monthAlternativeDate.setDate(monthAlternativeDate.getDate()+1)
    }
   

    var monthAlternative=[<View style={{backgroundColor:style.backgroundColorContrast, width:"100%", height:maxWidth/3+20, justifyContent:"center"}}>
      <View style={{marginLeft:"5%", marginRight:"5%", flexDirection:"row",justifyContent: 'space-between'}}>
      {monthAlternativeItem.map((rowData,i)=>(
        <TouchableHighlight key={"monthAlternativeItem"+i } onPress={() => { var selectedMonth=this.state.monthDate; selectedMonth.setMonth(rowData.month); console.log("selectedMonth = "+selectedMonth); this.setState(() => ({daySelected:rowData.day, monthDate:selectedMonth})) }} underlayColor={"#00000000"} >

            <View style={{borderRadius:maxWidth/6, height:maxWidth/3, width:maxWidth/3, borderWidth:3,borderColor:style.color1, backgroundColor:(this.state.daySelected==rowData.day && rowData.month==this.state.monthDate.getMonth() )?style.color1:null, justifyContent:"center"}}>
                <Text style={{color:(this.state.daySelected==rowData.day && rowData.month==this.state.monthDate.getMonth() )?style.backgroundColorContrast:style.textInputColor  ,fontSize: style.textInputFontSize*((Dimensions.get('window').width>1000)?4.5:3.4),textAlign: "center",}}>{rowData.day}</Text>
                <Text style={{marginTop:-15,color: (this.state.daySelected==rowData.day && rowData.month==this.state.monthDate.getMonth() )?style.backgroundColorContrast:style.textInputColor,fontSize: style.textInputFontSize*((Dimensions.get('window').width>1000)?2.1:1.5),textAlign: "center",}}>{strings.monthList[rowData.month].toLowerCase()}</Text>
            </View>

          </TouchableHighlight>
        ))}


        </View>
    </View>]






    var hourView=[<View style={{flexDirection: 'row',alignItems:'center',justifyContent:'center', marginTop:20, marginLeft:(this.state.reducedSize==true)?30:undefined }}>
       
        <View style={{flexDirection: 'column',alignItems:'center',justifyContent:'center'}}>

          <TouchableHighlight accessibilityLabel={strings.nextHour} underlayColor={"#00000000"} onPress={() => {this.changeHour(1)}}>
                      <View style={{alignItems:'center',justifyContent:'center'}}>
                          <Image source={(style.mode=="dark")?require('../assets/arrowUp_dark.png'):require('../assets/arrowUp_light.png')} style={{ width: 45*( (this.state.dispenser==true)?2:1), height: 45*( (this.state.dispenser==true)?2:1) }} />
                      </View> 
                  </TouchableHighlight>

                    <TextInput allowFontScaling={false}
                      keyboardType={"numeric"}
                      placeholder={"19"}
                      accessibilityLabel={this.state.hour.toString()+" "+strings.hour} 
                      placeholderTextColor={style.placeholderTextInputColor}
                      autoCorrect={false}   
                      underlineColorAndroid="rgba(0,0,0,0)"
                      style={{marginTop:7, marginBottom:7,fontSize:30*( (this.state.dispenser==true)?2:1),color:style.textInputColor,width:60*( (this.state.dispenser==true)?2:1),textAlign:"center"}}
                      
                      onChangeText={text => {
                        var val=parseInt(text);
                        if(!(val<24)) val=0;
                        this.setState({hour:parseInt(val)})


                      }}
                      value={((this.state.hour<10)?"0":"")+this.state.hour.toString()}
                      />




            <TouchableHighlight accessibilityLabel={strings.previousHour} underlayColor={"#00000000"} onPress={() => {this.changeHour(-1)}}>
                      <View style={{alignItems:'center',justifyContent:'center'}}>
                          <Image source={(style.mode=="dark")?require('../assets/arrowDown_dark.png'):require('../assets/arrowDown_light.png')} style={{ width: 45*( (this.state.dispenser==true)?2:1), height: 45*( (this.state.dispenser==true)?2:1) }} />
                      </View> 
                  </TouchableHighlight>

            


        </View>

        <Text style={{fontSize:30*( (this.state.dispenser==true)?2:1), color:style.textInputColor, marginLeft:15, marginRight:15}}>{":"}</Text>





          <View style={{flexDirection: 'column',alignItems:'center',justifyContent:'center',}}>

            
            <TouchableHighlight accessibilityLabel={strings.nextMinute} underlayColor={"#00000000"} onPress={() => {this.changeMinute(1)}}>
                        <View style={{alignItems:'center',justifyContent:'center'}}>
                            <Image source={(style.mode=="dark")?require('../assets/arrowUp_dark.png'):require('../assets/arrowUp_light.png')} style={{ width: 45*( (this.state.dispenser==true)?2:1), height: 45*( (this.state.dispenser==true)?2:1) }} />
                        </View> 
                    </TouchableHighlight>

                    <TextInput allowFontScaling={false}
                        keyboardType={"numeric"}
                        placeholder={"00"}
                        accessibilityLabel={this.state.minute.toString()+" "+strings.minute} 
                        placeholderTextColor={style.placeholderTextInputColor}
                        autoCorrect={false}   
                        underlineColorAndroid="rgba(0,0,0,0)"
                        style={{marginTop:7, marginBottom:7,fontSize:30*( (this.state.dispenser==true)?2:1),color:style.textInputColor,width:60*( (this.state.dispenser==true)?2:1),textAlign:"center"}}
                        
                        onChangeText={text => {
                          var val=parseInt(text);
                          if(!(val<59)) val=0;
                          this.setState({minute:parseInt(val)})


                        }}
                        value={((this.state.minute<10)?"0":"")+this.state.minute.toString()}
                        />


              <TouchableHighlight accessibilityLabel={strings.previousMinute} underlayColor={"#00000000"} onPress={() => {this.changeMinute(-1)}}>
                        <View style={{alignItems:'center',justifyContent:'center'}}>
                            <Image source={(style.mode=="dark")?require('../assets/arrowDown_dark.png'):require('../assets/arrowDown_light.png')} style={{ width: 45*( (this.state.dispenser==true)?2:1), height: 45*( (this.state.dispenser==true)?2:1) }} />
                        </View> 
                    </TouchableHighlight>



           


          </View>
    </View>]


    





    return (
    <StyleContext.Consumer>{style => 
    <View style={{flex:1, minHeight:(this.state.reducedSize==true)?320:undefined}}>


    {(this.state.navigateTrue===true) && <View style={{width:(this.state.disableHour==true && Platform.OS=="web")?"100%":((this.state.reducedSize==true)?style.minimizeWidth*1.2:( ((Dimensions.get('window').width>style.mobileSizeMax && this.state.dispenser!=true)?style.minimizeWidth:Dimensions.get('window').width))),height:(( (Dimensions.get('window').width>style.mobileSizeMax && this.state.dispenser!=true) || this.state.disableHour==true ))?null:Dimensions.get('window').height, backgroundColor:style.backgroundColor}}>

        {((Platform.OS=="web" || (Dimensions.get('window').width>style.mobileSizeMax)|| this.state.dispenser==true) && this.state.hideNavBar==false) && <MavNavigationMinimize  maxiMode={this.state.dispenser}  clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={(this.state.dispenser==true)?strings.when:strings.dateAndHour } clickRight={() => { this._validateNewHour()  }} right={(this.state.dispenser==true)?strings.next:strings.validate} />}


        {(this.state.immediateMode==true && this.state.enableTimezone==false) && <View>
         <MavTextInput style={{marginTop:0}} maxiMode={this.state.dispenser}  enableNext={false} arrowCircle={true} arrow={true} arrowEnabled={(this.state.immediateModeSelected===true)} onChange={()=>{this.setState({immediateModeSelected:true})}}  marginSize={20} textExplicationBold={true} disableLine={true} textExplication={strings.asap} disabledText={true}  />
         <MavTextInput style={{marginTop:0}} maxiMode={this.state.dispenser}  enableNext={false} arrowCircle={true} arrow={true} arrowEnabled={(this.state.immediateModeSelected===false)} onChange={()=>{this.setState({immediateModeSelected:false})}}  marginSize={20} textExplicationBold={true} disableLine={true} textExplication={strings.chooseDate} disabledText={true} />
        </View>}

        
            <View style={{flexDirection:(this.state.reducedSize==true)?"row":undefined, backgroundColor:(this.state.reducedSize==true)?style.backgroundColorContrast:undefined}}>
              {!(Dimensions.get('window').width>Dimensions.get('window').height && this.state.dispenser==true) && monthView}
              {(Dimensions.get('window').width>Dimensions.get('window').height && this.state.dispenser==true) && monthAlternative}
              {(this.state.disableHour!=true) && <View style={{marginLeft:(this.state.reducedSize==true)?10:undefined, marginTop:(this.state.reducedSize==true)?40:undefined}}>
                  {true && hourView }
              </View>}


                



            </View>

            {(this.state.reducedSize==true || this.state.enableValidateButton==true) && <View style={{alignItems:"center",justifyContent:"center",backgroundColor:style.backgroundColorContrast  }}>
                    <MavButton text={strings.validate} style={{marginTop:10, marginBottom:10}} onlyButton={true}  onPress={()=>{ this._validateNewHour() }} />
                </View>}



       
      {this.state.immediateModeSelected==true &&<View style={{position:"absolute", top:(80*((this.state.dispenser==true)?((Dimensions.get('window').width>1000)?4:3.5):1))+((Platform.OS=="web" || (Dimensions.get('window').width>style.mobileSizeMax && this.state.dispenser!=true ))?50:0),width:((Dimensions.get('window').width>style.mobileSizeMax && this.state.dispenser!=true)?style.minimizeWidth:Dimensions.get('window').width), height:   ((Dimensions.get('window').width>style.mobileSizeMax && this.state.dispenser!=true)?(80+134+style.textInputWidth*0.9+(maxWidth/7)*numberLine) :    Dimensions.get('window').height-80)                         , backgroundColor:style.backgroundColorOpacity}}/>}
        

    </View>}










    


     {<Modal
          animationType="none"
          transparent={true}
          visible={this.state.modalVisible}>
          <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",alignItems:'center',justifyContent:'center'}}>

              <TouchableHighlight underlayColor={'#FFFFFF00'} style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,backgroundColor:"rgba(0,0,0,0.5)",position:"absolute",marginTop:0}} onPress={() => {this.setState({modalVisible:false})}}>
                  <View/>
              </TouchableHighlight>


              

             

               <View style={{height:(this.state.disableHour!=true)?Dimensions.get('window').height*0.9:null,width:((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth: (Dimensions.get('window').width*((Platform.OS === "ios")?1:0.9))   )        ,backgroundColor:style.backgroundColor,position:"absolute",bottom:(Platform.OS === "ios")?0:null}}>
            
                 <View style={{flexDirection: 'row',marginTop:10,justifyContent: 'space-between',marginLeft:Dimensions.get('window').width*0.05,marginRight:Dimensions.get('window').width*0.05}}>

                    <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.setState({modalVisible:false})}}><Text style={{fontSize: style.textInputFontSize*1.15,color:style.textInputColor}}>{strings.cancel}</Text></TouchableHighlight>
                    <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {
                          var date=((this.state.daySelected<10)?"0":"")+this.state.daySelected+"/"+(((this.state.monthDate.getMonth()+1)<10)?"0":"")+(this.state.monthDate.getMonth()+1)+"/"+this.state.monthDate.getFullYear()+" - "+((this.state.hour<10)?"0":"")+this.state.hour+":"+((this.state.minute<10)?"0":"")+this.state.minute
                          this.setState({modalVisible:false})
                          timer.setTimeout("timerPicker", () => {if(this.props.onDateChange!=undefined) this.props.onDateChange(date)}, 300);
                    }}>
                        <Text style={{fontSize: style.textInputFontSize*1.15,color:style.textInputColor}}>{strings.confirm}</Text>
                    </TouchableHighlight>


                 </View>

                 <View
                    style={{
                      height: style.textLineHeight,
                      backgroundColor: style.textInputLineColor,
                      marginTop: 10,
                      width: (Dimensions.get('window').width)*((Platform.OS === "ios")?1:0.9),
                    }}
                  />

                


                
                  {monthView}
                  
                  {(this.state.disableHour!=true && this.state.reducedSize!=true) && hourView}


                   {(this.state.reducedSize==true || this.state.enableValidateButton==true) && <View style={{alignItems:"center",justifyContent:"center",backgroundColor:style.backgroundColorContrast  }}>
                        <MavButton text={strings.validate} style={{marginTop:10, marginBottom:10}} onlyButton={true}  onPress={()=>{ this._validateNewHour() }} />
                    </View>}
                  
















              </View>

              
 
        </View>


        </Modal>}

        <MavAlert ref={alert => {this.alert = alert;}}/>

        </View>

        }</StyleContext.Consumer>
      
      )}

    }


    

