import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  TextInput,
  Platform,
  Dimensions,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';

import info from '../info';
import MavViewMore from './mavViewMore';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MapView from 'react-native-maps';
import MavAlert from './mavAlert';
import MavSelectDate from './mavSelectDate';
import MavInfoBubble from './mavInfoBubble';

import MavNavigationMinimize from './mavNavigationMinimize';

const timer = require('react-native-timer');
export default class account extends Component {
  constructor(props) {
    super(props);
    
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    if(Platform.OS!="web"){
        this.props.navigation.setOptions({ title:(params.hourOnly==true)?strings.otherMajorationHour:strings.majorationHighAffluence})
    }

    var ha=(params.highAffluence!=undefined)?params.highAffluence:{};
    var halist=Object.keys(ha).sort( function ( a, b ) { return ha[a].startDate - ha[b].endDate  } )

    this.state = {
      highAffluence:ha,
      modify:false,
      disabledChange:(params!=undefined && params.disabledChange!=undefined)?params.disabledChange:false,
      highAffluenceList:halist,
      viewHighAffluence:(params.hourOnly!=true)?true:false,
      viewmajoOther:(params.hourOnly==true)?true:false,
      selected:"",
      hourOnly:params.hourOnly,
      majoOther:(params.majoOther==undefined)?{}:params.majoOther, 
      params:params, 
      };
  }



  validateHighAffluence(id,i){
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
      if(this.state.highAffluence[id].name!="" && this.state.highAffluence[id].startDate!="" && this.state.highAffluence[id].endDate!="" && isNaN(parseFloat(this.state.highAffluence[id].majoration))!=true) {
        params.updateHighAffluence({name:this.state.highAffluence[id].name,majoration:parseFloat(this.state.highAffluence[id].majoration), startDate:this.state.highAffluence[id].startDate, endDate:this.state.highAffluence[id].endDate},id );  
        this['viewHA_' + i].close();
        this.setState({modify:true})
      }
      else this.alert.onAlert({title:strings.thanksAllItems})
        
  }

  addHighAffluenceInfo(){
     var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
     if(this.state.highAffluence["new"].name!="" && this.state.highAffluence["new"].startDate!="" && this.state.highAffluence["new"].endDate!="" && isNaN(parseFloat(this.state.highAffluence["new"].majoration))!=true) {
        var uuid=generateUUID(); 
        
        var infoObj={name:this.state.highAffluence["new"].name,majoration:parseFloat(this.state.highAffluence["new"].majoration), startDate:this.state.highAffluence["new"].startDate, endDate:this.state.highAffluence["new"].endDate}
        params.updateHighAffluence(infoObj,uuid );
        var highAffluence=JSON.parse(JSON.stringify(this.state.highAffluence))
        var highAffluenceList=JSON.parse(JSON.stringify(this.state.highAffluenceList))
        delete highAffluence["new"];
        highAffluenceList.splice(highAffluenceList.length-1, 1); 
        highAffluence[uuid]=infoObj;
        highAffluenceList.push(uuid); 
        this.setState({modify:true,highAffluence:{},highAffluenceList:[]},() =>{  this.setState({highAffluence:highAffluence, highAffluenceList:highAffluenceList})    })

        
      }
      else this.alert.onAlert({title:strings.thanksAllItems})



  }

  addMajoOtherInfo(){
    console.log("addMajoOtherInfo appelé")

    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
    
    if(this.state.majoOther["new"].startDate!="" && (this.state.majoOther["new"].excludeDay==undefined || Object.keys(this.state.majoOther["new"].excludeDay).length<7) && this.state.majoOther["new"].endDate!="" && isNaN(parseFloat(this.state.majoOther["new"].majoration))!=true) {
      var uuid=generateUUID(); 
      var send={majoration:parseFloat(this.state.majoOther["new"].majoration), startDate:this.state.majoOther["new"].startDate, endDate:this.state.majoOther["new"].endDate,};
      if(this.state.majoOther["new"].excludeDay!=undefined && this.state.majoOther["new"].excludeDay!=null && this.state.majoOther["new"].excludeDay!={})  send.excludeDay=this.state.majoOther["new"].excludeDay

      params.updateMajoOther(send,uuid)
      var majoOther=JSON.parse(JSON.stringify(this.state.majoOther))
      delete majoOther["new"];
      majoOther[uuid]=send;
      this.setState({modify:true,majoOther:{} },() =>{  this.setState({majoOther:majoOther})    })

    }
    else this.alert.onAlert({title:strings.thanksAllItems})

  }



  removeHighAffluence(id,i){
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
      if(id!="new") params.removeHighAffluence(id);
      var highAffluence=JSON.parse(JSON.stringify(this.state.highAffluence))
      var highAffluenceList=JSON.parse(JSON.stringify(this.state.highAffluenceList))
      delete highAffluence[id];
      highAffluenceList.splice(i, 1); 
      this.setState({highAffluence:{},highAffluenceList:[]},() =>{  this.setState({highAffluence:highAffluence, highAffluenceList:highAffluenceList})    })
      if(id!="new")this.setState({modify:true})
  }


removeMajoOther(id,i){
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
      if(id!="new") params.removeMajoOther(id);
      var majoOther=JSON.parse(JSON.stringify(this.state.majoOther))
      delete majoOther[id];
      this.setState({majoOther:{}},() =>{  this.setState({majoOther:majoOther})    })
      if(id!="new")this.setState({modify:true})
  }


  addHighAffluence(){
    var highAffluence=JSON.parse(JSON.stringify(this.state.highAffluence))
    var highAffluenceList=JSON.parse(JSON.stringify(this.state.highAffluenceList))
    highAffluence["new"]={name:"",startDate:"",endDate:"",majoration:""}
    highAffluenceList.push("new");
    var size=highAffluenceList.length-1;
    this.setState({highAffluence:highAffluence, highAffluenceList:highAffluenceList},() =>{ this.closeOtherViewMore(-1); this['viewHA_' + size ].open();   })

  }

  addMajoOther(){
    var majoOther=JSON.parse(JSON.stringify(this.state.majoOther))
    majoOther["new"]={excludeDay:{},startDate:"",endDate:"",majoration:""}
    var size=Object.keys(majoOther).length-1;
    this.setState({majoOther:majoOther,},() =>{ this.closeOtherViewMore(-1); this['viewMO_' + size ].open();     })
}




validateMajoOther(id,i){
console.log("validateMajoOther appelé")
  var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
  if(this.state.majoOther[id].startDate!="" && this.state.majoOther[id].endDate!="" && (this.state.majoOther[id].excludeDay==undefined || Object.keys(this.state.majoOther[id].excludeDay).length<7)  && isNaN(parseFloat(this.state.majoOther[id].majoration))!=true) {

    var send={majoration:parseFloat(this.state.majoOther[id].majoration), startDate:this.state.majoOther[id].startDate, endDate:this.state.majoOther[id].endDate};
    if(this.state.majoOther[id].excludeDay!=undefined && this.state.majoOther[id].excludeDay!=null && this.state.majoOther[id].excludeDay!={})  send.excludeDay=this.state.majoOther[id].excludeDay

    params.updateMajoOther(send,id)
    this['viewMO_' + i].close();
    this.setState({modify:true})
  }
  else this.alert.onAlert({title:strings.thanksAllItems})


}

closeOtherViewMore= id => {
  var i;

  if(this.state.hourOnly==true){
    var objMajoOther=Object.keys(this.state.majoOther)
    for(i=0;i<objMajoOther.length; i++){
      if(i!=id) this['viewMO_' + i].close();
    }
  } 
  else {
    for(i=0;i<this.state.highAffluenceList.length; i++){
      if(i!=id) this['viewHA_' + i].close();
    }

  }
  


}





 


render() {
    return (
      <StyleContext.Consumer>{style => 
      <View style={{ backgroundColor: style.backgroundColor, flex: 1}}>
      <ScrollView style={{ marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor,flex:1 }}>

        

        {(Platform.OS=="web") && <MavNavigationMinimize  clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={ (this.state.params.hourOnly==true)?strings.otherMajorationHour:strings.majorationHighAffluence } />}

        <MavInfoBubble type={"info"} title={strings.majoCustom} subTitle={(this.state.hourOnly==true)?strings.otherMajorationHourMoreInfo: strings.majorationHighAffluenceMoreInfo} style={{marginTop:10,}} />

        {this.state.modify==true && <MavInfoBubble type={"warning"} title={strings.thinkSave} subTitle={strings.thinkSaveMajoOther} style={{marginTop:10,}} />}





        {this.state.viewHighAffluence==true && this.state.highAffluenceList.map((marker,i) => (
          <MavViewMore ref={viewHA => {this['viewHA_' + i] = viewHA;}}  onOpen={(text)=>{ this.closeOtherViewMore(i) }} text={(marker=="new")? strings.newMajo: ((this.state.highAffluence[marker].name=="")?strings.noNameMajo:this.state.highAffluence[marker].name)} textRight={((this.state.highAffluence[marker].majoration!="" && this.state.highAffluence[marker].majoration!=undefined)?("x"+this.state.highAffluence[marker].majoration+"    "):"")} textMini={ (marker=="new")? undefined : ( ((new Date(this.state.highAffluence[marker].startDate).getDate()<10)?"0":"")+new Date(this.state.highAffluence[marker].startDate).getDate()+"/"+((new Date(this.state.highAffluence[marker].startDate).getMonth()<9)?"0":"")+(new Date(this.state.highAffluence[marker].startDate).getMonth()+1)+" - "+((new Date(this.state.highAffluence[marker].startDate).getHours()<10)?"0":"")+new Date(this.state.highAffluence[marker].startDate).getHours()+":"+((new Date(this.state.highAffluence[marker].startDate).getMinutes()<10)?"0":"")+new Date(this.state.highAffluence[marker].startDate).getMinutes()+" -> "+ ( ( new Date(this.state.highAffluence[marker].endDate).getDate()!=new Date(this.state.highAffluence[marker].startDate).getDate() || new Date(this.state.highAffluence[marker].endDate).getMonth()!=new Date(this.state.highAffluence[marker].startDate).getMonth()   ) ?( ((new Date(this.state.highAffluence[marker].endDate).getDate()<10)?"0":"")+new Date(this.state.highAffluence[marker].endDate).getDate()+"/"+((new Date(this.state.highAffluence[marker].endDate).getMonth()<9)?"0":"")+(new Date(this.state.highAffluence[marker].endDate).getMonth()+1)+" - "  ) :"")      +         ((new Date(this.state.highAffluence[marker].endDate).getHours()<10)?"0":"")+new Date(this.state.highAffluence[marker].endDate).getHours()+":"+((new Date(this.state.highAffluence[marker].endDate).getMinutes()<10)?"0":"")+new Date(this.state.highAffluence[marker].endDate).getMinutes()  )  }>

                      <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1.3} text={this.state.highAffluence[marker].name} textExplication={strings.lastName} placeholder={"Concert de Madonna"} onChangeText={(text)=>{text=text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''); var highAffluence=JSON.parse(JSON.stringify(this.state.highAffluence)); highAffluence[marker].name=text;     this.setState({highAffluence:highAffluence}) ;}}/>

                      <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1.3} keyboardType={"numeric"} text={this.state.highAffluence[marker].majoration.toString()} textExplication={strings.majoration} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');var highAffluence=JSON.parse(JSON.stringify(this.state.highAffluence)); highAffluence[marker].majoration=text;     this.setState({highAffluence:highAffluence}) }}/>

                      {<TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {if(this.state.disabledChange!=true){this.setState({selected:"startDate", selectedItem:marker},() =>{ this.selectDate.onPicker(); }) } }}>
                          <MavTextInput style={{marginTop:5}}  backgroundColor={style.backgroundColorContrast} textInputFlex={1.3} editable={false} text={(this.state.highAffluence[marker].startDate=="" || this.state.highAffluence[marker].startDate==undefined)?strings.selectDate:(((new Date(this.state.highAffluence[marker].startDate).getDate()<10)?"0":"")+new Date(this.state.highAffluence[marker].startDate).getDate()+"/"+((new Date(this.state.highAffluence[marker].startDate).getMonth()<9)?"0":"")+(new Date(this.state.highAffluence[marker].startDate).getMonth()+1)+"/"+new Date(this.state.highAffluence[marker].startDate).getFullYear()+" - "+((new Date(this.state.highAffluence[marker].startDate).getHours()<10)?"0":"")+new Date(this.state.highAffluence[marker].startDate).getHours()+":"+((new Date(this.state.highAffluence[marker].startDate).getMinutes()<10)?"0":"")+new Date(this.state.highAffluence[marker].startDate).getMinutes()   )} textExplication={strings.startDate}  />
                      </TouchableHighlight>} 

                      {<TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.state.disabledChange!=true){  this.setState({selected:"endDate",  selectedItem:marker},() =>{ this.selectDate.onPicker();  }) } }}>
                          <MavTextInput style={{marginTop:5}} disableLine={true} backgroundColor={style.backgroundColorContrast} textInputFlex={1.3} editable={false} text={(this.state.highAffluence[marker].endDate=="" || this.state.highAffluence[marker].endDate==undefined)?strings.selectDate:(((new Date(this.state.highAffluence[marker].endDate).getDate()<10)?"0":"")+new Date(this.state.highAffluence[marker].endDate).getDate()+"/"+((new Date(this.state.highAffluence[marker].endDate).getMonth()<9)?"0":"")+(new Date(this.state.highAffluence[marker].endDate).getMonth()+1)+"/"+new Date(this.state.highAffluence[marker].endDate).getFullYear()+" - "+((new Date(this.state.highAffluence[marker].endDate).getHours()<10)?"0":"")+new Date(this.state.highAffluence[marker].endDate).getHours()+":"+((new Date(this.state.highAffluence[marker].endDate).getMinutes()<10)?"0":"")+new Date(this.state.highAffluence[marker].endDate).getMinutes()   )} textExplication={strings.endDate}  />
                      </TouchableHighlight>}

                      {this.state.disabledChange!=true && <View style={{alignItems:"center",justifyContent:"center", marginTop:10, flexDirection:"row"}}>
                          <MavButton onlyButton={true} style={{marginRight:5}} text={strings.validate} onPress={()=>{if(marker=="new") this.addHighAffluenceInfo(); else this.validateHighAffluence(marker,i) }}/> 
                          <MavButton onlyButton={true} style={{marginLeft:5}} strokeMode={true} text={(marker=="new")?strings.cancel:strings.remove} onPress={()=>{ this.removeHighAffluence(marker,i) }}/>
                          
                      </View>}
          </MavViewMore>
        ))}



        {this.state.viewmajoOther==true && Object.keys(this.state.majoOther).map((marker,i) => (
          <MavViewMore ref={viewMO_ => {this['viewMO_' + i] = viewMO_;}}  onOpen={(text)=>{ this.closeOtherViewMore(i) }} text={(marker=="new")? strings.newMajo: (this.state.params.majoOther[marker].startDate+" > "+this.state.params.majoOther[marker].endDate)}   >

                      <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1.3} keyboardType={"numeric"} text={this.state.majoOther[marker].majoration.toString()} textExplication={strings.majoration} placeholder={"1.5"} onChangeText={(text)=>{text=text.replace(",",'.');var majoOther=JSON.parse(JSON.stringify(this.state.majoOther)); majoOther[marker].majoration=text;     this.setState({majoOther:majoOther}) }}/>

                      <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
                              if(this.state.disabledChange!=true){ 
                                this.alert.onAlert({title:strings.defineHour,textInput:{placeholder:"00:00",text:this.state.majoOther[marker].startDate,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                                    {text: strings.validate,onPress: (value) => {
                                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ) {var majoOther=JSON.parse(JSON.stringify(this.state.majoOther)); majoOther[marker].startDate=value;     this.setState({majoOther:majoOther})}
                                       else this.alert.onAlert({title:strings.errorHour,})
                                       
                                    }},
                                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                                    ],})
                                  }
                              }}>
                              <MavTextInput backgroundColor={style.backgroundColorContrast} editable={false} textInputFlex={1.3} text={(this.state.majoOther[marker].startDate!="")?this.state.majoOther[marker].startDate:strings.hourNotDefine} style={{marginTop:5}} textExplication={strings.startHour} />
                            </TouchableHighlight>


                            <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
                              if(this.state.disabledChange!=true){ 
                                this.alert.onAlert({title:strings.defineHour,textInput:{placeholder:"00:00",text:this.state.majoOther[marker].endDate,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                                    {text: strings.validate,onPress: (value) => {
                                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ) {var majoOther=JSON.parse(JSON.stringify(this.state.majoOther)); majoOther[marker].endDate=value;     this.setState({majoOther:majoOther})}
                                       else this.alert.onAlert({title:strings.endDate,})
                                       
                                    }},
                                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                                    ],})
                                  }
                              }}>
                              <MavTextInput backgroundColor={style.backgroundColorContrast}  editable={false} textInputFlex={1.3} text={(this.state.majoOther[marker].endDate!="")?this.state.majoOther[marker].endDate:strings.hourNotDefine} style={{marginTop:5}} textExplication={strings.endHour} />
                            </TouchableHighlight>

                            <Text style={{marginTop:25,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.okayDayList}</Text>

                            {true && strings.dayListComplete.map( (markerDayList,key) => ( 

                                <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1.5} disabledText={true}  arrow={true} arrowEnabled={(this.state.majoOther[marker].excludeDay==undefined || (this.state.majoOther[marker].excludeDay!=undefined && this.state.majoOther[marker].excludeDay[key]!=true))} textExplication={markerDayList}  onChange={()=>{var majoOther=JSON.parse(JSON.stringify(this.state.majoOther));  if(majoOther[marker].excludeDay==undefined) majoOther[marker].excludeDay={};  var exc=majoOther[marker].excludeDay;  if(exc[key]==true) delete exc[key]; else exc[key]=true; majoOther[marker].excludeDay=exc; this.setState({majoOther:majoOther})   }}/>

                            ))}

                      {this.state.disabledChange!=true && <View style={{alignItems:"center",justifyContent:"center", marginTop:10, flexDirection:"row"}}>
                          <MavButton onlyButton={true} style={{marginRight:5}} text={strings.validate} onPress={()=>{ if(marker=="new") this.addMajoOtherInfo(); else this.validateMajoOther(marker,i) }}/> 
                          <MavButton onlyButton={true} style={{marginLeft:5}} strokeMode={true} text={(marker=="new")?strings.cancel:strings.remove} onPress={()=>{ this.removeMajoOther(marker,i) }}/>
                      </View>}

          </MavViewMore>
        ))}



        


      






        
        


         <MavAlert ref={alert => {this.alert = alert;}}/>


         


         <MavSelectDate 
            minDate={(this.state.selected=="startDate")?new Date():((this.state.startDate!="")?new Date(this.state.startDate):new Date() )    }
            maxDate={(this.state.selected=="endDate")?"2100-01-01":((this.state.endDate!="")?new Date(this.state.endDate):new Date() )}

            onDateChange={dateTxt => { 
                var date=dateTxt;
                date=new Date(date[6]+date[7]+date[8]+date[9]+'-'+date[3]+date[4]+'-'+date[0]+date[1]+'T'+date[13]+date[14]+':'+date[16]+date[17]+':00')
                /*date=Date.parse(date)+date.getTimezoneOffset()*60000;*/
                console.log("this.state.selectedItem = "+this.state.selectedItem)
                console.log("date "+date)
                var highAffluence=JSON.parse(JSON.stringify(this.state.highAffluence)); 
                if(this.state.selected=="startDate") highAffluence[this.state.selectedItem].startDate=date; 
                else highAffluence[this.state.selectedItem].endDate=date; 
                this.setState({highAffluence:highAffluence})
                

            }} 

            ref={select => {this.selectDate = select;}}/>
       




      </ScrollView>

      {(this.state.highAffluenceList[this.state.highAffluenceList.length-1]!="new" && Object.keys(this.state.majoOther)[Object.keys(this.state.majoOther).length-1]!="new"  && this.state.disabledChange!=true) && <View style={{alignItems:"center",justifyContent:"center", marginTop:Dimensions.get('window').height * 0.05,marginBottom:Dimensions.get('window').height * 0.05}}>
          <MavButton text={strings.add} onlyButton={true}  onPress={()=>{ if(this.state.hourOnly==true) this.addMajoOther(); else this.addHighAffluence() }}/>

          </View>}

          </View>


      }</StyleContext.Consumer>
    );
  }
}



export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
