import React, { createContext, Component } from "react";
import {Dimensions, Platform} from "react-native";
/*import { eventEmitter} from "react-native-dark-mode";*/
const timer = require('react-native-timer');

var isDarkMode=false;
export const StyleContext = React.createContext()

export function styleInfo() {
 	var styleBase = require('./style');

  var urlInfo={}
  if(Platform.OS==="web" && window.location.search!=undefined){
      urlInfo=getParamsFromUrl(window.location)
  }



    var style=JSON.parse(JSON.stringify(styleBase.default));
    var i;
    if(style.mode=="light" && isDarkMode==true && style.defaultDark!=undefined){
    	var listD=Object.keys(style.defaultDark)
    	for(i=0;i<listD.length;i++){
    		style[listD[i]]=style.defaultDark[listD[i]]
    	}
    }
    if(urlInfo.themeMode!=undefined){
      style.mode=urlInfo.themeMode;
      var listD=Object.keys( (urlInfo.themeMode=="light")?style.themeLight:style.defaultDark)
      for(i=0;i<listD.length;i++){
        style[listD[i]]=style.defaultDark[listD[i]]
      }

    }
    if(urlInfo.backgroundColor!=undefined) style.backgroundColor=urlInfo.backgroundColor;
    if(urlInfo.color1!=undefined) style.color1=urlInfo.color1;
    if(urlInfo.backgroundColorContrast!=undefined) style.backgroundColorContrast=urlInfo.backgroundColorContrast;
    


    return style
}


export function getParamsFromUrl(url) {
    url = decodeURI(url);
    if (typeof url === 'string') {
        let params = url.split('?');
        if(params.length==1) return {}
        else {
          let eachParamsArr = params[1].split('&');
          let obj = {};
          if (eachParamsArr && eachParamsArr.length) {
              eachParamsArr.map(param => {
                  let keyValuePair = param.split('=')
                  let key = keyValuePair[0];
                  let value = keyValuePair[1];
                  obj[key] = value;
              })
          }
          return obj;
        }
      }
        else return {}


    }



export class StyleProvider extends Component {
	constructor(props) {
    super(props);

   	const style=styleInfo();



    this.state={...style}

    /*eventEmitter.on('currentModeChanged', newMode => {
       isDarkMode=newMode;
       const style=styleInfo();
       this.setState({...style})
	})

	*/
	/*
	timer.setInterval("name", () => {
       isDarkMode=!isDarkMode;
       const style=styleInfo();
       this.setState({...style})




    }, 1000000);*/


  }


  render() {
    return (
      <StyleContext.Provider value={this.state}>
        {this.props.children}
      </StyleContext.Provider>
    );
  }
}








