import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  Easing,
  Platform,
  TouchableHighlight,
  Animated,
  TextInput,
  Dimensions,
  Image,
  Clipboard, 
  Alert
} from 'react-native';
import {StyleContext,styleInfo} from '../styleC';

import info from '../info';
import firebase from './firebase';
import { getAuth,  } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue} from "firebase/database";
import strings from '../strings';
import MavAlert from './mavAlert';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import SortableList from './SortableList';
import Trajet from "./trajet"
import MavNavigationMinimize from './mavNavigationMinimize';


class Row extends Component {

  constructor(props) {
    super(props);


    this._active = new Animated.Value(0);
    console.log(this.props.data)

    
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      Animated.timing(this._active, {
        duration: 300,
        useNativeDriver: true,
        easing: Easing.bounce,
        toValue: Number(nextProps.active),
      }).start();
    }
  }

  render() {
   const {rowData, active} = this.props;

    return (
      <StyleContext.Consumer>{style =>
      <Animated.View style={{flexDirection: 'row',justifyContent: 'space-between',  marginLeft:"10%",marginRight:"10%",marginTop:10, marginBottom:10}}>
        
          {(Platform.OS!="web") && <View style={{backgroundColor:style.headerBackgroundColor,}} > 
            <Image source={require('../assets/more_grey.png')} style={{height:30,width:30,marginRight:10}} /> 
          </View>}
          <TouchableHighlight style={{height:30, justifyContent:"center"}}><Text style={{fontSize: style.textInputFontSize,color:style.textInputColor, textAlign:"left", width:((Platform.OS=="web")?("80%-60"):((Dimensions.get('window').width*0.8)-60)) }} onPress={() => { this.props.actionLink({action:"update",keyID:this.props.keyID})  }}  >{this.props.data.userDescription}</Text></TouchableHighlight>
        
          <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {
            
            this.props.actionLink({action:"remove",keyID:this.props.keyID}) 

          }} >
               <Image source={require('../assets/favorite_cancel.png')} style={{ width: 20, height: 20,marginTop:9 }}/>
          </TouchableHighlight>

      </Animated.View>

      }</StyleContext.Consumer>
      

      


      
      


      
    );
  }
}



export default class account extends Component {
  constructor(props) {
    super(props);
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    console.log("avant declanché")
    if(Platform.OS!="web"){
        if(params!=undefined && (params.view=="admin" || params.viewPickUp==true)) this.props.navigation.setOptions({ title: ((params.viewPickUp==true)?strings.pickUpLocation:strings.poi) })
          else this.props.navigation.setOptions({ title: strings.myFavorite })
    }   


    const style=styleInfo();

    const iconList=[
        {name:"no",icon:""},

        {name:"flight",icon:(style.mode=="dark")?require('../assets/favorite/flight_white.png'):require('../assets/favorite/flight_black.png')},
        {name:"train",icon:(style.mode=="dark")?require('../assets/favorite/train_white.png'):require('../assets/favorite/train_black.png')},
        {name:"home",icon:(style.mode=="dark")?require('../assets/favorite/home_white.png'):require('../assets/favorite/home_black.png')},
        {name:"bus",icon:(style.mode=="dark")?require('../assets/favorite/bus_white.png'):require('../assets/favorite/bus_black.png')},
        {name:"portfolio",icon:(style.mode=="dark")?require('../assets/favorite/portfolio_white.png'):require('../assets/favorite/portfolio_black.png')},
        {name:"restaurant",icon:(style.mode=="dark")?require('../assets/favorite/restaurant_white.png'):require('../assets/favorite/restaurant_black.png')},
        {name:"bed",icon:(style.mode=="dark")?require('../assets/favorite/bed_white.png'):require('../assets/favorite/bed_black.png')},
        {name:"library",icon:(style.mode=="dark")?require('../assets/favorite/library_white.png'):require('../assets/favorite/library_black.png')},
        {name:"foot",icon:(style.mode=="dark")?require('../assets/favorite/foot_white.png'):require('../assets/favorite/foot_black.png')},
        {name:"cinema",icon:(style.mode=="dark")?require('../assets/favorite/cinema_white.png'):require('../assets/favorite/cinema_black.png')},
        {name:"ferry",icon:(style.mode=="dark")?require('../assets/favorite/ferry_white.png'):require('../assets/favorite/ferry_black.png')},
        {name:"bar",icon:(style.mode=="dark")?require('../assets/favorite/bar_white.png'):require('../assets/favorite/bar_black.png')},
        {name:"caddy",icon:(style.mode=="dark")?require('../assets/favorite/caddy_white.png'):require('../assets/favorite/caddy_black.png')},
        {name:"heart",icon:(style.mode=="dark")?require('../assets/favorite/heart_white.png'):require('../assets/favorite/heart_black.png')},
        {name:"park",icon:(style.mode=="dark")?require('../assets/favorite/park_white.png'):require('../assets/favorite/park_black.png')},
        {name:"attraction",icon:(style.mode=="dark")?require('../assets/favorite/attraction_white.png'):require('../assets/favorite/attraction_black.png')},
        {name:"hospital",icon:(style.mode=="dark")?require('../assets/favorite/hospital_white.png'):require('../assets/favorite/hospital_black.png')},
        {name:"doctor",icon:(style.mode=="dark")?require('../assets/favorite/doctor_white.png'):require('../assets/favorite/doctor_black.png')},
        {name:"pharmacy",icon:(style.mode=="dark")?require('../assets/favorite/pharmacy_white.png'):require('../assets/favorite/pharmacy_black.png')},
        {name:"briefcase",icon:(style.mode=="dark")?require('../assets/favorite/briefcase_white.png'):require('../assets/favorite/briefcase_black.png')},
        {name:"university",icon:(style.mode=="dark")?require('../assets/favorite/university_white.png'):require('../assets/favorite/university_black.png')},
        {name:"hatGrad",icon:(style.mode=="dark")?require('../assets/favorite/hatGrad_white.png'):require('../assets/favorite/hatGrad_black.png')},
        {name:"vehiculeR",icon:(style.mode=="dark")?require('../assets/favorite/vehiculeR_white.png'):require('../assets/favorite/vehiculeR_black.png')},

      ] 

    var favoritePickUp=[]; 
    if(params!=undefined && params.favoritePickUp!=undefined){
      var i; 
      var lisFPU=Object.keys(params.favoritePickUp);
      for(i=0;i<lisFPU.length;i++){

          if(params.favoritePickUp[lisFPU[i]].pickUpLocationDescription!=undefined){
              var elemd=params.favoritePickUp[lisFPU[i]];
              
              

              if(elemd.icon && iconList.findIndex(x => x.name === elemd.icon)>0 && iconList[iconList.findIndex(x => x.name === elemd.icon)]!=undefined){
                elemd.iconInfo=iconList[iconList.findIndex(x => x.name === elemd.icon)].icon
                
              } 


              console.log(elemd)

              favoritePickUp.push(elemd)
          }
      }
    
    }




    this.state = {
      admin:false,
      params:params,
      favorite:{},
      favoriteGlobal:{},
      disableNavigation:(params!=undefined && params.disableNavigation==true && Platform.OS=="web")?true:false,
      pickUpLocation:(params!=undefined && params.pickUpLocation==true)?true:false,
      favoritePickUp:favoritePickUp,
      viewMode:(params!=undefined && params.viewPickUp==true)?"pickup":"normal",
      descriptionName:"",
      defineFakeCar:(params!=undefined && params.defineFakeCar==true)?true:false,
      uuid:"",
      actualFavoriteIndex:"",
      descriptionPlace:"",
      pickUpLocationDescription:"",
      favoritePlaceWaiting:"",
      favoritePlaceWaitingDest:"",
      defineFakeCarSelect:"", 
      selected:-1,
      iconSelected:0,
      iconList:iconList
    };
  }


  componentDidMount() {

    if (this.state.viewMode!="pickup" && getAuth(firebase).currentUser != null && this.state.defineFakeCar!=true) {
      get(child(ref(getDatabase(firebase)), '/users/' + getAuth(firebase).currentUser.uid)).then(value => {
        var val = value.val();
        if(val!=null) {

          if(val.driver==true && (this.state.params!=undefined && this.state.params.view=="admin") ){
            this.setState({admin:true});
            get(child(ref(getDatabase(firebase)), '/favorite')).then(value2 => {
                var val2 = value2.val();
                var val3={}
                if(val2!=null){

                  var i;
                  var list=Object.keys(val2)
                  for(i=0;i<list.length;i++){
                        if(val2[list[i]].index==undefined) val2[list[i]].index=i;
                  }
                   
                  

                  Object.keys(val2).sort(function(a, b){
      
                      if(val2[a] < val2[b]) return -1;
                      if(val2[a] > val2[b]) return 1;
                      return 0;
                  });

                  var val3={}
                  var list=Object.keys(val2).sort( function ( a, b ) { return val2[a].index - val2[b].index  } )

                  for(i=0;i<list.length;i++){
                    val3[list[i]]=val2[list[i]];
                  }

                }
                

                this.setState({favoriteGlobal:val3, selected:1})

            })
          }
          if(val.favorite!=undefined) {

                var i;
                var list=Object.keys(val.favorite)
                for(i=0;i<list.length;i++){
                      if(val.favorite[list[i]].index==undefined) val.favorite[list[i]].index=i;
                }

                console.log(val.favorite)
                
                Object.keys(val.favorite).sort(function(a, b){
    
                    if(val.favorite[a] < val.favorite[b]) return -1;
                    if(val.favorite[a] > val.favorite[b]) return 1;
                    return 0;
                });

                var val3={}
                var list=Object.keys(val.favorite).sort( function ( a, b ) { return val.favorite[a].index - val.favorite[b].index  } )

                for(i=0;i<list.length;i++){
                  val3[list[i]]=val.favorite[list[i]];
                }

                this.setState({favorite:val3,selected:0})
            

          }
          else this.setState({selected:0}) 
         

        }
        
      });
    }
  }

  onFavoritePlace = data => {
    console.log(data)

    if(this.state.defineFakeCar!=true) this.setState({viewMode:"place",favoritePlaceWaiting:data})
    else if(this.state.defineFakeCarSelect=="start") this.setState({viewMode:"normal",favoritePlaceWaiting:data},() =>{this.calculatePath()})
    else this.setState({viewMode:"normal",favoritePlaceWaitingDest:data},() =>{this.calculatePath()})
  } 



  angle(cx, cy, ex, ey) {
    var dy = ey - cy;
    var dx = ex - cx;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    return theta;

  }



   decode(t, e) {
          for (
            var n,
              o,
              u = 0,
              l = 0,
              r = 0,
              d = [],
              h = 0,
              i = 0,
              a = null,
              c = Math.pow(10, e || 5);
            u < t.length;

          ) {
            (a = null), (h = 0), (i = 0);
            do
              (a = t.charCodeAt(u++) - 63), (i |= (31 & a) << h), (h += 5);
            while (a >= 32);
            (n = 1 & i ? ~(i >> 1) : i >> 1), (h = i = 0);
            do
              (a = t.charCodeAt(u++) - 63), (i |= (31 & a) << h), (h += 5);
            while (a >= 32);
            (o = 1 & i ? ~(i >> 1) : i >> 1), (l += n), (r += o), d.push([
              l / c,
              r / c,
            ]);
          }
          return (d = d.map(function(t) {
            return { latitude: t[0], longitude: t[1], lat:t[0], lng:t[1] };
          }));
  }


  calculatePath = data => {

    if(this.state.favoritePlaceWaitingDest!="" && this.state.favoritePlaceWaiting!=""){
      var uur='https://maps.googleapis.com/maps/api/directions/json?origin='+this.state.favoritePlaceWaiting.userDescription+'&destination='+this.state.favoritePlaceWaitingDest.userDescription+' &key='+info.googleMapsKey;
      console.log(uur)
       fetch(uur)
        .then(response => response.json())
        .then(responseJson => {
          var i;
          var coor=this.decode(responseJson.routes[0].overview_polyline.points)
          var duration=(responseJson.routes[0].legs[0].duration.value*1000)/coor.length;
          for(i=1;i<coor.length;i++){
            coor[i]={
              latitude:coor[i].latitude,
              longitude:coor[i].longitude,
              duration:duration,
              rotation:this.angle(coor[i-1].latitude,coor[i-1].longitude,coor[i].latitude,coor[i].longitude)
            }
          }
      this.copyToClipboard(JSON.stringify(coor)+",")
      this.alert.onAlert({title:strings.copiedClipboard }) 

      
      

     })

    }

  }

  copyToClipboard(text){
  Clipboard.setString(text);

}



  _renderRow = ({key, index, data, active}) => {
    return <Row data={data} keyID={key} index={index} active={active} actionLink={this._actionLink} />
  }

  _actionLink= (info) => {

    console.log(info)
    if(info.action=="remove"){
        var value=(this.state.selected==0)?this.state.favorite:this.state.favoriteGlobal;
        delete value[info.keyID];
        var i;
        var list=Object.keys(value)
        for(i=0;i<list.length;i++){
          value[list[i]].index=i;
        }

        if(this.state.selected==0){

          this.setState({favorite:{},selected:-1  },() =>{
            this.setState({favorite:value,selected:0  },() =>{
              this.setFavorite();
              })
          })

        }
        else {


          this.setState({favoriteGlobal:{},selected:-1   },() =>{
            this.setState({favoriteGlobal:value,selected:1  },() =>{
              this.setFavoriteGlobal();
              })
          })

        }
        
        
        
    } 
    else if(info.action=="update"){


      var value=(this.state.selected==0)?this.state.favorite:this.state.favoriteGlobal;
      var value2=JSON.parse(JSON.stringify(value));


      var iconIndex=0;
      if(value2[info.keyID].icon!=undefined) iconIndex=this.state.iconList.findIndex(x => x.name === value2[info.keyID].icon);
      if(iconIndex<0) iconIndex=0;
      console.log("iconIndex "+iconIndex)

      value2[info.keyID].userDescription=value[info.keyID].description;
      this.setState({descriptionName: value[info.keyID].userDescription,
        descriptionPlace:value[info.keyID].descriptionPlace,
        pickUpLocationDescription:value[info.keyID].pickUpLocationDescription,
        favoritePlaceWaiting:value2[info.keyID], 
        viewMode:"place",
        iconSelected:iconIndex, 
        actualFavoriteIndex:value2[info.keyID].index, 
        uuid:info.keyID,

      })
      







    }
    
    

    
  }

  setFavoriteGlobal(){
    console.log("setFavoriteGlobal")
    const fireInfo={} 
    fireInfo["favorite"]=this.state.favoriteGlobal
    update(ref(getDatabase(firebase)), fireInfo)
  }

  setFavorite(){
    console.log("setFavorite")
    const fireInfo={} 
    fireInfo['users/' + getAuth(firebase).currentUser.uid+"/favorite"]=this.state.favorite
    update(ref(getDatabase(firebase)), fireInfo)
  
  }
  

  render() {
    return (
      <StyleContext.Consumer>{style =>
      <View style={{flex:1,backgroundColor:style.backgroundColor}}>
      {this.state.viewMode=="normal" && <View style={{flex:1}}>
        {(Platform.OS=="web" && this.state.disableNavigation!=true) && <MavNavigationMinimize clickLeft={() => { this.state.params.goBack();  }} left={strings.return} title={ strings.myFavorite } />}

        <View style={{flex:1,marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor}}>


            {this.state.defineFakeCar==true && <View style={{flex: 1,backgroundColor: style.backgroundColor}}>


              <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={() => {
                  this.setState({defineFakeCarSelect:"start"})
              
                  if(Platform.OS=="web") this.setState({viewMode:"Trajet"})
                    else this.props.navigation.navigate('Trajet', {onFavoritePlace: this.onFavoritePlace, });
                  }}>
                  <MavTextInput style={{marginTop:5}} textInputFlex={1.5} text={(this.state.favoritePlaceWaiting.userDescription==undefined || this.state.favoritePlaceWaiting.userDescription=="")?strings.selectFrom:this.state.favoritePlaceWaiting.userDescription} textExplication={strings.start} editable={false} />
            </TouchableHighlight>

            <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={() => {
                  this.setState({defineFakeCarSelect:"dest"})
              
                  if(Platform.OS=="web") this.setState({viewMode:"Trajet"})
                    else this.props.navigation.navigate('Trajet', {onFavoritePlace: this.onFavoritePlace, });
                  }}>
                  <MavTextInput style={{marginTop:5}} textInputFlex={1.5} text={(this.state.favoritePlaceWaitingDest.userDescription==undefined || this.state.favoritePlaceWaitingDest.userDescription=="")?strings.selectFrom:this.state.favoritePlaceWaitingDest.userDescription} textExplication={strings.description} editable={false} />
            </TouchableHighlight>




            </View>}


            {(this.state.viewMode=="normal" &&  this.state.selected==0) && <SortableList
                style={{height:Dimensions.get('window').height-(5*style.buttonHeight)-30}}
                contentContainerStyle={{}}
                data={this.state.favorite}
                renderRow={this._renderRow} 
                onReleaseRow={(val,currentOrder)=>{ 
                    var val2 = (this.state.selected==0)?this.state.favorite:this.state.favoriteGlobal
                    var i;
                    for(i=0;i<currentOrder.length;i++){
                          val2[currentOrder[i]].index=i;
                    }
                    if(this.state.selected==0) this.setState({favorite:val2},() =>{this.setFavorite()})
                    else this.setState({favoriteGlobal:val2},() =>{this.setFavoriteGlobal()})
                      


                }} 

            />}

            {(this.state.viewMode=="normal" &&  this.state.selected==1 ) && <SortableList
                style={{height:Dimensions.get('window').height-(5*style.buttonHeight)-30}}
                contentContainerStyle={{}}
                data={this.state.favoriteGlobal}
                renderRow={this._renderRow} 
                onReleaseRow={(val,currentOrder)=>{ 
                    var val2 = (this.state.selected==0)?this.state.favorite:this.state.favoriteGlobal
                    var i;
                    for(i=0;i<currentOrder.length;i++){
                          val2[currentOrder[i]].index=i;
                    }
                    if(this.state.selected==0) this.setState({favorite:val2},() =>{this.setFavorite()})
                    else this.setState({favoriteGlobal:val2},() =>{this.setFavoriteGlobal()})
                      


                }} 

            />}


          {this.state.defineFakeCar!=true && <View style={{alignItems:"center",justifyContent:"center", marginTop:30,marginBottom:30}}> 
             <MavButton text={strings.add} onlyButton={true} onPress={()=>{ 
                  this.setState({viewMode:"place"})
                  
          }}/>

        </View>}
          
          </View>

      </View>}

      {this.state.viewMode=="place" && <View style={{flex: 1,backgroundColor: style.backgroundColor}}>
           
         
                
          <View style={{marginTop: 50,backgroundColor:style.backgroundColor}}>

            <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={() => {
              
              if(Platform.OS=="web") this.setState({viewMode:"Trajet"})
              else this.props.navigation.navigate('Trajet', {onFavoritePlace: this.onFavoritePlace, });
            }}>
                  <MavTextInput style={{marginTop:5}} textInputFlex={1.5} text={(this.state.favoritePlaceWaiting.userDescription==undefined || this.state.favoritePlaceWaiting.userDescription=="")?strings.selectFrom:this.state.favoritePlaceWaiting.userDescription} textExplication={strings.adress} editable={false} />
            </TouchableHighlight>



            <MavTextInput style={{marginTop:5}} textInputFlex={1.5} text={this.state.descriptionName} textExplication={strings.placeName} placeholder={(this.state.selected==0)?"Maison":"Aéroport T2"} onChangeText={(text)=>{this.setState({descriptionName:text})}}/>

            {(this.state.selected==1) && <MavTextInput style={{marginTop:5}} textInputFlex={1.5} text={this.state.descriptionPlace} textExplication={strings.description} placeholder={"Vol internationaux"} onChangeText={(text)=>{this.setState({descriptionPlace:text})}}/>}

            {(this.state.selected==1 && this.state.pickUpLocation==true) && <MavTextInput style={{marginTop:5}} textInputFlex={1.5} text={this.state.pickUpLocationDescription} multiline={true} textExplication={strings.pickUpLocation} placeholder={"Au rez-de-chaussée, en face de la porte T2E1"} onChangeText={(text)=>{this.setState({pickUpLocationDescription:text})}}/>}




            {(this.state.selected==1) && <Text allowFontScaling={false} style={{fontSize: style.textInputFontSize,color:style.textInputColor, marginLeft:"10%",marginTop:10, marginBottom:10}}>{strings.iconChoice+" :"}</Text>}

            <View style={{marginLeft:"10%",width:"80%", flexDirection: 'row', flex: 1,  flexWrap: 'wrap', alignItems: 'flex-start', marginBottom:10+50* (this.state.iconList.length/Math.trunc( (Dimensions.get('window').width*0.8*((this.state.disableNavigation==true)?0.5:1))/50))}}> 

                 {(this.state.selected==1) && this.state.iconList.map((rowData,i)=>(

                 <TouchableHighlight style={{width:50,height:50,marginRight:5,alignItems:"center",justifyContent:"center",borderRadius:50, backgroundColor:(i==this.state.iconSelected)?style.color1:style.backgroundColor}}
                  underlayColor="rgba(0,0,0,0)"
                  onPress={() => {
                    this.setState({iconSelected:i})
                  }}> 

                  <Image source={rowData.icon} style={{height:30,width:30,resizeMode:"contain"}}/> 
                      

                  </TouchableHighlight>

                ))}
              



              
            </View>



            {(this.state.favoritePlaceWaiting!="" && this.state.descriptionName!="") && <View style={{alignItems:"center",justifyContent:"center", marginTop:40}}>
            <MavButton text={strings.validate} onlyButton={true} onPress={()=>{ 
                var value=(this.state.selected==0)?this.state.favorite:this.state.favoriteGlobal;
                var uuid=(this.state.uuid!="")?this.state.uuid:generateUUID();
                value[uuid]=this.state.favoritePlaceWaiting;
                value[uuid].userDescription=this.state.descriptionName;

                if(this.state.selected==1){
                  value[uuid].icon=this.state.iconList[this.state.iconSelected].name;
                  if(this.state.descriptionPlace!=undefined && this.state.descriptionPlace!="") value[uuid].descriptionPlace=this.state.descriptionPlace;
                  else value[uuid].descriptionPlace=null;
                  if(this.state.pickUpLocationDescription!=undefined) value[uuid].pickUpLocationDescription=this.state.pickUpLocationDescription;
                  else value[uuid].pickUpLocationDescription=null;
                }

                value[uuid].index=(this.state.actualFavoriteIndex!="")?this.state.actualFavoriteIndex:Object.keys(value).length-1;
                this.setState({actualFavoriteIndex:"",uuid:"", favoritePlaceWaiting:"",iconSelected:0,pickUpLocationDescription:"",pickUpLocationDescription:"", userDescription:"",descriptionPlace:"", descriptionName:"", viewMode:"normal",})


                if(this.state.selected==0){

                    this.setState({favorite:{},selected:-1   },() =>{
                      this.setState({favorite:value,selected:0  },() =>{
                        this.setFavorite();
                        })
                    })

                  }
                  else {


                    this.setState({favoriteGlobal:{},selected:-1   },() =>{
                      this.setState({favoriteGlobal:value,selected:1  },() =>{
                        this.setFavoriteGlobal();
                        })
                    })

                  }


                


              }}/>
              </View> }
              <View style={{alignItems:"center",justifyContent:"center", marginTop:(this.state.favoritePlaceWaiting!="" && this.state.descriptionName!="")?10:40}}> 
                   <MavButton text={strings.cancel} strokeMode={true} onlyButton={true} onPress={()=>{ 
                      this.setState({actualFavoriteIndex:"",uuid:"", favoritePlaceWaiting:"",iconSelected:0,pickUpLocationDescription:"",userDescription:"",descriptionPlace:"", descriptionName:"", viewMode:"normal",})
                   }}/>
             </View>      
            
          </View>
        </View>}


        {this.state.viewMode=="Trajet" && <Trajet params={{  goBack:() => {this.setState({viewMode:"place"}); }, onFavoritePlace: this.onFavoritePlace }}/>}



        {this.state.viewMode=="pickup" && <ScrollView>

          {(Platform.OS=="web" && this.state.disableNavigation!=true) && <MavNavigationMinimize clickLeft={() => { this.state.params.goBack();  }} left={strings.return} title={ strings.pickUpLocation } />}
          {(this.state.favoritePickUp.length==0) && <Text style={{marginTop:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.noElement}</Text>}
          {(this.state.favoritePickUp.length>0) && <View> 

              {this.state.favoritePickUp.map((rowData,i)=>(
                  <View style={{marginLeft:"10%",marginRight:"10%",marginTop:20}}>
                      <View style={{flexDirection: 'row',alignItems:"center",  marginBottom:10}}>
                        {(rowData.iconInfo!=undefined) && <Image source={rowData.iconInfo} style={{height:30,width:30,resizeMode:"contain", marginRight:10}}/> }
                        
                        <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*1.2,fontWeight: 'bold'}}>{rowData.userDescription}</Text>
                      </View>
                      <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*1,}}>{rowData.pickUpLocationDescription}</Text>
                      
                  </View>
              ))}

          </View>}

        </ScrollView>}


      <MavAlert ref={alert => {this.alert = alert;}}/>

  </View>
  }</StyleContext.Consumer>
    );
  }
}




export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
