import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, DirectionsRenderer, Polyline, Marker,Circle, Polygon, AnimatedRegion } from "react-google-maps";
import info from '../info'
import style from '../style';
import {View,Dimensions} from 'react-native';



const GoogleMapContainer = withGoogleMap(props => (
  <GoogleMap {...props}  ref="map" 
   

  onClick={ (value)=>{ if( props.onLongPress!=undefined){ var obj={lat:value.latLng.lat(), lng:value.latLng.lng(), web:true }; props.onLongPress(obj) }; } } ref={props.handleMapMounted}  zoom={props.zoom} />
));






class MapViewPolyline extends Component {
  render() {
    return <Polyline {...this.props} />;
  }
}

class MapViewCircle extends Component {
  render() {
    return <Circle {...this.props} />;
  }
}

class MapViewMarker extends Component {
  render() {
    const { description, title, coordinate, ...rest } = this.props;
    return (
      <Marker
        {...rest}
        title={description ? `${title}\n${description}` : title}
        position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
        style={{width:40, height:40}}
      />
    );
  }
}


class MapViewPolygon extends Component {
  render() {
    return <Polygon {...this.props} />;
  }
}






class MapView extends Component {




 render() {





  var centerProps = this.props.region
      ? {
          center: {
            lat: this.props.region.latitude,
            lng: this.props.region.longitude,

          },
        }
      : (center
      ? { center }
      : {
          defaultCenter: {
            lat: this.props.initialRegion.latitude,
            lng: this.props.initialRegion.longitude,
          },
        });




var forceZoom=this.props.defaultZoom;
forceZoom=Math.round(Math.log(360 / this.props.region.longitudeDelta) / Math.LN2)






 

  return (
<View style={this.props.style}>
        <GoogleMapContainer

          defaultOptions={{
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: false,
      panControl: false,
      zoomControl: (this.props.enableZoom==true || this.props.onLongPress!=undefined)?true:false,
      rotateControl: false,
      fullscreenControl: false,
    }}
          {...centerProps}
          containerElement={<div style={{ height: (this.props.height!=undefined)?this.props.height:'100%' }} />}
          mapElement={<div style={{ height: (this.props.height!=undefined)?this.props.height:'100%' }} />}
          onLongPress={this.props.onLongPress}
          

          ref="map"
          


          zoom={forceZoom}>
          {this.props.children}
        </GoogleMapContainer>
      </View>

)}
}
MapView.Polyline = MapViewPolyline;
MapView.Marker = MapViewMarker;
MapView.Polygon = MapViewPolygon;
MapView.Circle = MapViewCircle;

export default MapView;



