/**
 * @format


*/

import { AppRegistry, Platform } from 'react-native';
import Main from './components/containerApp';
import {name as appName} from './app.json';



AppRegistry.registerComponent(appName, () => Main);

if (Platform.OS === 'web') {
	AppRegistry.runApplication(appName, {
		rootTag: document.getElementById('main'),
	});
}

/*
import {name as appName} from './app.json';
import Main from './components/main';
import MavSelectDate from './components/mavSelectDate';
import { createSwitchNavigator } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import { AppRegistry, Platform } from 'react-native';
import React from 'react';
import {  createStackNavigator,createAppContainer } from 'react-navigation';
import ReactDOM from 'react-dom';

var stackList={
	Main: {screen: Main,},
	MavSelectDate: {screen: MavSelectDate}

}



const MyNavigator = createSwitchNavigator(stackList);

const Appli = createBrowserApp(MyNavigator);


AppRegistry.registerComponent(appName, () => Appli);
AppRegistry.runApplication(appName, {
		rootTag: document.getElementById('main'),
	});



const Stack = createStackNavigator(stackList,{});
const Appli = createAppContainer(Stack);


ReactDOM.render(<Main />, document.getElementById('main'));

*/
