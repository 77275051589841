import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  Alert,
 
  Dimensions,
  Image,
} from 'react-native';
import style from '../style'; 


export default class SortableList extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      drawerVisible:false, 
    }; 


  }



  componentDidMount(){ 
    console.log("SortableList mount")
    



    

  }


  

  render() {



    return (
      <ScrollView style={{width:"100%"}}> 

      {(this.props.data!=undefined && this.props.data.length>0) && this.props.data.map((rowData,i)=>(<View>{this.props.renderRow({data:rowData, key:rowData.index,index:i} )}</View>))}
      
      
             {(this.props.data!=undefined && !(this.props.data.length>0)) && Object.keys(this.props.data).map((rowData,i)=>(
        <View>{this.props.renderRow({data:this.props.data[rowData], key:rowData,index:i, } )}</View>
             ))}





      </ScrollView>
    );
  }
}
