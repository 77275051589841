import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableHighlight,
  Dimensions,
  TextInput,
  ActivityIndicator,
  Platform,
  Image,
  Linking,
  ScrollView,
  Keyboard,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';

import firebase from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";


import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MavAlert from './mavAlert';
import MavNavigationMinimize from './mavNavigationMinimize';
import MavInfoBubble from './mavInfoBubble';
import MavViewMore from './mavViewMore';

class HeaderRight extends Component {
render() {
    return (
      <StyleContext.Consumer>{style => 

         <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { this.props.contactMessage(this.props.senderPhone);
          

          }}>
       <Image
        source={(style.mode=="dark")?require('../assets/driver_phone_light.png'):require('../assets/driver_phone_dark.png')}
        style={{ width: 25, height: 25,marginRight:10 }}
      />
      </TouchableHighlight>
      }</StyleContext.Consumer>

      )
  }
}




export default class contact extends Component {
constructor(props) {
    super(props);

    var params=(this.props.route!=undefined && this.props.route.params!=undefined)?this.props.route.params:this.props.params;


    this.state = {
      loading:true,
      prefillMode:params.prefillMode,
      prefillAdmin:params.prefillAdmin,
      partner:params.partner,
      prefillList:{}, 
      outRace:params.outRace,
      service:params.service, 
      raceID:params.raceID,
      sender:params.sender,
      senderID:params.senderID,
      driverID:params.driverID,
      driverName:params.driverName,
      adminMode:(params.adminMode==true)?true:false,
      noSend:(params.noSend==true)?true:false,
      keyboardOffset:0,
      sendMessage:"",
      messages:{},
      viewContactPlatform:(params.contactUs!=undefined)?true:false,



    };


    
  }

  sendMessage(forceMess){
    
    var date=Date.parse(new Date());

    const fireInfo={} 
    fireInfo["/chat/"+this.state.raceID+"/messages/"+date+"/text"]=(forceMess!=undefined)?forceMess:this.state.sendMessage
    fireInfo["/chat/"+this.state.raceID+"/messages/"+date+"/sender"]=getAuth(firebase).currentUser.uid;
    fireInfo["/chat/"+this.state.raceID+"/messages/"+date+"/receiver"]=this.state.senderID

    if(this.state.adminMode==true) {
      fireInfo["/chat/"+this.state.raceID+"/messages/"+date+"/admin"]=true;
      fireInfo["/chat/"+this.state.raceID+"/messages/"+date+"/outRace"]=true;
    }

    if(this.state.outRace==true) fireInfo["/chat/"+this.state.raceID+"/messages/"+date+"/outRace"]=true;
    
    update(ref(getDatabase(firebase)), fireInfo)

    if(forceMess==undefined) this.setState({sendMessage:""});

  }
  
    componentDidMount(){ 

      if(Platform.OS!="web" && this.state.adminMode!=true && this.props.route!=undefined && this.props.route.params!=undefined && this.props.route.params.contactMessage!=undefined) this.props.navigation.setOptions({title:this.state.sender , headerRight: props => <HeaderRight contactMessage={(data) => {
        
        this.props.route.params.contactMessage(data); this.props.navigation.goBack(); }} senderPhone={this.props.route.params.senderPhone} {...props} /> })


      this.keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            this._keyboardDidShow.bind(this),
        );
        this.keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            this._keyboardDidHide.bind(this),
        );
      /*si je suis en prefilladmin ou que je suis chauffeur alors ok */
      if(this.state.prefillAdmin==true || this.state.partner==true) get(child(ref(getDatabase(firebase)), "/prefill/chat/")).then(valuePrefill => { 

            var val2 = valuePrefill.val();
            console.log(val2)
            if(val2!=null){

                var i;
                var list=Object.keys(val2)
                for(i=0;i<list.length;i++){
                      if(val2[list[i]].index==undefined) val2[list[i]].index=i;
                }
                 
                var val3={}

                Object.keys(val2).sort(function(a, b){
                    if(val2[a] < val2[b]) return -1;
                    if(val2[a] > val2[b]) return 1;
                    return 0;
                });

                var list=Object.keys(val2).sort( function ( a, b ) { return val2[a].index - val2[b].index  } )

                for(i=0;i<list.length;i++){
                  val3[list[i]]=val2[list[i]];
                }
                
                this.setState({prefillList:val3})
                
          }




      })
      
      if(this.state.prefillAdmin!=true) onValue(ref(getDatabase(firebase), "/chat/"+this.state.raceID+"/messages"), (value) => { 

          var val=value.val();
          if(val==null) val={}

            if(this.state.service!=undefined){
              val=Object.assign(val, this.state.service)
            }

          

          const ordered = {};
          Object.keys(val).sort().forEach(function(key) {
            ordered[key] = val[key];
          });

          this.setState({messages:ordered, loading:false})

        })

        



      

      

  }

  componentWillUnmount(){ 
      

      this.keyboardDidShowListener.remove();
      this.keyboardDidHideListener.remove();

      
  }

  savePrefill(id,i){

      const fireInfo={} 
      var prefillList=JSON.parse(JSON.stringify(this.state.prefillList));  
      if(this.state.prefillList[id].new==true) {
        this.setState({addPrefill:true})
        /*je supprime le new si existant */
        delete prefillList[id].new
        
      }
      if(this.state.prefillList[id].modifIndex!=undefined){
          /*si l'index a été modifié*/
          delete prefillList[id].modifIndex
          var j,k;
          var testVar=false;
          
          for(k=((prefillList[id].index>prefillList[id].oldIndex)?(prefillList[id].oldIndex+1):(prefillList[id].index));k<((prefillList[id].index>prefillList[id].oldIndex)?(prefillList[id].index+1):(prefillList[id].oldIndex));k++){                                 
           var list=Object.keys(prefillList);
              for(j=0; j<list.length;j++){
                if(id!=list[j] && testVar==false){
                    if(prefillList[list[j]].index==k) {
                      if(prefillList[id].index>prefillList[id].oldIndex) prefillList[list[j]].index=(k-1);
                      else prefillList[list[j]].index=(k+1);
                      fireInfo["/prefill/chat/"+list[j]+"/index"]=prefillList[list[j]].index;
                      testVar=true; 
                }
              }
              testVar=false;
            }
          }
                                            



          delete prefillList[id].oldIndex
      }
      if(prefillList[id].modif!=undefined) delete prefillList[id].modif

      

      this.setState({prefillList:prefillList })
      /*j'envoi sur firebase*/
      fireInfo["/prefill/chat/"+id]=prefillList[id]; 
      update(ref(getDatabase(firebase)), fireInfo)

      /*je close */
      this['viewPrefill_' + i].close()


  }

  removePrefill(id,i){
      if(this.state.prefillList[id].new==undefined){
        /*je supprime sur firebase*/
        const fireInfo={} 
        fireInfo["/prefill/chat/"+id]=null
        update(ref(getDatabase(firebase)), fireInfo)
      }
      else this.setState({addPrefill:true})

      this['viewPrefill_' + i].close()
      /*je supprime de mes infos */
      var prefillList=JSON.parse(JSON.stringify(this.state.prefillList));  
      delete prefillList[id]
      this.setState({prefillList:prefillList })
      
  }

   _keyboardDidShow(event) {
        this.setState({
            keyboardOffset: (Platform.OS === 'ios')?event.endCoordinates.height:0,
        })
    }

    _keyboardDidHide() {
        this.setState({
            keyboardOffset: 0,
        })
    }
    
    
    
  render() {
    return (
      <StyleContext.Consumer>{style => 
      <View style={{flex:1,backgroundColor:style.backgroundColor}}>
      <View style={{flex:1,backgroundColor:style.backgroundColor,marginBottom:Platform.OS === 'ios' ? ((Dimensions.get('window').height === 812 || Dimensions.get('window').height === 896 || Dimensions.get('window').width === 896 || Dimensions.get('window').width === 812)? ((this.state.keyboardOffset==0)?30:10):10):10}}>
      {(Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax ) && <MavNavigationMinimize clickLeft={() => { var params=(this.props.navigation!=undefined)?this.props.navigation:this.props.params; params.goBack();  }} left={strings.return} title={ this.state.sender } />}

        {this.state.prefillMode!=true && <ScrollView style={{marginBottom:70}}> 



          {this.state.viewContactPlatform==true && <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => {console.log("contact"); var params=(this.props.navigation!=undefined)?this.props.navigation:this.props.params; var paramsBis=(this.props.route!=undefined)?this.props.route.params:this.props.params; params.goBack(); paramsBis.contactUs();  }}>
            <Text style={{textAlign: 'center', color: style.textInputColor,fontSize: style.textInputFontSize, marginTop:5,marginLeft:"5%",marginRight:"5%" }}>{strings.preferContactPlatform}</Text>
          </TouchableHighlight>}

          { this.state.adminMode!=true &&  <Text style={{textAlign: 'center', color: style.textInputColor,fontSize: style.textInputFontSize*0.8, marginTop:5,marginLeft:"10%",marginRight:"10%" }}>{strings.readyToContact}</Text>}


          {this.state.loading==true && <ActivityIndicator  size="large" color={style.color1} /> }


        {Object.keys(this.state.messages).map(marker => (

          <View>
              {(this.state.messages[marker].service!=true) && <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { var mess=this.state.messages; mess[marker].viewDetail=(mess[marker].viewDetail==true)?false:true; this.setState({messages:mess})  }} >
              <View>
              <View style={{marginLeft:((this.state.messages[marker].sender==getAuth(firebase).currentUser.uid) ||(this.state.driverID!=undefined && this.state.messages[marker].sender==this.state.driverID) )?( (Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax )?"32%":( Dimensions.get('window').width*0.33-5)):5, marginTop:10,width:"67%", backgroundColor:((this.state.messages[marker].sender==getAuth(firebase).currentUser.uid) || (this.state.driverID!=undefined && this.state.messages[marker].sender==this.state.driverID))?style.color1:style.backgroundColorContrast, borderRadius: 15}}>
                  <Text style={{fontSize: style.textInputFontSize, marginTop:10, marginLeft:12,marginRight:12, marginBottom:10,color:((this.state.messages[marker].sender==getAuth(firebase).currentUser.uid) || (this.state.driverID!=undefined && this.state.messages[marker].sender==this.state.driverID))? style.backgroundColor:style.textInputColor }}><Text style={{fontWeight: 'bold',}}>{(this.state.messages[marker].admin==true)?"[ADMIN] ":""}</Text>{this.state.messages[marker].text}</Text>
              </View>
               {this.state.messages[marker].viewDetail==true && <Text style={{fontSize: style.textInputFontSize*0.8, color:style.textInputColor,marginLeft:((this.state.messages[marker].sender==getAuth(firebase).currentUser.uid) || (this.state.driverID!=undefined && this.state.messages[marker].sender==this.state.driverID))?((Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax )?"31%":Dimensions.get('window').width*0.33-10):10,width:"67%",textAlign:((this.state.messages[marker].sender==getAuth(firebase).currentUser.uid) ||(this.state.driverID!=undefined && this.state.messages[marker].sender==this.state.driverID))?"right":"left" }}>{strings.sendBy+" "+((this.state.messages[marker].sender==getAuth(firebase).currentUser.uid)?strings.you.toLowerCase(): ((this.state.messages[marker].admin==true)?"admin": ((this.state.driverID!=undefined && this.state.messages[marker].sender==this.state.driverID)?this.state.driverName : this.state.sender)   )  )+" "+strings.at.toLowerCase()+" "+((new Date(parseInt(marker)).getHours()<10)?"0":"")+new Date(parseInt(marker)).getHours()+":"+((new Date(parseInt(marker)).getMinutes()<10)?"0":"")+new Date(parseInt(marker)).getMinutes()}</Text>}
                </View>
            </TouchableHighlight>}

            {(this.state.messages[marker].service==true) && <Text style={{marginTop:10, fontSize: style.textInputFontSize*0.9, color:style.textInputColor, width:"100%",textAlign:"center" }}>{this.state.messages[marker].text}</Text>}
                

        </View>
 

          

          ))}

        </ScrollView>}

        {(Object.keys(this.state.prefillList).length>0 && this.state.noSend!=true && this.state.prefillMode!=true) && <View style={{ position:"absolute", width:"100%",bottom:0,backgroundColor:style.backgroundColor,height:style.textInputHeight+this.state.keyboardOffset+( (Object.keys(this.state.prefillList).length>0)?40:0 ) }}> 

            <ScrollView showsHorizontalScrollIndicator={false}  horizontal={true}  style={{ marginLeft:10, marginRight:10}}>

                {Object.keys(this.state.prefillList).map((prefill,i) => (
                    <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { this.sendMessage(this.state.prefillList[prefill].text) }} style={{height:30, borderRadius: style.buttonRadius,borderWidth:1,borderColor:style.color1, alignItems:"center", justifyContent:"center", marginRight:10}}>
                      <Text style={{marginLeft:5, marginRight:5, fontSize: style.textInputFontSize, color:style.textInputColor, width:"100%",textAlign:"center" }}>{this.state.prefillList[prefill].text}</Text>
                    </TouchableHighlight>

                ))}
            </ScrollView>
        </View>}


        {(this.state.noSend!=true && this.state.prefillMode!=true) && <View style={{ position:"absolute", width:"100%",bottom:0,backgroundColor:style.backgroundColor,height:style.textInputHeight+10+this.state.keyboardOffset }}> 

          <View style={{marginLeft:10,marginRight:10, marginTop:10, borderRadius: style.buttonRadius,borderColor:style.backgroundColorContrast,borderWidth:1,flexDirection: 'row'}} >
          <TextInput
              placeholder={"Message"}
              placeholderTextColor={style.placeholderTextInputColor}
              underlineColorAndroid="rgba(0,0,0,0)"
              style={{width:(Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax )?"90%":(Dimensions.get('window').width-70), marginLeft:10,color:style.textInputColor,fontSize: style.textInputFontSize,textAlign:"left",height:style.textInputHeight}}
              
              onChangeText={text => {
                this.setState({sendMessage:text})


              }}
              value={this.state.sendMessage}
              />

              <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { this.sendMessage() }}>
                  <View style={{width:style.textInputHeight,height:style.textInputHeight,backgroundColor:style.color1,borderRadius:style.textInputHeight/2,alignItems:"center",justifyContent:"center"}}>
                      <Image source={(style.mode=="dark")?require('../assets/send_dark.png'):require('../assets/send_light.png')} style={{height:style.textInputHeight*0.55,width:style.textInputHeight*0.55,resizeMode:"contain"}}/>
                  </View>
              </TouchableHighlight>

          </View>
        </View>}


        {(this.state.prefillMode==true && this.state.prefillAdmin==true) && <ScrollView>

            <MavInfoBubble type={"info"} title={strings.chatPrefill} subTitle={strings.chatPrefillMoreInfo} style={{marginTop:10,}} />

            {Object.keys(this.state.prefillList).map((prefill,i) => (
                <MavViewMore disable={(this.state.addPrefill==false)?true:false} ref={viewAds => {this['viewPrefill_' + i] = viewAds;}}   iconClassic={(style.mode=="dark")?require('../assets/driver_message_light.png'):require('../assets/driver_message_dark.png')} textMini={(this.state.prefillList[prefill].modif==true && this.state.prefillList[prefill].new!=true )?"("+strings.notSaved+")":undefined} text={(this.state.prefillList[prefill].new==true || this.state.prefillList[prefill].text=="")?strings.newMessage:this.state.prefillList[prefill].text }  >

                    <MavTextInput style={{marginTop:5}} backgroundColor={style.backgroundColorContrast}  text={this.state.prefillList[prefill].text} multiline={true} textExplication={strings.text} placeholder={strings.takeMessage } onChangeText={(text)=>{var prefillList=JSON.parse(JSON.stringify(this.state.prefillList)); prefillList[prefill].text=text; prefillList[prefill].modif=true;   this.setState({prefillList:prefillList })  }}/>
                    

                    {(Object.keys(this.state.prefillList).length>1) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1.5} enableInfo={true} onEnableInfo={() => {this.alert.onAlert({title:strings.position, subTitle:strings.prefillMessagePositionMoreInfos}) }} enableButtonsLH={true} text={"n°"+(this.state.prefillList[prefill].index+1)}  textExplication={strings.position}  onChange={(value)=>{ 
                        var prefillList=JSON.parse(JSON.stringify(this.state.prefillList));
                        prefillList[prefill].oldIndex=parseInt(prefillList[prefill].index);
                        prefillList[prefill].index+=(value==0)?-1:1;
                        if(prefillList[prefill].index<0) prefillList[prefill].index=0;
                        else if(prefillList[prefill].index>(Object.keys(this.state.prefillList).length-1)) prefillList[prefill].index=Object.keys(this.state.prefillList).length-1

                        prefillList[prefill].modif=true;  
                        prefillList[prefill].modifIndex=true;

                        this.setState({prefillList:prefillList})
                    }} />}

                    <View style={{width:"100%", alignItems:"center", justifyContent:"center"}}>
                        <MavButton onlyButton={true} text={strings.validate} style={{marginTop:40}} onPress={()=>{  this.savePrefill(prefill,i) }}/>

                        <MavButton onlyButton={true} text={(this.state.prefillList[prefill].new==undefined)?strings.remove :strings.cancel} strokeMode={true} style={{marginTop:10}} onPress={()=>{ this.removePrefill(prefill, i); }}/>

                    </View>

                    
                </MavViewMore>))}
            

        </ScrollView>}


        {(this.state.prefillMode==true && this.state.prefillAdmin==true && this.state.addPrefill!=false) && <View style={{alignItems:"center",justifyContent:"center", marginTop:Dimensions.get('window').height * 0.05,marginBottom:Dimensions.get('window').height * 0.05}}>
          <MavButton text={strings.add} onlyButton={true}  onPress={()=>{ 
           var prefillList=JSON.parse(JSON.stringify(this.state.prefillList));  
           prefillList[generateUUID()]={text:"", new:true, index:Object.keys(this.state.prefillList).length};  
           this.setState({prefillList:prefillList, addPrefill:false },() =>{ 
              var k;
              for(var k=0; k<Object.keys(this.state.prefillList).length;k++){this['viewPrefill_' + k].close();}
              this['viewPrefill_' + (Object.keys(this.state.prefillList).length-1)].open(); 
           })
           }}/>
                      

          </View>}
       
      
       
       
       

      </View>
      <MavAlert ref={alert => {this.alert = alert;}}/>
      </View>
      }</StyleContext.Consumer>
    );
  }
}

export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}


