import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Image,
  Platform
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';
import MavTextInput from './mavTextInput';
import strings from '../strings';
import moneyList from '../moneyList';
import MapView from './mapView';
import MavButton from './mavButton';

export default class BookingView extends Component {
constructor(props) { 
    super(props);
    console.log("BookingView mount")
    if(true) console.log(this.props.data)

    var finalPrice=""; 
    if(this.props.data.forceFinalPrice!=undefined){
        finalPrice=this.props.data.forceFinalPrice;
    }
    else if(this.props.data.finalPriceClientTTC!=undefined){
      var money=this.findMoney(this.props.data.moneyID)
      finalPrice=((money.position=="left")?(money.symbole+" "):"")+this.props.data.finalPriceClientTTC+((money.position=="right")?(money.symbole):"")
    }
    
    this.state = {
      view:(this.props.defautVisible==true)?true:false,
      arrowEnabled:false,
      finalPrice:finalPrice
      

    }
  }

  findMoney(value){
    if(value==undefined) value="eu";
    var final="";
    var i;
    for(i=0;i<moneyList.length;i++){
      if(final==""){
        if(moneyList[i].id==value) final=moneyList[i];
      }
    }
    return final
  }



  render() {
    return (
    <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>
        <View style={{}}>
        {(this.props.mode=="liveBooking" && (Platform.OS!="web" || this.props.close==undefined) && this.props.data.racePlaceStartName!=undefined) && <View style={{width:"100%",alignItems:"center"}}> 
            <Image source={require("../assets/login_logo.png")} style={{marginTop:(Platform.OS=="web")?10:50,width:100,height:100,resizeMode:"contain"}}/>
        </View>}

        {(this.props.mode=="error") && <View style={{width:"100%",alignItems:"center"}}> 
            <Image source={(style.mode=="dark")?require('../assets/driver_cancel_light.png'):require('../assets/driver_cancel_dark.png')} style={{width:100,height:100,marginTop:20 }}/>
                <Text style={{marginTop:10,color: style.textInputColor,fontSize: style.textInputFontSize*2,textAlign: "center",}}>{strings.noRaceFound}</Text>
                <Text style={{marginTop:10,marginBottom:50,marginLeft:"10%", marginRight:"10%", color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.noRaceFoundMoreInfo}</Text>
            
        </View>}


       {this.props.mode=="confirmRace" && <View style={{flexDirection: 'row', alignItems:"center",justifyContent: 'space-between', }}>
          <Text style={{ fontSize:style.textInputFontSize*1.1,color:style.textInputColor, fontStyle: 'italic',marginBottom:10}}>{((new Date(this.props.data.raceDate).getDate()<10)?"0":"")+new Date(this.props.data.raceDate).getDate()+"/"+(((new Date(this.props.data.raceDate).getMonth()+1)<10)?"0":"")+(new Date(this.props.data.raceDate).getMonth()+1)+"/"+new Date(this.props.data.raceDate).getFullYear()+" - "+((new Date(this.props.data.raceDate).getHours()<10)?"0":"")+new Date(this.props.data.raceDate).getHours()+":"+((new Date(this.props.data.raceDate).getMinutes()<10)?"0":"")+new Date(this.props.data.raceDate).getMinutes()}</Text>
          <Image source={(style.mode=="dark")?require('../assets/viewMoreWhite.png'):require('../assets/viewMoreBlack.png')} style={{ width: 20, height: 20}} />


        </View>}
        
       {(this.props.mode!="error" && this.props.mode!="raceInfo" && this.props.mode!="confirmRace" ) && <View style={{justifyContent: 'space-between',flexDirection: 'row',marginBottom:10,alignItems:"center", marginTop:(Platform.OS=="web" && this.props.close!=undefined)?10:undefined }}>
          <View>
              <Text style={{ fontSize:style.textInputFontSize*1.1,color:style.textInputColor, fontStyle: 'italic',}}>{(this.props.data.date!=undefined)?this.props.data.date.toUpperCase():""}</Text>
              {(this.props.data.limited!=true && this.props.mode!="liveBooking" && this.props.mode!="revenue" ) && <Text style={{ fontSize:style.textInputFontSize*0.9,color:style.textInputColor,fontWeight: 'bold',}}>{(this.props.data.status=="invoiced")?(this.state.finalPrice):this.props.data.estimatePrice }
                  {this.props.data.carName!=undefined && <Text style={{ fontWeight: 'normal', }}>{" - "+this.props.data.carName}</Text>}

              </Text>}

              {(this.props.mode=="liveBooking" && info.bundleIdentifer=="acc8a1b0" && this.props.data.raceID!=undefined) && <Text style={{ fontSize:style.textInputFontSize*0.9,color:style.textInputColor}}>{"ID de la course : "+this.props.data.raceID.split("-")[0]}</Text>}

              {(this.props.mode=="liveBooking" && this.props.data.carName!=undefined && this.props.mode!="revenue") && <Text style={{ fontSize:style.textInputFontSize*0.9,color:style.textInputColor,fontWeight: 'bold',}}>{this.props.data.carName}</Text>}



          </View>

         {(this.props.data.status=="waiting" && this.props.mode!="revenue") && <View style={{alignItems:"center"}} >
              <Image source={require('../assets/waiting.png')} style={{ width: 22, height: 22, marginBottom:5 }} />
              <Text style={{ fontSize:style.textInputFontSize*0.7,color:style.colorWaiting}}>{strings.waitingValidation.toUpperCase()}</Text>
          </View>}

          {(this.props.data.status=="validate" && this.props.data.raceInProgress!=true && this.props.mode!="revenue") && <View style={{alignItems:"center"}} >
              <Image source={require('../assets/check_mark.png')} style={{ width: 30, height: 22, marginBottom:5 }} />
              <Text style={{ fontSize:style.textInputFontSize*0.7,color:style.colorValidate}}>{strings.validatedRace.toUpperCase()}</Text>
          </View>}

          {( (this.props.data.status=="invoiced" || this.props.data.status=="finish") && this.props.mode=="liveBooking") && <View style={{alignItems:"center"}} >
              <Image source={require('../assets/raceFinish.png')} style={{resizeMode:"contain", width: 30, height: 25, marginBottom:3 }} />
              <Text style={{ fontSize:style.textInputFontSize*0.7,color:style.colorValidate}}>{strings.raceFinished.toUpperCase()}</Text>
          </View>}



          

          {(this.props.data.downloadLink!=undefined && this.props.mode=="revenueSearch") && <View style={{alignItems:"center"}} >
            <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ if(this.props.viewPDFFile!=undefined) this.props.viewPDFFile() }}>
              <Image source={(style.mode=="dark")?require('../assets/download_dark.png'):require('../assets/download_light.png')}  style={{resizeMode:"contain", width: 30, height: 25, marginBottom:5 }} />
            </TouchableHighlight>
          </View>}

          {(this.props.data.debugMode==true) && <View style={{alignItems:"center"}} >
            <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{ if(this.props.viewDebug!=undefined) this.props.viewDebug() }}>
              <Image source={(style.mode=="dark")?require('../assets/settings_white.png'):require('../assets/settings_black.png')}  style={{resizeMode:"contain", width: 30, height: 25, marginBottom:5 }} />
            </TouchableHighlight>
          </View>}

          {(this.props.data.status=="validate" && this.props.data.raceInProgress==true) && <TouchableHighlight underlayColor="rgba(0,0,0,0)" onPress={()=>{if(this.props.viewRaceInProgress!=undefined && this.props.mode!="liveBooking") this.props.viewRaceInProgress() }}>
                <View style={{alignItems:"center"}} >
                    <Image source={require('../assets/way_green.png')} style={{ width: 30, height: 22, marginBottom:5 }} />
                    <Text style={{ fontSize:style.textInputFontSize*0.7,color:style.colorValidate}}>{strings.raceInProgress.toUpperCase()}</Text>
                    {this.props.mode!="liveBooking" && <Text style={{ fontSize:style.textInputFontSize*0.6,color:style.colorValidate}}>{strings.moreInfos.toUpperCase()}</Text>}
                </View>
          </TouchableHighlight>}


        </View>}



        {(this.props.mode=="liveBooking" && this.props.data.attributedPartner!=undefined) && <View style={{flexDirection:"row", alignItems:"center"}}>

                      <View style={{width: 100, height:100, marginTop:5, marginBottom:15 }}>
                        <Image source={require('../assets/userBlank.png')} style={{ width: 100, height:100,position:"absolute" }}/>
                        {(this.props.data.picture!=undefined) && <Image source={{uri: this.props.data.picture}} style={{borderRadius:50,width: 100, height:100,position:"absolute" }}/>}
                      </View>

                      <View style={{marginLeft:20}}>
                        <Text style={{ fontSize:style.textInputFontSize*0.9,color:style.textInputColor,fontStyle: 'italic',}}>{strings.driver.toUpperCase()}</Text>
                        <Text style={{ fontSize:style.textInputFontSize*1.1,fontWeight: 'bold',color:style.textInputColor}}>{this.props.data.attributedPartner.text}</Text>
                        {this.props.data.attributedPartner.car!=undefined && <Text style={{color: style.textInputColor,fontSize:style.textInputFontSize*0.8}}>{this.props.data.attributedPartner.car.brand+" "+this.props.data.attributedPartner.car.model+( (this.props.data.attributedPartner.car.immatriculation!=undefined)?(" ("+this.props.data.attributedPartner.car.immatriculation+")"):"")}</Text>}

                        {this.props.contactMessage!=undefined && this.props.data.status=="validate" &&  <View style={{marginTop:10}}>
                            <MavButton onlyButton={true} small={true}  text={strings.contactClient} style={{}} onPress={()=>{ 
                                  this.props.contactMessage(); 
                             }}/>
                         </View>}

                      </View>

        </View>}

        {(this.props.mode=="liveBooking" && this.props.data.status=="validate" && this.props.data.raceInProgress==true && (this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate")) && <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize,fontWeight: 'bold',textAlign: 'center',marginBottom:(this.props.data.immediateMode=="driverArrivate" || (this.props.data.immediateMode=="driverNavigateToCustomer" && this.props.data.arrivalDate!=undefined))?0:15,}}>{ (this.props.data.immediateMode=="driverNavigateToCustomer")?strings.yourDriverApproch.toUpperCase():(  (this.props.data.immediateMode=="driverArrivate")?strings.driverIsPresent.toUpperCase():strings.raceInProgress.toUpperCase()   )        }</Text>}
        {(this.props.mode=="liveBooking" && this.props.data.status=="validate" && this.props.data.immediateMode=="driverNavigateToCustomer" && this.props.data.arrivalDate!=undefined) && <Text style={{color: style.textInputColor,fontSize:style.textInputFontSize,textAlign: 'center',marginBottom:10}}>{strings.arrivalAt+" : "+this.props.data.arrivalDate }</Text>}
        {(this.props.mode=="liveBooking" && this.props.data.status=="validate" && this.props.data.immediateMode=="driverArrivate" && this.props.data.arrivateDate!=undefined) && <Text style={{color: style.textInputColor,fontSize:style.textInputFontSize,textAlign: 'center',marginBottom:10}}>{strings.yourDriverAreArrivateAt+" : "+((new Date(this.props.data.arrivateDate).getHours()<10)?"0":"")+ new Date(this.props.data.arrivateDate).getHours()+":"+((new Date(this.props.data.arrivateDate).getMinutes()<10)?"0":"")+ new Date(this.props.data.arrivateDate).getMinutes()}</Text>}

        

        {(this.props.mode=="liveBooking" && this.props.data.status=="validate" && this.props.data.raceInProgress==true && this.props.data.attributedPartner!=undefined && this.props.data.coor!=undefined && this.props.data.coor!=null) && <View>

        <MapView region={(this.props.data.mapRegion!=undefined && this.props.data.immediateMode=="driverNavigateToCustomer")?this.props.data.mapRegion:{latitude:this.props.data.coor.latitude, latitudeDelta:0.0922,longitudeDelta:0.0421,longitude:this.props.data.coor.longitude,} } style={{alignSelf: 'stretch',width: "100%",height:200, marginBottom:15}}>
            <MapView.Marker coordinate={this.props.data.coor} icon={(Platform.OS=="web")?require('../assets/driver_place.png'):null} > 
              {Platform.OS!="web" && <Image source={require('../assets/driver_place.png')} style={{resizeMode:"contain",height:30,width:30}}/>}
            </MapView.Marker>


            
            {(this.props.data.immediateMode=="driverNavigateToCustomer" && this.props.data.coordDriver!=undefined) && <MapView.Polyline
              coordinates={[...this.props.data.coordDriver]}
              path={[...this.props.data.coordDriver]}
              strokeWidth={6}
              strokeColor={style.color1}
              geodesic={true}
              options={{strokeColor: style.color1,strokeWidth:6}}


            />}

            {(this.props.data.immediateMode=="driverNavigateToCustomer" && this.props.data.coordDriver!=undefined) && <MapView.Marker icon={(Platform.OS=="web")?require('../assets/pin.png'):null} coordinate={this.props.data.coordDriver[this.props.data.coordDriver.length-1]} > 
              {Platform.OS!="web" &&<Image source={require('../assets/pin.png')} style={{resizeMode:"contain",height:30,width:30}}/>} 
            </MapView.Marker>}
            

        </MapView>

        <Text style={{marginLeft:4, marginTop:4,position:"absolute", fontSize:style.textInputFontSize*0.6,color:style.textInputColor,}}>{strings.lastMAJ+" : "+((new Date(this.props.data.lastPositionDate).getHours()<10)?"0":"")+ new Date(this.props.data.lastPositionDate).getHours()+":"+((new Date(this.props.data.lastPositionDate).getMinutes()<10)?"0":"")+ new Date(this.props.data.lastPositionDate).getMinutes()}</Text>

        </View>}


        {(this.props.mode=="liveBooking" && ((this.props.data.raceInProgress==true) || ((this.props.data.status=="invoiced" || this.props.data.status=="finish") && this.props.data.driverNavigateDate!=undefined ) ) ) && <View style={{justifyContent: 'space-between',flexDirection: 'row',height:50}}>
              <View style={{flexDirection: 'row',maxWidth:(this.props.mode!="revenue")?"65%":"85%"}}>
                <View style={{ marginRight:10,}}>
                    <View style={{width:20, height:20,borderRadius: 10, backgroundColor:(this.props.data.raceInProgress!=true && this.props.data.status!="invoiced" && this.props.data.status!="finish" )?style.backgroundColorContrast:style.color1, }}/>
                    <View style={{width:2, height:15, backgroundColor:(this.props.data.raceInProgress!=true && this.props.data.status!="invoiced" && this.props.data.status!="finish" )?style.backgroundColorContrast:style.color1, marginLeft:9, }}/>
                    <View style={{width:2, height:15, backgroundColor:(this.props.data.immediateMode=="driverNavigateToCustomer")?style.backgroundColorContrast:style.color1, marginLeft:9, }}/>
                </View>
                <View>
                    <Text style={{marginTop:4,fontSize:style.textInputFontSize*0.75,color:style.textInputColor,fontStyle: 'italic',}}>{strings.raceStarted.toUpperCase()}</Text>
                </View>
              </View>
              {(this.props.data.driverNavigateDate!=undefined && this.props.mode!="revenue") && <Text style={{fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold',}}>{((new Date(this.props.data.driverNavigateDate).getHours()<10)?"0":"")+ new Date(this.props.data.driverNavigateDate).getHours()+":"+((new Date(this.props.data.driverNavigateDate).getMinutes()<10)?"0":"")+ new Date(this.props.data.driverNavigateDate).getMinutes()}</Text>}
        </View>}



        {(this.props.mode!="error" && this.props.data.racePlaceStartName!=undefined) && <View style={{justifyContent: 'space-between',flexDirection: 'row',height:(this.props.mode!="revenue")?50:30,}}>
              <View style={{flexDirection: 'row',maxWidth:(this.props.mode!="revenue")?"75%":"85%"}}>
                <View style={{ marginRight:10, marginTop:(this.props.mode!="revenue")?undefined:6}}>
                    <View style={{width:(this.props.mode!="revenue")?20:10, height:(this.props.mode!="revenue")?20:10,borderRadius: (this.props.mode!="revenue")?10:5, backgroundColor:(this.props.mode=="liveBooking" && (this.props.data.immediateMode=="driverNavigateToCustomer") )?style.backgroundColorContrast:style.color1, }}/>
                    <View style={{width:2, height:(this.props.mode!="revenue")?15:11, backgroundColor:(this.props.mode=="liveBooking" && (this.props.data.immediateMode=="driverNavigateToCustomer") )?style.backgroundColorContrast:style.color1, marginLeft:(this.props.mode!="revenue")?9:4, }}/>
                    <View style={{width:2, height:(this.props.mode!="revenue")?15:11, backgroundColor:(this.props.mode=="liveBooking" && (this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate") )?style.backgroundColorContrast:style.color1, marginLeft:(this.props.mode!="revenue")?9:4, }}/>
                </View>
                <View style={{flexShrink: 1}}>
                    <Text style={{ fontSize:style.textInputFontSize*0.75*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor,fontStyle: 'italic',}}>{(this.props.mode=="liveBooking" && ( (this.props.data.status=="invoiced" || this.props.data.status=="finish" ) || (this.props.data.raceInProgress==true && (this.props.data.immediateMode=="driverArrivate" || this.props.data.immediateMode=="raceInProgress"))))?strings.driverPresentStartPoint.toUpperCase():strings.start.toUpperCase()}</Text>
                    <Text numberOfLines={1} style={{ fontSize:style.textInputFontSize*((this.props.mode!="revenue")?0.7:0.6),color:style.textInputColor,}}>{this.props.data.racePlaceStartName}</Text>
                </View> 
              </View>
              {(this.props.mode!="revenue" && this.props.mode!="confirmRace") && <Text style={{fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold',}}>{" "+((this.props.mode=="liveBooking" && (this.props.data.immediateMode=="driverArrivate" || this.props.data.immediateMode=="raceInProgress" || ( (this.props.data.status=="invoiced" || this.props.data.status=="finish" ) && this.props.data.arrivateDate!=undefined)  ))?((new Date(this.props.data.arrivateDate).getHours()<10)?"0":"")+ new Date(this.props.data.arrivateDate).getHours()+":"+((new Date(this.props.data.arrivateDate).getMinutes()<10)?"0":"")+ new Date(this.props.data.arrivateDate).getMinutes():this.props.data.hour)}</Text>}
        </View>}

        {(this.props.mode=="liveBooking" && (this.props.data.raceInProgress==true || ( (this.props.data.status=="invoiced" || this.props.data.status=="finish" ) && this.props.data.realRaceDate!=undefined) )) && <View style={{justifyContent: 'space-between',flexDirection: 'row',height:50}}>
              <View style={{flexDirection: 'row',maxWidth:(this.props.mode!="revenue")?"65%":"85%"}}>
                <View style={{ marginRight:10,}}>
                    <View style={{width:(this.props.mode!="revenue")?20:10, height:(this.props.mode!="revenue")?20:10,borderRadius: (this.props.mode!="revenue")?10:5, backgroundColor:(this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate")?style.backgroundColorContrast:style.color1, }}/>
                    <View style={{width:2, height:(this.props.mode!="revenue")?15:11,  backgroundColor:(this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate")?style.backgroundColorContrast:style.color1, marginLeft:(this.props.mode!="revenue")?9:4, }}/>
                    <View style={{width:2, height:(this.props.mode!="revenue")?15:11,  backgroundColor:(this.props.data.status!="invoiced" && this.props.data.status!="finish"  && (this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate" || (this.props.data.immediateMode=="raceInProgress" && (this.props.data.stopList==undefined || this.props.data.sla==0)   ) ))?style.backgroundColorContrast:style.color1, marginLeft:(this.props.mode!="revenue")?9:4, }}/>
                </View>
                <View>
                    <Text style={{marginTop:4,fontSize:style.textInputFontSize*0.75,color:style.textInputColor,fontStyle: 'italic',}}>{strings.customerOnBoard.toUpperCase()}</Text>
                </View>
              </View>
              <View style={{alignItems:"center"}}>
                <Text style={{fontSize:style.textInputFontSize*1.4*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor,fontWeight: 'bold',}}>{ (this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate")? this.props.data.hour :((new Date(this.props.data.realRaceDate).getHours()<10)?"0":"")+ new Date(this.props.data.realRaceDate).getHours()+":"+((new Date(this.props.data.realRaceDate).getMinutes()<10)?"0":"")+ new Date(this.props.data.realRaceDate).getMinutes()}</Text>
                {((this.props.data.immediateMode=="raceInProgress" || (this.props.data.status=="invoiced" || this.props.data.status=="finish" ) ) && ( Math.abs(new Date(this.props.data.realRaceDate)-new Date(this.props.data.raceDate)) >300000) ) && <Text style={{fontSize:style.textInputFontSize*0.9,color:style.textInputColor,marginTop:-3,textDecorationLine: 'line-through',}}>{this.props.data.hour}</Text>}
              </View>
        </View>}


        {this.props.data.stopList!=undefined && this.props.data.stopList.length>0 && this.props.data.stopList.map((rowData, stI)=>(     
            <View style={{justifyContent: 'space-between',flexDirection: 'row',height:((this.props.mode!="revenue")?50:30)}}>
                  <View style={{flexDirection: 'row',maxWidth:"85%"}}>
                    <View style={{ marginRight:10,marginTop:(this.props.mode!="revenue")?undefined:6}}>
                        <View style={{width:(this.props.mode!="revenue")?20:10, height:(this.props.mode!="revenue")?20:10,borderRadius: (this.props.mode!="revenue")?10:5, borderColor:(this.props.mode=="liveBooking" && (this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate" || (this.props.data.immediateMode=="raceInProgress" && this.props.data.sla!=undefined && this.props.data.sla-1<stI) ) && this.props.data.raceInProgress==true && this.props.data.status!="invoiced" && this.props.data.status!="finish")?style.backgroundColorContrast:style.color1,borderWidth:2 }}/>
                        <View style={{width:2, height:(this.props.mode!="revenue")?15:11,  backgroundColor:(this.props.mode=="liveBooking" && (this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate" || (this.props.data.immediateMode=="raceInProgress" && this.props.data.sla!=undefined && this.props.data.sla-1<stI) ) && this.props.data.raceInProgress==true && this.props.data.status!="invoiced" && this.props.data.status!="finish")?style.backgroundColorContrast:style.color1, marginLeft:(this.props.mode!="revenue")?9:4, }}/>
                        <View style={{width:2, height:(this.props.mode!="revenue")?15:11,  backgroundColor:(this.props.mode=="liveBooking" && (this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate" || (this.props.data.immediateMode=="raceInProgress" && ( (this.props.data.sla==undefined && stI==this.props.data.stopList.length-1) ||   (this.props.data.sla!=undefined && this.props.data.sla-2<stI)) ) ) && this.props.data.raceInProgress==true && this.props.data.status!="invoiced" && this.props.data.status!="finish")?style.backgroundColorContrast:style.color1, marginLeft:(this.props.mode!="revenue")?9:4, }}/>
                    </View>
                    <View style={{flexShrink: 1}}>
                        <Text style={{ fontSize:style.textInputFontSize*0.75*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor*((this.props.mode!="revenue")?1:0.7),fontStyle: 'italic',}}>{strings.onlyStop.toUpperCase()}</Text>
                        <Text numberOfLines={1} style={{fontSize:style.textInputFontSize*((this.props.mode!="revenue")?0.7:0.6),color:style.textInputColor,}}>{rowData}</Text>
                    </View>
                  </View>
                  
            </View>
         ))}


        {(this.props.mode!="error" && this.props.data.racePlaceStartName!=undefined) && <View style={{justifyContent: 'space-between',flexDirection: 'row',height:((this.props.mode!="revenue")?30:20)}}>
              <View style={{flexDirection: 'row',maxWidth:(this.props.mode!="revenue")?"65%":"85%"}}>
                
                <View style={{ marginRight:10,marginTop:(this.props.mode!="revenue")?undefined:6}}>
                    <View style={{width:(this.props.mode!="revenue")?18:9, height:(this.props.mode!="revenue")?18:9,transform: [{ rotate:"45deg" }], backgroundColor:(this.props.mode=="liveBooking" && this.props.data.raceInProgress==true && this.props.data.status!="invoiced" && this.props.data.status!="finish" && (this.props.data.immediateMode=="driverNavigateToCustomer" || this.props.data.immediateMode=="driverArrivate" || this.props.data.immediateMode=="raceInProgress") )?style.backgroundColorContrast:style.color1 }}/>
                </View>
                <View style={{flexShrink: 1}}>
                    <Text style={{ fontSize:style.textInputFontSize*0.75*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor,fontStyle: 'italic',marginTop:(this.props.data.racePlaceEndName==undefined && this.props.mode!="raceInfo")?5:undefined}}>{(this.props.data.racePlaceEndName==undefined && this.props.data.letaxiProvider!=true)?strings.provision.toUpperCase():strings.arrival.toUpperCase()}</Text>
                    {(this.props.data.racePlaceEndName!=undefined) && <Text numberOfLines={1} style={{fontSize:style.textInputFontSize*((this.props.mode!="revenue")?0.7:0.6),color:style.textInputColor,}}>{this.props.data.racePlaceEndName}</Text>}
                    {(this.props.data.racePlaceEndName==undefined && this.props.data.letaxiProvider!=true && this.props.mode=="raceInfo") && <Text numberOfLines={1} style={{fontSize:style.textInputFontSize*((this.props.mode!="revenue")?0.7:0.6),color:style.textInputColor,}}>{strings.duration+" : "+(Math.floor(this.props.data.estimateTimeValue/3600)<10?"0":"")+Math.floor(this.props.data.estimateTimeValue/3600)+":"+((Math.floor((this.props.data.estimateTimeValue-3600*Math.floor(this.props.data.estimateTimeValue/3600))/60)<10)?"0":"")+Math.floor((this.props.data.estimateTimeValue-3600*Math.floor(this.props.data.estimateTimeValue/3600))/60)}</Text>}
                    {(this.props.data.racePlaceEndName==undefined && this.props.data.letaxiProvider==true && this.props.mode=="raceInfo") && <Text numberOfLines={1} style={{fontSize:style.textInputFontSize*((this.props.mode!="revenue")?0.7:0.6),color:style.textInputColor,}}>{"Destination inconnue"}</Text>}

                </View>
              </View>
              {this.props.mode!="revenue" && <View style={{alignItems:(this.props.mode=="raceInfo" && this.props.data.goReturnTaxi==true)?"flex-end":"center", backgroundColor:style.backgroundColor}}>
                  {( (this.props.data.status!="invoiced" && this.props.data.status!="finish")  || this.props.mode!="liveBooking") && <Text style={{textAlign:"right",fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold',}}>{(this.props.data.immediateMode=="raceInProgress" && ( Math.abs(new Date(this.props.data.realRaceDate)-new Date(this.props.data.raceDate)) >300000) )?((new Date(this.props.data.realRaceDate+1000*this.props.data.estimateTimeValue).getHours()<10)?"0":"")+ new Date(this.props.data.realRaceDate+1000*this.props.data.estimateTimeValue).getHours()+":"+((new Date(this.props.data.realRaceDate+1000*this.props.data.estimateTimeValue).getMinutes()<10)?"0":"")+ new Date(this.props.data.realRaceDate+1000*this.props.data.estimateTimeValue).getMinutes():this.props.data.hourDestination}</Text>}
                  { (this.props.mode=="raceInfo" && this.props.data.goReturnTaxi==true) && <Text style={{fontSize:style.textInputFontSize*0.8,textAlign:"right",color:style.textInputColor,top:-3}}>{strings.totalGoReturn}</Text>}


                  {( (this.props.data.status=="invoiced" || this.props.data.status=="finish") && this.props.mode=="liveBooking"  ) && <Text style={{fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold',}}>{ (this.props.data.dropOffDate==undefined)?this.props.data.hourDestination : (   ((new Date(this.props.data.dropOffDate).getHours()<10)?"0":"")+ new Date(this.props.data.dropOffDate).getHours()+":"+((new Date(this.props.data.dropOffDate).getMinutes()<10)?"0":"")+ new Date(this.props.data.dropOffDate).getMinutes())}</Text>}
                  {(this.props.data.status!="invoiced" && this.props.data.status!="finish" && this.props.data.immediateMode=="raceInProgress" && ( Math.abs(new Date(this.props.data.realRaceDate)-new Date(this.props.data.raceDate)) >300000) ) && <Text style={{fontSize:style.textInputFontSize*0.9,color:style.textInputColor,marginTop:-3,textDecorationLine: 'line-through',}}>{this.props.data.hourDestination}</Text>}
                  {( (this.props.data.status=="invoiced" || this.props.data.status=="finish") && this.props.mode=="liveBooking" && ( Math.abs(new Date(this.props.data.dropOffDate)-new Date(this.props.data.raceDate+1000*this.props.data.estimateTimeValue)) >300000) ) && <Text style={{fontSize:style.textInputFontSize*0.9,color:style.textInputColor,marginTop:-3,textDecorationLine: 'line-through',}}>{this.props.data.hourDestination}</Text>}
              </View>}
        </View>}

        

        {(this.props.mode!="invoiced" && this.props.data.status!="finish" && (this.props.data.reqMod==true || this.props.data.reqMod=="accept" || this.props.data.reqMod=="decline") ) && <View style={{marginTop:15,flexDirection: 'row', alignItems:"center" }}>
            <Image source={(this.props.data.reqMod=="accept")?require('../assets/modify_green.png'):require('../assets/modify_red.png')} style={{ width: 22, height: 22, marginRight:10 }} />
            <View>
                <Text style={{ fontSize:style.textInputFontSize*0.75,color:(this.props.data.reqMod=="accept")?style.colorValidate:style.colorNotification,fontStyle: 'italic',fontWeight: 'bold'}}>{(this.props.data.reqMod=="accept")?strings.requestModifAccept.toUpperCase():( (this.props.data.reqMod=="decline")?strings.requestModifDecline.toUpperCase()  : strings.requestModifWait.toUpperCase() )}</Text>
                {this.props.data.reqMod==true && <Text style={{ fontSize:style.textInputFontSize*0.92,textAlign:"justify", color:style.colorNotification,marginRight:"10%"}}>{strings.requestModifWaitMoreInfo}</Text>}
            </View>
          </View>}


          {(this.props.mode=="raceInfo" && this.props.data.carName!=undefined && this.props.data.carName!="" ) && <View style={{marginTop:15,flexDirection: 'row', alignItems:"center",justifyContent: 'space-between', }}>
            <View style={{flexDirection: 'row', alignItems:"center",}}>
                <Image source={(style.mode=="dark")?require('../assets/carFront_white.png'):require('../assets/carFront_black.png')} style={{ width:22 , height: 22, marginRight:10 }} />
                <View>
                    <Text style={{ fontSize:style.textInputFontSize*0.75*((this.props.mode!="revenue")?1:0.8),color:style.textInputColor,fontStyle: 'italic',}}>{ strings.vehicule.toUpperCase() +( (this.props.data.driverPrice!=undefined)?( " / "+ ( (this.props.data.priceNetForDriver==true)?strings.priceDriver.toUpperCase():strings.price.toUpperCase()) ) :"" ) }</Text>
                    <Text style={{ fontSize:style.textInputFontSize*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor}}>{this.props.data.carName}</Text>
                </View>
            </View>

             {(this.props.data.driverPrice!=undefined) && <View style={{alignItems:"center", backgroundColor:style.backgroundColor}}>
                  <Text style={{fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold',}}>{this.props.data.driverPrice}</Text>
                  </View>}
          </View>}



          
          


          {(this.props.data.clientInfo!=undefined) && <View style={{marginTop:15,flexDirection: 'row', alignItems:"center",justifyContent: 'space-between', }}>
            <View style={{flexDirection: 'row', alignItems:"center",}}>
                <Image source={(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png')} style={{ width: (this.props.mode!="revenue")?22:12 , height: (this.props.mode!="revenue")?22:15, marginRight:(this.props.mode!="revenue")?10:7 }} />
                <View>
                    <Text style={{ fontSize:style.textInputFontSize*0.75*((this.props.mode!="revenue")?1:0.8),color:style.textInputColor,fontStyle: 'italic',}}>{ (this.props.data.passagersList==undefined)?strings.traveler.toUpperCase(): strings.travelers.toUpperCase()}</Text>
                    <Text style={{ fontSize:style.textInputFontSize*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor}}>{(this.props.data.clientInfo.length>30)?(this.props.data.clientInfo.slice(0,30)+" ..."):this.props.data.clientInfo}</Text>
                    {this.props.data.passagersList!=undefined && this.props.data.passagersList.map(rowData=>(
                      <View>
                        {((rowData.name!=undefined && rowData.name!="") || (rowData.lastName!=undefined && rowData.lastName!="" )) && <Text style={{ fontSize:style.textInputFontSize*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor}}>{rowData.name+" "+rowData.lastName}</Text>}
                      </View>
                    ))}
                </View>
            </View>

            {(this.props.mode=="raceInfo" && this.props.data.vip==true) && <View style={{width:40, marginLeft:10, height:20, borderColor:style.textInputColor, borderWidth:1,alignItems:"center", justifyContent:"center", borderRadius:5}}><Text style={{ fontSize: 12, color: style.textInputColor,backgroundColor: 'rgba(0,0,0,0)', }}>{strings.vip.toUpperCase()}</Text></View>}
            {(this.props.mode=="raceInfo" && this.props.data.karhoo==true) && <View style={{width:65, marginLeft:10, height:20, borderColor:style.textInputColor, borderWidth:1,alignItems:"center", justifyContent:"center", borderRadius:5}}><Text style={{ fontSize: 12, color: style.textInputColor,backgroundColor: 'rgba(0,0,0,0)', }}>{"KARHOO"}</Text></View>}
            {(this.props.mode=="raceInfo" && this.props.data.letaxiProvider==true) && <View style={{width:65, marginLeft:10, height:20, borderColor:style.textInputColor, borderWidth:1,alignItems:"center", justifyContent:"center", borderRadius:5}}><Text style={{ fontSize: 12, color: style.textInputColor,backgroundColor: 'rgba(0,0,0,0)', }}>{"LE.TAXI"}</Text></View>}


            {(this.props.mode=="revenue" && this.props.data.priceRace!=undefined) && <View style={{flexDirection:"row", alignItems:"center"}}>
                
                {this.props.data.downloadBillLink!=undefined && <TouchableHighlight onPress={() => { if(this.props.viewPDFFile!=undefined) this.props.viewPDFFile(this.props.data.downloadBillLink)  }} underlayColor={"#FFFFFF00"}  style={{justifyContent: 'center',alignItems: 'center',height:22,width:22, marginRight:10}}>
                    <Image source={(style.mode=="dark")?require('../assets/download_dark.png'):require('../assets/download_light.png')} style={{ width: 22, height: 22 }}/>
                </TouchableHighlight>}

                <View style={{alignItems:"flex-end" }}>
                    <Text style={{fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold', textAlign:"center"}}>{this.props.data.priceRace}</Text>
                    <Text style={{fontSize:style.textInputFontSize*0.6,color:style.textInputColor,marginTop:-3, textAlign:"center"}}>{this.props.data.priceRaceExplication.toUpperCase()}</Text>
                </View>

                

            </View>}
          </View>}



          {(this.props.mode=="raceInfo" && this.props.data.desiredDriver!=undefined && this.props.data.desiredDriver.id!=undefined ) && <View style={{marginTop:15,flexDirection: 'row', alignItems:"center",justifyContent: 'space-between', }}>
            <View style={{flexDirection: 'row', alignItems:"center",}}>
                <Image source={require('../assets/starDriverFavorite.png')} style={{ width: (this.props.mode!="revenue")?22:12 , height: (this.props.mode!="revenue")?22:15, marginRight:(this.props.mode!="revenue")?10:7 }} />
                <View>
                    <Text style={{ fontSize:style.textInputFontSize*0.75*((this.props.mode!="revenue")?1:0.8),color:style.textInputColor,fontStyle: 'italic',}}>{ strings.desiredDriver.toUpperCase()}</Text>
                    <Text style={{ fontSize:style.textInputFontSize*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor}}>{this.props.data.desiredDriver.text}</Text>
                    
                </View>
            </View>
          </View>}



          {(this.props.mode=="revenue" && this.props.data.providerName!=undefined && this.props.data.providerID!=this.props.data.attributedPartner.id) && <View style={{marginTop:15,flexDirection: 'row', alignItems:"center",justifyContent: 'space-between', }}>
             <View style={{flexDirection: 'row', alignItems:"center",}}>
                <Image source={(style.mode=="dark")?require('../assets/team_light.png'):require('../assets/team_dark.png')} style={{ width: (this.props.mode!="revenue")?22:12 , height: (this.props.mode!="revenue")?22:15 , marginRight:(this.props.mode!="revenue")?10:7  }} />
                <View>
                    <Text style={{ fontSize:style.textInputFontSize*0.75*((this.props.mode!="revenue")?1:0.8),color:style.textInputColor,fontStyle: 'italic',}}>{strings.providerAffair.toUpperCase()}</Text>
                    <Text style={{ fontSize:style.textInputFontSize*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor}}>{((this.props.data.providerEnterprise!=undefined)?(this.props.data.providerEnterprise+" - "):"")+    this.props.data.providerName+" "+this.props.data.providerLastName}</Text>
                </View>
            </View>

            {(this.props.data.priceRaceProvider!=undefined) && <View style={{flexDirection:"row", alignItems:"center"}}>
                    <Text style={{fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold', textAlign:"center"}}>{this.props.data.priceRaceProvider}</Text>
               
            </View>}

          </View>}

        {(this.props.enableDriverNameInRace==true && this.props.data.attributedPartner!=undefined) && <View style={{marginTop:15,flexDirection: 'row', alignItems:"center",justifyContent: 'space-between', }}>
             <View style={{flexDirection: 'row', alignItems:"center",}}>
                <Image source={(style.mode=="dark")?require('../assets/partner_light.png'):require('../assets/partner_dark.png')} style={{ width: (this.props.mode!="revenue")?22:12 , height: (this.props.mode!="revenue")?22:15 , marginRight:(this.props.mode!="revenue")?10:7  }} />
                <View>
                    <Text style={{ fontSize:style.textInputFontSize*0.75*((this.props.mode!="revenue")?1:0.8),color:style.textInputColor,fontStyle: 'italic',}}>{strings.driver.toUpperCase()}</Text>
                    <Text style={{ fontSize:style.textInputFontSize*((this.props.mode!="revenue")?1:0.7),color:style.textInputColor}}>{this.props.data.attributedPartner.text}</Text>
                </View>
            </View>

            {(this.props.mode=="revenue" && this.props.data.priceRaceDriver!=undefined) && <View style={{flexDirection:"row", alignItems:"center"}}>

                {this.props.data.downloadDriverBillLink!=undefined && <TouchableHighlight onPress={() => { if(this.props.viewPDFFile!=undefined) this.props.viewPDFFile(this.props.data.downloadDriverBillLink)  }} underlayColor={"#FFFFFF00"}  style={{justifyContent: 'center',alignItems: 'center',height:22,width:22, marginRight:10}}>
                    <Image source={(style.mode=="dark")?require('../assets/download_dark.png'):require('../assets/download_light.png')} style={{ width: 22, height: 22 }}/>
                </TouchableHighlight>}

                <View style={{alignItems:"flex-end" }}>
                    <Text style={{fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold', textAlign:"center"}}>{this.props.data.priceRaceDriver}</Text>
                    {this.props.data.stripeCBAuto!=undefined && <Text style={{fontSize:style.textInputFontSize*0.6,color:(this.props.data.stripeCBAuto==true)?style.colorValidate:style.colorNotification,marginTop:-3, textAlign:"center"}}>{(this.props.data.stripeCBAuto==true)?strings.paymentAuto:strings.paymentAutoError}</Text>}

                </View>

                

            </View>}

          </View>}

          


          


          


           {(this.props.mode=="liveBooking" && this.props.close!=undefined && Platform.OS!="web") && <View style={{alignItems:"center",justifyContent:"center", marginTop:20,marginBottom:20 }}>
             <MavButton text={strings.close} strokeMode={true} onlyButton={true} onPress={()=>{ this.props.close() }}/>
          </View>}


          {(this.props.mode=="liveBooking" && Platform.OS=="web" && this.props.close==undefined) && <View style={{width:40, height:40}}/>}


          






        </View>
    </View>
    }</StyleContext.Consumer>
    	)}

    }

