import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';


export default class MavSelector extends Component {

  render() {
    
    return (
    <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>


    <View style={{marginTop:10,marginLeft:"10%", marginRight:"10%",  flexDirection:"row",justifyContent:'center',borderRadius: 10,borderColor:style.color1,borderWidth:2,}}>
        <View style={{width:(this.props.textCenter!=undefined)?"33.33%":"50%",}}> 
              <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={()=>{if(this.props.onChange!=null) this.props.onChange(0);}}>
                    <View style={{width:"100%",}}>
                            <View style={{height:30* ( (this.props.maxiMode==true)?2:1) ,borderRadius: 8,backgroundColor:(this.props.selected==0 || (this.props.enabledItem!=undefined && this.props.enabledItem[0]==true) )?style.color1:style.colorBackground,justifyContent: 'center'}}> 
                     <Text
                     allowFontScaling={false}
                      style={{
                        color: (this.props.selected===0 || (this.props.enabledItem!=undefined && this.props.enabledItem[0]==true) )?style.backgroundColor:style.color1,fontWeight: (this.props.selected==0 || (this.props.enabledItem!=undefined && this.props.enabledItem[0]==true) )?'bold':'normal',
                        fontSize: style.textInputFontSize*((this.props.textCenter!=undefined)?0.85:1)*( (this.props.maxiMode==true)?3:1),
                        textAlign: 'center',
                        
                      }}>
                      {this.props.textLeft} 
                    </Text>
                    
                    </View>
                    </View>
                    </TouchableHighlight>
                    
        </View>




        {this.props.textCenter!=undefined && <View style={{width:"33.33%"}}> 
              <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={()=>{if(this.props.onChange!=null) this.props.onChange(0.5);}}>
                    <View style={{width:"100%",}}>
                            <View style={{height:30*( (this.props.maxiMode==true)?2:1),borderRadius: 8,backgroundColor:(this.props.selected==0.5 || (this.props.enabledItem!=undefined && this.props.enabledItem[1]==true) )?style.color1:style.colorBackground,justifyContent: 'center'}}> 
                     <Text
                     allowFontScaling={false}
                      style={{
                        color: (this.props.selected==0.5 || (this.props.enabledItem!=undefined && this.props.enabledItem[1]==true))?style.backgroundColor:style.color1,fontWeight: (this.props.selected==0.5 || (this.props.enabledItem!=undefined && this.props.enabledItem[1]==true))?'bold':'normal',
                        fontSize: style.textInputFontSize*0.85*( (this.props.maxiMode==true)?3:1),
                        textAlign: 'center',
                        
                      }}>
                      {this.props.textCenter} 
                    </Text>
                    
                    </View>
                    </View>
                    </TouchableHighlight>
                    
        </View>}
                    
                    
                   <View style={{width:(this.props.textCenter!=undefined)?"33.33%":"50%"}}>  
                    <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={()=>{if(this.props.onChange!=null) this.props.onChange(1);}}>
                    <View>
                            <View style={{height:30*( (this.props.maxiMode==true)?2:1),borderRadius: 8,backgroundColor:(this.props.selected==1 || (this.props.enabledItem!=undefined && this.props.enabledItem[2]==true) )?style.color1:style.colorBackground,justifyContent: 'center'}}> 
                     <Text
                     allowFontScaling={false}
                      style={{
                        color: (this.props.selected==1 || (this.props.enabledItem!=undefined && this.props.enabledItem[2]==true) )?style.backgroundColor:style.color1,fontWeight: (this.props.selected==1 || (this.props.enabledItem!=undefined && this.props.enabledItem[2]==true))?'bold':'normal',
                        fontSize: style.textInputFontSize*((this.props.textCenter!=undefined)?0.85:1)*( (this.props.maxiMode==true)?3:1),
                        textAlign: 'center',
                        
                      }}>
                      {this.props.textRight}
                    </Text>
                    
                    </View>
                    </View>
                    </TouchableHighlight>
                </View>

        </View>



        </View>
        }</StyleContext.Consumer>
    	)}

    }

