import React, { Component } from 'react';
import {
View,
  Text,
  ScrollView,TouchableHighlight,FlatList,Dimensions,Animated,Image, Easing,Platform
} from 'react-native';
import {StyleContext} from '../styleC';

import firebase from './firebase';
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue} from "firebase/database";
import strings from '../strings';
import info from '../info';
import MavTextInput from './mavTextInput';
import SortableList from './SortableList';
import MavButton from './mavButton';
import MavAlert from './mavAlert';
import Modal from './modal';
import MavViewMore from './mavViewMore';
import MavNavigationMinimize from './mavNavigationMinimize';
import languageList from '../languageList';
import MavInfoBubble from './mavInfoBubble';
import example from '../example';
const timer = require('react-native-timer');
import TextPresentation from './textPresentation';

class Row extends Component {

  constructor(props) {
    super(props);


    this._active = new Animated.Value(0);

    
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      Animated.timing(this._active, {
        duration: 300,
        useNativeDriver: true,
        easing: Easing.bounce,
        toValue: Number(nextProps.active),
      }).start();
    }
  }

  render() {
   const {rowData, active} = this.props;

    return (
      <StyleContext.Consumer>{style => 
      <Animated.View style={{flexDirection: 'row',justifyContent: 'space-between',  marginLeft:"10%",marginRight:"10%",marginTop:10, marginBottom:10}}>
        
          {(Platform.OS!="web") && <View style={{backgroundColor:style.headerBackgroundColor,}} > 
            <Image source={require('../assets/more_grey.png')} style={{height:30,width:30,marginRight:10}} /> 
          </View>}

          {(Platform.OS=="web") && <View style={{flexDirection:"row"}}> 
            <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => { console.log(this.props); this.props.modifyOrder(this.props.index,-1) }} style={{backgroundColor:style.headerBackgroundColor,}} > 
              <Image source={(style.mode=="dark")?require("../assets/arrowUp_dark.png"):require("../assets/arrowUp_light.png")} style={{height:15,width:30,marginRight:10}} /> 
            </TouchableHighlight>
            <TouchableHighlight style={{backgroundColor:style.headerBackgroundColor,}} underlayColor={'rgba(255,255,255,0)'} onPress={() => { this.props.modifyOrder(this.props.index,1) }} > 
              <Image source={(style.mode=="dark")?require("../assets/arrowDown_dark.png"):require("../assets/arrowDown_light.png")} style={{marginLeft:5, height:15,width:30,marginRight:10}} /> 
            </TouchableHighlight>
          </View>}


          <TouchableHighlight style={{height:30, justifyContent:"center"}}><Text style={{fontSize: style.textInputFontSize,color:style.textInputColor, textAlign:"left", width:((Platform.OS=="web")?("80%-60"):((Dimensions.get('window').width*0.8)-60)) }} onPress={() => { this.props.actionLink({action:"update",keyID:this.props.keyID})  }}  >{this.props.data.val["fr"].name}</Text></TouchableHighlight>
        
          <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {if(this.props.data.cgu!=true) this.props.actionLink({action:"remove",keyID:this.props.keyID}) }} > 
               <View>
               {this.props.data.cgu!=true && <Image source={require('../assets/favorite_cancel.png')} style={{ width: 20, height: 20,marginTop:9, marginLeft:10 }}/>}
                {this.props.data.cgu==true && <View style={{ width: 20, height: 20,marginTop:9, marginLeft:10 }} />}
                </View>
          </TouchableHighlight>

      </Animated.View>
       }</StyleContext.Consumer>


      

      


      
      


      
    );
  }
}




export default class help extends Component {
  constructor(props) {
    super(props);
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    if(Platform.OS!="web"){
        this.props.navigation.setOptions({ title: (params!=undefined)?( (params.customerReviews===true)? strings.customerReviews: ((params.smsPrefill===true)?strings.smsPrefill:strings.help) ):strings.help, })
    }  

    var smsPrefillListBase=["validate", "validateT"] 
    if(info.stripePublicKey!=undefined) {
      smsPrefillListBase.push("validateP"); 
      smsPrefillListBase.push("validatePT"); 
    }
    smsPrefillListBase.push("cancelRace");
    smsPrefillListBase.push("userRequestAnnulation"); 
    smsPrefillListBase.push("imcoming");
    smsPrefillListBase.push("imhere");
    


    this.state={
      viewAddItem:false, 
      params:params,
      element:{}, 
      selectedElement:"fr",
      removeInProgress:false,
      disableNavigation:(params!=undefined && params.disableNavigation==true && Platform.OS=="web")?true:false,
      googleMyBusinessText:(params!=undefined && params.googleMyBusinessText!=undefined)?params.googleMyBusinessText:{fr:""}, 
      oldGoogleMyBusinessText:(params!=undefined && params.googleMyBusinessText!=undefined)?JSON.parse(JSON.stringify(params.googleMyBusinessText)):{fr:""}, 
      elementID:"",
      help:[],
      driver:(params!=undefined && params.driver===true)?true:false,
      customerReviews:(params!=undefined && params.customerReviews===true)?true:false,
      smsPrefill:(params!=undefined && params.smsPrefill===true)?true:false,
      activateGoogleMyBusiness:(params!=undefined && params.activateGoogleMyBusiness===true)?true:false,
      activateComment:(params!=undefined && params.activateComment===true)?true:false,
      activateDriverNotation:(params!=undefined && params.activateDriverNotation===true)?true:false,
      activateCustomerNotation:(params!=undefined && params.activateCustomerNotation===true)?true:false,
      smsPrefillList:{},
      smsPrefillListBase:smsPrefillListBase,
    }
  }
  
   componentDidMount(){

    if(this.state.smsPrefill==true){

        get(child(ref(getDatabase(firebase)), "/prefill/sms")).then(snapshot => { 
            var val=snapshot.val();
            if(val!=null){

              

              this.setState({smsPrefillList:val},() =>{
                  var listPr=Object.keys(val); 
                  console.log("je vais faire "+listPr[0])
                   this.viewSMSExemple(listPr[0], 0, listPr)

                  


              })
            }
         })

    }
    else if(this.state.customerReviews!=true){

      get(child(ref(getDatabase(firebase)), "/faq")).then(snapshot => { 
        var val=snapshot.val();
        var list=Object.keys(val);
        var list2=new Array;
        var i;
        for(i=0;i<list.length;i++){
              if(val[list[i]].index==undefined) val[list[i]].index=i;
        }

        list=Object.keys(val).sort( function ( a, b ) { return val[a].index - val[b].index  } )


        for(i=0;i<list.length;i++){
          if(this.state.params!=undefined && this.state.params.driver===true){
              list2.push({index:i,i:i,cgu:(list[i]=="CGU")?true:false,val:val[list[i]],uuid:list[i]   })
          } 
          else list2.push({
              name:(val[list[i]][strings.idLangue]==undefined)?val[list[i]]["fr"].name:val[list[i]][strings.idLangue].name,
              description:(val[list[i]][strings.idLangue]==undefined)?val[list[i]]["fr"].description:val[list[i]][strings.idLangue].description,
              i:i,
              index:i,
              uuid:list[i], 
              cgu:(list[i]=="CGU")?true:false,
          })



          


        }

        this.setState({help:list2})   
      });
    }
    }

    _renderRow = ({key, index, data, active}) => {
    return <Row data={data} keyID={key} index={index} active={active} modifyOrder={this.modifyOrder} actionLink={this._actionLink}  />
  }


  _actionLink= (info) => {
    if(info.action=="remove"){
        console.log("remove")

        var value=this.state.help
        value.splice(info.keyID, 1)
        
        
        var i;
        for(i=0;i<value.length;i++){
          value[i].index=i;
          value[i].i=i;
        }

        console.log(value)

        this.setState({help:value,removeInProgress:true},() =>{this.setState({removeInProgress:false}); this.updateHelp()})

      



    } 
    else if(info.action=="update"){
        console.log("update")
        console.log(this.state.help[info.keyID])
        this.setState({viewAddItem:true,element:this.state.help[info.keyID].val,elementID:info.keyID, selectedElement:"fr",})
    }

  }

  modifyOrder= (oldP,newP) => {    

    console.log("oldP "+oldP+" et "+newP)
      var val2 = JSON.parse(JSON.stringify(this.state.help))
      

      if( (oldP>0 || newP==1) && ( oldP<(val2.length-1) || newP==-1) ){
          var i;

          val2[oldP].index=val2[oldP].index+newP;
          if(newP==1)  val2[oldP+1].index=val2[oldP+1].index-1;
          else if(newP=-1)  val2[oldP-1].index=val2[oldP-1].index+1;


          var val=val2.sort( function ( a, b ) { return a.index - b.index  } )
          
          

          this.setState({help:val},() =>{ this.updateHelp() })
      }
      
      
      
  }

  updateHelp(){
    var send={};
    var i;
    for(i=0;i<this.state.help.length;i++){
      send[this.state.help[i].uuid]=this.state.help[i].val;
      send[this.state.help[i].uuid].index=this.state.help[i].index;
    }

    const fireInfo={} 
    fireInfo["/faq"]=send
    update(ref(getDatabase(firebase)), fireInfo)
    


  }


  viewSMSExemple(type, actual, liste){
    console.log("type = "+type)
    console.log(this.state.smsPrefillList)

    var text=(this.state.smsPrefillList[type].fr.name!=undefined)?this.state.smsPrefillList[type].fr.name:""
    var date=date=new Date()
    date=((date.getDate()<10)?"0":"")+date.getDate()+"/"+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+"/"+date.getFullYear()+" - "+((date.getHours()<10)?"0":"")+date.getHours()+":"+((date.getMinutes()<10)?"0":"")+date.getMinutes()
          
    
    text = text.split('%NAME%').join(example.name)
    text = text.split('%LASTNAME%').join(example.lastName)
    text = text.split('%DATE%').join(date)

    text = text.split('%START%').join(example.adress+", "+info.companyCity)
    text = text.split('%END%').join("Gare du Centre, "+info.companyCity)

    text = text.split('%DRIVER%').join("Richard DUPOND"); 
    text = text.split('%TRACKLINK%').join("http://exempleLiensuivi.com"); 
    text = text.split('%PAYLINK%').join("http://exempleLienpaiement.com"); 
    text = text.split('%DURATION%').join("10"); 
    text = text.split('%PRICE%').join("25€"); 

    var elem=JSON.parse(JSON.stringify(this.state.smsPrefillList)); 
    elem[type].fr.nameExemple=text;  

    this.setState({smsPrefillList:elem},() =>{
      if(actual!=undefined) {
        actual+=1; 
        if(actual<liste.length) this.viewSMSExemple(liste[actual], actual, liste);
      } 

    })





  }




  render() {
    return (
      <StyleContext.Consumer>{style => 
      <View style={{backgroundColor:style.backgroundColor,flex:1,}}>
      {(Platform.OS=="web" && this.state.disableNavigation!=true) && <MavNavigationMinimize clickLeft={() => { this.state.params.goBack();  }} left={strings.return} title={ strings.help } />}

      <ScrollView >

      {(this.state.viewAddItem==false && this.state.driver==false && this.state.customerReviews==false && this.state.smsPrefill==false) && this.state.help.map(item => (
          
          <MavViewMore style={{marginTop:0, marginBottom:0}} text={item.name} iconClassic={(style.mode=="dark")?require('../assets/help_white.png'):require('../assets/help_black.png')} >
                <Text style={{marginTop:10,marginBottom:10,marginLeft:"10%", marginRight:"10%",color: style.textInputColor,textAlign: 'justify', fontSize:(item.cgu==true)?10:style.textInputFontSize*0.9, marginTop: 20,color:style.textInputColor }}>{item.description}</Text>
          </MavViewMore>

      ))}
      
      {(this.state.viewAddItem==false && this.state.removeInProgress==false && this.state.driver==true && false && this.state.customerReviews==false && this.state.smsPrefill==false && this.state.help.length>0) && <View>
          <MavInfoBubble type={"info"} title={strings.help} subTitle={strings.helpMoreInfo} style={{marginTop:10,}} />
          
          {this.state.help.map(item => (

            <MavViewMore style={{marginTop:0, marginBottom:0}} text={item.val[strings.idLangue].name} iconClassic={(style.mode=="dark")?require('../assets/help_white.png'):require('../assets/help_black.png')} >

                <MavTextInput style={{marginTop:5}} textInputFlex={3} multiline={true} backgroundColor={style.backgroundColorContrast}   flag={require('../assets/flags/fr.png')} editable={true} text={(this.state.element[this.state.selectedElement]!=undefined && this.state.element[this.state.selectedElement].name!=undefined)?this.state.element[this.state.selectedElement].name:""} textExplication={strings.titre} placeholder={strings.question} onChangeText={(text)=>{var elem=this.state.element; if(typeof(elem[this.state.selectedElement])!="object") elem[this.state.selectedElement]={"name":""} ; elem[this.state.selectedElement].name=text; this.setState({element:elem})       }}/>




            </MavViewMore>

          ))}

          </View>

      }
       
      
      {(this.state.viewAddItem==false && this.state.removeInProgress==false && this.state.driver==true && this.state.customerReviews==false && this.state.smsPrefill==false && this.state.help.length>0) && <SortableList
          style={{height:Dimensions.get('window').height-(5*style.buttonHeight)-30}}
          contentContainerStyle={{}}
          data={this.state.help}
          renderRow={this._renderRow} 
          
          onReleaseRow={(val,currentOrder)=>{ 
              var val2 = this.state.help
              var i;
              for(i=0;i<currentOrder.length;i++){
                    val2[currentOrder[i]].index=i;
              }
              this.setState({help:val2},() =>{ this.updateHelp() })
                


          }} 

      />}

          
      <Modal
        animationType="slide"
        transparent={false}
        visible={( (this.state.viewAddItem==true) && this.state.driver==true)}>

            <View style={{flex: 1,backgroundColor: style.backgroundColor}}>
            <View style={{flex: 1,marginTop: 50}}>

                <View style={{marginLeft:Dimensions.get('window').width * 0.1,marginTop:10, marginRight:Dimensions.get('window').width * 0.1, flexDirection: 'row',justifyContent:"center"}}>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"fr"}) }} > 
                            
                          <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/fr.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="fr" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>

                        </TouchableHighlight>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"en"}) }} > 
                            <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/gb.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="en" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>
                        </TouchableHighlight>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"it"}) }} > 
                            <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/it.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="it" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>
                        </TouchableHighlight>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"es"}) }} > 
                            <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/es.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="es" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>
                        </TouchableHighlight>

                        <TouchableHighlight underlayColor={'rgba(255,255,255,0)'} onPress={() => {this.setState({selectedElement:"ru"}) }} > 
                           <View style={{height: 30,width:40, marginRight:10, }}>
                            <Image source={require('../assets/flags/ru.png')} style={{height: 30,width:40, }}/>
                            {this.state.selectedElement=="ru" && <View style={{position: 'absolute', height:30,width:40,opacity:0.8, backgroundColor:style.backgroundColor,alignItems:"center", justifyContent:"center"}}>
                              <Text style={{color: "#009a00",height:30,width:40,marginTop:-10,fontSize: style.textInputFontSize*2.5,textAlign: "center",}}>{"✓"}</Text>
                            </View>}
                          </View>
                        </TouchableHighlight>


                    

              </View>




               {this.state.viewAddItem==true && <MavTextInput style={{marginTop:5}} textInputFlex={3} multiline={true} editable={true} text={(this.state.element[this.state.selectedElement]!=undefined && this.state.element[this.state.selectedElement].name!=undefined)?this.state.element[this.state.selectedElement].name:""} textExplication={strings.titre} placeholder={strings.question} onChangeText={(text)=>{var elem=this.state.element; if(typeof(elem[this.state.selectedElement])!="object") elem[this.state.selectedElement]={"name":""} ; elem[this.state.selectedElement].name=text; this.setState({element:elem})       }}/>}

               {this.state.viewAddItem==true && <MavTextInput style={{marginTop:5}} textInputFlex={2} multiline={true} editable={true} text={(this.state.element[this.state.selectedElement]!=undefined && this.state.element[this.state.selectedElement].description!=undefined)?this.state.element[this.state.selectedElement].description:""} textExplication={strings.description} placeholder={strings.answer} onChangeText={(text)=>{var elem=this.state.element; if(typeof(elem[this.state.selectedElement])!="object") elem[this.state.selectedElement]={"description":""} ; elem[this.state.selectedElement].description=text; this.setState({element:elem})   }}/>}

               <MavButton text={strings.validate} style={{marginTop:40}} onPress={()=>{ 
                    
                    

                      if(this.state.element["fr"]!=undefined && this.state.element["fr"].name!=undefined && this.state.element["fr"].description!=undefined){
                        var help=this.state.help;
                        if(this.state.elementID==""){
                          help.push({
                            cgu:false,
                            uuid:generateUUID(),
                            i: help.length,
                            index:help.length,
                            val:this.state.element
                          })
                        }
                        else help[this.state.elementID].val=this.state.element;

                        this.setState({help:help,viewAddItem:false},() =>{this.updateHelp()})
                      }
                      else {this.alert.onAlert({title:strings.thanksAllItems});}

                    
                    

                }}/>

                <MavButton text={strings.cancel} strokeMode={true} style={{marginTop:10}} onPress={()=>{ this.setState({viewAddItem:false,})}}/>



          </View>
          </View>
              </Modal>





      {this.state.customerReviews===true && <View>

          <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.activateDriverNotation!=undefined && this.state.activateDriverNotation==true)} text={strings.activateDriverNotation} textMini={strings.activateDriverNotationMoreInfo}  onOnOff={()=>{ this.state.params.updateActivateDriverNotation(!this.state.activateDriverNotation) ; this.setState({activateDriverNotation:!this.state.activateDriverNotation}) }}/>

          <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.activateCustomerNotation!=undefined && this.state.activateCustomerNotation==true)} text={strings.activateCustomerNotation} textMini={strings.activateCustomerNotationMoreInfo}  onOnOff={()=>{ this.state.params.updateActivateCustomerNotation(!this.state.activateCustomerNotation) ;this.setState({activateCustomerNotation:!this.state.activateCustomerNotation}) }}/>

          {(this.state.activateCustomerNotation==true || this.state.activateDriverNotation==true) && <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.activateComment!=undefined && this.state.activateComment==true)} text={strings.activateComment} textMini={strings.activateCommentMoreInfo}  onOnOff={()=>{ this.state.params.updateActivateComment(!this.state.activateComment) ; this.setState({activateComment:!this.state.activateComment})}}/>}



          <TextPresentation reduceTextSizeOnOff={true} editable={false} onoff={(this.state.activateGoogleMyBusiness!=undefined && this.state.activateGoogleMyBusiness==true)} text={strings.activateGoogleMyBusiness} textMini={strings.activateGoogleMyBusinessMoreInfo}  onOnOff={()=>{ this.state.params.updateActivateGoogleMyBusiness(!this.state.activateGoogleMyBusiness) ; this.setState({activateGoogleMyBusiness:!this.state.activateGoogleMyBusiness}) }}/>


          {this.state.activateGoogleMyBusiness==true && <TextPresentation hideTopbar={true} defautVisible={true} explicationChild={strings.gestionGoogleMyBusinessLinkMoreInfo} >

                    {Object.keys(languageList).map((item,languageListI)  => (
                             <TextPresentation style={{marginTop:(languageListI==0)?20:undefined}} reduceTextSize={true} multiline={true} icon={languageList[item].flag }  text={(this.state.googleMyBusinessText[languageList[item].id]!=undefined)?this.state.googleMyBusinessText[languageList[item].id]:""} textExplication={languageList[item].name}   placeholder={"Texte"} onChangeText={(text)=>{ var el=this.state.googleMyBusinessText; el[languageList[item].id]=text;     this.setState({googleMyBusinessText:el})   }}/>
                      ))}


                    <View style={{alignItems:"center",justifyContent:"center", marginTop:40,marginBottom:10, flexDirection:"row"}}>
                        <MavButton text={strings.validate} onlyButton={true}  onPress={()=>{ this.state.params.updateGoogleMyBusinessText(this.state.googleMyBusinessText); this.setState({oldGoogleMyBusinessText:this.state.googleMyBusinessText}); this.alert.onAlert({title:strings.modifyOk})  }}/>
                    </View>


              
          </TextPresentation>}


        
      </View>}



      {this.state.smsPrefill===true && <View>

             <MavInfoBubble type={"info"} title={strings.smsPrefill} subTitle={strings.smsPrefillMoreInfo} style={{marginTop:10,}} />
          

            {this.state.smsPrefillListBase.map((item,i) => (
             <MavViewMore style={{marginTop:0}} ref={smsPr => {this['smsPr_' + i] = smsPr;}} text={ (item=="validate" || item=="validateT" || item=="validateP" || item=="validatePT")?(strings.validatedRace+( (item=="validateT" || item=="validatePT")?(" "+strings.withTrackLink.toLowerCase() ):""  ) + ( (item=="validatePT" || item=="validateP")?(( (item=="validatePT")?" / ":" avec ")+strings.payLink.toLowerCase()):"" ) ): ( (item=="cancelRace")?strings.raceHasBeenCanceled :((item=="imcoming")?strings.SMSImComming :( (item=="imhere")?strings.SMSImHere:strings.confirmCancelClient) ) )  } textMini={(this.state.smsPrefillList[item]!=undefined && this.state.smsPrefillList[item].modif!=undefined)?"("+strings.notSaved+")":undefined} iconClassic={(style.mode=="dark")?require('../assets/driver_message_light.png'):require('../assets/driver_message_dark.png')} >
                
                <Text style={{marginTop:10,marginLeft:"10%", marginRight:"10%",color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",}}>{(item=="validateP")?strings.smsPrefillValidateT:( (item=="validatePT")?strings.smsPrefillValidatePT: (    (item=="validateT")?strings.smsPrefillValidateT:( (item=="imcoming")?strings.smsPrefillImcomming:strings.smsPrefillValidate)))}</Text>


                <View style={{marginTop:10,marginBottom:10, marginLeft:"10%", marginRight:"10%", minHeight:50, backgroundColor:style.backgroundColor,borderRadius: 11,borderWidth: 1, borderColor:style.backgroundColor,shadowOpacity: 0.3,shadowRadius:2,shadowOffset: {height: 0,width: 0},elevation:1 }}>

                <View style={{marginLeft:10, marginRight:10, marginTop:5, flexDirection:"row", justifyContent: 'space-between', alignItems:"center"}} >
                    <View style={{flexDirection:"row", alignItems:"center"}} >
                        <Image source={(Platform.OS=="android")?require("../assets/messageAndroid.png"):require("../assets/messageIOS.png")} style={{borderRadius: 5,borderWidth: 1,borderColor:style.backgroundColor, height:20, width:20,resizeMode:"contain"}}/>
                        <Text style={{marginLeft:5, color: style.textInputColor,fontSize: style.textInputFontSize}}>{strings.messages.toUpperCase()}</Text>
                    </View>
                    <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*0.7}}>{strings.overview}</Text>

                </View>
                <Text style={{marginLeft:10, marginTop:5, color: style.textInputColor,fontSize: style.textInputFontSize,fontWeight: 'bold',}}>{info.companyBrand}</Text>
                <Text style={{marginLeft:10,marginRight:10,  marginTop:0, marginBottom:5, color: style.textInputColor,fontSize: style.textInputFontSize}}>{(this.state.smsPrefillList[item]!=undefined && this.state.smsPrefillList[item].fr!=undefined && this.state.smsPrefillList[item].fr.nameExemple!=undefined)?this.state.smsPrefillList[item].fr.nameExemple:""}</Text>


              </View>


                <MavTextInput style={{marginTop:5}} textInputFlex={3} disableLine={true} multiline={true} backgroundColor={style.backgroundColorContrast} editable={true} text={(this.state.smsPrefillList[item]!=undefined && this.state.smsPrefillList[item].fr!=undefined && this.state.smsPrefillList[item].fr.name!=undefined)?this.state.smsPrefillList[item].fr.name:""} textExplication={strings.text} placeholder={strings.textDefault} onChangeText={(text)=>{var elem=JSON.parse(JSON.stringify(this.state.smsPrefillList)); if(elem[item]!=undefined) elem[item].fr.name=text; else elem[item]={ fr:{name:text} } ; elem[item].modif=true;  this.setState({smsPrefillList:elem},() =>{this.viewSMSExemple(item)})     }}/>
                
                <View style={{width:"100%", alignItems:"center", justifyContent:"center"}}>
                    <MavButton onlyButton={true} text={strings.validate} style={{marginTop:40}}  onPress={()=>{ 

                        if(this.state.smsPrefillList[item]!=undefined && this.state.smsPrefillList[item].fr!=undefined && this.state.smsPrefillList[item].fr.name!=undefined){
                          var linkFire="/prefill/sms/"+item
                          if(this.state.smsPrefillList[item].fr.name=="") {
                            const fireInfo={} 
                            fireInfo[linkFire]=null
                            update(ref(getDatabase(firebase)), fireInfo)
                          }
                          else {
                            const fireInfo={} 
                            fireInfo[linkFire+"/fr"]={name:this.state.smsPrefillList[item].fr.name}
                            update(ref(getDatabase(firebase)), fireInfo)
                          }

                          var elem=JSON.parse(JSON.stringify(this.state.smsPrefillList)); delete elem[item].modif;  this.setState({smsPrefillList:elem})

                        }

                        this['smsPr_' + i].close();  
                    }}/>
                  </View>
              </MavViewMore>))}




        </View>}

      
      <View style={{width:50, height:50, }}/>    
  
      </ScrollView>

      {(this.state.viewAddItem==false && this.state.driver==true && this.state.customerReviews==false && this.state.smsPrefill===false) && <View style={{alignItems:"center",justifyContent:"center", bottom:30}}> 
      <MavButton onlyButton={true} text={strings.add}  onPress={()=>{ 
            this.setState({viewAddItem:true,element:{},elementID:"", selectedElement:"fr",})
            
        }}/>
        </View>}

        <MavAlert ref={alert => {this.alert = alert;}}/>

      </View>
      }</StyleContext.Consumer>
    );
  }
}





export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
