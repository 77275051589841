import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  Easing,
  TouchableHighlight,
  Animated,
  TextInput,
  Dimensions,
  ActivityIndicator,
  Image,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import firebase from './firebase';
import strings from '../strings';
import MavSelector from './mavSelector';
import MavAlert from './mavAlert';
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import RNFetchBlob from './RNFetchBlob';
import MavViewMore from './mavViewMore';
import info from '../info';

const timer = require('react-native-timer');

class Row extends Component {

  constructor(props) {
    super(props);


    this._active = new Animated.Value(0);

    
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      Animated.timing(this._active, {
        duration: 300,
        useNativeDriver: true,
        easing: Easing.bounce,
        toValue: Number(nextProps.active),
      }).start();
    }
  }

  render() {
   

    return (
      <StyleContext.Consumer>{style => 
      <Animated.View style={{flex:1,flexDirection: 'row',justifyContent: 'space-between', width:Dimensions.get('window').width,marginTop:10, marginBottom:10}}>
          

          <MavViewMore style={{width:"100%"}} text={this.props.data.name} iconClassic={(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png')} >

              <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={2}  text={"truc"} textExplication={strings.langue} editable={false} />
              
          </MavViewMore>

          


      </Animated.View>}
      </StyleContext.Consumer>

      

      


      
      


      
    );
  }
}



export default class account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ads:{},
      adsFullScreen:{},      
    };
  }


  loadAds(fullSize){
    var ref=(fullSize===true)?"/settings/adsFullScreen":"/settings/ads"; 
    firebase.database().ref(ref).once('value').then(value => {

      var val2 = value.val();
      if(val2!=null){

          var i;
          var list=Object.keys(val2)
          for(i=0;i<list.length;i++){
                if(val2[list[i]].index==undefined) val2[list[i]].index=i;
          }
           
          var val3={}

          Object.keys(val2).sort(function(a, b){
              if(val2[a] < val2[b]) return -1;
              if(val2[a] > val2[b]) return 1;
              return 0;
          });

          var list=Object.keys(val2).sort( function ( a, b ) { return val2[a].index - val2[b].index  } )

          for(i=0;i<list.length;i++){
            val3[list[i]]=val2[list[i]];
          }
          
          if(val2!=null){
            if(fullSize===true) this.setState({adsFullScreen:val3})
            else this.setState({ads:val3})

          }
    }

    })
  }
  componentDidMount() {

    this.loadAds();
    this.loadAds(true);
    

  }




takePicture(adsType, adsID){
    /*var ImagePicker = require('react-native-image-picker');*/
    ImagePicker=ImagePicker.default;
    var options = {title: strings.selectPicture,chooseFromLibraryButtonTitle:strings.library, takePhotoButtonTitle:strings.camera,cancelButtonTitle:strings.cancel,maxWidth:1024, maxHeight:1024, storageOptions: {skipBackup: true,path: 'images',quality:0.5, base64: true,}};


    ImagePicker.launchImageLibrary(options, (response) => {
      if (response.didCancel) {console.log('User cancelled image picker');}else if (response.error) {console.log('ImagePicker Error: ', response.error);}else if (response.customButton) {console.log('User tapped custom button: ', response.customButton);}
      else {

        var storage=firebase.storage();   
        const image = Platform.OS === 'ios' ? response.uri.replace('file://', '') : response.uri

        var ads=JSON.parse(JSON.stringify( ((adsType=="adsFullScreen")?this.state.adsFullScreen:this.state.ads) )); 
        ads[adsID].data=response.data;
        ads[adsID].apercu=image;
        ads[adsID].dataType=(response.type!="image/jpeg")?( (response.type!="image/gif")? ( (response.type!="image/bmp")? ( (response.type!="image/png")?"tiff":"png" ) :"bmp" ) :"gif" ):"jpg"
        ads[adsID].modif=true;
        if(adsType=="adsFullScreen") this.setState({adsFullScreen:ads}); 
        else  this.setState({ads:ads}) 
        


    }
    });

    
     
  }





  uploadAds(adsType, adsID, i){
                var adsTotal=(adsType=="adsFullScreen")?this.state.adsFullScreen:this.state.ads
                if(adsTotal[adsID].name==undefined || adsTotal[adsID].name=="") this.alert.onAlert({title:strings.thankWriteName});  
                else if(adsTotal[adsID].file==undefined && adsTotal[adsID].apercu==undefined) this.alert.onAlert({title:strings.noFileUpload});  
                else if(adsTotal[adsID].action!=undefined && adsTotal[adsID].action!="" && (adsTotal[adsID].link==undefined || adsTotal[adsID].link=="")) this.alert.onAlert({title:strings.noLink});  
                else {
                    var final=JSON.parse(JSON.stringify(adsTotal[adsID]));
                    var ads=JSON.parse(JSON.stringify(adsTotal));

                    if(adsTotal[adsID].target!=undefined && (adsTotal[adsID].target[0]!=true || adsTotal[adsID].target[1]!=true || adsTotal[adsID].target[2]!=true)) delete final.target;
                    delete ads[adsID].modif;

                    if(adsTotal[adsID].apercu!=undefined){
                      /*je vais envoyer l'image sur le serveur */
                      /*j'affiche un waiting */
                      ads[adsID].waiting=true; 
                      if(adsType=="adsFullScreen") this.setState({adsFullScreen:ads}); 
                      else this.setState({ads:ads}); 

                      firebase.auth().currentUser.getIdToken().then(token => {

                          var bodyInfo={
                              bundleIdentifer:info.bundleIdentifer,
                              bundleAdress:firebase.apps[0].options_.projectId,
                              token:token,
                              userID:firebase.auth().currentUser.uid,
                              data:adsTotal[adsID].data,
                              pictureUsage:"ads",
                              dataType:adsTotal[adsID].dataType,
                          }

                         

                          RNFetchBlob.fetch('POST','https://www.google.com',{ 'Content-Type': 'application/json'},JSON.stringify(bodyInfo)).then(response => {
                                        console.log(response.data)
                                        if(typeof(response.data)=="string") response.data=JSON.parse(response.data)
                                        if(response.data.error==true) console.log("erreur")
                                        else {
                                          ads[adsID].file=response.data.url;
                                          /*si une image existait, je la supprime du serveur */
                                          if(ads[adsID].removeFileUUID!=undefined){
                                            console.log("je supprime "+ads[adsID].removeFileUUID)
                                            var asup=ads[adsID].removeFileUUID+"";
                                            var asup2=ads[adsID].removeFileDataType+"";
                                            firebase.auth().currentUser.getIdToken().then(token => {

                                              var bodyRemove={bundleIdentifer:info.bundleIdentifer,dataType:asup2, bundleAdress:firebase.apps[0].options_.projectId,token:token,userID:firebase.auth().currentUser.uid,uuid:asup,}
                                              RNFetchBlob.fetch('POST','http://52ea3f3.online-server.cloud/remove',{ 'Content-Type': 'application/json'},JSON.stringify(bodyRemove)).then(response => {
                                              })

                                            })

                                          }

                                          ads[adsID].removeFileUUID=response.data.uuid;
                                          ads[adsID].removeFileDataType=ads[adsID].dataType;
                                          /*une fois que j'ai le retour je supprime le waiting, apercu, data, dataType  */
                                          delete ads[adsID].waiting
                                          delete ads[adsID].apercu
                                          delete ads[adsID].data
                                          delete ads[adsID].dataType

                                          if(ads[adsID].modifIndex!=undefined){
                                            
                                            var j,k;
                                            var testVar=false;
                                            for(k=((ads[adsID].index>ads[adsID].oldIndex)?(ads[adsID].oldIndex+1):(ads[adsID].index));k<((ads[adsID].index>ads[adsID].oldIndex)?(ads[adsID].index+1):(ads[adsID].oldIndex));k++){                                  var list=Object.keys(adsTotal);
                                                var list=Object.keys(adsTotal);
                                                for(j=0; j<list.length;j++){
                                                  if(adsID!=list[j] && testVar==false){
                                                      if(adsTotal[list[j]].index==k) {
                                                        if(ads[adsID].index>ads[adsID].oldIndex) ads[list[j]].index=(k-1);
                                                        else ads[list[j]].index=(k+1);
                                                        if(adsType=="adsFullScreen") firebase.database().ref('settings/adsFullScreen/'+list[j]).update({index:ads[list[j]].index})
                                                        else firebase.database().ref('settings/ads/'+list[j]).update({index:ads[list[j]].index})
                                                        testVar=true; 
                                                  }
                                                }
                                                testVar=false;
                                              }
                                            }
                                            delete ads[adsID].oldIndex;
                                            delete ads[adsID].modifIndex;

                                          }
                                          var generate=generateUUID(); 
                                          if(adsID=="new") {
                                              ads[generate]=JSON.parse(JSON.stringify(ads[adsID]))
                                              delete ads[adsID]
                                          }
                                          
                                          if(adsType=="adsFullScreen"){
                                              this.setState({adsFullScreen:ads},() =>{
                                                  if(adsID=="new") firebase.database().ref('settings/adsFullScreen/'+generate).update(ads[generate])
                                                  else firebase.database().ref('settings/adsFullScreen/'+adsID).update(ads[adsID])

                                              })
                                              this['viewAdsFS_' + i].close();
                                          }
                                          else {
                                              /*j'envoi les infos */
                                              this.setState({ads:ads},() =>{
                                                  if(adsID=="new") firebase.database().ref('settings/ads/'+generate).update(ads[generate])
                                                  else firebase.database().ref('settings/ads/'+adsID).update(ads[adsID])

                                              })
                                              this['viewAds_' + i].close();
                                            }
                                        }
                                        
                                    })

            

                      })
                      

                    } else {

                        if(ads[adsID].modifIndex!=undefined){
                                            
                              var j,k;
                              var testVar=false;
                                for(k=((ads[adsID].index>ads[adsID].oldIndex)?(ads[adsID].oldIndex+1):(ads[adsID].index));k<((ads[adsID].index>ads[adsID].oldIndex)?(ads[adsID].index+1):(ads[adsID].oldIndex));k++){                                  var list=Object.keys(adsTotal);
                                  for(j=0; j<list.length;j++){
                                    if(adsID!=list[j] && testVar==false){
                                        if(adsTotal[list[j]].index==k) {
                                          if(ads[adsID].index>ads[adsID].oldIndex) ads[list[j]].index=(k-1);
                                          else ads[list[j]].index=(k+1);
                                          if(adsType=="adsFullScreen") firebase.database().ref('settings/adsFullScreen/'+list[j]).update({index:ads[list[j]].index})
                                          else firebase.database().ref('settings/ads/'+list[j]).update({index:ads[list[j]].index})
                                          testVar=true; 
                                    }
                                  }
                                  testVar=false;
                                }
                              }
                              delete ads[adsID].oldIndex;
                              delete ads[adsID].modifIndex;

                            }

                        if(adsType=="adsFullScreen"){
                            this.setState({adsFullScreen:ads},() =>{
                                if(adsID=="new") firebase.database().ref('settings/adsFullScreen/'+generate).update(ads[generate])
                                else firebase.database().ref('settings/adsFullScreen/'+adsID).update(ads[adsID])

                            })
                            this['viewAdsFS_' + i].close();
                        }
                        else {
                            /*j'envoi les infos */
                            this.setState({ads:ads},() =>{
                                if(adsID=="new") firebase.database().ref('settings/ads/'+generate).update(ads[generate])
                                else firebase.database().ref('settings/ads/'+adsID).update(ads[adsID])

                            })
                            this['viewAds_' + i].close();
                          }
                    }

                }
  }

  removeAds(adsType, adsID, i){
      var adsTotal=(adsType=="adsFullScreen")?this.state.adsFullScreen:this.state.ads
      var ads=(adsType=="adsFullScreen")?this.state.adsFullScreen:this.state.ads;
      if(adsTotal.new==undefined){
        if(adsType=="adsFullScreen") this['viewAdsFS_' + i].close();
        else this['viewAds_' + i].close();

        /*je décale l'index chez les autres*/
        var testVar=false;
        for(k=ads[adsID].index+1;k<Object.keys(ads).length;k++){
              var list=Object.keys(adsTotal);
              for(j=0; j<list.length;j++){
                if(adsID!=list[j] && testVar==false){
                    if(adsTotal[list[j]].index==k) {
                      ads[list[j]].index=(k-1);
                      if(adsType=="adsFullScreen") firebase.database().ref('settings/adsFullScreen/'+list[j]).update({index:ads[list[j]].index})
                      else firebase.database().ref('settings/ads/'+list[j]).update({index:ads[list[j]].index})
                      testVar=true; 
                }
              }
              testVar=false;
            }
        }
        /*je supprime l'image si besoin*/
        if(ads[adsID].removeFileUUID!=undefined){
            console.log("je supprime "+ads[adsID].removeFileUUID)
            var asup=ads[adsID].removeFileUUID+"";
            var asup2=ads[adsID].removeFileDataType+"";
            firebase.auth().currentUser.getIdToken().then(token => {

              var bodyRemove={bundleIdentifer:info.bundleIdentifer,dataType:asup2, bundleAdress:firebase.apps[0].options_.projectId,token:token,userID:firebase.auth().currentUser.uid,uuid:asup,}
              RNFetchBlob.fetch('POST','http://52ea3f3.online-server.cloud/remove',{ 'Content-Type': 'application/json'},JSON.stringify(bodyRemove)).then(response => {
              })

            })

        }

        /*je supprime la ref */
        if(adsType=="adsFullScreen") firebase.database().ref('settings/adsFullScreen/'+adsID).remove()
        else firebase.database().ref('settings/ads/'+adsID).remove()
        
        delete ads[adsID]
        if(adsType=="adsFullScreen") this.setState({adsFullScreen:ads}); 
        else this.setState({ads:ads}); 


      }
      else {
        delete ads.new
        if(adsType=="adsFullScreen") this.setState({adsFullScreen:ads}); 
        else this.setState({ads:ads}); 
      }

  }
  

  render() {

    return (
      <StyleContext.Consumer>{style => 
      <ScrollView style={{flex:1,backgroundColor:style.backgroundColor}}>
      <View style={{flex:1,marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor}}>

            <Text style={{marginTop:15,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.adsBottom}</Text>
            <Text style={{marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",marginLeft:"10%",width: "80%",}}>{strings.adsBottomMoreInfo}</Text>



          {Object.keys(this.state.ads).map((adsID,i) => ( <MavViewMore disable={(this.state.ads.new!=undefined)?true:undefined} onOpen={()=>{ 
            if(this.state.ads.new==undefined){ 
              for(var k=0; k<Object.keys(this.state.ads).length;k++){if(k!=parseInt(i)) this['viewAds_' + k].close();}
            } 
            }} 
            ref={viewAds => {this['viewAds_' + i] = viewAds;}} textMini={(this.state.ads[adsID].modif==true && adsID!="new")?"(Modifications non enregistrées)":undefined} text={(this.state.ads[adsID].name!=undefined &&this.state.ads[adsID].name!="")?this.state.ads[adsID].name:strings.adsNoName} iconClassic={(style.mode=="dark")?require('../assets/megaphone_white.png'):require('../assets/megaphone_black.png')} >

            <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.takePicture("ads",adsID)}}>
                <View style={{marginTop:15,marginLeft:"10%", marginRight:"10%", height:50, backgroundColor:style.backgroundColor,borderRadius: 5,borderWidth: 1, borderColor:style.backgroundColor,shadowOpacity: 0.3,shadowRadius:2,shadowOffset: {height: 0,width: 0},elevation:1 }}>

                    {(this.state.ads[adsID].apercu==undefined && this.state.ads[adsID].file!=undefined) && <Image source={{uri:this.state.ads[adsID].file}} style={{borderRadius: 5,borderWidth: 1,borderColor:style.backgroundColor, height:"100%", width:"100%",resizeMode:"contain"}}/>}
                    {this.state.ads[adsID].apercu!=undefined && <Image source={{uri:this.state.ads[adsID].apercu}} style={{borderRadius: 5,borderWidth: 1,borderColor:style.backgroundColor, height:"100%", width:"100%",resizeMode:"contain"}}/>}
                    {(this.state.ads[adsID].apercu==undefined) && <View style={{position:"absolute", width:"100%", height:"100%",justifyContent:"center", alignItems:"center",}}> 
                    <Text style={{ textAlign:"center", color: (this.state.ads[adsID].file!=undefined)?style.backgroundColorContrast:style.textInputColor,fontSize: style.textInputFontSize*1.2}}>{(this.state.ads[adsID].file!=undefined)?strings.clickToModify:strings.defineImageAds}</Text>
                    </View>}

                </View>
            </TouchableHighlight>


            <MavTextInput style={{marginTop:5}} textInputFlex={1.5} backgroundColor={style.backgroundColorContrast} text={this.state.ads[adsID].name} textExplication={strings.adsName} placeholder={"ex : Hotel du lac"} onChangeText={(text)=>{var ads=JSON.parse(JSON.stringify(this.state.ads)); ads[adsID].name=text; ads[adsID].modif=true;    this.setState({ads:ads})}}/>
            
            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1.5} enableButtonsLH={true} text={this.state.ads[adsID].duration+"s"}  textExplication={strings.duration}  onChange={(value)=>{ 
                    var ads=JSON.parse(JSON.stringify(this.state.ads));
                    ads[adsID].duration+=(value==0)?-1:1;
                    if(ads[adsID].duration<1) ads[adsID].duration=1;
                    ads[adsID].modif=true;    
                    this.setState({ads:ads})
            }} />



            {(this.state.ads.new==undefined && Object.keys(this.state.ads).length>1) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1.5} enableInfo={true} onEnableInfo={() => {this.alert.onAlert({title:strings.position, subTitle:strings.positionAdsMoreInfos}) }} enableButtonsLH={true} text={"n°"+(this.state.ads[adsID].index+1)}  textExplication={strings.position}  onChange={(value)=>{ 
                    var ads=JSON.parse(JSON.stringify(this.state.ads));
                    ads[adsID].oldIndex=parseInt(ads[adsID].index);
                    ads[adsID].index+=(value==0)?-1:1;
                    if(ads[adsID].index<0) ads[adsID].index=0;
                    else if(ads[adsID].index>(Object.keys(this.state.ads).length-1)) ads[adsID].index=Object.keys(this.state.ads).length-1


                    ads[adsID].modif=true;   
                    ads[adsID].modifIndex=true;  
                    this.setState({ads:ads})
            }} />}
            

            

            <Text style={{marginLeft:"10%", marginTop:15, color: style.textInputColor,fontSize: style.textInputFontSize}}>{strings.actionOnClick}</Text>

            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true}  arrowCircle={true} arrow={true} arrowEnabled={this.state.ads[adsID].action=="" || this.state.ads[adsID].action==undefined} textExplication={strings.doNothing} onChange={()=>{var ads=JSON.parse(JSON.stringify(this.state.ads)); ads[adsID].action=""; ads[adsID].modif=true;    this.setState({ads:ads}) }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true}  arrowCircle={true} arrow={true} arrowEnabled={this.state.ads[adsID].action=="web"} textExplication={strings.goWeb} onChange={()=>{var ads=JSON.parse(JSON.stringify(this.state.ads)); ads[adsID].action="web"; ads[adsID].modif=true;    this.setState({ads:ads})   }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true}  arrowCircle={true} arrow={true} arrowEnabled={this.state.ads[adsID].action=="contact"} textExplication={strings.goContact} onChange={()=>{var ads=JSON.parse(JSON.stringify(this.state.ads)); ads[adsID].action="contact"; ads[adsID].modif=true;    this.setState({ads:ads})  }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true}  arrowCircle={true} arrow={true} arrowEnabled={this.state.ads[adsID].action=="clipboard"} textExplication={strings.goCopyClipboard} onChange={()=>{var ads=JSON.parse(JSON.stringify(this.state.ads)); ads[adsID].action="clipboard"; ads[adsID].modif=true;    this.setState({ads:ads})  }}/>

            {(this.state.ads[adsID].action!="" && this.state.ads[adsID].action!=undefined) && <MavTextInput style={{marginTop:0}} backgroundColor={style.backgroundColorContrast} textInputFlex={1.5} text={this.state.link} textExplication={(this.state.ads[adsID].action=="web")?strings.link:( (this.state.ads[adsID].action=="contact")? strings.preFillContact:strings.textCopyClipboard     )} placeholder={(this.state.ads[adsID].action=="web")?"https://www.hotel-du-lac.com":( (this.state.ads[adsID].action=="contact")? "Bonjour, j'aimerais plus d'info":"HAPPY10" )} onChangeText={(text)=>{var ads=JSON.parse(JSON.stringify(this.state.ads)); ads[adsID].link=text; ads[adsID].modif=true;    this.setState({ads:ads})}}/>}



            <Text style={{marginLeft:"10%", marginTop:15, color: style.textInputColor,fontSize: style.textInputFontSize}}>{strings.target}</Text>
            <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.ads[adsID].target==undefined || this.state.ads[adsID].target[0]==true} textExplication={strings.customer}   onChange={()=>{ var ads=JSON.parse(JSON.stringify(this.state.ads)); if(this.state.ads[adsID].target==undefined) ads[adsID].target=[true, true, true]; ads[adsID].target[0]=(ads[adsID].target[0]==true)?false:true; ads[adsID].modif=true;    this.setState({ads:ads})  }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.ads[adsID].target==undefined || this.state.ads[adsID].target[1]==true} textExplication={strings.partner}   onChange={()=>{ var ads=JSON.parse(JSON.stringify(this.state.ads)); if(this.state.ads[adsID].target==undefined) ads[adsID].target=[true, true, true]; ads[adsID].target[1]=(ads[adsID].target[1]==true)?false:true; ads[adsID].modif=true;    this.setState({ads:ads})  }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.ads[adsID].target==undefined || this.state.ads[adsID].target[2]==true} textExplication={strings.providerAffair}   onChange={()=>{ var ads=JSON.parse(JSON.stringify(this.state.ads)); if(this.state.ads[adsID].target==undefined) ads[adsID].target=[true, true, true]; ads[adsID].target[2]=(ads[adsID].target[2]==true)?false:true; ads[adsID].modif=true;    this.setState({ads:ads})  }}/>
            
            {this.state.ads[adsID].waiting!=true && <MavButton text={strings.validate} style={{marginTop:40}} onPress={()=>{  this.uploadAds("ads", adsID, i) }}/>}

             {this.state.ads[adsID].waiting!=true && <MavButton text={(this.state.ads.new==undefined)?strings.remove :strings.cancel} strokeMode={true} style={{marginTop:10}} onPress={()=>{ this.removeAds("ads", adsID, i); }}/>}

             {this.state.ads[adsID].waiting==true && <ActivityIndicator style={{marginTop:40}} size="large" color={style.color1} />}



          </MavViewMore>))}



            {(this.state.ads.new==undefined) && <View style={{alignItems:"center",justifyContent:"center", marginTop:30}}>
                <MavButton text={strings.add} onlyButton={true} onPress={()=>{ var ads=JSON.parse(JSON.stringify(this.state.ads)); ads.new={duration:5, index:Object.keys(this.state.ads).length}; this.setState({ads:ads},() =>{ 
                      var k;
                      for(var k=0; k<Object.keys(this.state.ads).length;k++){this['viewAds_' + k].close();}
                      this['viewAds_' + (Object.keys(this.state.ads).length-1)].open();
                 })    }}/>
            </View>}


             <Text style={{marginTop:30,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.adsFullScreen}</Text>
             <Text style={{marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",marginLeft:"10%",width: "80%",}}>{strings.adsFullScreenMoreInfo}</Text>

















             {Object.keys(this.state.adsFullScreen).map((adsID,i) => ( <MavViewMore disable={(this.state.adsFullScreen.new!=undefined)?true:undefined} onOpen={()=>{ 
            if(this.state.adsFullScreen.new==undefined){ 
              for(var k=0; k<Object.keys(this.state.adsFullScreen).length;k++){if(k!=parseInt(i)) this['viewAdsFS_' + k].close();}
            } 
            }} 
            ref={viewAdsFS => {this['viewAdsFS_' + i] = viewAdsFS;}} textMini={(this.state.adsFullScreen[adsID].modif==true && adsID!="new")?"(Modifications non enregistrées)":undefined} text={(this.state.adsFullScreen[adsID].name!=undefined &&this.state.adsFullScreen[adsID].name!="")?this.state.adsFullScreen[adsID].name:strings.adsNoName} iconClassic={(style.mode=="dark")?require('../assets/megaphone_white.png'):require('../assets/megaphone_black.png')} >

            <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {this.takePicture("adsFullScreen", adsID)}}>
                <View style={{marginTop:15,marginLeft:"10%", marginRight:"10%", height:250, backgroundColor:style.backgroundColor,borderRadius: 5,borderWidth: 1, borderColor:style.backgroundColor,shadowOpacity: 0.3,shadowRadius:2,shadowOffset: {height: 0,width: 0},elevation:1 }}>

                    {(this.state.adsFullScreen[adsID].apercu==undefined && this.state.adsFullScreen[adsID].file!=undefined) && <Image source={{uri:this.state.adsFullScreen[adsID].file}} style={{borderRadius: 5,borderWidth: 1,borderColor:style.backgroundColor, height:"100%", width:"100%",resizeMode:"contain"}}/>}
                    {this.state.adsFullScreen[adsID].apercu!=undefined && <Image source={{uri:this.state.adsFullScreen[adsID].apercu}} style={{borderRadius: 5,borderWidth: 1,borderColor:style.backgroundColor, height:"100%", width:"100%",resizeMode:"contain"}}/>}
                    {(this.state.adsFullScreen[adsID].apercu==undefined) && <View style={{position:"absolute", width:"100%", height:"100%",justifyContent:"center", alignItems:"center",}}> 
                    <Text style={{ textAlign:"center", color: (this.state.adsFullScreen[adsID].file!=undefined)?style.backgroundColorContrast:style.textInputColor,fontSize: style.textInputFontSize*1.2}}>{(this.state.adsFullScreen[adsID].file!=undefined)?strings.clickToModify:strings.defineImageAds}</Text>
                    </View>}

                </View>
            </TouchableHighlight>


            <MavTextInput style={{marginTop:5}} textInputFlex={1.5} backgroundColor={style.backgroundColorContrast} text={this.state.adsFullScreen[adsID].name} textExplication={strings.adsName} placeholder={"ex : Hotel du lac"} onChangeText={(text)=>{var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); ads[adsID].name=text; ads[adsID].modif=true;    this.setState({adsFullScreen:ads})}}/>
            

            {(this.state.adsFullScreen.new==undefined && Object.keys(this.state.adsFullScreen).length>1) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1.5} enableInfo={true} onEnableInfo={() => {this.alert.onAlert({title:strings.position, subTitle:strings.positionAdsMoreInfos}) }} enableButtonsLH={true} text={"n°"+(this.state.adsFullScreen[adsID].index+1)}  textExplication={strings.position}  onChange={(value)=>{ 
                    var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen));
                    ads[adsID].oldIndex=parseInt(ads[adsID].index);
                    ads[adsID].index+=(value==0)?-1:1;
                    if(ads[adsID].index<0) ads[adsID].index=0;
                    else if(ads[adsID].index>(Object.keys(this.state.adsFullScreen).length-1)) ads[adsID].index=Object.keys(this.state.adsFullScreen).length-1


                    ads[adsID].modif=true;   
                    ads[adsID].modifIndex=true;  
                    this.setState({adsFullScreen:ads})
            }} />}
            

            

            <Text style={{marginLeft:"10%", marginTop:15, color: style.textInputColor,fontSize: style.textInputFontSize}}>{strings.actionOnClick}</Text>

            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true}  arrowCircle={true} arrow={true} arrowEnabled={this.state.adsFullScreen[adsID].action=="" || this.state.adsFullScreen[adsID].action==undefined} textExplication={strings.doNothing} onChange={()=>{var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); ads[adsID].action=""; ads[adsID].modif=true;    this.setState({adsFullScreen:ads}) }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true}  arrowCircle={true} arrow={true} arrowEnabled={this.state.adsFullScreen[adsID].action=="web"} textExplication={strings.goWeb} onChange={()=>{var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); ads[adsID].action="web"; ads[adsID].modif=true;    this.setState({adsFullScreen:ads})   }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true}  arrowCircle={true} arrow={true} arrowEnabled={this.state.adsFullScreen[adsID].action=="contact"} textExplication={strings.goContact} onChange={()=>{var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); ads[adsID].action="contact"; ads[adsID].modif=true;    this.setState({adsFullScreen:ads})  }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:-5}} textInputFlex={0.8} disableLine={true} disabledText={true}  arrowCircle={true} arrow={true} arrowEnabled={this.state.adsFullScreen[adsID].action=="clipboard"} textExplication={strings.goCopyClipboard} onChange={()=>{var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); ads[adsID].action="clipboard"; ads[adsID].modif=true;    this.setState({adsFullScreen:ads})  }}/>

            {(this.state.adsFullScreen[adsID].action!="" && this.state.adsFullScreen[adsID].action!=undefined) && <MavTextInput style={{marginTop:0}} backgroundColor={style.backgroundColorContrast} textInputFlex={1.5} text={this.state.link} textExplication={(this.state.adsFullScreen[adsID].action=="web")?strings.link:( (this.state.adsFullScreen[adsID].action=="contact")? strings.preFillContact:strings.textCopyClipboard     )} placeholder={(this.state.adsFullScreen[adsID].action=="web")?"https://www.hotel-du-lac.com":( (this.state.adsFullScreen[adsID].action=="contact")? "Bonjour, j'aimerais plus d'info":"HAPPY10" )} onChangeText={(text)=>{var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); ads[adsID].link=text; ads[adsID].modif=true;    this.setState({adsFullScreen:ads})}}/>}



            <Text style={{marginLeft:"10%", marginTop:15, color: style.textInputColor,fontSize: style.textInputFontSize}}>{strings.target}</Text>
            <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.adsFullScreen[adsID].target==undefined || this.state.adsFullScreen[adsID].target[0]==true} textExplication={strings.customer}   onChange={()=>{ var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); if(this.state.adsFullScreen[adsID].target==undefined) ads[adsID].target=[true, true, true]; ads[adsID].target[0]=(ads[adsID].target[0]==true)?false:true; ads[adsID].modif=true;    this.setState({adsFullScreen:ads})  }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.adsFullScreen[adsID].target==undefined || this.state.adsFullScreen[adsID].target[1]==true} textExplication={strings.partner}   onChange={()=>{ var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); if(this.state.adsFullScreen[adsID].target==undefined) ads[adsID].target=[true, true, true]; ads[adsID].target[1]=(ads[adsID].target[1]==true)?false:true; ads[adsID].modif=true;    this.setState({adsFullScreen:ads})  }}/>
            <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:0}} textInputFlex={0.8} disableLine={true} disabledText={true} arrow={true} arrowEnabled={this.state.adsFullScreen[adsID].target==undefined || this.state.adsFullScreen[adsID].target[2]==true} textExplication={strings.providerAffair}   onChange={()=>{ var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); if(this.state.adsFullScreen[adsID].target==undefined) ads[adsID].target=[true, true, true]; ads[adsID].target[2]=(ads[adsID].target[2]==true)?false:true; ads[adsID].modif=true;    this.setState({adsFullScreen:ads})  }}/>
            
            {this.state.adsFullScreen[adsID].waiting!=true && <MavButton text={strings.validate} style={{marginTop:40}} onPress={()=>{  this.uploadAds("adsFullScreen", adsID, i) }}/>}

             {this.state.adsFullScreen[adsID].waiting!=true && <MavButton text={(this.state.adsFullScreen.new==undefined)?strings.remove :strings.cancel} strokeMode={true} style={{marginTop:10}} onPress={()=>{ this.removeAds("adsFullScreen", adsID, i); }}/>}

             {this.state.adsFullScreen[adsID].waiting==true && <ActivityIndicator style={{marginTop:40}} size="large" color={style.color1} />}



          </MavViewMore>))}


























              {(this.state.adsFullScreen.new==undefined) && <View style={{alignItems:"center",justifyContent:"center", marginTop:30}}>
                <MavButton text={strings.add} onlyButton={true} onPress={()=>{ 

                  var ads=JSON.parse(JSON.stringify(this.state.adsFullScreen)); ads.new={index:Object.keys(this.state.adsFullScreen).length}; this.setState({adsFullScreen:ads},() =>{ 
                      var k;
                      for(var k=0; k<Object.keys(this.state.adsFullScreen).length;k++){this['viewAdsFS_' + k].close();}
                      this['viewAdsFS_' + (Object.keys(this.state.adsFullScreen).length-1)].open();
                 })   



                 }}/>
            </View>}


            


          


       

      </View>

       

      <MavAlert ref={alert => {this.alert = alert;}}/>
  </ScrollView>
  }</StyleContext.Consumer>
    );
  }
}




export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
