import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Linking,
  Image,
  Picker,
  Platform,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import {StyleContext} from '../styleC';
import info from '../info';
import strings from '../strings';
import MavButton from './mavButton';
import colorList from '../colorList';
import TextPresentation from './textPresentation';
const timer = require('react-native-timer');
import Modal from './modal';
import BookingView from './bookingView';


export default class mavAlert extends Component {
  state = {
   viewElem:true



  };
componentDidMount(){



}

  

  render() {
    return (
      <StyleContext.Consumer>{style => 
    


     <Modal
          animationType="none"
          transparent={true}
          visible={(this.props.navigation.getState().routes[this.props.navigation.getState().index].name=="Main" && this.state.viewElem==true)}>

          <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",justifyContent:"center"}}>


              <TouchableHighlight underlayColor={"rgba(0,0,0,0.5)"} style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,backgroundColor:"rgba(0,0,0,0.5)",position:"absolute",marginTop:0}} onPress={() => {console.log("click nodal") }}>
                  <View/>
              </TouchableHighlight>



              <View style={{width:(Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth:(Dimensions.get('window').width*0.9) ,backgroundColor:style.backgroundColor,position:"absolute",marginLeft:(Dimensions.get('window').width>style.mobileSizeMax)?((Dimensions.get('window').width-style.minimizeWidth)/2):(Dimensions.get('window').width*0.05),shadowOpacity: 0.6,shadowRadius: 2,shadowOffset: {height: 0,width: 0},elevation: 3}}>
                <Text style={{marginTop:(this.state.check==true || this.state.waiting==true)?-5:15,marginLeft:15,marginRight:15, marginBottom:(this.state.waiting==true && this.state.subTitle=="")?40:undefined, color: style.textInputColor,fontSize: style.textInputFontSize*1.5*( (this.state.maxiMode==true)?((Dimensions.get('window').width>1000)?2:1.8):1),textAlign: "center",}}>{((Object.keys(this.props.race).length==1)?strings.yourAreWaitConfirmRace:strings.yourAreWaitConfirmRaces)}</Text>
                
                {Object.keys(this.props.race).length<4 && Object.keys(this.props.race).map((rowData,i)=>( <TouchableHighlight underlayColor={"#FFFFFFF"} onPress={() => {this.setState({viewElem:false});this.props.openRace(this.props.race[rowData],rowData); timer.setTimeout("viewElem", () => {this.setState({viewElem:true}) }, 6500);   }}>
                                 <BookingView   mode={"confirmRace"} data={this.props.race[rowData]} style={{width:"90%", marginLeft:"5%",marginTop:20, marginBottom:20}}/>
                            </TouchableHighlight>
                ))}

                {Object.keys(this.props.race).length>3 && <ScrollView style={{maxHeight:400}}>
                   {Object.keys(this.props.race).map((rowData,i)=>( <TouchableHighlight underlayColor={"#FFFFFFF"} onPress={() => {this.setState({viewElem:false});this.props.openRace(this.props.race[rowData],rowData); timer.setTimeout("viewElem", () => {this.setState({viewElem:true}) }, 6500);   }}> 
                                 <BookingView   mode={"confirmRace"} data={this.props.race[rowData]} style={{width:"90%", marginLeft:"5%",marginTop:20, marginBottom:20}}/>
                            </TouchableHighlight>
                ))}

                </ScrollView>}


                <View style={{alignItems:"center",justifyContent:"center",marginTop:15,marginBottom:10}}>
                     <MavButton text={strings.close} onlyButton={true} onPress={()=>{ this.props.closeConfirmRace()    }}/>
                  </View>






              </View>


              
              

              
 
        </View>


        </Modal>

        }</StyleContext.Consumer>
      
      )}

    }


    export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

