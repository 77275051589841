import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  Keyboard,
  TouchableHighlight,
  Clipboard,
  Linking,
  Image,
  Picker,
  Platform,
  ScrollView,
} from 'react-native';
import {StyleContext,styleInfo} from '../styleC'; 
import info from '../info';
import strings from '../strings';
const timer = require('react-native-timer');
import MavAlert from './mavAlert';
import MavMiniButton from './mavMiniButton';

import Modal from './modal';
import MavButton from './mavButton';

import QRCode from 'react-native-qrcode-generator';
export default class MavSelectDate extends Component {


  state = {
    modalVisible: false,
    title:strings.trackLink, 
    subTitle:strings.scanCode, 
    url:"",
    phone:"",
    callback:"",
    dispenser:false,
    date:undefined,
    
  };


  onPicker(info){

    var st={modalVisible:true, url:info.url, urlWeb:"https://api.qrserver.com/v1/create-qr-code/?data="+info.url}
    if(info.phone!=undefined && info.phone!="") st.phone=info.phone
    if(info.date!=undefined && info.date!="") st.date=info.date
    if(info.callback!=undefined && info.callback!="") st.callback=info.callback;
    if(info.dispenser==true) {st.dispenser=true; st.title=strings.confirmationNewValidationRace; st.subTitle=strings.youCanScanThisQrcode}
    this.setState(st)

  }

  sendWithSMS(message,phone){
    Linking.openURL("sms:"+((phone==undefined)?this.state.phone:phone)+((Platform.OS === 'ios')?"&":"?")+"body="+message)
  } 

  sendLink(sendMode){

    if(sendMode=="copy"){
      this.setState({modalVisible:false});
      Clipboard.setString(this.state.url);
      this.alert.onAlert({title:strings.copiedClipboard,buttons:[{text: strings.ok,onPress: () => {  if(this.state.callback!="") this.state.callback();   }}]});
    }
    else if(sendMode=="phone"){
        var textRace="Bonjour, voici le lien de suivi de votre course"; 
        if(this.state.date!=undefined) textRace+=(" du "+this.state.date)
        textRace+=(" : "+this.state.url)

        this.setState({modalVisible:false});
        this.alert.onAlert({title:strings.howContactCustomer,buttons:
            [{text: strings.withSMS,onPress: () => {
              this.sendWithSMS(textRace)
              if(this.state.callback!="") this.state.callback();
            }},
            {text: strings.withWhatsApp,onPress: () => {
              var phone=this.state.phone;
              if(phone!=undefined && phone[0]==0 && info.countryCode=="fr") phone="33"+phone;
              Linking.openURL('whatsapp://send?text='+textRace+((Platform.OS === 'ios')?"&":"?")+"phone="+phone )
              if(this.state.callback!="") this.state.callback();
                
            }},
            {text: strings.cancel,onPress: () => {if(this.state.callback!="") this.state.callback(); }},
            ]
        })


    }
    else {
        if(this.state.callback!="") this.state.callback();
        this.setState({modalVisible:false})
    }



    

  }

  componentDidMount () {

    console.log("QR code mount")
        
  }





  render() {
    


    return (
    <StyleContext.Consumer>{style => 
    <View style={{flex:1}}>



     <Modal
          animationType="none"
          transparent={true}
          visible={this.state.modalVisible}>
          <View style={{backgroundColor:style.backgroundColor, height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute",alignItems:'center'}}>
          {(Dimensions.get('window').width<Dimensions.get('window').height) && <Image source={require("../assets/login_logo.png")} style={{marginTop:(this.state.dispenser==true)?20:50,height:Dimensions.get('window').height*((this.state.dispenser==true)?0.08:0.1),resizeMode:"contain"}}/>}
        
          {<View style={{shadowOpacity: 0.8, shadowColor:(style.mode=="dark")?'#A0A2A2':"#4D4D4D", shadowRadius: 0.2,shadowOffset: {height: 0,width: 0},elevation: 1,marginTop:30,marginBottom:30,marginLeft:"10%", marginRight:"10%",}}>
              <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*1.4*((this.state.dispenser==true)?((Dimensions.get('window').width>1000)?3:2.3):1),textAlign: "center",}}>{this.state.title}</Text> 
              {false && <Text style={{color: style.textInputColor,fontSize: style.textInputFontSize*((this.state.dispenser==true)?((Dimensions.get('window').width>1000 && Dimensions.get('window').height>1000)?2.5:2.2):1),textAlign: "center",}}>{this.state.subTitle}</Text> }
          </View>}

          

          {true && Platform.OS!="web" && <View style={{backgroundColor:"#FFFFFF",width:((Dimensions.get('window').width<Dimensions.get('window').height)?Dimensions.get('window').width:Dimensions.get('window').height*0.75)*0.8, height:(((Dimensions.get('window').width<Dimensions.get('window').height)?Dimensions.get('window').width:Dimensions.get('window').height*0.75)*0.8)* ((this.state.dispenser==true && (Dimensions.get('window').width<1000 ||Dimensions.get('window').height<1000 ))?0.8:1),alignItems:'center', justifyContent:'center' }}>
           <QRCode
            value={this.state.url}
            size={ (((Dimensions.get('window').width<Dimensions.get('window').height)?Dimensions.get('window').width:Dimensions.get('window').height*0.75)*0.75)* ((this.state.dispenser==true && (Dimensions.get('window').width<1000 ||Dimensions.get('window').height<1000))?0.8:1) }
            
          />
          </View>}

          {Platform.OS=="web" && <View style={{backgroundColor:"#FFFFFF",width:((Dimensions.get('window').width<Dimensions.get('window').height)?Dimensions.get('window').width:Dimensions.get('window').height*0.75)*0.8, height:(((Dimensions.get('window').width<Dimensions.get('window').height)?Dimensions.get('window').width:Dimensions.get('window').height*0.75)*0.8)* ((this.state.dispenser==true && (Dimensions.get('window').width<1000 ||Dimensions.get('window').height<1000 ))?0.8:1),alignItems:'center', justifyContent:'center' }}>
              <Image source={{uri:this.state.urlWeb}} style={{backgroundColor:"#FFFFFF",width:((Dimensions.get('window').width<Dimensions.get('window').height)?Dimensions.get('window').width:Dimensions.get('window').height*0.75)*0.8, height:(((Dimensions.get('window').width<Dimensions.get('window').height)?Dimensions.get('window').width:Dimensions.get('window').height*0.75)*0.8)* ((this.state.dispenser==true && (Dimensions.get('window').width<1000 ||Dimensions.get('window').height<1000 ))?0.8:1),alignItems:'center', justifyContent:'center' }}/>

          </View>}


          

          <View style={{flexDirection:"row",marginTop:30, justifyContent:"space-between", }}>

              {this.state.dispenser!=true && <MavMiniButton icon={(style.mode=="dark")?require('../assets/copy_dark.png'):require('../assets/copy_light.png')} text={strings.copyLink} onlyButton={true}  onPress={()=>{ this.sendLink("copy")  }} />}

              {this.state.dispenser!=true && <MavMiniButton style={{marginLeft:25, marginRight:25}} icon={(style.mode=="dark")?require('../assets/driver_message_dark.png'):require('../assets/driver_message_light.png')} text={strings.send} onlyButton={true}  onPress={()=>{ this.sendLink("phone") }} />}

               <MavMiniButton icon={(style.mode=="dark")?require('../assets/close_black.png'):require('../assets/close_white.png')} text={strings.close} color={style.colorNotification} onlyButton={true}  onPress={()=>{ this.sendLink() }} />


              

          </View>

          </View>


        </Modal>


        <MavAlert ref={alert => {this.alert = alert;}}/>

        </View>

        }</StyleContext.Consumer>
      
      )}

    }


    



















