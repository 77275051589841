import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  ScrollView,
  Animated,
  ActivityIndicator,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import countryList from '../countryList';
import moneyList from '../moneyList';

import Modal from './modal';

const timer = require('react-native-timer');
import MavButton from './mavButton';
import NotificationWeb from './notificationWeb';
import firebase from './firebase';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, equalTo, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";
import MavAlert from './mavAlert';

export default class TextPresentation extends Component {
constructor(props) { 
    super(props);



   
    
    this.state = {
      view:false,
      displayList:{},
    }
  }


close(){
    Animated.timing(this.onOffButtonPlace, {toValue: 376,duration: 300, useNativeDriver: false}).start()
    timer.setTimeout("viewOff", () => { this.setState({view:false})  }, 400);
  }

  open(){
    this.setState({view:true},() =>{Animated.timing(this.onOffButtonPlace, {toValue: 0,duration: 300, useNativeDriver: false}).start() })
    
  }


  removeNotif(id){
    
     Animated.timing(this.state[id], {toValue: 376,duration: 500, useNativeDriver: false}).start() 
     timer.setTimeout(("startremove"+id), () => {
          var displayList=JSON.parse(JSON.stringify((this.state.displayList)))
          delete displayList[id]
          this.setState({displayList:displayList})
     }, 520)

     
  }


  setNotif(id){

      var animate={}
      animate[id]=new Animated.Value(376);
      this.setState(animate)
      this.setState(animate,() =>{ Animated.timing(this.state[id], {toValue: 0,duration: 500, useNativeDriver: false}).start()  })
      timer.setTimeout(("remove"+id), () => {this.removeNotif(id) }, 8000)

  }

  componentDidMount() {
    console.log("je vais notificationAdmin")

    onValue(ref(getDatabase(firebase), "/notificationAdmin"), (value) => {
          console.log("j'ai passé notificationAdmin")
          var val2 = value.val(); if(val2!=null){ 


            var valArray=Object.keys(val2);



             valArray.sort(function (a, b) {
                if (val2[a].date < val2[b].date) return 1;
                else if (val2[a].date > val2[b].date) return -1;
                return 0;
              });

            console.log(valArray)
            var i;
            var val={}
            for(i=0; i<valArray.length; i++){
              val[valArray[i]]=val2[valArray[i]]
            }

            var displayList=JSON.parse(JSON.stringify((this.state.displayList)))

            var list=Object.keys(val)
            var i; 
            var timeout=Date.parse(new Date())+10*60000;
            const fireInfoB={} 
            var sendFire=false;
              

            for(i=0; i<list.length;i++){
                if(val[list[i]].notView==true && displayList[list[i]]==undefined) {
                      displayList[list[i]]=val[list[i]]
                      this.setNotif(list[i],val[list[i]])
                      fireInfoB["notificationAdmin/" + list[i]+"/notView"]=null
                      sendFire=true;


                  }  
            }
            if(sendFire==true) update(ref(getDatabase(firebase)), fireInfoB)
            this.setState({displayList:displayList, totalList:val})

          


          } 
        })

      
     

  }



  render() {

    this.onOffButtonPlace = new Animated.Value(376);

    return (
      <StyleContext.Consumer>{style => 
    <View style={(this.props.style==undefined)?{}:this.props.style}>

         {this.state.view!=true && Object.keys(this.state.displayList).map((marker,i) => (
          <Animated.View key={marker} style={{left:this.state[marker], marginTop:10, marginRight:10  }}>
                <NotificationWeb data={this.state.displayList[marker]} onNotificationClick={(data) => { this.props.onNotificationClick(data) }} />
          </Animated.View>
          ))}


        {this.state.view==true && <Animated.View style={{width:376, left:this.onOffButtonPlace,borderWidth: 1, alignItems:"center", height:Dimensions.get('window').height-2, backgroundColor:"#343434E9", borderColor:"#B3B3B3", shadowOpacity: 0.3,shadowRadius: 5,shadowOffset: {height: 0,width: 0},elevation: 1,  }}>
              <View style={{height:60, width:376,justifyContent:"space-between",flexDirection:"row", alignItems:"center"  }} >
                    <View>
                        <Text style={{fontSize: style.textInputFontSize*1.5, marginLeft:14, fontWeight: 'bold' ,color:"#FFFFFF",  }}>{"Alertes"}</Text>
                        <Text style={{fontSize: style.textInputFontSize*0.65, marginLeft:14, color:"#FFFFFF", marginTop:-2  }}>{"Dernière 24h"}</Text>
                    </View>

                     <TouchableHighlight underlayColor={"#FFFFFFFF"} onPress={() => { this.close() }} style={{justifyContent:"center",alignItems:"center",height:40, width:40,marginRight:12, backgroundColor:"#FFFFFF",borderRadius: 25, }}>
                         <Image source={require('../assets/close_black.png')} style={{ width: 17, height: 17}}/>

                     </TouchableHighlight>



              </View>
              <View style={{height:10,width:50}} />

              {(this.state.totalList!=undefined && Object.keys(this.state.totalList).length>0) && <ScrollView style={{height:"100%", width:"100%", }}>  
              {Object.keys(this.state.totalList).map((marker,i) => ( 
                
                    <NotificationWeb style={{marginBottom:10, marginLeft:12}} data={this.state.totalList[marker]} onNotificationClick={(data) => { this.props.onNotificationClick(data) }}/>
                
                ))}
              </ScrollView>}
              
              {(this.state.totalList==undefined || Object.keys(this.state.totalList).length==0) &&  <Text style={{fontSize: style.textInputFontSize*1.5, marginLeft:14, fontWeight: 'bold' ,color:"#FFFFFF", width:376, height:50, marginTop:50, textAlign:"center",  }}>{"Aucune alerte à afficher"}</Text> }

          </Animated.View>}

    </View>

         }</StyleContext.Consumer>


    	)}

    }

