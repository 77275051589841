import React, { Component } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  TextInput,
  Platform,
  Dimensions,
  Clipboard,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import Modal from './modal';
import firebase from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";

import info from '../info';

import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MapView from './mapView';
import MavAlert from './mavAlert';
 import MavSelector from './mavSelector';
import moneyList from '../moneyList';
import MavNavigationMinimize from './mavNavigationMinimize';
import Trajet from './trajet';
import MavViewMore from './mavViewMore';
import TextPresentation from './textPresentation';
import MavInfoBubble from './mavInfoBubble';

const timer = require('react-native-timer');
export default class account extends Component {
  constructor(props) {
    super(props);
    var infoMoney=this.findMoney();
    
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 

    console.log("params.customerID "+params.customerID)

    this.state = {
      id:(params.data!=undefined && params.data.id!=undefined)?params.data.id:"",
      regionZoom:{latitude: 47.245996,longitude: 2.62,latitudeDelta: 12,longitudeDelta: 12,},
      goReturn:false,
      money:infoMoney,
      extraEUMode:(params!=undefined && params.extraEUMode!=undefined)?params.extraEUMode:false,
      co2onBill:(params!=undefined && params.co2onBill!=undefined)?params.co2onBill:false,
      providerFixeCommission:(params!=undefined && params.providerFixeCommission!=undefined)?params.providerFixeCommission:false,
      defineMinimalPassager:(params!=undefined && params.defineMinimalPassager!=undefined)?params.defineMinimalPassager:false,
      luggageOption:(params!=undefined && params.luggageOption!=undefined)?params.luggageOption:false,
      vipMode:(params!=undefined && params.vipMode!=undefined)?params.vipMode:false,
      ref:(getAuth(firebase).currentUser != null && (params.driverPricing!=undefined && params.driverPricing==true)  )? ("/pricingDriver/"+( (params.viewSpecificID==true)?params.driverID:getAuth(firebase).currentUser.uid) ): (   (params.customerPricing!=undefined && params.customerPricing==true)?("/pricingCustomer/"+params.customerID) : "/pricing"   )     ,
      driverPricing:(params.driverPricing!=undefined && params.driverPricing==true)?true:false, 
      customerPricing:(params.customerPricing!=undefined && params.customerPricing==true)?true:false,
      karhooMode: (params.karhooMode!=undefined && params.karhooMode==true)?true:false,
      activateETA:(params.karhooMode!=undefined && params.karhooMode==true)?true:false,
      etaList:{},
      taxiMode:(params.taxiMode===true)?true:false,
      disabledChange:(params.disabledChange!=undefined && params.disabledChange==true)?true:false, 
      tarifBDFrom:"",
      tarifBDTo:"",
      validityFrom:"",
      validityTo:"",
      tarifBDSaturday:false,
      tarifBDSunday:false, 
      tarifBDFerie:false, 


      startName:'',
      startZoneDrawed:false,
      startZone:[],
      startPath:[], 
      startRadius:0,
      startLatitude: '',
      startLongitude: '',

      endName:'',
      endZoneDrawed:false,
      endZone:[],
      endPath:[], 
      endRadius:0,
      endLatitude: '',
      endLongitude: '',
     
      name: '',
      zoneDrawed:false,
      zone:[],
      path:[], 


      radius: 0,
      latitude: '',
      longitude: '',

      carList:{},
      carInfo:{},
      
      coeffEnable:false,
      coeffPercent:0,
      
      
      majoration:{}, 
      type:(params.type!=undefined && params.type=="forfait")?"forfait":"classic",
      
      

    };
  }

  findMoney(value){
    if(value==undefined) value="eu";
    var final="";
    var i; 
    for(i=0;i<moneyList.length;i++){
      if(final==""){
        if(moneyList[i].id==value) final=moneyList[i];
      }
    }
    return final
  }

  changeAllPrice(value){

      var car=this.state.carList; 
      var carArray=Object.keys(car)
      var type=["base","minute","km","minPrice"];
      var i,j; 
      for(i=0;i<carArray.length;i++){
          for(j=0;j<type.length;j++){
            if(car[carArray[i]][type[j]]!=undefined && car[carArray[i]][type[j]]!=""){
                car[carArray[i]][type[j]]=car[carArray[i]][type[j]].replace(this.state.money.symbole,'')
                car[carArray[i]][type[j]]=((value.position=="left")?(value.symbole+" "):"")+car[carArray[i]][type[j]]+((value.position=="right")?(value.symbole):"")
            }
          }  
      }
      
      

      this.setState({money:value,carList:car})
  }




  componentDidMount() {


    if(this.state.driverPricing==true || this.state.customerPricing==true){
      console.log("je récupère les settings")
      get(child(ref(getDatabase(firebase)), '/settings')).then(value => {
            var val=value.val();
            if(val!=null){
              var st={}; 
              if(val.extraEUMode!=undefined) st.extraEUMode=val.extraEUMode;
              if(val.co2onBill!=undefined) st.co2onBill=val.co2onBill;
              if(val.providerFixeCommission!=undefined) st.providerFixeCommission=val.providerFixeCommission;
              if(val.defineMinimalPassager!=undefined) st.defineMinimalPassager=val.defineMinimalPassager;
              if(val.luggageOption!=undefined) st.luggageOption=val.luggageOption;
              console.log("on va st")
              console.log(st)

              this.setState(st)


        }
      })
    }

  



      console.log("ref = "+this.state.ref+"/"+this.state.id)
      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 


    if (params.data!=undefined && params.data.type!=undefined) {

        
        var val = JSON.parse(JSON.stringify(params.data));
        if(params.data.etaList==undefined || params.data.etaList==null) val.etaList={}


        if(val.type=="forfait"){
          val.startRadius=val.startRadius.toString();
          if(val.startRadius!="" && val.startRadius!=undefined) val.startRadius+=" km";
          val.endRadius=val.endRadius.toString()
          if(val.endRadius!="" && val.endRadius!=undefined) val.endRadius+=" km";
        }
        else{
          val.radius=val.radius.toString()
          if(val.radius!="" && val.radius!=undefined) val.radius+=" km";
        }
        var i,j,temp;

        if(val.zone!=undefined){
          val.path=[]; 
          for(i=0; i<val.zone.length;i++){
              val.path.push({lat:val.zone[i].latitude, lng:val.zone[i].longitude })
          }
        } 
        if(val.startZone!=undefined){
          val.startPath=[]; 
          for(i=0; i<val.startZone.length;i++){
              val.startPath.push({lat:val.startZone[i].latitude, lng:val.startZone[i].longitude })
          }
        }
        if(val.endZone!=undefined){
          val.endPath=[]; 
          for(i=0; i<val.endZone.length;i++){
              val.endPath.push({lat:val.endZone[i].latitude, lng:val.endZone[i].longitude })
          }
        }

        var price=Object.keys(val.price);


        console.log(val.price)
        
        for(i=0;i<price.length;i++){



          temp=Object.keys(val.price[price[i]]);

          

          for(j=0;j<temp.length;j++){
            if(i==0 && j==0){
                var infoMoney=this.state.money
                if(params!=undefined && params.extraEUMode===true){
                  infoMoney=this.findMoney((val.price[price[i]].moneyID!=undefined)?val.price[price[i]].moneyID:"eu"); 
                  this.setState({money:infoMoney})
                }
            }

          if(temp[j]!="passagers" && temp[j]!="co2" && temp[j]!="cpam" && temp[j]!="passagersMin" && temp[j]!="target" && temp[j]!="targetKarhoo" && temp[j]!="name" && temp[j]!="luggageMax" && temp[j]!="moneyID") val.price[price[i]][temp[j]]= ((infoMoney.position=="left")?(infoMoney.symbole+" "):"")+val.price[price[i]][temp[j]]+((infoMoney.position=="right")?(infoMoney.symbole):"")  
          }

          /*if(val.price[price[i]].target!=undefined) val.price[price[i]].target=[val.price[price[i]].target.standard, val.price[price[i]].target.vip, val.price[price[i]].target.pro ]
          */


          if(val.price[price[i]].passagers==undefined) val.price[price[i]].passagers=1;
          if(val.price[price[i]].passagersMin==undefined) val.price[price[i]].passagersMin=1;
        }
        
        
        this.setState(() => (val),function(){ this.calculateRegionZoom()})
        this.setState({carList:val.price})

    }
    
  }


  onPricePlaceFrom = data => {
        this.onPricePlace("from", data)
  }
  onPricePlaceTo = data => {
        this.onPricePlace("to", data)
  }


onPricePlace = (origin,data) => {
  if(this.state.type=="forfait"){
    if(origin=="from"){

      this.setState(() => ({startName: data.description,
      startLatitude:data.coordinates.latitude,
      startLongitude:data.coordinates.longitude}),function(){ this.calculateRegionZoom()})
    }
    else {
      this.setState(() => ({endName: data.description,
      endLatitude:data.coordinates.latitude,
      endLongitude:data.coordinates.longitude}),function(){ this.calculateRegionZoom()})
    }
  }
  else {
    this.setState(() => ({name: data.description,latitude:data.coordinates.latitude,longitude:data.coordinates.longitude}),function(){ this.calculateRegionZoom()})

  } 
}

onZoneChange = data => {

  console.log("on zone change");
  console.log(data)
  var send={ }

  

  var radius=Math.sqrt(this.calcArea(data.coordinates)/Math.PI);
  console.log("equivalent radius "+radius/1000+" km" )

  var path=[]; 
  for(var i=0; i<data.coordinates.length;i++){path.push({lat:data.coordinates[i].latitude, lng:data.coordinates[i].longitude })}

  console.log(path)

  if(data.selectedZone=="zone") {send.zone=data.coordinates; send.path=path; send.radius=(radius/1000).toFixed(2)+" km";}
  else if(data.selectedZone=="startZone"){ send.startZone=data.coordinates; send.startPath=path; send.startRadius=(radius/1000).toFixed(2)+" km";}
  else if(data.selectedZone=="endZone") {send.endZone=data.coordinates; send.endPath=path; send.endRadius=(radius/1000).toFixed(2)+" km";}

  this.setState(() => (send),function(){ this.calculateRegionZoom()}) 

  


 } 




changePrice(rowData, value,type){
    var car=JSON.parse(JSON.stringify(this.state.carList)); 

    timer.setTimeout("changePrice"+type, () => {
       var car=JSON.parse(JSON.stringify(this.state.carList)); 
        var test=car[rowData][type].indexOf(this.state.money.symbole);
        if(test==-1 && car[rowData][type]!="") {
          car[rowData][type]=((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+car[rowData][type]+((this.state.money.position=="right")?(this.state.money.symbole):"")
          
          this.setState({carList:car}); 
        }
    }, 1000);

    car[rowData][type]=value.toString().replace(",",'.')

    
    car[rowData][type]=car[rowData][type].replace(this.state.money.symbole,'')

    this.setState({ carList:car });

}

changeKm(value,ver){

    timer.setTimeout(generateUUID(), () => {
        var test;
        if(ver=="startRadius") test=this.state.startRadius;
        else if(ver=="endRadius") test=this.state.endRadius;
        else test=this.state.radius;
        var test2=test.indexOf(" km");
        test=parseFloat(test);
        if(isNaN(test)) test=""
        else test=test.toString();
        if(test2==-1 && test!="") {

          test+=" km";
          if(ver=="startRadius") this.setState({ startRadius:test });
          else if(ver=="endRadius") this.setState({ endRadius:test });
          else this.setState({ radius:test });
        }
    }, 3500);

    value=value.toString().replace(",",'.')
    var test2=value;
    value=parseFloat(value);
    value=value.toString();
    if(test2[test2.length-1]==".") value+=".";
    if(value=="NaN." || value=="NaN") value=""

    if(ver=="startRadius") this.setState(() => ({startRadius:value}),function(){ this.calculateRegionZoom()}) 
    else if(ver=="endRadius") this.setState(() => ({endRadius:value}),function(){ this.calculateRegionZoom()}) 
    else this.setState(() => ({radius:value}),function(){ this.calculateRegionZoom()}) 
    

}

changePassagers(rowData,value){

    var car=JSON.parse(JSON.stringify(this.state.carList)); 
    var val=(car[rowData].passagers==undefined)?1:car[rowData].passagers;
    val=val+((value==0)?-1:+1) ;
    if(val<0) val=1;
    car[rowData].passagers=val;
    this.setState({carList:car});

  }

  changeCO2(rowData,value){
    
    var car=JSON.parse(JSON.stringify(this.state.carList)); 
    car[rowData].co2=(value!="")?parseFloat(value):"";
    if(!isNaN(car[rowData].co2)) this.setState({carList:car});

  }

  changeTarget(rowData,value){
    
    var car=JSON.parse(JSON.stringify(this.state.carList)); 
    if(car[rowData].target==undefined) car[rowData].target={}; 
    if(car[rowData].target[value]==true || car[rowData].target[value]==undefined) car[rowData].target[value]=false; 
    else car[rowData].target[value]=true; 

    this.setState({carList:car});

  }


  changeTargetKarhoo(rowData,value){
      var car=JSON.parse(JSON.stringify(this.state.carList)); 
      if(value=="all") delete car[rowData].targetKarhoo;
      else if(value=="public") car[rowData].targetKarhoo={public:true}
      else if(value=="assistance") car[rowData].targetKarhoo={assistance:true}

      this.setState({carList:car}); 

  }


changePassagersMin(rowData,value){
    var car=JSON.parse(JSON.stringify(this.state.carList)); 
    var val=(car[rowData].passagersMin==undefined)?1:car[rowData].passagersMin;
    val=val+((value==0)?-1:+1) ;
    if(val<1) val=1;
    car[rowData].passagersMin=val;

    this.setState({carList:car});

  }


  changeBaggage(rowData,value){
    var car=JSON.parse(JSON.stringify(this.state.carList)); 
    var val=(car[rowData].luggageMax==undefined)?1:car[rowData].luggageMax;
    val=val+((value==0)?-1:+1) ;
    if(val<1) val=1;
    car[rowData].luggageMax=val;

    this.setState({carList:car});

  }


   navigateTo= (link,params) => {
   
    if(this.props.navigation!=undefined) this.props.navigation.navigate(link, params);
    else this.props.params.navigate(link, params);
  }



calculateRegionZoom() {
  console.log("calculateRegionZoom start")
  var points=[];
   var i;

  if(this.state.zoneDrawed==false && this.state.latitude!="" && this.state.radius!="") {
    points.push({latitude: this.state.latitude,longitude: this.state.longitude})
    points.push({latitude: this.state.latitude + (parseFloat(this.state.radius) / 6378) * (180 / Math.PI)*1.01,longitude: this.state.longitude - 1.01*(parseFloat(this.state.radius) / 6378) * (180 / Math.PI) / Math.cos(this.state.latitude * Math.PI/180)})
    points.push({latitude: this.state.latitude - (parseFloat(this.state.radius) / 6378) * (180 / Math.PI)*1.01,longitude: this.state.longitude + 1.01*(parseFloat(this.state.radius) / 6378) * (180 / Math.PI) / Math.cos(this.state.latitude * Math.PI/180)})
  }

  if(this.state.zoneDrawed==true && this.state.zone.length>0){
    for(i=0;i<this.state.zone.length;i++){
        points.push({latitude:this.state.zone[i].latitude*1.0001,longitude:this.state.zone[i].longitude*1.0001   });
        points.push({latitude:this.state.zone[i].latitude*0.9999,longitude:this.state.zone[i].longitude*1.0001   });
        points.push({latitude:this.state.zone[i].latitude*1.0001,longitude:this.state.zone[i].longitude*0.9999   });
        points.push({latitude:this.state.zone[i].latitude*0.9999,longitude:this.state.zone[i].longitude*0.9999   });
    }
  }



  if(this.state.startZoneDrawed==false && this.state.startLatitude!="" && this.state.startRadius!="") {
    points.push({latitude: this.state.startLatitude,longitude: this.state.startLongitude})
    points.push({latitude: this.state.startLatitude + (parseFloat(this.state.startRadius) / 6378) * (180 / Math.PI)*1.01,longitude: this.state.startLongitude - 1.01*(parseFloat(this.state.startRadius) / 6378) * (180 / Math.PI) / Math.cos(this.state.startLatitude * Math.PI/180)})
    points.push({latitude: this.state.startLatitude - (parseFloat(this.state.startRadius) / 6378) * (180 / Math.PI)*1.01,longitude: this.state.startLongitude + 1.01*(parseFloat(this.state.startRadius) / 6378) * (180 / Math.PI) / Math.cos(this.state.startLatitude * Math.PI/180)})
  }
  if(this.state.startZoneDrawed==true && this.state.startZone.length>0){
    for(i=0;i<this.state.startZone.length;i++){
        points.push({latitude:this.state.startZone[i].latitude*1.0001,longitude:this.state.startZone[i].longitude*1.0001   });
        points.push({latitude:this.state.startZone[i].latitude*0.9999,longitude:this.state.startZone[i].longitude*1.0001   });
        points.push({latitude:this.state.startZone[i].latitude*1.0001,longitude:this.state.startZone[i].longitude*0.9999   });
        points.push({latitude:this.state.startZone[i].latitude*0.9999,longitude:this.state.startZone[i].longitude*0.9999   });
    }
  }

  if(this.state.endZoneDrawed==false  && this.state.endLatitude!="" && this.state.endRadius!="") {
    points.push({latitude: this.state.endLatitude,longitude: this.state.endLongitude})
    points.push({latitude: this.state.endLatitude + (parseFloat(this.state.endRadius) / 6378) * (180 / Math.PI)*1.01,longitude: this.state.endLongitude - 1.01*(parseFloat(this.state.endRadius) / 6378) * (180 / Math.PI) / Math.cos(this.state.endLatitude * Math.PI/180)})
    points.push({latitude: this.state.endLatitude - (parseFloat(this.state.endRadius) / 6378) * (180 / Math.PI)*1.01,longitude: this.state.endLongitude + 1.01*(parseFloat(this.state.endRadius) / 6378) * (180 / Math.PI) / Math.cos(this.state.endLatitude * Math.PI/180)})
  }

  if(this.state.endZoneDrawed==true && this.state.endZone.length>0){
    for(i=0;i<this.state.endZone.length;i++){
        points.push({latitude:this.state.endZone[i].latitude*1.0001,longitude:this.state.endZone[i].longitude*1.0001   });
        points.push({latitude:this.state.endZone[i].latitude*0.9999,longitude:this.state.endZone[i].longitude*1.0001   });
        points.push({latitude:this.state.endZone[i].latitude*1.0001,longitude:this.state.endZone[i].longitude*0.9999   });
        points.push({latitude:this.state.endZone[i].latitude*0.9999,longitude:this.state.endZone[i].longitude*0.9999   });
    }
  }

  if(points.length==0){

    if(this.state.latitude!="") {
        points.push({latitude: this.state.latitude,longitude: this.state.longitude})
        points.push({latitude: this.state.latitude + (2 / 6378) * (180 / Math.PI)*1.01,longitude: this.state.longitude - 1.01*(2 / 6378) * (180 / Math.PI) / Math.cos(this.state.latitude * Math.PI/180)})
        points.push({latitude: this.state.latitude - (2 / 6378) * (180 / Math.PI)*1.01,longitude: this.state.longitude + 1.01*(2 / 6378) * (180 / Math.PI) / Math.cos(this.state.latitude * Math.PI/180)})
  }
  else if(this.state.startLatitude!="") {
    points.push({latitude: this.state.startLatitude,longitude: this.state.startLongitude})
    points.push({latitude: this.state.startLatitude + (2 / 6378) * (180 / Math.PI)*1.01,longitude: this.state.startLongitude - 1.01*(2 / 6378) * (180 / Math.PI) / Math.cos(this.state.startLatitude * Math.PI/180)})
    points.push({latitude: this.state.startLatitude - (2 / 6378) * (180 / Math.PI)*1.01,longitude: this.state.startLongitude + 1.01*(2 / 6378) * (180 / Math.PI) / Math.cos(this.state.startLatitude * Math.PI/180)})
  }

  else if(this.state.endLatitude!="") {
    points.push({latitude: this.state.endLatitude,longitude: this.state.endLongitude})
    points.push({latitude: this.state.endLatitude + (2 / 6378) * (180 / Math.PI)*1.01,longitude: this.state.endLongitude - 1.01*(2 / 6378) * (180 / Math.PI) / Math.cos(this.state.endLatitude * Math.PI/180)})
    points.push({latitude: this.state.endLatitude - (2 / 6378) * (180 / Math.PI)*1.01,longitude: this.state.endLongitude + 1.01*(2 / 6378) * (180 / Math.PI) / Math.cos(this.state.endLatitude * Math.PI/180)})
  }



  }


  
  if(points.length>0){
      var minX = points[0].latitude;
      var maxX = points[0].latitude;
      var minY = points[0].longitude;
      var maxY = points[0].longitude;

     

      for(i=1;i<points.length+1;i++){
        minX = Math.min(minX, points[i-1].latitude);
        maxX = Math.max(maxX, points[i-1].latitude);
        minY = Math.min(minY, points[i-1].longitude);
        maxY = Math.max(maxY, points[i-1].longitude);
      }
      

      var midX = (minX + maxX) / 2;
      var midY = (minY + maxY) / 2;
      var midPoint = [midX, midY];

      var deltaX = (maxX - minX);
      var deltaY = (maxY - minY);
      var r={latitude: midX, longitude: midY,latitudeDelta: deltaX, longitudeDelta: deltaY};
     
      this.setState({regionZoom:{latitude: midX, longitude: midY,latitudeDelta: deltaX, longitudeDelta: deltaY}})
  }
}


updateMajoration = value => {
  this.setState({majoration:value})
}

closeOtherViewMore= id => {
  var i;
  var objCar=Object.keys(this.state.carList)
    for(i=0;i<objCar.length; i++){
      if(i!=id) this['viewCar_' + i].close();
    }
}

verifyFields(id){
  var error=false;
  var selectedCar=Object.keys(this.state.carList)[id]; 
  if(this.state.type=="classic"){
      if(this.state.carList[selectedCar]==undefined) error=strings.pricingErrorCar+" "+strings.newCar;
      else if(this.state.taxiMode!=true && error==false){ if(this.state.carList[selectedCar].name=="" || this.state.carList[selectedCar].name==undefined || (this.state.carList[selectedCar].base==undefined) || (this.state.carList[selectedCar].minute==undefined) || (this.state.carList[selectedCar].km==undefined) || (this.state.carList[selectedCar].minPrice==undefined) ) error=strings.pricingErrorCar+" "+ ((this.state.carList[selectedCar].name=="" || this.state.carList[selectedCar].name==undefined )?strings.newCar:this.state.carList[selectedCar].name)    ;}
      else if(this.state.carList[selectedCar].name=="" || this.state.carList[selectedCar].name== undefined || (this.state.carList[selectedCar].tarifA==undefined) || (this.state.carList[selectedCar].tarifB==undefined) || (this.state.carList[selectedCar].tarifC==undefined) || (this.state.carList[selectedCar].tarifD==undefined) || (this.state.carList[selectedCar].base==undefined) || (this.state.carList[selectedCar].majoTaxiImmediate==undefined) || (this.state.carList[selectedCar].majoTaxiAvance==undefined) || (this.state.carList[selectedCar].minPrice==undefined)) error=strings.pricingErrorCar+" "+((this.state.carList[selectedCar].name=="" || this.state.carList[selectedCar].name==undefined )?strings.newCar:this.state.carList[selectedCar].name)
  }
  else{
    if(this.state.carList[selectedCar].name=="" || this.state.carList[selectedCar].name==undefined || this.state.carList[selectedCar].base==undefined  ) error=strings.pricingErrorCar+" "+((this.state.carList[selectedCar].name=="")?strings.newCar:this.state.carList[selectedCar].name);
    if(this.state.taxiMode==true && this.state.carList[selectedCar].baseMajo==undefined ) error=strings.pricingErrorCar+" "+this.state.carList[selectedCar].name;
    

  } 

  return error;


}


confirmPlace = value => {

            var error=false;
            var sendToFirebase;
            var tarifBDTo;
            var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
            if(this.state.type=="classic"){
            

                sendToFirebase={
                  type:"classic",
                  longitude:this.state.longitude,
                  name:this.state.name,
                  latitude:this.state.latitude,
                  radius:parseFloat(this.state.radius),
                  
                }

                sendToFirebase.etaList=null;
                  var etaList=JSON.parse(JSON.stringify(this.state.etaList));  
                    delete etaList["new"]; 
                    if(Object.keys(etaList).length>0){
                        sendToFirebase.etaList=etaList; 
                    } 


                if(this.state.validityTo!="" && this.state.validityFrom!=""){
                  sendToFirebase.validityFrom=this.state.validityFrom;
                  sendToFirebase.validityTo=this.state.validityTo;
                }
                else if((this.state.validityTo=="" && this.state.validityFrom!="") || (this.state.validityTo!="" && this.state.validityFrom=="")){
                  error=strings.limitTimeError
                }
                else {sendToFirebase.validityFrom=null; sendToFirebase.validityTo=null}



                if(this.state.taxiMode!=true) sendToFirebase.majoration=this.state.majoration;
                else{
                  sendToFirebase.coeffEnable=this.state.coeffEnable;
                  sendToFirebase.coeffPercent=this.state.coeffPercent;
                  sendToFirebase.tarifBDFrom=this.state.tarifBDFrom;
                  sendToFirebase.tarifBDTo=this.state.tarifBDTo
                  sendToFirebase.tarifBDSaturday=this.state.tarifBDSaturday
                  sendToFirebase.tarifBDSunday=this.state.tarifBDSunday
                  sendToFirebase.tarifBDFerie=this.state.tarifBDFerie

                  sendToFirebase.taxiMode=true;

                  if(sendToFirebase.tarifBDFrom=="" || sendToFirebase.tarifBDFrom==undefined ) error=strings.tarifBDFrom+" : "+strings.notDefined.toLowerCase();
                  else if(sendToFirebase.tarifBDTo=="" || sendToFirebase.tarifBDTo==undefined ) error=strings.tarifBDTo+" : "+strings.notDefined.toLowerCase();

                }

                if(this.state.zoneDrawed==true){
                  sendToFirebase.zoneDrawed=true;
                  sendToFirebase.zone=this.state.zone
                   if(sendToFirebase.zone.length<2) error=strings.thanksDrawZone; 
                }

                if(sendToFirebase.name=="" || sendToFirebase.name==undefined) error=strings.incorrectPricingStart;
                if(error==false && (sendToFirebase.radius=="" || sendToFirebase.radius==undefined || isNaN(sendToFirebase.radius))) error=strings.incorrectPricingRadiusStart;


                sendToFirebase.price=new Object;
                var carListArray=Object.keys(this.state.carList);
                if(carListArray.length==0) error=strings.oneCarMin; 


                var i;
                for(i=0;i<carListArray.length;i++){

                    if(this.state.carList[carListArray[i]]==undefined) error=strings.pricingErrorCar+" "+strings.newCar;
                    else if(this.state.taxiMode!=true && error==false){ if(this.state.carList[carListArray[i]].name=="" || this.state.carList[carListArray[i]].name==undefined || (this.state.carList[carListArray[i]].base==undefined) || (this.state.carList[carListArray[i]].minute==undefined) || (this.state.carList[carListArray[i]].km==undefined) || (this.state.carList[carListArray[i]].minPrice==undefined) ) error=strings.pricingErrorCar+" "+ ((this.state.carList[carListArray[i]].name=="" || this.state.carList[carListArray[i]].name==undefined )?strings.newCar:this.state.carList[carListArray[i]].name)    ;}
                    else if(this.state.carList[carListArray[i]].name=="" || this.state.carList[carListArray[i]].name== undefined || (this.state.carList[carListArray[i]].tarifA==undefined) || (this.state.carList[carListArray[i]].tarifB==undefined) || (this.state.carList[carListArray[i]].tarifC==undefined) || (this.state.carList[carListArray[i]].tarifD==undefined) || (this.state.carList[carListArray[i]].base==undefined) || (this.state.carList[carListArray[i]].majoTaxiImmediate==undefined) || (this.state.carList[carListArray[i]].majoTaxiAvance==undefined) || (this.state.carList[carListArray[i]].minPrice==undefined)) error=strings.pricingErrorCar+" "+((this.state.carList[carListArray[i]].name=="" || this.state.carList[carListArray[i]].name==undefined )?strings.newCar:this.state.carList[carListArray[i]].name)

                    
                    
                    if(this.state.taxiMode!=true && error==false){ 



                      sendToFirebase.price[carListArray[i]]={
                          base:parseFloat(this.state.carList[carListArray[i]].base.toString().replace(this.state.money.symbole,'')),
                          minute:parseFloat(this.state.carList[carListArray[i]].minute.toString().replace(this.state.money.symbole,'')),
                          km:parseFloat(this.state.carList[carListArray[i]].km.toString().replace(this.state.money.symbole,'')),
                          minPrice:parseFloat(this.state.carList[carListArray[i]].minPrice.toString().replace(this.state.money.symbole,'')),
                          passagers:this.state.carList[carListArray[i]].passagers,
                          passagersMin:(this.state.carList[carListArray[i]].passagersMin==undefined || this.state.carList[carListArray[i]].passagersMin==1)?null:this.state.carList[carListArray[i]].passagersMin,
                          name:this.state.carList[carListArray[i]].name.trim(),
                          co2:(this.state.carList[carListArray[i]].co2!=null && (typeof this.state.carList[carListArray[i]].co2 !== 'undefined')  && (this.state.carList[carListArray[i]].co2.toString().length>0 || this.state.carList[carListArray[i]].co2.toString()=="0" ) )?this.state.carList[carListArray[i]].co2:null,
                          provCom:(this.state.providerFixeCommission==true && this.state.carList[carListArray[i]].provCom!=undefined && !isNaN(parseFloat(this.state.carList[carListArray[i]].provCom.toString().replace(this.state.money.symbole,'')) )        )?parseFloat(this.state.carList[carListArray[i]].provCom.toString().replace(this.state.money.symbole,'')):null,

                        }

                        if(this.state.karhooMode==true) sendToFirebase.price[carListArray[i]].targetKarhoo=(this.state.carList[carListArray[i]].targetKarhoo==undefined)?null:this.state.carList[carListArray[i]].targetKarhoo; 

                    }

                    else if(error==false) {

                      if(isNaN(parseFloat(this.state.carList[carListArray[i]].tarifA)) || isNaN(parseFloat(this.state.carList[carListArray[i]].tarifB)) || isNaN(parseFloat(this.state.carList[carListArray[i]].tarifC)) || isNaN(parseFloat(this.state.carList[carListArray[i]].tarifD.toString().replace(this.state.money.symbole,''))) || isNaN(parseFloat(this.state.carList[carListArray[i]].base))     ){
                        error=strings.thanksAllItems;
                        sendToFirebase.price[carListArray[i]]={
                          name:this.state.carList[carListArray[i]].name.trim(), 
                          
                        }
                      } 
                      else sendToFirebase.price[carListArray[i]]={

                        tarifA:parseFloat(this.state.carList[carListArray[i]].tarifA.toString().replace(this.state.money.symbole,'')),
                        tarifB:parseFloat(this.state.carList[carListArray[i]].tarifB.toString().replace(this.state.money.symbole,'')),
                        tarifC:parseFloat(this.state.carList[carListArray[i]].tarifC.toString().replace(this.state.money.symbole,'')),
                        tarifD:parseFloat(this.state.carList[carListArray[i]].tarifD.toString().replace(this.state.money.symbole,'')),
                        base:parseFloat(this.state.carList[carListArray[i]].base.toString().replace(this.state.money.symbole,'')),
                        majoTaxiImmediate:parseFloat(this.state.carList[carListArray[i]].majoTaxiImmediate.toString().replace(this.state.money.symbole,'')),
                        majoTaxiAvance:parseFloat(this.state.carList[carListArray[i]].majoTaxiAvance.toString().replace(this.state.money.symbole,'')),
                        minPrice:parseFloat(this.state.carList[carListArray[i]].minPrice.toString().replace(this.state.money.symbole,'')),
                        passagers:this.state.carList[carListArray[i]].passagers,
                        passagersMin:(this.state.carList[carListArray[i]].passagersMin == undefined || this.state.carList[carListArray[i]].passagersMin==1)?null:this.state.carList[carListArray[i]].passagersMin,
                        name:this.state.carList[carListArray[i]].name.trim(),
                        provCom:(this.state.providerFixeCommission==true && this.state.carList[carListArray[i]].provCom!=undefined && !isNaN(parseFloat(this.state.carList[carListArray[i]].provCom.toString().replace(this.state.money.symbole,'')) )        )?parseFloat(this.state.carList[carListArray[i]].provCom.toString().replace(this.state.money.symbole,'')):null,
                        co2:( this.state.carList[carListArray[i]].co2!=null && (typeof this.state.carList[carListArray[i]].co2 !== 'undefined') && ( this.state.carList[carListArray[i]].co2.toString().length>0 || this.state.carList[carListArray[i]].co2.toString()=="0" ))?this.state.carList[carListArray[i]].co2:null,
                        cpam:(this.state.carList[carListArray[i]].cpam==true)?true:null,
                      }

                    }

                    if(params!=undefined && params.vipMode===true && error==false){
                      var target=this.state.carList[carListArray[i]].target;
                      if(target==undefined) target=null
                      sendToFirebase.price[carListArray[i]].target=target; 
                    }

                    if(this.state.karhooMode==true) sendToFirebase.price[carListArray[i]].targetKarhoo=(this.state.carList[carListArray[i]].targetKarhoo==undefined)?null:this.state.carList[carListArray[i]].targetKarhoo; 



                    if(this.state.luggageOption===true && error==false){
                      sendToFirebase.price[carListArray[i]].luggageMax=(this.state.carList[carListArray[i]].luggageMax!=undefined)?this.state.carList[carListArray[i]].luggageMax:1
                    }
                    if(params!=undefined && params.extraEUMode===true && error==false){
                      sendToFirebase.price[carListArray[i]].moneyID=this.state.money.id
                    }

                    

                    console.log("error = "+error)
                    if(this.state.taxiMode!=true && error==false){ if(sendToFirebase.price[carListArray[i]].name=="" || sendToFirebase.price[carListArray[i]].name==undefined || isNaN(sendToFirebase.price[carListArray[i]].base) || isNaN(sendToFirebase.price[carListArray[i]].minute) || isNaN(sendToFirebase.price[carListArray[i]].km) || isNaN(sendToFirebase.price[carListArray[i]].minPrice) ) error=strings.pricingErrorCar+" "+sendToFirebase.price[carListArray[i]].name;}
                    else if(error==false && (sendToFirebase.price[carListArray[i]].name=="" || sendToFirebase.price[carListArray[i]].name== undefined || isNaN(sendToFirebase.price[carListArray[i]].tarifA) || isNaN(sendToFirebase.price[carListArray[i]].tarifB) || isNaN(sendToFirebase.price[carListArray[i]].tarifC) || isNaN(sendToFirebase.price[carListArray[i]].tarifD) || isNaN(sendToFirebase.price[carListArray[i]].base) || isNaN(sendToFirebase.price[carListArray[i]].majoTaxiImmediate) || isNaN(sendToFirebase.price[carListArray[i]].majoTaxiAvance) || isNaN(sendToFirebase.price[carListArray[i]].minPrice))) error=strings.pricingErrorCar+" "+sendToFirebase.price[carListArray[i]].name;




                  }



            }
            else{
                  sendToFirebase={
                    type:"forfait",
                    startLongitude:this.state.startLongitude,
                    endLongitude:this.state.endLongitude,
                    startLatitude:this.state.startLatitude,
                    endLatitude:this.state.endLatitude,

                    startName:this.state.startName,
                    endName:this.state.endName,
                    endRadius:parseFloat(this.state.endRadius),
                    goReturn:this.state.goReturn,
                    startRadius:parseFloat(this.state.startRadius),
                  }


                  sendToFirebase.etaList=null;
                  var etaList=JSON.parse(JSON.stringify(this.state.etaList));  
                    delete etaList["new"]; 
                    if(Object.keys(etaList).length>0){
                        sendToFirebase.etaList=etaList; 
                    } 

                  if(this.state.validityTo!="" && this.state.validityFrom!=""){
                      sendToFirebase.validityFrom=this.state.validityFrom;
                      sendToFirebase.validityTo=this.state.validityTo;
                    }
                    else if((this.state.validityTo=="" && this.state.validityFrom!="") || (this.state.validityTo!="" && this.state.validityFrom=="")){
                      error=strings.limitTimeError
                    }

                  if(this.state.taxiMode!=true) sendToFirebase.majoration=this.state.majoration;
                  else {
                    sendToFirebase.taxiMode=true;
                    sendToFirebase.tarifBDFrom=this.state.tarifBDFrom;
                    sendToFirebase.tarifBDTo=this.state.tarifBDTo
                    sendToFirebase.tarifBDSaturday=this.state.tarifBDSaturday
                    sendToFirebase.tarifBDSunday=this.state.tarifBDSunday
                    sendToFirebase.tarifBDFerie=this.state.tarifBDFerie
                    if(sendToFirebase.tarifBDFrom=="") error=strings.tarifBDFrom+" : "+strings.notDefined.toLowerCase();
                    else if(sendToFirebase.tarifBDTo=="") error=strings.tarifBDTo+" : "+strings.notDefined.toLowerCase();

                  }

                  if(this.state.endZoneDrawed==true){
                      sendToFirebase.endZoneDrawed=true;
                      sendToFirebase.endZone=this.state.endZone
                      if(sendToFirebase.endZone.length<2) error=strings.thanksDrawZone+" ("+strings.destinationZone+")"; 
                  }

                  if(this.state.startZoneDrawed==true){
                      sendToFirebase.startZoneDrawed=true;
                      sendToFirebase.startZone=this.state.startZone
                      if(sendToFirebase.startZone.length<2) error=strings.thanksDrawZone+" ("+strings.departureZone+")"; 

                  }

                  

                  if(sendToFirebase.startName=="" || sendToFirebase.startName==undefined) error=strings.incorrectPricingStart;
                  if(error==false && (sendToFirebase.startRadius=="" || sendToFirebase.startRadius==undefined || isNaN(sendToFirebase.startRadius))) error=strings.incorrectPricingRadiusStart;

                  if(error==false &&(sendToFirebase.endName=="" || sendToFirebase.endName==undefined)) error=strings.incorrectPricingEnd;
                  if(error==false && (sendToFirebase.endRadius=="" || sendToFirebase.endRadius==undefined || isNaN(sendToFirebase.endRadius))) error=strings.incorrectPricingRadiusEnd;

                  sendToFirebase.price=new Object;

                  var carListArray=Object.keys(this.state.carList);
                  if(carListArray.length==0) error=strings.oneCarMin; 


                  for(i=0;i<carListArray.length;i++){



                      sendToFirebase.price[carListArray[i]]={
                        base:(this.state.carList[carListArray[i]]==undefined || this.state.carList[carListArray[i]].base==undefined)?undefined:parseFloat(this.state.carList[carListArray[i]].base.toString().replace(this.state.money.symbole,'')),
                        passagers:this.state.carList[carListArray[i]].passagers,
                        passagersMin:(this.state.carList[carListArray[i]].passagersMin==undefined || this.state.carList[carListArray[i]].passagersMin==1)?null:this.state.carList[carListArray[i]].passagersMin,
                        name:(this.state.carList[carListArray[i]].name!=undefined)?this.state.carList[carListArray[i]].name.trim():"",
                        provCom:(this.state.providerFixeCommission==true && this.state.carList[carListArray[i]].provCom!=undefined && !isNaN(parseFloat(this.state.carList[carListArray[i]].provCom.toString().replace(this.state.money.symbole,'')) )        )?parseFloat(this.state.carList[carListArray[i]].provCom.toString().replace(this.state.money.symbole,'')):null,
                        co2:(this.state.carList[carListArray[i]].co2!=null && (typeof this.state.carList[carListArray[i]].co2 !== 'undefined') && (this.state.carList[carListArray[i]].co2.toString().length>0 || this.state.carList[carListArray[i]].co2.toString()=="0" ))?this.state.carList[carListArray[i]].co2:null,
                      }

                      if(params!=undefined && params.vipMode===true){
                        var target=this.state.carList[carListArray[i]].target;
                        if(target==undefined) target=null
                        sendToFirebase.price[carListArray[i]].target=target; 
                      }

                      if(this.state.karhooMode==true) sendToFirebase.price[carListArray[i]].targetKarhoo=(this.state.carList[carListArray[i]].targetKarhoo==undefined)?null:this.state.carList[carListArray[i]].targetKarhoo; 



                      if(this.state.luggageOption===true){
                        sendToFirebase.price[carListArray[i]].luggageMax=(this.state.carList[carListArray[i]].luggageMax!=undefined)?this.state.carList[carListArray[i]].luggageMax:1
                      }

                      if(params!=undefined && params.extraEUMode===true && error==false){
                          sendToFirebase.price[carListArray[i]].moneyID=this.state.money.id
                      }

                      if(sendToFirebase.price[carListArray[i]].name=="" || sendToFirebase.price[carListArray[i]].name==undefined || isNaN(sendToFirebase.price[carListArray[i]].base)  ) error=strings.pricingErrorCar+" "+((sendToFirebase.price[carListArray[i]].name=="")?strings.newCar:sendToFirebase.price[carListArray[i]].name);
                      if(this.state.taxiMode==true){
                        if(this.state.carList[carListArray[i]].baseMajo==undefined) this.state.carList[carListArray[i]].baseMajo="";
                        sendToFirebase.price[carListArray[i]].baseMajo=parseFloat(this.state.carList[carListArray[i]].baseMajo.toString().replace(this.state.money.symbole,''))
                        if(isNaN(sendToFirebase.price[carListArray[i]].baseMajo)) error=strings.pricingErrorCar+" "+sendToFirebase.price[carListArray[i]].name;
                      }
                      
                    }
            
            }

            if(error!=false) this.alert.onAlert({title:error}); 

            else if(value=="copy") {
              sendToFirebase.date=Date.parse(new Date())
              const fireInfoB={} 
              fireInfoB["users/" + getAuth(firebase).currentUser.uid+"/lastCopiedPrice"]=sendToFirebase
              update(ref(getDatabase(firebase)), fireInfoB)

              this.alert.onAlert({title:(this.state.type=="forfait")?strings.copyThisForfait:strings.copyThisZone, subTitle:(this.state.type=="forfait")?strings.forfaitHasBeenCopied:strings.zoneHasBeenCopied, buttons:[
                    {text: strings.ok,onPress: (value) => { 

                        if(this.props.navigation!=undefined) this.props.navigation.goBack(); 
                        else params.goBack();

                    }}]  });  }
            else if(this.state.karhooMode==true){

              if(params.updateData!=null) params.updateData(((this.state.id==undefined || this.state.id=="")?generateUUID():this.state.id),sendToFirebase );
              if(this.props.navigation!=undefined) this.props.navigation.goBack(); 
              else params.goBack();
            }
            else {
              this.setState({id:(this.state.id==undefined || this.state.id=="")?generateUUID():this.state.id},function(){
              
              const fireInfo={} 
              fireInfo[this.state.ref+'/' + this.state.id]=sendToFirebase
              update(ref(getDatabase(firebase)), fireInfo)

              if( params!=undefined && (params.driverPricing==true || params.customerPricing==true) && params.updatePersonalData!=null ) params.updatePersonalData(this.state); 
              else if(params.updateData!=null) params.updateData(this.state);

              if(this.props.navigation!=undefined) this.props.navigation.goBack(); 
              else params.goBack();

                    

                })

                }

              }





calcArea(locations) {

      if (!locations.length) {    
        return 0;
      }
      if (locations.length < 3) {
        return 0;
      }
      let radius = 6371000;

      const diameter = radius * 2;
      const circumference = diameter * Math.PI;
      const listY = [];
      const listX = [];
      const listArea = [];
      // calculate segment x and y in degrees for each point

      const latitudeRef = locations[0].latitude;
      const longitudeRef = locations[0].longitude;
      for (let i = 1; i < locations.length; i++) {
        let latitude = locations[i].latitude;
        let longitude = locations[i].longitude;
        listY.push(this.calculateYSegment(latitudeRef, latitude, circumference));

        listX.push(this.calculateXSegment(longitudeRef, longitude, latitude, circumference));

      }

      // calculate areas for each triangle segment
      for (let i = 1; i < listX.length; i++) {
        let x1 = listX[i - 1];
        let y1 = listY[i - 1];
        let x2 = listX[i];
        let y2 = listY[i];
        listArea.push(this.calculateAreaInSquareMeters(x1, x2, y1, y2));

      }

      // sum areas of all triangle segments
      let areasSum = 0;
      listArea.forEach(area => areasSum = areasSum + area)


      // get abolute value of area, it can't be negative
      let areaCalc = Math.abs(areasSum);// Math.sqrt(areasSum * areasSum);  
      return areaCalc;
}

calculateAreaInSquareMeters(x1, x2, y1, y2) {
    return (y1 * x2 - x1 * y2) / 2;
}

calculateYSegment(latitudeRef, latitude, circumference) {
    return (latitude - latitudeRef) * circumference / 360.0;
}

calculateXSegment(longitudeRef, longitude, latitude, circumference) {
      return (longitude - longitudeRef) * circumference * Math.cos((latitude * (Math.PI / 180))) / 360.0;
}


 addETA(){
    var etaList=JSON.parse(JSON.stringify(this.state.etaList))
    etaList["new"]={excludeDay:{},startDate:"",endDate:"",minutes:10}
    var size=Object.keys(etaList).length-1;
    this.setState({etaList:etaList,},() =>{ this.closeETAViewMore(-1); this['viewEtaList_' + size ].open();     })
}


closeETAViewMore= id => {
    var i;
    var objetaList=Object.keys(this.state.etaList)
    for(i=0;i<objetaList.length; i++){
      if(i!=id) this['viewEtaList_' + i].close();
    }
}

addetaListInfo(){
    console.log("addetaListInfo appelé")

   
    if(this.state.etaList["new"].startDate!="" && (this.state.etaList["new"].excludeDay==undefined || Object.keys(this.state.etaList["new"].excludeDay).length<7) && this.state.etaList["new"].endDate!="") {
      var uuid=generateUUID(); 
      var send={minutes:parseInt(this.state.etaList["new"].minutes), startDate:this.state.etaList["new"].startDate, endDate:this.state.etaList["new"].endDate, excludeDay:this.state.etaList["new"].excludeDay};
      var etaList=JSON.parse(JSON.stringify(this.state.etaList))
      delete etaList["new"];
      etaList[uuid]=send;
      this.setState({etaList:{} },() =>{  this.setState({etaList:etaList})    })

    }
    else this.alert.onAlert({title:strings.thanksAllItems})

  }






render() {
    return (
      <StyleContext.Consumer>{style =>
      <ScrollView style={{ marginLeft: 0, marginRight: 0, backgroundColor: style.backgroundColor }}>
          {(Platform.OS=="web" && this.props.params!=undefined && this.props.params.goBack!=undefined) && <MavNavigationMinimize  clickLeft={() => { this.props.params.goBack();  }} left={strings.return} title={ strings.place } />}







          <Text style={{marginTop:10,marginBottom:(this.state.karhooMode==true)?0:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.departureZone}</Text>
          {this.state.karhooMode==true && <Text style={{marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.7,textAlign: "center",}}>{strings.drawedNotAvaiableKarhoo}</Text>}

          {(this.state.karhooMode!=true  && this.state.disabledChange!=true) && <MavSelector style={{marginBottom:2}} selected={(this.state.type=="forfait")?(  (this.state.startZoneDrawed==false)?0:1 ): (  (this.state.zoneDrawed==false)?0:1 )  } textLeft={strings.circulaireZone} textRight={strings.drawedZone} onChange={(value)=>{ 
            if(this.state.disabledChange!=true){ 
              if(this.state.type=="forfait") this.setState({startZoneDrawed:!this.state.startZoneDrawed})
              else this.setState({zoneDrawed:!this.state.zoneDrawed})
            }
          }}/>}

          <MavViewMore ref={fromClose => {this.fromClose = fromClose;}} disable={(this.state.disabledChange==true)?true:false} text={(this.state.type=="forfait")?((this.state.startName=="")?strings.selectFrom:strings.from+" : "+this.state.startName)  :(this.state.name=="")?strings.selectFrom:strings.startPoint+" : "+this.state.name} disabledText={true} iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/favorite/pin_white.png'):require('../assets/favorite/pin_black.png')  }  >
              <Trajet params={{hideNavBar:true, searchLimited:5, backgroundColor:style.backgroundColorContrast, onPricePlace: this.onPricePlaceFrom,  goBack:() => {this.fromClose.close() }, }}/>
          </MavViewMore>

          
          {((this.state.type=="forfait" && this.state.startZoneDrawed==false) || (this.state.type!="forfait" && this.state.zoneDrawed==false) )  && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} text={(this.state.type=="forfait")?this.state.startRadius:this.state.radius} iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/compass_white.png'):require('../assets/compass_black.png')} textExplication={strings.actionRay} textExplicationMini={strings.pricingKmPoint} placeholder={"ex : 15 km"} onChangeText={(text)=>{this.changeKm(text,((this.state.type=="forfait")?"startRadius":"radius")); }}/>}


          {((this.state.type=="forfait" && this.state.startZoneDrawed==true && this.state.startName!="") || (this.state.type!="forfait" && this.state.zoneDrawed==true && this.state.name!="") )&& <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {
            if(this.state.disabledChange!=true){ 
              this.navigateTo('SetDrawCard', {selectedZone:((this.state.type=="forfait")?"startZone":"zone"), onZoneChange: this.onZoneChange, data:{zone:((this.state.type=="forfait")?this.state.startZone:this.state.zone),elementColor:"rgba(0,100,0,1)", elementColor2:"rgba(0,100,0,0.5)",regionZoom:this.state.regionZoom }});
            }
          }}>
              <MavTextInput iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/paint_brush_white.png'):require('../assets/paint_brush_black.png')} disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={strings.drawZone} />
          </TouchableHighlight>} 


          {this.state.type=="forfait" && <Text style={{marginTop:20,marginBottom:(this.state.karhooMode==true)?0:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.destinationZone}</Text>}
          {(this.state.type=="forfait" && this.state.karhooMode==true) && <Text style={{marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*0.7,textAlign: "center",}}>{strings.drawedNotAvaiableKarhoo}</Text>}

           {(this.state.type=="forfait" && this.state.karhooMode!=true) && <MavSelector style={{marginBottom:2}} selected={(this.state.endZoneDrawed==false)?0:1 } textLeft={strings.circulaireZone} textRight={strings.drawedZone} onChange={(value)=>{ if(this.state.disabledChange!=true){  this.setState({endZoneDrawed:!this.state.endZoneDrawed}) } }}/>}
 


           {(this.state.type=="forfait") && <MavViewMore ref={fromClose => {this.fromClose = fromClose;}} disable={(this.state.disabledChange==true)?true:false} text={((this.state.endName=="")?strings.selectTo:strings.to+" : "+this.state.endName)} disabledText={true} iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/favorite/pin_white.png'):require('../assets/favorite/pin_black.png')  }  >
              <Trajet params={{hideNavBar:true, searchLimited:5, backgroundColor:style.backgroundColorContrast, onPricePlace: this.onPricePlaceTo,  goBack:() => {this.fromClose.close() }, }}/>
           </MavViewMore>}


          {(this.state.type=="forfait"  && this.state.endZoneDrawed==false ) && <MavTextInput style={{marginTop:5}} textInputFlex={1} editable={!this.state.disabledChange} keyboardType={"numeric"} text={this.state.endRadius} iconClassicSmall={true}  iconClassic={(style.mode=="dark")?require('../assets/compass_white.png'):require('../assets/compass_black.png')} textExplication={strings.actionRay} textExplicationMini={strings.pricingKmPoint} placeholder={"ex : 15 km"} onChangeText={(text)=>{this.changeKm(text,"endRadius"); }}/>}

          {(this.state.type=="forfait" && this.state.endZoneDrawed==true && this.state.endName!="") && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {
            if(this.state.disabledChange!=true){ 
              this.navigateTo('SetDrawCard', {selectedZone:"endZone", onZoneChange: this.onZoneChange, data:{zone:this.state.endZone,elementColor:"rgba(100,0,0,1)", elementColor2:"rgba(100,0,0,0.5)",regionZoom:this.state.regionZoom }});
            }
          }}>
              <MavTextInput iconClassicSmall={true} iconClassic={(style.mode=="dark")?require('../assets/paint_brush_white.png'):require('../assets/paint_brush_black.png')} disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={strings.drawZone} />
          </TouchableHighlight>} 





          


          <MapView region={this.state.regionZoom} style={{marginTop:10,alignSelf: 'stretch',width: "80%",marginLeft:"10%",height:200,}}>
              
              {(this.state.latitude!="" && this.state.radius!="" && this.state.zoneDrawed==false ) && <MapView.Circle key={"A"+(this.state.latitude).toString()+(this.state.longitude).toString()+this.state.radius}  center={{latitude: this.state.latitude,longitude: this.state.longitude, lat:this.state.latitude,lng: this.state.longitude}} options={{strokeColor: "rgba(0,100,0,1)", fillColor:"rgba(0,100,0,0.5)"}} strokeColor={"rgba(0,100,0,1)"} fillColor={"rgba(0,100,0,0.5)"} radius={parseFloat(this.state.radius)*1000} />}
        
              {(this.state.startLatitude!="" && this.state.startRadius!="" && this.state.startZoneDrawed==false) && <MapView.Circle key={"B"+(this.state.startLatitude).toString()+(this.state.startLongitude).toString()+this.state.startRadius} center={{latitude: this.state.startLatitude,longitude: this.state.startLongitude, lat: this.state.startLatitude,lng: this.state.startLongitude}} options={{strokeColor: "rgba(0,100,0,1)", fillColor:"rgba(0,100,0,0.5)"}} strokeColor={"rgba(0,100,0,1)"} fillColor={"rgba(0,100,0,0.5)"} radius={parseFloat(this.state.startRadius)*1000} />}

              {(this.state.endLatitude!="" && this.state.endRadius!="" && this.state.endZoneDrawed==false ) && <MapView.Circle key={"C"+(this.state.endLatitude).toString()+(this.state.endLongitude).toString()+this.state.endRadius} center={{latitude: this.state.endLatitude,longitude: this.state.endLongitude, lat: this.state.endLatitude,lng: this.state.endLongitude}} options={{strokeColor: "rgba(100,0,0,1)", fillColor:"rgba(100,0,0,0.5)"}} strokeColor={"rgba(100,0,0,1)"} fillColor={"rgba(100,0,0,0.5)"} radius={parseFloat(this.state.endRadius)*1000} />}


              {(this.state.startLatitude!="" && this.state.startZone.length>0 && this.state.startZoneDrawed==true) && <MapView.Polygon path={this.state.startPath} coordinates={this.state.startZone} options={{strokeColor: "rgba(0,100,0,1)", fillColor:"rgba(0,100,0,0.5)"}} strokeColor={"rgba(0,100,0,1)"}  fillColor={"rgba(0,100,0,0.5)"}   />}
              {(this.state.endLatitude!="" && this.state.endZone.length>0 && this.state.endZoneDrawed==true) && <MapView.Polygon coordinates={this.state.endZone} path={this.state.endPath} options={{strokeColor: "rgba(100,0,0,1)", fillColor:"rgba(100,0,0,0.5)"}} strokeColor={"rgba(100,0,0,1)"}  fillColor={"rgba(100,0,0,0.5)"}   />}
              {(this.state.latitude!="" && this.state.zone.length>0 && this.state.zoneDrawed==true) && <MapView.Polygon coordinates={this.state.zone} path={this.state.path} options={{strokeColor: "rgba(0,100,0,1)", fillColor:"rgba(0,100,0,0.5)"}} strokeColor={"rgba(0,100,0,1)"}  fillColor={"rgba(0,100,0,0.5)"}   />}




          </MapView>

         {(this.state.type=="forfait" || this.state.extraEUMode===true ) && <Text style={{marginTop:20,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.price}</Text>}
          
          {(this.state.type=="forfait") && <MavTextInput style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.goReturn} textExplication={strings.goReturnIdenticPrice} textExplication={strings.goReturnIdenticPrice} editable={!this.state.disabledChange}  editable={!this.state.waiting}  enableInfo={true} onEnableInfo={() => {this.alert.onAlert({title:strings.goReturnIdenticPrice, subTitle:strings.goReturnPriceMoreInfos}); }}  

          onChange={()=>{
            if(this.state.disabledChange!=true){ 
              if(this.state.goReturn==true) this.setState({goReturn:false}) 
                else this.setState({goReturn:true})
            } 
          }}/>}

          {this.state.extraEUMode===true  && <MavTextInput style={{marginTop:5}} textInputFlex={0.65} keyboardType={"numeric"} editable={false} disabledText={false} text={this.state.money[strings.idLangue]} chooseMoney={true} flagMoney={this.state.money.flag} textExplication={strings.moneySelected} onChangeMoney ={(info)=>{ this.changeAllPrice(info); }}  />}



          <Text style={{marginTop:20,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.carType}</Text>
          
          <Text style={{marginLeft:"10%", marginRight:"10%",marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.priceClassicExplication}</Text>


          {Object.keys(this.state.carList).map((rowData,i) => (


            <MavViewMore  ref={viewCar => {this['viewCar_' + i] = viewCar;}} text={(this.state.carList[rowData].name==undefined)? strings.newCar :((this.state.carList[rowData].name!="")?this.state.carList[rowData].name:strings.noNameCar)} disabledText={true} onOpen={(text)=>{ this.closeOtherViewMore(i) }}  >

            {(this.state.driverPricing!=true && this.state.customerPricing!=true && this.state.karhooMode!=true) && <MavTextInput enableError={this.state.disabledChange!=true && this.state.carList[rowData].name==""} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].name!=undefined && this.state.carList[rowData].name.length>2 }  editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={1} backgroundColor={style.backgroundColorContrast} text={this.state.carList[rowData].name} textExplication={strings.priceCarName} placeholder={"ex : Berline"} onChangeText={(text)=>{text=text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');  var car=JSON.parse(JSON.stringify(this.state.carList));car[rowData].name=text; this.setState({carList:car}) ;}}/>}
            {(this.state.taxiMode==true && info.countryCode=="fr" && this.state.karhooMode!=true) && <MavTextInput backgroundColor={style.backgroundColorContrast} editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={(this.state.carList[rowData].cpam==undefined || this.state.carList[rowData].cpam==false)?false:true} textExplication={strings.vehicleCPAM}  enableInfo={true} onEnableInfo={() => {this.alert.onAlert({title:strings.vehicleCPAM, subTitle:strings.vehicleCPAMMoreInfo}) }} onChange={()=>{  
                var car=JSON.parse(JSON.stringify(this.state.carList)); 
                car[rowData].cpam=(car[rowData].cpam==true)?null:true;
                this.setState({carList:car});
            }}/>}

            {(this.state.driverPricing==true || this.state.customerPricing==true || this.state.karhooMode==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
                  if(this.state.disabledChange!=true){ 
                  
                  var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
                  var prov=(this.state.karhooMode==true)?params.carListName:Object.keys(params.carListName)

                  var items=[]
                  var i;
                  var itemSelected=-1;

                  for(i=0;i<prov.length;i++){
                    let index=prov[i]
                    if(this.state.karhooMode==true && prov[i].name==this.state.carList[rowData].name) itemSelected=i;
                    else if(this.state.karhooMode!=true && prov[i]==this.state.carList[rowData].name) itemSelected=i;
                    
                    items.push({itemSelected:itemSelected,text:(this.state.karhooMode==true)?prov[i].name:prov[i], })
                  }



                    this.alert.onAlert({title:strings.selectName,items:items,itemSelected:itemSelected, buttons:[{text: strings.validate,onPress: (id,value) => {
                      var car=JSON.parse(JSON.stringify(this.state.carList));car[rowData].name=value;this.setState({carList:car}) ; 
                    }},{text: strings.cancel, style:"cancel",onPress: () => { }} ]});
                  }

              }}>
               <MavTextInput style={{marginTop:5}} backgroundColor={style.backgroundColorContrast}  enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].name!="" && this.state.carList[rowData].name!=undefined } textInputFlex={1} text={(this.state.carList[rowData].name=="")?strings.selectName:this.state.carList[rowData].name} textExplication={strings.priceCarName} editable={false}/>
            </TouchableHighlight>}

            {(this.state.karhooMode!=true) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"}  enableButtonsLH={true} text={(this.state.carList[rowData].passagers==undefined)?"1":this.state.carList[rowData].passagers} textExplication={strings.morePassager+" "+strings.max.toLowerCase()} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].passagers!=undefined && this.state.carList[rowData].passagers>1}  enableQuestion={this.state.disabledChange!=true && (this.state.carList[rowData].passagers==undefined || this.state.carList[rowData].passagers==1 || this.state.carList[rowData].passagers==0)} onQuestion={(value)=>{this.alert.onAlert({title:strings.warningOnePassager}) }} onChange={(value)=>{ if(this.state.disabledChange!=true){  this.changePassagers(rowData, value) } }} />}
            {(this.state.defineMinimalPassager===true && this.state.karhooMode!=true) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"}  enableButtonsLH={true} text={(this.state.carList[rowData].passagersMin==undefined)?"1":this.state.carList[rowData].passagersMin} textExplication={strings.morePassager+" "+strings.min.toLowerCase()} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].passagersMin!=undefined}  onChange={(value)=>{ if(this.state.disabledChange!=true){  this.changePassagersMin(rowData, value) } }} />}
            {(this.state.luggageOption==true && this.state.karhooMode!=true) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"}  enableButtonsLH={true} text={(this.state.carList[rowData].luggageMax==undefined)?"1":this.state.carList[rowData].luggageMax} textExplication={strings.baggageMax} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].luggageMax!=undefined}  onChange={(value)=>{ if(this.state.disabledChange!=true){  this.changeBaggage(rowData, value) } }} />}
 
            {(this.state.co2onBill==true && this.state.karhooMode!=true) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={(this.state.carList[rowData].co2!=undefined)?this.state.carList[rowData].co2.toString():""} textExplication={strings.co2+" g/km"} enableCheck={this.state.disabledChange!=true && !isNaN(parseInt(this.state.carList[rowData].co2))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].co2!=undefined && this.state.carList[rowData].co2!="" && isNaN(parseInt(this.state.carList[rowData].co2)) } placeholder={"ex : 115 g/km"} onChangeText={(text)=>{this.changeCO2(rowData,text)}}/>}



            {this.state.taxiMode!=true && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} editable={!this.state.disabledChange} text={this.state.carList[rowData].base} textExplication={strings.priceBase} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].base!=undefined && !isNaN(parseInt(this.state.carList[rowData].base.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].base!=undefined && isNaN(parseInt(this.state.carList[rowData].base.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"base")}}/>}

            {(this.state.type=="classic" && this.state.taxiMode!=true ) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].minute} textExplication={strings.priceMinute} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].minute!=undefined && !isNaN(parseInt(this.state.carList[rowData].minute.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].minute!=undefined && isNaN(parseInt(this.state.carList[rowData].minute.replace(this.state.money.symbole,''))) }  placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"minute")}}/>}

            {(this.state.type=="classic" && this.state.taxiMode!=true ) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].km} textExplication={strings.priceKm} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].km!=undefined &&!isNaN(parseInt(this.state.carList[rowData].km.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].km!=undefined && isNaN(parseInt(this.state.carList[rowData].km.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"km")}}/>}

            {(this.state.type=="classic" && this.state.taxiMode==true ) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].tarifA} textExplication={strings.tarif+" A"} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].tarifA!=undefined && !isNaN(parseInt(this.state.carList[rowData].tarifA.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].tarifA!=undefined && isNaN(parseInt(this.state.carList[rowData].tarifA.replace(this.state.money.symbole,''))) }  placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"tarifA")}}/>}

            {(this.state.type=="classic" && this.state.taxiMode==true ) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].tarifB} textExplication={strings.tarif+" B"} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].tarifB!=undefined && !isNaN(parseInt(this.state.carList[rowData].tarifB.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].tarifB!=undefined && isNaN(parseInt(this.state.carList[rowData].tarifB.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"tarifB")}}/>}

            {(this.state.type=="classic" && this.state.taxiMode==true ) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].tarifC} textExplication={strings.tarif+" C"} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].tarifC!=undefined && !isNaN(parseInt(this.state.carList[rowData].tarifC.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].tarifC!=undefined && isNaN(parseInt(this.state.carList[rowData].tarifC.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"tarifC")}}/>}

            {(this.state.type=="classic" && this.state.taxiMode==true ) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].tarifD} textExplication={strings.tarif+" D"} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].tarifD!=undefined && !isNaN(parseInt(this.state.carList[rowData].tarifD.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].tarifD!=undefined && isNaN(parseInt(this.state.carList[rowData].tarifD.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"tarifD")}}/>}

            {this.state.taxiMode==true && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} editable={!this.state.disabledChange} text={this.state.carList[rowData].base} textExplication={strings.priceCharge} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].base!=undefined && !isNaN(parseInt(this.state.carList[rowData].base.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].base!=undefined && isNaN(parseInt(this.state.carList[rowData].base.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"base")}}/>}
            {(this.state.type!="classic" && this.state.taxiMode==true) && <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:5}} textInputFlex={1} keyboardType={"numeric"} editable={!this.state.disabledChange} text={this.state.carList[rowData].baseMajo} textExplication={strings.priceChargeMajo} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].baseMajo!=undefined && !isNaN(parseInt(this.state.carList[rowData].baseMajo.replace(this.state.money.symbole,'') ))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].baseMajo!=undefined && isNaN(parseInt(this.state.carList[rowData].baseMajo.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"10"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"baseMajo")}}/>}

            {(this.state.type=="classic" && this.state.taxiMode==true ) && <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].majoTaxiImmediate} textExplication={strings.majoTaxiImmediate} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].majoTaxiImmediate!=undefined && !isNaN(parseInt(this.state.carList[rowData].majoTaxiImmediate.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].majoTaxiImmediate!=undefined && isNaN(parseInt(this.state.carList[rowData].majoTaxiImmediate.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"majoTaxiImmediate")}}/>}
            {(this.state.type=="classic" && this.state.taxiMode==true ) && <MavTextInput backgroundColor={style.backgroundColorContrast}  style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].majoTaxiAvance} textExplication={strings.majoTaxiAvance} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].majoTaxiAvance!=undefined && !isNaN(parseInt(this.state.carList[rowData].majoTaxiAvance.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].majoTaxiAvance!=undefined && isNaN(parseInt(this.state.carList[rowData].majoTaxiAvance.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"majoTaxiAvance")}}/>}


            {(this.state.type=="classic" ) && <MavTextInput disableLine={(this.state.vipMode===true || this.state.karhooMode )?false:true} backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1} editable={!this.state.disabledChange} keyboardType={"numeric"} text={this.state.carList[rowData].minPrice} textExplication={strings.priceMin} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].minPrice!=undefined && !isNaN(parseInt(this.state.carList[rowData].minPrice.replace(this.state.money.symbole,'')))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].minPrice!=undefined && isNaN(parseInt(this.state.carList[rowData].minPrice.replace(this.state.money.symbole,''))) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"20"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"minPrice")}}/>}

            {(this.state.providerFixeCommission==true && this.state.taxiMode!=true) && <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"} text={this.state.carList[rowData].provCom}  textExplication={strings.providerCommission} enableCheck={this.state.disabledChange!=true && this.state.carList[rowData].provCom!=undefined && !isNaN(parseInt(this.state.carList[rowData].provCom))}  enableError={this.state.disabledChange!=true && this.state.carList[rowData].provCom!=undefined && isNaN(parseInt(this.state.carList[rowData].provCom)) } placeholder={"ex : "+((this.state.money.position=="left")?(this.state.money.symbole+" "):"")+"2"+((this.state.money.position=="right")?(this.state.money.symbole):"")} onChangeText={(text)=>{this.changePrice(rowData,text,"provCom")}}/>}



            {(this.state.vipMode===true) && <MavTextInput backgroundColor={style.backgroundColorContrast} editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={(this.state.carList[rowData].target==undefined || this.state.carList[rowData].target.standard!=false)?true:false} textExplication={strings.authorisedStandards}  onChange={()=>{ this.changeTarget(rowData,"standard") }}/>}
            {(this.state.vipMode===true) && <MavTextInput backgroundColor={style.backgroundColorContrast} editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={(this.state.carList[rowData].target==undefined || this.state.carList[rowData].target.vip!=false)?true:false} textExplication={strings.authorisedVIP}  onChange={()=>{ this.changeTarget(rowData,"vip") }}/>}
            {(this.state.vipMode===true) && <MavTextInput disableLine={true} backgroundColor={style.backgroundColorContrast} editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={(this.state.carList[rowData].target==undefined || this.state.carList[rowData].target.pro!=false)?true:false} textExplication={strings.authorisedPro}  onChange={()=>{ this.changeTarget(rowData,"pro") }}/>}


            {this.state.karhooMode===true && <MavTextInput backgroundColor={style.backgroundColorContrast} editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowCircle={true} arrowEnabled={(this.state.carList[rowData].targetKarhoo===undefined)} textExplication={strings.karhooForAll}  onChange={()=>{ this.changeTargetKarhoo(rowData,"all") }}/> }  
            {this.state.karhooMode===true && <MavTextInput backgroundColor={style.backgroundColorContrast} editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowCircle={true} arrowEnabled={(this.state.carList[rowData].targetKarhoo!=undefined && this.state.carList[rowData].targetKarhoo.public==true)} textExplication={strings.karhooOnlyGP}  onChange={()=>{ this.changeTargetKarhoo(rowData,"public") }}/> }  
            {this.state.karhooMode===true && <MavTextInput backgroundColor={style.backgroundColorContrast} disableLine={true} editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowCircle={true} arrowEnabled={(this.state.carList[rowData].targetKarhoo!=undefined && this.state.carList[rowData].targetKarhoo.assistance==true)} textExplication={strings.karhooOnlyAssis}  onChange={()=>{ this.changeTargetKarhoo(rowData,"assistance") }}/> }  



           { this.state.disabledChange!=true &&  <View style={{alignItems:"center",justifyContent:"center", marginTop:10, flexDirection:"row"}}> 

                <MavButton onlyButton={true} style={{marginRight:5}} text={strings.validate} onPress={()=>{ var err=this.verifyFields(i); if(err==false) this['viewCar_' + i].close(); else this.alert.onAlert({title:err,}) }}/> 
 
                <MavButton onlyButton={true} style={{marginLeft:5}} strokeMode={true} text={strings.remove} onPress={()=>{  
                  var cl={}
                  var i;
                  var obj=Object.keys(this.state.carList)
                  var test;
                  for (i=0;i<obj.length;i++){
                    console.log("je teste "+i+ " "+rowData+" ")
                    this['viewCar_' + i].close()
                    test="type"+(i+1);
                    if(test!=rowData) cl["type"+(Object.keys(cl).length+1)]=this.state.carList[obj[i]];

                  }

                  this.setState({carList:cl})  
                }}/>
            </View>}







           </MavViewMore>


            ))}



        {(Object.keys(this.state.carList).length<7 && (!this.state.disabledChange) ) && <View style={{alignItems:"center",justifyContent:"center", marginTop:30}}>  
            <MavButton onlyButton={true} bottomText={strings.vehiculeOne} text={strings.add} style={{marginTop:30}} onPress={()=>{ var car=JSON.parse(JSON.stringify(this.state.carList)); car["type"+(Object.keys(car).length+1)]={};  this.setState({carList:car},() =>{ this['viewCar_' + (Object.keys(this.state.carList).length-1)].open()  })   }}/>
            </View>}

        <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,marginTop: style.textInputMarginTop+10,marginLeft:"10%",width: "80%",}}/>


          {this.state.activateETA==true && <View>

            <Text style={{marginTop:20,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.etaEstimation}</Text>

            <Text style={{marginLeft:"10%", marginRight:"10%",marginTop:5,color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "center",}}>{strings.etaEstimationMoreInfo}</Text>











          {Object.keys(this.state.etaList).map((marker,i) => (
          <MavViewMore ref={viewEtaList_ => {this['viewEtaList_' + i] = viewEtaList_;}}  onOpen={(text)=>{ this.closeETAViewMore(i) }} text={(marker=="new")? strings.newETA: (this.state.etaList[marker].minutes+" "+strings.minutes)}   >


                      <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} editable={!this.state.disabledChange} textInputFlex={1} keyboardType={"numeric"}  enableButtonsLH={true} text={this.state.etaList[marker].minutes} textExplication={strings.minutes} onChange={(value)=>{var etaList=JSON.parse(JSON.stringify(this.state.etaList)); if(value==0 && etaList[marker].minutes>5) etaList[marker].minutes-=5; else if(value==1) etaList[marker].minutes+=5;   this.setState({etaList:etaList})  }} />


                      <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
                              if(this.state.disabledChange!=true){ 
                                this.alert.onAlert({title:strings.defineHour,textInput:{placeholder:"00:00",text:this.state.etaList[marker].startDate,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                                    {text: strings.validate,onPress: (value) => {
                                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ) {var etaList=JSON.parse(JSON.stringify(this.state.etaList)); etaList[marker].startDate=value;     this.setState({etaList:etaList})}
                                       else this.alert.onAlert({title:strings.errorHour,})
                                       
                                    }},
                                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                                    ],})
                                  }
                              }}>
                              <MavTextInput backgroundColor={style.backgroundColorContrast} editable={false} textInputFlex={1.3} text={(this.state.etaList[marker].startDate!="")?this.state.etaList[marker].startDate:strings.hourNotDefine} style={{marginTop:5}} textExplication={strings.startHour} />
                            </TouchableHighlight>


                            <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
                              if(this.state.disabledChange!=true){ 
                                this.alert.onAlert({title:strings.defineHour,textInput:{placeholder:"00:00",text:this.state.etaList[marker].endDate,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                                    {text: strings.validate,onPress: (value) => {
                                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ) {var etaList=JSON.parse(JSON.stringify(this.state.etaList)); etaList[marker].endDate=value;     this.setState({etaList:etaList})}
                                       else this.alert.onAlert({title:strings.endDate,})
                                       
                                    }},
                                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                                    ],})
                                  }
                              }}>
                              <MavTextInput backgroundColor={style.backgroundColorContrast}  editable={false} textInputFlex={1.3} text={(this.state.etaList[marker].endDate!="")?this.state.etaList[marker].endDate:strings.hourNotDefine} style={{marginTop:5}} textExplication={strings.endHour} />
                            </TouchableHighlight>

                            <Text style={{marginTop:25,marginBottom:10,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.okayDayList}</Text>

                            {true && strings.dayListComplete.map( (markerDayList,key) => ( 

                                <MavTextInput backgroundColor={style.backgroundColorContrast} style={{marginTop:5}} textInputFlex={1.5} disabledText={true}  arrow={true} arrowEnabled={(this.state.etaList[marker].excludeDay==undefined || (this.state.etaList[marker].excludeDay!=undefined && this.state.etaList[marker].excludeDay[key]!=true))} textExplication={markerDayList}  onChange={()=>{var etaList=JSON.parse(JSON.stringify(this.state.etaList));  if(etaList[marker].excludeDay==undefined) etaList[marker].excludeDay={};  var exc=etaList[marker].excludeDay;  if(exc[key]==true) delete exc[key]; else exc[key]=true; etaList[marker].excludeDay=exc; this.setState({etaList:etaList})   }}/>

                            ))}

                      {this.state.disabledChange!=true && <View style={{alignItems:"center",justifyContent:"center", marginTop:10, flexDirection:"row"}}>
                          <MavButton onlyButton={true} style={{marginRight:5}} text={strings.validate} onPress={()=>{ if(marker=="new") this.addetaListInfo(); else this['viewEtaList_' + i].close(); }}/> 
                          <MavButton onlyButton={true} style={{marginLeft:5}} strokeMode={true} text={(marker=="new")?strings.cancel:strings.remove} onPress={()=>{ 
                              var etaList=JSON.parse(JSON.stringify(this.state.etaList))
                              delete etaList[marker];
                              this.setState({etaList:{} },() =>{  this.setState({etaList:etaList})    })


                          }}/>
                      </View>}

          </MavViewMore>
        ))}














            <View style={{alignItems:"center",justifyContent:"center", marginTop:30}}>  
                  <MavButton onlyButton={true} bottomText={strings.addETA} text={strings.add} style={{marginTop:30}} onPress={()=>{ this.addETA() }}/>
            </View>


            <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,marginTop: style.textInputMarginTop+10,marginLeft:"10%",width: "80%",}}/>


          </View>}
            

          <Text style={{marginTop:20,color: style.textInputColor,fontSize: style.textInputFontSize*1.2,textAlign: "center",}}>{strings.options}</Text>

          {this.state.taxiMode!=true && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {  this.navigateTo('Majoration',{karhooMode:this.state.karhooMode,majoration:this.state.majoration, updateMajoration:this.updateMajoration, money:this.state.money, disabledChange:this.state.disabledChange }); }}>
              <MavTextInput disabledText={true} enableNext={true} style={{marginTop:5}} textExplication={strings.localMajoration} />
            </TouchableHighlight>}


            <MavViewMore text={strings.limitTime} disabledText={true}>
                  <Text style={{marginTop:10,marginLeft:"10%", marginRight:"10%",color: style.textInputColor,fontSize: style.textInputFontSize*0.8,textAlign: "justify",}}>{(this.state.type=="forfait")?strings.limitTimeMoreInfoForfait:strings.limitTimeMoreInfoZone}</Text>
                  
                  <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
              if(this.state.disabledChange!=true){ 
                this.alert.onAlert({title:strings.defineHour,textInput:{placeholder:"00:00",text:this.state.validityFrom,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                    {text: strings.validate,onPress: (value) => {
                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ){
                        this.setState({validityFrom:value})
                       }
                       else {
                         this.alert.onAlert({title:strings.errorHour,})
                       }
                    }},
                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                    ],})
                  }
              }}>
              <MavTextInput editable={false} enableRemove={(this.state.validityFrom=="")?false:true} onRemove={() => {this.setState({validityFrom:""})}} backgroundColor={style.backgroundColorContrast} text={(this.state.validityFrom!="")?this.state.validityFrom:strings.hourNotDefine} style={{marginTop:5}} textExplication={strings.validityFrom} />
            </TouchableHighlight>


            



            <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
              if(this.state.disabledChange!=true){ 
                this.alert.onAlert({title:strings.defineHour,textInput:{placeholder:"00:00",text:this.state.validityTo,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                    {text: strings.validate,onPress: (value) => {
                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ){
                        this.setState({validityTo:value})
                       }
                       else {
                         this.alert.onAlert({title:strings.errorHour,})
                       }
                    }},
                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                    ],})
                }
              }}>
              <MavTextInput disableLine={true} editable={false} enableRemove={(this.state.validityTo=="")?false:true} onRemove={() => {this.setState({validityTo:""})}} backgroundColor={style.backgroundColorContrast} text={(this.state.validityTo!="")?this.state.validityTo:strings.hourNotDefine} style={{marginTop:5}} textExplication={strings.validityTo} />
            </TouchableHighlight>



           </MavViewMore>







            {(this.state.taxiMode==true ) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
              if(this.state.disabledChange!=true){ 
                this.alert.onAlert({title:strings.defineHour,textInput:{placeholder:"00:00",text:this.state.tarifBDFrom,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                    {text: strings.validate,onPress: (value) => {
                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ){
                        this.setState({tarifBDFrom:value})
                       }
                       else {
                         this.alert.onAlert({title:strings.errorHour,})
                       }
                    }},
                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                    ],})
                  }
              }}>
              <MavTextInput editable={false} text={(this.state.tarifBDFrom!="")?this.state.tarifBDFrom:strings.hourNotDefine} style={{marginTop:5}} textExplication={strings.tarifBDFrom} />
            </TouchableHighlight>}



            {(this.state.taxiMode==true) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { 
              if(this.state.disabledChange!=true){ 
                this.alert.onAlert({title:strings.defineHour,textInput:{placeholder:"00:00",text:this.state.tarifBDTo,sizeMultiplicator:2,typeHour:true,keyboardType:"numeric"},buttons:[
                    {text: strings.validate,onPress: (value) => {
                       if(value.length==5 && parseInt(value[0]+value[1])<24 && parseInt(value[3]+value[4])<60 ){
                        this.setState({tarifBDTo:value})
                       }
                       else {
                         this.alert.onAlert({title:strings.errorHour,})
                       }
                    }},
                    {text: strings.cancel, style:"cancel",onPress: () => {}}
                    ],})
                }
              }}>
              <MavTextInput editable={false} text={(this.state.tarifBDTo!="")?this.state.tarifBDTo:strings.hourNotDefine} style={{marginTop:5}} textExplication={strings.tarifBDTo} />
            </TouchableHighlight>}







            {(this.state.taxiMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.tarifBDSaturday} textExplication={strings.tarifBDSaturday}  onChange={()=>{this.setState({tarifBDSaturday:!this.state.tarifBDSaturday}) }}/>}
            {(this.state.taxiMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.tarifBDSunday} textExplication={strings.tarifBDSunday}  onChange={()=>{this.setState({tarifBDSunday:!this.state.tarifBDSunday}) }}/>}
            {(this.state.taxiMode==true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.tarifBDFerie} textExplication={strings.tarifBDFerie}  onChange={()=>{this.setState({tarifBDFerie:!this.state.tarifBDFerie}) }}/>}

            {(this.state.type=="classic" && this.state.taxiMode==true && this.state.karhooMode!=true) && <MavTextInput editable={!this.state.disabledChange} style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.coeffEnable} textExplicationOnPress={() => {this.alert.onAlert({title:strings.coeffEnable, subTitle:strings.coeffEnableMoreInfo}) }} textExplication={strings.coeffEnable+((this.state.coeffEnable==true)?(" (+/- "+this.state.coeffPercent+"%)"):"")+" - "+strings.clickMoreInfos}  onChange={()=>{
              this.setState({coeffEnable:!this.state.coeffEnable},() =>{
                if(this.state.coeffEnable==true) {

                  this.alert.onAlert({title:strings.definePercent,textInput:{placeholder:"0%",text:this.state.coeffPercent.toString()+"%",sizeMultiplicator:2,detectSequencePosition:"right",detectSequence:"%",keyboardType:"numeric"},buttons:[
                  {text: strings.validate,onPress: (value) => {value=parseInt(value); if(value<50) this.setState({coeffPercent:value}); else this.alert.onAlert({title:strings.percentError}) }},
                  {text: strings.cancel, style:"cancel",onPress: () => {}}
                  ],})


                }

              })
              

            }}/>}





         {(!this.state.disabledChange) && <View style={{alignItems:"center",justifyContent:"center", marginTop:30}}>

          <MavButton text={strings.confirm} onlyButton={true} onPress={()=>{  this.confirmPlace()}}/>

        </View>}


        {(this.state.id!="" && this.state.karhooMode!=true) && <View style={{alignItems:"center",justifyContent:"center", marginTop:15}}> 
            <MavButton onlyButton={true} text={(this.state.type=="forfait")?strings.copyThisForfait:strings.copyThisZone} color={style.color1}  onPress={()=>{  this.confirmPlace("copy")}}/> 
        </View>}



          

          {((!this.state.disabledChange) && this.state.id!="") && <View style={{alignItems:"center",justifyContent:"center", marginTop:15}}>

          <MavButton onlyButton={true} text={strings.remove} strokeMode={true} color={style.color1}  onPress={()=>{


            if(this.state.karhooMode!=true) {
              const fireInfo={} 
              fireInfo[this.state.ref+'/' + this.state.id]=null
              update(ref(getDatabase(firebase)), fireInfo)
            }
            
            var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
            if(this.state.karhooMode==true) params.updateData(this.state.id, {action:"remove"});
            else if( params!=undefined && (params.driverPricing==true || params.customerPricing==true) && params.updatePersonalData!=null ) params.updatePersonalData(this.state); 
            else if(params.updateData!=null) params.updateData(this.state);
            
            if(this.props.navigation!=undefined) this.props.navigation.goBack(); 
              else params.goBack();
               
          }}/> 

          </View>}

          <View style={{width:40, height:40}} />

          

          




        <MavAlert ref={alert => {this.alert = alert;}}/>
        
        

      </ScrollView>
      }</StyleContext.Consumer>
      
    );
  }
}


export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
