import React, { Component } from 'react';

import {StyleContext} from '../styleC';
import strings from '../strings';
import firebase from './firebase';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword,sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, limitToFirst, startAt, endAt, query} from "firebase/database";
import info from '../info';
import MavSelector from './mavSelector';
import MavButton from './mavButton';
import MavNavigationMinimize from './mavNavigationMinimize';
import countryList from '../countryList';
import example from '../example';
import MavAlert from './mavAlert';
const timer = require('react-native-timer');
import {
  View,
  Text,
  TouchableHighlight,
  Alert,
  ScrollView,
  Image,
  TextInput,
  Dimensions,Picker,
Platform,
Linking,
ActivityIndicator
 
} from 'react-native';
import Qs from 'qs';

import MavTextInput from './mavTextInput';
import Modal from './modal';



export default class trajet extends Component {
  constructor(props) {
    super(props);
    

    

      var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
      var phoneInfo=this.findPhoneIID();



    

    
    this.state={
      mode:"create",
      textInput:"",
      minimizeMode:true, 
      waiting:false,
      name:"",
      lastName:"",
      email:"", 
      password:"",
      countryPhone:phoneInfo.id,
      flagPhone:phoneInfo.flag,
      countryPhoneIID:phoneInfo.idd,
      partnerListAccount:[{id:-1,text:strings.noFavoriteDriver}],
      partnerSelected:{id:-1,text:strings.noFavoriteDriver},
      lockOtherPartner:(params.lockOtherPartner==true)?true:false, 
      cguAgree:false ,
      cguText:[], 
      seeCgu:false, 
      
      
    }




    
   

 
  }
  componentDidMount(){
    if(info.disableFavoriteDriverForCustomer==false) this.getPartnersList()
    

  }

  viewPartner(){

      var selected=-1;
      var i;
      for(i=0;i<this.state.partnerListAccount.length;i++){
          if(this.state.partnerListAccount[i].id==this.state.partnerSelected.id) selected=i;
      }
      this.alert.onAlert({title:strings.desiredDriver, items:this.state.partnerListAccount ,itemSelected:selected, buttons:[{text: strings.validate,onPress: (id,value) => {
        this.setState({partnerSelected:this.state.partnerListAccount[id]})
       
      }},{text: strings.cancel, style:"cancel",onPress: () => {}}] })  

  }



  getPartnersList(){
    fetch("https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/getPartner", {body: "{\"uuid\":\"new\"}",method: "POST"}).then((response) => response.json()).then((responseJson) => {
          var res=responseJson
          var res2=[{id:-1,text:strings.noFavoriteDriver}]
          var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
          var i; 
          for(i=0;i<res.length;i++){
            res2.push(res[i]);
            if(params.partnerID!=undefined && params.partnerID!=false && res[i].id==params.partnerID) this.setState({partnerSelected:res[i]})
          }
          this.setState({partnerListAccount:res2})
          
        }).catch((err) => { console.log("error getPartnersList"); console.log(err); timer.setTimeout("getPartnersList", () => { this.getPartnersList();  }, 2000);   })
    }



  findPhoneIID(test){
    if(test!=undefined) test=test.replace("+","00")
    var final="";
    var i, j;
    var n;
    var list=Object.keys(countryList);
    for(i=0;i<list.length;i++){
      if(final==""){
          var testList=countryList[list[i]].list;
          for(j=0;j<testList.length;j++){ 
            if(test!=undefined){
              n=testList[j].idd.replace("+","00")
              n=test.search(n)
              if(n==0) final=testList[j]
            }
            else if(testList[j].id==info.countryCode) final=testList[j]
          }
      }
    }
    if(final=="") return {id:"fr",code:"+33"}
    else return {id:final.id,idd:final.idd, flag:final.flag}
    
  }

createAccount(){
    if(this.state.waiting==false){
      this.setState({waiting:true})

      if(this.state.name.length>0 && this.state.lastName.length>0){
        let reg = /[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,8}$/;
        if(reg.test(this.state.email)===true){
          if(this.state.phone==""){this.setState({waiting:false});this.alert.onAlert({title:strings.incorrectPhoneNumber}) }
          else {
            if(this.state.password.length<8){this.setState({waiting:false});this.alert.onAlert({title:strings.incorrectPasswordLength})}
            else {
              let reg = /[0-9]/;
              if(!reg.test(this.state.password)) {this.setState({waiting:false});this.alert.onAlert({title:strings.incorrectPasswordNumber}) }
              else {
                let reg1 = /[a-z]/;let reg2 = /[A-Z]/;
                if(!(reg1.test(this.state.password) && reg2.test(this.state.password))) {this.setState({waiting:false}); this.alert.onAlert({title:strings.incorrectPasswordUpperCase}) }
                else { 
                  if(this.state.cguAgree==false){this.setState({waiting:false});this.alert.onAlert({title:strings.cguMustAgree}) }
                  else {
                    
                    createUserWithEmailAndPassword(getAuth(firebase),this.state.email, this.state.password).then(snapshot => {
                      this.setState({waiting:false})
                      this.unsubscribe = onAuthStateChanged(getAuth(firebase), user => {
                        if(user!=null){
                          var phoneTotal=this.state.countryPhoneIID+this.state.phone
                          var sendFi={name: this.state.name,lastName:this.state.lastName,email:this.state.email,phone:phoneTotal}
                          if(info.disableFavoriteDriverForCustomer==false && this.state.partnerSelected.id!=-1) sendFi.favoriteDriver=this.state.partnerSelected;
                          
                          const fireInfo={} 
                          fireInfo['users/' + user.uid]=sendFi
                          update(ref(getDatabase(firebase)), fireInfo)

                        }
                        
                        var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
                        
                        params.goToBook();
                        /*var goBck=(this.props.navigation!=undefined)?this.props.navigation:this.props.params;
                        goBck.goBack();*/
                        

                        this.unsubscribe();
                      });
                    }).catch(error => {
                        this.setState({waiting:false})
                        if(error.code==="auth/email-already-in-use") this.alert.onAlert({title:strings.mailAlreadyUse}) 
                        else this.alert.onAlert({title:strings.errorDefault}) 
                      })}
                  }
                }
              }
            }
          }
          else {this.setState({waiting:false}); this.alert.onAlert({title:strings.incorrectEmailFormat}) }
        }
        else {this.setState({waiting:false});this.alert.onAlert({title:strings.incorrectNameLastName}) }
      }
  }


  loginWithAccount(){
     if(this.state.waiting==false){
              this.setState({ waiting: true });
              signInWithEmailAndPassword(getAuth(firebase),this.state.email,this.state.password).then(user => {
                  console.log("user bien ok")
                this.setState({ waiting: false }); 
                /*var goBck=(this.props.navigation!=undefined)?this.props.navigation:this.props.params;
                goBck.goBack();
                */
                var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 
                params.goToBook();

              })
                    .catch(error => {
                      const { code, message } = error; 
                      this.setState({ waiting: false });
                      console.log(message);
                      this.alert.onAlert({title:strings.incorrectEmailPassword}); 
                    });
     }
  }

  
  seeCgu(){
    if(this.state.cguText.length>0)  this.setState({seeCgu:true})
    else {
      get(child(ref(getDatabase(firebase)), '/faq/CGU')).then(snapshot => { 
        var val=snapshot.val();
        
        var cguText=(val[strings.idLangue]==undefined)?val["fr"].description:val[strings.idLangue].description;
        cguText=cguText.split('.');

        this.setState({cguText:cguText,seeCgu:true })
      });
    }
  }


  
  

  render() {
    return (
    <StyleContext.Consumer>{style => 
     <ScrollView style={{flex:1,backgroundColor:style.backgroundColor}}>


          {(Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax ) && <MavNavigationMinimize clickLeft={() => { var params=(this.props.navigation!=undefined)?this.props.navigation:this.props.params; params.goBack();  }} left={strings.return} title={ strings.account } />}

          <TouchableHighlight underlayColor={style.backgroundColor}  onPress={() => {this.setState({mode:(this.state.mode=="create")?"login":"create"});  }} >
              <Text style={{textDecorationLine: 'underline',marginTop: 10,marginBottom:5,textAlign: 'center',fontSize: style.textInputFontSize*0.8,color: style.textInputColor,width: ((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth:Dimensions.get('window').width) }}>{(this.state.mode=="create")?strings.alreadyAccountSignUp:strings.noAccountCreate}</Text>
          </TouchableHighlight>

          {this.state.mode=="create" && <MavTextInput style={{marginTop:5}} textInputFlex={2} editable={!this.state.waiting} text={this.state.name} textExplication={(this.state.adminView==true)?strings.name:strings.name} placeholder={example.name} onChangeText={(text)=>{this.setState({name:text})}}/>}
          {this.state.mode=="create" && <MavTextInput style={{marginTop:5}} textInputFlex={2} editable={!this.state.waiting} text={this.state.lastName} textExplication={(this.state.adminView==true)?strings.lastName:strings.lastName} placeholder={example.lastName} onChangeText={(text)=>{this.setState({lastName:text})}}/>}
          
          {this.state.mode=="create" && <MavTextInput style={{marginTop:5}} textInputFlex={2} editable={!this.state.waiting } text={this.state.phone} keyboardType={"numeric"} choosePhone={true} countryPhone={this.state.countryPhone} flagPhone={this.state.flagPhone} onChangeCountry={(info)=>{ this.setState({countryPhone:info.id,countryPhoneIID:info.idd,flagPhone:info.flag }) }} textExplication={strings.phone} placeholder={example.phone} onChangeText={(text)=>{this.setState({phone:text})}}/>}
          

          <MavTextInput style={{marginTop:5}} textInputFlex={2} editable={!this.state.waiting  } keyboardType={"email-address"} text={this.state.email} textExplication={(this.state.adminView==true)?strings.email:strings.email} placeholder={example.email} onChangeText={(text)=>{
            var emailFinal=text;
            emailFinal=emailFinal.replace(/\s/g, '');
            this.setState({email:emailFinal})}}/>


            {<MavTextInput style={{marginTop:5}} secureTextEntry={true} editable={!this.state.waiting} disableLine={(this.state.mode=="create")?true:false} textInputFlex={2} text={this.state.password} enableViewPassword={true}  textExplication={strings.password} placeholder={"********"} onChangeText={(text)=>{this.setState({password:text})}}/>}

             {(this.state.mode=="create") && <View style={{marginLeft:"10%", marginRight:"10%",}}>
                
                <Text style={{fontSize: style.textInputFontSize*0.8,color:(/[A-Z]/.test(this.state.password)==true)?style.colorValidate:style.textInputColor}}>{ ((/[A-Z]/.test(this.state.password)==true)?"✓ ":"- ")+strings.caractereMAJ}</Text>
                <Text style={{fontSize: style.textInputFontSize*0.8,color:(/[a-z]/.test(this.state.password)==true)?style.colorValidate:style.textInputColor}}>{ ((/[a-z]/.test(this.state.password)==true)?"✓ ":"- ")+strings.caractereMinus}</Text>
                <Text style={{fontSize: style.textInputFontSize*0.8,color:(/[0-9]/.test(this.state.password)==true)?style.colorValidate:style.textInputColor}}>{ ((/[0-9]/.test(this.state.password)==true)?"✓ ":"- ")+strings.caractereNumber}</Text>
                <Text style={{fontSize: style.textInputFontSize*0.8,color:(this.state.password.length>7)?style.colorValidate:style.textInputColor}}>{ ((this.state.password.length>7)?"✓ ":"- ")+strings.caractereMin}</Text>
                <View style={{height: style.textLineHeight,backgroundColor: style.textInputLineColor,marginTop: 10}}/>

            </View>}

            




            {(this.state.mode=="login") && <TouchableHighlight underlayColor={'#FFFFFFF0'}  onPress={() => {

            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
              if(reg.test(this.state.email)===true) {
                sendPasswordResetEmail(getAuth(firebase),this.state.email).then(r => {
                  this.alert.onAlert({title:strings.emailReinit}); 
                }).catch(error => {
                    console.log(error);this.alert.onAlert({title:strings.errorDefault}); });
              }
             else this.alert.onAlert({title:strings.noEmail});

              }} >
              <Text style={{marginTop: 10,marginBottom:5,textAlign: 'center',fontSize: style.textInputFontSize*0.6,color: style.textInputColor,width: ((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth:Dimensions.get('window').width) }}>{strings.forbidPassword.toUpperCase()}</Text>
          </TouchableHighlight>}

          




            {((info.disableFavoriteDriverForCustomer==false && this.state.mode=="create"   )  ) && <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {if(this.state.lockOtherPartner!=true) this.viewPartner()}}>
              <MavTextInput style={{marginTop:5}} textInputFlex={2} textExplication={strings.myDriver} text={this.state.partnerSelected.text} editable={false}/>
          </TouchableHighlight>}




          {this.state.mode=="create" && <MavTextInput style={{marginTop:5}} textInputFlex={0.8} disabledText={true}  arrow={true} arrowEnabled={this.state.cguAgree} textExplication={strings.cguAgree} editable={!this.state.waiting} textExplicationOnPress={() => {
              this.seeCgu(); 

           }} onChange={()=>{
            if(this.state.waiting==false){
              if(this.state.cguAgree==true) this.setState({cguAgree:false}) 
              else this.setState({cguAgree:true}) } 
          }}/>}



        {this.state.waiting==false && <View style={{width: ((Dimensions.get('window').width>style.mobileSizeMax)?style.minimizeWidth:Dimensions.get('window').width),alignItems:"center",justifyContent:"center",marginTop:10}}>
          <MavButton text={strings.confirm} onlyButton={true} style={{marginTop:30}} onPress={()=>{ 
            if(this.state.mode=="create") this.createAccount();
            else this.loginWithAccount(); 
          }}/>
        </View>}



          {this.state.waiting==true && <ActivityIndicator style={{marginTop:30,marginBottom:50}} size="large" color={style.color1} />}


          <MavAlert ref={alert => {this.alert = alert;}}/>

          <Modal animationType="none" transparent={true} visible={this.state.seeCgu}>
              <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,position:"absolute"}}>
                  
                  
                  {!(Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax ) && <View style={{height:Dimensions.get('window').height,width:Dimensions.get('window').width,backgroundColor:"rgba(0,0,0,0.5)",position:"absolute",marginTop:0}}/>}
                  

                  <View style={{marginTop:Dimensions.get('window').height*((Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax )?0:0.1), height:Dimensions.get('window').height*((Platform.OS=="web" || Dimensions.get('window').width>style.mobileSizeMax )?1:0.9),width:Dimensions.get('window').width, backgroundColor:style.backgroundColor}}> 

                      <MavNavigationMinimize clickLeft={() => { this.setState({seeCgu:false});  }} left={strings.return} title={ "CGU"} />


                      <ScrollView style={{marginLeft:15, marginRight:15, marginTop:15}}>
                      {this.state.cguText.map(text => (
                          <Text style={{fontSize: style.textInputFontSize}}>{text+ ((text!="" && text!=" " )?".":"")}</Text>
                        ))}

                        <View style={{alignItems:"center",justifyContent:"center",marginTop:10}}>
                          <MavButton text={strings.return} onlyButton={true} style={{marginTop:20, marginBottom:20}} onPress={()=>{ this.setState({seeCgu:false});  }}/>
                        </View>
                        
                      </ScrollView>

                  </View>

                  
              </View>
          </Modal>

 





          


     </ScrollView>

     }</StyleContext.Consumer>

    );
  }
}


export function generateUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
