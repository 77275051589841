import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  Dimensions,
  Image,
  Alert,
  ScrollView,
  ActivityIndicator,
  Platform, 
  Clipboard,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';
import firebase from './firebase';
import MavButton from './mavButton';
import { getAuth,  } from "firebase/auth";

import TextPresentation from './textPresentation';
const timer = require('react-native-timer');

export default class payment extends Component {
  constructor(props) {
    super(props);
    

    var exempleDateCard=new Date();
    exempleDateCard.setFullYear(exempleDateCard.getUTCFullYear()+2)
    exempleDateCard=exempleDateCard.getUTCFullYear().toString()[2]+exempleDateCard.getUTCFullYear().toString()[3]
    exempleDateCard="07/"+exempleDateCard;


    this.state={
      waiting:false,
      
      exempleDateCard:exempleDateCard,
      cardNumber:"",
      cardExpiration:"",
      cvv:"",
      typeCard:"unknown",
      error:{}, 
    }
    

  }



sendNewCard(){
      var test=true;
      
      var error={}
      console.log(this.state.cardNumber.length)
      if(!(this.state.cardNumber.length==19 || (this.state.cardNumber.length==17 && this.state.typeCard=="amex") )) {error.cardNumber=true; test=false;}
      if(this.state.cardExpiration.length!=5) {error.cardExpiration=true; test=false;}
      if(!(this.state.cvv.length==3 || (this.state.cvv.length==4 && this.state.typeCard=="amex"))) {error.cvv=true; test=false;}
      
      if(test==false){this.setState({error:error, errorDefault:false}) }
      else {
        this.setState({waiting:true, errorDefault:false})
        var card=this.state.cardNumber;

        getAuth(firebase).currentUser.getIdToken().then(token => {
              var body={
                  userID:getAuth(firebase).currentUser.uid,
                  bundleIdentifer:info.bundleIdentifer,
                  cardNumber:card,
                  expiration:this.state.cardExpiration,
                  cvv:this.state.cvv,
                  token:token,
                  bundleAdress:info.firebaseProjectID,
                  version:info.appliVersion,
              }

               fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/newStripeCard", {body: JSON.stringify(body),method: "POST"}).then((response) => response.text()).then((responseJson) => {
                var res=responseJson;
                console.log("j'ajoute une carte stripe")
                console.log(res)
                if(res==="ok"){
                  this.setState({cardNumber:"",cardExpiration:"",cvv:"",typeCard:""})
                  this.maVM.close();
                  if(this.props.newCardAdded!=undefined) this.props.newCardAdded()
                }
                else{ this.setState({waiting:false, errorDefault:true}) }
 

               }).catch((err) => { console.log("error sendNewCard"); console.log(err); timer.setTimeout("sendNewCard", () => { this.sendNewCard();  }, 2000);   })


        })


          
      }
      
  }


detectCardType(number) {
    var re = {
        electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        dankort: /^(5019)\d+$/,
        interpayment: /^(636)\d+$/,
        unionpay: /^(62|88)\d+$/,
        visa: /^4[0-9]\d+$/,
        mastercard: /^5[1-5]\d+$/,
        amex: /^3[47][0-9]\d+$/,
        diners: /^3(?:0[0-5]|[68][0-9])\d+$/,
        discover: /^6(?:011|5[0-9]{2})\d+$/,
        jcb: /^(?:2131|1800|35\d{3})\d+$/
    }
  
    for(var key in re) {
        if(re[key].test(number)) {
            return key
        }
    }
}


componentDidMount() {
  console.log("addCreditCard mount")
}

cardNumber(text){
        text=text.replace(/\s+/g,"");
        var card=this.state.cardNumber
        if(this.state.typeCard!="amex") card=text.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'') 
        else card=text.replace(/(\d{4})/, '$1 ').replace(/(\d{6})/, '$1 ').replace(/(^\s+|\s+$)/,'') 
        var typeCard=this.detectCardType(text)
        if(typeCard!="visa" && typeCard!="mastercard" && typeCard!="amex") typeCard="unknown"
        var error=this.state.error
        delete error.cardNumber;
        this.setState({ cardNumber:card,typeCard:typeCard, error:error });         
}

cardExpiration(text){

   var final="";
   if(text[0]!=undefined && text[0]!="u"){
   if(text[0]>1)final+="0"+text[0]+"/";
   else final+=text[0];

   if(text[1]!=undefined && ((text[0]==1 && text[1]<3) || (text[0]==0)) ){
      if(this.state.cardExpiration[2]==="/" && text[3]===undefined) final=final;
      else{
        final+=text[1]+"/";
      if(text[3]!=undefined) final+=text[3]
        if(text[4]!=undefined) final+=text[4]
      }
    
    }

    }
    var card=this.state.cardExpiration;
    var error=this.state.error
    delete error.cardExpiration;
    this.setState({ cardExpiration:final,error:error });

}


cardCVV(text){
   var final=((text[0]!=undefined)?text[0]:"")+((text[1]!=undefined)?text[1]:"")+((text[2]!=undefined)?text[2]:"")
   if(this.state.typeCard=="amex") final+=((text[3]!=undefined)?text[3]:"")
   var error=this.state.error
   delete error.cvv;
   this.setState({ cvv: final, error:error });
}




render() {
    return (
      <StyleContext.Consumer>{style => 




            <TextPresentation style={this.props.style} disableViewEdit={true} ref={maVM => {this.maVM = maVM;}} defautVisible={this.props.defautVisible} text={strings.addCard} editable={false} icon={require("../assets/paymentCard.png")} >


          
                
                {(Object.keys(this.state.error).length>0) && <Text style={{marginTop:10, marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', textAlign:"center", color:style.colorNotification,fontSize: style.textInputFontSize}}>{strings.thanksAllItems}</Text>}
                {this.state.errorDefault==true && <Text style={{marginTop:10, marginLeft:"5%", marginRight:"5%",fontWeight: 'bold', textAlign:"center", color:style.colorNotification,fontSize: style.textInputFontSize}}>{strings.errorDefault}</Text>}
            
                <TextPresentation style={{marginTop:5}} colorNotification={(this.state.error.cardNumber==true)?true:undefined}  icon={(this.state.typeCard=="visa")?require('../assets/visa.png') :((this.state.typeCard=="mastercard")?require('../assets/mastercard.png') : (this.state.typeCard=="amex")?require('../assets/amex.png') : ( (style.mode=="dark")?require('../assets/unknownCard_white.png'):require('../assets/unknownCard_black.png')  ) ) } backgroundColor={style.backgroundColorContrast}   editable={!this.state.waiting && this.props.disable!=true} textExplication={strings.cardNumber} placeholder={"4242 4242 4242 4242"} keyboardType={"numeric"}  text={this.state.cardNumber} onChangeText={text => { this.cardNumber(text)}} />

                <TextPresentation style={{marginTop:5}} colorNotification={(this.state.error.cardExpiration==true)?true:undefined} icon={(style.mode=="dark")?require('../assets/calendar_white.png'):require('../assets/calendar_black.png')} backgroundColor={style.backgroundColorContrast}  text={this.state.cardExpiration} editable={!this.state.waiting && this.props.disable!=true} textExplication={strings.cardExpiration} placeholder={this.state.exempleDateCard}  onChangeText={text => { this.cardExpiration(text) }} text={this.state.cardExpiration} />
            
                <TextPresentation style={{marginTop:5}} colorNotification={(this.state.error.cvv==true)?true:undefined} icon={(style.mode=="dark")?require('../assets/cvv_white.png'):require('../assets/cvv_black.png')} backgroundColor={style.backgroundColorContrast}  editable={!this.state.waiting && this.props.disable!=true} text={this.state.cvv} textExplication={strings.cardCvv} placeholder={(this.state.typeCard=="amex")?"1234":"123"} keyboardType={(Platform.OS!="web")?"numeric":null} onChangeText={text => {this.cardCVV(text) }}/>

                <View style={{alignItems:"center",justifyContent:"center", marginTop:10,flexDirection:"row"}}>
                  {this.state.waiting==false && <MavButton text={strings.validate} onlyButton={true} onPress={()=>{ if(this.props.disable!=true) this.sendNewCard() }}/>}
                  {this.state.waiting==true && <ActivityIndicator style={{marginTop:0}} size="large" color={style.color1} />}
                </View>


                {(this.state.waiting==false && this.props.cancelButton!=undefined) && <View style={{alignItems:"center",justifyContent:"center", marginTop:10,flexDirection:"row"}}>
                  <MavButton text={strings.cancel} strokeMode={true} onlyButton={true} onPress={()=>{if(this.props.disable!=true) this.props.cancelButton() }}/>
                </View>}

            </TextPresentation>



          

        }</StyleContext.Consumer>

    );
  }
}




