export default {
 login:"Se connecter",
  myPosition:"Ma position",
  noDriverService:"Désolé, nous n'avons pas de chauffeur dans ce secteur en ce moment",
  maps:"Carte",
  start:"Départ",
  yourStart:"Votre départ",

  
  destination:"Destination",
  yourDestination:"Votre destination",
  updateMandatory:"Merci de mettre à jour l'application pour pouvoir vous en servir",
  signIn:"S'identifier",
  signUp:"S'enregistrer",
  email:"Email",
  password:"Mot de passe",
  forbidPassword:"Mot de passe oublié ?",
  emailReinit:"Un email de réinitialisation vous a été envoyé",
  errorDefault:"Une erreur s'est produite",
  incorrectEmailPassword:"Email ou mot de passe incorrect",
  noEmail:"Merci d'indiquer une adresse mail valide dans le champ Email",
  connexion:"Connexion",
  name:"Prénom",
  lastName:"Nom",
  phone:"Téléphone",
  incorrectPhoneNumber:"Votre numéro de téléphone n'est pas correct",
  incorrectPasswordLength:"Votre mot de passe doit contenir 8 caractères minimum !",
  incorrectPasswordNumber:"Votre mot de passe doit contenir 1 chiffre minimum",
  incorrectPasswordUpperCase:"Votre mot de passe doit contenir des minuscules et des majuscules",
  incorrectEmailFormat:"Merci d'indiquer une adresse mail valide",
  incorrectNameLastName:"Merci d'indiquer votre nom et prénom",
  confirm:"Confirmer",
  cancel:"Annuler",
  cguNext:"En cliquant sur confirmer, vous acceptez les conditions générales d'utilisation",
  myAccount:"Mon compte",
  paymentMode:"Modes de paiement",
  contact:"Contact",
  help:"Aide",
  nextRace:"Futures courses",
  planning:"Planning",
  customers:"Clients",
  priceSettings:"Réglage prix",
  disconnect:"Déconnexion",
  disconnectAnswer:'Êtes-vous sûr de vouloir vous déconnecter ?',
  yes:"Oui",
  no:"Non",
  logout:"Deconnexion",
  changePassword:"Modifier le mot de passe",
  errorDuringUpdateEmail:"Une erreur est survenue pendant la mise à jour de l'adresse mail",
  loading:"Chargement en cours",
  notes:"Notes",
  customersNotePlaceorder:"Entrez des détails concernant votre client (goût musicaux, sujets de discussion ...) pour améliorer la prestation auprès du client",
  moreInfos:"+ d'infos",
  enterLocation:'Entrer la localisation',
  yourPosition:"Votre position",
  pricingGestion:'Gestion des tarifs',
  pricingForfait:'Liste des forfaits',
  pricingClassic:'Liste des zones',
  pricingKmPoint:"À plus ou moins",
  add:"Ajouter",
  place:"Lieu",
  placeSelect:"Choisissez le lieu",
  startPoint:"Départ",
  endPoint:"Point d'arrivée",
  car:"Véhicule",
  priceBase:"Prix de base",
  priceMinute:"Prix par minute",
  priceKm:"Prix par km",
  priceMin:"Prix minimum",
  priceCarName:"Nom du véhicule",
  pricingErrorCar:"Merci de remplir tous les champs pour le véhicule",
  incorrectPricingStart:"Merci de choisir un lieu de départ",
  incorrectPricingEnd:"Merci de choisir un lieu de destination",
  incorrectPricingRadiusStart:"Merci de définir un rayon de départ",
  incorrectPricingRadiusEnd:"Merci de définir un rayon de destination",
  remove:"Supprimer",
  addTarif:"Ajouter un tarif",
  forfait:"Forfait",
  zone:"Zone",
  to:'Vers',
  from:'De',  
  priceEstimation:"Estimation du prix",
  preBook:"Pré-réserver",
  mainName:"Carte",
  noWayWasFound:"Aucun chemin n'a été trouvé",
  clientName:"Nom du client",
  clientLastName:"Prénom du client",
  clientEmail:"Email du client",
  clientPhone:"Téléphone du client",
  dateAndHour:"Date et Heure",
  specialRequests:"Demandes particulières",
  validate:"Valider",
  selectDate:"Sélectionner la date",
  thanksSelectDate:'Merci de remplir la date de départ',
  thanksWeWillReceiveAnConfirmationEmail:"Nous vous remercions pour votre réservation",
  confirmationEmailForClient:'Un mail de confirmation a été envoyé au client',
  confirmationNewValidationRace:"La course a bien été enregistrée",
  duration:"Durée",
  trip:"Trajet",
provision:"Mise à dispo",
paiementExplication:"A bord, vous aurez la possibilité de choisir entre différents moyens de paiements ",

ImUnderstand:"J'ai compris",
contactTxt:"Nous sommes à votre écoute. N'hésitez pas à nous contacter pour toutes demandes ou informations complémentaires",
yourRemark:"Votre remarque",
thankToWriteMessage:"Merci d'écrire votre message",
send:'Envoyer',
contactUs:"Nous contacter",
bookingOf:"Réservation de",
ProvisionFor:"Mise à disposition pour",
calendar:"Calendrier",
conditions:"Conditions",
kmLimit:"Attention, le nombre de kilomètres inclus est limité",
price:"Prix",
description:"Description",
dayList:["Lun","Mar","Mer","Jeu","Ven","Sam","Dim"],
monthList:["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre"],
noMoreRaceValidate:"Toutes les demandes ont été traitées",
km:"Km",
hour:"Heure",
hourMore:"Heure Supp.",
clientInfo:"Infos voyageur",
driverInfo:"Infos chauffeur",
raceInfo:"Infos courses",
durationInMinute:"Durée en minute",
startRace:"Début course",
priceInEuro:"Prix en €",
bill:"Facture",
clientNameError:"Veuillez indiquer le nom et le prénom du client",
driverNameError:"Veuillez indiquer le nom et le prénom du chauffeur",
timeBillError:"Veuillez indiquer un temps valide",
startBillError:"Veuillez indiquer une adresse de départ",
endBillError:"Veuillez indiquer une adresse de destination",
priceBillError:"Veuillez indiquer un prix valide",
billCaution:"Vérifiez bien que les informations soient correctes avant de confirmer la génération de la facture. En effet, conformément à la loi, une fois confirmée, la facture ne peut plus être modifiée ou supprimée",
billEnd:"La facture a bien été générée",
revenue:"Revenus",
toDate:"au",
onPeriod:"Sur la période",
races:"Courses",
sendByMail:"Envoyer par mail",
messageSendYou:"Le mail vous a été envoyé",
yourTripOf:"Votre course du",
hasBeenValidate:"a été validée !",
hasBeenCancelate:"a été annulée ",
payment:"Paiement",
mailAlreadyUse:"L'adresse email est déjà utilisée",
setPrices:"Régler les prix",
facultatif:"Facultatif",



myFavorite:"Mes favoris",
placeName:"Nom du lieu",
thankWriteName:"Merci d'indiquer un nom",
myBooking:"Mes réservations",
shopping:"Shopping Tour",
waitingValidation:"En attente",
validatedRace:"Course validée",
peopleMax1:"Max",
peopleMax2:"Pers.",
thanksSelectDuration:"Merci de choisir la durée",
shoppingEstimation:"Devis Shopping Tour",
welcomeBottomText:"",
momentarilyUnavailable:"L'application est momentanément indisponible",
updateAppNow:"Mettre à jour maintenant",

cguMustAgree:"Vous devez accepter les CGU",

paymentCard:"Carte",
paymentCash:"Espèces",

paymentBankCheck:"Chèque",
paymentCb:"Carte bleue",
paymentPaypal:"Paypal",
paymentHollidayCheck:"Chèque vacances",
paymentTransfer:"Virement",
others:"Autres",
reservationTimeBefore1:"Merci de réserver",
reservationTimeBefore2:"avant !",
idLangue:"fr",










inactiveAccount:"Compte inactif",
home:"Accueil", 
notes:"Notes",
account:"Compte",
errorAccountDisabled:"Votre compte est désactivé",
online:'En ligne',
offline:'Hors ligne',
errorAppliPartner:"Attention ! Vous êtes sur l'application chauffeur",
offlineAnswer:'Etes-vous sûr de vouloir passer hors ligne ?',
noLocalisation:"Nous n'arrivons pas à vous localiser :(",
lockClient:"Bloquer le client",
lockPartner:"Bloquer le partenaire",
lockDriver:"Bloquer le chauffeur",
unlockClient:"Débloquer le client",
unlockPartner:"Débloquer le partenaire",
unlockDriver:"Débloquer le chauffeur",
accountHasBeenEnabled:"Le compte a été activé",
accountHasBeenDisabled:"Le compte a été désactivé",
partners:"Partenaires",
admins:"Admins",
documents:"Documents",
partnerDocuments:["Carte VTC","Pièce d'identité","Permis","Kbis","Carte Atout France","Assurance RCE","Quitus","Carte Grise","Carte Verte","Photo Intérieur","Photo Extérieur","Assurance RCC"],
picture:"Photo",


accountName:"Titulaire",
city:"Ville",
bic:"Bic",
iban:"Iban",
type:"Type",
bank:"Banque",
brand:"Marque",
immatriculation:"Immatriculation",
immatriculationExample:"(Ex : LD-001-LD)",
model:"Modèle",

newOrder:"Nouvelle demande pour le ",
newRace:'Nouvelle course',
noCar:"Pas de vehicule",
modify:"Modifier",
purchaseOrder:"Bon de commande",
settings:"Paramètres",
bankName:"Nom de votre banque",
bankCity:"Ville de votre banque",
bankAccountProprietary:"Titulaire du compte",
bankAccountType:"Type de compte (perso ou pro)",
bic:"Bic/Swift",
thanksBic:"Merci d'indiquer un BIC valide",
thanksIban:"Merci d'indiquer un IBAN valide",
thanksBankAccountType:"Merci d'indiquer le type de compte et le titulaire",
thanksBankName:"Merci d'indiquer votre banque et sa ville",
validationWaiting:"Validation en attente",
validationDocMissing:"Document absent",
validationOK:"Document validé",
oldPassword:"Ancien mot de passe",
newPassword:"Nouveau mot de passe",
incorrectOldPassword:"L'ancien mot de passe est incorrect",
newPasswordOK:"Le mot de passe a bien été modifié",
yourPasswordObligatory:"Pour supprimer votre compte, merci d'indiquer votre mot de passe",
accountDeleted:"Votre compte a été supprimé",
removeAnswer:'Êtes-vous sûr de vouloir supprimer votre compte ?',
book:"Commander",
clickOnScreenToAccept:"Appuyer n'importe où sur l'écran pour accepter",
decline:"Refuser",
accept:"Accepter",
contactClient:"Contacter le client",
navigation:"Navigation",
signalArrival:"Signaler votre arrivée",
waitingClient:"En attente de validation par le client",
signalEndRace:"Terminer la course",
noOffineOnRace:"Durant une course, vous ne pouvez pas pas être hors ligne",
findDriver:"Recherche d'un chauffeur",
driverApproaching:"Chauffeur en approche",
driverIsPresent:"Chauffeur arrivé",
raceInProgress:"Course en cours",
raceFinished:"Course terminée",
yourNote:"Votre note",
arrivalAt:"Arrivée prévue à",
noDriver:"Désolé mais aucun chauffeur n'est disponible dans votre secteur en ce moment. Merci de réessayer ultérieurement",
provider:"Via",
clientToProvider:"Transformer en apporteur d'affaires",
providerToClient:"Transformer en client",
clientToProviderOK:"Mode apporteur d'affaires activé",
providerToClientOK:"Mode apporteur d'affaires désactivé",

myOldRace:"Mes trajets",
downloadBill:"Télécharger la facture",
cardNumber:"N° carte",
cardCvv:"Cryptogramme",
cardExpiration:"Date d'exp.",
paymentCode:"Code promo",
promoCodeExplication:"Le code promo sera automatiquement utilisé lors de votre prochaine course",
yourCompany:"Votre entreprise",
yourBank:"Votre banque",
companyName:"Nom de la société",
companyCapital:"Capital social",
companyAdress:"Adresse de la société",
rcs:"Numéro de RCS",
tvaNumber:"Numéro de TVA",
subjectToTVA:"Soumis à TVA",
yourAssurance:"Votre assurance",
assurance:"Assurance",
assuranceName:"Nom de l'assurance",
assuranceNumber:"Numéro de contrat",
siret:"Siret",
addCardOk:"La carte a bien été ajoutée",
addCardError:"Une erreur s'est produite", 
removeCardOk:"La carte a bien été supprimée",
removeCardError:"Une erreur s'est produite", 
removeCard:"Etes-vous sur de vouloir supprimer la carte ?",
errorCard:"Le paiement a été refusé",
errorCardAccount:"Merci d'indiquer une carte valide dans Paiement",
invalidCard:"Carte invalide",
childSeats:"J'ai besoin de sièges auto",
explain:"Expliquez ...",
preFill:"Pré-remplir",
addClientOK:"Le client a bien été créé !",
removeClient:"Supprimer le client",
removeClientOK:"Le client a bien été supprimé !",
oneWay:"Aller simple",
roundTrip:"Aller / retour",
dateAndHourGo:"Date et Heure aller",
dateAndHourReturn:"Date et Heure retour",
go:"Aller",
return:"Retour", 
onePassager:"Passager",
morePassager:"Passagers",
maxPassagers:"passagers à bord maximum",
selectFrom:"Selectionner le point de départ",
selectTo:"Selectionner la destination",
setMajo:"Régler la majoration",
majoNight:"Majoration nuit",
majoSaturday:"Majoration samedi",
majoSunday:"Majoration dimanche",
majoFerie:"Majoration fériée",
majoPassagers:"Majoration par passager",
passagersMore:"Au delà de",
whose:"dont",
majoAllPassagers:"Majoration des passagers",
openGPS:"Ouvrir GPS",
preferGmapWaze:"Préférez-vous Google Maps ou Waze ?",
SMSImHere:"Prévenir le client que je suis arrivé",
SMSImComming:"Prévenir le client que j'arrive",
raceCanceled:"Prévenir le client que la course est annulé",
cancelRace:"Annuler la course", 
sureCancelRace:"Êtes-vous sûr d'annuler la course ?",
raceHasBeenCanceled:"Course annulée",
howContactCustomer:"Comment voulez-vous prévenir le client ?",
withSMS:"Par SMS",
withMail:"Par mail",
notPrevent:"Ne pas prévenir",
callCustomer:"Téléphoner au client",
moreInfosAboutCustomer:"En savoir plus sur le client",
tools:"Outils",
viewBDC:"Afficher le bon de commande",
generateBill:"Générer la facture",
raceHasBeenValidate:"Validation de la course",
raceCanceledConfirm:"Annulation de la course",
cguAgree:"J'accepte les CGU. Cliquez ici pour les voir",
callPartner:"Téléphoner au partenaire",
sendSMS:"Envoyer un SMS",
sendMail:"Envoyer un mail",
infoPersonal:"Informations personnelles",
options:"Options",
partnerModeEnabled:"Passer en partenaire",
partnerModeDisabled:"Désactiver le mode partenaire",
viewRace:"Voir les courses",
lock:"Bloquer",
unlock:"Débloquer",
export:"Exporter",
whatExport:"Que voulez-vous exporter ?",
all:"Tous",
providerAffair:"Apporteur d'affaires",
copiedClipboard:"Copié dans le presse papier !",
next:"Suivant",
week:"Semaine",
month:"Mois",
driverHasAnswered:"Un autre chauffeur a déjà répondu !",  
arrival:"Arrivée",
comming:"Futurs",
old:"Anciens", 
promoCode:"Code promo",
promoCodeName:"Nom du code",
promoCodePercent:"Réduction de",
promoCodeInvalid:"Code promo invalide",
codeUsedTimes:"utilisation(s)",
promoCodeViewCustomers:"Afficher les utilisateurs",
promoCodeHideCustomers:"Cacher les utilisateurs",
defineStartDate:"Régler une date de début précise",
defineEndDate:"Régler une fin de validité précise",
majoration:"Majoration",
sendToFriend:"Envoyer la course par SMS",
myDriver:"Mon chauffeur", 
noMatter:"Peu importe",
desiredDriver:"Chauffeur désiré",
attributedPartner:"Chauffeur attribué",
you:"Vous",
atMe:"À moi",
atThem:"A lui",
attributeRaceQuestion:"A qui voulez-vous attribuer la course",
or:"ou", 
giveToPool:"Donner au pool",
perso:"Perso",
appli:"Appli",
billAtMyName:"Au nom de ma société",
billAtThisName:"Au nom de sa société",
doubleBill:"Double facturation",
companyNameComplete:"Nom complet de la société",
companyCity:"Ville de la société",
companySiret:"Numéro de SIRET",
appliVersion:"Version de l'application",
appliVersionOK:"Votre application est à jour",
majoFiftyOneHundred:"Majoration de 50 à 100km",
majoOneHundredTwoHundred:"Majoration de 150 à 200km",
majoTwoHundredThreeHundred:"Majoration de 250 à 300km",
majoMoreThreeHundred:"Majoration > 300km",
majoEvening:"Majoration soirée",
majoMorning:"Majoration matin",
promoExplication:"- Donner un nom, un pourcentage de réduction ou une réduction fixe à votre code promo\n- Vous pouvez définir une date de début et de fin de validité pour votre code promo\n- Le code promo n'est valable qu'une seule fois par utilisateur (sauf si vous décidez du contraire)\n-Vous pouvez définir un nombre maximum d'utilisation\n-Vous pouvez également affecter ce code promo à un point d’intérêt particulier ou pour la mise à disposition",
nextMonth:"Mois suivant",
previousMonth:"Mois précédent",
nextHour:"Ajouter une heure",
nextMinute:"Ajouter une minute",
previousHour:"Baisser d'une heure",
previousMinute:"Baisser d'une minute",
notDefined:"Non défini",
addCard:"Ajouter une carte",
cvvExplication:"Il s'agit du code de vérification à l'arrière de votre carte",
cardErrorNumero:"Numéro de carte invalide",
cardErrorExpiration:"Date d'expiration invalide",
cardErrorCVV:"Code de vérification invalide",
yourCredit:"Votre crédit",
yourHaveNotCredit:"Vous n'avez pas de crédit",
creditYouHave:"Votre crédit est de",
yourCards:"Vos cartes",
youHaveNotCard:"Vous n'avez pas de carte enregistrée",
buyRecharge:"Acheter une recharge",
useRecharge:"Utiliser une recharge",
whyBuyRecharge:"Envie de faire plaisir à quelqu'un et l'aider à se déplacer plus facilement ? Achetez lui une recharge prépayé qui pourra créditer sur son compte !",
montant:"Montant",
paiementOnBoard:"Paiement à bord",
errorCardReturn:"Le paiement est bien passé pour l'aller mais pas pour le retour",
hourDestinationSupHourStart:"L'heure de retour doit être supérieur à l'heure de départ",
learnMore:"En savoir plus",
passwordMoreInfo:"Le mot de passe doit faire 8 caractères au minimun, avoir une majuscule, une minuscule et un chiffre",
alreadyPaid:"Déjà payé",
thankWaiting:"Veuillez patientez",
errorPaymentFacture:"Erreur de paiement. Facturez",
userAlreadyPaidRefundQuetionPart1:"L'utilisateur avait payé la course",
userAlreadyPaidRefundQuetionPart2:"Voulez-vous le rembourser sur son credit ?",
userPaidLower:"Le client a payé moins que la somme facturée",
paidOnBoard:"Facturez-vous la somme supplémentaire à bord ?",
userPaidHighter:"Le client a payé plus que la somme facturée",
waitingPaymentValidation:"Validation du paiement en cours",
waitingCancelValidation:"Annulation de la course en cours",
withCredit:"Avec mon crédit",
insufficientCredit:"Votre crédit est insuffisant",
rechargeCreditifinsufficient:"Voulez-vous recharger maintenant ou ajouter une carte bancaire ?",
clickToViewParameter:"Cliquez pour afficher les paramètres",
clickToHideParameter:"Cliquez pour masquer les paramètres",
viewBy:"Afficher par",
order:"Ordre",
newToOld:"Récentes vers plus anciennes",
oldToNew:"Anciennes vers plus récentes",
driver:"Chauffeur",
racesValidate:"Courses validées",
racesBilled:"Courses facturées",
racesValBill:"Courses validées et facturées",
commission:"Commission",
thankValidCommission:"Merci d'indiquer une commission valide",
billedToClient:"CA Appli",
billedToDriver:"Dû par l'appli",
customer:"Client",
whatBillyouSee:"Quelle facture voulez-vous voir ?",
feeDelay:"Pénalité de retard",
applyFeeDelay:"Appliquer une pénalité de retard",
delayMinute:"Minutes de retard",
delayPriceMinute:"Prix par minute de retard",
priceAfterDelay:"Prix après pénalité",
ok:"ok",
close:"Fermer",
copy:"Copier",
yourRechargeCode:"Voici votre code de recharge",
rechargeNowOrGive:"Vous pouvez recharger votre compte avec dès maintenant ou l'offrir à quelqu'un",
code:"Code",
howUseCode:"On vous a offert une recharge ? Saississez simplement le code reçu ici et votre compte sera automatiquement crédité !",
noCode:"Merci d'indiquer un code",
codeAlreadyUse:"Le code a déjà été utilisé",
codeNotFound:"Le code n'a pas été trouvé",
codeNotFoundExplication:"Attention aux majuscules et aux minucules ainsi qu'aux espaces non désirés",
yourAccountHasBeenCreditate:"Votre compte a été crédité de",
thanks:"Merci !",
noElement:"Aucun élement à afficher",
finishValidate:"Vous avez fini de valider les courses",
finishValidateRewind:"Voulez-vous rechercher des courses à nouveau ?",
blacklistedDriver:"Chauffeurs blacklistés",
blacklistedClient:"Clients blacklistés",
noDriverBlacklisted:"Aucun chauffeur blacklisté",
noClientBlacklisted:"Aucun client blacklisté",
noFavoriteDriver:"Pas de chauffeur préféré",
zoneWork:"Zone de travail",
zoneWorkExplication:"Définissez les zones géographiques sur lequels le chauffeur travaille. C'est le point de départ du client qui est pris en compte (y compris pour les mises à disposition). Si vous ne cochez pas au moins une zone, le chauffeur ne sera compatible avec aucune course. Les zones qui apparaissent ici proviennent des Réglages > Gestion des tarifs. Vous pouvez donc en rajouter en allant dans les réglages et en ajoutant des zones",
permananteReduction:"Réduction permanente",
invalidPermanenteReduction:"Réduction permanente invalide",
deferredPayment:"Paiements différés",
billGenerateWithDeferredPayment:"La facture sera édité lors de la génération de la facture globale",
racesDeferredInvoice:"Courses avec paiement différé",
billNow:"Facturez maintenant",
deferredPaymentRace:"Paiement différé",
deferredPaymentEnabled:"Vous bénéficiez du paiement différé. Vous ne payez pas au moment de la course mais selon les conditions que vous avons évoqué",
carExplicationSimple:"Vous pouvez définir les informations sur le vehicule du chauffeur",
carExplicationComplex:"Définissez les types de véhicules pour lequels le chauffeur peut effectuer la course. Si vous ne cochez pas au moins un véhicule, le chauffeur ne sera compatible avec aucune course. Les véhicules qui apparaissent ici proviennent des noms des véhicules que vous avez défini dans Réglages > Gestion des tarifs. Vous pouvez donc en rajouter en allant dans les réglages et en modifiant ou en ajoutant des zones",
chassis:"N° chassis",
enterprise:"Entreprise",
yourDriverApproch:"Votre chauffeur est en approche",
now:"Maintenant",
moreLate:"Plus tard",
manualGestion:"Gestion manuelle",
startRaceNow:"Démarrer la course maintenant",
cancelRaceNow:"Annuler la course en direct",
navigateTo:"Naviguez vers",
waitingCustomer:"En attente du client",
yourAreArrivateAt:"Vous êtes arrivé à",
yourDriverAreArrivateAt:"Présent depuis",
customerOnBoard:"Voyageur à bord",
quitRaceImmediate:"Êtes-vous sûr de vouloir quitter le suivi de la course ?",
quitRaceImmediateNext:"Vous pourrez supprimer cette course dans le planning, la modifier ou la reprendre plus tard",
clientNotActualyPaid:"Le client n'a pas encore payé. Encaissez-le avant de continuer",
continueQuestion:"Voulez-vous continuer ?",
clientHasDelay:"Retard", 
allRace:"Toutes les courses",
raceOf:"Courses de",
withPhone:"Par téléphone",
myBill:"Mes factures",
theBlog:"Le blog",
accessBlog:"Accédez au blog de Mon Appli VTC",
myOffer:"Mon offre",
nothingBill:"Aucune facture pour le moment",
changePicture:"Changer la photo de profil",
selectPicture:"Selectionner la photo",
camera:"Appareil photo",
library:"Photothèque",
photoOK:"La photo a bien été mise à jour",

majoDynamic:"Majoration dynamique",
majoChildSeats:"Majoration siège auto",
majoCompleteNight:"Majoration soirée complète",
majoExplication:"- La majoration kilométrique s’applique pour les prix zones en fonction du nombre de kilomètres de la course du client. Elle est indépendante des autres majorations.\n- Pour les majorations weekend, fériés, nuits, nombre de passagers …, c’est la majoration la plus élevé qui est utilisée si plusieurs majorations peuvent s’appliquer.\n- Régler seulement les majorations que vous souhaitez utiliser",
majoAfternoon:"Majoration après-midi",
yourAreInProvisionMode:"Vous êtes en mode de mise à disposition",
sendBy:"Envoyé par", 
at:"À",
call:"Téléphoner",
howContact:"Comment souhaitez-vous prendre contact ?",
readyToContact:"Vous pouvez désormais échanger entre vous. Ne communiquez pas de données sensibles. Un administrateur peut avoir accès à la conversation",
raceInWaiting:"Vous avez des courses en attente de validation",
majoAvance:"frais de réservation à l'avance",
kilometerMax:"Kilomètres maximum",
kmMaxHour:"Km max par heure",
iconChoice:"Choix de l'icone",
adress:"Adresse", 
goReturnIdenticPrice:"Prix valable dans les 2 sens",
goReturnPriceMoreInfos:"Si cette case n'est pas coché, le prix ne s'appliquera que dans un sens. Vous pourrez donc créer un autre forfait pour le trajet retour avec un prix différent de celui de l'aller. En cochant cette case, le tarif s'appliquera dans le sens depart vers destination ET dans le sens destination vers départ",
departureZone:"Zone de départ",
destinationZone:"Zone de destination",
circulaireZone:"Zone circulaire",
drawedZone:"Zone dessinée",
drawZone:"Cliquez ici pour dessiner la zone",
removeLastPoint:"Supprimer le dernier point",

appInPause:"L'application est ponctuellement en pause",
appInPauseMoreLater:"L'application est ponctuellement en pause. Nous vous invitons à revenir plus tard",
internetProblemConnexion:"Problème de connexion internet",
retryNowQuestion:"Réessayer maintenant ?",
settings:"Réglages",
poi:"Points d'intérêts",
delayReservationMinutes:"Délai de réservation",
enabledBookNow:"Mode réservation instantanée",
clickMoreInfos:"Cliquez ici pour plus d'infos",
enabledBookNowMoreInfo:"En activant ce mode, le client pourra choisir entre le mode 'dès que possible' ou 'réservation à l'avance' lors de la commande de sa course. A noter que si vous activez cette option, cela n'influe pas sur le délai minimal pour la réservation d'une course. Vous pouvez proposer, par exemple, une réservation immediate et une réservation à partir de 2h à l'avance",
settingsBook:"Réservations",
settingsOther:"Divers",
settingsPricing:"Tarifs et réductions",
ads:"Publicités",
durationMinimumMinutes:"Durée minimal",
adsName:"Nom de la pub", 
link:"Lien",
defineImageAds:"Choisir l'image publicitaire", 
forceManualDispatch:"Forcer le mode manuel", 
forceManualDispatchExplication:"Vous possédez le mode flotte. Avec ce mode, le dispatch des courses est automatique. En activant ce bouton, vous repasser en mode de dispatch manuel", 
validate3DSecure:"Vérification 3D Secure",
validatePaiementToContinue:"Validez le paiement pour continuer",
errorCardNoRace:"La dernière course n'a donc pas été prise en compte",
noDriverAvaiable:"Aucun chauffeur disponible",
shouldyouTakeRace:"Souhaitez-vous faire la course à",
toGo:"A venir",
carAuthorised:"Véhicules autorisées", 
carsDriver:"Véhicules du chauffeur",
noCarDefined:"Aucun véhicule défini",
carType:"Type de véhicule",
withNotification:"Par notification",
evtc:"N° EVTC",
takingTraffic:"Facturer plus en cas de bouchon",
takingTrafficMoreInfo:"Ce paramètre ne s'applique que pour une course instantanée. Par exemple, si à cause des bouchons, le trajet est plus long de 10 minutes, alors le client sera facturé pour les 10 minutes supplémentaires. Attention, cela ce cumule avec les majorations", 
majoTakingTraffic:"Majoration bouchon",
contactDispatcher:"Voulez-vous contacter la centrale ?",
acceptChildSeats:"Proposer un siège auto", 
acceptBoosterSeats:"Proposer un réhausseur", 
boosterSeats:"J'ai besoin de réhausseur",
majoBoosterSeats:"Majoration réhausseur",
thanksDrawZone:"Merci de dessiner la zone",
longPressToAddPoint:"Faites un appui long pour ajouter un point. 3 points minimum sont nécessaires",
hideReasonMajoClient:"Masquer la majoration horaire",
hideReasonMajoClientExplication:"En activant cette option, le client verra inscrit 'Majoration horaire' au lieu de d'avoir précisement la tranche horaire", 
majoHourFrequency:"Majoration horaire", 
addBaggageItem:"Ajouter une option bagage", 
priceProvisionExplication:"Ajoutez des types de véhicules (Eco, Berline, Van ...) et définissez les prix pour la mise à disposition", 
oneBaggageMax:"Bagage maximum",
baggageMax:"Bagages maximum",
priceProvisionAddExplication:"Ajoutez les informations sur ce type de véhicule et indiquez le prix le tarif horaire. Par exemple, si une mise à disposition coute 60€ pour 1h et 100€ pour 2h, indiquez 60€ pour heure n°1 et 40€ pour heure n°2 ...",
priceClassicExplication:"Ajoutez des types de véhicules (Eco, Berline, Van ...) et définissez les prix", 
luggage:"Bagage",
luggages:"Bagages",
autoGestion:"Gestion automatique",
autoGestionNotAvaiable:"Gestion automatique non disponible",
messageSend:"Envoyer un message", 
withWhatsApp:"Par WhatsApp",
clientNotAppli:"Course créée manuellement",

removeRace5minutes:"Supprimer une course en attente 5 minutes après son début",
removeRace5minutesMoreInfo:"Ce paramètre permet de supprimer automatiquement une course en envoyant un message de type 'Aucun chauffeur disponible' si personne n'a répondu 5 minutes après l'heure prévu de la course. Par exemple, il est 13h et un client commande une course pour 14h. Si à 14h05, personne n'a répondu, cette course sera supprimé",
removeFavoriteDriver30:"Laisser 30 minutes au chauffeur favori pour répondre",
removeFavoriteDriver30MoreInfo:"Vous avez activé l'option permettant au client de choisir son chauffeur. Tant que le chauffeur n'a pas répondu, les autres chauffeurs ne peuvent pas voir la course. Pour éviter une absence de réponse, vous pouvez activer cette option. Ainsi, si le chauffeur favori n'a pas répondu dans les 30 minutes, la course devient accessible aux autres chauffeurs. Ce paramètre désactive aussi le choix du chauffeur pour les courses instantanée si vous avez activé la suppression auto de la course au bout de 5 minutes",
pricingPrecise:"Prix précis",
pricingPreciseMoreInfo:"En activant ce paramètre, le client aura un prix plus précis qui s'affichera. Par exemple, au lieu d'avoir 50€, il aura 49.87€",  
viewMajorationDynamicDriver:"Afficher des zones dynamique pour les chauffeurs",
viewMajorationDynamicDriverMoreInfo:"En activant ce paramètre, les chauffeurs pourront voir quel est le coefficient mulitiplicateur dynamique dans les différentes zones. Vous pouvez régler ce coefficient dans 'Gestion des tarifs' > 'Régler la majoration globale' ou précisement pour chaque zone / forfait dans 'Gestion des tarifs' > [Votre zone / forfait] > Régler la majoration",
setGlobalMajoration:"Régler la majoration globale",

enableRealPosition:"Afficher l’adresse au lieu de 'Ma position'",
enableRealPositionMoreInfo:"En activant ce paramètre, le client verra marquer sa position concrète (par exemple : 45 rue de la liberté) au lieu du texte 'Ma position'. L’activation de ce paramètre est déconseillé. En effet, la précision du GPS étant aléatoire, il est possible qu’il y est un petit décalage (par exemple 50 rue de la liberté au lieu de 45). Ce décalage peut induire une confusion chez le client alors qu’en vrai cela ne change rien pour le chauffeur). Dans tout les cas, les chauffeurs resteront sur le texte 'Ma position'",
selectFromPoint:"Adresse de départ",
destinationOrDuration:"Destination ou mise à dispo",
chooseDestinationOrDuration:"Choisir la destination ou la durée",
chooseStart:"Choisir le départ",
chooseDestination:"Choisir la destination",
chooseDate:"Choisir la date",
asap:"Dès que possible",
subTotal:"Sous total",
total:"Total",
majoKilometer:"Majoration kilométrique",
partialHour:"Heure partielle",
onlyAdminViewCalcPrice:"Important : Seul un administrateur peut voir cet écran. Il vous permet de vous aider à comprendre comment le prix de la course a été calculé. Vous pouvez rendre visible cet écran pour tous, en activant l'option 'Afficher l'explication du calcul du prix' dans les réglages",
majoLast:"frais de réservation de dernières minutes",
majoAvancePercent:"Majoration à l'avance",
majoLastPercent:"Majoration instantanée",
viewCalcPrice:"Afficher le détail du calcul",
viewCalcPriceMoreInfo:"Cette option active l'affichage du point 'i' à côté du prix et permet d'expliquer aux clients comment le prix a été calculé",
hourStart:"Heure de départ",
contactCancelRace:"N'hésitez pas à nous donner la raison de l'annulation de la course, nous reviendrons vers vous", 
userRequestAnnulation:"Le client a demandé à annuler la course",


disableCancelButton:"Masquer le bouton d'annulation", 
disabledOldRace:"Masquer les anciennes courses",
disabledOldRaceMoreInfo:"Cette option masque aux clients les anciennes courses qu'ils ont fait sur la plateforme. Dans le cas contraire, ils pourront les consulter ainsi que télécharger les factures",
repertoire:"Répertoire",
search:"Rechercher", 
filter:"Filtrer", 
contacts:"contacts",
providersAffair:"Apporteurs d'affaires", 
partner:"Chauffeur",
compteType:"Type de compte", 
languageOkay:"Langues acceptées",
languageOkayMoreInfo:"Définissez les langues que le chauffeur parle ou pas. L'application se basera sur la langue utilisée sur le téléphone pour proposer la course. Si vous ne cochez pas au moins une langue, le chauffeur ne sera compatible avec aucune course.", 
languageAvaiable:[{id:"fr",text:"Français"},{id:"en", text:"Anglais"},{id:"it", text:"Italien"},{id:"es", text:"Espagnol"},{id:"ru", text:"Russe"}  ], 
privilegeDriver:"Privilège chauffeur", 
privilegeDriverMoreInfo:"Définissez le niveau de privilège du chauffeur. Le niveau 'Basique' permet au chauffeur d'accepter et de réaliser les courses. Le niveau 'Standard' ajoute la possiblité d'annuler les courses. Le niveau 'Avancé' offre la possibilité au chauffeur de pouvoir ajouter des courses manuellement. Le niveau 'Maximal' permet au chauffeur de court-circuiter la plateforme pour ses courses qui ne sont pas payable par carte. Si un autre chauffeur fait ce type de course, l'autre chauffeur facturera directement le chauffeur sans prendre en compte les commissions de la plateforme", 
privilegeDriverAvaiable:[{id:"basic",text:"Basique"},{id:"standard", text:"Standard"},{id:"avance", text:"Avancé"},{id:"max", text:"Maximal"} ], 
viewPhone:"Affichage du téléphone du client",
viewPhoneAvaiable:[{id:"never",text:"Jamais"},{id:"onlyManualClient", text:"Uniquement si c’est une course manuelle"},{id:"always", text:"Toujours"} ], 
privilegeProvider:"Privilège apporteur d'affaire", 
privilegeProviderMoreInfo:"Définissez le niveau de privilège de l'apporteur d'affaire. le niveau 'Basique' permet à l'apporteur d'affaires de commander des courses pour ses clients. Le niveau 'Standard' permet à l'apporteur d'affaires de payer à la place du client. Le niveau 'Avancé' permet en plus de modifier le prix de la course", 
privilegeProviderAvaiable:[{id:"basic",text:"Basique"},{id:"standard", text:"Standard"},{id:"avance", text:"Avancé"} ],
settingsDrivers:"Chauffeurs",
addCommissionFixe:"Ajouter une commission fixe",
addCommissionFixeMoreInfo:"En activant cette option pour pourrez ajouter une commission fixe en plus de la commission que vous prenez au chauffeur. Par exemple, pour une course à 50€, une commission fixe de 2€ et une commission sur le chauffeur de 15%, le chauffeur touchera : (prix de la course - commission fixe) * (100% - commission chauffeur) = (50 - 2)*(100% - 15%)= 40.8€",
addCommissionVariable:"Ajouter une commission variable",
addCommissionVariableMoreInfo:"En activant cette option, vous pourrez ajouter une commission variable en plus de la commission que vous prenez au chauffeur. Par exemple, pour une course à 50€, une commission variable de 2% et une commission sur le chauffeur de 15%, le chauffeur touchera : prix de la course * (100% - commission variable) * (100% - commission chauffeur) = 50*(100%-2%)*(100% - 15%)= 41.65€. En combinant, la commission fixe et la commission variable, vous pouvez ainsi exclure les frais bancaires de Stripe par exemple et garder un taux de marge identique par course",
addCommissionFixeAmount:"Montant de la commission fixe",
addCommissionVariableAmount:"Montant de la commission variable",
priceNetForDriver:"Afficher le prix net chauffeur",
priceNetForDriverMoreInfo:"En activant cette option, le chauffeur verra le prix de la course sans les diverses commissions. Il s'agira du prix TTC pour les chauffeurs soumis à TVA",
priceDriver:"Prix chauffeur",
priceClient:"Prix client",
notpaid:"Non payé",
priceAfterPlafeform:"Prix après la commission de la plateforme",
carRequestClient:"Demandée par le client", 
hidePriceRaceForDriver:"Masquer le prix de la course",
hidePriceRaceForDriverMoreInfo:"En activant cette option, les chauffeurs en mode basique ou standard ne verront pas directement le prix que le client a payé sauf si celui-ci a choisi de faire du paiement à bord",
view:"Vue",
normal:"Normal",
twentytoGo:"20 courses à venir", 
selectViewMode:"Selectionner le mode d'affichage", 
notFacturateCustomer:"Ne pas facturer le client",
billWaiting:"Factures en attente", 
generateBills:"Générer les factures",
acceptWheelchair:"Proposer un fauteuil roulant", 
wheelchair:"J'ai besoin d'un fauteuil roulant",
optionsOkay:"Options acceptées", 
OptionsOkayMoreInfo:"Définissez les options que le chauffeur accepte à bord du véhicule. Si le client demande l'option et que le chauffeur ne l'a pas, il ne recevra pas la course",
BillOptionForMaxDriver:[{id:"client",text:"Facturer la course au client"},{id:"onlyDriver",text:"Me facturer la course"}, {id:"driverAndClient",text:"Me facturer la course\net faire une facture pour le client"} ],
infoBill:"Infos facturation", 
forceDriverPrice:"Forcer le prix chauffeur", 
forceDriverPriceInfo:"En définissant un prix, vous allez forcer le prix que recevra le chauffeur s'il est en mode 'Double facturation' ou 'Facture au nom de sa société'. Ce tarif remplace toutes les autres commissions qui existent. Ce prix n'est pas pris en compte si le chauffeur est en mode 'Facture au nom de ma société",
amount:"Montant", 
amountDriver:"Montant chauffeur",
moneySelected:"Monnaie selectionnée",
globalMajoration:"Majoration globale",
extraEUMode:"Mode extra Union Européenne",
extraEUModeMoreInfo:"En activant cette option, vous allez pouvoir choisir la monnaie (comme le dollar, le Franc-Suisse ...). Cette option est en bêta",
settingsView:"Affichage",
viewRealDriver:"Afficher la position des chauffeurs sur la carte",
viewRealDriverForDriver:"Afficher les infos des chauffeurs aux administrateurs",
viewRealDriverForDriverMoreInfo:"En activant cette options, les administrateurs pourront cliquer sur les véhicules de la carte, savoir de quels chauffeurs il s'agit. Les chauffeurs ayant un privilège 'maximal' pourront aussi voir les autres chauffeurs",
lastMAJ:"Dernière mise à jour", 
forceTVAPercent:"Forcer le pourcentage de TVA",
tvaRace:"Taux de TVA course",
tvaDispo:"Taux de TVA dispo",
conversationGestion:"Gestion des devises", 
conversationGestionMoreInfo:"Vous pouvez activer le mode extra EU pour gérer des prix dans d'autres devises. Vous pouvez aussi vérifier le fuseau horaire de la commande et afficher le prix de la course dans différentes devices ($, £, ¥ ...) pour aider vos clients. En activant cette option, différentes devices apparaitrons en dessous du prix de la course. La conversation est donnée à titre indicatif, le prix faisant foi est le prix de la course. C'est exchangeratesapi.io qui fourni les données de manière non contractuelle.", 
viewAccurency:"Afficher les conversions de devices",
viewAccurencyMoreInfo:"Activez cette option pour que le client puisse avoir une idée du montant de la course dans une devise autre que celle de la course", 
selectDeviseToView:"Selectionner les devises que vous voulez afficher", 
equivalentTo:"Equivalent à environ", 
addToPOI:"Affecter à un point d’intérêt", 
in:"en",
nothingToView:"Rien à afficher",
validateAfterPayment:"Valider après paiement", 
copyLink:"Copier le lien", 
userAlreadyPaidRefundQuetionWithProvider:"Le client n'ayant pas de compte (il a payé grâce à un lien), il n'est pas possible de le rembourser directement via l'application. Si vous souhaitez le rembourser vous pouvez le faire par Stripe ou par virement",
otherInfoBill:"Informations complémentaires à faire apparaitre sur la facture", 
commissionDriverExplication:"Cette commission s'applique uniquement à ce chauffeur et se cumule avec les commissions défini dans 'Réglages'. Dans le mode 'Au nom de sa société', cette commission ne s'appliquera uniquement vous avez activé le paiement par carte dans l'application et que le client a choisi de payer par carte",
defineMaxUsePromoCode:"Définir un nombre maximal d'utilisateurs", 
defineNumber:"Saississez le nombre",
sorryDriverToLate:"Désolé, mais un autre chauffeur a répondu juste avant vous", 
majorationHighAffluence:"Majoration de forte affluence", 
majorationHighAffluenceMoreInfo:"Vous pouvez régler des majorations qui s'appliqueront à certains crénaux précis (une journée de grève, un concert ...)", 
startDate:"Date de début",
endDate:"Date de fin",
thanksAllItems:"Merci de remplir correctement tous les champs",
majoHighAffluence:"Majoration haute affulence",
acceptAnimal:"Proposer d'accepter les animaux", 
animal:"J'aurais mon animal durant la course",
majoWheelchair:"Majoration fauteuil roulant", 
majoAnimal:"Majoration animal", 
vehicule:"Véhicule", 
helpGestion:"Gérer la rubrique aide", 
titre:"Titre", 
question:"Question", 
answer:"Réponse", 
hideMajoration:"Masquer les majorations",
hideMajorationMoreInfo:"En activant cette option, l'application n’expliquera pas pourquoi le prix a été majoré",  
admin:"Administrateur", 
adminAndDriver:"Admin et chauffeur",
adminNotRaceForHim:"L'admin ne peut pas s'auto attribuer une course", 
adminNotRaceForHimMoreInfo:"Par défaut, lorsqu'un admin créé manuellement une course, celle-ci lui est automatiquement attribué. En cochant cette option, cela signifie que l'admin ne réalise pas de course par lui même et donc que la course ne lui sera pas attribué", 
disabledPromoCode:"Désactiver les codes promos", 
raceAlreadyPaid:"Considérer la course comme payé", 
billTo:"Facturer à",
customerReviews:"Avis clients",
activateGoogleMyBusiness:"Activer le lien Google My business",
activateGoogleMyBusinessMoreInfo:"Cette option permet aux administrateurs d'envoyer manuellement un lien pour inciter les clients à laisser un avis sur votre page Google My Business. Une icone apparaitra pour chaque course terminé. Ainsi, si la course c'est bien passé, vous pourrez envoyer en un clic un message personnalisée au client", 
gestionGoogleMyBusinessLink:"Gérer le lien My Business", 
gestionGoogleMyBusinessLinkMoreInfo:"Ecrivez le texte qui pré-remplira le message.\nVous pourrez toujours modifier ce message.\n\nInscrivez '%NAME%' pour insérer le prénom du client, %LASTNAME% pour le nom de famille, %DATE% pour la date de la course, %START% pour l'adresse de départ, %END% pour la destination et %DRIVER% pour le nom du chauffeur",   
text:"Texte", 
defineText:"Définissez le texte", 
sendGMBLink:"Envoyez le lien Google My Business", 
customerAutomaticCancel:"Autoriser l’annulation auto de la course pour le client", 
customerAutomaticCancelMoreInfo:"En activant cette option, le client pourra annuler automatiquement une course. Le chauffeur recevra une notification pour l’informer que le client a annulé la course. Vous pourrez régler le temps maximal avant la course où cette option est disponible pour le client. Si le client avait payé, il sera remboursé sous forme de crédit",
minutes:"Minutes",
timeBeforeRace:"Jusqu'à combien de minutes avant la course ?", 
driverName:"Prénom du chauffeur",
driverLastName:"Nom du chauffeur",
forcePaiementExplication:"Texte explication paiement",
exportToAgenda:"Exporter vers l'agenda", 
activateDriverNotation:"Activer la notation des chauffeurs", 
activateDriverNotationMoreInfo:"En activant cette option, les clients pourront noter de 1 à 5 étoiles les chauffeurs à la fin de la course quand celle-ci aura été facturé",
activateCustomerNotation:"Activer la notation des clients", 
activateCustomerNotationMoreInfo:"En activant cette option, les chauffeurs pourront noter de 1 à 5 étoiles les clients à la fin de la course",
activateComment:"Activer la possibilité de laisser un commentaire", 
activateCommentMoreInfo:"En activant cette option, les clients et / ou les chauffeurs pourront en plus de la note laisser un commentaire que vous pourrez voir en tant qu'administrateur", 
noteCustomer:"Notez votre client", 
noteDriver:"Notez votre chauffeur", 
addComment:"Ajoutez un commentaire si vous souhaitez", 
yourComment:"Votre commentaire",
noThanks:"Non merci", 
thanksForYourRace:"Merci pour votre course du", 
effectWith:"Effectué avec", 
reviews:"Avis", 
raceTo:"Course du", 
clientNote:"Note attribué par le client",
driverNote:"Note attribué par le chauffeur", 
clientComment:"Commentaire rédigé par le client", 
driverComment:"Commentaire rédigé par le chauffeur", 
modifyNote:"Modifier la note", 
modifyNoteMoreInfo:"Vous pouvez modifier la note de l'utilisateur (maximum 5/5)",
modifyNumberReview:"Modifier le nombre d'avis", 
modifyNumberReviewMoreInfo:"Vous pouvez modifier le nombre d'avis de l'utilisateur",
incorrectNote:"Note saisi incorrect", 
incorrectNumberReview:"Nombre d'avis saisi incorrect", 
orderFormat:"Format du bon de commande", 
driverPricing:"Tarifs définissable par les chauffeurs", 
driverPricingMoreInfo:"Vous avez activé le mode chauffeur favori. En activant cette option, vous autorisez les chauffeurs « Avancé » et « Maximal » à pouvoir définir des forfaits et des zones qui s’appliqueront à leurs clients. Par exemple, vous facturez 50€ un aéroport - centre ville. Chauffeur A veut le facturer 45€. Client X a défini chauffeur A comme chauffeur favori. Client X paiera donc la course 45€ (ce que soit chauffeur A qui la fasse ou un autre si chauffeur A n’est pas disponible). Client Y n’a pas défini de chauffeur favori, il paiera donc 50€ même si c’est chauffeur A qui effectue la course",
selectName:"Selectionner un nom", 
activateTaxiMode:"Activer la tarification taxi", 
activateTaxiModeMoreInfo:"En activant la tarification taxi, vous allez pouvoir accepter des taxis sur l'application. Dans 'Gestion des tarifs', vous allez pouvoir définir une tarification taxi complémentaire à celle des VTCS. Dans répertoire, pour chaque chauffeur vous pourrez choisir s'il est taxi ou non. Au niveau des clients, en plus des offres VTC, ils pourront choisir une offre taxi. Contrairement au VTC, comme c'est le taximètre qui fait foi, vous ne pourrez pas accepter de paiement par carte et le client aura une estimation du prix", 
zoneTaxi:"Zone taxi", 
forfaitTaxi:"Forfait taxi", 
tarif:"Tarif", 
priceCharge:"Prise en charge", 
majoTaxiImmediate:"Réservation immediate", 
majoTaxiAvance:"Réservation à l'avance", 
tarifBDFrom:"Tarif majoré à partir de", 
tarifBDTo:"Tarif majoré jusqu'à", 
hourNotDefine:"Heure non défini", 
defineHour:"Définissez l'heure",
errorHour:"Heure non valide", 
tarifBDSaturday:"Tarif majoré valable le samedi",
tarifBDSunday:"Tarif majoré valable le dimanche",
tarifBDFerie:"Tarif majoré valable les fériés",
coeffEnable:"Activer l'affichage d'une fourchette de prix",
coeffEnableMoreInfo:"En activant cette option, les utilisateurs ne verra directement le prix calculé mais une estimation à plus ou moins un certain pourcentage que vous allez pouvoir définir. En activant cette option, le paiement par carte dans l'appli n'est pas possible pour cette course", 
definePercent:"Définissez le pourcentage", 
percentError:"Le pourcentage est invalide. Vérifier le pourcentage, celui-ci doit être inférieur à 50%",
taxi:"Taxi", 
priceStationToClient:"Station vers client", 
base:"Base", 
horaire:"Horaire", 
warningPriceTaxi:"Attention, il s’agit d’une estimation. Seul le prix affiché au taximètre est valable. Ce tarif ne comprend pas non plus les éventuels arrêts", 
race:"Course", 
raceNumber:"Course n°",
seeChat:"Voir le chat",  
viewDriverPricing:"Voir les prix définis par le chauffeur", 
goReturnTaxi:"C'est une course aller / retour", 
goReturnTaxiMoreInfo:"Lors d'une course aller / retour en taxi, vous bénéficiez d'une tarification différente. En revanche, le temps d'attente entre l'aller et le retour est payant et n'est pas compté dans l'estimation",
totalGoReturn:"Total aller - retour", 
raceTaxiFor:"Course taxi pour", 
raceReturn:"Course retour", 
sendMailToAdmin:"Envoyer systématiquement un mail pour chaque nouvelle course",
sendMailToAdminMoreInfo:"En activant cette option, vous recevrez systématiquement un mail pour chaque nouvelle course y compris si l'application est en mode de dispatch automatique. Cela vous permet de garder une trace par mail de la commande. Attention, si un admin créé une course et ce l'attribue directement, vous ne recevrez pas de mail car cela concerne uniquement les courses qui passent par le dispatch (manuel ou automatique)", 

alreadyAccountSignUp:"Vous avez déjà un compte ? Cliquez ici pour vous connecter", 
noAccountCreate:"Vous n'avez pas de compte ? Cliquez ici pour en créer un", 
doyouMean:"Voulez-vous dire ?",
hourOf:"Heure de", 
localHour:"Heure local", 
international:"International",
enableTimezone:"Vérification du fuseau horaire", 
enableTimezoneMoreInfo:"En activant cette option, l’application vérifiera si le fuseau horaire du client et le même que le votre. Dans le cas contraire, il demandera à l’utilisateur de quoi il parle. Exemple, il est 15h ici et 16h sur le téléphone du client. Il commande une course pour 19h. L’application demandera s’il s’agit de 19h par rapport à son fuseau horaire (soit 18h ici) ou 19h par rapport à ici",
majoTwoHour:"Majoration commande avant 2h", 
majoLastMinutes:"Majoration dernières minutes", 
preferCard:"Vous préférez payer par carte ? Cliquez ici pour l’ajouter", 
paidWithCredit:"Vous préférez payer sur votre crédit ? Cliquez ici",


customerMessageFreeCancel:"Message d’annulation gratuite possible", 
customerMessageFreeCancelMoreInfo:"En activant cette option, il y aura un message qui informe le client qu'une annulation gratuite est possible s'il prévient X minutes avant. Il s'agit simplement d'un message d'information, vous pouvez par exemple rembourser entièrement le client même s'il annule en dehors du délai",
freeCancelAnnulation1:"Annulation gratuite possible jusqu'à", 
freeCancelAnnulation2:"Minutes avant la course", 
delayKMExplication:"Le temps et le kilométrage annoncé sont annoncé à titre indicatif en fonction des informations fournies par Google Maps. Ils sont succeptibles de varier en fonction des conditions de circulation, de la méteo ...", 
dropOffClient:"Dépose du client", 
customerPro:"Client professionnel", 
customerMoreInfo:"Il s'agit d'un compte de base pour les clients de votre application",
partnerMoreInfo:"Il s'agit d'un compte chauffeur",
customerProMoreInfo:"Il s'agit d'un compte destiné aux professionels 'Grand compte' qui utilisent votre application pour commander des courses pour leurs salariés", 
providerMoreInfo:"Ce type de compte destiné aux hotels, restaurants, bars ... permet de commander la course directement au nom du client", 
vipMode:"Mode VIP", 
vipModeMoreInfo:"Le mode VIP vous permet, pour les clients que vous souhaitez, de les surclasser en VIP. Un badge VIP s'activera dans le menu. Dans 'Gestion des tarifs', vous pourrez définir des véhicules spéciques aux VIP. Pour pourrez également choisir les chauffeurs pour les VIP", 
standard:"Standard",
vip:"Vip",
pro:"Pro",  
distance:"Distance", 
generateBillsWithLinkPayment:"Génerer avec lien de paiement", 
generateBillsWithLinkPaymentMoreInfo:"Si vous choissisez de générer un lien de paiement, celui-ci sera disponible dans la facture que vous envoyez au client",
providerCommission:"Commission de l'apporteur d'affaires", 
invalidProviderCommission:"Commission de l'apporteur d'affaires invalide", 
trackLink:"Lien de suivi de course", 
scanCode:"Scanner ce code ou copier le lien", 
noRaceFound:"Aucune course n'a été trouvé", 
noRaceFoundMoreInfo:"Plusieurs raisons possible : soit le lien de suivi est invalide soit aucun chauffeur n'était disponible pour cette course", 
estimation:"Estimation", 
proposeOtherOptions:"Proposer d'autres options", 
settingsOption:"Réglages des options",
defineNameOption:"Définissez le nom de l'option pour chaque langue", 
definePriceOption:"Définissez le prix de l'option si l'option est payante (laissez vide sinon)", 
targetOption:"Définissez qui peut accéder à cette option",
quantity:"Quantité", 
quantityOption:"Définissez la quantité maximale disponible pour cet article", 
viewCar:"Affichage des véhicules", 
selectColor:"Selectionnez la couleur", 
hideOtherCarIfRaceInProgress:"Masquer les autres véhicules quand une course est en cours", 
carColor:"Couleur des véhicules", 
carColorMoreInfo:"Sur la carte, afin de mieux différentier les véhicules, vous pouvez attribuer des couleurs différentes. 'Véhicules des chauffeurs' correspond aux vrais chauffeurs. 'Chauffeur en approche' est visible par le client quand un chauffeur vient le chercher",
byDefault:"Par défaut", 
carDriver:"Véhicules des chauffeurs", 
seenFromAbove:"Vues du dessus", 
seenFromAboveMoreInfo:"Pour chaque type de véhicule que vous avez défini dans les réglages, vous pouvez spécifiez une vue du dessus qui correspond à ce véhicule", 
pictureAvaiable:"Images disponibles", 
pictureAvaiableAboveMoreInfo:"Pour qu’une image fonctionne bien, l’image doit être en png avec un fond transparent. Le véhicule doit être vu du dessus, l’avant du véhicule en haut", 

disableAutomaticMapFrom:"Désactiver le remplissage automatique du point de départ", 
disableAutomaticMapFromMoreInfo:"Par défaut, si l'utilisateur accepte la géolocalisation, l'application pré-remplira le champ 'Départ' avec les coordonnées actuelles de l'utilisateur. En activant cette option, le champ 'Départ' ne sera pas pré-rempli",
dayMajoration:"Majoration journalière", 
optionMajoration:"Majoration des options", 
orderMajoration:"Majoration de commande", 
localMajoration:"Régler la majoration locale", 
localMajorationMoreInfo:"La majoration que vous allez définir ici ne concernera que cette zone. Entre la majoration globale et la majoration de cette zone, l'application prend la valeur la plus élevée. Comme pour la majoration globale, utilisez seulement les majorations souhaitées. Si la majoration est la même que la majoration globale, vous n'avez pas besoin d'indiquer la majoration ici", 
otherMajorationHour:"Autres majorations horaire",
otherMajorationHourMoreInfo:"Si les créneaux horaires proposées par défaut ne vous conviennent pas, vous pouvez en ajouter d'autres ici", 
majoDynamicMoreInfo:"La majoration dynamique vous permet de modifier ponctuellement un prix lors d'une forte demande par exemple. Elle reste active tant que vous ne remodifier pas sa valeur", 
startHour:"Heure de début",
endHour:"Heure de fin", 
okayDayList:"Valable les jours suivants", 
dayListComplete:["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"],
createAccount:"Créer un compte", 
myRaces:"Mes courses", 
defineMinimalPassager:"Définir un nombre minimal de passagers", 
defineMinimalPassagerMoreInfo:"En activant cette option, vous aurez la possibilité de définir un nombre minimal de passagers pour chaque type de véhicule. Par exemple, vous pourrez dire qu'il faut minimum 2 passagers pour un 'Loti' ou 5 pour un minibus ...", 
max:"Max", 
min:"Min",
BookDriver:"Commander un chauffeur", 
when:"Quand ?",
whereWantToGo:"Où voulez-vous aller ?", 
finalizeOrder:"Finaliser la commande", 
priceChoiceVehicule:"Prix et choix du véhicule",
viewMoreOption:"Afficher les options", 
passwordToContinu:"Saississez le mot de passe pour continuer",
dispenserMode:"Mode borne", 
dispenserModeSure:"Êtes-vous sûr de vouloir activer le mode borne sur cet appareil ? Pour quitter le mode borne, il faudra cliquer 5 fois sur le logo de l'application", 
dispenserModeMoreInfo:"Le mode borne permet à vos clients de commander la course directement depuis une tablette avec une interface adaptée ! Vous voulez mettre en place le mode borne ? C'est très simple. Cliquez sur 'Mon Compte' et saississez l'adresse de votre établissement. Puis re-cliquez à nouveau sur ce bouton !", 
youCanScanThisQrcode:"Vous pouvez scanner ce QR Code pour suivre la course",
thanksOrder:"Merci pour votre commande", 
target:"Cible",
exportDataMoreInfo:"Vous pouvez copier les données et les coller dans un fichier au format .csv. Vous pourrez ensuite utiliser ce fichier comme vous le souhaitez", 
seeLockedAccount:"Voir les comptes bloqués", 
lockedAccount:"Bloqués", 
pressToAddPoint:"Appuyer sur la carte pour ajouter un point. 3 points minimum sont nécessaires", 
pickUpLocation:"Lieu de prise en charge", 
pickUpLocationMoreInfo:"En activant cette option, un onglet 'Lieu de prise en charge' sera rajouté dans le menu. Vous pourrez définir dans les 'Points d'intérêts' les lieux de prises en charge pour les gares, les aéroports ...", 
adsBottom:"Publicités en bas de l'écran", 
adsBottomMoreInfo:"Définissez des publicités qui apparaîtront en petit en bas l'écran. Les publicités passent les unes à suite des autres. Le format idéal est 5 largeur pour 1 hauteur",
adsFullScreen:"Publicités plein écran", 
adsFullScreenMoreInfo:"Définissez des publicités qui apparaîtront sous la forme d'un pop up plein écran. Attention les utilisateurs détestent ce genre de publicité. Si vous définissez plusieurs publicités pour une même cible, une seule apparaitra. Le format idéal est le format portrait",
actionOnClick:"Action lors d'un clic", 
doNothing:"Ne rien faire", 
goWeb:"Aller sur une page web",
goContact:"Aller sur 'Contacter'", 
goCopyClipboard:"Copier un texte dans le presse papier",  
preFillContact:"Texte pré-rempli", 
textCopyClipboard:"Texte à copier",
nloti:"n° LOTI", 
ntaxi:"n° TAXI",  
maxSizePicture:"La taille maximal du fichier doit être de 1MO", 
unavailableIfRace:"Considérer le chauffeur comme indisponible s’il est en course",
unavailableIfRaceMoreInfo:"En cochant cette option, un chauffeur qui est en train de faire une course (en mode automatique) sera considéré comme indisponible et ne recevra aucune nouvelle course", 
descriptionMoreOptionEx:"La taille maximal doit être de 56 x 45 x 25 cm",
bookDescriptionEx:"Vous pouvez indiquer le numéro de vol, de train ou une demande spéciale",
forcePaymentOnBoard:"Règlement", 
co2onBill:"Afficher le CO2 sur la facture", 
co2onBillMoreInfo:"En activant cette option, vous aurez la possibilité de définir le taux de CO2 par km pour les différents types de véhicule que vous avez et cette information apparaitra sur la facture",
co2onOrder:"Afficher le CO2 lors de la commande", 
co2onOrderMoreInfo:"En activant cette option, le rejet de CO2 appaitra lors de la commande de la course", 
co2:"CO2", 
caractereMin:"Au moins huit caractères",
caractereMAJ:"Au moins une majuscule",
caractereMinus:"Au moins une minuscule",
caractereNumber:"Au moins un chiffre", 
thanksSubscribe:"Merci pour votre inscription", 
orderNowDownloadApp:"Vous pouvez commander une course dès maintenant ou télécharger notre application", 
downloadApp:"Télécharger l'appli", 
contactUrgency:"Vous pouvez aussi nous contacter et nous essaierons de trouver une solution", 
autoGestionNotAvaiableMoreInfo:"Le mode de gestion automatique n'est pas disponible car il s'agit d'une course ajoutée manuellement dans l'appli (par un administrateur, un chauffeur ou un apporteur d'affaire). Comme le client n'a pas l'appli, le chat et le suivi de la course en temps réel ne serait pas possible. C'est pour ça que l'option est désactivé", 
providerFixeCommission:"Ajouter une commission fixe pour les apporteurs d’affaires",
providerFixeCommissionMoreInfo:"En activant cette option, vous aurez la possibilité, pour chaque zone et chaque forfait de définir une commission fixe pour les apporteurs d’affaires (dans Gestion des tarifs). Cette commission s’ajoutera au prix de la course ainsi qu’à la commission que vous pouvez définir pour chaque apporteur d’affaire. Comme pour cette dernière, la commission fixe ne s’applique qu’en cas de « paiement à bord »",
errorGeolocalisation:"La géolocalisation n’est pas active ou le téléphone n’arrive pas à connaitre votre position",
noMajoForPro:"Pas de majoration pour les clients pro",
noMajoForProvider:"Pas de majoration pour les apporteurs d'affaires",
priceChargeMajo:"Prise en charge majorée", 
for:"Pour", 
whatDoing:"Que voulez-vous faire ?", 
seeDriver:"Voir où sont les chauffeurs", 
seePlaning:"Voir le planning, accepter et ajouter des courses", 
seePhoneBook:"Voir le répertoire",
seeBill:"Voir les factures",
seeSettings:"Voir et modifier les réglages",
meLogout:"Me déconnecter", 
addRace:"Ajouter une course", 
noRaceDispatch:"Aucune course à dispatcher", 
raceDispatch:"Course(s) à dispatcher",
raceValidateLinkPayment:"Course validée - lien de paiement", 
linkCopiedMessage:"Le lien pour payer la course a été copié dans le presse papier. Vous pourrez le coller dans votre message", 

sendMessageIfPaiementError:"Recevoir un mail en cas d'erreur de paiement",
sendMessageIfPaiementErrorMoreInfo:"Cette option est déconseillé. Elle vous permet de recevoir un mail avec les informations clients / de la course lorsqu'un paiement a echoué.",

notesForDriver:"Notes visibles par les chauffeurs", 
thanksWeWillReceiveAnswer:"Merci, nous reviendrons vers vous", 
billWithoutDetail:"Factures différées sans détail", 
billWithoutDetailMoreInfo:"En activant cette option, les factures différées seront générés sans afficher les détails des courses (départ, destination ...)",

percent:"Pourcentage", 
validFrom:"Valable à partir de", 
promoCodeInvalidDate:"Le code promo n'est pas valide à cette date là", 
promoCodeInvalidDest:"Le code promo n'est valable pour cette destination",
promoCodeInvalidPrice:"Le code promo est valable pour une course minimun de",
promoCodeInvalidUse:"Vous avez déjà utilisé ce code promo", 
saveRemovedRace:"Garder une trace des courses annulées", 
saveRemovedRaceMoreInfo:"En activant cette option, les chauffeurs ne supprimerons plus les courses directement. La course apparaitra bien comme supprimé mais elle sera encore visible dans le planning. Vous pourrez toujours la faire disparaitre définitivement en temps d'admin", 
removeRace:"Supprimer la course", 
orderDate:"Commande", 
ifProblemeMAV:"Identifiant MAV en cas de problème avec ce compte", 
includeNotBilled:"Dont non facturé", 
forcePrice:"Forcer le prix", 
changePriceDriver:"Voulez-vous forcer le prix chauffeur ?", 
fixedPrice:"Prix fixé par l'admin", 
enableFlightTrainVerif:"Demander le numéro de vol / de train", 
enableFlightTrainVerifMoreInfo:"En activant cette option, si un client choisi comme point de depart ou comme destination un aéroport ou une gare, l'application lui demandera de saisir un numéro de vol ou de train (de manière non obligatoire). L'application se base sur les points d'intérêt xvous avez défini possédant l'icône 'Avion' ou l'icône 'Train'",
numeroFlight:"Numéro de vol", 
numeroTrain:"Numéro de train",
see:"Voir",  

enableDriverNameInRace:"Afficher le nom du chauffeur dans 'Mes Courses'",
enableDriverNameInRaceMoreInfo:"En activant cette option au lieu d'avoir 'en attente de validation' / 'course validée' dans l'onglet 'Mes Courses' dans le compte du client, il y aura le nom du chauffeur",
definePrice:"Définir des prix", 
adressBill:"Adresse de facturation", 
cityBill:"Ville de facturation", 
oneCarMin:"Merci de définir un véhicule au minimun",
warningOnePassager:"Attention : Ce véhicule ne peut prendre en charge aucun ou un passager. Vérifiez ça avant de continuer. Ca peut être valable si vous êtes en moto ou si c'est un véhicule pour le colis", 
basicDriverAddRace:"Autoriser les chauffeurs 'basique' et 'standard' à ajouter des courses",
basicDriverAddRaceMoreInfo:"Par défault, seuls les chauffeurs 'avancé' et 'maximal' peuvent ajouter des courses. En activant cette option, tout les chauffeurs pourront ajouter manuellement des courses dans l'appli",
bookDescriptionBasicEx:"Saisissez vos demandes particulières ici",

quitDispatch:"Quitter le dispatch", 
warning:"Attention", 
selectWrongDriver:"Vous avez selectionné un chauffeur qui n'est pas compatible avec cette course (options, type de voitures, hors ligne ...). Etes-vous sur de lui affecter la course ?", 

seeAll:"Voir tout", 
bancontact:"Bancontact",
withBancontact:"Avec Bancontact",
paidWithBancontact:"Vous préférez payer avec Bancontact ? Cliquez ici",

raceOkAfterBancontact:"Vous allez être dirigé sur une page permettant de payer avec Bancontact. Votre course sera prise en compte une fois que vous aurez payé",
commissionZero:"Attention, vous avez défini une commission variable de 0%. C'est économiquement dangereux, soyez vigilant", 

errorEmail:"L'email que vous avez indiqué n'est pas valide", 
errorTelephone:"Vous n'avez pas rempli le numéro de téléphone", 
contactClientYou:"Vous devez donc prévenir le client par vos propres moyens",

validateAfterQuotation:"Valider après devis",
emailToQuotation:"Merci d'indiquer un email valide pour l'envoi du devis", 
emailHasBeenSent:"L'email a été envoyé",

contactFavoriteDriver:"Permettre de contacter directement le chauffeur favori",
contactFavoriteDriverMoreInfo:"En activant cette option, les clients ayant désigné un chauffeur favori pourront le contacter directement dans l'onglet 'Contact' de l'application",

youWillContact:"Vous allez contacter",
preferContactPlatform:"Vous préférez contacter la plateforme ? Cliquez ici", 

partnerPartialAccessPhoneBook:"Autoriser les chauffeurs à accéder partiellement au répertoire de l’application", 
partnerPartialAccessPhoneBookMoreInfo:"Par défaut, les chauffeurs n’ont pas accès au répertoire global de l’application. Les chauffeurs « avancés » et « max » peuvent bien créer leur propre répertoire mais ça s’arrête là. En activant cette option, les chauffeurs pourront avoir accès aux clients de l’application les ayants uniquement dans le cas où le client les a désigné comme chauffeur favori",

manualPhoneBookList:"Liste des contacts que vous avez manuellement ajouté", 
favoriteDriverPhoneBookList:"Liste des contacts où vous êtes désigné comme 'chauffeur favori'", 
clickHereSeeUserFavoriteDriver:"Cliquez ici pour voir les utilisateurs vous ayant désigné comme chauffeur favori",

multiStop:"Autoriser le multi arrêt",
multiStopMoreInfo:"En activant cette option, les utilisateurs pourront définir des arrêts intermédiaires",
multiStopWarning:"En activant cette option, si un client commande une course multi trajet, l’application ne testera plus les forfaits (qui par définition ne concerne qu’un point A vers un point B) mais uniquement les zones. De plus, vous risquez de perdre du temps pendant la course et le client risque aussi de se tromper dans l’ordre des arrêts. Le multi arrêt est désactivé dans le mode borne",
addStop:"Ajouter un arrêt intermédiaire", 
multiStopInfo:"Arrêt intermédiaire", 
onlyStop:"Arrêt", 
onlyStops:"Arrêts",

addClientBook:"Permettre l’ajout de plusieurs clients lors de la commande", 
addClientBookMoreInfo:"En activant cette option, les clients pourront spécifier de manière facultative le nom des différentes personnes durant la course (indisponible dans le mode borne)", 
infoPassager:"Infos passagers", 
principalCustomer:"Voyageur principal",
addCustomer:"Ajouter un passager",
passagerToHigh:"Passager en trop", 
incorrectPassagers:"Il y a trop de passagers", 
dropOff:"Dépose",
purposeReturn:"Proposer une course retour",
purposeReturnMoreInfo:"En activant cette option, l'application proposera, si c'est possible, de faire un trajet retour", 
wantCreateReturn:"Voulez-vous créer la course retour ?",
stats:"Statistiques", 
statsMoreInfo:"Selectionnez un intervalle de temps et obtenez rapidement des statistiques qui vous permettront d'optimisez votre stratégie commerciale", 
seeStats:"Voir les statistiques", 
startDate:"Date de début", 
endDate:"Date de fin",
thanksIntervalHigh:"Merci de définir un intervalle plus grand",
topPlace:"Top des lieux", 
topClients:"Top des clients",
topDrivers:"Top des chauffeurs",
orderByDay:"Commandes par jour", 
orderByHour:"Commandes par heure",
traveler:"Voyageur",
travelers:"Voyageurs",
autorizeChangeDriverFavoritePrice:"Autoriser également la modification du tarif",
autorizeChangeDriverFavoritePriceMoreInfo:"En activant cette option, vous autoriserez les chauffeurs ayant été désigné comme chauffeur favori par le client à ajouter et modifier des forfaits et des zones spécifique à ce client. Cette option est déconseillée car si vous évitez que les chauffeurs ne vous appelle pour ajouter manuellement des forfaits / zones, vous perdez en pouvoir", 

viewWaitingPaymentRace:"Courses en attente de paiement",
viewWaitingPaymentRaceMoreInfo:"En activant cette option, les courses que vous avez créé en 'Valider après paiement' et qui n'ont pas été encore payé apparaitront quand même dans le planning",
viewWaitingPaymentRaceExp:"Vous ou un autre administrateur a créé cette course en mode 'Valider après paiement'. Si vous avez changé d'avis et que vous voulez quand même valider la course, cliquez sur 'Valider'. Vous voulez annuler la course ? Vous n'avez rien à faire, elle se supprime automatiquement au bout de quelques jours",
seeOneBill:"Voir la facture",
generatePDFRecap:"Générer un PDF recapitulatif",
buyerInfos:"Infos payeur", 
seePOIMap:"Voir les points d'intérêt sur la carte", 
seePOIMapMoreInfo:"En activant cette option, les points d'intérêt de Google Maps (comme les restaurants, les gares ...) seront désactivés mais les vôtres apparaitront et les clients pourront les choisir comme départ et destination (hors version web)", 
startFromHere:"Partir d'ici !",
goToHere:"Y aller !",
requestModif:"Demander une modification",
modif:"Modification", 
whatChange:"Que voulez-vous modifier ?",
date:"Date",
newValue:"Saisissez la nouvelle valeur",
thanksModify:"Vous n'avez rien modifié", 
requestModifWait:"Modification demandée",
requestModifWaitMoreInfo:"Le chauffeur n'a pas encore répondu à votre demande de modification",
requestModifAccept:"Modification acceptée",
requestModifDecline:"Modification refusée",
otherProvisionZone:"Zones à tarification spécifique",
otherProvisionZoneMoreInfo:"Si vous le souhaitez, vous pouvez définir des taux horaires différents pour une ville ou une zone de départ spécifique. Ces prix remplaceront ceux par défaut que vous avez défini au dessus", 
provisionPriceDefault:"Prix par défaut et par type de véhicule",
newZone:"Nouvelle zone", 
noDefinedPrice:"Aucun prix défini",
forTheZone:"Pour la zone",
multipleCarProvision:"Autoriser la commande multiple de véhicule",
multipleCarProvisionMoreInfo:"En activant cette option, les clients pourront demander plusieurs voitures lors d’une mise à disposition. Vous serez toujours libre d’accepter ou non la course. Cette option est déconseillée si vous laissez les chauffeurs accepter les courses (car peu pratique)",
cars:"Véhicules",
promoValidMoreUser:"Utilisable plusieurs fois par l'utilisateur", 
promoValidMoreUserMoreInfo:"En activant cette option, l'utilisateur pourra se servir plusieurs fois du code promo alors que ce n'est pas le cas par défaut. Pour lui faire une réduction permanente, préférez plutôt l'option 'Reduction permanente' dans sa fiche client",
onlyProvision:"Uniquement en mise à dispo",
newMessage:"Nouveau message", 
newMessageForRace:"Vous avez reçu un message pour la course du", 
notDriverBecome:"Vous n'etes pas le chauffeur de cette course, souhaitez-vous le devenir ?",
manualRaceSMSWA:"Comme il s'agit d'une course manuelle, le voyageur final n'a pas l'application. Souhaitez-vous le prevenir par sms / WhatsApp ?", 
day:"Jour",
onBoard:"A bord",
cbapp:"CB appli",
fromWaiting:"La course est de nouveau en attente de validation",
yourRaceButNotDriver:"Vous avez ajouté cette course, mais vous n'êtes pas le chauffeur. Vous pouvez voir cette course à titre d'information",
autoriseDriverSeeRace:"Autoriser les chauffeurs à voir les courses non réalisées par eux mais où ils sont concernés",
autoriseDriverSeeRaceMoreInfo:"En activant cette option, vous autorisez les chauffeurs à pouvoir voir les courses qu'ils ont ajoutées ou les courses où ils ont été désigné 'chauffeur favori' mais réalisées par un autre chauffeur. Par exemple, chauffeur A ajoute une course qu'il ne peut pas faire et chauffeur B prend cette course. En activant cette option, chauffeur A verra cette course dans son planning. Cette option ne s'applique que si vous avez activé la gestion des chauffeurs favoris ou que vous avez autorisé certains chauffeurs à pouvoir ajouter des courses",
notYourRaceButFavorite:"Vous n'êtes pas le chauffeur de cette course mais vous pouvez la voir car vous avez été désigné chauffeur favori",
viaApp:"Via l'appli",
howWhoContact:"Qui et comment voulez-vous le prévenir ?",
messageBeforeBook:"Définir un message avant la commande", 
messageBeforeBookMoreInfo:"Vous pouvez définir un message qui appaitra au moment de la finalisation de la commande (par exemple, les conditions d'annulation, remercier le client pour sa confiance ...)",
raceAsap:"Le client a demandé un départ dès que possible", 
takeClientNowOrAfter:"Partez-vous le chercher maintenant ou dans quelques minutes ?", 
into:"Dans", 
contactDriver:"Contacter le chauffeur", 
contactPlateform:"Contacter la plateforme",
cashOrCard:"Par espèce ou par carte",
duplicateRace:"Dupliquer la course",
duplicateRaceMoreInfo:"Vous allez dupliquer cette course. Vous allez devoir selectionner la nouvelle date", 
duplicateRaceMoreInfoCharge:"Attention, la charge bancaire associé à cette course ne sera pas copié",
returnMode:"Inverser départ et destination", 
raceHasBeenDuplicate:"La course a bien été dupliqué", 
favoriteDriverNotPossible:"Ne peux pas être chauffeur favori", 
favoriteDriverNotPossibleMoreInfo:"(seuls les clients sont concernés par cette limitation)",
copyThisZone:"Copier cette zone", 
copyThisForfait:"Copier ce forfait",
createFromClipboard:"Créer depuis le presse-papier",
zoneHasBeenCopied:"Cette zone a bien été copiée ! Vous pouvez créer facilement une nouvelle zone ailleurs en cliquant sur le bouton 'Ajouter' puis sur 'Créer depuis le presse-papier'",
forfaitHasBeenCopied:"Ce forfait a bien été copiée ! Vous pouvez créer facilement un nouveau forfait ailleurs en cliquant sur le bouton 'Ajouter' puis sur 'Créer depuis le presse-papier'",
saveTimeCopyForfaitZone:"Vous pouvez gagner du temps en duplicant des forfaits / zones. Ouvrez le forfait ou la zone en question puis cliquez sur 'Copier ce forfait / cette zone'", 
seeCreditCustomer:"Voir / modifier le crédit du client", 
modifyCreditCustomer:"Saisissez le nouveau montant",
thanksPassword:"Merci de saisir votre mot de passe pour continuer", 
linkPayment:"Lien de paiement", 
textDefault:"Laisser le texte par défaut",
thanksFinishPayRace:"Merci de terminer le paiement de la course",
amountPaid:"Montant à payer",
paid:"Payer", 
paidPhoneWait:"Le client peut désormais payer sur son téléphone. Il lui suffit d'ouvrir l'appli et de confirmer le montant",
remainingTime:"Temps restant",
remainingTimeMoreInfo:"Après il faudra refaire une tentative",
paymentValidate:"Paiement validé !",
withBill:"avec la facture",
withBillMoreInfo:"Un lien de paiement sera ajouté au mail envoyé au client contenant sa facture. Il devra cliquer sur le lien et payer. A réserver aux clients de confiance",
inApp:"dans l'appli",
inAppMoreInfo:"Le client devra ouvrir l'application et valider le paiement avec sa carte bancaire", 
necessairePaid:"Il est nécessaire de faire payer le client. Vous avez différents choix :", 
paiementOnBoardMoreInfo:"Vous devrez encaisser le client à bord comme vous le souhaitez (espèce, carte ...)",
raceStarted:"Chauffeur en route vers le départ",
driverPresentStartPoint:"Chauffeur présent au départ",
raceRecap:"Récapitulatif de la course",
fiftytoGo:"50 courses à venir",
selectBillNumber:"Saisissez le numéro de la facture", 
byDate:"Par date", 
byCustomer:"Par client", 
byDriver:"Par chauffeur",
deferred:"Différé",
customersPro:"Clients pro",
reminder:"Rappel", 
raceAdmin15:"Rappel après 15 min", 
raceAdmin15MoreInfo:"Si aucun chauffeur n'a pris la course, l'admin recevra environ 15 minutes après une notification pour l'informer qu'une course reste toujours en attente", 
raceReminder:"Rappel de courses",
raceReminderMoreInfo:"L'application enverra aux chauffeurs un rappel entre 0h45 et 1h45 avant le début de la course pour lui rappeler. S'il y a plusieurs courses pour le même chauffeur, l'application enverra un rappel du style : 'Vous avez une course dans 45 minutes puis dans 85 minutes'",
raceReminderTomorrow:"Annonce des courses du lendemain", 
raceReminderTomorrowMoreInfo:"L'application enverra une notification pour dire aux chauffeurs le nombre de courses prévu le lendemain. Si aucune course n'est prévu, l'application n'enverra pas de notification",
raceReminderArrivate:"Rappel de l'arrivée d'une course",
raceReminderArrivateMoreInfo:"L'application enverra une notification si le chauffeur n'a pas déclaré qu'il était arrivé au lieu de prise en charge pour lui demander de le faire afin de prévenir le client", 
cancelAnnulationSend:"Votre demande d'annulation a été envoyé", 
drawedNotAvaiableKarhoo:"Le dessin d'une zone n'est pas disponible avec l'API pour Karhoo",
raceRemoved:"Course supprimée", 
actionRay:"Rayon d'action",
noNameCar:"(Véhicule sans nom)",
newCar:"Nouveau véhicule", 
authorisedStandards:"Accessible aux clients standards",
authorisedVIP:"Accessible aux clients VIP",
authorisedPro:"Accessible aux clients pros",
limitTime:"Limitation horaire",
limitTimeMoreInfoForfait:"Par défaut, les forfaits sont valables à n'importe quelle heure. Ici, vous pouvez changer ça et désactiver ce forfait sur un créneau horaire", 
limitTimeMoreInfoZone:"Par défaut, les zones sont valables à n'importe quelle heure. Ici, vous pouvez changer ça et désactiver cette zone sur un créneau horaire", 
validityFrom:"Valide à partir de",
validityTo:"Valide jusqu'à",
limitTimeError:"Vous n'avez pas réglé correctement la limite de validité car l'un des champs est manquant. Merci de vérifier ça et eventuellement de supprimer la limitation",
adsNotificationAutorize:"Autoriser les notifications publicitaires", 
modifyOk:"La modification a été prise en compte",
modificationOkDevice:"Sur cet appareil la modification est effective dès maintenant. Si vous utilisez plusieurs appareils, la modification aura lieu lors de la prochaine ouverture de l’appli",
adsNotification:"Notification publicitaire ou informative",
setNotification:"Gérer les notifications",
adsNotificationMoreInfo:"Les notifications publicitaires ou informative vous permettent d'informer vos clients sur iOS (iPhone) et android, d'une opération spéciale, de réductions ponctuelles ou encore d'annoncer des nouveautés. Les notifications publicitaires sont tolérés par Google et Apple à condition que l'utilisateur puisse les désactiver. C'est le cas dans l'onglet 'Mon compte' où le client pourra désactiver la reception des notifications publicitaires. Si vous étiez client de Mon Appli VTC avant le 2 avril 2021 et que vous aviez un version inférieure à la 1.801 les utilisateurs devront avoir mis à jour l'application avant de recevoir les notifications publicitaires. N'abusez pas des notifications publicitaires",
newNotification:"Nouvelle notification",
overview:"Aperçu",
title:"Titre", 
subTitle:"Sous titre",
langue:"Langue",
oldNotifications:"Anciennes notifications",
notifHasBeenSend:"La notification a été envoyé. Cela peut prendre plusieurs minutes avant les utilisateurs la recoive", 
warningManualAccount:"Attention : c'est un compte manuel",
warningManualAccountMoreInfo:"Les comptes manuels vous permettent de rentrer les informations des clients qui ne possèdent pas l'appli mais qui commandent régulièrement (les personnes agés par exemple). Les clients, les apporteurs d'affaires et les chauffeurs doivent créer un compte client puis vous pourrez changer le status de leur compte. Si vous voulez le faire à leur place, vous devez vous déconnecter et créer un nouveau compte puis leur communiquer les informations. Cette option est cependant déconseillée à cause de la transmission du mot de passe",
majoHowWork:"Comment fonctionne les majorations ?", 
noForfait:"Aucun forfait défini", 
clickOnAddForfait:"Vous pouvez en ajouter un en cliquant sur Ajouter",
noZone:"Aucune zone définie",
clickOnAddZone:"Vous pouvez en ajouter une en cliquant sur Ajouter",
clickOnAddZoneMore:"Vous devez créer une zone au minimum pour que l'application fonctionne et que les gens puissent commander",
noPromoCode:"Aucun code promo défini",
majoPromoCodeWork:"Comment fonctionne les codes promos ?",
internationalCustomer:"Clientèle internationale ?",
explicationHexaColor:"Selectionner l'une des couleurs pré-établi ou entrez un code couleur en héxadécimal", 
explicationHexaColorMoreInfo:"Un code couleur en héxadécimal correspond à une nuance de couleur. Vous avez problablement un code couleur dans votre charte graphique. Vous pouvez contacter votre graphiste qui pourra vous la communiquer ci-besoin",
needHelp:"Besoin d'aide ?",
invalidColorCode:"Code couleur invalide. Merci d'indiquer un code hexadécimal correct",
majoCustom:"Majorations personnalisées", 
thinkSave:"N'oubliez pas d'enregistrer", 
thinkSaveMajoOther:"Les modifications que vous effectuez ici seront enregistré lorsque vous confirmerez les majorations dans l'écran précédent en appuyant sur le bouton Confirmer", 
noNameMajo:"(Majoration sans nom)",
newMajo:"Nouvelle majoration",
thinkSaveClickConfirm:"En cliquant sur le bouton Confirmer qui est situé en bas de la page",
majoKMNegatif:"Vous pouvez également mettre un coefficient négatif (exemple : x0.9) si vous préférez offrir une réduction pour les longs trajets",
vehiculeOne:"un véhicule",
paymentMode:"Mode de paiement",
notBill:"Ne pas génerer de facture", 
forCustomer:"Pour le client", 
notBillMoreInfo:"Activez cette option si vous utilisez un système de facturation externe pour certaines courses. C'est uniquement la facture client qui est concernée et non la facture du chauffeur qui sera généré normalement (dans le cas où il faudrait faire une facture pour le chauffeur)",
viewWaitingPaymentRaceQuote:"Vous ou un autre administrateur a créé cette course en mode 'Valider après devis'. Si vous avez changé d'avis et que vous voulez quand même valider la course, cliquez sur 'Valider'. Vous voulez annuler la course ? Vous n'avez rien à faire, elle se supprime automatiquement au bout de quelques jours",
noSystemConnected:"Aucun système connecté",
seeCalendar:"Voir le calendrier",
byHour:"Par heure", 
byList:"Par liste",
status:"Statut",
allDriver:"Tous les chauffeurs",
clickToClose:"Cliquez sur le logo pour fermer", 
blacklistedQuestion:"Qui souhaitez-vous blacklister ?",
cancelRaceDriver:"Souhaitez-vous informer le chauffeur que la course a été annulé ?", 
onlyDesired:"Chauffeur désiré seulement",
stopTracking:"Arrêter le suivi",

messageEstimation:"Définir un message pour l'estimation", 
messageEstimationMoreInfo:"Lorsqu'une personne commande une course, le kilométrage et le temps estimés apparaissent. A côté, un 'estimation' apparait pour rappeler aux clients que les conditions peuvent varier en fonction de divers paramètres : circulation, méteo ... Si vous le souhaitez, vous pouvez personnaliser ce message",
trajetModifyCalcul:"Vous avez modifié le trajet. Voulez-vous re-calculer le prix de la course ?",
trajetModifyCalculWarning:"Attention, le prix ne tiendra pas compte d’une éventuelle remise des conditions particulières au moment de la commande ...", 
writeValue:"Saisissez l'information ici",
calculRaceWait:"Nous calculons le prix de la course",
coche:"Coché",
notCoche:"Non coché",
selected:"Selectionné",
notSelected:"Non selectionné", 
minute:"Minute",
addElement:"Bouton ajouter",
removeElement:"Bouton retirer", 
removeCustomer:"Retirer un passager", 
hideParticularRqs:"Masquer les demandes particulières",
hideParticularRqsMoreInfo:"Par défaut, le client peut, lors de la commande, indiquer des demandes particulières sur sa course. En activant, cette option, cela ne sera plus possible",
manager:"Gestionnaire",
managerMoreInfo:"Vous souhaitez ajouter des membres de votre équipe qui puissent accéder à certaines parties de l'application ? Passez-les en Gestionnaire et selectionner les tâches autorisées",
roles:"Rôles", 
rolesManagerMoreInfo:"Cochez le ou les rôles que vous voulez affecter à cet utilisateur",
dispatch:"Dispatch",
otherDriver:"un autre chauffeur",
managerModifAccountType:"La modification du type de compte n'est pas possible pour les comptes 'Gestionnaire' pour des raisons de sécurité. Merci de contacter un administrateur",
managerModifAccountManage:"La modification des rôles pour les gestionnaires est impossible avec votre compte pour des raisons de sécurité. Merci de contacter un administrateur",
managers:"Gestionnaires",
paiementOnBoardCardMoreInfo:"Vous encaissez le client à bord spécifiquement avec un terminal de paiement",
onBoardCash:"A bord espèce",
onBoardCard:"A bord carte", 
seeParams:"Voir les paramètres",
hideParams:"Masquer les paramètres",
viewComplete:"Vue complète",
viewSimplified:"Vue simplifiée",
exportCSV:"Exporter en CSV",
other:"Autre",
otherPaiment:"Autre moyen de paiement", 

settingsAdmin:"Réglages pour les admins",
modifRaceAdmin:"Recevoir les demandes de modification",
modifRaceAdminMoreInfo:"Lorsqu’un client demande une motification, c’est le chauffeur qui effectue la course qui la reçois. En activant cette option, les admins la recevra aussi", 
raceStatusAdmin:"Recevoir les changements d'état d'une course",
raceStatusAdminMoreInfo:"En activant cette option, vous pourrez recevoir une notification push à chaque fois qu’un chauffeur change de statut d’une course (quand il part, quand il arrive sur le lieu de prise en charge …)",

noFileUpload:"Merci de charger le fichier",
noLink:"Merci d'indiquer ce que l'action doit faire", 
position:"Position",
positionAdsMoreInfos:"Les pubs passent les unes à la suite des autres. Ici, vous pouvez définir quand la pub passera par rapport aux autres publicités", 
adsNoName:"(Publicité sans nom)",
clickToModify:"Cliquez pour modifier",
viewKarhooForDrivers:"Afficher le badge Karhoo",
viewKarhooForDriversMoreInfo:"En activant cette option, vous indiquerez aux chauffeurs qu'il s'agit d'une course provenant de Karhoo",


createLinkSripeConnect:"Créer un lien stripe Connect", 
createLinkSripeConnectMoreInfo:"(pour connecter le compte bancaire du chauffeur à l'appli)",
createLinkSripeConnectLink:"Attention, le lien n'est valable que 5 minutes (et ouvrable qu'une seule fois). Vous pouvez remplir les informations par vous même ou le transferer au chauffeur",


hidePhoneApp:"Masquer le téléphone dans 'Contact'",
hidePhoneAppMoreInfo:"En activant cette option vous désactiverez le numéro de téléphone de l'application dans l'onglet 'contact' disponible dans le menu pour les clients. Cela signifie que les gens devront vous contactez par mail et plus par téléphone",
navigateWith:"Naviguez avec",
bdc:"Voir le bon",
signPost:"Voir la pancarte",
chatPrefill:"Préremplissage chat", 
chatPrefillMoreInfo:"Afin de faire gagner du temps aux chauffeurs, vous pouvez définir des messages pré-enregistré. Comme ça, le chauffeur n'aura qu'à cliquer sur le message et celui-ci sera automatiquement envoyé au client dans le chat. Vous pouvez mettre des messages du style : 'Où êtes-vous ?', 'je suis dans les bouchons' ...",
takeMessage:"Saisissez le message",
prefillMessagePositionMoreInfos:"Les messages sont affichés les uns à la suite des autres. Ici, vous pouvez définir l'ordre où vous voulez voir les messages (mettre les plus importants en premier par exemple)", 
billToGeneretate:"Facture à générer", 
isDeferred:"Il s'agit d'une facture globale", 
isDeferredOkOther:"D'autres course peut être rattaché sur la même facture, voulez-vous appliquer ce choix aux autres ?", 
viewTrajetCustomerDriver:"Afficher le trajet prévu du chauffeur", 
viewTrajetCustomerDriverMoreInfo:"Cette option permet côté client de voir le trajet prévu par le chauffeur. Si cette option n'est pas activé, le client verra simplement la voiture se déplacer",
source:"Source",
customerInApp:"Client de l'appli", 
allSources:"Toutes", 
raceWaitingValidation:"En attente de validation",
helpMoreInfo:"Afin d'aider les clients pour les questions qu'ils pourraient avoir, vous pouvez définir des questions / réponses. Cela vous permet de gagner du temps car ça évite qu'ils vous contactent par message pour rien. Par défaut, nous avons mis des exemples. Ils sont bien entendus modifiables et supprimables. ", 
smsPrefill:"Préremplissage SMS",
smsPrefillMoreInfo:"En fonction de la fonction dont vous configurez l'appli et notamment si vous autorisez les chauffeurs a envoyer des messages aux clients, l'application peut pré-remplir des SMS (ou Whatsapp). Vous pouvez personnaliser ici les textes (ou les laisser par défaut)",

smsPrefillValidate:"Ecrivez le texte qui pré-remplira le message.\nVous pourrez toujours modifier ce message.\n\nInscrivez '%NAME%' pour insérer le prénom du client, %LASTNAME% pour le nom de famille, %DATE% pour la date de la course, %START% pour l'adresse de départ, %END% pour la destination et %DRIVER% pour le nom du chauffeur. Attention, si l'élement n'est pas disponible (comme le prénom du client) il sera remplacé par un texte vide. Si vous utilisez %END% et qu'il s'agit en fait d'une mise à dispo, le texte 'Mise à dispo' apparaitra. Pensez-y quand vous faites vos phrases",   
smsPrefillValidateT:"Ecrivez le texte qui pré-remplira le message et qui sera envoyé aux clients avec le lien de suivi de course.\nVous pourrez toujours modifier ce message.\n\nInscrivez '%NAME%' pour insérer le prénom du client, %LASTNAME% pour le nom de famille, %DATE% pour la date de la course, %START% pour l'adresse de départ, %END% pour la destination, %DRIVER% pour le nom du chauffeur et %TRACKLINK% pour le lien de suivi de course. Attention, si l'élement n'est pas disponible (comme le prénom du client) il sera remplacé par un texte vide. Si vous utilisez %END% et qu'il s'agit en fait d'une mise à dispo, le texte 'Mise à dispo' apparaitra. Pensez-y quand vous faites vos phrases",   
smsPrefillImcomming:"Ecrivez le texte qui pré-remplira le message et qui sera envoyé aux clients avec le lien de suivi de course.\nVous pourrez toujours modifier ce message.\n\nInscrivez '%NAME%' pour insérer le prénom du client, %LASTNAME% pour le nom de famille, %START% pour l'adresse de départ, %END% pour la destination, %DRIVER% pour le nom du chauffeur et %DURATION% pour la durée en minutes. Attention, si l'élement n'est pas disponible (comme le prénom du client) il sera remplacé par un texte vide (ou -- pour %DURATION%). Si vous utilisez %END% et qu'il s'agit en fait d'une mise à dispo, le texte 'Mise à dispo' apparaitra. Pensez-y quand vous faites vos phrases",   

notSaved:"Modifications non enregistrées",
withTrackLink:"Avec lien de suivi", 
messages:"Messages",
confirmCancelClient:"Confirmation demande d'annulation", 
contactAdminRace:"Aucun chauffeur n'est compatible avec cette course. Merci de prendre contact avec un administrateur qui pourra forcer l'attributation", 
noDriverTakeRace:"Aucun autre chauffeur n'est compatible avec cette course. Voulez-vous faire cette course ?", 
acceptRace:"Accepter la course", 
declineRace:"Refuser la course", 
viewNextRace:"Course suivante", 
seeThisPhonebook:"Voir son répertoire", 
phonebookCustomer:"Répertoire créé manuellement par cet utilisateur", 
defineColorForCustomer:"Définir des couleurs pour les clients",
defineColorForCustomerMoreInfo:"En activant cette option, vous pourrez mettre en valeur certains clients côté chauffeur et admin. Vous pourrez définir une couleur pour chaque client dans l'onglet répertoire. Une pastille de la couleur apparaitra à côté du nom du client (et la ligne entière change de couleur dans 'liste' sur l'admin web)", 
giveColorCustomer:"Cliquez pour donner une couleur au client", 
colorCustomer:"Couleur client", 
sendWhatsapp:"Envoyer un Whatsapp", 
addCarDriverInfo:"Cliquez sur 'Ajouter' ou définir le ou les véhicules du chauffeur afin que le client puisse avoir les informations lors de la course. Cela permet d'éviter les erreurs de véhicule ou que le chauffeur sous-traite la course sans votre accord (le client verra que le numéro d'immatriculation ne correspond pas)", 
definePriceOnSettings:"Définissez des prix avant de continuer", 
definePriceOnSettingsMoreInfo:"Afin de paramétrer cette partie, vous devez créer au moins une zone de prix dans les Réglages (Réglages > Gestion des tarifs)", 
selectOneCarMin:"Sélectionner au moins un véhicule", 
selectOneZoneMin:"Sélectionner au moins une zone", 
selectOneLangueMin:"Sélectionner au moins une langue", 
selectOption:"Selectionner les options",
selectLevel:"Selectionner le niveau de privilège", 
manageBill:"Gérer la facturation", 
manageBillMoreInfo:"La facturation s'adapte à votre mode de fonctionnement\n- 'Au nom de ma société' est idéal si ce chauffeur est salarié de la plateforme\n- 'Au nom de sa société' est adapté aux groupements de chauffeurs\n- 'Double facturation' génèrera 2 factures : l'une de la plateforme pour le client et l'autre du chauffeur pour la plateforme imputée de la commission que vous prenez", 
enterpriseInfo:"Infos de l'entreprise",
problemClientDriver:"Problème client / chauffeur ?",
problemClientDriverMoreInfo:"Pour éviter que ça se reproduise, vous pouvez créer des blacklists afin qu'il ne puisse plus tomber ensemble",
customRace:"Personnalisez votre prestation", 
noVAT:"Non soumis à TVA", 
takeCost:"Prendre en charge le cout", 
takeCostMoreInfo:"Cette option vous concerne si vous prenez des commissions sur les chauffeurs. Par défaut, quand on utilise un code promo, le cout est partagé entre la plateforme (c'est à dire vous) et le chauffeur. En activant cette option, le code promo ne coutera rien au chauffeur et vous assumerez 100% du cout",
quitRaceImmediateNextKarhoo:"Vous pouvez reprendre cette course plus tard ou signaler que le client n'est pas là et déclencher un no-show", 
yesAfter:"Oui et reprendre plus tard",
yesNoShow:"Oui et signaler un no show",

smsPrefillValidateP:"Ecrivez le texte qui pré-remplira le message et qui sera envoyé aux clients avec le lien de paiement.\nVous pourrez toujours modifier ce message.\n\nInscrivez '%NAME%' pour insérer le prénom du client, %LASTNAME% pour le nom de famille, %DATE% pour la date de la course, %START% pour l'adresse de départ, %END% pour la destination, %DRIVER% pour le nom du chauffeur et %PAYLINK% pour le lien de paiement. Attention, si l'élement n'est pas disponible (comme le prénom du client) il sera remplacé par un texte vide. Si vous utilisez %END% et qu'il s'agit en fait d'une mise à dispo, le texte 'Mise à dispo' apparaitra. Pensez-y quand vous faites vos phrases",   
smsPrefillValidatePT:"Ecrivez le texte qui pré-remplira le message et qui sera envoyé aux clients avec le lien de suivi de course et le lien de paiement.\nVous pourrez toujours modifier ce message.\n\nInscrivez '%NAME%' pour insérer le prénom du client, %LASTNAME% pour le nom de famille, %DATE% pour la date de la course, %START% pour l'adresse de départ, %END% pour la destination, %DRIVER% pour le nom du chauffeur, %TRACKLINK% pour le lien de suivi de course et %PAYLINK% pour le lien de paiement. Attention, si l'élement n'est pas disponible (comme le prénom du client) il sera remplacé par un texte vide. Si vous utilisez %END% et qu'il s'agit en fait d'une mise à dispo, le texte 'Mise à dispo' apparaitra. Pensez-y quand vous faites vos phrases",   
vehicleCPAM:"Véhicule réservé CPAM", 
vehicleCPAMMoreInfo:"En cochant cette option, vous indiquerez aux clients que ce véhicule est à destination des utilisateurs ayant une prise en charge CPAM. Le prix sera masqué côté client et un message lui indiquera qu'il devra avoir un bon de transport pour pouvoir sélectionner ce véhicule. Le chauffeur verra aussi qu'il s'agit d'une course CPAM", 
raceCPAM:"Course CPAM", 
raceCPAMMoreInfo:"Ce type de course est réservée aux personnes ayant un bon de prise en charge permettant la prise en charge par la sécurité sociale", 
stripeConnectMoreInfo:"Stripe permet aux clients de payer ses courses directement sur l’application. Par défaut, l’argent est ensuite versé sur votre compte bancaires. Cependant, si vous avez plusieurs chauffeurs indépendants, plusieurs choix s’offre à vous. Si vous sélectionner l’option 2 ou 3, vous devrez vérifier sur votre compte stripe les paramètres stripe Connect et indiquer les IBAN des chauffeurs en allant dans Répertoire > Nom du chauffeur, cliquez sur Stripe Connect dans Facturation et remplissez les informations",
stripeOnMyAccount:"Recevoir l’argent sur mon compte bancaire", 
stripeManualOnRevenus:"Payer les chauffeurs manuellement dans Revenus", 
stripeCBAuto:"Transférer l’argent des CB automatiquement", 

stripeOnMyAccountMoreInfo:"Vous recevrez l’argent sur votre compte et vous pourrez ensuite le verser quand vous voulez aux chauffeurs", 
stripeManualOnRevenusMoreInfo:"L’argent restera un certain temps sur votre compte stripe et vous pourrez verser manuellement de l’argent aux chauffeurs en allant dans l’onglet Revenus de l’application", 
stripeCBAutoMoreInfo:"A la fin de la course, le chauffeur reçoit une partie de la somme payé par le client directement sur son compte sans action de votre part. Attention, cela risque de poser des problèmes si vous avez des courses avec paiement par espèce pour récupérer votre commission", 
stripeCBAutoPrice:"Vous avez choisi de transférer l’argent automatiquement. Souhaitez-vous transférer le coût Stripe au chauffeur ? Habituellement, cette option est activé si vous ne prenez pas de commission sur les chauffeurs.",
transferFeeStripeDriver:"Transferer le coût Stripe aux chauffeurs", 
paymentAuto:"Paiement auto",
paymentAutoError:"Erreur paiement auto",
stripeWarning:"Attention, Stripe fonctionne un peu comme un compte bancaire. L’argent peut mettre un peu de temps à arriver. Veuillez à avoir suffisamment d’argent en compte afin d’éviter des erreurs de versement. N’hésitez pas à contacter l’assistance de Stripe pour en savoir plus. ", 
paymentAutoErrorWarning:"Ce genre de problème à plus de chance d'arriver avec le mode que vous avez selectionné. C'est le cas si vous voyez le message 'Erreur paiement auto' apparaitre sur certaines courses dans Revenue. ",
optionMoreInfo:"Quand les clients commandent des courses, ils ont la possibilité de choisir des options (gratuites ou payantes). Vous pouvez choisir parmi des options préfenir comme proposer un siège auto ou accepter les animaux. Vous voulez allez plus loin ? Vous pouvez également créer votre propre option en cliquant sur 'Proposer d'autres options'",
rappelMoreInfo:"Activez des rappels via des notifications push pour les chauffeurs pour différents scénarios : afin qu'ils n'oublient pas des courses ou qu'ils aient une idée du nombre de courses pour le landemain", 

transferToDriver:"Transférer de l’argent à un chauffeur", 
transferMoreInfo:"Pour ajouter un chauffeur à qui vous pouvez transférer de l'argent, c'est très simple : Il vous suffit d'aller dans Répertoire > nom du chauffeur > Créer un lien stripe connect dans la partie Facturation. Vous pourrez envoyer le lien au chauffeur ou répondre aux différentes questions à sa place", 
transferToDriverNoDriver:"Actuellement vous n'avez aucun chauffeur qui peut accepter les transferts",
transferToDriverMoney:"A quel chauffeur voulez-vous transférer de l’argent ?", 
amountTransfert:"Quelle montant voulez-vous transférer ?",

transferToDriverError:"Le problème peut venir de l'IBAN du chauffeur ou plus probablement d'un problème de fond de roulement sur votre compte Stripe. En effet, entre le moment où le client paye et l'arivée de l'argent sur votre compte Stripe, il peut y avoir un petit délai. Attention également aux versements automatique des fonds sur votre compte bancaires", 

transferOK:"Le transfert a bien été effectué", 
interconnexion:"Interconnexion", 
interconnexionMoreInfo:"Connectez votre application à d'autres systèmes. Karhoo vous propose des courses de leur partenaire comme la SNCF, booking.comm ou encore les assistances fidélia. Vous pouvez aussi travailler avec des partenaires extérieurs en leur créant un compte et en utilisant les API que nous proposons", 
customInterfaceMoreInfo:"Personnalisez l’interface de l’application en activant les options que vous souhaitez. La personnalisation de l’application est séparé en différentes sous parties : écran principal, pendant la commande, menus …",
mainScreenCustom:"Personnalisation de l’écran principal",
mainScreenCustomMoreInfo:"Gérer ici ce qui s'affiche sur l'écran principal de l'application ce que ça soit des publicités pour vos apporteurs d'affaires, de personnaliser les miniatures des véhicules ...",

gestionPOI:"Gérer les points d'intérêts",
poiMoreInfo:"C'est super important de créer des points d'intérêts comme des hotels, des restaurants, des gares ... En effet, les points d'intérêts permettent de gagner du temps aux clients et évite les erreurs ! De plus, si vous travaillez avec des hotels, des restaurants ou des bars, ça peut faire un échange de bon procédé avec eux",
finaliseOrder:"Finalisation de la commande", 
finaliseOrderMoreInfo:"Les options ci-dessous apparaissent sur l'écran au moment de la finalisation de la commande", 
favoriteDriver:"Chauffeur préféré",
favoriteDriverMoreInfo:"Vous avez choisi d'activer la possibilité pour les clients d'avoir, d'une façon ou d'une autre au chauffeur préféré. Ici, vous pouvez personnaliser la façon dont le mode 'chauffeur préféré' fonctionne", 
tarifReducMoreInfo:"Ici, vous pourrez régler les tarifs qui s'appliqueront dans l'application. A l'inverse, vous pouvez aussi définir des codes promos. A noter, que si vous avez des tarifs spécifiques pour certains clients, pas besoin de régler ici ou définir des réductions. Vous pouvez le faire en allant dans Répertoire > Nom du client > Définir des prix", 
settingsDriversMoreInfo:"Procédez ici à certains ajustement pour les chauffeurs. Pour rappel, c'est très simple d'ajouter un chauffeur. Laissez-le créer un compte sur l'application puis rendez-vous dans Répertoire > Son nom et passer-le en partenaire !", 
settingsBookMoreInfo:"Gérer ici certaines options lors de la réservation. Par exemple, vous pouvez fixer un délai pour qu'un client puisse réserver une course ou si vous souhaitez forcer le mode manuel (vous recevrez toutes les courses au lieu des chauffeurs)", 

cancelView:"Annulation",
cancelViewMoreInfo:"Gérer la façon dont les clients peuvent annuler des courses",

changeDriverRaceInProgress:"La course a déjà démarré. Si vous souhaitez tout de même changer de chauffeur, vous devez d'abord mettre fin à la course en cliquant sur 'Annuler la course en direct'", 
partnerCompanyWithCommission:"Ne pas facturer de commission si paiement par espèce",

payLink:"Lien de paiement", 
userNoDescription:"Aucun commentaire disponible",
infos:"infos",
notPaidOnBoard:"Le client ne paye pas sa course au moment de la commande mais ultérieurement. Aucun encaissement à bord ne doit être fait", 
paidCard:"Le client a payé par carte dans l'application. Aucun encaissement à bord ne doit être fait sauf si le montant de la course a été ajusté (il faudra facturer les frais supplémentaires)", 
paidOnBoardMoreInfo:"Le client a choisi de payer à bord du véhicule. Il faudra donc l'encaisser à bord à la fin de la course", 

sureCancelRaceKarhoo:"Attention, si le client vous a demandé d'annuler cette course, ce n'est pas à vous de le faire. Merci de lui dire que vous ne pouvez pas annuler cette course mais que lui peut tout à fait le faire de lui même sans passer par vous", 

raceCPAM:"Course CPAM pour",
raceStartAfter:"Cette course doit démarrer dans plus de 24h",
raceStartBefore:"Cette course aurait dû démarrer il y a plus de 24h",
raceStartABGeneral:"Si vous souhaitez démarrer cette course, merci de changer de modifier le jour de départ", 
sendCommentAdmin:"Envoyer un commentaire aux admins", 
sendCommentKarhoo:"Envoyer un commentaire à Karhoo", 
messageHasBeenSend:"Le message a bien été envoyé", 
message:"Message",
trainAtTime:"A priori à l’heure",
delayOf:"Retard prévu de",
noInfo:"Aucune information disponible", 
infoBySNCF:"Informations fournies par la SNCF", 
indicatifOnly:"A titre informatif uniquement", 
trainDeleted:"Train a priori supprimé",
paymentType:"Statut du paiement", 

karhooForAll:"Accessible à tout le monde", 
karhooOnlyGP:"Uniquement grand public",
karhooOnlyAssis:"Uniquement aux assistances",

majoZeroTwentyFive:"Majoration de 0 à 25km",
majoTwentyFiveFifty:"Majoration de 25 à 50km",
majoOneHundredFifty:"Majoration de 100 à 150km",
majoTwoHundredFifty:"Majoration de 200 à 250km",
transactionTraitement:"Traitement de la transaction en cours", 
acceptedPayment:"Paiement accepté", 
etaEstimation:"Estimation du temps d'arrivée (ETA)", 
etaEstimationMoreInfo:"Vous pouvez définir des estimations du temps d'arrivée dans le cas où vous feriez des courses dès que possible. Par exemple, en semaine, l'application informera le voyageur que le délai d'attente moyen est de 15 minutes. En revanche, le week-end, il est de 30 minutes. Vous êtes pas obligé de remplir cette partie, par défaut, l'application mettra 10 minutes", 
addETA:"un ETA",
newETA:"Nouvelle ETA", 
noShowToKarhoo:"Signaler un no-show à Karhoo", 
addNumTrain:"Ajouter un numéro de train",
addNumFlight:"Ajouter un numéro de vol",
viewPartnerForDrivers:"Afficher le nom de l'apporteur d'affaires",
viewPartnerForDriversMoreInfo:"En activant cette option, vous indiquerez aux chauffeurs qu'il s'agit d'une course provenant d'un apporteur d'affaires",
infoByFlight:"Informations fournies par AviationStack", 
terminal:"Terminal", 
gate:"Porte",
doubtRace:"Un doute sur la course ?", 
doubtRaceMoreInfo:"L'application stocke un certain nombre d'informations quand une personne commande une course que vous pourrez retrouvez ici et qui peuvent vous aider à identifier une eventuelle course frauduleuse. Si c'est le cas, ne faites pas justice vous même ! Vous pourrez confier ces informations à la police qui pourra mener une enquête", 
ipAdress:"Adresse IP",
flightDeleted:"Vol a priori supprimé",
actionNotAutorized:"Action non autorisée",
karhooNotModifyRaceDate:"Karhoo ne prend pas en charge la modification de la date de la course (y compris si le client vous le demande). Pour en savoir plus, vous pouvez contacter Karhoo", 
kmInfo:"Kilométrage", 

totalOnPeriod:"Total sur la période",
inAverage:"En moyenne",
kmLessRace:"Course la plus courte",
kmMoreRace:"Course la plus longue",
priceLessRace:"Course la moins chère",
priceMoreRace:"Course la plus chère",
caByDay:"CA par jour", 
orderFor:"Commander pour le compte de", 
orderForMoreInfo:"Quand vous ajoutez une course manuellement en tant qu'admin, vous avez l'aller dans le répertoire et de choisir le client qui fera la course. En activant cette option, un second bouton apparaitra. Il vous permettra de sélectionner un apporteur d'affaires ou un client pro et de commander à sa place en indiquant le nom du client final", 
forAccount:"pour le compte de", 
preblockAccount:"Pré-bloquer les nouveaux comptes", 
preblockAccountMoreInfo:"En activant cette option, lorsqu’un client créé un compte, son compte sera bloqué par défaut. Vous devrez le débloquer. Cette option n’est a activé que dans le cas où vous ne souhaitez pas ouvrir le service à tout le monde mais à seulement quelques personnes",
preblockAccountDefault:"Merci pour votre inscription ! Encore un peu de patience, après vérification, un administrateur vous activera votre compte",
preblockAccountMessage:"Message à afficher aux clients pré-bloqués", 
creationDate:"Date de création",
newClient:"Nouveaux clients", 
newClientOnPeriod:"Nouveau(x) client(s) sur la période",
enableOrderDriver:"Activer un rang aux chauffeurs", 
enableOrderDriverMoreInfo:"En activant cette option, vous aurez la possibilité de définir un ordre d’affectation pour les chauffeurs. Par exemple, si vous avez des salariés et des externes. Les salariés recevront en priorité les demandes de courses puis les autres. L’application contactera d’abord les chauffeurs du rang 1, 10 secondes après ceux du rang 2, 10 secondes encore après ceux du rang 3 … Par défaut, si vous ne changez pas le rang du chauffeur, il sera de rang 1. Les courses restent accessibles dès qu’on a passé le rang. Par exemple, quand on envoi les notifications pour les chauffeurs de rang 3, la course peut toujours être validé par un chauffeur de rang 1 et 2", 
selectOrderDriver:"Selectionner l'ordre du chauffeur", 
selectOrderDriverMoreInfo:"Les chauffeurs de rang 1 recevront en priorité les courses, pour ceux du rang 2, il faudra attendre 10 secondes de plus ... Cette option vous permet de préviligier certains chauffeurs par rapport à d'autres. Si vous avez activé l'option 'Chauffeur favori' celui-ci continuera d'être prioritaire sur la course, peut importe son rang", 
rank:"Rang", 
longRank:"Augmenter fortement le délai entre les chauffeurs", 
longRankMoreInfo:"Avec l'option précédente que vous avez activé, il y a un décalage de 10 secondes entre les chauffeurs de rang 1, 2, 3 ... En activant cette option, ce n'est plus 10 secondes et 5 minutes entre les chauffeurs pour les courses commmandés à l'avance (les courses dès que possible reste à 10 secondes). Cette option est recommandé si vous voulez distinguer des salariés et des prestataires extérieurs",
qrCustomerVerif:"Proposer un QR de vérification", 
qrCustomerVerifMoreInfo:"En activant cette option, le chauffeur pourra demander via l’application un QR code de vérification au client. Cette vérification peut se faire uniquement pour les clients ayant l’application directement (donc ça exclu les clients pro, les apporteurs d’affaires, les courses API et les courses ajouté par un admin ou un chauffeur). Quand cette option est possible, une fois que le chauffeur a déclaré qu’il était arrivé au point de départ, il aura la possibilité de déclencher un bouton pour faire apparaitre un QR code de vérification côté client",
doubtQuestion:"Un doute ?", 
qrCustomerVerifDoubt:"Voulez-vous faire apparaitre un QR code vérification côté voyageur ?", 
raceProblemID:"Un problème avec cette course ? Son ID est :", 
raceProblemIDNext:"(Cliquez ici pour copier l'ID que vous pourrez coller dans un mail par exemple)", 
autorizeCameraAccess:"Autoriser l'accès à la caméra", 
autorizeCameraAccessForQR:"L'accès à la caméra du téléphone est nécessaire pour lire le QR Code",
qrPosition:"Positionner le QR code dans la mire afin que l'application puisse vérifier sa validité",
validCode:"Code valide !",
deferredKarhoo:"Vous voyez ici les courses Karhoo qui ont été annulées. Selon vos CGU, toutes les courses qui apparaissent ici ne font pas facturable à Karhoo. N’hésitez pas à en rediscuter avec votre conseiller Karhoo",
removeRequest:"Annulation demandée",
warningInfos:"Attention aux infos", 
notModify:"Modifications non autorisées", 
notModifyMoreInfo:"Vous ne disposez pas d'un niveau autorisation suffisant pour effectuer une modification des informations contenues ici. Si quelque chose n'est pas correct, ne validez pas la facture. Cliquez plutôt sur le bouton pour prévenir l'administrateur", 
notModifyMoreInfoPriceInverval:"(vous pouvez modifier seulement le prix dans l'intervalle autorisé)", 

racePrice:"Prix de la course", 
racePriceMoreInfo:"Prix sans retard incluant éventuellement les options souscrites lors de la commande",
priceDriverMoreInfo:"Prix final pour le chauffeur que vous pouvez forcer pour mettre le montant souhaité", 

feePause:"Frais de pause", 
feePauseMoreInfo:"Délai d'attente pendant le parcours ou à l'arrivée (pour faire un arrêt shopping par exemple)",
feeDelayMoreInfo:"Délai entre l’arrivée du chauffeur au point de prise en charge et l’arrivée du client ", 
billAgree:"Facturation possible",

delayWhatduration:"Quelle est la durée du retard ?", 
delayPauseWhatduration:"Quelle est la durée de la pause ?", 
delaySuggestion:"Durée suggéré",
priceDelay:"Quel est le prix par minute ?", 

personnalized:"Personnalisées", 

suggestions:"Suggestions", 
pause:"Pause",

priceInThisInterval:"Le prix doit être dans cet interval : ",
alertAdminPrice:"Vous pouvez aussi alerter un admin en lui expliquant pourquoi le prix devrait être modifié (ou une autre info concernant la course)",
incorrectPrice:"Montant incorrect",
finalPriceNecessary:"Prix final nécessaire", 
modifyNow:"Modifier maintenant", 
paymentCards:"Cartes",


raceNotStartedReminder:"Rappel course imminente",
raceNotStartedReminderMoreInfo:"Si 20 minutes avant le début d'une course le chauffeur n'est pas encore parti à la rencontre du client, il recevra une notification pour lui rappeler la course", 
raceOkAfterMypos:"Vous allez être dirigé sur une page permettant de payer avec MyPOS. Votre course sera prise en compte une fois que vous aurez payé",
oneOption:"Option", 
zeroOption:"Aucune option",
freeOption:"Option gratuite", 
optionsFee:"Frais liés aux options",
optionsFeeMoreInfo:"Si le client a commandé des options payantes, le tarif se mettra à jour automatiquement",
modifyPrice:"Vous avez modifié le prix de la course",
updateDriverPrice:"Souhaitez-vous mettre à jour le prix pour le chauffeur ?",
immediateModeDefaut:"Réservation instantanée par défaut", 
immediateModeDefautMoreInfo:"En activant cette option, les utilisateurs auront par défaut le mode instantané selectionné. Ils pourront toutefois choisir une réservation à l'avance en appuyant sur 'Date et heure'",
onlyImmediate:"Désactiver la réservation à l'avance", 
onlyImmediateMoreInfo:"En activant cette option, il ne sera plus possible aux utilisateurs de commander à l'avance sur l'application mais uniquement faire des courses instantanées (ce réglage ne s'applique pas aux admins)",
signalNoShow:"Signaler un no-show",
driverPositionPrecise:"Position plus précise", 
driverPositionPreciseWarning:"Attention, cette option est déconseillée car elle consomme beaucoup plus de batterie sur les téléphones qu'en mode moins précis", 
driverPosition:"Position des chauffeurs", 
positionViewClientAdmin:"Les chauffeurs seront visibles par les clients et par les admins avec juste la position de la voiture",
positionViewClient:"Les chauffeurs seront visibles par les clients avec juste la position de la voiture",
hideOtherCarIfRaceInProgressMoreInfo:"En activant cette option, le client ne verra que son véhicule sur la carte et non les véhicules des autres chauffeurs",
lockProviderInfo:"Vous ne pouvez pas modifier cet élement car la course est en paiement différé", 
lockProviderInfoMoreInfo:"Si vous souhaitez faire une modification, vous pourrez le faire lors de l'édition de la facture différé en les modifiants préablement dans Répertoire > Nom de l'apporteur d'affaires",
initialyDecline:"Initialement refusé",
orderMultiCar:"Commander plusieurs voitures pour votre mise à disposition",
selectProviderAffaire:"Choisir le partenaire",
forceDriverPriceQuestion:"Forcer le prix chauffeur ?",
raceAlreadyPaidMoreInfo:"Cette option permet d'éviter au chauffeur de demander à encaisser cette course à bord",
roomHotel:"Numéro de chambre",
roomHotelMoreInfo:"Demander le numéro de la chambre quand on il y a un hotel comme départ ou destination", 
warningRemoveAccount:"En supprimant ce compte, vous allez empêcher la personne de pouvoir recréer un compte à partir de la même adresse mail. Etes-vous sur de vouloir continuer ?",
stripeCorrectyConf:"Stripe correctement configuré", 
stripeNotConf:"Stripe non configuré",
contactMAV:"Contactez Mon Appli VTC",
driverNotStart:"Course non démarrée",

subscription:"Abonnement", 
subscriptionStripeMoreInfo:"Stripe vous permets si vous le souhaitez de proposer des abonnements mensuels à vos clients", 
manageSubscription:"Gérer les abonnements",
noSubscription:"Aucun abonnement", 
createSubNow:"Créer un abonnement dès maintenant en cliquant sur 'Créer un abonnement' !", 
createSub:"Créer un abonnement",
newSubscription:"Nouvel abonnement",
noNameSub:"(Abonnement sans nom)",
perMonth:"Par mois",
hideSub:"Suspendre l'abonnement",
hideSubMoreInfo:"L'abonnement ne sera plus visible mais les anciens abonnés continueront à l'être",
enableSub:"Réactiver l'abonnement",
enableSubMoreInfo:"Actuellement, les clients ne peuvent plus voir cet abonnement. Vous pouvez le réactiver dès maintenant",
kmSub:"Abonnement kilométrique",
hidenSub:"Vente suspendu",
oldSub:"Ancien abonnement", 
chooseSub:"Choisir un abonnement", 
chooseSubMoreInfo:"Choissisez un abonnement mensuel et profitez de prix avantageux",
createAccountFromBook:"En cliquant sur oui, vous allez créer un compte manuel dans votre répertoire qui vous évitera de retaper les informations du clients lors d'une prochaine commande",

delayReservationMinutesMoreInfo:"Définissez un délai de réservation (en minutes) en dessous duquel le client ne pas commander une course (ce réglage ne s'applique pas aux admins)",

disableCancelButtonMoreInfo:"Dans leur onglet 'Mes Courses', les clients peuvent demander l'annulation d'une course. En activant cette option, ils ne pourront plus",
days:"Jours",
delayRaceMax:"Délai de réservation max",
delayRaceMaxMoreInfo:"Vous pourrez définir un nombre de jours maximal dans le futur où les clients peuvent réserver. Par exemple, un client peut réserver jusqu'à 30 jours avant le voyage. Par défaut, le nombre de jour est illimité", 
delayRaceMaxFixed:"Fixer le nombre de jours maximal autorisé pour la réservation à l'avance (ce paramètre ne s'applique pas aux admins)",

userMaxRace:"Réservation max par client",
userMaxRaceMoreInfo:"Vous pouvez fixer un nombre de réservation max par client afin d’éviter qu’un client commande trop de course à l’avance",
userMaxRaceFixed:"Fixer le nombre de courses maximal autorisé par client",
userMaxRaceSorry:"Désolé mais vous avez atteint le nombre maximal de réservation", 
promoCodeMoreInfo:"Réalisez des opérations marketing réussi et/ou remerciez vos plus fidèles clients avec des codes promos. Les codes promos peuvent être sous la forme d’un pourcentage de réduction ou sur un montant à déduire",
yourPromoCode:"Vos codes promos",
videoTuto:"Consultez notre tuto vidéo",
seeBlog:"Consultez notre article de blog",
select:"Selectionner",
yourForfaitZone:"Vos forfaits et vos zones",
yourForfaitZoneMoreInfo:"La gestion de vos tarifs est une étape importante. Ici, vous pourrez gérer les tarifs qui s’appliqueront à la l’ensemble de vos clients. Vous avez le choix entre forfait et zone. Le forfait est un prix fixe d’un point A et à un point B (par exemple d’un aéroport à un centre ville). A l’inverse, une zone dépendra du kilométrage et du temps de la course. L’application commence par tester les forfaits pour voir si ça correspond à la demande du client. Si ce n’est pas le cas, l’application testera les zones de la plus petite à la plus grande. Ainsi, vous pourrez faire des tarifs différents d’une ville à l’autre par exemple et aussi d’un quartier par rapport à un autre", 
customTarifCustomer:"Tarifs négociés pour certains clients ?",
createZoneForfait:"Vous allez créer une zone ou un forfait", 
createZoneForfaitMoreInfo:"Choisissez forfait si vous souhaitez créer un prix fixe d'un point A et à un point B. Choisissez zone pour définir un prix de prise en charge + un prix par minute + un prix par kilomètre",
createZoneForfaitMoreInfoTaxi:"Vous pourrez également créer des zones et forfait taxi. Pour les zones taxis, vous pourrez rentrer les tarifs A,B,C et D",
nlicence:"Numéro de licence", 
nads:"Numéro ADS",
chooseDriver:"Choisir un chauffeur",
addDriver:"Ajouter un chauffeur",
cityADS:"Ville de l'ADS",
restartPause:"Reprendre la course", 
yesPause:"Oui et mettre en pause", 
selectSituation:"Choisissez en fonction de votre situation",

helpRAZRace:"Vous pourrez recommencer la course plus tard à zéro en allant dans planning. Le suivi de course disparait côté client",
helpPauseRace:"Votre client a un gros retard ? Mettez cette course en pause le temps d'en faire une autre et vous pourrez la reprendre plus tard à la même étape. Le suivi de course reste fixer à la dernière étape côté client",
helpNoShowRace:"Le client ne viendra pas et il faut signaler un no-show",
driverStatus:"Recevoir le statut du chauffeur",
driverStatusMoreInfo:"Vous recevrez une notification push quand un chauffeur passera en ligne ou hors ligne", 
addButtonCancelMain:"Ajouter un bouton 'Annuler' pendant la course", 
addButtonCancelMainMoreInfo:"L'utilisateur verra ce bouton pendant la recherche du chauffeur et pendant l'arrivée du chauffeur (des frais d'annulation pourront toutefois être facturé selon vos CGU",
trajetBird:"Trajet jusqu'au point de départ",
trajetBirdMoreInfo:"Si le voyageur est trop loin d'une point de départ, l'application affichera le trajet à vol d'oiseau jusqu'au point de départ", 
unknown:"Inconnu",
unknownF:"Inconnue",
destinationUnknown:"Destination inconnue",
confirmAttributedRace:"Confirmer une course attribuée", 
confirmAttributedRaceMoreInfo:"Lorsque qu’un admin / gestionnaire attribue une course à un chauffeur, celui-ci reçoit une notification pour le prévenir. En activant cette option, il y a aura en plus un pop-up dans l’application. L'admin / le gestionnaire pourra voir que la course a été vu", 
yourAreWaitConfirmRace:"Vous avez une course attribuée",
yourAreWaitConfirmRaces:"Vous avez des courses attribuées", 
seeByDriver:"Vu par le chauffeur", 
alertModif:"Signaler la modification",
alertModifMessage:"Votre message pour le chauffeur", 
alertModifMessageMoreInfo:"Vous pouvez par exemple lui expliquer pourquoi vous avez fait la modification",

modifyDraw:"Modifier le dessin de la zone",
modifyRay:"Modifier le rayon d’action",
defineRay:"Définir un rayon d’action",
defineDraw:"Dessiner une zone",
rayInKM:"Rayon en kilomètres",
rayOrDraw:"Rayon d'action ou zone dessiné ?",
rayOrDrawMoreInfo:"Définir un rayon d'action est bien plus simple. Vous mettrez par exemple 10 km autour d'une adresse. Mais la zone dessiné vous permet d'être bien plus précis. Par exemple, vous pouvez faire la distintion précise d'un quartier par rapport à un autre. Le choix que vous allez faire ici dépendra donc de la nécessité d'avoir de la précision ou non",
clientChoice:"Au choix du client",
zoneHelp:"Vous avez choisi de créer une zone. Le prix de la course ne dépendra pas la destination mais donc uniquement du point de départ. Si vous souhaitez mettre des tarifs fixes en fonction du départ et de la destination (exemple : entre un aéroport et un centre ville), vous devrez créer un forfait à la place", 
priorizationDriver:"Priorisation des chauffeurs",
priorizationDriverMoreInfo:"Afin de permettre aux chauffeurs qui attendent depuis longtemps en station d'avoir les meilleurs courses, vous devez définir 2 zones. Celle d'entrée dans la station et celle où on considère que le chauffeur est sortie", 
zoneChoice:"Choix de la zone de travail", 
zoneChoiceMoreInfoForfait:"Vous devrez indiquer ici le point de départ et la destination que vous voulez que ce forfait couvre. Evitez de faire des zones trop petites qui risque de ne jamais correspondre à une demande client",
zoneChoiceMoreInfoZone:"Vous devez indiquer la zone de couverture qui sera couverte par ces prix. Vous pouvez créer des zones dans des zones, par défaut, l'application choisira la zone la plus petite. N'hésitez pas à faire une grande zone (à l'échelle d'un canton, d'une région ...) qui correspondra à votre zone de couverture maximale",
enterZone:"Zone d'entrée",
selectZone:"Selectionner la zone", 
quitZone:"Zone de sortie", 

};