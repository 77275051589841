/*
import DeviceInfo from 'react-native-device-info';
export default DeviceInfo;

*/


export default {
  getUniqueId: function() {
    return "demo";
  },
}