import React, { Component } from 'react';
import {
  Text,
  View,
  TouchableHighlight,
  Dimensions,
  TextInput,
  ScrollView,
  ActivityIndicator,
  Platform,
  Image,
  Linking,
  Alert,
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';

import firebase from './firebase';
import { getAuth, } from "firebase/auth";
import MavTextInput from './mavTextInput';
import MavButton from './mavButton';
import MavAlert from './mavAlert';
import { getDatabase, ref, set, update, remove, get, onValue, child, orderByChild, orderByValue, query,startAt, endAt } from "firebase/database";

 import MavSelector from './mavSelector';
 import MavNavigationMinimize from './mavNavigationMinimize';
 import MavViewMore from './mavViewMore';
 import countryList from '../countryList';
const timer = require('react-native-timer');
import CommonActions from "./CommonActions";

export default class contact extends Component {
constructor(props) {
    super(props);
    var params=(this.props.route!=undefined)?this.props.route.params:this.props.params; 


    if(Platform.OS!="web") {this.props.navigation.setOptions({ title:(params!=undefined && params.requestModif!=undefined)?strings.modif:strings.contact   }) }

    if(params!=undefined && params.requestModif!=undefined && params.requestModif!=false ){
      if(params.requestModif.racePlaceStartName!=undefined) params.requestModif.racePlaceStartName=params.requestModif.racePlaceStartName.replace(/, /gi, '\n')
      if(params.requestModif.racePlaceEndName!=undefined) params.requestModif.racePlaceEndName=params.requestModif.racePlaceEndName.replace(/, /gi, '\n')
      if(params.requestModif.stopList!=undefined){
          var i;
          for(i=0;i<params.requestModif.stopList.length;i++) params.requestModif.stopList[i]=params.requestModif.stopList[i].replace(/, /gi, '\n')
      }

        

    }
    var companyPhoneList=[];
    if(info.otherCompanyPhone!=undefined){
      
      companyPhoneList.push({country:this.findPhoneIID(info.countryCode), phone:info.companyPhone})
      var i;
      for(i=0;i<info.otherCompanyPhone.length;i++){
          companyPhoneList.push({country:this.findPhoneIID(info.otherCompanyPhone[i].id), phone:info.otherCompanyPhone[i].phone})
      }

    }


    this.state = {
      waiting:false,
      viewPhone:false,
      description:(params!=undefined && params.description!=undefined)?params.description:"", 
      cancel:(params!=undefined && params.cancel===true)?true:false, 
      requestModif:(params!=undefined && params.requestModif!=undefined)?params.requestModif:false, 
      hidePhoneApp:(params!=undefined && params.hidePhoneApp!=undefined)?params.hidePhoneApp:false, 
      id:(params!=undefined && params.id!=undefined)?params.id:"",
      modifWait:{},
      phoneFavoriteDriver:undefined,
      params:params, 
      companyPhoneList:companyPhoneList,
    };



    
  }
  
    componentDidMount(){ 
    console.log("contact mount")
  }

   componentWillUnmount(){

      if(this.state.waitingSendMessage==true) timer.clearTimeout("sendMessage"); 

      

  }

  findPhoneIID(id){
    var final="";
    var i,j;
    var n;
    var list=Object.keys(countryList);
    for(i=0;i<list.length;i++){
      if(final==""){
          var testList=countryList[list[i]].list;
          for(j=0;j<testList.length;j++){ 
            if(testList[j].id==id) final=testList[j]
          }
      }
    }
    if(final=="") return {id:"fr",code:"+33"}
    else return {id:final.id,idd:final.idd, flag:final.flag}
    
  }

sendMessage(){
          this.setState({waiting:true, waitingSendMessage:true}) 
            if(this.state.description.length>0){
              getAuth(firebase).currentUser.getIdToken().then(token => {

                var body={
                userID:getAuth(firebase).currentUser.uid,
                bundleIdentifer:info.bundleIdentifer,
                message:this.state.description,
                token:token,
                title:"Nouveau message",
                bundleAdress:info.firebaseProjectID,
            }
            if(this.state.id!=undefined && this.state.id!=""){
              body.raceID=this.state.id; 
              if(this.state.cancel==true) {body.cancel=true;body.title="Demande d'annulation"}
            }

            if(this.state.params!=undefined && this.state.params.contactFavoriteDriver==true && this.state.params.favoriteDriver!=undefined && this.state.params.favoriteDriver.id!=-1) body.sendTo=this.state.params.favoriteDriver.id; 

            console.log(JSON.stringify(body))
            fetch("https://us-central1-"+info.firebaseProjectID+".cloudfunctions.net/sendMessageToAdmin", {
                  body:JSON.stringify(body),
                  method: "POST"})

          }).then(response => {
            console.log(response)
            this.setState({waiting:false, waitingSendMessage:false}) 
            this.alert.onAlert({title:(this.state.cancel==true)?strings.cancelAnnulationSend:strings.thanksWeWillReceiveAnswer,buttons:[{text:strings.ok,onPress: () => {
              if(Platform.OS=="web") this.state.params.resetNavigation(); 
              else if(this.state.cancel==true) this.props.navigation.dispatch(CommonActions.reset({index: 0,routes: [{ name: 'Main' },],}) );
              else this.props.navigation.goBack();



            }}],

            });

          }).catch((err) => { console.log("error sendMessage"); console.log(err); timer.setTimeout("sendMessage", () => { this.sendMessage();  }, 2000);   });
        
        
        

          
          
          
        }
       else {this.setState({waiting:false}); this.alert.onAlert({title:strings.thankToWriteMessage});} 

          }


  sendModification(){
      var modif=JSON.parse(JSON.stringify(this.state.modifWait));
      var i; 

      if(this.state.modifWait.racePlaceStartName!=undefined) modif.racePlaceStartName=modif.racePlaceStartName.replace(/\n/gi, ', ')
      if(this.state.modifWait.racePlaceEndName!=undefined) modif.racePlaceEndName=modif.racePlaceEndName.replace(/\n/gi, ', ')

      if(this.state.modifWait.stopList!=undefined){
          var removeStopList=true;
          for(i=0;i<this.state.modifWait.stopList.length;i++){
            if(this.state.modifWait.stopList[i]!=undefined) {
              removeStopList=false;
              if(this.state.modifWait.stopList[i]!="removed") modif.stopList[i]=modif.stopList[i].replace(/\n/gi, ', ')

            }
          }
          if(removeStopList==true) delete modif.stopList;
      }
      if(this.state.modifWait.passagersList!=undefined){
        var removePassagersList=true;
        for(i=0;i<this.state.modifWait.passagersList.length;i++){
            if(this.state.modifWait.passagersList[i]=="removed") removePassagersList=false;
            else {
              if(this.state.modifWait.passagersList[i].name!=undefined) removePassagersList=false;
              else delete modif.passagersList[i].name
              if(this.state.modifWait.passagersList[i].lastName!=undefined) removePassagersList=false;
              else delete modif.passagersList[i].lastName
            }
        }
        if(removePassagersList==true) delete modif.passagersList;


      }

      if(this.state.description!="") modif.description=this.state.description;


      var raceDate=new Date(this.state.requestModif.raceDate)

      if(this.state.modifWait.date!=undefined || this.state.modifWait.hour!=undefined){
        delete modif.date
        delete modif.dateJS;
        delete modif.hour

        if(this.state.modifWait.date!=undefined){
          var date=new Date(this.state.modifWait.dateJS); 
          raceDate.setMonth(date.getMonth())
          raceDate.setFullYear(date.getFullYear())
          raceDate.setDate(date.getDate())
        }

        if(this.state.modifWait.hour!=undefined){
            var hour=this.state.modifWait.hour[0]+this.state.modifWait.hour[1];
            raceDate.setHours(hour)
            var minutes=this.state.modifWait.hour[3]+this.state.modifWait.hour[4];
            console.log(hour)
            raceDate.setMinutes(minutes)
        }


        modif.raceDate=Date.parse(raceDate);

      }

      if(Object.keys(modif).length==0) this.alert.onAlert({title:strings.thanksModify})
      else {
        modif.userID=getAuth(firebase).currentUser.uid;
        
        const fireInfo={} 
        fireInfo['/modifyRace/'+this.state.id]=modif
        update(ref(getDatabase(firebase)), fireInfo)  

        this.alert.onAlert({title:strings.thanksWeWillReceiveAnswer,buttons:[{text:strings.ok,onPress: () => {
            if(Platform.OS=="web") this.state.params.resetNavigation(); 
            else this.props.navigation.dispatch(CommonActions.reset({index: 0,routes: [{ name: 'Main' },],}) );
          }}],});



      }



      


  }
    
    
    
  render() {
    return (

      <StyleContext.Consumer>{style => 
      <ScrollView style={{flex:1,backgroundColor:style.backgroundColor}}>

        {(Platform.OS=="web") && <MavNavigationMinimize clickLeft={() => { this.state.params.goBack();  }} left={strings.return} title={ strings.contact } />}



        

        <Text style={{textAlign: 'center', fontSize: style.textInputFontSize* ( (this.state.params!=undefined && this.state.params.contactFavoriteDriver==true && this.state.params.favoriteDriver!=undefined && this.state.params.favoriteDriver.id!=-1)?1.2:1 ) , marginTop:20 ,marginLeft:"10%", marginRight:"10%",color: style.textInputColor }}>{(this.state.params!=undefined && this.state.params.contactFavoriteDriver==true && this.state.params.favoriteDriver!=undefined && this.state.params.favoriteDriver.id!=-1)?(strings.youWillContact+" : "+this.state.params.favoriteDriver.text ):( (this.state.cancel===true)?strings.contactCancelRace:(  (this.state.requestModif!=false)?strings.whatChange:strings.contactTxt) )}</Text>
        {(this.state.params!=undefined && this.state.params.contactFavoriteDriver==true && this.state.params.favoriteDriver!=undefined && this.state.params.favoriteDriver.id!=-1)  && <TouchableHighlight style={{ }} underlayColor={"#FFFFFF00"} onPress={() => {  var params=this.state.params; params.contactFavoriteDriver=false; this.setState({params:params})   }}>
              <Text style={{textAlign: 'center', textDecorationLine:"underline",fontSize: style.textInputFontSize,marginLeft:"10%", marginRight:"10%",color: style.textInputColor }}>{strings.preferContactPlatform}</Text>
          </TouchableHighlight>}

        {(this.state.cancel!=true && this.state.requestModif==false && this.state.hidePhoneApp!=true) && <MavSelector style={{marginBottom:20}} selected={(this.state.viewPhone==false)?0:1 } textLeft={strings.email} textRight={strings.phone} onChange={(value)=>{ 

          if(this.state.params!=undefined && this.state.params.contactFavoriteDriver==true && this.state.params.favoriteDriver!=undefined && this.state.params.favoriteDriver.id!=-1 && this.state.phoneFavoriteDriver==undefined && this.state.viewPhone==false){
              
              get(child(ref(getDatabase(firebase)), '/users/'+this.state.params.favoriteDriver.id+"/phone")).then(value => {
                  var val=value.val();
                  if(val!=null){
                    if(val[0]=="+" && val[1]==3 && val[2]==3 && val[3]==0) val="+33"+val.slice(4)
                    else if (val[0]==0 && info.countryCode=="fr") val="+33"+val.slice(1);
                  }

                  this.setState({viewPhone:!this.state.viewPhone, phoneFavoriteDriver:(val!=null)?val:"--"})

              }); 
          }
          else this.setState({viewPhone:!this.state.viewPhone})
        }}/>}










        {this.state.requestModif!=false && <View>

            <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { this.alert.onAlert({title:strings.date, subTitle:strings.newValue, textInput:{text:"",placeholder:"00/00/0000", typeDate:true,keyboardType:"numeric"}, buttons: [{text:strings.ok,onPress: (value) => { 
            
                if(value.length==10 && parseInt(value[0]+value[1])<32 && value[2]=="/" && parseInt(value[3]+value[4])<13 && value[5]=="/" ){
                    var actualDate=new Date()
                    var takeDate=new Date()
                    takeDate.setFullYear(value[6]+value[7]+value[8]+value[9])
                    var month=value[3]+value[4]
                    month-=1;
                    takeDate.setMonth(month)
                    takeDate.setDate(value[0]+value[1])
                    if(isNaN(takeDate)!=true && takeDate>=actualDate){var modifWait=this.state.modifWait;modifWait.date=value; modifWait.dateJS=takeDate; if(modifWait.date==this.state.requestModif.date) {delete modifWait.date; delete modifWait.dateJS }; this.setState({modifWait:modifWait}) }
                    else this.alert.onAlert({title:strings.errorHour,})
                }
                else if(value!="") this.alert.onAlert({title:strings.errorHour,})

             }},{text: strings.cancel, style:"cancel",onPress: () => {}}]      }) }} >
                  <MavTextInput textInputColor={(this.state.modifWait.date!=undefined)?style.colorWaiting:undefined} style={{marginTop:5}} disabledText={true} textRight={(this.state.modifWait.date!=undefined)?this.state.modifWait.date:this.state.requestModif.date} textExplication={strings.date} />
            </TouchableHighlight>

            <TouchableHighlight underlayColor={"#FFFFFF00"} onPress={() => { this.alert.onAlert({title:strings.hour, subTitle:strings.newValue, textInput:{text:"",placeholder:"00:00", typeHour:true,keyboardType:"numeric"}, buttons: [{text:strings.ok,onPress: (value) => { 
                if(value.length==5 && parseInt(value[0]+value[1])<24 && value[2]==":" && parseInt(value[3]+value[4])<60 ) {var modifWait=this.state.modifWait;modifWait.hour=value; if(modifWait.hour==this.state.requestModif.hour) delete modifWait.hour; this.setState({modifWait:modifWait}) }
                else if(value!="") this.alert.onAlert({title:strings.errorHour,})
             }},{text: strings.cancel, style:"cancel",onPress: () => {}}]      }) }} >
                  <MavTextInput style={{marginTop:5}} textInputColor={(this.state.modifWait.hour!=undefined)?style.colorWaiting:undefined}  disabledText={true} textRight={(this.state.modifWait.hour!=undefined)?this.state.modifWait.hour:this.state.requestModif.hour} textExplication={strings.hour} />
            </TouchableHighlight>




            <MavViewMore text={ strings.trip } iconClassic={(style.mode=="dark")?require('../assets/route_light.png'):require('../assets/route_dark.png')} >



                    <MavTextInput style={{marginTop:5}} backgroundColor={style.backgroundColorContrast } textInputColor={(this.state.modifWait.racePlaceStartName!=undefined)?style.colorWaiting:undefined} multiline={true}  text={(this.state.modifWait.racePlaceStartName!=undefined)?this.state.modifWait.racePlaceStartName:this.state.requestModif.racePlaceStartName} textExplication={strings.start} onChangeText={(value)=>{var modifWait=this.state.modifWait;modifWait.racePlaceStartName=value; if(modifWait.racePlaceStartName==this.state.requestModif.racePlaceStartName) delete modifWait.racePlaceStartName; this.setState({modifWait:modifWait});}}/>
              
                    {this.state.requestModif.stopList!=undefined && this.state.requestModif.stopList.map((rowData,id)=>( 

                        <MavTextInput backgroundColor={style.backgroundColorContrast } style={{marginTop:5}} strike={(this.state.modifWait.stopList!=undefined && this.state.modifWait.stopList[id]=="removed")?true:false}  editable={(this.state.modifWait.stopList!=undefined && this.state.modifWait.stopList[id]=="removed")?false:true}   enableRemove={(this.state.modifWait.stopList!=undefined && this.state.modifWait.stopList[id]=="removed")?false:true} onRemove={(value)=>{ var modifWait=this.state.modifWait; if(modifWait.stopList==undefined) modifWait.stopList=new Array(); modifWait.stopList[id]="removed"; this.setState({modifWait:modifWait});  }}   textInputColor={(this.state.modifWait.stopList!=undefined && this.state.modifWait.stopList[id]!=undefined)?( (this.state.modifWait.stopList[id]!="removed")?style.colorWaiting:style.colorNotification):undefined} multiline={true}  text={(this.state.modifWait.stopList!=undefined && this.state.modifWait.stopList[id]!=undefined && this.state.modifWait.stopList[id]!="removed")?this.state.modifWait.stopList[id]:rowData} textExplication={strings.onlyStop} onChangeText={(value)=>{var modifWait=this.state.modifWait; if(modifWait.stopList==undefined) modifWait.stopList=new Array(); modifWait.stopList[id]=value; if(modifWait.stopList[id]==rowData) modifWait.stopList[id]=undefined; this.setState({modifWait:modifWait});}}/>

                    ))}

                    {(this.state.requestModif.racePlaceEndName!=undefined) && <MavTextInput backgroundColor={style.backgroundColorContrast } disableLine={true} style={{marginTop:5}} textInputColor={(this.state.modifWait.racePlaceEndName!=undefined)?style.colorWaiting:undefined} multiline={true}  text={(this.state.modifWait.racePlaceEndName!=undefined)?this.state.modifWait.racePlaceEndName:this.state.requestModif.racePlaceEndName} textExplication={strings.destination} onChangeText={(value)=>{var modifWait=this.state.modifWait;modifWait.racePlaceEndName=value; if(modifWait.racePlaceEndName==this.state.requestModif.racePlaceEndName) delete modifWait.racePlaceEndName; this.setState({modifWait:modifWait});}}/>}

          </MavViewMore>




            {(this.state.requestModif.providerID!=undefined || this.state.requestModif.customerPro==true) && <View>

              <MavViewMore text={ strings.principalCustomer } iconClassic={(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png')} >

                    <MavTextInput style={{marginTop:15}} textInputFlex={2}  textInputColor={(this.state.modifWait.clientName!=undefined)?style.colorWaiting:undefined} text={(this.state.modifWait.clientName!=undefined)?this.state.modifWait.clientName:this.state.requestModif.clientName} backgroundColor={style.backgroundColorContrast } textExplication={strings.name}  placeholder={"Jean"} onChangeText={(value)=>{var modifWait=this.state.modifWait;modifWait.clientName=value; if(modifWait.clientName==this.state.requestModif.clientName) delete modifWait.clientName; this.setState({modifWait:modifWait}) }}/>

                    <MavTextInput style={{marginTop:5}} textInputFlex={2} textInputColor={(this.state.modifWait.clientLastName!=undefined)?style.colorWaiting:undefined} text={(this.state.modifWait.clientLastName!=undefined)?this.state.modifWait.clientLastName:this.state.requestModif.clientLastName} backgroundColor={style.backgroundColorContrast } textExplication={strings.lastName} disableLine={!(this.state.clientEnterprise!=undefined && this.state.clientEnterprise!="" && this.state.clientNote!=undefined)} placeholder={"Martin"} onChangeText={(value)=>{ var modifWait=this.state.modifWait;modifWait.clientLastName=value; if(modifWait.clientLastName==this.state.requestModif.clientLastName) delete modifWait.clientLastName; this.setState({modifWait:modifWait}) }}/>  
              
              </MavViewMore>


              {this.state.requestModif.passagersList!=undefined && this.state.requestModif.passagersList.map((rowData,id)=>( 

                   <MavViewMore text={ strings.traveler+" n°"+(id+2) } iconClassic={(style.mode=="dark")?require('../assets/user_white.png'):require('../assets/user_black.png')} >

                    <MavTextInput style={{marginTop:15}} textInputFlex={2} strike={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]=="removed")?true:false}  enableRemove={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]=="removed")?false:true}  editable={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]=="removed")?false:true}    onRemove={(value)=>{ var modifWait=this.state.modifWait; if(modifWait.passagersList==undefined) modifWait.passagersList=new Array(); modifWait.passagersList[id]="removed"; this.setState({modifWait:modifWait});  }}     textInputColor={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]!=undefined && this.state.modifWait.passagersList[id])?((this.state.modifWait.passagersList[id].name!=undefined)?style.colorWaiting:( (this.state.modifWait.passagersList[id]=="removed")?style.colorNotification:undefined)):undefined} text={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]!=undefined && this.state.modifWait.passagersList[id].name!=undefined)?this.state.modifWait.passagersList[id].name:rowData.name} backgroundColor={style.backgroundColorContrast } textExplication={strings.name}  placeholder={"Jean"} onChangeText={(value)=>{ var modifWait=this.state.modifWait;if(modifWait.passagersList==undefined) modifWait.passagersList=new Array(); if(modifWait.passagersList[id]==undefined) modifWait.passagersList[id]=new Object();modifWait.passagersList[id].name=value; if(modifWait.passagersList[id].name==this.state.requestModif.passagersList[id].name) modifWait.passagersList[id].name=undefined; this.setState({modifWait:modifWait}) }}/>
                    <MavTextInput style={{marginTop:5}} textInputFlex={2} strike={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]=="removed")?true:false} disableLine={true}  editable={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]=="removed")?false:true} textInputColor={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]!=undefined && this.state.modifWait.passagersList[id])?((this.state.modifWait.passagersList[id].lastName!=undefined)?style.colorWaiting:( (this.state.modifWait.passagersList[id]=="removed")?style.colorNotification:undefined)):undefined} text={(this.state.modifWait.passagersList!=undefined && this.state.modifWait.passagersList[id]!=undefined && this.state.modifWait.passagersList[id].lastName!=undefined)?this.state.modifWait.passagersList[id].lastName:rowData.lastName} backgroundColor={style.backgroundColorContrast } textExplication={strings.lastName}  placeholder={"Jean"} onChangeText={(value)=>{ var modifWait=this.state.modifWait;if(modifWait.passagersList==undefined) modifWait.passagersList=new Array(); if(modifWait.passagersList[id]==undefined) modifWait.passagersList[id]=new Object();modifWait.passagersList[id].lastName=value; if(modifWait.passagersList[id].lastName==this.state.requestModif.passagersList[id].lastName) modifWait.passagersList[id].lastName=undefined; this.setState({modifWait:modifWait}) }}/>

                    
                   
              </MavViewMore>

                ))}

              




            </View>}








        </View>}

        {(this.state.viewPhone==true && (info.otherCompanyPhone==undefined || (this.state.params!=undefined && this.state.params.contactFavoriteDriver==true) ) ) && <Text style={{ textAlign: 'center', fontSize: style.textInputFontSize*2.5, marginTop:20 ,marginLeft:"10%", marginRight:"10%" }}>{(this.state.params!=undefined && this.state.params.contactFavoriteDriver==true)?this.state.phoneFavoriteDriver:info.companyPhone}</Text>}

        {(this.state.viewPhone==true && info.otherCompanyPhone==undefined ) && <MavButton text={strings.contact} style={{marginTop:30,marginBottom:Dimensions.get('window').height * 0.05}} onPress={()=>{
            var tel=info.companyPhone.replace(/[ \\*-]/g,"")
            if(this.state.params!=undefined && this.state.params.contactFavoriteDriver==true && this.state.params.favoriteDriver!=undefined && this.state.params.favoriteDriver.id!=-1) tel=this.state.phoneFavoriteDriver;
            Linking.openURL("tel:"+tel);
         }} /> }


         {(this.state.viewPhone==true && info.otherCompanyPhone!=undefined && !(this.state.params!=undefined && this.state.params.contactFavoriteDriver==true)  ) && this.state.companyPhoneList.map((rowData,id)=>( 
              <TouchableHighlight style={{ }} underlayColor={"#FFFFFF00"} onPress={() => { Linking.openURL("tel:"+rowData.phone); }}>
                  <View style={{flexDirection:"row",marginLeft:Dimensions.get('window').width*style.textInputMarginLeft, alignItems:"center",marginTop:20 , }}>
                      <Image source={rowData.country.flag} style={{width: 50, height: 30, resizeMode:"contain", }}/>
                      <Text style={{ textAlign: 'center', fontSize: style.textInputFontSize*2, marginLeft:15,marginRight:Dimensions.get('window').width*style.textInputMarginLeft,color: style.textInputColor }}>{rowData.phone}</Text>
                  </View>
              </TouchableHighlight>
          ))}







        {this.state.viewPhone==false && <MavTextInput style={{marginTop:5}} text={this.state.description} multiline={true} textExplication={strings.yourRemark} placeholder={"Bonjour !"} onChangeText={(text)=>{this.setState({description:text})}}/>}


          {(this.state.waiting==false && this.state.viewPhone==false && this.state.requestModif==false) && <View style={{alignItems:"center",justifyContent:"center", marginTop:30,marginBottom:30}}> 
              <MavButton text={strings.send} onlyButton={true} onPress={()=>{ this.sendMessage()}}/>
          </View>}


          {(this.state.waiting==false && this.state.viewPhone==false && this.state.requestModif!=false) && <View style={{alignItems:"center",justifyContent:"center", marginTop:30,marginBottom:30}}>  
              <MavButton text={strings.validate} onlyButton={true} onPress={()=>{ this.sendModification() }}/> 
          </View>}



          {this.state.waiting==true && <ActivityIndicator style={{marginTop:30}} size="large" color={style.color1} />}
          
          

          <MavAlert ref={alert => {this.alert = alert;}}/>
       
       
       
      
       
       
       

      </ScrollView>
      }</StyleContext.Consumer>
    );
  }
}

contact.navigationOptions = ({ navigation, screenProps }) => {
  return {
    headerTitle: strings.contactUs,
    headerTintColor: screenProps.headerColor,
    headerStyle: {
      backgroundColor: screenProps.headerBackgroundColor,
    },
  };
};
