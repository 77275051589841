import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  ScrollView,
  Animated,
  ActivityIndicator,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';


const timer = require('react-native-timer');
import MavButton from './mavButton';
import MavMiniButton from './mavMiniButton';
import Trajet from './trajet';
export default class RaceTrajetInfo extends Component {

  constructor(props) {
    super(props);  
    this.state={
          viewFrom:false,
          viewAddStop:false,
          viewStopList:[false, false, false, false, false, false, false, false, false, false, false, false],
    }


  }

  render() {
   const {rowData, active} = this.props;
   var i; 

   var racePlaceStartName=(this.props.racePlaceStartName!=undefined)?this.props.racePlaceStartName.split(", ") :[]; 
   if(racePlaceStartName[0]!=undefined && racePlaceStartName[0].length>30) racePlaceStartName[0]=racePlaceStartName[0].substring(0,30)+"..."

   var racePlaceStartNameList=undefined;
   if(racePlaceStartName.length>1){
    racePlaceStartNameList="";
    for(i=1; i<racePlaceStartName.length; i++){
      racePlaceStartNameList+=((i>1)?" - ":"")+racePlaceStartName[i];
    }
    racePlaceStartNameList=(racePlaceStartNameList.length>55)? (racePlaceStartNameList.substring(0,55)+"..."):racePlaceStartNameList;
   }


   var racePlaceEndName=(this.props.racePlaceEndName!=undefined)?this.props.racePlaceEndName.split(", ") :[]; 
   if(racePlaceEndName[0]!=undefined && racePlaceEndName[0].length>30) racePlaceEndName[0]=racePlaceEndName[0].substring(0,30)+"..."
   var racePlaceEndNameList=undefined;
   if(racePlaceEndName.length>1){
    racePlaceEndNameList="";
    for(i=1; i<racePlaceEndName.length; i++){
      racePlaceEndNameList+=((i>1)?" - ":"")+racePlaceEndName[i];
    }
    racePlaceEndNameList=(racePlaceEndNameList.length>55)? (racePlaceEndNameList.substring(0,55)+"..."):racePlaceEndNameList;
   }

   

   var hourDestination=(this.props.racePlaceStartName!=undefined)?( ( (new Date(this.props.raceDate+this.props.estimateTimeValue*1000).getHours()<10)?"0":"" ) +new Date(this.props.raceDate+this.props.estimateTimeValue*1000).getHours()+":"+( (new Date(this.props.raceDate+this.props.estimateTimeValue*1000).getMinutes()<10)?"0":"" )+new Date(this.props.raceDate+this.props.estimateTimeValue*1000).getMinutes()  ):""



   var stopList=[];
   var addStopColor="#9A9A9A"; 
   var i; 
   if(this.props.stopList!=undefined && this.props.stopList!="") for(i=0; i<this.props.stopList.length;i++){
      stopList.push(this.props.stopList[i].split(", "))
   }

    return (
      <StyleContext.Consumer>{style => 
        <View style={{}}>

        <View style={{justifyContent: 'space-between',flexDirection: 'row',}}>
                        <View style={{flexDirection: 'row',maxWidth:(this.props.oldRacePlaceStartName!=undefined)?"60%":"85%",}}>
                          <View style={{ marginRight:10,}}>
                              <View style={{width:20, height:20,borderRadius:10, backgroundColor:(this.props.color!=undefined)?this.props.color:style.color1 }}/>
                              <View style={{width:2, height:15, backgroundColor:(this.props.color!=undefined)?this.props.color:style.color1, marginLeft:9 }}/>
                              <View style={{width:2, height:15, backgroundColor:(this.props.addStopAutorization==true && (stopList.length==0) && this.state.viewAddStop==false && this.props.racePlaceEndName!=undefined)?addStopColor:((this.props.color!=undefined)?this.props.color:style.color1), marginLeft:9 }}/>
                          </View>

                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.props.disable!=true){if(this.props.clickOnFrom!=undefined) this.props.clickOnFrom(); else this.setState({viewFrom:!this.state.viewFrom}) } }}>
                            <View style={{flexShrink: 1}}>
                                <View style={{flexDirection:"row"}}>
                                    <Text style={{ fontSize:style.textInputFontSize*0.75,color:style.textInputColor,fontStyle: 'italic',}}>{strings.start.toUpperCase()}</Text>
                                    {this.props.disable!=true && <Image source={(style.mode=="dark")?require('../assets/edit_white.png'):require('../assets/edit_black.png')} style={{height:12,width:12,marginLeft:5, resizeMode:"contain"}}/>}
                                </View>
                                <Text numberOfLines={1} style={{ fontSize:style.textInputFontSize,color:style.textInputColor, marginTop:-2}}>{racePlaceStartName[0]}</Text>
                                
                                <View style={{flexDirection:'row'}}>
                                    {racePlaceStartName.map((rowData,id) => ( <View style={{maxWidth:(this.props.oldRacePlaceStartName!=undefined)?"60%":undefined,}}>
                                          {(rowData!="France" && id!=0) && <Text numberOfLines={1} style={{ fontSize:style.textInputFontSize*0.6,color:style.textInputColor,  }}>{((id>1)?" - ":"")+ rowData}</Text>}
                                    </View>))}
                                </View>


                                

                            </View> 
                            </TouchableHighlight>
                        </View>
                        
                        {this.props.oldRacePlaceStartName!=undefined && <View style={{ alignItems:"center", justifyContent:"center",width:"35%", }}> 
                            <Text style={{fontSize:style.textInputFontSize*0.6,color:style.textInputColor,textDecorationLine:'line-through',  textAlign:"right", width:"100%" }}>{this.props.oldRacePlaceStartName}</Text>
                        </View>}

                        {(this.state.viewFrom==false && this.props.oldRacePlaceStartName==undefined) && <Text style={{ fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold',}}>{(this.props.raceDate!=undefined)?(((new Date(this.props.raceDate).getHours()<10)?"0":"")+ new Date(this.props.raceDate).getHours()+":"+((new Date(this.props.raceDate).getMinutes()<10)?"0":"")+ new Date(this.props.raceDate).getMinutes() ):""}</Text>}
                        {this.state.viewFrom==true && <MavMiniButton  minimize={true} icon={(style.mode=="dark")?require('../assets/close_black.png'):require('../assets/close_white.png')} text={strings.close} onlyButton={true}  onPress={()=>{  this.setState({viewFrom:false})  }} />}


                         



                  </View>

                  {this.state.viewFrom==true && <Trajet params={{hideNavBar:true,searchLimited:5,  onPricePlace: this.props.onPricePlaceFrom,  goBack:() => {this.setState({viewFrom:false}) }, }}/>}
                  {this.state.viewFrom==true && <View style={{width:2, height:8.5*style.textInputHeight,marginTop:12, backgroundColor:style.color1, marginLeft:9, position:"absolute" }}/> }         



                  {(stopList.length>0) && stopList.map((rowData, stI)=>(<View>     
                      <View style={{justifyContent: 'space-between',flexDirection: 'row',height:50}}>
                            <View style={{flexDirection: 'row',maxWidth:"85%"}}>
                              <View style={{ marginRight:10,}}>
                                  <View style={{width:20, height:20,borderRadius: 10, borderColor:(this.props.color!=undefined)?this.props.color:style.color1,borderWidth:2 }}/>
                                  <View style={{width:2, height:15,  backgroundColor:(this.props.color!=undefined)?this.props.color:style.color1, marginLeft:9, }}/>
                                  <View style={{width:2, height:15,  backgroundColor:(this.props.addStopAutorization==true && stI==stopList.length-1 && this.state.viewAddStop==false)?addStopColor:((this.props.color!=undefined)?this.props.color:style.color1), marginLeft:9, }}/>
                              </View>
                              <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {if(this.props.disable!=true ){ var viewStopList=JSON.parse(JSON.stringify(this.state.viewStopList)); viewStopList[stI]=!viewStopList[stI]; this.setState({viewStopList:viewStopList}) } }}>
                                  <View style={{flexShrink: 1}}>

                                      <View style={{flexDirection:"row"}}>
                                        <Text style={{ fontSize:style.textInputFontSize*0.75,color:style.textInputColor,fontStyle: 'italic',}}>{strings.onlyStop.toUpperCase()}</Text>
                                        {this.props.disable!=true && <Image source={(style.mode=="dark")?require('../assets/edit_white.png'):require('../assets/edit_black.png')} style={{height:12,width:12,marginLeft:5, resizeMode:"contain"}}/>}
                                      </View>
                                      <Text numberOfLines={1} style={{fontSize:style.textInputFontSize*0.75,color:style.textInputColor,textDecorationLine:(this.props.oldStopList!=undefined && this.props.oldStopList[stI]!=undefined && this.props.stopList[stI]=="removed")?'line-through':undefined,  }}>{ (this.props.oldStopList!=undefined && this.props.oldStopList[stI]!=undefined && this.props.stopList[stI]=="removed")?this.props.oldStopList[stI]:rowData[0]}</Text>
                                      


                                      

                                  </View>
                              </TouchableHighlight>
                            </View>
                          


                          



                        {(this.props.oldStopList!=undefined && this.props.oldStopList[stI]!=undefined && this.props.stopList[stI]!="removed") && <View style={{ justifyContent:"center",width:'40%', }}> 
                            <Text style={{fontSize:style.textInputFontSize*0.6,color:style.textInputColor, textDecorationLine:'line-through',  textAlign:"right" }}>{this.props.oldStopList[stI]}</Text>
                        </View>}

                          {(this.props.oldStopList==undefined && this.state.viewStopList[stI]==true) && <View style={{flexDirection:"row"}}>
                              <MavMiniButton  minimize={true} icon={(style.mode=="dark")?require('../assets/driver_bin_dark.png'):require('../assets/driver_bin_light.png')} text={strings.remove} style={{marginRight:10}} onlyButton={true}  onPress={()=>{ this.props.onPricePlaceStop("",stI, true);   }} />
                              <MavMiniButton  minimize={true} icon={(style.mode=="dark")?require('../assets/close_black.png'):require('../assets/close_white.png')} text={strings.close} onlyButton={true}  onPress={()=>{ var viewStopList=JSON.parse(JSON.stringify(this.state.viewStopList)); viewStopList[stI]=false; this.setState({viewStopList:viewStopList}) }} />
                          </View>}

                      </View>

                      {this.state.viewStopList[stI]==true && <Trajet params={{hideNavBar:true,searchLimited:5,  onPricePlace:(data) => { this.props.onPricePlaceStop(data,stI); }, goBack:() => {var viewStopList=JSON.parse(JSON.stringify(this.state.viewStopList)); viewStopList[stI]=false; this.setState({viewStopList:viewStopList}) }, }}/>}
                      {this.state.viewStopList[stI]==true && <View style={{width:2, marginTop:20, height:8*style.textInputHeight+10, backgroundColor:style.color1, marginLeft:9, position:"absolute" }}/> }         




                      </View>
                   ))}

                   {(this.props.addStopAutorization==true && this.props.racePlaceEndName!=undefined) && <View><View style={{justifyContent: 'space-between',flexDirection: 'row',height:50}}>
                            <View style={{flexDirection: 'row',maxWidth:"85%"}}>
                              <View style={{ marginRight:10,}}>
                                  <View style={{width:20, height:20,borderRadius: 10, borderColor:(this.state.viewAddStop==false)?addStopColor:((this.props.color!=undefined)?this.props.color:style.color1),borderWidth:2 }}/>
                                  <View style={{width:2, height:15,  backgroundColor:(this.state.viewAddStop==false)?addStopColor:((this.props.color!=undefined)?this.props.color:style.color1), marginLeft:9, }}/>
                                  <View style={{width:2, height:15,  backgroundColor:style.color1, marginLeft:9, }}/>
                              </View>
                              <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => {if(this.props.disable!=true ){  this.setState({viewAddStop:!this.state.viewAddStop}) } }}>
                                  <View style={{flexShrink: 1}}>

                                      <View style={{flexDirection:"row"}}>
                                        <Text style={{ fontSize:style.textInputFontSize*0.75,color:(this.state.viewAddStop==false)?addStopColor:style.textInputColor,fontStyle: 'italic',}}>{strings.onlyStop.toUpperCase()}</Text>
                                        {this.props.disable!=true && <Image source={(this.state.viewAddStop==false)? require('../assets/edit_grey.png') :((style.mode=="dark")?require('../assets/edit_white.png'):require('../assets/edit_black.png'))} style={{height:12,width:12,marginLeft:5, resizeMode:"contain"}}/>}
                                      </View>
                                      <Text numberOfLines={1} style={{fontSize:style.textInputFontSize*0.75,color:(this.state.viewAddStop==false)?addStopColor:style.textInputColor,}}>{strings.addStop}</Text>
                                      

                                  </View>
                              </TouchableHighlight>
                            </View>
                          
                          {this.state.viewAddStop==true && <MavMiniButton  minimize={true} icon={(style.mode=="dark")?require('../assets/close_black.png'):require('../assets/close_white.png')} text={strings.close} onlyButton={true}  onPress={()=>{ this.setState({viewAddStop:false}) }} />}

                      </View>

                      {this.state.viewAddStop==true && <Trajet params={{hideNavBar:true,searchLimited:5,  onPricePlace:(data) => { var stopI=(this.props.stopList==undefined)?0:this.props.stopList.length;  this.props.onPricePlaceStop(data,stopI); }, goBack:() => { this.setState({viewAddStop:false}) }, }}/>}
                      {this.state.viewAddStop==true && <View style={{width:2, marginTop:20, height:8.5*style.textInputHeight, backgroundColor:style.color1, marginLeft:9, position:"absolute" }}/> }         




                      </View>}











                   <View style={{}}>
                       <View style={{justifyContent: 'space-between',flexDirection: 'row',}}>
                            <View style={{flexDirection: 'row',maxWidth:"85%"}}>
                              
                              <View style={{ marginRight:10,}}>
                                  {this.state.viewTo!=true && <View style={{width:16, marginLeft:2, height:16,transform: [{ rotate:"45deg" }],backgroundColor:(this.props.color!=undefined)?this.props.color:style.color1 }}/>}
                                  {this.state.viewTo==true && <View style={{width:16, marginLeft:2,height:16,transform: [{ rotate:"45deg" }], }}/>}
                              </View>

                              <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.props.disable!=true && this.props.letaxiProvider!=true){ if(this.props.racePlaceEndName!=undefined){
                                  if(this.props.clickOnTo!=undefined) this.props.clickOnTo(); 
                                  else this.setState({viewTo:!this.state.viewTo}) 
                                } 
                                  else this.props.changeDuration() } 
                                }}>
                                <View style={{flexShrink: 1}}>
                                    <View style={{flexDirection:"row"}}>
                                          <Text style={{ fontSize:style.textInputFontSize*0.75,color:style.textInputColor,fontStyle: 'italic',}}>{(this.props.racePlaceEndName==undefined && this.props.letaxiProvider!=true)?strings.provision.toUpperCase():strings.arrival.toUpperCase()}</Text>
                                          {(this.props.disable!=true && this.props.letaxiProvider!=true) && <Image source={(style.mode=="dark")?require('../assets/edit_white.png'):require('../assets/edit_black.png')} style={{height:12,width:12,marginLeft:5, resizeMode:"contain"}}/>}
                                      </View>
                                      
                                      {(this.props.racePlaceEndName!=undefined) && <Text numberOfLines={1} style={{ fontSize:style.textInputFontSize,color:style.textInputColor, marginTop:-2}}>{racePlaceEndName[0]}</Text>}
                                      

                                      {(racePlaceEndNameList!=undefined) &&<Text numberOfLines={1} style={{ fontSize:style.textInputFontSize*0.6,color:style.textInputColor}}>{racePlaceEndNameList}</Text>}


                                      


                                      {(this.props.racePlaceEndName==undefined && this.props.letaxiProvider!=true) && <Text numberOfLines={1} style={{fontSize:style.textInputFontSize*((this.props.mode!="revenue")?0.7:0.6),color:style.textInputColor,}}>{strings.duration+" : "+(Math.floor(this.props.estimateTimeValue/3600)<10?"0":"")+Math.floor(this.props.estimateTimeValue/3600)+":"+((Math.floor((this.props.estimateTimeValue-3600*Math.floor(this.props.estimateTimeValue/3600))/60)<10)?"0":"")+Math.floor((this.props.estimateTimeValue-3600*Math.floor(this.props.estimateTimeValue/3600))/60)}</Text>}
                                      {(this.props.racePlaceEndName==undefined && this.props.letaxiProvider==true) && <Text numberOfLines={1} style={{fontSize:style.textInputFontSize*((this.props.mode!="revenue")?0.7:0.6),color:style.textInputColor,}}>{strings.unknownF}</Text>}
                                </View>
                                </TouchableHighlight>
                              </View>

                              {this.state.viewTo==true && <MavMiniButton  minimize={true} icon={(style.mode=="dark")?require('../assets/close_black.png'):require('../assets/close_white.png')} text={strings.close} onlyButton={true}  onPress={()=>{ this.setState({viewTo:false})  }} />}
                              
                              {this.props.oldRacePlaceEndName!=undefined && <View style={{ justifyContent:"center",width:'40%', }}> 
                                  <Text style={{fontSize:style.textInputFontSize*0.6,color:style.textInputColor, textDecorationLine:'line-through',  textAlign:"right" }}>{this.props.oldRacePlaceEndName}</Text>
                              </View>}

                               {(this.state.viewTo!=true && this.props.oldRacePlaceEndName==undefined && this.props.letaxiProvider!=true) && <View style={{alignItems:"center", backgroundColor:style.backgroundColor}}>
                                  {(this.props.modifyPath!=true && this.props.raceDate!=undefined) && <Text style={{textAlign:"right",fontSize:style.textInputFontSize*1.4,color:style.textInputColor,fontWeight: 'bold',}}>{hourDestination}</Text>}
                                  { (this.props.goReturnTaxi==true) && <Text style={{fontSize:style.textInputFontSize*0.8,textAlign:"right",color:style.textInputColor,top:-3}}>{strings.totalGoReturn}</Text>}
                              </View>}

                        
       
                      </View>

                    {this.state.viewTo==true && <Trajet params={{hideNavBar:true,searchLimited:5,  onPricePlace: this.props.onPricePlaceTo,  goBack:() => {this.setState({viewTo:false}) }, }}/>}
                    {this.state.viewTo==true && <View style={{width:2, height:8.5*style.textInputHeight,backgroundColor:style.color1, marginLeft:9, position:"absolute" }}/> }         
                    {this.state.viewTo==true && <View style={{ marginRight:10,marginTop:8*style.textInputHeight,  position:"absolute"}}>
                          <View style={{width:20, height:20,borderRadius:10,backgroundColor:style.color1, }}/>
                      </View>}


                     </View>
                  





                  </View>
      }</StyleContext.Consumer>)





      }

    }

