import React, { Component } from 'react';
import {
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableHighlight,
  Platform,
  ScrollView,
  ActivityIndicator,
  Image
} from 'react-native';
import {StyleContext} from '../styleC';
import strings from '../strings';
import info from '../info';


import MavButton from './mavButton';
import MavMiniButton from './mavMiniButton';
import TextPresentation from './textPresentation';
import TrainDetail from './trainDetail';

export default class sncfInfo extends Component {
constructor(props) { 
    super(props);


    
    var num=(this.props.numero!=undefined)?this.props.numero:"";
    num=num.toUpperCase(); 

    num=num.replace("TGV INOUI","")
    num=num.replace("TGV","")
    num=num.replace("TER","")
    num=num.replace("INTERCITES","")
    num=num.replace("INTERCITE","")
    num=num.replace("NUMERO","")
    num=num.replace("NUM","")
    num=num.replace("N°","")
    num=num.replace(/ /g,'')





    this.state = {
      changeNumber:false,
      numTrain:num, 
      trainName:undefined,
      viewDetailTrajet:false,
      hideIconi:(this.props.racePlaceStartName!=undefined)?true:false,
      

    }












      


  }

  findPlace(position){
      fetch("https://api.sncf.com/v1/coverage/sncf/places/"+position.longitude+"%3B"+position.latitude+"/places_nearby?", {method: "GET",headers: {'Authorization': '670396b7-c369-4eae-bb7e-eb3f17426463', 'Content-Type': 'application/x-www-form-urlencoded'}, }).then(response => response.json()).then(responseJson => {

          if(responseJson!=undefined && responseJson.places_nearby!=undefined){
              this.setState({placesNearby:responseJson.places_nearby, placesNearbyI:0},() =>{this.findStationInfo(responseJson.places_nearby[0])})

          }

      })
  }


  findStationInfo(placeID){
    console.log("findStationInfo lancé")
      var date=new Date(this.props.raceDate-15*60*1000)
      date=date.getFullYear()+""+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+""+((date.getDate()<10)?"0":"")+date.getDate()+"T"+((date.getHours()<10)?"0":"")+date.getHours()+""+((date.getMinutes()<10)?"0":"")+date.getMinutes()+"00"

      var found=false;
      console.log("date" +date)
      console.log("https://api.sncf.com/v1/coverage/sncf/stop_areas/"+placeID.id+"/"+this.props.typeTrajet+"?from_datetime="+date+"&")

      fetch("https://api.sncf.com/v1/coverage/sncf/stop_areas/"+placeID.id+"/"+this.props.typeTrajet+"?from_datetime="+date+"&", {method: "GET",headers: {'Authorization': '670396b7-c369-4eae-bb7e-eb3f17426463', 'Content-Type': 'application/x-www-form-urlencoded'}, }).then(response => response.json()).then(responseStation => {
                        


                        if(responseStation!=undefined){
                          var listToTest=(this.props.typeTrajet=="arrivals")?responseStation.arrivals:responseStation.departures
                          if(listToTest==undefined) listToTest=[];

                            var i;
                            for(i=0; i<listToTest.length;i++){
                              if(listToTest[i].display_informations!=undefined && (listToTest[i].display_informations.trip_short_name==this.state.numTrain || listToTest[i].display_informations.headsign==this.state.numTrain)){
                                
                                
                                

                                var hourTrain=this.convertDate((this.props.typeTrajet=="arrivals")?listToTest[i].stop_date_time.base_arrival_date_time:listToTest[i].stop_date_time.base_departure_date_time)
                                var realHourTrain=this.convertDate((this.props.typeTrajet=="arrivals")?listToTest[i].stop_date_time.arrival_date_time:( (listToTest[i].stop_date_time.despature_date_time!=undefined)?listToTest[i].stop_date_time.despature_date_time:listToTest[i].stop_date_time.base_departure_date_time) )

                                var realHourTrainDisplay=undefined;
                                var delay=0;

                                if((realHourTrain-hourTrain)> 5*60*1000){

                                  delay=(realHourTrain-hourTrain)/60000; 
                                  if(delay>60) delay=parseInt(delay/60)+"h"+((delay%60<10)?"0":"")+delay%60;
                                  else delay=delay+" "+strings.minutes.toLowerCase();

                                  realHourTrainDisplay=listToTest[i].stop_date_time.arrival_date_time[9]+listToTest[i].stop_date_time.arrival_date_time[10]+":"+listToTest[i].stop_date_time.arrival_date_time[11]+listToTest[i].stop_date_time.arrival_date_time[12]
                                }

                               
                                var hourTrainDisplay=listToTest[i].stop_date_time.base_arrival_date_time[9]+listToTest[i].stop_date_time.base_arrival_date_time[10]+":"+listToTest[i].stop_date_time.base_arrival_date_time[11]+listToTest[i].stop_date_time.base_arrival_date_time[12]
                                
                                this.setState({trainName:listToTest[i].display_informations.commercial_mode,stationName:placeID.name, journey:listToTest[i].links[1].id, hourTrain:hourTrainDisplay,realHourTrain:realHourTrainDisplay, delay:delay })

                                found=true; 

                              }



                            }

                            

                        }

                        if(found==false){
                          if(this.state.placesNearbyI<this.state.placesNearby.length-1) this.setState({placesNearbyI:this.state.placesNearbyI+1},() =>{this.findStationInfo(this.state.placesNearby[this.state.placesNearbyI])})
                          else this.setState({placesNearbyI:0},() =>{this.findRemoveTrain(this.state.placesNearby[0])})
                        }
                        

                      })

  }

  findRemoveTrain(placeID){
    console.log("findRemoveTrain")

    var date=new Date(this.props.raceDate-15*60*1000)
    date=date.getFullYear()+""+(((date.getMonth()+1)<10)?"0":"")+(date.getMonth()+1)+""+((date.getDate()<10)?"0":"")+date.getDate()+"T"+((date.getHours()<10)?"0":"")+date.getHours()+""+((date.getMinutes()<10)?"0":"")+date.getMinutes()+"00"

    var found=false;
    fetch("https://api.sncf.com/v1/coverage/sncf/stop_areas/"+placeID.id+"/traffic_reports?from_datetime="+date+"&", {method: "GET",headers: {'Authorization': '670396b7-c369-4eae-bb7e-eb3f17426463', 'Content-Type': 'application/x-www-form-urlencoded'}, }).then(response => response.json()).then(responseDeleted => {


                      if(responseDeleted!=undefined && responseDeleted.disruptions!=undefined){
                          var i; 
                          for(i=0; i<responseDeleted.disruptions.length;i++){
                              if(responseDeleted.disruptions[i].impacted_objects!=undefined && responseDeleted.disruptions[i].impacted_objects[0].pt_object!=undefined && responseDeleted.disruptions[i].impacted_objects[0].pt_object.trip!=undefined && responseDeleted.disruptions[i].impacted_objects[0].pt_object.trip.name==this.state.numTrain) {
                                if(responseDeleted.disruptions[i].severity.effect=="NO_SERVICE"){
                                  
                                    this.setState({noService:true })
                                    found=true;
                                 }

                              }

                          }
                        }

                      if(found==false){
                        if(this.state.placesNearbyI<this.state.placesNearby.length-1) this.setState({placesNearbyI:this.state.placesNearbyI+1},() =>{this.findRemoveTrain(this.state.placesNearby[this.state.placesNearbyI])})
                      }
                      

                    })


  }

    decode(t, e) {
        for (
          var n,
            o,
            u = 0,
            l = 0,
            r = 0,
            d = [],
            h = 0,
            i = 0,
            a = null,
            c = Math.pow(10, e || 5);
          u < t.length;

        ) {
          (a = null), (h = 0), (i = 0);
          do
            (a = t.charCodeAt(u++) - 63), (i |= (31 & a) << h), (h += 5);
          while (a >= 32);
          (n = 1 & i ? ~(i >> 1) : i >> 1), (h = i = 0);
          do
            (a = t.charCodeAt(u++) - 63), (i |= (31 & a) << h), (h += 5);
          while (a >= 32);
          (o = 1 & i ? ~(i >> 1) : i >> 1), (l += n), (r += o), d.push([
            l / c,
            r / c,
          ]);
        }
        return (d = d.map(function(t) {
          return { latitude: t[0], longitude: t[1] };
        }));
  }



  componentDidMount(){

        if(this.props.racePlaceStartName!=undefined && this.props.racePlaceEndName!=undefined){ 

          var fetchURLparams={origin:this.props.racePlaceStartName ,type:"directions", key: info.googleMapsKey, minimizeRequest:true,destination:this.props.racePlaceEndName}
          if(info.avoidHighways==true) fetchURLparams.avoidHighways=true;
          
          fetch("https://us-central1-mon-appli-vtc.cloudfunctions.net/getMapsInfo", {body: JSON.stringify(fetchURLparams),method: "POST"}).then(response => response.json()).then(responseJson => {
              if(typeof(responseJson)!="object") responseJson=JSON.parse(responseJson);
              console.log("componentDidMount sncf info")
              console.log(responseJson)
        
              if (responseJson.status != 'ZERO_RESULTS' && responseJson.routes.length>0) {
                var coords=this.decode(responseJson.routes[0].overview_polyline.points);

                if(this.props.racePlaceEndName.toUpperCase().search("GARE")!='-1' && this.props.racePlaceStartName.toUpperCase().search("GARE")=='-1') this.findPlace({latitude:responseJson.routes[0].legs[0]["end_location"].lat,longitude:responseJson.routes[0].legs[0]["end_location"].lng })
                else this.findPlace({latitude:responseJson.routes[0].legs[0]["start_location"].lat,longitude:responseJson.routes[0].legs[0]["start_location"].lng })
              
              }

            })
    

        } else if(this.props.stationPosition!=undefined){ 

          /*je vais récupérer la gare*/
          this.findPlace(this.props.stationPosition)
        }

    }


convertDate(dateConv){

  var date=dateConv[0]+dateConv[1]+dateConv[2]+dateConv[3]+"-"+dateConv[4]+dateConv[5]+"-"+dateConv[6]+dateConv[7]+"T"+dateConv[9]+dateConv[10]+":"+dateConv[11]+dateConv[12]+":00Z"
  date=new Date(date);
  return date

}



  render() {


    return (
      <StyleContext.Consumer>{style => 
          <View style={(this.props.style==undefined)?{}:this.props.style}>


            <TextPresentation style={{marginTop:5}}  disableChild={true}  onEditClick={()=>{ this.setState({changeNumber:!this.state.changeNumber}) }}   viewEdit={(this.state.viewDetailTrajet!=true && this.props.editable==true)} 
            icon={(this.state.noService==true || this.state.trainName=="TGV")?require('../assets/sncf/sncf.png'): ((this.state.trainName=="OUIGO")?require('../assets/sncf/ouigo.png'):( (this.state.trainName=="TER")? require('../assets/sncf/ter.png') :( (this.state.trainName=="RER")? require('../assets/sncf/rer.png'): ( (this.state.trainName=="TGV Lyria")? require('../assets/sncf/lyria.png'): ((this.state.trainName=="Thalys")? require('../assets/sncf/thalys.png') :( (this.state.trainName=="TGV INOUI")? (style.mode=="light")?require('../assets/sncf/inoui_light.png'):require('../assets/sncf/inoui_dark.png') : ((style.mode=="light")?require('../assets/favorite/train_black.png'):require('../assets/favorite/train_white.png'))  )  ) )  )  ))} 

            textExplication={(this.state.trainName!=undefined)?(this.state.trainName.toUpperCase()+" N°") :strings.numeroTrain.toUpperCase()} editable={false} textRightRemoved={true}  text={this.state.numTrain} 
            elementRight={ <View>

                    {(this.state.changeNumber!=true && this.state.hourTrain!=undefined && this.state.realHourTrain==undefined && this.state.viewDetailTrajet!=true) && <View style={{ alignItems:"flex-end" }}>

                          <Text style={{fontSize:style.textInputFontSize*0.8,color:style.textInputColor,}}>{strings.arrivalAt+" "}<Text style={{fontSize:style.textInputFontSize*1,color:style.textInputColor,fontWeight: 'bold',}}>{this.state.hourTrain}</Text></Text> 
                          
                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.state.hideIconi!=true) this.setState({viewDetailTrajet:true}) }}>
                              <View style={{flexDirection:"row", alignItems:"center" }} >
                                {this.state.hideIconi!=true && <Image source={require('../assets/iconi_green.png')} style={{height:13,width:13,resizeMode:"contain", }}/>}
                              <Text style={{marginLeft:2,fontSize:style.textInputFontSize,color:style.colorValidate,fontWeight: 'bold'}}>{strings.trainAtTime}</Text>
                              </View>
                          </TouchableHighlight>

                    
                    </View>}

                     {(this.state.changeNumber!=true && this.state.hourTrain!=undefined && this.state.realHourTrain!=undefined && this.state.viewDetailTrajet!=true) && <View style={{ alignItems:"flex-end" }}>

                          <Text style={{fontSize:style.textInputFontSize*0.8,color:style.textInputColor,}}>{strings.arrivalAt+" "}<Text style={{color:style.textInputColor,textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{this.state.hourTrain}</Text><Text style={{fontSize:style.textInputFontSize*1,color:style.colorNotification,fontWeight: 'bold',}}>{" "+this.state.realHourTrain}</Text></Text> 
                          
                          <TouchableHighlight underlayColor={'#FFFFFF00'} onPress={() => { if(this.state.hideIconi!=true) this.setState({viewDetailTrajet:true}) }}>

                            <View style={{flexDirection:"row", alignItems:"center" }} >
                              {this.state.hideIconi!=true && <Image source={require('../assets/iconi_red.png')} style={{height:13,width:13,resizeMode:"contain", }}/>}
                              <Text style={{marginLeft:2, fontSize:style.textInputFontSize,color:style.colorNotification,fontWeight: 'bold'}}>{strings.delayOf+" "+this.state.delay}</Text>
                            </View>
                          </TouchableHighlight>

                    </View>}

                    {this.state.noService==true && <Text style={{fontSize:style.textInputFontSize,color:style.colorNotification,fontWeight: 'bold'}}>{strings.trainDeleted}</Text> }

                    {(this.state.changeNumber==true || this.state.viewDetailTrajet==true) && <MavMiniButton  minimize={true} icon={(style.mode=="dark")?require('../assets/close_black.png'):require('../assets/close_white.png')} text={strings.close} onlyButton={true}  onPress={()=>{ if(this.state.viewDetailTrajet==true) this.setState({viewDetailTrajet:false}) ; else this.setState({changeNumber:false})  }} />}
    
                    </View> }
              />



              {(this.state.changeNumber==true && this.props.editable==true) && <TextPresentation hideTopbar={true} style={{marginTop:5 }} defautVisible={true} text={ "" }  >

                  <TextPresentation style={{marginTop:5}} text={this.state.numTrain}  textExplication={strings.numeroTrain} placeholder={"ex : 6504"} onChangeText={(text)=>{this.setState({numTrain: text })}}/>
             
                  <View style={{width:"100%", alignItems:"center", justifyContent:"center", marginTop:10}}> 
                      <MavButton onlyButton={true} small={true} text={strings.confirm} onPress={()=>{this.setState({changeNumber:false, placesNearbyI:0},() =>{if(this.state.placesNearby!=undefined) this.findStationInfo(this.state.placesNearby[0])}); if(this.props.onChangeNumber!=null) this.props.onChangeNumber(this.state.numTrain); }} />
                  </View>

                </TextPresentation>}


                {(this.state.viewDetailTrajet==true) && <TextPresentation hideTopbar={true} style={{marginTop:5 }} defautVisible={true} text={ "" }  >
                    <TrainDetail stationName={this.state.stationName} journey={this.state.journey}  />
                </TextPresentation>}













          


         

            
        </View>

      }</StyleContext.Consumer>


    	)}

    }

