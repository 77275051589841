export default {
  "AA":{name:"American Airlines",icon:require('./assets/flight/AA.png') }, 
  "EW":{name:"Eurowings",icon:require('./assets/flight/EW.png') }, 

  "B0":{name:"La Compagnie",icon:require('./assets/flight/B0.png') }, 
  
  "EY":{name:"ETIHAD",icon:require('./assets/flight/EY.png') }, 
  "BA":{name:"British Airways",icon:require('./assets/flight/BA.png') }, 
  "QR":{name:"Qatar Airways",icon:require('./assets/flight/QR.png') }, 
  "AT":{name:"Royal Air Maroc",icon:require('./assets/flight/AT.png') }, 
  "AH":{name:"Air Algerie",icon:require('./assets/flight/AH.png') }, 
  "SU":{name:"AEROFLOT",icon:require('./assets/flight/SU.png') }, 
  

  "W6":{name:"WIZZ AIR",icon:require('./assets/flight/W6.png') }, 
  "EK":{name:"Emirates",icon:require('./assets/flight/EK.png') }, 
  "D8":{name:"Norwegian",icon:require('./assets/flight/D8.png') }, 
  "TK":{name:"Turkish Airlines",icon:require('./assets/flight/TK.png') }, 
  "LG":{name:"Luxair",icon:require('./assets/flight/LG.png') }, 
  "FR":{name:"Ryanair",icon:require('./assets/flight/FR.png') }, 
  "LX":{name:"SWISS",icon:require('./assets/flight/LX.png') }, 
  "TP":{name:"TAP",icon:require('./assets/flight/TP.png') }, 
  

  "AZ":{name:"ITA",icon:require('./assets/flight/AZ.png') }, 
  "V7":{name:"Volotea",icon:require('./assets/flight/V7.png') }, 
  "LH":{name:"Luftansa",icon:require('./assets/flight/LH.png') }, 
  "BJ":{name:"Nouvelair",icon:require('./assets/flight/BJ.png') }, 
  "KL":{name:"KLM",icon:require('./assets/flight/KL.png') }, 
  "UA":{name:"United Airlines",icon:require('./assets/flight/UA.png') }, 
  "U2":{name:"Eazyjet",icon:require('./assets/flight/U2.png') }, 
  "VY":{name:"Vueling",icon:require('./assets/flight/VY.png') }, 
  "AF":{name:"Air France",icon:require('./assets/flight/AF.png') }, 
  "IB":{name:"Iberia",icon:require('./assets/flight/IB.png') }, 
  "DL":{name:"Delta",icon:require('./assets/flight/DL.png') }, 
  "MU":{name:"China Eastern Airlines",icon:require('./assets/flight/MU.png') }, 
  "CZ":{name:"China Southern Airlines",icon:require('./assets/flight/CZ.png') }, 
  "SN":{name:"Brussels Airlines",icon:require('./assets/flight/SN.png') }, 
  "WN":{name:"Southwest Airlines",icon:require('./assets/flight/WN.png') }, 
  "XK":{name:"Corsica",icon:require('./assets/flight/XK.png') }, 
  "HV":{name:"Transavia",icon:require('./assets/flight/HV.png') }, 

}
 


  